import _ from "lodash";
import { Features } from "modules/auth/Authorization";

export const getSolutionId = (featureData) => {
  return featureData
    ? featureData.find(
        (feature) => feature.feature_name === Features.SURGICAL_TOTE_TRACKING,
      )?.solution ?? ""
    : "";
};
