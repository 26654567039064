import { connect } from "react-redux";
import { SearchBar } from "components/search-bar/SearchBar";
import SearchBarState from "../redux/ConnectedCarSearchBarState";
import { getSolutionId } from "modules/organizations/OrganizationsState";
import connectedCarDomainData from "modules/domain-data/ConnectedCarDomainData";

const mapStateToProps = (state) => {
  const {
    getSearchText,
    getSearchCategory,
    getTypeaheadOptionsMetadata,
    getHasSearchCriteriaChanged,
  } = SearchBarState.selectors;

  return {
    typeaheadOptionsMetadata: getTypeaheadOptionsMetadata(state),
    searchText: getSearchText(state),
    searchCategory: getSearchCategory(state),
    hasSearchCriteriaChanged: getHasSearchCriteriaChanged(state),
    solutionId: getSolutionId(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  const {
    setSearchCategoryForKey,
    setSearchText,
    clearSearchText,
    resetSearchBar,
    clearSavedSearch,
    searchEntities,
    clickedSearchOrEnter,
  } = SearchBarState.actionCreators;
  return {
    setSearchCategory: (key) => dispatch(setSearchCategoryForKey(key)),
    setSearchText: (text) => dispatch(setSearchText(text)),
    clearSearchText: () => dispatch(clearSearchText()),
    resetSearchBar: () => dispatch(resetSearchBar()),
    clearSavedSearch: () => dispatch(clearSavedSearch()),
    searchEntities: (solutionId) => {
      dispatch(clickedSearchOrEnter(true));
      dispatch(searchEntities(solutionId, false));
    },
    fetchDomainData: (solutionId) =>
      dispatch(
        connectedCarDomainData.actionCreators.fetchDomainData(solutionId),
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
