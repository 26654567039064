import axios from "axios";
import _ from "lodash";
import { getSolutionId } from "modules/organizations/OrganizationsState";
import { PaginationType } from "components/search-bar/enums.utils";

/**
 * Create a redux state for async requests for use with async/paginated select filters.
 *
 * @param {object} config The configuration object containing available parameters.
 * @param {string} config.topic The key where data will be stored in redux state.
 * @param {string} config.url The request url.
 * @param {function} config.getUrl A function that will be passed solutionId and redux state to build the URL.
 * @param {object} config.additionalParams An object of static parameters that will be applied to every fetch.
 * If `config.getAdditionalParams` is provided, this value will be ignored.
 * @param {function} config.getAdditionalParams A function, given redux state, that returns an object of parameters
 * that will be applied to every fetch. If provided, `config.additionalParams` will be ignored.
 * @param {object} config.additionalHeaders An object of static headers that will be applied to every fetch.
 * If `config.getAdditionalHeaders` is provided, this value will be ignored.
 * @param {function} config.getAdditionalHeaders A function, given redux state, that returns an object of headers
 * that will be applied to every fetch. If provided, `config.additionalHeaders` will be ignored.
 * @param {object} config.additionalQueryOnlyParams An object of static parameters that will be applied only to fetches with a query.
 * @param {object} config.additionalQueryOnlyHeaders An object of static headers that will be applied only to fetches with a query.
 * @param {string} config.queryParam The query parameter for the user's search query.
 * @param {PaginationType} config.paginationType The method of pagination to use when requesting the next set of options.
 * @param {function(*): Array} config.getResponseData A function, given the response data, will return the list of results to use as options.
 * @param {function(*): *} config.transformResult A function, given a single result, will return the resulting option.
 * @returns {Object}
 */
export const buildAsyncFilterOptionsState = ({
  topic,
  url,
  getUrl,
  additionalParams = {},
  getAdditionalParams,
  additionalHeaders = {},
  getAdditionalHeaders,
  additionalQueryOnlyParams = {},
  additionalQueryOnlyHeaders = {},
  queryParam = "query",
  pageSize = 20,
  paginationType = PaginationType.DEFAULT,
  getResponseData = (data) => data,
  getMetaData = (data) => data?.meta,
  transformResult = (data) => data,
}) => {
  // Validating configuration inputs. topic and url are required.
  if (!topic) {
    throw new Error(
      "Missing required parameter `topic` in buildAsyncFilterOptionsState",
    );
  }

  if (!url && !getUrl) {
    throw new Error(
      "Missing required parameter `url` or `getUrl` in buildAsyncFilterOptionsState",
    );
  }

  // Actions types

  const FETCH_NEXT = `${topic}/FETCH_NEXT_OPTIONS`;
  const RECEIVE_NEXT = `${topic}/RECEIVE_NEXT_OPTIONS`;
  const FUZZY_OPTION_COUNT = `${topic}/FUZZY_OPTION_COUNT`;
  const FETCH_NEXT_ERROR = `${topic}/FETCH_NEXT_OPTIONS_ERROR`;
  const SET_QUERY_TEXT = `${topic}/SET_QUERY_TEXT`;
  const SET_ACTIVE_ORGANIZATION = "Organizations/SET_ACTIVE_ORGANIZATION";

  // Helpers

  // Object that will hold cancel callbacks for the requests made in the fetch
  const tokens = {};
  // Allows us to know if the error is from a cancelation in the catch of the promise
  const CANCEL_MESSAGE = "CANCELED";

  const getRequestOrderIndex = (state) => {
    return state[topic].requestOrderIndex;
  };

  const getQuery = (state) => {
    return state[topic].query ?? "";
  };

  const getRequestUrl = (state) => {
    let requestUrl = url;

    if (getUrl) {
      let solutionId = getSolutionId(state);
      requestUrl = getUrl(solutionId, state);
    }

    return requestUrl;
  };

  const getParams = (state, queryValueOverride = null) => {
    let params = {};

    // Similar to url and getUrl, getAdditionalParams takes priority over additionalParams.
    if (getAdditionalParams) {
      params = { ...getAdditionalParams(state) };
    } else {
      params = { ...additionalParams };
    }

    const query = queryValueOverride ?? getQuery(state);
    if (query.length > 0) {
      // These params are only added when the user is searching.
      params[queryParam] = query;
      params = { ...params, ...additionalQueryOnlyParams };
    }

    // Pagination
    const lastResponse = state[topic].lastResponse;
    if (paginationType === PaginationType.OPEN_SEARCH) {
      params = {
        ...params,
        searchAfter: lastResponse?.data.meta?.searchAfter ?? null,
        size: pageSize,
      };
    } else {
      params = {
        ...params,
        pageNumber: _.isNumber(lastResponse?.data.meta.currentPage)
          ? lastResponse?.data.meta.currentPage + 1
          : 0,
        pageSize: pageSize,
      };
    }

    return params;
  };

  const getHeaders = (state, queryValueOverride = null) => {
    let headers = {};

    // Similar to url and getUrl, getAdditionalHeaders takes priority over additionalHeaders.
    if (getAdditionalHeaders) {
      headers = { ...getAdditionalHeaders(state) };
    } else {
      headers = { ...additionalHeaders };
    }

    const query = queryValueOverride ?? getQuery(state);
    // These headers are only added when the user is searching.
    if (query.length > 0) {
      headers = { ...headers, ...additionalQueryOnlyHeaders };
    }

    return headers;
  };

  // Action creators

  /**
   * The fetch function will request the data and apply the transforms as configured.
   *
   * @param {string} query The queryParam value.
   * @returns
   */
  const fetchNext = () => {
    return (dispatch, getState) => {
      const state = getState();
      const requestOrderIndex = getRequestOrderIndex(state);

      // If we previously requested, cancel the pending request.
      const tokenKey = `${requestOrderIndex}`;
      if (tokens[tokenKey]) {
        tokens[tokenKey].cancelRequest(CANCEL_MESSAGE);
      }

      // Setup cancel token for this request.
      tokens[tokenKey] = {};
      const cancelToken = new axios.CancelToken((cancel) => {
        tokens[tokenKey].cancelRequest = cancel;
      });

      dispatch({ type: FETCH_NEXT });

      const requestUrl = getRequestUrl(state);
      const config = {
        headers: getHeaders(state),
        params: getParams(state),
        cancelToken: cancelToken,
      };

      return axios
        .get(requestUrl, config)
        .then((response) => {
          // Using getResponseData to get an array of results.
          const results = getResponseData(response.data) ?? [];
          const meta = getMetaData(response.data) ?? {};

          dispatch({
            type: RECEIVE_NEXT,
            // Using transformResult to get the desired format of the options.
            payload: {
              options: results.map((result) => transformResult(result)),
              hasMore: results.length === pageSize,
              requestOrderIndex: requestOrderIndex,
              totalCount: meta.totalCount,
              lastResponse: response,
            },
          });
        })
        .catch((error) => {
          if (error.message !== CANCEL_MESSAGE) {
            console.error(error);
            dispatch({ type: FETCH_NEXT_ERROR });
          }
        });
    };
  };

  const initFuzzyOptionsCount = (selectedOptions) => {
    return (dispatch, getState) => {
      const state = getState();
      const requestUrl = getRequestUrl(state);

      selectedOptions.forEach((option) => {
        const query = option.label ?? "";

        if (query.length > 0) {
          const config = {
            headers: getHeaders(state, query),
            params: getParams(state, query),
          };

          // Force starting from the beginning for fuzzy options.
          if (paginationType === PaginationType.OPEN_SEARCH) {
            config.params.searchAfter = null;
          } else {
            config.params.pageNumber = 0;
          }

          return axios
            .get(requestUrl, config)
            .then((response) => {
              // Using getMetaData to get meta data for total count.
              const meta = getMetaData(response.data) ?? {};

              dispatch({
                type: FUZZY_OPTION_COUNT,
                payload: {
                  query: query,
                  totalCount: meta.totalCount,
                },
              });
            })
            .catch((error) => {
              console.error(error);
            });
        }
      });
    };
  };

  const setQueryText = (query = "") => ({
    type: SET_QUERY_TEXT,
    payload: { query },
  });

  // Reducer

  const initialState = {
    requestMap: new Map(),
    hasMore: true,
    query: "",
    requestOrderIndex: 0,
    totalCounts: {},
    isLoading: false,
    lastResponse: null,
  };

  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_QUERY_TEXT:
        return {
          requestMap: new Map(),
          hasMore: true,
          query: action.payload.query,
          requestOrderIndex: 0,
          totalCounts: { ...state.totalCounts, [action.payload.query]: 0 },
        };
      case FETCH_NEXT:
        return {
          ...state,
          requestOrderIndex: state.requestOrderIndex + 1,
          isLoading: true,
        };
      case RECEIVE_NEXT:
        let totalCounts = { ...state.totalCounts };
        if (state.query.length > 0) {
          totalCounts[state.query] = action.payload.totalCount;
        }
        // maintain immutability by creating a new Map
        const newRequestMap = new Map(state.requestMap);
        newRequestMap.set(
          action.payload.requestOrderIndex,
          action.payload.options,
        );
        return {
          ...state,
          requestMap: newRequestMap,
          isLoading: false,
          hasMore: action.payload.hasMore,
          totalCounts: totalCounts,
          lastResponse: action.payload.lastResponse,
        };
      case FUZZY_OPTION_COUNT:
        return {
          ...state,
          totalCounts: {
            ...state.totalCounts,
            //update the query of fuzzy option with total count
            [action.payload.query]: action.payload.totalCount,
          },
        };
      case FETCH_NEXT_ERROR:
        return { ...state, isLoading: false, hasMore: false };
      case SET_ACTIVE_ORGANIZATION:
        return initialState;
      default:
        return state;
    }
  };

  // Selectors
  const getOptions = (state) => {
    const requestMap = state[topic].requestMap;
    // flatten all pages in map, which are arrays of options, into one array
    const options = [].concat.apply([], [...requestMap.values()]);
    return options;
  };

  const getHasMore = (state) => {
    return state[topic].hasMore;
  };

  const getTotalCounts = (state) => {
    return state[topic].totalCounts;
  };

  const getIsLoading = (state) => {
    return state[topic].isLoading;
  };

  return {
    mountPoint: topic,
    actionCreators: { fetchNext, setQueryText, initFuzzyOptionsCount },
    selectors: {
      getOptions,
      getHasMore,
      getTotalCounts,
      getIsLoading,
    },
    reducer,
  };
};
