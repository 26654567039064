import apiUrl from "api-url";
import { buildAsyncFilterOptionsState } from "components/search-bar/AsyncFilterOptionsStateBuilder";
import { FilterNameEnum } from "pages/containertracking/utils/enum.utils";

export const locationOptionsState = buildAsyncFilterOptionsState({
  topic: "sensorLocationFilter",
  url: apiUrl(
    `/containertracking/api/location?filter_name=${FilterNameEnum.SENSOR_LOCATIONS}`,
  ),
  pageSize: 5,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (item) => ({
    label: `${item.location_name} (${item.location_code})`,
    value: item.location_code,
  }),
});
