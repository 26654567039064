/** @jsxImportSource @emotion/react */
import { Text } from "components/atoms/Text.atom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { DateTime } from "components/atoms/DateTime.atom";

const SensorID = ({ value }) => {
  return <Text data-qa="sensor-id">{value}</Text>;
};

SensorID.propTypes = {
  value: PropTypes.string,
};

const LocationCell = ({ value }) => {
  const { locationName, locationCode } = value;
  return (
    <Text data-qa="location">
      ({locationCode}) {locationName}
    </Text>
  );
};

LocationCell.propTypes = {
  value: PropTypes.string,
};

const SensorStatusCell = ({ value }) => {
  return <Text data-qa="sensor-status">{value}</Text>;
};

SensorStatusCell.propTypes = {
  value: PropTypes.string,
};

const LastReceivedReading = ({ value }) => {
  const { t } = useTranslation("container-tracking");

  return (
    <>
      {value ? (
        <DateTime
          plain
          localize
          dateTime={value}
          fallback={<Text>{t("container-tracking:N/A")}</Text>}
        />
      ) : null}
    </>
  );
};

LastReceivedReading.propTypes = {
  value: PropTypes.string,
};

export const useColumns = () => {
  const { t } = useTranslation("container-tracking");

  const results = [
    {
      Header: t("container-tracking:Sensor ID"),
      id: "sensorId",
      Cell: SensorID,
      accessor: "sensorId",
      minWidth: 200,
    },
    {
      Header: t("container-tracking:Location"),
      id: "locationCode",
      Cell: LocationCell,
      accessor: (d) => {
        return {
          locationName: d.locationName ? d.locationName : "",
          locationCode: d.locationCode ? d.locationCode : "",
        };
      },
      minWidth: 200,
    },
    {
      Header: t("container-tracking:Sensor Status"),
      id: "status",
      minWidth: 60,
      Cell: SensorStatusCell,
      accessor: (d) => {
        return d?.status;
      },
    },
    {
      Header: t("container-tracking:Last Received Reading"),
      id: "lastReadTime",
      minWidth: 125,
      Cell: LastReceivedReading,
      accessor: "lastReadTime",
    },
  ];

  return results;
};
