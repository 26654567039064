import { connect } from "react-redux";
import { getFeatureData } from "modules/organizations/OrganizationsState";
import { getSolutionId } from "pages/containertracking/utils/ContainerTrackingUtils";
import SensorOverviewSearch from "pages/containertracking/search/SensorOverview/SensorOverview.Search.page";
import SensorOverviewSearchBarState from "pages/containertracking/redux/SensorOverviewSearchBarState";

function mapStateToProps(state) {
  return {
    solutionId: getSolutionId(getFeatureData(state)),
    searchResults:
      SensorOverviewSearchBarState.selectors.getSearchResults(state),
    showFilters:
      SensorOverviewSearchBarState.selectors.getShowAdvancedSearch(state),
    isLoading: SensorOverviewSearchBarState.selectors.getIsLoading(state),
    sortColumn: SensorOverviewSearchBarState.selectors.getSortColumn(state),
    reverseSort: SensorOverviewSearchBarState.selectors.getReverseSort(state),
    page: SensorOverviewSearchBarState.selectors.getPage(state),
    pageSize: SensorOverviewSearchBarState.selectors.getPageSize(state),
    totalPages: SensorOverviewSearchBarState.selectors.getTotalPages(state),
    totalCount: SensorOverviewSearchBarState.selectors.getTotalEntities(state),
    exportIdentifier:
      SensorOverviewSearchBarState.selectors.getExportIdentifier(state),
    exportName: SensorOverviewSearchBarState.selectors.getExportName(state),
    isExporting: SensorOverviewSearchBarState.selectors.getIsExporting(state),
    exportFailed: SensorOverviewSearchBarState.selectors.getExportFailed(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleShowFilters: (showFilters) =>
      dispatch(
        SensorOverviewSearchBarState.actionCreators.toggleShowFilters(
          showFilters,
        ),
      ),
    setPagination: (page, pageSize) =>
      dispatch(
        SensorOverviewSearchBarState.actionCreators.setPagination(
          null,
          page,
          pageSize,
        ),
      ),
    setSort: (sortColumn, reverse) =>
      dispatch(
        SensorOverviewSearchBarState.actionCreators.setSort(
          null,
          sortColumn,
          reverse,
        ),
      ),
    exportEntities: () => {
      dispatch(SensorOverviewSearchBarState.actionCreators.exportSearch(null));
    },
    clearExportErrors: () => {
      dispatch(SensorOverviewSearchBarState.actionCreators.clearExportErrors());
    },
    resetSearch: () => {
      dispatch(
        SensorOverviewSearchBarState.actionCreators.resetSearchAndFilters(),
      );
      dispatch(SensorOverviewSearchBarState.actionCreators.searchEntities());
    },
    searchEntities: () => {
      dispatch(SensorOverviewSearchBarState.actionCreators.searchEntities());
    },
    resetExport: () => {
      dispatch(SensorOverviewSearchBarState.actionCreators.resetExport());
    },
    resetSearchBar: () =>
      dispatch(SensorOverviewSearchBarState.actionCreators.resetSearchBar()),
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    exportEntities: () => dispatchProps.exportEntities(stateProps.solutionId),
    resetSearch: () => dispatchProps.resetSearch(stateProps.solutionId),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(SensorOverviewSearch);
