import { buildAsyncFilterOptionsState } from "components/search-bar/AsyncFilterOptionsStateBuilder";
import { locationsUrl } from "../redux/MilestoneDomainDataState";

export const milestoneDashboardLocationsOptionsState =
  buildAsyncFilterOptionsState({
    topic: "milestoneDashboardLocationsFilter",
    getUrl: locationsUrl,
    pageSize: 20,
    getResponseData: (data) => data?.locations ?? [],
    transformResult: (result) => ({ label: result.name, value: result.code }),
  });
