import { getEverythingQueryString } from "../../../components/search-bar/search-filter-query-strings";

export const SEARCH_CATEGORIES = [
  {
    queryKey: "everything",
    label: (t) => t("connected-car:Search VIN, Last 8 of VIN, Product Type"),
    placeholder: (t) =>
      t("connected-car:Search VIN, Last 8 of VIN, Product Type"),
    queryBuilder: getEverythingQueryString,
    property: null,
  },
];
