/** @jsxImportSource @emotion/react */
import { customerApiUrl } from "api-url";
import { buildAsyncFilterOptionsState } from "components/search-bar/AsyncFilterOptionsStateBuilder";
import CriticalPartsState from "pages/critical-parts/redux/CriticalParts.state";

export const fuCodeFilterOptionsState = buildAsyncFilterOptionsState({
  topic: "fuCodeCPFilter",
  getUrl: (solutionId, state) => {
    const locationCode =
      CriticalPartsState.selectors.getSelectedLocationCode(state);
    return customerApiUrl(
      `/critical-parts/location-inventory/${locationCode}/dropdown`,
    );
  },
  pageSize: 20,
  getResponseData: (data) => data?.partGroups ?? [],
  transformResult: (item) => ({
    label: item,
    value: item,
  }),
});
