/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import Loader from "react-loader";
import { useState, useEffect, useMemo, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import { Text } from "components/atoms/Text.atom";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import { DonutChart } from "components/molecules/DonutChart.molecule";
import { ExceptionCountGroup } from "components/molecules/ExceptionCountGroup.molecule";
import { SavedSearchesPanel } from "components/organisms/SavedSearchesPanel.organism";
import { SavedSearchPanel } from "components/organisms/SavedSearchPanel.organism";
import { getExceptionChartData } from "components/utils/exceptions.utils";
import { MediaQueries } from "components/responsive";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import { Features } from "modules/auth/Authorization";

import ShipmentEditSavedSearchModalContainer from "pages/shipments/components/search/Shipments.EditSavedSearchModal.container";
import ShipmentsSavedSearchState from "pages/shipments/redux/ShipmentsSavedSearchState";
import ShipmentsSearchBarState from "pages/shipments/redux/ShipmentsSearchBarState";
import ShipmentsDomainDataState from "modules/domain-data/ShipmentsDomainDataState";
import ShipmentSavedSearchCardDataState from "pages/shipments/redux/ShipmentSavedSearchCardDataState";
import { ShipmentsSavedSearchSubscriptionState } from "pages/shipments/redux/ShipmentsSavedSearchSubscriptionState";
import { DialogModal } from "components/molecules/DialogModal.molecule";
import {
  ShipmentExceptionType,
  getExceptionData,
  getTranslatedNameForExceptionType,
  getIconData,
} from "pages/shipments/utils/exception.utils";
import { MAX_TOTAL_COUNT_LIMIT_FOR_SAVED_SEARCH } from "shared/redux/SubscriptionStateBuilder";

const ShipmentSavedSearchCard = ({
  savedSearch,
  isDeleting,
  onEditClick,
  //Alert Me
  userSubscriptions,
  onSubscriptionClick,
  onRefreshClick,
}) => {
  const { t } = useTranslation("shipment-status");
  const dispatch = useDispatch();

  const exceptionTypes = useSelector(
    ShipmentsDomainDataState.selectors.getExceptionTypes,
  );

  const { data: exceptionTotals, isLoading } = useSelector(
    ShipmentSavedSearchCardDataState.selectors.getSavedSearchCardData(
      savedSearch.id,
    ),
  );

  useEffect(() => {
    dispatch(
      ShipmentSavedSearchCardDataState.actionCreators.fetchSavedSearchCardData(
        savedSearch,
      ),
    );
  }, [dispatch, savedSearch]);

  const handleSearchClick = () => {
    dispatch(
      ShipmentsSavedSearchState.actionCreators.loadSavedSearch(savedSearch),
    );
    dispatch(ShipmentsSearchBarState.actionCreators.searchEntities());
  };

  const handleExceptionClick = (exception) => {
    dispatch(
      ShipmentsSavedSearchState.actionCreators.loadSavedSearch({
        search: { ...savedSearch.search, active_exception: [exception.id] },
      }),
    );
    dispatch(ShipmentsSearchBarState.actionCreators.searchEntities());
  };

  const { exceptions, totalShipments } = useMemo(
    () => getExceptionData(exceptionTotals, exceptionTypes),
    [exceptionTotals, exceptionTypes],
  );

  const formattedExceptions = exceptions.map((exception) => {
    return {
      ...exception,
      name: getTranslatedNameForExceptionType(exception.type, t),
      icon: getIconData(exception.type),
    };
  });

  const mainExceptions = formattedExceptions.filter(
    (exception) => exception.type !== ShipmentExceptionType.UNDER_REVIEW,
  );

  const chartData = getExceptionChartData(
    formattedExceptions,
    totalShipments,
    formattedExceptions.reduce((acc, curr) => acc + curr.count, 0),
  );

  const savedSearchSubscribed =
    _.find(userSubscriptions, function (s) {
      return s.id === savedSearch.id;
    })?.subscribed ?? false;

  return (
    <SavedSearchPanel
      isLoading={isLoading}
      savedSearch={savedSearch}
      isDeleting={isDeleting}
      onSearchClick={handleSearchClick}
      onEditClick={onEditClick}
      //Alert Me
      onSubscriptionClick={onSubscriptionClick}
      onRefreshClick={savedSearchSubscribed ? onRefreshClick : null}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          [MediaQueries.mediumAndUp]: {
            flexDirection: "column-reverse",
            justifyContent: "between",
          },
          [MediaQueries.extraLarge]: {
            flexDirection: "row",
            justifyContent: "space-around",
          },
        }}
      >
        <ExceptionCountGroup
          title={t("shipment-status:Exceptions")}
          exceptions={mainExceptions}
          vertical
          clickHandler={handleExceptionClick}
        />
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "center",
            [MediaQueries.mediumAndUp]: {
              flexDirection: "row",
              justifyContent: "center",
            },
            [MediaQueries.extraLarge]: {
              flexDirection: "column",
              justifyContent: "start",
            },
          }}
        >
          <DonutChart
            data={chartData}
            handler={handleSearchClick}
            total={totalShipments}
          />
        </div>
      </div>
    </SavedSearchPanel>
  );
};

ShipmentSavedSearchCard.propTypes = {
  // These come from the getCardProps in ShipmentSavedSearchCards
  savedSearch: PropTypes.object,
  isDeleting: PropTypes.bool,
  onEditClick: PropTypes.func,
  //Alert Me
  userSubscriptions: PropTypes.array,
  onSubscriptionClick: PropTypes.func,
  onRefreshClick: PropTypes.func,
};

const initialShowModalState = {
  showAlert: false,
  showRefreshAlert: false,
  showEditSavedSearchModal: false,
  showRefreshSubscriptionModal: false,
};

export const ShipmentSavedSearchCards = () => {
  const { t } = useTranslation("shipment-status");

  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(initialShowModalState);
  const [currentSavedSearch, setCurrentSavedSearch] = useState(null);
  const [deletingSavedSearchId, setDeletingSavedSearchId] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  const hasSavedSearchFeature = useSelector(getAuthorization).hasFeatures([
    Features.SAVED_SEARCH_NOTIFICATION_SUBSCRIPTION,
  ]);

  const savedSearches = useSelector(
    ShipmentsSavedSearchState.selectors.getSavedSearches,
  );

  const isSavedSearchesLoading = useSelector(
    ShipmentsSavedSearchState.selectors.getIsLoading,
  );

  // read data to show spinner / count / failure status of fetchSavedSearchCardData api
  // in Edit Saved Search Update Confirmation Modal
  // And show count in Alert me Form for subscribe, update and unsubscribe api's
  const editSearchData = useSelector(
    ShipmentSavedSearchCardDataState.selectors.getSavedSearchCardData(
      currentSavedSearch?.id,
    ),
  );

  //Alert Me
  //receive subscriptions for all saved searches
  const userSubscriptions = useSelector(
    ShipmentsSavedSearchSubscriptionState.selectors.getUserSubscriptions,
  );

  //In order to show the saved search update modal
  //and send subscription details to refresh subscription api
  const currentSavedSearchSubscription = _.find(
    userSubscriptions,
    function (s) {
      return s.id === currentSavedSearch?.id;
    },
  );

  //On Refresh Modal Open - fetch the subscription count data
  const subscriptionData = useSelector(
    ShipmentsSavedSearchSubscriptionState.selectors.getSubscriptionCountData,
  );
  const isSubscriptionCountDetailsLoading =
    subscriptionData?.isLoading ?? false;
  const subscriptionCount = subscriptionData?.data?.delta_to_subscribe ?? null;
  const isSubscriptionCountDetailsLoadingError =
    subscriptionData?.isLoadingError ?? false;

  //On Refresh Modal Submit
  const isSubscriptionRefreshing = useSelector(
    ShipmentsSavedSearchSubscriptionState.selectors.getIsSubscriptionRefreshing,
  );
  const subscriptionRefreshInProgress = useSelector(
    ShipmentsSavedSearchSubscriptionState.selectors
      .getSubscriptionRefreshInProgress,
  );
  const subscriptionRefreshSuccess = useSelector(
    ShipmentsSavedSearchSubscriptionState.selectors
      .getSubscriptionRefreshSuccess,
  );
  const subscriptionRefreshError = useSelector(
    ShipmentsSavedSearchSubscriptionState.selectors.getSubscriptionRefreshError,
  );
  const onRefreshSubmit = () => {
    setShowModal({ ...showModal, showRefreshAlert: true });
    dispatch(
      ShipmentsSavedSearchSubscriptionState.actionCreators.refreshSubscription(
        currentSavedSearch,
        currentSavedSearchSubscription,
      ),
    ).then(() => {
      // fetch latest data for current saved search card after successfull refresh
      dispatch(
        ShipmentSavedSearchCardDataState.actionCreators.fetchSavedSearchCardData(
          currentSavedSearch,
        ),
      );
    });
  };

  useEffect(
    () => {
      dispatch(ShipmentsSavedSearchState.actionCreators.fetchSavedSearches());

      if (hasSavedSearchFeature) {
        //fetch user level subscriptions which contains data for all saved searches
        dispatch(
          ShipmentsSavedSearchSubscriptionState.actionCreators.fetchUserSubscriptions(),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Fragment>
      <SavedSearchesPanel
        savedSearches={savedSearches}
        isLoading={isSavedSearchesLoading}
        onAddSavedSearch={() => {
          setShowModal({ ...showModal, showEditSavedSearchModal: true });
        }}
        CardComponent={ShipmentSavedSearchCard}
        getCardProps={(savedSearch) => {
          return {
            savedSearch,
            isDeleting: deletingSavedSearchId === savedSearch.id,
            onEditClick: () => {
              dispatch(
                ShipmentsSavedSearchState.actionCreators.loadSavedSearch(
                  savedSearch,
                  true,
                ),
              );
              setCurrentSavedSearch(savedSearch);
              setShowModal({ ...showModal, showEditSavedSearchModal: true });
              setTabIndex(0);
            },
            userSubscriptions,
            onSubscriptionClick: hasSavedSearchFeature
              ? () => {
                  dispatch(
                    ShipmentsSavedSearchState.actionCreators.loadSavedSearch(
                      savedSearch,
                      true,
                    ),
                  );
                  setCurrentSavedSearch(savedSearch);
                  setShowModal({
                    ...showModal,
                    showEditSavedSearchModal: true,
                  });
                  setTabIndex(1);
                }
              : null,
            onRefreshClick: hasSavedSearchFeature
              ? () => {
                  setCurrentSavedSearch(savedSearch);
                  setShowModal({
                    ...showModal,
                    showRefreshSubscriptionModal: true,
                    showAlert: true,
                  });

                  dispatch(
                    ShipmentsSavedSearchSubscriptionState.actionCreators.fetchUserSubscriptionsCount(
                      savedSearch,
                    ),
                  );
                }
              : null,
          };
        }}
      />
      <ShipmentEditSavedSearchModalContainer
        savedSearch={currentSavedSearch}
        show={showModal.showEditSavedSearchModal}
        hide={() => {
          dispatch(
            ShipmentsSearchBarState.actionCreators.resetSearchAndFilters(true),
          );
          setShowModal({ ...showModal, showEditSavedSearchModal: false });
          setCurrentSavedSearch(null);
        }}
        deleteSearch={(id) => {
          setDeletingSavedSearchId(id);
          return dispatch(
            ShipmentsSavedSearchState.actionCreators.deleteSearch(id),
          );
        }}
        //Alert Me
        showSubscriptionTab={hasSavedSearchFeature}
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        editSearchData={editSearchData}
        isCurrentSavedSearchSubscribed={
          currentSavedSearchSubscription?.subscribed ?? false
        }
      />
      <DialogModal
        show={showModal.showRefreshSubscriptionModal}
        onHide={() => {
          setShowModal({
            ...showModal,
            showAlert: false,
            showRefreshAlert: false,
            showRefreshSubscriptionModal: false,
          });
          dispatch(
            ShipmentsSavedSearchSubscriptionState.actionCreators.clearRefreshSubscription(),
          );
        }}
        title={t("shipment-status:Confirm Subscription")}
        closeOnBlur={false}
        cancelButtonText={t("shipment-status:Cancel")}
        submitButtonText={t("shipment-status:Subscribe")}
        submitButtonVariant="primary"
        onSubmit={onRefreshSubmit}
        isSubmitting={isSubscriptionRefreshing}
        submitButtonDisabled={
          isSubscriptionCountDetailsLoading ||
          isSubscriptionCountDetailsLoadingError ||
          subscriptionCount === 0 ||
          subscriptionCount > MAX_TOTAL_COUNT_LIMIT_FOR_SAVED_SEARCH ||
          subscriptionRefreshSuccess
        }
      >
        <Loader loaded={!isSubscriptionCountDetailsLoading} scale={0.5}>
          {!isSubscriptionCountDetailsLoadingError
            ? subscriptionCount === 0
              ? t("shipment-status:There are no new shipments to subscribe.")
              : subscriptionCount > MAX_TOTAL_COUNT_LIMIT_FOR_SAVED_SEARCH
              ? t(
                  "shipment-status:Your saved search contains [[[count]]] shipments, which is more than the maximum allowed of [[[maxCount]]] shipments. Please create a smaller saved search and try again.",
                  {
                    count: subscriptionCount,
                    maxCount: MAX_TOTAL_COUNT_LIMIT_FOR_SAVED_SEARCH,
                  },
                )
              : t(
                  "shipment-status:Are you sure you want to subscribe to [[[count]]] shipments?",
                  {
                    count: subscriptionCount,
                  },
                )
            : null}
        </Loader>
        {isSubscriptionCountDetailsLoadingError ? (
          <Alert
            show={showModal.showAlert}
            variant={AlertVariant.Danger}
            onClose={() => setShowModal({ ...showModal, showAlert: false })}
            dismissible
            css={{ margin: "1rem 0 0.5rem", paddingRight: "2.5rem" }}
          >
            <Text>
              {t(
                "shipment-status:There was an error when fetching your Subscription Count. Please try again later.",
              )}
            </Text>
          </Alert>
        ) : null}

        {subscriptionRefreshSuccess ? (
          <Alert
            show={showModal.showRefreshAlert}
            variant={AlertVariant.Success}
            onClose={() =>
              setShowModal({ ...showModal, showRefreshAlert: false })
            }
            dismissible
            css={{ margin: "1rem 0 0.5rem", paddingRight: "2.5rem" }}
          >
            <Text>
              {t("shipment-status:Your alert preferences have been updated.")}
            </Text>
          </Alert>
        ) : null}

        {subscriptionRefreshInProgress ? (
          <Alert
            show={showModal.showRefreshAlert}
            variant={AlertVariant.Warning}
            onClose={() =>
              setShowModal({ ...showModal, showRefreshAlert: false })
            }
            dismissible
            css={{ margin: "1rem 0 0.5rem", paddingRight: "2.5rem" }}
          >
            <Text>
              {t(
                "shipment-status:Your alert preferences update is processing. Please review the shipments after a few minutes.",
              )}
            </Text>
          </Alert>
        ) : null}

        {subscriptionRefreshError && !subscriptionRefreshInProgress ? (
          <Alert
            show={showModal.showRefreshAlert}
            variant={AlertVariant.Danger}
            onClose={() =>
              setShowModal({ ...showModal, showRefreshAlert: false })
            }
            dismissible
            css={{ margin: "1rem 0 0.5rem", paddingRight: "2.5rem" }}
          >
            <Text>
              {t(
                "shipment-status:There was an error when updating your alert preferences. Please try again later.",
              )}
            </Text>
          </Alert>
        ) : null}
      </DialogModal>
    </Fragment>
  );
};
