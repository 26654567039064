import _ from "lodash";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Roles } from "modules/auth/Authorization";
import { logWarningForMissingTranslation } from "utils/log-warning.utils";

export const useUsersTranslation = () => {
  const { t } = useTranslation("users");

  const getTranslatedFriendlyName = useCallback(
    (roleName: string) => {
      switch (roleName) {
        case Roles.CARRIER_SYSTEM:
          return t("users:Carrier System");
        case Roles.CONTAINER_TRACKING_ADMIN:
          return t("users:Container Tracking Admin");
        case Roles.CONTAINER_TRACKING_USER:
          return t("users:Container Tracking User");
        case Roles.EQUIPMENT_USER:
          return t("users:Equipment User");
        case Roles.FINISHED_VEHICLE_INV:
          return t("users:Finished Vehicle InventoryView");
        case Roles.FINISHED_VEHICLE_SYSTEM:
          return t("users:Finished Vehicle System");
        case Roles.FINISHED_VEHICLE_USER:
          return t("users:Finished Vehicle User");
        case Roles.FREIGHTVERIFY_ADMIN:
          return t("users:FreightVerify Admin");
        case Roles.ORGANIZATION_ADMIN:
          return t("users:Organization Admin");
        case Roles.ORGANIZATION_REPORTING_USER:
          return t("users:Organization Reporting User");
        case Roles.ORGANIZATION_USER:
          return t("users:Organization User");
        case Roles.PARTVIEW_USER:
          return t("users:PartView User");
        case Roles.PARTVIEW_SELLER:
          return t("users:PartView Seller");
        case Roles.PLANT_ASSET_TRACKING_USER:
          return t("users:Plant Asset Tracking User");
        case Roles.REPORT_BUILDER_USER:
          return t("users:Report Builder User");
        case Roles.SCAC_ADMIN:
          return t("users:Scac Admin");
        case Roles.THIRD_PARTY_SYSTEM:
          return t("users:Third Party System");
        case Roles.HEALTHCARE_TRACKING_USER:
          return t("users:Healthcare Tracking User");
        case Roles.HEALTHCARE_TRACKING_ADMIN:
          return t("users:Healthcare Tracking Admin");
        default: {
          logWarningForMissingTranslation(roleName);
          // Default is the role name from the API formatted to look like other labels.
          // Split on "_" to get each word in the role name, capitalize each word, and
          // join with spaces. e.g. "ORGANIZATION_USER" -> "Organization User"
          return roleName.split("_").map(_.capitalize).join(" ");
        }
      }
    },
    [t],
  );

  const getTranslatedStatus = useCallback(
    (status: string) => {
      switch (status) {
        case "Active":
          return t("users:Active");
        case "Disabled":
          return t("users:Disabled");
        case "Invitation Expired":
          return t("users:Invitation Expired");
        default: {
          logWarningForMissingTranslation(status);
          return status;
        }
      }
    },
    [t],
  );

  return {
    getTranslatedFriendlyName,
    getTranslatedStatus,
  };
};
