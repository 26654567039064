/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import { useEffect, useState, useRef, Fragment } from "react";

import Loader from "react-loader";
import { useTranslation } from "react-i18next";
import { faCheckCircle, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Modal } from "components/molecules/Modal.molecule";
import {
  SelectInput,
  TextInput,
  TimePickerInput,
  PhoneInput,
} from "components-old/modal-elems";
import { AsyncLocationSelect } from "../molecules/AsyncLocationSelect.molecule";
import Colors from "styles/colors";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Icon } from "components/atoms/Icon.atom";
import { Button } from "components/atoms/Button.atom";
import { DialogModal } from "components/molecules/DialogModal.molecule";
import { Alert } from "components/atoms/Alert.atom";
import { ShipmentDwellModalMode } from "../../ShipmentDwellNotificationSearch.page";
import { buildSearchLocationOptions } from "pages/administration/location-management/locations/search/LocationSearchStateExtensions";

const ALERT_LEVEL_OPTIONS = [
  { label: "Level 1", value: 1 },
  { label: "Level 2", value: 2 },
  { label: "Level 3", value: 3 },
];

export const EditShipmentDwellModal = ({
  mode,
  show,
  hide,
  rowData,
  fetchPrefillData,
  clearPrefillData,
  clearModifyDwellNotification,
  isPrefillDataLoading,
  recipients: recipientsFromProps,
  location: locationFromProps,
  modifyDwellNotification,
  isModifyDwellNotificationLoading,
  modifyDwellResponse,
  searchEntities,
}) => {
  const { t } = useTranslation("shipment-dwell");

  const [recipients, setRecipients] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();
  const selectedLocationRef = useRef();
  const [showDialog, setShowDialog] = useState(false);
  let addRecipientButtonRef = useRef(null);

  // Clear forms
  useEffect(() => {
    if (!show) {
      setSelectedLocation(undefined);
      setRecipients([]);
    }
  }, [show]);

  useEffect(() => {
    // Clear form if we have a successful submission, but only for newly created notifications.
    // For existing ones, just keep the screen up as it is.
    if (
      modifyDwellResponse?.status === 200 &&
      mode === ShipmentDwellModalMode.CREATE
    ) {
      selectedLocationRef.current = null;
      setSelectedLocation(selectedLocationRef.current);
      setRecipients([]);
    }
  }, [modifyDwellResponse, mode]);

  // Load prefill data
  useEffect(() => {
    // Clear error/success messages
    clearModifyDwellNotification();

    if (mode === ShipmentDwellModalMode.UPDATE) {
      if (!rowData) {
        return;
      }

      if (show) {
        const { loc_code } = rowData;
        fetchPrefillData(loc_code);
      }
    } else if (mode === ShipmentDwellModalMode.CREATE) {
      clearPrefillData();
    }
  }, [
    mode,
    show,
    fetchPrefillData,
    clearPrefillData,
    clearModifyDwellNotification,
    rowData,
  ]);

  useEffect(() => {
    if (locationFromProps) {
      const formatted = buildSearchLocationOptions([locationFromProps])[0];
      setSelectedLocation(formatted);
      selectedLocationRef.current = formatted;
    }
  }, [locationFromProps]);

  useEffect(() => {
    setRecipients(recipientsFromProps ?? []);
  }, [recipientsFromProps]);

  useEffect(() => {
    scrollToBottom();
  }, [recipients]);

  // Helpers for modifying recipients

  const scrollToBottom = () => {
    if (addRecipientButtonRef.current) {
      addRecipientButtonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const addRecipient = () => {
    setRecipients([...recipients, {}]);
  };

  const deleteRecipient = (indexToRemove) => {
    const next = [...recipients];
    const id = next[indexToRemove]?.id;

    // If it has an id, need to mark it for deletion.
    // else, remove it from the list.
    if (Number.isInteger(id)) {
      next[indexToRemove] = {
        id: id,
        status: "deleted",
      };
    } else {
      next.splice(indexToRemove, 1);
    }

    setRecipients(next);
  };

  const updateRecipient = (index, field, value) => {
    // Clear error/success messages when changing recipient data
    clearModifyDwellNotification();

    const next = [...recipients];
    const recipient = next[index];

    if (recipient) {
      next[index] = {
        ...recipient,
        [field]: value,
        // If it has an id, this is an update. Else, this is a create.
        status: Number.isInteger(recipient.id) ? "updated" : "created",
      };

      setRecipients(next);
    }
  };

  const handleLocationChange = (value) => {
    // Clear error/success messages when changing the location
    clearModifyDwellNotification();

    // Keep a reference to the selected value to set later.
    selectedLocationRef.current = value;

    // If already selected, confirm change with the dialog.
    // else, set the location and fetch prefill data.
    if (selectedLocation) {
      setShowDialog(true);
    } else {
      setSelectedLocation(selectedLocationRef.current);
      fetchPrefillData(selectedLocationRef.current.code);
    }
  };

  const recipientsWithInvalidFields = recipients
    // Ignore deleted recipients, as they can't be "invalid".
    .filter(
      (recipient) =>
        recipient.status !== "deleted" &&
        getInvalidFields(recipient).length > 0,
    );
  const isAllValid = recipientsWithInvalidFields.length === 0;

  const isSubmitting = isModifyDwellNotificationLoading;
  const isEditable = !isSubmitting && !isPrefillDataLoading;
  const canSubmit =
    isEditable && selectedLocation && recipients.length > 0 && isAllValid;
  let rowNumber = 0;

  return (
    <Modal
      size="xl"
      backdrop="static"
      show={show}
      onHide={() => {
        hide();
      }}
    >
      <Modal.Header
        title={
          mode === ShipmentDwellModalMode.CREATE
            ? t("shipment-dwell:Add Dwell Notification")
            : t("shipment-dwell:Edit Dwell Notification")
        }
      />
      <Modal.Body css={{ maxHeight: "70vh", overflowY: "auto" }}>
        {/* Confimation dialog when changing locations */}
        <DialogModal
          show={showDialog}
          submitButtonText={t("shipment-dwell:Yes")}
          cancelButtonText={t("shipment-dwell:No")}
          // User decided to change location.
          onSubmit={() => {
            // Update the selected location and fetch prefill data.
            setSelectedLocation(selectedLocationRef.current);
            fetchPrefillData(selectedLocationRef.current.code);
            setShowDialog(false);
          }}
          // User decided not to change location.
          onHide={() => {
            // Reset the ref to the last value.
            selectedLocationRef.current = selectedLocation;
            setShowDialog(false);
          }}
        >
          <Text block>
            {t(
              "shipment-dwell:Any unsaved changes will be lost. Would you like to continue?",
            )}
          </Text>
        </DialogModal>

        {/* Form */}
        <div css={{ marginBottom: 10 }}>
          <Text block size={FontSize.size16} color={Colors.text.DARK_GRAY}>
            {t("shipment-dwell:Location")}
          </Text>
          <div css={{ paddingTop: "0.25rem", width: "50%" }}>
            {mode === ShipmentDwellModalMode.UPDATE ? (
              <Text size={FontSize.size20} bold>
                {selectedLocation?.label}
              </Text>
            ) : (
              <AsyncLocationSelect
                value={selectedLocation}
                onChange={handleLocationChange}
                disabled={!isEditable}
                customStyles={{
                  menuList: (base) => ({
                    ...base,
                    maxHeight: "150px", // your desired height
                  }),
                }}
              />
            )}
          </div>
        </div>
        <hr />
        <div css={{ position: "relative" }}>
          <Loader
            loaded={!isPrefillDataLoading}
            scale={0.75}
            top="50%"
            left="50%"
          />
          <Text
            size={FontSize.size16}
            color={Colors.text.DARK_GRAY}
            block
            css={{ marginBottom: "1em" }}
          >
            {t("shipment-dwell:Notification Recipients")}
          </Text>
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "1em",
              minHeight: "3em",
            }}
          >
            {isEditable && recipients.length === 0 ? (
              <Text color={Colors.text.GRAY} align="center">
                {!selectedLocation
                  ? t(
                      "shipment-dwell:Select a location above before adding recipients.",
                    )
                  : t(
                      'shipment-dwell:Click "Add Recipient" to add a dwell notification recipeient for this location.',
                    )}
              </Text>
            ) : null}
            {recipients?.map((recipient, index) => {
              // We don't want to display deleted recipients, but don't want to mess with the indexing,
              // so we skip it here.
              if (recipient.status === "deleted") {
                return null;
              } else {
                ++rowNumber;
              }

              const name = recipient.name;
              const phoneNumber = recipient.phoneNumber;
              const alertLevel = recipient.alertLevel;
              const startTime = recipient.startTime;
              const endTime = recipient.endTime;

              const getHelpTextLevelListItem = (num, minutes) => {
                return t(
                  "shipment-dwell:Level [[[num]]] = [[[minutes]]] minutes",
                  { num, minutes },
                );
              };

              return (
                <div
                  key={index}
                  css={{
                    display: "flex",
                    gap: "0.5em",
                    alignItems: "flex-end",
                  }}
                >
                  <Text size={FontSize.size16} css={{ lineHeight: 2.25 }}>
                    {rowNumber}
                  </Text>
                  <TextInput
                    label={t("shipment-dwell:Name")}
                    style={{ flexGrow: 1 }}
                    value={name}
                    prop="name"
                    changeHandler={(value) => {
                      updateRecipient(index, "name", value?.name ?? "");
                    }}
                    disabled={!isEditable}
                  />
                  <PhoneInput
                    label={t("shipment-dwell:Phone Number")}
                    style={{ flexGrow: 1 }}
                    value={phoneNumber}
                    prop="phoneNumber"
                    changeHandler={(value) => {
                      updateRecipient(
                        index,
                        "phoneNumber",
                        value?.phoneNumber ?? "",
                      );
                    }}
                    disabled={!isEditable}
                    helpText={t(
                      "shipment-dwell:The phone number of the recipeient including the country code.",
                    )}
                  />
                  <SelectInput
                    label={t("shipment-dwell:Alert Level")}
                    style={{ flexGrow: 1 }}
                    multi={false}
                    options={ALERT_LEVEL_OPTIONS}
                    value={ALERT_LEVEL_OPTIONS.find(
                      (option) => option.value === alertLevel,
                    )}
                    prop="alertLevel"
                    changeHandler={(value) => {
                      updateRecipient(
                        index,
                        "alertLevel",
                        value?.alertLevel?.value ?? undefined,
                      );
                    }}
                    disabled={!isEditable}
                    helpText={
                      <Fragment>
                        {t(
                          "shipment-dwell:Configure dwell time before receiving a notification.",
                        )}
                        <ul css={{ textAlign: "left", margin: 0 }}>
                          <li>{getHelpTextLevelListItem(1, 15)}</li>
                          <li>{getHelpTextLevelListItem(2, 45)}</li>
                          <li>{getHelpTextLevelListItem(3, 75)}</li>
                        </ul>
                      </Fragment>
                    }
                  />
                  <TimePickerInput
                    label={t("shipment-dwell:Shift Start Time")}
                    style={{ flex: "0 0 170px" }}
                    value={startTime}
                    prop="startTime"
                    changeHandler={(value) => {
                      updateRecipient(
                        index,
                        "startTime",
                        value?.startTime ?? "",
                      );
                    }}
                    disabled={!isEditable}
                    helpText={t(
                      "shipment-dwell:Choose a shift start time for the time period for individual to start receiving dwell alerts",
                    )}
                  />
                  <TimePickerInput
                    label={t("shipment-dwell:Shift End Time")}
                    style={{ flex: "0 0 170px" }}
                    value={endTime}
                    prop="endTime"
                    changeHandler={(value) => {
                      updateRecipient(index, "endTime", value?.endTime ?? "");
                    }}
                    disabled={!isEditable}
                    helpText={t(
                      "shipment-dwell:Choose a shift end time for the time period for individual to stop receiving dwell alerts",
                    )}
                  />
                  <Button
                    size="md"
                    variant="danger"
                    onClick={() => {
                      deleteRecipient(index);
                    }}
                    disabled={!isEditable}
                  >
                    {t("shipment-dwell:Delete")}
                  </Button>
                </div>
              );
            })}
          </div>

          {/* Add Recipient button */}
          <div
            css={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "2em",
            }}
          >
            <Button
              variant="primary"
              onClick={() => addRecipient()}
              disabled={!isEditable || !selectedLocation}
              ref={addRecipientButtonRef}
            >
              <Icon src={faPlus} /> {t("shipment-dwell:Add Recipient")}
            </Button>
          </div>
        </div>

        {/* Alert on submisson error */}
        <Alert
          show={modifyDwellResponse?.isLoadingError ?? false}
          variant="danger"
          css={{ margin: "1em 0 0 0" }}
        >
          <Text>
            {t(
              "shipment-dwell:Something went wrong. Please contact FreightVerify.",
            )}
          </Text>
        </Alert>
        <Alert
          show={modifyDwellResponse?.status === 200}
          variant="success"
          css={{ margin: "1em 0 0 0" }}
        >
          <Text>
            {mode === ShipmentDwellModalMode.CREATE
              ? t("shipment-dwell:Successfully submitted recipients.")
              : t("shipment-dwell:Successfully updated recipients.")}
          </Text>
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        {recipients.length > 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: ".25em",
              width: "100%",
            }}
          >
            <Icon
              style={{
                color:
                  recipientsWithInvalidFields.length === 0
                    ? Colors.highlight.GREEN
                    : Colors.highlight.MEDIUM_LIGHT_GRAY,
                fontSize: "large",
                marginRight: ".5em",
                marginTop: ".1em",
              }}
              src={faCheckCircle}
            />
            <Text size={FontSize.size12}>
              {recipientsWithInvalidFields.length > 0
                ? t("shipment-dwell:One or more Recipients have invalid fields")
                : t("shipment-dwell:All Recipients have valid fields")}
            </Text>
          </div>
        ) : null}

        <Button
          variant="light"
          onClick={() => {
            hide();
          }}
          disabled={isSubmitting}
        >
          {modifyDwellResponse?.status === 200
            ? t("shipment-dwell:Close")
            : t("shipment-dwell:Cancel")}
        </Button>
        <Button
          variant="success"
          onClick={() => {
            const locationCode = selectedLocation.code;

            modifyDwellNotification(locationCode, recipients).then(() => {
              // Force search page to re-search to see updated values
              searchEntities();
            });
          }}
          disabled={!canSubmit}
        >
          {isSubmitting
            ? mode === ShipmentDwellModalMode.UPDATE
              ? t("shipment-dwell:Updating...")
              : t("shipment-dwell:Saving...")
            : mode === ShipmentDwellModalMode.UPDATE
            ? t("shipment-dwell:Update")
            : t("shipment-dwell:Save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

EditShipmentDwellModal.propTypes = {
  mode: PropTypes.string, // From ShipmentDwellModalMode
  show: PropTypes.bool,
  hide: PropTypes.func.isRequired,
  rowData: PropTypes.object,
  fetchPrefillData: PropTypes.func.isRequired,
  clearPrefillData: PropTypes.func.isRequired,
  clearModifyDwellNotification: PropTypes.func.isRequired,
  isPrefillDataLoading: PropTypes.bool,
  recipients: PropTypes.arrayOf(PropTypes.object),
  location: PropTypes.object,
  modifyDwellNotification: PropTypes.func.isRequired,
  isModifyDwellNotificationLoading: PropTypes.bool,
  modifyDwellResponse: PropTypes.shape({
    isLoadingError: PropTypes.bool,
    loadingError: PropTypes.any,
    status: PropTypes.any,
  }),
  searchEntities: PropTypes.func.isRequired,
};

const getInvalidFields = (recipient) => {
  const invalidFields = [];

  const isNameValid = !_.isNil(recipient?.name) && !_.isEmpty(recipient.name);
  if (!isNameValid) {
    invalidFields.push("name");
  }

  // Using Number constructor to convert because it will attempt to parse the entire string.
  // e.g. `parseInt("1234abc", 10)` will return `1234`, but `Number("1234abc")` will return `NaN`.
  const isPhoneNumberValid =
    !isNaN(Number(recipient?.phoneNumber)) &&
    !_.isEmpty(recipient?.phoneNumber) &&
    recipient?.phoneNumber?.length >= 10;
  if (!isPhoneNumberValid) {
    invalidFields.push("phoneNumber");
  }

  const isAlertLevelValid = !_.isNil(recipient?.alertLevel);
  if (!isAlertLevelValid) {
    invalidFields.push("alertLevel");
  }

  const isStartTimeValid =
    !_.isNil(recipient?.startTime) && !_.isEmpty(recipient.startTime);
  if (!isStartTimeValid) {
    invalidFields.push("startTime");
  }

  const isEndTimeValid =
    !_.isNil(recipient?.endTime) && !_.isEmpty(recipient.endTime);
  if (!isEndTimeValid) {
    invalidFields.push("endTime");
  }

  return invalidFields;
};
