import { connect } from "react-redux";

import ConnectedCarView from "./ConnectedCar.Dashboard.page";
import ConnectedCarSearchBarState, {
  RECORD_LIMIT,
} from "./redux/ConnectedCarSearchBarState";
import MapState from "modules/map/MapState";
import { getSolutionId } from "../../modules/organizations/OrganizationsState";

function mapStateToProps(state) {
  const {
    getSearchResults,
    getTotalEntities,
    getIsLoadingError,
    getIsLoading,
    getIsExporting,
    getClickedSearchOrEnter,
    getAreThereFiltersSelected,
    getExportFailed,
  } = ConnectedCarSearchBarState.selectors;
  return {
    ...state.endToEnd,
    connectedCarEntities: getSearchResults(state),
    totalEntities: getTotalEntities(state),
    isLoading: getIsLoading(state),
    isLoadingError: getIsLoadingError(state),
    isExporting: getIsExporting(state),
    exportIdentifier:
      ConnectedCarSearchBarState.selectors.getExportIdentifier(state),
    exportName: ConnectedCarSearchBarState.selectors.getExportName(state),
    solutionId: getSolutionId(state),
    exportFailed: getExportFailed(state),
    clickedSearchOrEnter: getClickedSearchOrEnter(state),
    areThereFiltersSelected: getAreThereFiltersSelected(state),
  };
}

function mapDispatchToProps(dispatch) {
  const {
    setPagination,
    clickedSearchOrEnter,
    resetSearchBar,
    clearSearchFilters,
    clearEntities,
    resetExport,
    exportSearch,
  } = ConnectedCarSearchBarState.actionCreators;
  return {
    setPagination: (solutionId) => {
      // Set the pageSize to 10,000 to ensure that all VINs are displayed on the map
      dispatch(setPagination(solutionId, 0, RECORD_LIMIT, true));
    },
    pushVinDetailsScreen: (entityId) => {
      dispatch({ type: "VIN_DETAILS", payload: { entity_id: entityId } });
    },
    resetClickedSearchOrEnter: () => {
      dispatch(clickedSearchOrEnter(false));
    },
    resetSearchBar: () => dispatch(resetSearchBar()),
    clearSearchFilters: () => dispatch(clearSearchFilters()),
    clearEntities: () => dispatch(clearEntities()),
    setViewCentered: (isCentered) =>
      dispatch(MapState.actionCreators.setViewCentered(isCentered)),
    resetExport: () => dispatch(resetExport()),
    exportSearch: (solutionId) => dispatch(exportSearch(solutionId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedCarView);
