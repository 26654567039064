import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import customerApiUrl from "api-url";

const fetchPart = async (locationId, partNumber) => {
  if (!locationId) {
    return null;
  }
  const url = customerApiUrl(
    "critical-parts/location-inventory/" + locationId + "/parts" + partNumber,
  );

  try {
    const response = await axios.get(url);

    const data = response.data;

    return data;
  } catch (error) {
    return null;
  }
};

export const useCriticalPartsDetails = (locationId, partNumber) => {
  const {
    isFetching: isFetchingDetails,
    data: criticalPartsDetails = null,
    refetch,
  } = useQuery({
    queryKey: ["critical-parts", locationId, partNumber],
    enabled: true,
    queryFn: () => fetchPart(locationId, partNumber),
  });
  return { isFetchingDetails, criticalPartsDetails, refetch };
};
