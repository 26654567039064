import apiUrl from "api-url";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import { FilterNameEnum } from "pages/containertracking/utils/enum.utils";

const STORE_MOUNT_POINT = "sensorOverview";
const duck = buildFetchDuck(STORE_MOUNT_POINT);

/**
 * Given a property name, this function knows how to get its related data from
 * the fv domain mount point in the store.
 *
 * @param string property: property you want to get from the state.
 * @param string sort: key you want to sort on the return.
 * @param boolean sort: if there is no key, a true value will mean: sort it for
 * me.
 *
 * It avoids code duplication to create selectors based on the same data
 * source.
 */
export const fetchDomainData = () => {
  const url = apiUrl(`/containertracking/api/filtervalues`);

  let transform = (data) => {
    let sensorOptions = data?.data;
    return sensorOptions.map((item) => {
      return {
        label: item.filter_desc,
        value: item.filter_value,
      };
    });
  };
  return duck.fetch(
    url,
    {
      params: {
        filter_name: FilterNameEnum.CONTAINER_TRACKING_SENSOR_STATUS,
      },
    },
    transform,
  );
};

export const SensorOverviewDomainDataState = {
  mountPoint: STORE_MOUNT_POINT,
  reducer: duck.reducer,
  actionCreators: {
    fetchDomainData,
  },
  selectors: {
    getSensorStatusOptions: duck.selectors.getData,
  },
};
