/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Search } from "components/templates/Search.template";
import SensorOverviewSearchBarContainer from "pages/containertracking/search/SensorOverview/SensorOverviewSearchBarContainer";
import SensorOverviewFilterSectionContainer from "pages/containertracking/search/SensorOverview/SensorOverviewSearchFiltersContainer";
import { useColumns } from "pages/containertracking/search/SensorOverview/SensorOverview.Search.columns";

import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import {
  useSetDescriptionOnMount,
  useSetTitleOnMount,
} from "components/hooks/useSetTitle";

const SearchResultsTotalCountLabel = () => {
  return (
    <Trans ns="container-tracking">
      <Search.TotalCount /> Total Containers
    </Trans>
  );
};

const SensorOverviewSearchPage = ({
  searchResults,
  isLoading,
  totalCount,
  totalEntitiesIsLoading,
  resetSearch,
  showFilters,
  toggleShowFilters,
  searchEntities,
  exportIdentifier,
  exportName,
  exportFailed,
  exportEntities,
  isExporting,
  page,
  pageSize,
  totalPages,
  sortColumn,
  reverseSort,
  setSort,
  setPagination,
  clearExportErrors,
  resetExport,
}) => {
  const { t } = useTranslation("container-tracking");

  useSetTitleOnMount(t("container-tracking:Sensor Overview"));

  useSetDescriptionOnMount(
    t(
      "container-tracking:This section is a Health Overview outlining the latest reading received from Sensor Data Providers.",
    ),
  );

  useTrackWithMixpanelOnce("Viewed Page: Sensor Overview / Dashboard");

  useEffect(() => {
    searchEntities();
  }, [searchEntities]);

  const columns = useColumns();

  return (
    <Search
      isLoading={isLoading}
      searchResults={searchResults}
      totalCount={totalCount}
      totalCountIsLoading={totalEntitiesIsLoading}
      hideSavedSearch={true}
      resetSearch={resetSearch}
      showFilters={showFilters}
      toggleShowFilters={toggleShowFilters}
      SearchBarContainer={SensorOverviewSearchBarContainer}
      FiltersContainer={SensorOverviewFilterSectionContainer}
      TotalCountLabel={SearchResultsTotalCountLabel}
      exportProps={{
        exportEntities: exportEntities,
        exportIdentifier: exportIdentifier,
        exportName: exportName,
        isExporting: isExporting,
        exportFailed: exportFailed,
        clearExportErrors: clearExportErrors,
        resetExport: resetExport,
      }}
      tableProps={{
        data: searchResults,
        columns: columns,
        isLoading: isLoading,
        showPagination: true,
        fixPaginationToBottom: true,
        isManualPagination: true,
        isManualSorting: true,
        sortColumn: sortColumn,
        reverseSort: reverseSort,
        pageIndex: page,
        pageSize: pageSize,
        pageCount: totalPages,
        onPageChange: (newPage) => {
          setPagination(newPage, pageSize);
        },
        onPageSizeChange: (newPageSize) => {
          setPagination(0, newPageSize);
        },
        onPageSort: (column, isReverse) => {
          setSort(column, isReverse);
        },
      }}
    />
  );
};

SensorOverviewSearchPage.propTypes = {
  searchResults: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  totalCount: PropTypes.PropTypes.number.isRequired,
  totalEntitiesIsLoading: PropTypes.bool.isRequired,
  showFilters: PropTypes.bool,
  toggleShowFilters: PropTypes.func.isRequired,
  clearExportErrors: PropTypes.func,
  resetExport: PropTypes.func,
  resetSearch: PropTypes.func.isRequired,
  searchEntities: PropTypes.func.isRequired,
  exportIdentifier: PropTypes.string,
  exportName: PropTypes.string,
  exportFailed: PropTypes.bool,
  exportEntities: PropTypes.func.isRequired,
  isExporting: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  sortColumn: PropTypes.string,
  reverseSort: PropTypes.bool,
  setSort: PropTypes.func.isRequired,
  setPagination: PropTypes.func.isRequired,
};

export default SensorOverviewSearchPage;
