/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FormControl, ButtonToolbar, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LadChicletCSS as Chiclet } from "components/chiclets";
import Colors from "styles/colors";
import { getLadName, useLadsTranslation } from "shared/utils/lads.utils";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import { Privileges } from "modules/auth/Authorization";
import { BaseTable } from "components/organisms/base-table/BaseTable.organism";

const CHICLET_HEIGHT = 60;

const LocationTypeCellRenderer = (props) => {
  const { t } = useTranslation("lads");
  const { getTranslatedLadName } = useLadsTranslation();
  const { updateLadLabel, ...lad } = props.value;
  const originalLabel = getLadName(lad);
  const { default_name: defaultLabel } = lad;
  const [currentLabel, setCurrentLabel] = useState(originalLabel);
  const isDirty = originalLabel !== currentLabel;
  const authorization = useSelector(getAuthorization);
  const readOnly = !authorization.hasPrivileges([
    Privileges.MANAGE_SHIPPER_LOCATIONS,
  ]);

  useEffect(() => {
    setCurrentLabel(originalLabel);
  }, [originalLabel]);

  const handleChange = (event) => {
    const newLabel = event.target.value;
    setCurrentLabel(newLabel);
  };

  const handleSave = () => {
    updateLadLabel(lad, currentLabel);
  };

  const handleCancel = () => {
    setCurrentLabel(originalLabel);
  };

  return (
    <div css={{ display: "flex", flexDirection: "row" }}>
      <div css={{ marginLeft: "1em" }}>
        <Chiclet
          lad={lad}
          height={CHICLET_HEIGHT}
          width={CHICLET_HEIGHT}
          showLadLabel
        />
      </div>
      <div>
        <FormControl
          disabled={readOnly}
          onChange={handleChange}
          value={isDirty ? currentLabel : getTranslatedLadName(currentLabel)}
          style={{
            height: `${CHICLET_HEIGHT}px`,
            width: "100%",
            borderRadius: "2px",
            fontSize: "1.2em",
          }}
        />
        <div>
          <p css={{ paddingTop: "0.25rem" }}>
            <em>{t("lads:Default")}:</em>{" "}
            <strong>{t(`lads:${getTranslatedLadName(defaultLabel)}`)}</strong>
          </p>
          {isDirty && (
            <ButtonToolbar>
              <Button size="xs" onClick={handleCancel}>
                {t("lads:Cancel")}
              </Button>
              <Button size="xs" variant="success" onClick={handleSave}>
                {t("lads:Save")}
              </Button>
            </ButtonToolbar>
          )}
        </div>
      </div>
    </div>
  );
};

LocationTypeCellRenderer.propTypes = {
  value: PropTypes.object,
};

const LadDefinitionCellRenderer = ({ row, value }) => {
  const { getTranslatedLadDefinition } = useLadsTranslation();
  const color = Colors.editLadsLabelText;

  return (
    <div css={{ color: { color }, whiteSpace: "normal", paddingLeft: "1em" }}>
      {getTranslatedLadDefinition(value, row.original.description)}
    </div>
  );
};

LadDefinitionCellRenderer.propTypes = {
  value: PropTypes.string,
};

const connectedLadEditColumns = (t, updateLadLabel) => {
  return [
    {
      Header: t("lads:Location types"),
      id: "code-name-chiclet",
      accessor: (d) => ({
        updateLadLabel,
        ...d,
      }),
      Cell: LocationTypeCellRenderer,
    },

    {
      Header: t("lads:Definition"),
      accessor: (d) => getLadName(d),
      Cell: LadDefinitionCellRenderer,
    },
  ];
};

const LadTable = ({ data, updateLadLabel }) => {
  const { t } = useTranslation("lads");
  const columns = connectedLadEditColumns(t, updateLadLabel);

  return (
    <div className="p-2">
      <BaseTable
        data={data ?? []}
        columns={columns}
        showPagination={true}
        fixPaginationToBottom={true}
        fixHeaderToTop={true}
        height="90vh"
      />
    </div>
  );
};

LadTable.propTypes = {
  data: PropTypes.array.isRequired,
  updateLadLabel: PropTypes.func.isRequired,
};

export default LadTable;
