import axios from "axios";
import apiUrl from "api-url";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";
import { SEARCH_CATEGORIES } from "../container-management/components/route-management/RouteManagement.searchOptions";
import _ from "lodash";
import { getTrackingFeatureName } from "../utils/ContainerTrackingUtils";

const STORE_MOUNT_POINT = "ctRouteManagementSearch";
const SAVED_ROUTE_CONTAINER_CHANGES = `${STORE_MOUNT_POINT}/SAVED_ROUTE_CONTAINER_CHANGES`;
const RESET_SAVED_STATUS = `${STORE_MOUNT_POINT}/RESET_SAVED_STATUS`;
const SAVING_ROUTE_CONTAINER_CHANGES = `${STORE_MOUNT_POINT}/SAVING_ROUTE_CONTAINER_CHANGES`;
const UPDATE_FAILED_CASES = `${STORE_MOUNT_POINT}/UPDATE_FAILED_CASES`;
const RESET_FAILED_CASES = `${STORE_MOUNT_POINT}/RESET_FAILED_CASES`;
const UPDATE_SAVED_CASES = `${STORE_MOUNT_POINT}/UPDATE_SAVED_CASES`;
const RESET_SAVED_CASES = `${STORE_MOUNT_POINT}/RESET_SAVED_CASES`;
const ERROR_ON_SAVE = `${STORE_MOUNT_POINT}/ERROR_ON_SAVE`;

const BATCH_UPLOAD = `${STORE_MOUNT_POINT}/BATCH_UPLOAD`;
const BATCH_UPLOAD_SUCCEEDED = `${STORE_MOUNT_POINT}/BATCH_UPLOAD_SUCCEEDED`;
const BATCH_UPLOAD_FAILED = `${STORE_MOUNT_POINT}/BATCH_UPLOAD_FAILED`;
const BATCH_UPLOAD_RESET = `${STORE_MOUNT_POINT}/BATCH_UPLOAD_RESET`;

const APPLICATION_BASE_URL = apiUrl("/containertracking/api");

const axiosConfig = (state) => {
  return {
    headers: {
      "x-target-feature": getTrackingFeatureName(state),
    },
  };
};

const searchUrl = (queryString) => {
  return `${APPLICATION_BASE_URL}/bucket?${queryString}`;
};

const batchUploadUrl = () => {
  return `${APPLICATION_BASE_URL}/location-bucket-batch-upload`;
};

const fetchSearch = (queryString = "", solutionId, duck, dispatch, state) => {
  const url = searchUrl(queryString, state);
  dispatch(
    duck.fetch(url, {
      headers: {
        Accept: "application/json;version=all",
        "x-target-feature": getTrackingFeatureName(state),
      },
    }),
  );
};

const updateLocationsData = (locations, bucketName, action) => {
  const updatedData = locations.map((location) => {
    return {
      ...location.details,
      bucket_name: bucketName,
      action,
    };
  });
  return updatedData;
};

const updateContainerData = (containers, bucketName, action) => {
  const updatedData = containers.map((container) => {
    return {
      bucket_name: bucketName,
      container_identifier: container.containerId,
      uuid: container.id,
      action,
    };
  });
  return updatedData;
};

const updateData = (
  newBucketName,
  bucketName,
  addedLocations,
  deletedLocations,
  addedContainers,
  deletedContainers,
  isNew,
) => {
  const url = APPLICATION_BASE_URL + "/bucket";
  const updatedData = {};
  if (bucketName !== "" && newBucketName !== bucketName) {
    updatedData["rename"] = {
      bucket_name: bucketName,
      new_bucket_name: newBucketName,
    };
  }
  const updatedBucketName =
    newBucketName && newBucketName !== bucketName ? newBucketName : bucketName;

  const updatedAddedLocations = updateLocationsData(
    addedLocations,
    updatedBucketName,
    "ADD",
  );

  const updatedDelLocations = updateLocationsData(
    deletedLocations,
    updatedBucketName,
    "DELETE",
  );

  const updatedAddedContainers = updateContainerData(
    addedContainers,
    updatedBucketName,
    "ADD",
  );

  const updatedDelContainers = updateContainerData(
    deletedContainers,
    updatedBucketName,
    "DELETE",
  );

  updatedData["data"] = [
    ...updatedAddedLocations,
    ...updatedDelLocations,
    ...updatedAddedContainers,
    ...updatedDelContainers,
  ];

  if (isNew) {
    updatedData["_isNew"] = true;
  }

  return async (dispatch, getState) => {
    dispatch({
      type: RESET_FAILED_CASES,
    });
    const state = getState();
    const config = axiosConfig(state);
    dispatch({
      type: SAVING_ROUTE_CONTAINER_CHANGES,
    });
    await axios
      .put(url, updatedData, config)
      .then((res) => {
        if (res.data.status === 200) {
          dispatch({
            type: SAVED_ROUTE_CONTAINER_CHANGES,
          });
          dispatch({
            type: UPDATE_SAVED_CASES,
            payload:
              res.data && res.data.response
                ? {
                    ...state[STORE_MOUNT_POINT].savedCases,
                    ...res.data.response.success,
                  }
                : state[STORE_MOUNT_POINT].savedCases,
          });
        } else if (res.data.status === 207 || res.data.status === 500) {
          dispatch({
            type: SAVED_ROUTE_CONTAINER_CHANGES,
          });
          dispatch({
            type: UPDATE_SAVED_CASES,
            payload:
              res.data && res.data.response
                ? {
                    ...state[STORE_MOUNT_POINT].savedCases,
                    ...res.data.response.success,
                  }
                : state[STORE_MOUNT_POINT].savedCases,
          });
          dispatch({
            type: UPDATE_FAILED_CASES,
            payload:
              res.data && res.data.response ? res.data.response.failure : null,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: SAVED_ROUTE_CONTAINER_CHANGES,
        });
        dispatch({
          type: ERROR_ON_SAVE,
        });
      });
  };
};

const batchUploadAction = (payload) => {
  return (dispatch, getState) => {
    dispatch({ type: BATCH_UPLOAD });
    const config = axiosConfig(getState());
    const url = batchUploadUrl();
    return axios
      .post(url, payload, config)
      .then((response) => {
        dispatch({
          type: BATCH_UPLOAD_SUCCEEDED,
          payload: response?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: BATCH_UPLOAD_FAILED,
          payload: error?.response?.data,
        });
      });
  };
};

const clearBatchUpload = () => {
  return function (dispatch) {
    dispatch({ type: BATCH_UPLOAD_RESET });
  };
};

// Selectors

const getSavedState = (state) =>
  state[STORE_MOUNT_POINT].changesSavedStatus || false;

const getIsSaving = (state) => state[STORE_MOUNT_POINT].isSaving || false;

const getSavedCases = (state) =>
  state[STORE_MOUNT_POINT].savedCases &&
  !_.isEmpty(state[STORE_MOUNT_POINT].savedCases)
    ? state[STORE_MOUNT_POINT].savedCases
    : null;

const getFailedCases = (state) =>
  state[STORE_MOUNT_POINT].failure &&
  !_.isEmpty(state[STORE_MOUNT_POINT].failure)
    ? state[STORE_MOUNT_POINT].failure
    : null;

const resetFailedCases = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_FAILED_CASES,
    });
  };
};

const getBatchUploadResult = (state) => {
  return state[STORE_MOUNT_POINT].batchUploadResult;
};

const getBatchUploadError = (state) => {
  return state[STORE_MOUNT_POINT].batchUploadError;
};

const getBatchUploadLoading = (state) => {
  return state[STORE_MOUNT_POINT].batchUploadLoading;
};

const resetSavedCases = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_SAVED_CASES,
    });
  };
};

const resetSavedStatus = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_SAVED_STATUS,
    });
  };
};

// Reducer
const ctRouteManagementSearchBarReducer = (
  state = {
    changesSavedStatus: false,
    isSaving: false,
    failure: null,
    batchUploadLoading: false,
    batchUploadResult: null,
    batchUploadError: null,
    savedCases: null,
    errorOnSave: false,
  },
  action,
) => {
  switch (action.type) {
    case SAVING_ROUTE_CONTAINER_CHANGES:
      return {
        ...state,
        changesSavedStatus: false,
        errorOnSave: false,
        isSaving: true,
        failure: null,
      };
    case SAVED_ROUTE_CONTAINER_CHANGES:
      return {
        ...state,
        changesSavedStatus: true,
        isSaving: false,
      };
    case RESET_SAVED_STATUS:
      return {
        ...state,
        changesSavedStatus: false,
      };
    case UPDATE_FAILED_CASES:
      return {
        ...state,
        failure: action.payload,
      };
    case RESET_FAILED_CASES:
      return {
        ...state,
        failure: null,
      };
    case BATCH_UPLOAD:
      return {
        ...state,
        batchUploadLoading: true,
      };
    case BATCH_UPLOAD_SUCCEEDED:
      return {
        ...state,
        batchUploadLoading: false,
        batchUploadResult: action.payload,
      };
    case BATCH_UPLOAD_FAILED:
      return {
        ...state,
        batchUploadLoading: false,
        batchUploadError: action.payload,
      };
    case BATCH_UPLOAD_RESET:
      return {
        ...state,
        batchUploadLoading: false,
        batchUploadResult: null,
        batchUploadError: null,
      };
    case UPDATE_SAVED_CASES:
      return {
        ...state,
        savedCases: action.payload,
      };
    case RESET_SAVED_CASES:
      return {
        ...state,
        savedCases: null,
      };
    case ERROR_ON_SAVE:
      return {
        ...state,
        errorOnSave: true,
      };
    default:
      return state;
  }
};

const SearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  [],
  fetchSearch,
  [ctRouteManagementSearchBarReducer],
);

SearchBarState.selectors = {
  ...SearchBarState.selectors,
  getSavedState,
  getIsSaving,
  getBatchUploadResult,
  getBatchUploadError,
  getBatchUploadLoading,
  getSavedCases,
  getFailedCases,
};

SearchBarState.actionCreators = {
  ...SearchBarState.actionCreators,
  updateData,
  resetFailedCases,
  batchUploadAction,
  clearBatchUpload,
  resetSavedCases,
  resetSavedStatus,
};

export default SearchBarState;
