/** @jsxImportSource @emotion/react */
import React, { Fragment } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { EtaName, useEtaTranslations } from "shared/hooks/useEtaTranslations";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Icon, IconType } from "components/atoms/Icon.atom";
import {
  faCalendarEdit,
  faCalendarCheck,
} from "@fortawesome/pro-light-svg-icons";
import fvLogo from "assets/logos/fv_mark.svg";
import { DateTimeRange } from "components/atoms/DateTimeRange.atom";
import { DateTime } from "components/atoms/DateTime.atom";
import { Tabs, TabsClassNames } from "../molecules/Tabs.molecule";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { useTranslation } from "react-i18next";
import { useSubcomponentOverride } from "components/hooks/useSubcomponentOverride";
import {
  ShowForMediumAndDown,
  ShowForLargeAndUp,
  useIsLargeAndUp,
  MediaQueries,
  useIsSmallAndUp,
} from "components/responsive";
import { LocationAddressComplex } from "components/molecules/LocationAddressBlock.molecule";
import Colors from "styles/colors";
import {
  faCircle,
  faExclamationCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { humanizeTimeString, localizedDateFormatter } from "utils/date-time";
import moment from "moment";
import { Separator } from "components/atoms/Separator.atom";

// For use with locations in FinishedVehicle Details
export const transformLocation = (location = {}) => {
  let scheduledArrivalTime;
  if (!_.isNil(location.scheduled_arrival_window)) {
    scheduledArrivalTime = JSON.parse(location.scheduled_arrival_window);
  }

  return {
    name: location.name,
    city: location.city,
    state: location.state,
    postalCode: location.postal_code,
    country: location.country,
    address: location.address,
    earliestArrivalDateTime: scheduledArrivalTime?.[0],
    latestArrivalDateTime: scheduledArrivalTime?.[1],
    actualArrivalDateTime: location.arrived,
    actualDepartureDateTime: location.departed,
  };
};

// For use with shipments in Shipment Details
export const getLocationDataFromShipments = (shipments = []) => {
  if (!_.isArray(shipments) || shipments.length === 0) {
    return {};
  }

  let originShipment = shipments[0] ?? null;
  let destinationShipment = shipments[shipments.length - 1] ?? null;

  if (!originShipment) {
    return {};
  }

  let {
    origin_name,
    destination_name,
    origin_earliest_arrival,
    destination_earliest_arrival,
    origin_actual_arrival,
    origin_actual_departure,
    destination_actual_arrival,
    destination_actual_departure,
    origin_latest_arrival,
    destination_latest_arrival,
    origin_city,
    origin_state,
    origin_postal_code,
    origin_country,
    origin_address,
    destination_city,
    destination_state,
    destination_postal_code,
    destination_country,
    destination_address,
    origin_calculated_eta,
    destination_calculated_eta,
  } = originShipment;

  // If there is more than one shipment, get the destination details from the last shipment.
  if (shipments.length > 1 && destinationShipment) {
    destination_name = destinationShipment.destination_name ?? null;
    destination_city = destinationShipment.destination_city ?? null;
    destination_state = destinationShipment.destination_state ?? null;
    destination_address = destinationShipment.destination_address ?? null;
    destination_postal_code =
      destinationShipment.destination_postal_code ?? null;
    destination_country = destinationShipment.destination_country ?? null;
    destination_earliest_arrival =
      destinationShipment.destination_earliest_arrival ?? null;
    destination_latest_arrival =
      destinationShipment.destination_latest_arrival ?? null;
    destination_actual_arrival =
      destinationShipment.destination_actual_arrival ?? null;
    destination_actual_departure =
      destinationShipment.destination_actual_departure ?? null;
    destination_calculated_eta =
      destinationShipment.destination_calculated_eta ?? null;
  }

  const origin = {
    name: origin_name,
    city: origin_city,
    state: origin_state,
    postalCode: origin_postal_code,
    address: origin_address,
    country: origin_country,
    earliestArrivalDateTime: origin_earliest_arrival,
    latestArrivalDateTime: origin_latest_arrival,
    actualArrivalDateTime: origin_actual_arrival,
    actualDepartureDateTime: origin_actual_departure,
    calculatedEta: origin_calculated_eta,
  };
  const destination = {
    name: destination_name,
    city: destination_city,
    state: destination_state,
    address: destination_address,
    postalCode: destination_postal_code,
    country: destination_country,
    earliestArrivalDateTime: destination_earliest_arrival,
    latestArrivalDateTime: destination_latest_arrival,
    actualArrivalDateTime: destination_actual_arrival,
    actualDepartureDateTime: destination_actual_departure,
    calculatedEta: destination_calculated_eta,
  };

  let calculatedEta = null;
  if (!destination?.actualArrivalDatetime) {
    calculatedEta = destination?.calculatedEta ?? null;
  }

  let shipmentWithFrozenEta = shipments.find((shipment) => {
    return !_.isNil(shipment.destination_frozen_eta);
  });
  let frozenEta = shipmentWithFrozenEta?.destination_frozen_eta;

  let isFvEta = destinationShipment?.destination_is_fv_eta;
  // H1-4646: Don't show FV ETA icon if we have a frozen ETA.
  if (frozenEta) {
    isFvEta = false;
  }

  // If the shipment has arrived, don't show any ETA.
  let hasArrived = destinationShipment?.active_status_ng === "Arrived";
  if (hasArrived) {
    calculatedEta = null;
    frozenEta = null;
    isFvEta = null;
  }

  return {
    origin,
    destination,
    calculatedEta,
    frozenEta,
    isFvEta,
  };
};

const locationPropType = PropTypes.shape({
  name: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  postalCode: PropTypes.string,
  country: PropTypes.string,
  address: PropTypes.string,
  earliestArrivalDateTime: PropTypes.string,
  latestArrivalDateTime: PropTypes.string,
  actualArrivalDateTime: PropTypes.string,
  actualDepartureDateTime: PropTypes.string,
});

const SubHeaders = ({ children }) => {
  if (children) {
    return children;
  }
  // Components are required to return something.
  // If we don't have anything to return, then use null.
  return null;
};

SubHeaders.propTypes = {
  children: PropTypes.any,
};

const RouteSubHeader = ({ routeId, onTimePercentage }) => {
  const { t } = useTranslation("components");

  if (!routeId) {
    return null;
  }

  return (
    <PanelGroup.SubHeader
      css={{
        background: Colors.background.WARM_GRAY,
        padding: "0.5em 1em",
        color: Colors.text.WHITE,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <span>
        <Text size={FontSize.size16} bold>
          {t("components:Route ID")}:{" "}
        </Text>
        <Text size={FontSize.size16} data-qa="text-route-number">
          {routeId}
        </Text>
      </span>
      {onTimePercentage ? (
        <Text size={FontSize.size16} bold align="right">
          {t("components:On Time")}{" "}
          <span data-qa="text-on-time-percentage">{onTimePercentage}</span>
        </Text>
      ) : (
        <Text size={FontSize.size16} bold align="right">
          <span data-qa="text-on-time-percentage">{t("components:N/A")}</span>
        </Text>
      )}
    </PanelGroup.SubHeader>
  );
};

RouteSubHeader.propTypes = {
  routeId: PropTypes.string,
  onTimePercentage: PropTypes.string,
};

const TripPlanIdSubHeader = ({ tripPlanId }) => {
  const { t } = useTranslation("components");

  if (!tripPlanId) {
    return null;
  }

  return (
    <PanelGroup.SubHeader
      css={{
        background: Colors.background.WARM_GRAY,
        padding: "0.5em 1em",
        color: Colors.text.WHITE,
        fontWeight: "bold",
      }}
    >
      <Text size={FontSize.size16} bold>
        {t("components:Trip Plan ID")}:{" "}
      </Text>
      <Text size={FontSize.size16} data-qa="text-trip-plan-number">
        {tripPlanId}
      </Text>
    </PanelGroup.SubHeader>
  );
};

TripPlanIdSubHeader.propTypes = {
  tripPlanId: PropTypes.string,
};

const LastUpdateSubHeader = ({ location, ts }) => {
  const { t } = useTranslation("components");

  if (!location) {
    return null;
  }

  const locationLabel = [location.city, location.state, location.country]
    .filter((value) => value)
    .join(",");

  const formattedTimestamp = humanizeTimeString(ts, true);

  return (
    <PanelGroup.SubHeader
      css={{
        background: Colors.background.WARM_GRAY,
        padding: "0.5em 1em",
        color: Colors.text.WHITE,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Text size={FontSize.size16}>
        {t("components:Last Update")}:{" "}
        <span data-qa="text-last-update-location">{locationLabel}</span>
      </Text>
      <Text size={FontSize.size16} data-qa="text-time-last-update">
        {formattedTimestamp}
      </Text>
    </PanelGroup.SubHeader>
  );
};

LastUpdateSubHeader.propTypes = {
  location: PropTypes.shape({
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
  }),
  ts: PropTypes.string,
};

const LocationDetails = ({ title, location, center = false, style = {} }) => {
  const name = location?.name;
  const address = location?.address;
  const city = location?.city;
  const state = location?.state;
  const postalCode = location?.postalCode;
  const country = location?.country;

  return (
    <span
      css={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        [MediaQueries.largeAndUp]: {
          justifyContent: "initial",
          alignItems: "initial",
        },
      }}
      style={style}
    >
      <Text color={Colors.text.GRAY} style={{ paddingBottom: "0.5em" }}>
        {title}
      </Text>
      <Text size={FontSize.size18} bold>
        {name}
      </Text>
      <LocationAddressComplex
        address1={address}
        city={city}
        state={state}
        postalCode={postalCode}
        country={country}
        lookupCountryNameFromCode
        style={center ? { alignItems: "center" } : null}
      />
    </span>
  );
};
LocationDetails.propTypes = {
  title: PropTypes.string,
  location: locationPropType,
  center: PropTypes.bool,
  style: PropTypes.object,
};

const ContentHeader = ({ title, icon, style = {} }) => {
  return (
    <div
      css={{
        display: "flex",
        justifyContent: "center",
        width: "calc(100% + 2em)",
        padding: "4px 0",
        background: Colors.background.DARK_GRAY,
      }}
      style={style}
    >
      <Text color={Colors.text.WHITE}>
        <Icon src={icon} style={{ marginRight: "0.5em" }} />
        {title}
      </Text>
    </div>
  );
};

ContentHeader.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.any,
  style: PropTypes.object,
};

const ScheduledArrivalWindow = ({
  location,
  style = {},
  hideScheduledTimes,
}) => {
  const { t } = useTranslation("components");

  let timeElement = <Text>{t("components:N/A")}</Text>;
  if (location?.earliestArrivalDateTime && location?.latestArrivalDateTime) {
    timeElement = (
      <DateTimeRange
        stack
        localize
        showTime={!hideScheduledTimes}
        from={location.earliestArrivalDateTime}
        to={location.latestArrivalDateTime}
        fallback={<Text>{t("components:N/A")}</Text>}
      />
    );
  } else if (location?.earliestArrivalDateTime) {
    timeElement = (
      <DateTime
        stack
        localize
        showTime={!hideScheduledTimes}
        dateTime={location.earliestArrivalDateTime}
        fallback={<Text>{t("components:N/A")}</Text>}
      />
    );
  } else if (location?.latestArrivalDateTime) {
    timeElement = (
      <DateTime
        stack
        localize
        showTime={!hideScheduledTimes}
        dateTime={location.latestArrivalDateTime}
        fallback={<Text>{t("components:N/A")}</Text>}
      />
    );
  }

  return (
    <TableSlice title={t("components:Arrival Window")} style={style}>
      {timeElement}
    </TableSlice>
  );
};

ScheduledArrivalWindow.propTypes = {
  location: locationPropType,
  style: PropTypes.object,
  hideScheduledTimes: PropTypes.bool,
};

const ArrivalDepartureTimes = ({
  location,
  availableForUnloadTs,
  completeStateChangeTs,
  style = {},
  altArrivalDateTime = null,
  showActualArrival = true,
  showActualDeparture = true,
  showAvailableForUnload = false,
  showCompletionDate = false,
}) => {
  const { t } = useTranslation("components");
  const isLargeAndUp = useIsLargeAndUp();

  const availableForUnloadTableSlice = showAvailableForUnload ? (
    <TableSlice
      title={t("components:Available for unload")}
      style={{ paddingTop: isLargeAndUp ? "1em" : null }}
    >
      <DateTime
        stack
        localize
        dateTime={availableForUnloadTs}
        fallback={<Text>{t("components:N/A")}</Text>}
      />
    </TableSlice>
  ) : null;

  let actualArrivalDateTime = location?.actualArrivalDateTime
    ? location.actualArrivalDateTime
    : altArrivalDateTime;
  let actualDepartureDateTime = location?.actualDepartureDateTime;
  return (
    <div style={style}>
      <div css={{ display: "flex" }}>
        {showActualArrival ? (
          <TableSlice title={t("components:Arrival")}>
            <DateTime
              stack
              localize
              dateTime={actualArrivalDateTime}
              fallback={<Text>{t("components:N/A")}</Text>}
            />
          </TableSlice>
        ) : null}
        {!isLargeAndUp ? availableForUnloadTableSlice : null}
        {showActualDeparture ? (
          <TableSlice title={t("components:Departure")}>
            <DateTime
              stack
              localize
              dateTime={actualDepartureDateTime}
              fallback={<Text>{t("components:N/A")}</Text>}
            />
          </TableSlice>
        ) : null}
        {showCompletionDate ? (
          <TableSlice title={t("components:Completed")}>
            <DateTime
              stack
              localize
              dateTime={completeStateChangeTs}
              fallback={<Text>{t("components:N/A")}</Text>}
            />
          </TableSlice>
        ) : null}
      </div>
      {isLargeAndUp ? availableForUnloadTableSlice : null}
    </div>
  );
};

ArrivalDepartureTimes.propTypes = {
  location: locationPropType,
  availableForUnloadTs: PropTypes.string,
  completeStateChangeTs: PropTypes.string,
  style: PropTypes.object,
  altArrivalDateTime: PropTypes.string,
  showActualArrival: PropTypes.bool,
  showActualDeparture: PropTypes.bool,
  showAvailableForUnload: PropTypes.bool,
  showCompletionDate: PropTypes.bool,
};

// TODO: Rename
const TableSlice = ({ title, children, style = {} }) => {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        flex: "1 1 0",
        textAlign: "center",
        [MediaQueries.largeAndUp]: {
          textAlign: "initial",
        },
      }}
      style={style}
    >
      <div
        css={{
          borderBottom: `1px solid ${Colors.background.GRAY}`,
          marginBottom: "0.5em",
        }}
      >
        <Text>{title}</Text>
      </div>
      <div
        css={{
          textAlign: "initial",
          display: "flex",
          justifyContent: "center",
          [MediaQueries.largeAndUp]: {
            justifyContent: "initial",
          },
        }}
      >
        {children}
      </div>
    </div>
  );
};

TableSlice.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  style: PropTypes.object,
};

export const ArrivalAndDeparturePanelGroup = ({
  origin,
  destination,
  hideScheduled = false,
  hideScheduledTimes = false,
  // ETA Box
  eta,
  onlyShowEtaDate = false,
  heldExceptionDateTime,
  isFvEta = false,
  etaWindow,
  etaMessage,
  etaWarningMessage,
  // Available for unload
  availableForUnloadTs,
  // trip plan complete event ts for if shipment actual arrival in None but trip plan is completed
  tripPlanCompleteTs,
  completeStateChangeTs,
  manualEtaRangeStart,
  manualEtaRangeEnd,
  // Styles for the parent PanelGroup
  style = {},
  visibleFields: visibleFieldsFromProps, // get the props value from 'visibleFields' to 'visibleFieldsFromProps'
  children,
}) => {
  const visibleFields = {
    // Defaults values
    originScheduledArrivalWindow: true,
    destinationScheduledArrivalWindow: true,
    originActualArrival: true,
    originActualDeparture: true,
    destinationActualArrival: true,
    destinationActualDeparture: true,
    availableForUnload: false,
    destinationCompletionDate: false,
    // Override from prop
    ...(visibleFieldsFromProps ?? {}),
  };

  const { t } = useTranslation("components");
  const { getEtaTooltipTranslation } = useEtaTranslations();
  const isSmallAndUp = useIsSmallAndUp();
  const subheaders = useSubcomponentOverride(children, <SubHeaders />);

  // Create ETA tooltip. Do not display a tooltip for a non-Intelligent ETA (unless it's TBD/Delayed)
  let tooltipText = null;
  if (eta && etaMessage) {
    tooltipText = etaMessage;
  } else {
    tooltipText = getEtaTooltipTranslation(eta, isFvEta);
  }

  // If we have a warning, overide the tooltip text.
  if (!_.isNil(etaWarningMessage)) {
    tooltipText = etaWarningMessage;
  }

  let etaBoxElement = (
    <Tooltip placement="top" tooltipChildren={tooltipText}>
      <EtaBox
        eta={eta}
        warningMessage={etaWarningMessage}
        isFvEta={isFvEta}
        etaWindow={etaWindow}
        onlyShowEtaDate={onlyShowEtaDate}
        heldExceptionDateTime={heldExceptionDateTime}
      />
    </Tooltip>
  );

  return (
    <PanelGroup collapsible style={style}>
      <PanelGroup.Header
        title={t("components:Arrivals and Departures")}
        postHeaderContent={isSmallAndUp ? etaBoxElement : null}
      />
      <Text
        size={FontSize.size12}
        css={{
          color: "white",
          backgroundColor: "#2785A9",
          display: "block",
          width: "100%",
          textAlign: "center",
        }}
      >
        {manualEtaRangeStart &&
        manualEtaRangeEnd &&
        eta?.toLowerCase() === EtaName.PENDING_DISPATCH
          ? t(
              "components:Estimated Delivery [[[fromMonthYear]]] - [[[toMonthYear]]]",
              {
                fromMonthYear: moment
                  .utc(manualEtaRangeStart)
                  .format("MMMM YYYY"),
                toMonthYear: moment.utc(manualEtaRangeEnd).format("MMMM YYYY"),
              },
            )
          : null}
      </Text>
      {!isSmallAndUp && (
        <PanelGroup.SubHeader>{etaBoxElement}</PanelGroup.SubHeader>
      )}
      {subheaders}
      <PanelGroup.Content style={{ padding: 0 }}>
        {/* NOTE: For changes to this PanelGroups's content,
         * make sure to update both the UI in ShowForLargeAndUp and ShowForMediumAndDown
         */}
        <ShowForLargeAndUp>
          <div
            css={{
              display: "grid",
              gridTemplateAreas: `
                'location-origin separator1 location-destination'
                'scheduled-header scheduled-header scheduled-header'
                'origin-scheduled separator2 destination-scheduled'
                'actual-header actual-header actual-header'
                'origin-actual separator3 destination-actual'`,
              gridTemplateRows: "auto auto auto auto auto",
              //1px of vertical line between the origin and destination content
              gridTemplateColumns: "1fr 1px 1fr",
            }}
          >
            <LocationDetails
              title={t("components:Origin")}
              location={origin}
              style={{
                gridArea: "location-origin",
                padding: "1em",
              }}
            />
            <Separator
              color={Colors.background.GRAY}
              style={{ gridArea: "separator1" }}
            />
            <LocationDetails
              title={t("components:Destination")}
              location={destination}
              style={{ gridArea: "location-destination", padding: "1em" }}
            />
            {!hideScheduled ? (
              <React.Fragment>
                <ContentHeader
                  title={t("components:Scheduled")}
                  icon={faCalendarEdit}
                  style={{ gridArea: "scheduled-header" }}
                />
                {visibleFields.originScheduledArrivalWindow ? (
                  <ScheduledArrivalWindow
                    hideScheduledTimes={hideScheduledTimes}
                    location={origin}
                    style={{
                      gridArea: "origin-scheduled",
                      padding: "1em",
                    }}
                  />
                ) : null}
                <Separator
                  color={Colors.background.GRAY}
                  style={{ gridArea: "separator2" }}
                />
                {visibleFields.destinationScheduledArrivalWindow ? (
                  <ScheduledArrivalWindow
                    hideScheduledTimes={hideScheduledTimes}
                    location={destination}
                    style={{
                      gridArea: "destination-scheduled",
                      padding: "1em",
                    }}
                  />
                ) : null}
              </React.Fragment>
            ) : null}
            <ContentHeader
              title={t("components:Actual")}
              icon={faCalendarCheck}
              style={{ gridArea: "actual-header" }}
            />
            <ArrivalDepartureTimes
              location={origin}
              style={{
                gridArea: "origin-actual",
                padding: "1em",
              }}
              showActualArrival={visibleFields.originActualArrival}
              showActualDeparture={visibleFields.originActualDeparture}
            />
            <Separator
              color={Colors.background.GRAY}
              style={{ gridArea: "separator3" }}
            />
            <ArrivalDepartureTimes
              location={destination}
              availableForUnloadTs={availableForUnloadTs}
              completeStateChangeTs={completeStateChangeTs}
              style={{ gridArea: "destination-actual", padding: "1em" }}
              altArrivalDateTime={tripPlanCompleteTs}
              showActualArrival={visibleFields.destinationActualArrival}
              showActualDeparture={visibleFields.destinationActualDeparture}
              showAvailableForUnload={visibleFields.availableForUnload}
              showCompletionDate={visibleFields.destinationCompletionDate}
            />
          </div>
        </ShowForLargeAndUp>
        <ShowForMediumAndDown>
          <Tabs
            fullWidthTabs
            css={{
              [TabsClassNames.TabList]: {
                paddingTop: "1em",
                background: "#AAA",
              },
            }}
          >
            <Tabs.TabList>
              <Tabs.Tab>{t("components:Origin")}</Tabs.Tab>
              <Tabs.Tab>{t("components:Destination")}</Tabs.Tab>
            </Tabs.TabList>
            <Tabs.TabPanel>
              <LocationDetails
                title={t("components:Origin")}
                location={origin}
                center
                style={{ padding: "1em" }}
              />
              {!hideScheduled && visibleFields.originScheduledArrivalWindow ? (
                <React.Fragment>
                  <ContentHeader
                    title={t("components:Scheduled")}
                    icon={faCalendarEdit}
                  />
                  <ScheduledArrivalWindow
                    location={origin}
                    style={{ padding: "1em" }}
                  />
                </React.Fragment>
              ) : null}
              <ContentHeader
                title={t("components:Actual")}
                icon={faCalendarCheck}
              />
              <ArrivalDepartureTimes
                location={origin}
                style={{ padding: "1em" }}
                showActualArrival={visibleFields.originActualArrival}
                showActualDeparture={visibleFields.originActualDeparture}
              />
            </Tabs.TabPanel>
            <Tabs.TabPanel>
              <LocationDetails
                title={t("components:Destination")}
                location={destination}
                center
                style={{ padding: "1em" }}
              />
              {!hideScheduled &&
              visibleFields.destinationScheduledArrivalWindow ? (
                <React.Fragment>
                  <ContentHeader
                    title={t("components:Scheduled")}
                    icon={faCalendarEdit}
                  />
                  <ScheduledArrivalWindow
                    location={destination}
                    style={{ padding: "1em" }}
                  />
                </React.Fragment>
              ) : null}
              <ContentHeader
                title={t("components:Actual")}
                icon={faCalendarCheck}
              />
              <ArrivalDepartureTimes
                location={destination}
                availableForUnloadTs={availableForUnloadTs}
                completeStateChangeTs={completeStateChangeTs}
                style={{ padding: "1em" }}
                showActualArrival={visibleFields.destinationActualArrival}
                showActualDeparture={visibleFields.destinationActualDeparture}
                showAvailableForUnload={visibleFields.availableForUnload}
                showCompletionDate={visibleFields.destinationCompletionDate}
              />
            </Tabs.TabPanel>
          </Tabs>
        </ShowForMediumAndDown>
      </PanelGroup.Content>
    </PanelGroup>
  );
};

ArrivalAndDeparturePanelGroup.propTypes = {
  /**
   * The origin location to display.
   */
  origin: locationPropType,
  /**
   * The destination location to display.
   */
  destination: locationPropType,
  /**
   * Hides the "Scheduled" section.
   *
   * Default: false
   */
  hideScheduled: PropTypes.bool,
  /**
   * The ETA value to display. This value is a datetime string
   * or another special ETA value (e.g. Delayed, Pending Dispatch, etc).
   */
  eta: PropTypes.string,
  /**
   * The ETA Date value to display. This value is boolean.
   */
  onlyShowEtaDate: PropTypes.bool,
  /**
   * The ETA value for held exception with until Date value to display. This value is string.
   */
  heldExceptionDateTime: PropTypes.string,
  /**
   * Flags the ETA value as a FreightVerify calcluated ETA.
   *
   * Default: false
   */
  isFvEta: PropTypes.bool,
  /**
   * The ETA value as a window of time (start and end).
   * This is passed as a string and has to be parsed using JSON.parse()
   */
  etaWindow: PropTypes.string,
  /**
   * The tooltip message to display when hovering the ETA value.
   * Overrides the default tooltip text.
   */
  etaMessage: PropTypes.string,
  /**
   * When present, a warning icon will be displayed next to the ETA value.
   * This text will be displayed in the tooltip when hovering the ETA value.
   * Overrides the default tooltip text and `etaMessage`.
   */
  etaWarningMessage: PropTypes.string,

  /**
   * The value to display for the "Available for unload" datetime.
   * Displays under the destination's "Actual" section.
   */
  availableForUnloadTs: PropTypes.string,
  /**
   * Fallback value for the destination's actual arrival datetime.
   */
  tripPlanCompleteTs: PropTypes.string,
  completeStateChangeTs: PropTypes.string,
  /**
   * CSS styles applied to the Panel Group.
   */
  style: PropTypes.object,
  manualEtaRangeStart: PropTypes.any,
  manualEtaRangeEnd: PropTypes.any,
  /**
   * Pass in components to override some children in the component.
   *
   * Available for:
   * - ArrivalAndDeparturePanelGroup.SubHeaders
   * - ArrivalAndDeparturePanelGroup.RouteSubHeader
   * - ArrivalAndDeparturePanelGroup.TripPlanIdSubHeader
   */
  hideScheduledTimes: PropTypes.bool,
  children: PropTypes.any,
  visibleFields: PropTypes.shape({
    originScheduledArrivalWindow: PropTypes.bool,
    destinationScheduledArrivalWindow: PropTypes.bool,
    originActualArrival: PropTypes.bool,
    originActualDeparture: PropTypes.bool,
    destinationActualArrival: PropTypes.bool,
    destinationActualDeparture: PropTypes.bool,
    availableForUnload: PropTypes.bool,
  }),
};

ArrivalAndDeparturePanelGroup.SubHeaders = SubHeaders;
ArrivalAndDeparturePanelGroup.RouteSubHeader = RouteSubHeader;
ArrivalAndDeparturePanelGroup.TripPlanIdSubHeader = TripPlanIdSubHeader;
ArrivalAndDeparturePanelGroup.LastUpdateSubHeader = LastUpdateSubHeader;

const EtaBox = ({
  eta,
  warningMessage,
  isFvEta,
  etaWindow,
  onlyShowEtaDate,
  heldExceptionDateTime,
}) => {
  const { t } = useTranslation("components");
  const { getEtaTranslation, isEtaName } = useEtaTranslations();

  if (!eta) {
    return null;
  }

  let etaElement;

  if (isEtaName(eta)) {
    if (eta.toLowerCase() === EtaName.TBD && heldExceptionDateTime) {
      etaElement = (
        <Text bold color={Colors.text.WHITE} css={{ margin: "1em 0" }}>
          {t("components:TBD until")}{" "}
          <DateTime
            plain
            localize
            dateTime={heldExceptionDateTime}
            fallback={t("components:N/A")}
          >
            <DateTime.Time style={{ display: "none" }} />
            <DateTime.Timezone style={{ display: "none" }} />
          </DateTime>
        </Text>
      );
    } else {
      etaElement = (
        <Text bold color={Colors.text.WHITE} css={{ margin: "1em 0" }}>
          {getEtaTranslation(eta)}
        </Text>
      );
    }
  } else {
    if (onlyShowEtaDate) {
      // Display only ETA date without time
      etaElement = (
        <Text bold color={Colors.text.WHITE} css={{ margin: "1em 0" }}>
          <DateTime
            plain
            localize
            dateTime={eta}
            fallback={t("components:N/A")}
          >
            <DateTime.Time style={{ display: "none" }} />
            <DateTime.Timezone style={{ display: "none" }} />
          </DateTime>
        </Text>
      );
    } else if (etaWindow) {
      // display ETA window
      const parsedEta = JSON.parse(etaWindow);
      etaElement = (
        <Text color={Colors.text.WHITE}>
          {localizedDateFormatter(parsedEta[0])} -{" "}
          {localizedDateFormatter(parsedEta[1])}
        </Text>
      );
    } else {
      // Display single ETA date and time
      etaElement = (
        <DateTime
          dateTime={eta}
          plain
          stack
          localize
          style={{ color: "white" }}
          size={FontSize.size16}
        />
      );
    }
  }

  return (
    <div
      css={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        background: Colors.background.DARK_TEAL,
        padding: "0 1em 0 0.75em",
        height: "100%",
      }}
    >
      <Text
        bold
        color={Colors.background.DARK_TEAL}
        style={{
          backgroundColor: "white",
          borderRadius: 4,
          padding: "0 5px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginRight: "0.75em",
        }}
      >
        {isFvEta ? (
          <Icon
            type={IconType.LocalImage}
            src={fvLogo}
            style={{ height: "1.75em" }}
          />
        ) : null}
        {t("components:ETA")}
      </Text>
      {etaElement}
      {warningMessage ? (
        // Icon stack: White circle on bottom and red (!) on top.
        // The white circle gives the ! some color so it's not transparent.
        <Fragment>
          <Icon
            src={faCircle}
            size={FontSize.size18}
            color={Colors.text.WHITE}
            css={{
              position: "absolute",
              top: 0,
              right: 0,
              transform: "translate(50%, -50%)",
            }}
          />
          <Icon
            src={faExclamationCircle}
            size={FontSize.size20}
            color={Colors.highlight.RED}
            css={{
              position: "absolute",
              top: 0,
              right: 0,
              transform: "translate(50%, -50%)",
            }}
          />
        </Fragment>
      ) : null}
    </div>
  );
};

EtaBox.propTypes = {
  eta: PropTypes.string,
  onlyShowEtaDate: PropTypes.bool,
  heldExceptionDateTime: PropTypes.string,
  warningMessage: PropTypes.string,
  isFvEta: PropTypes.bool,
  etaWindow: PropTypes.string,
};
