/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { useTranslation } from "react-i18next";
import {
  useSetTitleOnMount,
  useSetDescriptionOnMount,
} from "components/hooks/useSetTitle";
import { useComplianceLinkQuery } from "./react-queries/useComplianceLinkQuery";
import { useComplianceLinksTable } from "./useComplianceLinksTable";

import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";

import { FontSize } from "components/atoms/enums";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import { Text } from "components/atoms/Text.atom";

const Section = styled.section({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "1em",
});

export const ComplianceDashboard = ({ pushReportDetailScreen }) => {
  const { t } = useTranslation("partner-portal");

  useSetTitleOnMount(t("partner-portal:Compliance"));
  useSetDescriptionOnMount(t("partner-portal:Compliance"));

  let complianceQuery = useComplianceLinkQuery();

  const requestResults = complianceQuery?.data;

  const rowClickHandler = (reportData) => {
    pushReportDetailScreen(reportData);
  };

  const columns = useComplianceLinksTable(rowClickHandler);

  const noDataFoundMessage = t(
    "partner-portal:No compliance reports are currently available for your organization.",
  );

  return (
    <Section>
      <Alert show={complianceQuery.isError} variant={AlertVariant.Danger}>
        <Text>
          {t(
            "partner-portal:Unable to fetch compliance links. Please try again later.",
          )}
        </Text>
      </Alert>
      {requestResults && (
        <PanelGroup collapsible={true} style={{ margin: "1em" }}>
          <PanelGroup.Header
            titleStyle={{ minHeight: "34px" }}
            title={t("partner-portal:Compliance Links")}
            titleSize={FontSize.size18}
            iconSize={FontSize.size16}
            tooltipPosition={"bottom"}
          />
          <PanelGroup.Content style={{ padding: 0 }}>
            <BaseTable
              theme={Themes.LIGHT}
              data={requestResults}
              columns={columns}
              isLoading={complianceQuery.isFetching}
              noDataFoundMessage={noDataFoundMessage}
            />
          </PanelGroup.Content>
        </PanelGroup>
      )}
    </Section>
  );
};

ComplianceDashboard.propTypes = {
  pushReportDetailScreen: PropTypes.func,
};
