import axios from "axios";
import { z } from "zod";

import apiUrl from "api-url";
import { BaseErrorResponse } from "api/responses";
import { createQueryRequestHook } from "shared/hooks/createQueryRequestHook";

const complianceLinksResponseSchema = z.array(
  z.object({
    title: z.string(),
    resource: z.string(),
    type: z.string(),
  }),
);

type ComplianceLinksResponse = z.infer<typeof complianceLinksResponseSchema>;

export const useComplianceLinkQuery = createQueryRequestHook<
  ComplianceLinksResponse,
  BaseErrorResponse
>({
  queryKey: ["partnerPortalComplianceLinks"],
  queryFn: async () => {
    const url = apiUrl(`/support/compliance`);
    const response = await axios.get(url);
    return complianceLinksResponseSchema.parse(response.data);
  },
  initialData: [],
  refetchOnWindowFocus: false,
  retry: false,
});
