import axios from "axios";
import { z } from "zod";

import { customerApiUrl } from "api-url";
import { BaseErrorResponse } from "api/responses";
import { createQueryRequestHook } from "shared/hooks/createQueryRequestHook";

import { populateCounts } from "../utils/chart.utils";

const countSchema = z.object({
  total: z.object({
    shippable: z.number(),
    nonShippable: z.number(),
  }),
  days0_2: z.object({
    shippable: z.number(),
    nonShippable: z.number(),
  }),
  days3_5: z.object({
    shippable: z.number(),
    nonShippable: z.number(),
  }),
  days6_9: z.object({
    shippable: z.number(),
    nonShippable: z.number(),
  }),
  days10_inf: z.object({
    shippable: z.number(),
    nonShippable: z.number(),
  }),
});

const etaSchema = z.object({
  total: z.number(),
  missed: z.number(),
  hours0_47: z.number(),
  hours48_95: z.number(),
  hours96_inf: z.number(),
});

const carrierItemSchema = z.object({
  fvId: z.string(),
  name: z.string(),
  scac: z.string(),
  count: z.number(),
});

const topCarriersSchema = z.object({
  total: z.number(),
  items: z.array(carrierItemSchema),
});

const orderTypeItemSchema = z.object({
  name: z.string(),
  count: z.number(),
});

const topOrderTypesSchema = z.object({
  total: z.number(),
  items: z.array(orderTypeItemSchema),
});

const dataSchema = z.object({
  count: countSchema,
  eta: etaSchema,
  topCarriers: topCarriersSchema,
  topOrderTypes: topOrderTypesSchema,
});

type OnsiteDataResponse = z.infer<typeof dataSchema>;

const onsiteDataParamsSchema = z.object({
  locationId: z.string(),
});

type OnsiteDataParams = z.infer<typeof onsiteDataParamsSchema>;

const useOnsiteDataQuery = (params: OnsiteDataParams) => {
  return createQueryRequestHook<OnsiteDataResponse, BaseErrorResponse>({
    queryKey: ["onsiteDataGraph", params.locationId],
    queryFn: async () => {
      const url = customerApiUrl(
        `/entity-inventory/location/${params.locationId}/metrics/onsite`,
      );
      const response = await axios.get(url);
      return dataSchema.parse(response.data);
    },
    initialData: {
      count: {
        total: { shippable: 0, nonShippable: 0 },
        days0_2: { shippable: 0, nonShippable: 0 },
        days3_5: { shippable: 0, nonShippable: 0 },
        days6_9: { shippable: 0, nonShippable: 0 },
        days10_inf: { shippable: 0, nonShippable: 0 },
      },
      eta: {
        total: 0,
        missed: 0,
        hours0_47: 0,
        hours48_95: 0,
        hours96_inf: 0,
      },
      topCarriers: {
        total: 0,
        items: [],
      },
      topOrderTypes: {
        total: 0,
        items: [],
      },
    },
    refetchOnWindowFocus: false,
    retry: false,
  })();
};

export const useOnsiteData = (
  params: OnsiteDataParams,
  includeNonShippable: boolean,
) => {
  const { data, isFetching, isError } = useOnsiteDataQuery(params);
  const populatedData = populateCounts(data);

  return { data: populatedData, isFetching, isError };
};
