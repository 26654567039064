import moment from "moment";
import _ from "lodash";

import apiUrl from "api-url";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";
import {
  SEARCH_CATEGORIES,
  FILTERS,
} from "pages/containertracking/search/SensorOverview/SensorOverview.searchOptions";

const STORE_MOUNT_POINT = "sensorSearch";
const entitiesUrl = (_ignored_solutionId, queryString) => {
  return apiUrl(`/containertracking/api/sensor/search?${queryString}`);
};

const axiosConfig = () => {
  return {
    headers: {
      "x-time-zone": moment.tz.guess(),
    },
  };
};

const fetchSearch = (queryString = "", _ignored_solutionId, duck, dispatch) => {
  let url = entitiesUrl(_ignored_solutionId, queryString);
  let config = axiosConfig();

  dispatch(duck.fetch(url, config));
};

const SensorOverviewSearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  FILTERS,
  fetchSearch,
  [],
  {
    defaultSort: "sensorId",
    reverseSort: false,
  },
);

SensorOverviewSearchBarState.actionCreators = {
  ...SensorOverviewSearchBarState.actionCreators,
  exportSearch: _.partial(
    SensorOverviewSearchBarState.actionCreators.exportEntities,
    entitiesUrl,
    null,
    { headers: { Accept: "text/csv;version=basic" } },
    "ct-sensor-overview-search-results",
  ),
};

SensorOverviewSearchBarState.selectors = {
  ...SensorOverviewSearchBarState.selectors,
};

export default SensorOverviewSearchBarState;
