function setValue(key, value, storageArea) {
  if (value === null) {
    storageArea.removeItem(key);
  } else {
    storageArea.setItem(key, value);
  }
}

function getValue(key, storageArea) {
  const value = storageArea.getItem(key);
  return value === "" ? null : value;
}

function getValueFromSessionWithLocalFallback(key) {
  let value = sessionStorage.getItem(key);

  if (!value) {
    const fromLocal = localStorage.getItem(key);
    if (fromLocal) {
      BrowserStorage[key] = fromLocal;
      value = fromLocal;
    }
  }

  return value;
}

const StorageKey = {
  ID_TOKEN: "id_token",
  ACCESS_TOKEN: "access_token",
  USER_ROLE_OVERRIDE: "userRoleOverride",
  EXPIRES_AT: "expires_at",
  IS_SILENT_AUTHENTICATION: "isSilentAuthentication",
  IS_LOGGING_IN: "isLoggingIn",
  FEDERATED_STATE: "federatedState",
  FEDERATION_DATA: "federationData",
  CURRENT_ORGANIZATION: "currentOrganization",
  SOLUTION_ID: "solutionId",
  LAST_REQUESTED_URL: "last_requested_url",
  MAP_TYPE_OVERRIDE: "mapTypeOverride",
  NAV_IS_COLLAPSED: "navIsCollapsed",
  NAV_OPEN_GROUPS: "navOpenGroups",
  DATE_FORMAT: "dateFormat",
  TIME_FORMAT: "timeFormat",
  MEASUREMENT_UNIT: "measurementUnit",
  FEDERATED_USER_PREFERENCE_MODAL_STATE: "hasRequestedFederatedUserEmail",
  LOCATION_TIMEZONE: "locationTimezone",
};

/**
 * Allows access to values that are saved in storage.
 *
 * To keep all access to these values consistent, we use this class
 * instead of using `sessionStorage` or `localStorage` directly.
 */
export class BrowserStorage {
  /* ID Token */
  static get idToken() {
    return getValue(StorageKey.ID_TOKEN, localStorage);
  }
  static set idToken(value) {
    setValue(StorageKey.ID_TOKEN, value, localStorage);
  }

  /* Access Token */
  static get accessToken() {
    return getValue(StorageKey.ACCESS_TOKEN, localStorage);
  }
  static set accessToken(value) {
    setValue(StorageKey.ACCESS_TOKEN, value, localStorage);
  }

  /* User Role Override */
  // TODO: Not sure if this value is actually used. Only usage in code is the get.
  static get userRoleOverride() {
    return getValue(StorageKey.USER_ROLE_OVERRIDE, localStorage);
  }
  static set userRoleOverride(value) {
    setValue(StorageKey.USER_ROLE_OVERRIDE, value, localStorage);
  }

  /* Expires At: When the token expires. */
  static get expiresAt() {
    return getValue(StorageKey.EXPIRES_AT, localStorage);
  }
  static set expiresAt(value) {
    setValue(StorageKey.EXPIRES_AT, value, localStorage);
  }

  /* Is user logging in */
  static get isSilentAuthentication() {
    return getValue(StorageKey.IS_SILENT_AUTHENTICATION, localStorage);
  }
  static set isSilentAuthentication(value) {
    setValue(StorageKey.IS_SILENT_AUTHENTICATION, value, localStorage);
  }

  /* Is user logging in */
  static get isLoggingIn() {
    return getValue(StorageKey.IS_LOGGING_IN, localStorage);
  }
  static set isLoggingIn(value) {
    setValue(StorageKey.IS_LOGGING_IN, value, localStorage);
  }

  /* Federated State: Federated login information. */
  static get federatedState() {
    return getValue(StorageKey.FEDERATED_STATE, localStorage);
  }
  static set federatedState(value) {
    setValue(StorageKey.FEDERATED_STATE, value, localStorage);
  }

  /* Federated State: Federated login information. */
  static get hasRequestedFederatedUserEmail() {
    return getValue(
      StorageKey.FEDERATED_USER_PREFERENCE_MODAL_STATE,
      localStorage,
    );
  }
  static set hasRequestedFederatedUserEmail(value) {
    setValue(
      StorageKey.FEDERATED_USER_PREFERENCE_MODAL_STATE,
      value,
      localStorage,
    );
  }

  /* Federation Data - Features and privilieges by federated login */
  static get federationData() {
    return getValue(StorageKey.FEDERATION_DATA, localStorage);
  }
  static set federationData(value) {
    setValue(StorageKey.FEDERATION_DATA, value, localStorage);
  }

  /* Current Organization */
  static get currentOrganization() {
    const key = StorageKey.CURRENT_ORGANIZATION;
    // If we try to access this value but it doesn't exist in the session,
    // we will use the localStorage version as a fallback.
    return getValueFromSessionWithLocalFallback(key);
  }
  static set currentOrganization(value) {
    const key = StorageKey.CURRENT_ORGANIZATION;
    // Need to store in both storages so localStorage has the latest value.
    setValue(key, value, sessionStorage);
    setValue(key, value, localStorage);
  }

  /* Solution ID */
  static get solutionId() {
    const key = StorageKey.SOLUTION_ID;
    // If we try to access this value but it doesn't exist in the session,
    // we will use the localStorage version as a fallback.
    return getValueFromSessionWithLocalFallback(key);
  }
  static set solutionId(value) {
    const key = StorageKey.SOLUTION_ID;
    // Need to store in both storages so localStorage has the latest value.
    setValue(key, value, sessionStorage);
    setValue(key, value, localStorage);
  }

  /* Last Reqeusted URL */
  static get lastRequestedUrl() {
    return getValue(StorageKey.LAST_REQUESTED_URL, sessionStorage);
  }
  static set lastRequestedUrl(value) {
    setValue(StorageKey.LAST_REQUESTED_URL, value, sessionStorage);
  }

  /* Map Type Override */
  static get mapTypeOverride() {
    return getValue(StorageKey.MAP_TYPE_OVERRIDE, sessionStorage);
  }
  static set mapTypeOverride(value) {
    setValue(StorageKey.MAP_TYPE_OVERRIDE, value, sessionStorage);
  }

  /* Navigation Bar - Collapse State */
  static get navIsCollapsed() {
    return getValue(StorageKey.NAV_IS_COLLAPSED, sessionStorage);
  }
  static set navIsCollapsed(value) {
    setValue(StorageKey.NAV_IS_COLLAPSED, value, sessionStorage);
  }

  /* Navigation Bar - Open Groups */
  static get navOpenGroups() {
    return getValue(StorageKey.NAV_OPEN_GROUPS, sessionStorage);
  }
  static set navOpenGroups(value) {
    setValue(StorageKey.NAV_OPEN_GROUPS, value, sessionStorage);
  }

  /* Date format */

  static get dateFormat() {
    return getValue(StorageKey.DATE_FORMAT, sessionStorage);
  }

  static set dateFormat(value) {
    setValue(StorageKey.DATE_FORMAT, value, sessionStorage);
  }

  /* Time format */

  static get timeFormat() {
    return getValue(StorageKey.TIME_FORMAT, sessionStorage);
  }

  static set timeFormat(value) {
    setValue(StorageKey.TIME_FORMAT, value, sessionStorage);
  }

  /* Measurement unit */

  static get measurementUnit() {
    return getValue(StorageKey.MEASUREMENT_UNIT, sessionStorage);
  }

  static set measurementUnit(value) {
    setValue(StorageKey.MEASUREMENT_UNIT, value, sessionStorage);
  }

  /* Location timezone */

  static get locationTimezone() {
    return getValue(StorageKey.LOCATION_TIMEZONE, sessionStorage);
  }

  static set locationTimezone(value) {
    setValue(StorageKey.LOCATION_TIMEZONE, value, sessionStorage);
  }

  /* Helpers, etc */

  /**
   * Event listeners to help manage storage values
   */
  static registerEventListeners() {
    // Update local storage when focusing the page.
    // We want local storage to reflect whatever the "active" tab has.
    // When the tab is focused again, we update localStorage to ensure
    // it has the correct value.
    window.addEventListener("focus", () => {
      const currentOrganization = BrowserStorage.currentOrganization;
      if (currentOrganization) {
        const key = StorageKey.CURRENT_ORGANIZATION;
        setValue(key, currentOrganization, localStorage);
      }

      const solutionId = BrowserStorage.solutionId;
      if (solutionId) {
        const key = StorageKey.SOLUTION_ID;
        setValue(key, solutionId, localStorage);
      }
    });

    // This event fires for other tabs, not the active one.
    window.addEventListener("storage", (event) => {
      // Check if we are setting the access token to null.
      // If so, we assume we're logging out.
      if (event.key === StorageKey.ACCESS_TOKEN) {
        if (event.oldValue) {
          if (event.newValue === null) {
            // Clear the session to ensure no issues when forced to logout.
            sessionStorage.clear();
            // Redirect to the root which will redirect us to the login page.
            window.location.assign("/session-ended");
          }
        }
      }
    });
  }
}
