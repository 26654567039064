// Displays a table of Watched Totes, and allows user to watch or unwatch each Tote

/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { faCommentAlt } from "@fortawesome/pro-solid-svg-icons";

import Colors from "styles/colors";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { Text } from "components/atoms/Text.atom";
import { Icon } from "components/atoms/Icon.atom";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { DateTimeCell } from "components/organisms/base-table/Cell/DateTimeCell";
import { ShowMoreList } from "components/molecules/ShowMoreList.molecule";
import { WatchToggle } from "shared/components/molecules/WatchToggle.molecule";
import { SurgicalToteTrackingDetailsWidgetState } from "pages/surgicaltotetracking/redux/SurgicalToteTrackingDetailsWidgetState";

export const SurgicalToteTrackingMyWatchedTotes = (props) => {
  const {
    watchedToteArray,
    watchedTotesLoading,
    fetchWatchedTotes,
    watchedTotesPageIndex,
    watchedTotesPageSize,
    watchedTotesPageCount,
    setWatchedTotesPageIndex,
  } = props;
  const { t } = useTranslation("surgical-tote-tracking");

  const [unwatchedTotes, setUnwatchedTotes] = useState([]);

  useEffect(() => {
    fetchWatchedTotes();
  }, [watchedTotesPageIndex, fetchWatchedTotes]);

  let unwatchTimers = {};

  const toggleWatch = (tote) => {
    let newUnwatchedTotes = [...unwatchedTotes];

    // If the table is pending a refresh, cancel it
    if (unwatchTimers[tote]) {
      clearTimeout(unwatchTimers[tote]);
    }

    if (newUnwatchedTotes.includes(tote)) {
      // Totes checkbox has already been unchecked - re-watch it
      newUnwatchedTotes = newUnwatchedTotes.filter((v) => v !== tote);
    } else {
      // Totes checkbox is checked - unwatch it
      newUnwatchedTotes.push(tote);
    }

    setUnwatchedTotes(newUnwatchedTotes);

    // Refresh the table after delay (gives the user time to undo a click)
    unwatchTimers[tote] = setTimeout(() => {
      fetchWatchedTotes();
    }, 2000);
  };

  // Render unchecked Tote rows with dimmed style
  const cellOpacity = (dim) => (dim ? 0.25 : 1);
  const CellRenderer = (cellInfo) => {
    const dim = unwatchedTotes.includes(cellInfo.row.original.container_uuid);
    return <div css={{ opacity: cellOpacity(dim) }}>{cellInfo.value}</div>;
  };

  const tableColumns = () => {
    return [
      {
        Header: t("surgical-tote-tracking:Watch"),
        accessor: "watch",
        width: 50,
        disableSortBy: true,
        disableResizing: true,
        centerAligned: true,
        Cell: (cellInfo) => {
          const dispatch = useDispatch();
          const tote = cellInfo.row.original.container_uuid;
          // API returns boolean in a string
          return (
            <WatchToggle
              // Forcing React to recreate this component when locationId changes
              // because of an issue in the component.
              key={tote}
              // Assumed true because it is in the watched table
              checked={true}
              onChange={(newWatchValue) => {
                dispatch(
                  SurgicalToteTrackingDetailsWidgetState.actionCreators.watchToteActions(
                    tote,
                    newWatchValue,
                  ),
                );
                toggleWatch(tote);
              }}
            />
          );
        },
      },
      {
        Header: t("surgical-tote-tracking:Asset ID"),
        accessor: "container_identifier",
        Cell: (cellInfo) => {
          const dim = unwatchedTotes.includes(
            cellInfo.row.original.container_uuid,
          );
          return (
            <div
              style={{
                opacity: cellOpacity(dim),
              }}
            >
              {cellInfo.row.original.commentsCount &&
              cellInfo.row.original.commentsCount > 0 ? (
                <Tooltip
                  placement="top"
                  tooltipChildren={
                    <Text>
                      {t("surgical-tote-tracking:This tote contains comments")}
                    </Text>
                  }
                >
                  <Icon
                    src={faCommentAlt}
                    color={Colors.comments.unreadCommentIcon}
                    style={{ marginRight: 8 }}
                  />
                </Tooltip>
              ) : null}
              <Text bold>{cellInfo.row.original.container_identifier}</Text>
            </div>
          );
        },
        minWidth: 200,
      },
      {
        Header: t("surgical-tote-tracking:Unique Device ID"),
        id: "contents",
        Cell: (cellInfo) => {
          const { value } = cellInfo;
          let uniqueDeviceIdList = value?.contents?.map(
            (content) => content.value,
          );

          return (
            <div css={{ display: "flex", flexDirection: "column" }}>
              {uniqueDeviceIdList?.length > 0 ? (
                <ShowMoreList
                  title={t("surgical-tote-tracking:Unique Device ID")}
                  list={uniqueDeviceIdList}
                  visibleItemCount={3}
                />
              ) : null}
            </div>
          );
        },
        accessor: (d) => {
          return {
            contents: d?.contents,
          };
        },
        width: 180,
        minWidth: 150,
      },
      {
        Header: t("surgical-tote-tracking:Asset Location"),
        accessor: "Status",
        Cell: CellRenderer,
        minWidth: 300,
      },
      {
        Header: t("surgical-tote-tracking:Last Update"),
        accessor: "last_update",
        Cell: (cellInfo) => {
          const last_update = cellInfo.row.original?.last_update;
          return <DateTimeCell dateTime={`${last_update}`} localize />;
        },
      },
    ];
  };

  const rowClickHandler = (row, cell) => {
    const { setSelectedToteId } = props;
    // Prevent navigation if clicking in "unwatch" checkbox cell
    if (cell.column.id === "watch") {
      return;
    }
    // Navigate to Tote Details when clicking row
    setSelectedToteId(row.original.container_uuid);
  };
  return (
    <PanelGroup collapsible style={{ marginTop: "1em" }}>
      <PanelGroup.Header
        title={t("surgical-tote-tracking:My Watched Assets")}
      />
      <PanelGroup.Content style={{ padding: 0 }}>
        <BaseTable
          data={watchedToteArray}
          columns={tableColumns()}
          theme={Themes.LIGHT}
          isLoading={watchedTotesLoading}
          rowClickHandler={rowClickHandler}
          showPagination={true}
          showPageSizeDropdown={false}
          isManualPagination={true}
          pageIndex={watchedTotesPageIndex}
          pageSize={watchedTotesPageSize}
          pageCount={watchedTotesPageCount}
          onPageChange={(newPage) => {
            setWatchedTotesPageIndex(newPage);
          }}
        />
      </PanelGroup.Content>
    </PanelGroup>
  );
};

SurgicalToteTrackingMyWatchedTotes.propTypes = {
  watchedToteArray: PropTypes.array,
  watchedTotesLoading: PropTypes.bool,
  watchedTotesPageIndex: PropTypes.number,
  watchedTotesPageSize: PropTypes.number,
  watchedTotesPageCount: PropTypes.number,
  fetchWatchedTotes: PropTypes.func.isRequired,
  setWatchedTotesPageIndex: PropTypes.func.isRequired,
  setSelectedToteId: PropTypes.func.isRequired,
};
