/** @jsxImportSource @emotion/react */
import { getMultiSelectQueryString } from "components/search-bar/search-filter-query-strings";
import { fuCodeFilterOptionsState } from "./CriticalParts.filterLoaders";
import { AsyncSelectFilterButton } from "components/search-bar/FilterButtons";
export const FILTERS = [
  {
    queryKey: "fuCodes",
    label: (t) => t("critical-parts:FU Codes"),
    optionsState: fuCodeFilterOptionsState,
    Component: AsyncSelectFilterButton,
    isMulti: true,
    hideFuzzySearch: true,
    hideSelectEmpty: true,
    queryBuilder: getMultiSelectQueryString,
  },
];
