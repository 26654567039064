import { connect } from "react-redux";
import { SurgicalToteDashboard } from "./SurgicalToteTracking.Dashboard.page";
import { SurgicalToteTrackingCountsWidgetState } from "../redux/SurgicalToteTrackingCountsWidgetState";
import SurgicalToteTrackingSearchBarState from "../redux/SurgicalToteTrackingSearchBarState";

function mapStateToProps(state) {
  return {
    totesTotals:
      SurgicalToteTrackingCountsWidgetState.selectors.getTotesTotals(state),
    isTotesTotalsLoading:
      SurgicalToteTrackingCountsWidgetState.selectors.getTotesTotalsLoadingFlag(
        state,
      ),
    exceptionTypes:
      SurgicalToteTrackingCountsWidgetState.selectors.getExceptionTypes(state),
    isExceptionTypesIsLoading:
      SurgicalToteTrackingCountsWidgetState.selectors.getExceptionTypesIsLoadingFlag(
        state,
      ),
  };
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const props = { ...stateProps, ...dispatchProps, ...ownProps };
  return props;
};

const { fetchTotesTotals, fetchExceptionTypes } =
  SurgicalToteTrackingCountsWidgetState.actionCreators;

const { searchEntities, setSearchFilter, resetSearchAndFilters } =
  SurgicalToteTrackingSearchBarState.actionCreators;

const actionCreators = {
  fetchTotesTotals,
  fetchExceptionTypes,
  searchEntities,
  setSearchFilter,
  resetSearchAndFilters,
};

export default connect(
  mapStateToProps,
  actionCreators,
  mergeProps,
)(SurgicalToteDashboard);
