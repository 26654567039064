import { connect } from "react-redux";

import { ErrorView } from "./Error.page";
import TitleState from "shared/redux/TitleState";

function mapStateToProps(state) {
  return {
    location: state.location,
    errorMessage: state.location?.payload?.errorMessage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTitle: (title) => dispatch(TitleState.actionCreators.setTitle(title)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorView);
