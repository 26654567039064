import { connect } from "react-redux";
import { FilterSection } from "components/search-bar/FilterSection";
import ContainerTrackingContainerTypeTableState from "pages/containertracking/redux/ContainerTrackingContainerTypeTableState";
import { CONTAINER_TYPE_FILTERS } from "../../../search/ContainerTrackingSearchFilterDefs";

const mapStateToProps = (state) => ({
  searchFilters:
    ContainerTrackingContainerTypeTableState.selectors.getSearchFilters(state),
});

const {
  setSearchFilter: setContainerTypeSearchFilter,
  clearSearchFilter: clearContainerTypeSearchFilter,
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
} = ContainerTrackingContainerTypeTableState.actionCreators;

const actionCreators = {
  setSearchFilter: (key, value) => {
    return (dispatch) => {
      dispatch(setContainerTypeSearchFilter(key, value));
      dispatch(searchEntities());
    };
  },
  clearSearchFilter: (key) => {
    return (dispatch) => {
      dispatch(clearContainerTypeSearchFilter(key));
      dispatch(searchEntities());
    };
  },
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  filtersMetadata: CONTAINER_TYPE_FILTERS,
});

export default connect(
  mapStateToProps,
  actionCreators,
  mergeProps,
)(FilterSection);
