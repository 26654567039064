import { connect } from "react-redux";

import {
  getSolutionId,
  getActiveOrganization,
} from "modules/organizations/OrganizationsState";

import { DamageViewDetails } from "./DamageView.Details.page";
import DamageViewDetailsSearchState from "../redux/DamageViewDetailsSearchState";
import DamageViewDamageFormState from "../redux/DamageViewDamageFormState";
import DamageViewDetailsState from "../redux/DamageViewDetailsState";
import NotificationsState from "modules/notifications/NotificationsState";
import DownloadPdfState from "../redux/DamageViewDownloadPdfState";
import { ProfileState } from "pages/profile/redux/ProfileState";

const mapStateToProps = (state) => {
  const submissionId = state.location.payload.submission_id;
  return {
    submissionId,
    solutionId: getSolutionId(state),
    organization: getActiveOrganization(state),
    searchResults:
      DamageViewDetailsSearchState.selectors.getSearchResults(state),
    isSearchLoading: DamageViewDetailsSearchState.selectors.getIsLoading(state),
    pageIndex: DamageViewDetailsSearchState.selectors.getPage(state),
    pageSize: DamageViewDetailsSearchState.selectors.getPageSize(state),
    pageCount: DamageViewDetailsSearchState.selectors.getTotalPages(state),
    isPhotoDataLoading:
      DamageViewDetailsState.selectors.getisPhotoDataLoading(state),
    photoData: DamageViewDetailsState.selectors.getPhotoData(state),
    // Comments
    comments: DamageViewDetailsState.selectors.getComments(state),
    isFetchingComments:
      DamageViewDetailsState.selectors.getIsFetchingComments(state),
    submissionDetailsRequestData:
      DamageViewDetailsState.selectors.getSubmissionDetailsRequestData(state),
    exportPDFIdentifier: DownloadPdfState.selectors.getExportIdentifier(state),
    exportPDFName: DownloadPdfState.selectors.getExportName(state),
    isExportingPDF: DownloadPdfState.selectors.getIsExporting(state),
    userPreference: ProfileState.selectors.getUserPreferences(state),
    fields: DamageViewDamageFormState.selectors.getFields(state),
    isInProgressStatusConfigured:
      DamageViewDetailsState.selectors.getIsInProgressStatusConfigured(state),
    inProgressStatusOptionsMapping:
      DamageViewDetailsState.selectors.getInProgressStatusOptionsMapping(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPagination: (solutionId, page, pageSize) =>
      dispatch(
        DamageViewDetailsSearchState.actionCreators.setPagination(
          solutionId,
          page,
          pageSize,
        ),
      ),
    searchEntities: () => {
      dispatch(DamageViewDetailsSearchState.actionCreators.searchEntities());
    },
    fetchPhotoData: (vinId, submissionId) => {
      dispatch(
        DamageViewDetailsState.actionCreators.fetchPhotoData(
          vinId,
          submissionId,
        ),
      );
    },
    fetchComments: (submissionId, pageNumber, pageSize) =>
      dispatch(
        DamageViewDetailsState.actionCreators.fetchComments(
          submissionId,
          pageNumber,
          pageSize,
        ),
      ),
    addComment: (submissionId, data) =>
      dispatch(
        DamageViewDetailsState.actionCreators.addComment(submissionId, data),
      ),
    markCommentsRead: (submissionId, dateTime) =>
      dispatch(
        DamageViewDetailsState.actionCreators.markCommentsRead(
          submissionId,
          dateTime,
        ),
      ),
    updateComment: (submissionId, commentId, updatedData) =>
      dispatch(
        DamageViewDetailsState.actionCreators.updateComment(
          submissionId,
          commentId,
          updatedData,
        ),
      ),
    cancelUpdateComment: (commentId) =>
      dispatch(
        DamageViewDetailsState.actionCreators.cancelUpdateComment(commentId),
      ),
    cancelAddComment: (fakeCommentId) =>
      dispatch(
        DamageViewDetailsState.actionCreators.cancelAddComment(fakeCommentId),
      ),
    fetchNotification: (params) =>
      dispatch(NotificationsState.actionCreators.fetchNotification(params)),
    fetchSubmissionDetails: (submissionId) =>
      dispatch(
        DamageViewDetailsState.actionCreators.fetchSubmissionDetails(
          submissionId,
        ),
      ),
    saveSubmissionChanges:
      DamageViewDetailsState.actionCreators.saveSubmissionChanges,
    exportPDFEntities: (submissionId, locale) => {
      dispatch(
        DownloadPdfState.actionCreators.exportAsyncPDF(submissionId, locale),
      );
    },
    resetPDFExports: () =>
      dispatch(DownloadPdfState.actionCreators.resetExport()),
    fetchFields: (ShipperSolutionId, submissionId = "") =>
      dispatch(
        DamageViewDamageFormState.actionCreators.fetchFields(
          ShipperSolutionId,
          submissionId,
        ),
      ),
    fetchStatusOptions:
      DamageViewDetailsState.actionCreators.fetchStatusOptions,
    fetchInProgressStatusOptions: (solutionId) =>
      dispatch(
        DamageViewDetailsState.actionCreators.fetchInProgressStatusOptions(
          solutionId,
        ),
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DamageViewDetails);
