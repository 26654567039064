import {
  getBasicQueryString,
  getEverythingQueryString,
} from "../../../components/search-bar/search-filter-query-strings";

export const SEARCH_CATEGORIES = [
  {
    queryKey: "everything",
    label: (t) => t("milestone-search:Search Everything"),
    placeholder: (t) =>
      t(
        "milestone-search:Search VIN, Location, Milestone Event, Milestone Status Code",
      ),
    queryBuilder: getEverythingQueryString,
    property: null, // meaning all other properties
  },
  {
    queryKey: "locationsCodes",
    label: (t) => t("milestone-search:Location"),
    placeholder: (t) => t("milestone-search:Search Location"),
    queryBuilder: getBasicQueryString,
  },
  {
    queryKey: "milestoneEvents",
    label: (t) => t("milestone-search:Milestone Event"),
    placeholder: (t) => t("milestone-search:Search Milestone Event"),
    queryBuilder: getBasicQueryString,
  },
  {
    queryKey: "milestoneCodes",
    label: (t) => t("milestone-search:Milestone Status Code"),
    placeholder: (t) => t("milestone-search:Search Milestone Status Code"),
    queryBuilder: getBasicQueryString,
  },
];
