/** @jsxImportSource @emotion/react */
import { useTranslation, Trans } from "react-i18next";
import { DateTime, FontSize } from "components/atoms/DateTime.atom";
import { Text } from "components/atoms/Text.atom";
import { ReactNode } from "react";

type WindowDateTimeRangeProps = {
  from?: string;
  to?: string;
  localize?: boolean;
  showTime?: boolean;
  size?: FontSize;
  "data-qa"?: string;
};

/**
 * Displays a datetime range for shipment schedules. If the upper and lower values are the same, it will display a single datetime.
 * It will also display a single datetime if only the upper or lower value is provided.
 *
 * @example as a range
 * <WindowDateTimeRange from="2024-02-02T00:00:00" to="2024-02-02T23:59:59" />
 * // 00:00 UTC 02/02/2024 to
 * // 23:59 UTC 02/02/2024
 *
 * @example with only one value
 * <WindowDateTimeRange from="2024-02-02T00:00:00" />
 * // 00:00 UTC
 * // 02/02/2024
 *
 */
export const WindowDateTimeRange = ({
  from,
  to,
  localize = false,
  showTime = true,
  size = FontSize.size14,
  "data-qa": dataQa,
}: WindowDateTimeRangeProps) => {
  const { t } = useTranslation("shipment-details");

  const emptyText = t("shipment-details:N/A");
  const sharedProps = {
    plain: true,
    bold: true,
    localize,
    size,
    showTime,
    fallback: emptyText,
  };

  let content: ReactNode = emptyText;
  if (!from && !to) {
    content = emptyText;
  } else if (from === to) {
    content = (
      <DateTime {...sharedProps} dateTime={to}>
        <DateTime.Date bold={false} />
      </DateTime>
    );
  } else if (from && to && from !== to) {
    content = (
      <Trans ns="shipment-details">
        <Text bold block size={size}>
          <DateTime {...sharedProps} dateTime={from} /> to{" "}
        </Text>
        <Text block size={size}>
          <DateTime {...sharedProps} bold={false} dateTime={to} />
        </Text>
      </Trans>
    );
  } else if (to) {
    content = (
      <DateTime {...sharedProps} stack dateTime={to}>
        <DateTime.Date bold={false} />
      </DateTime>
    );
  } else if (from) {
    content = (
      <DateTime {...sharedProps} stack dateTime={from}>
        <DateTime.Date bold={false} />
      </DateTime>
    );
  }

  return (
    <Text size={size} data-qa={dataQa}>
      {content}
    </Text>
  );
};
