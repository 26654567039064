import PropTypes from "prop-types";

import Colors from "styles/colors";
import { useTranslation } from "react-i18next";
import { Button } from "components/atoms/Button.atom";
import { Text } from "components/atoms/Text.atom";

const linkStyle = {
  width: "100%",
  textAlign: "left",
  color: Colors.text.DARK_BLUE,
  fontSize: "1em",
  fontWeight: 400,
  display: "block",
  ":hover": {
    color: Colors.text.DARK_BLUE,
    cursor: "pointer",
  },
};

const buttonStyle = {
  color: Colors.text.DARK_BLUE,
  ":hover": {
    color: Colors.background.DARK_BLUE,
  },
  display: "flex",
  alignItems: "center",
  whiteSpace: "nowrap",
  padding: 0,
};

const LinkRenderer = ({ value }) => {
  const { resource, title, linkType, rowClickHandler } = value;

  let columnElement = null;

  if (linkType === "report") {
    const reportId = resource;

    columnElement = (
      <Button
        variant="link"
        style={buttonStyle}
        onClick={() => rowClickHandler(reportId)}
      >
        <Text
          css={{
            color: Colors.text.DARK_BLUE,
            ":hover": {
              color: Colors.background.DARK_BLUE,
            },
          }}
        >
          {title}
        </Text>
      </Button>
    );
  }

  if (linkType === "external") {
    columnElement = (
      <Button variant="link" style={buttonStyle}>
        <Text>
          <a href={resource} target="_blank" rel="noreferrer" style={linkStyle}>
            {title}
          </a>
        </Text>
      </Button>
    );
  }

  return columnElement;
};

LinkRenderer.propTypes = {
  value: PropTypes.shape({
    title: PropTypes.string,
    rowClickHandler: PropTypes.func,
    linkType: PropTypes.string,
  }),
};

export const useComplianceLinksTable = (rowClickHandler) => {
  const { t } = useTranslation("partner-portal");
  let columns = [];
  columns.push({
    Header: t("partner-portal:Link"),
    accessor: (row) => {
      return {
        resource: row.resource,
        title: row.title,
        linkType: row.type,
        rowClickHandler: rowClickHandler,
      };
    },
    Cell: LinkRenderer,
  });

  return columns;
};
