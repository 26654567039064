/** @jsxImportSource @emotion/react */
import { useSelector } from "react-redux";
import SelectFilter from "components/organisms/base-table/Filter/SelectFilter";
import DomainDataState from "modules/domain-data/DomainDataState";
import PropTypes from "prop-types";

// Dropdown filter allowing selection of Shipper on Manage Locations search results table
const ShipperFilter = (props) => {
  const { value, onChange } = props;

  const shippers = useSelector(DomainDataState.selectors.getShippers);
  const options = shippers.map((s) => {
    return { value: s.shipper_organization_id, label: s.shipper_name };
  });

  return (
    <SelectFilter
      placeholder={null}
      isClearable={true}
      stateValue={value}
      options={options}
      onChange={(selected) => {
        const selectedValue = selected ? selected.value : undefined;
        onChange(selectedValue);
      }}
    />
  );
};

ShipperFilter.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
};

export default ShipperFilter;
