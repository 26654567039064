import { connect } from "react-redux";
import { EditSavedSearchModal } from "components/saved-search/EditSavedSearchModal";
import SurgicalToteTrackingSearchBarState from "../../redux/SurgicalToteTrackingSearchBarState";
import SurgicalToteTrackingSavedSearchState from "../../redux/SurgicalToteTrackingSavedSearchState";
import SearchBarContainer from "./SurgicalToteTrackingSearchBarContainer";
import FilterSectionContainer from "./SurgicalToteTrackingSearchFiltersContainer";
import { getSolutionId } from "../../utils/SurgicalToteTrackingUtils";
import { getFeatureData } from "modules/organizations/OrganizationsState";

const mapStateToProps = (state) => {
  const { getSearchFilters, getSearchCategory, getSearchText } =
    SurgicalToteTrackingSearchBarState.selectors;

  return {
    searchFilters: getSearchFilters(state),
    searchCategory: getSearchCategory(state),
    searchText: getSearchText(state),
    SearchBarContainer: SearchBarContainer,
    FilterSectionContainer: FilterSectionContainer,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { saveSearch, editSearch } =
    SurgicalToteTrackingSavedSearchState.actionCreators;

  function saveSearchDispatch(name, search) {
    return (dispatch, getState) => {
      let state = getState();
      let featureData = getFeatureData(state);
      const solution_id = getSolutionId(featureData);
      dispatch(saveSearch(name, search, solution_id));
    };
  }

  return {
    saveSearch: (name, search) => {
      return dispatch(saveSearchDispatch(name, search));
    },
    editSearch: (id, name, search) => dispatch(editSearch(id, name, search)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditSavedSearchModal);
