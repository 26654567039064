import _ from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { formatDuration } from "utils/date-time";
import { convertMeasurementUnits } from "utils/measurement-utils";
import { DiagnosticState, DiagnosticStateColors } from "api/consts";

export const useDiagnosticDataFormatter = () => {
  const { t } = useTranslation("diagnostic");

  return {
    getDiagonsticLabel: (label) => {
      const labelData = {
        leftfronttirepressure: t("Left Front Tire Pressure"),
        rightfronttirepressure: t("Right Front Tire Pressure"),
        leftreartirepressure: t("Left Rear Tire Pressure"),
        rightreartirepressure: t("Right Rear Tire Pressure"),
        batterystateofchargelow: t("Battery State Of Charge Low"),
        batterystateofchargecritical: t("Battery State Of Charge Critical"),
        batterystateofcharge: t("Battery State Of Charge"),
        longitude: t("Longitude"),
        latitude: t("Latitude"),
        fuellevel: t("Fuel Level"),
        lowfuelpercentage: t("Low Fuel Percentage"),
        presaleodometer: t("Presale Odometer"),
        frontplacard: t("Front Placard"),
        rearplacard: t("Rear Placard"),
        statuscode: t("Status Code"),
        odometer: t("Odometer"),
        modemversion: t("Modem Version"),
        datagoodtouse: t("Data Good to Use"),
        leftrearinnertirepressure: t("Left Rear Inner Tire Pressure"),
        rightrearinnertirepressure: t("Right Rear Inner Tire Pressure"),
        bevdistancetoempty: t("BEV Distance to Empty"),
        bevsocpercent: t("BEV SOC Percent"),
        bevsocvalue: t("BEV SOC Value"),
        bevbatterytemperature: t("BEV Battery Temperature"),
        lowtirepressurealert: t("Low Tire Pressure Alert"),
      };
      return labelData[label];
    },
    getColorByState: (state) => {
      return DiagnosticStateColors[
        Object.keys(DiagnosticState).filter(
          (key) => DiagnosticState[key] === state,
        )
      ];
    },
  };
};

// Sorts the given exception array using the exception name parameter,
// in some places this is exception.name, on others it's exception.text
export const sortEntityExceptions = (exceptions, nameAttr) => {
  const withSortedAttr = exceptions.map((exception) => {
    let sortOrder = 0;
    switch (exception[nameAttr]) {
      case "Behind Schedule":
        sortOrder = 0;
        break;
      case "On Hold":
        sortOrder = 1;
        break;
      case "Excessive Dwell":
        sortOrder = 2;
        break;
      default:
        sortOrder = 3;
        break;
    }
    return { ...exception, sort: sortOrder };
  });

  let sortedExceptions = _.sortBy(withSortedAttr, "sort");

  // Remove the "sort" property as it is no longer used outside of this function.
  sortedExceptions.forEach((s) => delete s.sort);
  return sortedExceptions;
};

export function getTransitDuration(eventUpdates) {
  // For both start and end, find the most recent event (reverse sort by eventTs)
  const startTransitEvent = _.sortBy(eventUpdates, ["eventTs"])
    .reverse()
    .find((event) => event.subcode === "StartTransitTimer");
  const endTransitEvent = _.sortBy(eventUpdates, ["eventTs"])
    .reverse()
    .find((event) => event.subcode === "EndTransitTimer");

  // put into moments for easier manipulation (default to now if not present)
  // NOTE: moment is local by default, but values from backend are UTC
  let endMoment = !_.isNil(endTransitEvent?.eventTs)
    ? moment.utc(endTransitEvent?.eventTs)
    : moment();
  let startMoment = !_.isNil(startTransitEvent?.eventTs)
    ? moment.utc(startTransitEvent?.eventTs)
    : null;

  // if no start event, display 0
  if (!startMoment) {
    return formatDuration(0);
  }
  // end cant be before start
  else if (endMoment.isBefore(startMoment)) {
    endMoment = new moment();
  }

  return formatDuration(moment.duration(endMoment.diff(startMoment)));
}

export const translateCurrentPositionType = (t, positionType) => {
  if (positionType) {
    switch (positionType.toLowerCase()) {
      case "onthewater":
        return t("On the Water");
      case "onrail":
        return t("On Rail");
      case "ontheroad":
        return t("On the Road");
      case "atlocation":
        return t("At Location");
      default:
        return null;
    }
  }
};

export const getCurrentPositionDetails = (details, t) => {
  // If there isn't a `lastPositionUpdate` field, return null so we don't have
  // to check fields in the object that's usually returned here.
  if (_.isNil(details.lastPositionUpdate)) {
    return null;
  }

  const currentPositionCode = details.lastPositionUpdate?.locationCode
    ? details.lastPositionUpdate.locationCode
    : null;

  const currentPositionType = translateCurrentPositionType(
    t,
    details.lastPositionUpdate?.positionType,
  );

  let currentPositionName = null;
  if (details.lastPositionUpdate?.locationName) {
    // from MilestoneTelematics
    currentPositionName = details.lastPositionUpdate.locationName;
  } else if (details.lastPositionUpdate?.references) {
    currentPositionName = _.find(details.lastPositionUpdate.references, [
      "qualifier",
      "locationName",
    ])?.value;
  }

  const currentPositionGeo = details.lastPositionUpdate?.references
    ? _.find(details.lastPositionUpdate.references, [
        "qualifier",
        "geofenceName",
      ])?.value
    : "";

  const getFullLocationName = () => {
    if (currentPositionCode || currentPositionName) {
      if (currentPositionCode && currentPositionName) {
        return `${currentPositionName} (${currentPositionCode}) ${
          currentPositionGeo ? currentPositionGeo : ""
        }`;
      } else if (currentPositionCode) {
        return `(${currentPositionCode})`;
      } else if (currentPositionName) {
        return `${currentPositionName}`;
      }
    } else {
      return null;
    }
  };

  return {
    currentPositionType: currentPositionType,
    currentPositionName: getFullLocationName(),
    source: details.lastPositionUpdate?.sourceType,
    ...details.lastPositionUpdate,
  };
};

export const getRefTranslationKey = (ref) => ref.replace("-", "").toLowerCase();

export const getData = (ref) => ref.replace("-", "").toLowerCase();

export const getValueFromData = (data, field) => {
  return _.find(data, (element) => element.qualifier === field)?.value;
};

// Use translated unit if available, else pass it through (including if null)
export const getUnit = (t, data, fieldName) => {
  // NOTE: this will need to expand as we get more types of units
  //       so far, we are only doing this when the unit is spelled out
  const mapUnitToTranslation = {
    // Degree is virtually always plural
    degree: t("fv-vin-details:degrees"),
  };
  const unit = getValueFromData(data, fieldName);
  return unit && mapUnitToTranslation[unit] ? mapUnitToTranslation[unit] : unit;
};

export const formatDiagnostics = (data, fieldName, units, getColorByState) => {
  let preferredUnit;
  let value = getValueFromData(data, fieldName);
  // Color info comes from the associated 'State' field for a reference
  // State reference values are mapped based on mapStateToColor above
  // example:
  //    'LeftFrontTirePressureState' with a value of 'warning' will result in a color of Yellow
  //
  // FIN-5504: State values should be lowercased before used to get the text color.
  //           This allows us to handle cases like "Warning".
  const fieldState =
    getValueFromData(data, `${fieldName}State`)?.toLowerCase() ?? null;
  const color = fieldState ? getColorByState(fieldState) : null;

  if (units) {
    let converted = convertMeasurementUnits({
      unit: units,
      value: value,
    });
    preferredUnit = converted.preferredUnit;
    value = converted.convertedValue;
  }

  return value
    ? { value, color, unit: preferredUnit ? preferredUnit : units }
    : {};
};

// H2-1393: clean/format diagnostics references for display in the tab
export const cleanDiagnosticsData = (
  t,
  data,
  getDiagonsticLabel,
  getColorByState,
) => {
  const fuelLevelUnits = getUnit(t, data, "FuelLevelUnits");
  const tirePressureUnits = getUnit(t, data, "TirePressureUnits");
  const coordPartUnits = getUnit(t, data, "GeoCoordinateUnits");
  const odometerUnits = getUnit(t, data, "OdometerUnits");
  const batteryStateOfChargeUnits = getUnit(
    t,
    data,
    "BatteryStateOfChargeUnits",
  );
  const bevSocPercentUnits = getUnit(t, data, "BEVSOCPercentUnits");
  const bevSocValueUnits = getUnit(t, data, "BEVSOCValueUnits");
  const bevBatteryTempUnits = getUnit(t, data, "BEVBatteryTemperatureUnits");
  const lowFuelPercentageUnits = getUnit(t, data, "LowFuelPercentageUnits");

  const validDiagnosticsFields = {
    leftfronttirepressure: {
      units: tirePressureUnits,
    },
    rightfronttirepressure: {
      units: tirePressureUnits,
    },
    leftreartirepressure: {
      units: tirePressureUnits,
    },
    rightreartirepressure: {
      units: tirePressureUnits,
    },
    batterystateofchargelow: {},
    batterystateofchargecritical: {},
    batterystateofcharge: {
      units: batteryStateOfChargeUnits,
    },
    longitude: {
      units: coordPartUnits,
    },
    latitude: {
      units: coordPartUnits,
    },
    fuellevel: {
      units: fuelLevelUnits,
    },
    lowfuelpercentage: {
      units: lowFuelPercentageUnits,
    },
    presaleodometer: {},
    frontplacard: {
      units: tirePressureUnits,
    },
    rearplacard: {
      units: tirePressureUnits,
    },
    statuscode: {},
    odometer: {
      units: odometerUnits,
    },
    modemversion: {
      sortOrder: 0,
    },
    datagoodtouse: {},
    leftrearinnertirepressure: {
      units: tirePressureUnits,
    },
    rightrearinnertirepressure: {
      units: tirePressureUnits,
    },
    bevdistancetoempty: {
      units: odometerUnits,
    },
    bevsocpercent: {
      units: bevSocPercentUnits,
    },
    bevsocvalue: {
      units: bevSocValueUnits,
    },
    bevbatterytemperature: {
      units: bevBatteryTempUnits,
    },
    lowtirepressurealert: {},
  };

  // filter out any non-diagnostics qualifiers or empty values
  const filteredData = data.filter(
    (item) =>
      item.qualifier.toLowerCase() in validDiagnosticsFields &&
      !_.isNil(item.value),
  );

  // Sort first by priority sort order, then by label name
  const sortedData = _.sortBy(
    filteredData,
    (item) => validDiagnosticsFields[item.qualifier.toLowerCase()].sortOrder,
    (item) => getDiagonsticLabel(item.qualifier.toLowerCase()),
  );

  // Format qualifiers and their units as we'd like the user to see
  return sortedData.map((item) => ({
    name: getDiagonsticLabel(item.qualifier.toLowerCase()),
    qualifier: item.qualifier,
    ...formatDiagnostics(
      data,
      item.qualifier,
      validDiagnosticsFields[item.qualifier.toLowerCase()].units,
      getColorByState,
    ),
    type: "Diagnostics",
    details: item.details,
  }));
};

const validOrderandDetailsFields = {
  "90dayrollingavg": "90 Day Rolling Average",
  columnnumbernavl: "Column Number NAVL",
  dealer: "Dealer",
  "dealer code": "Dealer Code",
  dealercounty: "Dealer County",
  dealerfips: "Dealer FIPS",
  dealersplc: "Dealer SPLC",
  destinationfvccode: "Destination FVC Code",
  destinationmodifier: "Destination Modifier",
  destinationportprocessor: "Destination Port Processor",
  destinationroutecode: "Destination Route Code",
  destinationupfitter: " Upfitter",
  division: "Division",
  intermediateyardoperator: "Intermediate Yard Operator",
  lanecategory: "Lane Category",
  last8ofvin: "Last 8 Of VIN",
  orderingdealercode: "Ordering Dealer Code",
  originemissionswindtunneltesting: "Origin Emissions Wind Tunnel Testing",
  originmodifier: "Origin Modifier",
  originportprocessor: "Origin Port Processor",
  originroutecode: "Origin Route Code",
  originupfitter: "Origin Upfitter",
  originyardoperator: "Origin Yard Operator",
  plantfvc: "Plant FVC",
  shipmentmethodcode: "Shipment Method Code",
  "target delivery date": "Target Delivery Date",
  wheelbase: "Wheelbase",
  idbuyercolor: "ID Buyer Color",
  descbuyercolor: "Desc Buyer Color",
  vehicleordertype: "Vehicle Order Type",
  vehiclelinedescription: "Vehicle Line Description",
  bodystyle: "Body Style",
  cab: "Cab",
  portofload: "Port Of Load",
  portofdischarge: "Port Of Discharge",
  marketbusinessunit: "Market Business Unit",
  markettype: "Market Type",
  secondarypol: "Secondary POL",
  secondarypod: "Secondary POD",
  transshipport: "Trans Ship Port",
  destemissionswindtunneltesting: "Dest Emissions Wind Tunnel Testing",
  barcode: "barcode",
  created: "created",
  decodedmanufacturerid: "Decoded-ManufacturerId",
  trackedassetid: "TrackedAssetId",
  decodedmotorcyclechassistype: "Decoded-MotorcycleChassisType",
  orderorderingdealercode: "Order-OrderingDealerCode",
  flavour: "flavour",
  decodedplantcountry: "Decoded-PlantCountry",
  batteryversion: "batteryVersion",
  heattreatmentdate: "heatTreatmentDate",
  decodedengineconfiguration: "Decoded-EngineConfiguration",
  decodederrorcode: "Decoded-ErrorCode",
  title: "title",
  containercapacity: "containerCapacity",
  decodeddisplacementci: "Decoded-DisplacementCI",
  decodedmanufacturer: "Decoded-Manufacturer",
  decodedtrim: "Decoded-Trim",
  decodedadditionalerrortext: "Decoded-AdditionalErrorText",
  decodedmodel: "Decoded-Model",
  orderingdealername: "OrderingDealerName",
  decodedbrakesystemtype: "Decoded-BrakeSystemType",
  decodedmessage: "Decoded-message",
  thing: "thing",
  actualquantity: "actualQuantity",
  decodederrortext: "Decoded-ErrorText",
  decodedcustommotorcycletype: "Decoded-CustomMotorcycleType",
  year: "Year",
  racklocation: "rackLocation",
  cspcid: "cspcId",
  decodedtrim2: "Decoded-Trim2",
  decodedenginecylinders: "Decoded-EngineCylinders",
  orderoriginemissionswindtunneltesting:
    "Order-OriginEmissionsWindTunnelTesting",
  decodedplantstate: "Decoded-PlantState",
  assetlongitude: "assetLongitude",
  decodedmodelyear: "Decoded-ModelYear",
  expectedlocation: "expectedLocation",
  decodedtrailerbodytype: "Decoded-TrailerBodyType",
  decodedfueltypesecondary: "Decoded-FuelTypeSecondary",
  soldstatus: "SoldStatus",
  containermacid: "containerMacId",
  orderdivision: "Order-Division",
  decodedotherengineinfo: "Decoded-OtherEngineInfo",
  decodedimageurl: "Decoded-ImageURL",
  orderlanecategory: "Order-LaneCategory",
  tempwarningdegreesc: "TempWarningDegreesC",
  decodedbusfloorconfigtype: "Decoded-BusFloorConfigType",
  decodedseries: "Decoded-Series",
  decodeddisplacementcc: "Decoded-DisplacementCC",
  assetlatitude: "assetLatitude",
  decodeddoors: "Decoded-Doors",
  decodedmotorcyclesuspensiontype: "Decoded-MotorcycleSuspensionType",
  dealercode: "DealerCode",
  customername: "CustomerName",
  orderplantfvc: "Order-PlantFVC",
  maxquantity: "maxQuantity",
  heattreatdate: "heatTreatDate",
  color: "color",
  serialnumber: "SerialNumber",
  decodedfueltypeprimary: "Decoded-FuelTypePrimary",
  vehiclepriority: "VehiclePriority",
  partdescription: "partDescription",
  decodedbodyclass: "Decoded-BodyClass",
  decodedairbaglocside: "Decoded-AirBagLocSide",
  plantid: "plantId",
  model: "Model",
  decodedmake: "Decoded-Make",
  orderoriginroutecode: "Order-OriginRouteCode",
  physicallocation: "physicalLocation",
  ordertype: "OrderType",
  ordernumber: "Order Number",
  passenger: "Passenger",
  decodedplantcity: "Decoded-PlantCity",
  decodedelectrificationlevel: "Decoded-ElectrificationLevel",
  created_at: "created_at",
  trackedassettype: "TrackedAssetType",
  usebydate: "useByDate",
  decodedenginemanufacturer: "Decoded-EngineManufacturer",
  make: "Make",
  decodedsuggestedvin: "Decoded-SuggestedVIN",
  decodedseatbeltsall: "Decoded-SeatBeltsAll",
  cspc: "cspc",
  decodedvin: "Decoded-Vin",
  wheel_count: "wheel_count",
  decodedtrailertype: "Decoded-TrailerType",
  decodedairbaglocknee: "Decoded-AirBagLocKnee",
  decodeddrivetype: "Decoded-DriveType",
  deviceversion: "deviceVersion",
  beaconid: "beaconId",
  decodedtpms: "Decoded-TPMS",
  statuscode: "StatusCode",
  decodedfault: "Decoded-Fault",
  decodeddisplacementl: "Decoded-DisplacementL",
  driver: "Driver",
  tempcriticaldegreesc: "TempCriticalDegreesC",
  trim: "Trim",
  containerserialno: "containerSerialNo",
  decodedplantcompanyname: "Decoded-PlantCompanyName",
  decodedgvwr: "Decoded-GVWR",
  decodedairbaglocfront: "Decoded-AirBagLocFront",
  decodedpossiblevalues: "Decoded-PossibleValues",
  orderorderingdealername: "Order-OrderingDealerName",
  orderlast8ofvin: "Order-Last8OfVIN",
  decodedbustype: "Decoded-BusType",
  decodedvehicletype: "Decoded-VehicleType",
  fuellevelunits: "FuelLevelUnits",
  lowfuelpercentage: "Low Fuel Percentage",
  frontplacard: "Front Placard",
  rightreartirepressure: "Right Rear Tire Pressure",
  geocoordinateunits: "Geo Coordinate Units",
  latitude: "Latitude",
  leftreartirepressure: "Left Rear Tire Pressure",
  presaleodometer: "Presale Odometer",
  fuellevel: "Fuel Level",
  batterystateofchargelow: "Battery State Of Charge Low",
  longitude: "Longitude",
  batterystateofchargecritical: "Battery State Of Charge Critical",
  rightfronttirepressure: "Right Front Tire Pressure",
  leftfronttirepressure: "Left Front Tire Pressure",
  tirepressureunits: "Tire Pressure Units",
  rearplacard: "Rear Placard",
  testdiagnosticsqualifier: "Test diagnostics qualifier",
  statusCode: "Status Code",
  bailment: "Bailment",
  bailmentparty: "Bailment Party",
  converter: "Converter",
  converterparty: "Converter Party",
  fence: "Fence",
  fenceparty: "Fence Party",
  finaldeliveryinvoice: "Final Delivery Invoice",
  finaldeliveryinvoiceparty: "Final Delivery Invoice Party",
  soldtodealer: "Sold To Dealer",
  shiptodealer: "Ship To Dealer",
  estimatedproductiondate: "Estimated Production Date",
  itemnumber: "Order Number",
  salesreceiptdate: "Sale Receipt Date",
  currentbuildweek: "Build week",
  equipmentgroupfeature: "Equipment Group Feature",
  equipmentgroupfeaturedescription: "Equipment Group Feature Description",
  interiortrimfeaturedescription: "Interior Trim Feature Description",
  enduserfincode: "End User FIN Code",
  fincode: "FIN Code",
  lowtirepressurealert: "Low Tire Pressure Alert",
};

export const filteredCombinedExceptions = (
  combinedExceptions,
  shouldFilterExceptions,
) => {
  const filteredExceptions = combinedExceptions.filter(
    (e) => !["On Hold", "In Hold"].includes(e.typeName),
  );
  return shouldFilterExceptions ? filteredExceptions : combinedExceptions;
};

export const getOrderDetails = (t, details) => {
  const references = details && details.references ? details.references : [];
  // H2-835: Set fields chosen for now
  // Get all order details field and put a "pretty" label on them
  const orderDetails = _.sortBy(
    references
      .filter((item) => {
        return (
          item?.type !== null &&
          !["diagnostics"].includes(item?.type?.toLowerCase())
        );
      })
      .map((item) => {
        return {
          value: item.value,
          qualifier: item.qualifier,
        };
      }),
    "qualifier",
  );
  return orderDetails;
};

export const getVinEquipment = (details) => {
  const references = details && details.references ? details.references : [];
  const vinEquipment = _.sortBy(
    references
      .filter(
        (item) => !["order", "diagnostics"].includes(item?.type?.toLowerCase()),
      )
      .map((item) => ({
        value: item.value,
        qualifier:
          getRefTranslationKey(item.qualifier) in validOrderandDetailsFields
            ? validOrderandDetailsFields[getRefTranslationKey(item.qualifier)]
            : item.qualifier,
      })),
    "qualifier",
  );
  return vinEquipment;
};

export const getDiagnosticsData = (
  t,
  details,
  showDiagnosticDataTab,
  getDiagonsticLabel,
  getColorByState,
) => {
  const references = details && details.references ? details.references : [];
  let diagnosticsData;
  if (showDiagnosticDataTab) {
    diagnosticsData = cleanDiagnosticsData(
      t,
      references.filter((item) => item.type === "Diagnostics"),
      getDiagonsticLabel,
      getColorByState,
    );
  }
  return diagnosticsData;
};

export const getCombinedExceptions = () => {};
