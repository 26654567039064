/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import styled from "@emotion/styled";
import Loader from "react-loader";
import RoutingMap from "../map/components/RoutingMap";
import ShipmentContentPanel from "./ShipmentContentPanel";
import MultimodalShipmentContentPanel from "./MultimodalShipmentContentPanel";
import { AddAssetModal } from "./AddAssetModal";
import { UnassignAssetModal } from "./UnassignAssetModal";
import { ShipmentEventsModal } from "./ShipmentEventsModal";
import { CancelShipmentModal } from "./CancelShipmentModal";
import { MobileTrackingModal } from "./MobileTrackingModal";
import {
  ReportCarrierDelayModal,
  ClearCarrierDelayModal,
} from "./ReportCarrierDelayModal";
import { isShipper, isCarrier } from "shared/utils/organizations.utils";
import {
  getAssetId,
  getRouteShipments,
} from "../shipment-detail/ShipmentUtils";
import {
  getMobileTrackingEnabled,
  getIsShipmentWithinTrackingWindow,
} from "./ShipmentUtils";
import { MediaQueries, useIsMediumAndDown } from "../../components/responsive";
import Colors from "../../styles/colors";
import MixpanelUtils from "../../trackers/mixpanel";
import { HeatMapButton } from "components/atoms/HeatMapButton.atom";
import { AlertMeModal } from "shared/components/modals/AlertMe.modal";
import { BillOfLadingModal } from "./BillOfLadingModal";
import { Mode } from "shared/constants/shipments.const";

export const Section = styled.section({
  position: "relative",
  display: "flex",
  flexGrow: 3,
  flexDirection: "column",
  justifyContent: "space-between",
  height: "calc(100vh - 3.3em)",
  backgroundColor: "white",
  maxWidth: "50%",
});

const OneColumnSection = styled(Section)((props) => ({
  height: "calc(40vh - 3em)",
  maxWidth: "100%",
  flexGrow: props.isMobile && props.halfHeight ? 1 : 3,
}));

export const ResponsiveSection = (props) => {
  const isMobile = useIsMediumAndDown();
  const halfHeight = Boolean(props.halfHeight);
  const SectionComponent = isMobile ? OneColumnSection : Section;
  return (
    <SectionComponent {...props} isMobile={isMobile} halfHeight={halfHeight}>
      {props.children}
    </SectionComponent>
  );
};

ResponsiveSection.propTypes = {
  children: PropTypes.node.isRequired,
  halfHeight: PropTypes.bool,
};

class ShipmentDetailView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    setSubTitle: PropTypes.func.isRequired,
    customerView: PropTypes.bool, // If true, scac and creatorShipmentID must come in the location payload
    pushLocationMatchingView: PropTypes.func.isRequired,
    shipment: PropTypes.object.isRequired,
    routeHeatmap: PropTypes.array,
    setShipmentWithUnresolvedLocation: PropTypes.func.isRequired,
    shipmentID: PropTypes.any,
    fetchShipmentDetails: PropTypes.func.isRequired,
    clearShipmentDetails: PropTypes.func.isRequired,
    fetchShipmentDetailsFromCarrierInfo: PropTypes.func.isRequired,
    fetchRouteHeatmap: PropTypes.func.isRequired,
    ladsList: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
    assignAsset: PropTypes.func.isRequired,
    unassignAsset: PropTypes.func.isRequired,
    assignTrailer: PropTypes.func.isRequired,
    createShipmentEvents: PropTypes.func.isRequired,
    activeOrganization: PropTypes.object.isRequired,
    cancelShipment: PropTypes.func.isRequired,
    startMobileTracking: PropTypes.func.isRequired,
    pushShipmentDetailView: PropTypes.func.isRequired,
    shipmentModes: PropTypes.array.isRequired,
    shippers: PropTypes.arrayOf(
      PropTypes.shape({
        shipper_organization_id: PropTypes.number,
        shipper_name: PropTypes.string,
        shipper_fv_id: PropTypes.string,
      }),
    ),
    childShipments: PropTypes.object.isRequired,
    finishedVehicleView: PropTypes.node.isRequired,
    scac: PropTypes.string,
    creatorShipmentID: PropTypes.string,
    fetchShipmentDetailsFromVin: PropTypes.func.isRequired,
    vin: PropTypes.string,
    childShipmentID: PropTypes.string,
    stopMobileTracking: PropTypes.func.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    setWatchShipment: PropTypes.func.isRequired,
    fetchShipmentTripPlan: PropTypes.func.isRequired,
    tripPlan: PropTypes.array,
    tripPlanIsLoading: PropTypes.bool,
    fetchComments: PropTypes.func.isRequired,
    isFetchingComments: PropTypes.bool,
    comments: PropTypes.shape({
      totalPages: PropTypes.number,
      totalCount: PropTypes.number,
      totalCountUnread: PropTypes.number,
      data: PropTypes.array,
    }),
    addComment: PropTypes.func.isRequired,
    cancelAddComment: PropTypes.func.isRequired,
    updateComment: PropTypes.func.isRequired,
    cancelUpdateComment: PropTypes.func.isRequired,
    markCommentsRead: PropTypes.func.isRequired,
    addBatchComments: PropTypes.func.isRequired,
    clearBatchComments: PropTypes.func.isRequired,
    isBatchCommentInProgress: PropTypes.bool.isRequired,
    isBatchCommentSuccessful: PropTypes.bool.isRequired,
    isBatchCommentFailed: PropTypes.bool.isRequired,
    fetchNotification: PropTypes.func.isRequired,
    // Map Coordinates
    addCoordinate: PropTypes.func.isRequired,
    clearCoordinatesByType: PropTypes.func.isRequired,
    selectedMapCoordinate: PropTypes.object,
    // Report/Clear Carrier Delay props
    editShipmentData: PropTypes.shape({
      data: PropTypes.any,
      isLoading: PropTypes.bool,
      isLoadingError: PropTypes.bool,
      loadingError: PropTypes.string,
      status: PropTypes.number,
      statusArray: PropTypes.array,
      isEditShipmentLoading: PropTypes.bool,
      isEditShipmentLoadingError: PropTypes.bool,
      editShipmentLoadingError: PropTypes.string,
      editShipmentStatus: PropTypes.number,
    }),
    reportDelay: PropTypes.func.isRequired,
    reportDelayData: PropTypes.shape({
      data: PropTypes.any,
      isLoading: PropTypes.bool,
      isLoadingError: PropTypes.bool,
      loadingError: PropTypes.object,
      status: PropTypes.number,
    }),
    clearDelay: PropTypes.func.isRequired,
    clearDelayData: PropTypes.shape({
      data: PropTypes.any,
      isLoading: PropTypes.bool,
      isLoadingError: PropTypes.bool,
      loadingError: PropTypes.object,
    }),
    clearReportDelayInState: PropTypes.func.isRequired,
    clearEditShipmentStatus: PropTypes.func.isRequired,
    fetchDelayOptions: PropTypes.func,
    delayOptionsData: PropTypes.shape({
      data: PropTypes.any,
      isLoading: PropTypes.bool,
      isLoadingError: PropTypes.bool,
      loadingError: PropTypes.object,
    }),
    isShipmentSubscriptionLoading: PropTypes.bool,
    shipmentSubscription: PropTypes.shape({
      email: PropTypes.string,
      enable_email: PropTypes.bool,
      enable_platform: PropTypes.bool,
      enable_sms: PropTypes.bool,
      id: PropTypes.any,
      mobile_number: PropTypes.any,
      owner_id: PropTypes.any,
      reference_id: PropTypes.string,
      source_service: PropTypes.string,
      timezone: PropTypes.string,
      type: PropTypes.string,
    }),
    subscriptionRequestError: PropTypes.bool,
    isSubscriptionUpdating: PropTypes.bool,
    subscriptionUpdateSuccess: PropTypes.bool,
    subscriptionUpdateError: PropTypes.bool,
    subscribe: PropTypes.func.isRequired,
    fetchShipmentSubscription: PropTypes.func.isRequired,
    updateSubscription: PropTypes.func.isRequired,
    unsubscribe: PropTypes.func.isRequired,
    clearReportShipmentEvents: PropTypes.func,
    updateShipment: PropTypes.func,
  };

  static defaultProps = {
    // This prop controls how we're fetching the shipment details.
    // It also signals the location path we're coming from:
    //
    // If false (default), we will fetch the details using the shipment_id provided
    // in the location payload (/shipments/:shipment_id), otherwise it means we're
    // coming from /carriers/:scac/shipments/:creator_shipment_id so
    // will instead use the carrier info in that payload to fetch the data.
    customerView: false,
    // Similar trigger for Finished Vehicle case.
    finishedVehicleView: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      showUnderReviewModal: false,
      showAddAssetModal: false,
      showAddShipmentEventsModal: false,
      showEnableMobileTrackingModal: false,
      showDisableMobileTrackingModal: false,
      showReportCarrierDelayModal: false,
      showClearCarrierDelayModal: false,
      showAlertMeModal: false,
      showHeatmap: false,
      showUnassignAssetModal: false,
      selectedLegID: null,
      showBillOfLadingModal: false,
    };

    this.eventHandler = this.eventHandler.bind(this);
  }

  componentDidMount() {
    const {
      t,
      setTitle,
      customerView,
      finishedVehicleView,
      shipmentID,
      scac,
      creatorShipmentID,
      fetchShipmentDetails,
      fetchShipmentDetailsFromCarrierInfo,
      fetchShipmentDetailsFromVin,
      fetchShipmentTripPlan,
      vin,
    } = this.props;

    setTitle(t("shipment-details:Shipment Detail"));

    MixpanelUtils.track("Viewed Page: Shipments / Details");

    /* H2-268 add another case for loading Finished Vehicle Shipment Details */
    if (customerView) {
      fetchShipmentDetailsFromCarrierInfo(scac, creatorShipmentID);
    } else if (finishedVehicleView) {
      fetchShipmentDetailsFromVin(scac, creatorShipmentID, vin);
    } else {
      fetchShipmentDetails(shipmentID);
    }

    fetchShipmentTripPlan(shipmentID);
  }

  componentWillUnmount() {
    this.props.clearShipmentDetails();
  }

  componentDidUpdate(prevProps) {
    const nextProps = this.props;
    const {
      customerView,
      finishedVehicleView,
      shipmentID,
      scac,
      creatorShipmentID,
      shipment,
      fetchShipmentDetails,
      fetchShipmentDetailsFromCarrierInfo,
      fetchShipmentDetailsFromVin,
      fetchRouteHeatmap,
      vin,
      fetchShipmentSubscription,
    } = prevProps;

    if (
      customerView &&
      (scac !== nextProps.scac ||
        creatorShipmentID !== nextProps.creatorShipmentID)
    ) {
      fetchShipmentDetailsFromCarrierInfo(
        nextProps.scac,
        nextProps.creatorShipmentID,
      );
    } else if (
      finishedVehicleView &&
      (scac !== nextProps.scac ||
        creatorShipmentID !== nextProps.creatorShipmentID ||
        vin !== nextProps.vin)
    ) {
      fetchShipmentDetailsFromVin(
        nextProps.scac,
        nextProps.creatorShipmentID,
        nextProps.vin,
      );
    } else if (shipmentID !== nextProps.shipmentID) {
      fetchShipmentDetails(nextProps.shipmentID);
    }

    if (shipment !== nextProps.shipment) {
      nextProps.setSubTitle(nextProps.shipment.creator_shipment_id);

      if (nextProps.shipment?.route_number) {
        fetchRouteHeatmap(nextProps.shipment.route_number);
      }

      if (!_.isEmpty(nextProps.shipment)) {
        fetchShipmentSubscription(nextProps.shipment);
      }
    }
  }

  eventHandler(val, type) {
    const {
      pushLocationMatchingView,
      shipment,
      setShipmentWithUnresolvedLocation,
    } = this.props;

    switch (type) {
      case "EDIT_REVIEW_STATUS":
        this.setState({ showUnderReviewModal: true });
        break;
      case "SHOW_ADD_ASSET":
        this.setState({ showAddAssetModal: true });
        break;
      case "SHOW_ADD_EVENTS":
        this.setState({ showShipmentEventsModal: true });
        break;
      case "SHOW_CANCEL_SHIPMENT":
        this.setState({ showCancelShipmentModal: true });
        break;
      case "TOGGLE_ENABLE_MOBILE_TRACKING":
        this.setState({ showEnableMobileTrackingModal: true });
        break;
      case "TOGGLE_DISABLE_MOBILE_TRACKING":
        this.setState({ showDisableMobileTrackingModal: true });
        break;
      case "SHOW_REPORT_CARRIER_DELAY":
        this.setState({ showReportCarrierDelayModal: true });
        break;
      case "SHOW_CLEAR_CARRIER_DELAY":
        this.setState({ showClearCarrierDelayModal: true });
        break;
      case "SHOW_ALERT_ME_MODAL":
        this.setState({ showAlertMeModal: true });
        break;
      case "TIMELINE_LAD_CLICK":
        setShipmentWithUnresolvedLocation(shipment);
        pushLocationMatchingView(val.id);
        break;
      case "SHOW_UNASSIGN_ASSET":
        this.setState({ showUnassignAssetModal: true });
        break;
      case "SELECT_LEG":
        this.setState({ selectedLegID: val });
        break;
      case "SHOW_BILL_OF_LADING":
        this.setState({ showBillOfLadingModal: true });
        break;
      default:
        break;
    }
  }

  render() {
    const {
      shipment,
      childShipmentID,
      childShipments,
      routeHeatmap,
      ladsList,
      selectedMapCoordinate,
      addCoordinate,
      clearCoordinatesByType,
      assignAsset,
      assignTrailer,
      createShipmentEvents,
      activeOrganization,
      cancelShipment,
      startMobileTracking,
      stopMobileTracking,
      pushShipmentDetailView,
      shipmentModes,
      shippers,
      unassignAsset,
      finishedVehicleView,
      scac,
      vin,
      isLoaded,
      setWatchShipment,
      fetchShipmentDetails,
      tripPlan,
      tripPlanIsLoading,
      fetchComments,
      isFetchingComments,
      comments,
      addComment,
      cancelAddComment,
      updateComment,
      cancelUpdateComment,
      markCommentsRead,
      addBatchComments,
      clearBatchComments,
      isBatchCommentInProgress,
      isBatchCommentSuccessful,
      isBatchCommentFailed,
      fetchNotification,
      editShipmentData,
      reportDelay,
      reportDelayData,
      clearDelay,
      clearDelayData,
      clearReportDelayInState,
      clearEditShipmentStatus,
      shipmentSubscription,
      isShipmentSubscriptionLoading,
      subscriptionRequestError,
      isSubscriptionUpdating,
      subscriptionUpdateSuccess,
      subscriptionUpdateError,
      subscribe,
      updateSubscription,
      unsubscribe,
      clearReportShipmentEvents,
      fetchDelayOptions,
      delayOptionsData,
      updateShipment,
    } = this.props;
    const {
      showUnderReviewModal,
      showAddAssetModal,
      showShipmentEventsModal,
      showCancelShipmentModal,
      showEnableMobileTrackingModal,
      showDisableMobileTrackingModal,
      showReportCarrierDelayModal,
      showClearCarrierDelayModal,
      showAlertMeModal,
      showHeatmap,
      showUnassignAssetModal,
      selectedLegID,
      showBillOfLadingModal,
    } = this.state;

    const enableActionMenu =
      isCarrier(activeOrganization) || isShipper(activeOrganization);
    const enableAlertMeAction =
      isCarrier(activeOrganization) || isShipper(activeOrganization);
    const enableShipmentEventsAction =
      isCarrier(activeOrganization) || isShipper(activeOrganization);

    // H1-1944: remove Cancel Shipment from action menu if shipment is already
    // canceled
    const isAlreadyCanceled = shipment.active_status === "canceled";
    const enableCancelShipmentAction =
      (isCarrier(activeOrganization) || isShipper(activeOrganization)) &&
      !isAlreadyCanceled;
    const assetId = getAssetId(activeOrganization, shipment);

    // H1-4679: Able to report a delay if is truck shipment and not canceled.
    const enableReportDelayAction =
      (isCarrier(activeOrganization) || isShipper(activeOrganization)) &&
      !isAlreadyCanceled &&
      [Mode.TRUCK, Mode.RAIL, Mode.PARCEL, Mode.LTL].includes(
        shipment.mode_name,
      );

    const enableClearDelayAction =
      (isCarrier(activeOrganization) || isShipper(activeOrganization)) &&
      !isAlreadyCanceled &&
      [Mode.TRUCK, Mode.RAIL, Mode.PARCEL, Mode.LTL].includes(
        shipment.mode_name,
      ) &&
      shipment.active_exceptions_ng === "Carrier Delayed";

    const enableMobileTrackingAction =
      isCarrier(activeOrganization) || isShipper(activeOrganization);

    const enableBillOfLadingAction =
      isCarrier(activeOrganization) || isShipper(activeOrganization);

    let routeShipments = getRouteShipments(
      shipment,
      childShipments,
      selectedLegID,
      isLoaded,
    );

    const isMultiLeg = shipment?.shipment_details?.is_multileg ?? false;

    const toggleWatchShipment = () => {
      setWatchShipment(shipment, !shipment.watched, () =>
        fetchShipmentDetails(shipment.id),
      );
    };

    const heatmapCoords =
      routeHeatmap?.length > 0
        ? routeHeatmap
        : _.get(shipment, "current_location.updates");

    //stauts of successfully created event
    let successResponseStatusCode =
      editShipmentData?.statusArray?.includes(201) ?? null;

    return (
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          [MediaQueries.largeAndUp]: {
            flexDirection: "row",
          },
        }}
      >
        <ResponsiveSection halfHeight>
          <RoutingMap
            ladsList={ladsList}
            shipments={routeShipments}
            showBreadCrumbs={true}
            showStopSequence={true}
            showHeatmap={showHeatmap}
            heatmapCoords={heatmapCoords}
            isMultiLeg={isMultiLeg}
            activeShipment={
              isMultiLeg
                ? shipment && shipment.active_shipment
                  ? shipment.active_shipment.toString()
                  : null
                : null
            }
          />
          {heatmapCoords?.length > 0 ? (
            <HeatMapButton
              isToggled={showHeatmap}
              onToggle={() => this.setState({ showHeatmap: !showHeatmap })}
              css={{
                position: "absolute",
                top: 5,
                right: 0,
                zIndex: 2,
              }}
            />
          ) : null}
        </ResponsiveSection>
        <ResponsiveSection
          css={{
            backgroundColor: Colors.background.LIGHT_GRAY,
            [MediaQueries.largeAndUp]: {
              overflow: "auto",
            },
          }}
        >
          <Loader loaded={isLoaded}>
            {
              // SH-6810: Carriers shouldn't be able to see leg tabs in a Multimodal/Crossborder parent shipment.
              // So force them to view the parent shipment as a single Shipment view.
              // Also, only display with leg tabs if we have actual child_shipments, which are loaded as part of
              // the /trip/ API which comes AFTER the /shipments/ API request, so we want to wait until we
              // have that /trip/ data before displaying the Multimodal view.
            }
            {isMultiLeg &&
            !_.isEmpty(activeOrganization) &&
            !isCarrier(activeOrganization) &&
            shipment?.child_shipments ? (
              <MultimodalShipmentContentPanel
                parentShipment={shipment}
                childShipments={childShipments}
                selectedChildShipmentID={childShipmentID}
                ladsList={ladsList}
                addCoordinate={addCoordinate}
                clearCoordinatesByType={clearCoordinatesByType}
                selectedMapCoordinate={selectedMapCoordinate}
                eventHandler={this.eventHandler}
                enableActionMenu={isShipper(activeOrganization)}
                enableAlertMeAction={enableAlertMeAction} // Only enable Alert Me action for Multimodal shipments
                enableShipmentEventsAction={false}
                enableCancelShipmentAction={false}
                enableReportDelayAction={false}
                enableClearDelayAction={false}
                enableMobileTrackingAction={false}
                organization={activeOrganization}
                pushShipmentDetailView={pushShipmentDetailView}
                shipmentModes={shipmentModes}
                finishedVehicleView={finishedVehicleView}
                vin={vin}
                scac={scac}
                isLoaded={isLoaded}
                toggleWatchShipment={toggleWatchShipment}
                showComments={isShipper(activeOrganization)}
                fetchComments={fetchComments}
                isFetchingComments={isFetchingComments}
                comments={comments}
                addComment={addComment}
                cancelAddComment={cancelAddComment}
                updateComment={updateComment}
                cancelUpdateComment={cancelUpdateComment}
                markCommentsRead={markCommentsRead}
                addBatchComments={addBatchComments}
                clearBatchComments={() => clearBatchComments()}
                isBatchCommentInProgress={isBatchCommentInProgress}
                isBatchCommentSuccessful={isBatchCommentSuccessful}
                isBatchCommentFailed={isBatchCommentFailed}
                fetchNotification={fetchNotification}
                shipperOrgId={
                  isShipper(activeOrganization)
                    ? activeOrganization.organization_id
                    : null
                }
                shipmentSubscription={shipmentSubscription}
                isShipmentSubscriptionLoading={isShipmentSubscriptionLoading}
              />
            ) : (
              <ShipmentContentPanel
                shipment={shipment}
                ladsList={ladsList}
                selectedMapCoordinate={selectedMapCoordinate}
                addCoordinate={addCoordinate}
                clearCoordinatesByType={clearCoordinatesByType}
                eventHandler={this.eventHandler}
                enableActionMenu={enableActionMenu}
                enableAlertMeAction={enableAlertMeAction}
                enableShipmentEventsAction={enableShipmentEventsAction}
                enableCancelShipmentAction={enableCancelShipmentAction}
                enableReportDelayAction={enableReportDelayAction}
                enableClearDelayAction={enableClearDelayAction}
                enableMobileTrackingAction={enableMobileTrackingAction}
                enableBillOfLadingAction={enableBillOfLadingAction}
                organization={activeOrganization}
                shipmentModes={shipmentModes}
                shippers={isCarrier(activeOrganization) ? shippers : []}
                scac={scac}
                isLoaded={isLoaded}
                toggleWatchShipment={toggleWatchShipment}
                tripPlan={tripPlan}
                tripPlanIsLoading={tripPlanIsLoading}
                showComments={
                  isShipper(activeOrganization) || isCarrier(activeOrganization)
                }
                commentShareableWithOrgId={
                  isShipper(activeOrganization)
                    ? shipment.carrier_organization_id
                    : isCarrier(activeOrganization)
                    ? shipment.created_by_org_id
                    : null
                }
                fetchComments={(pageNumber, pageSize) => {
                  return shipment?.id
                    ? fetchComments(shipment.id, pageNumber, pageSize)
                    : null;
                }}
                isFetchingComments={isFetchingComments}
                comments={comments}
                addComment={(data) => {
                  return addComment(shipment.id, data);
                }}
                cancelAddComment={cancelAddComment}
                updateComment={(commentId, updatedData) => {
                  return updateComment(shipment.id, commentId, updatedData);
                }}
                cancelUpdateComment={cancelUpdateComment}
                markCommentsRead={(datetime) => {
                  return markCommentsRead(shipment.id, datetime);
                }}
                addBatchComments={(data, isCsvFormat) => {
                  return addBatchComments(data, isCsvFormat);
                }}
                clearBatchComments={() => clearBatchComments()}
                isBatchCommentInProgress={isBatchCommentInProgress}
                isBatchCommentSuccessful={isBatchCommentSuccessful}
                isBatchCommentFailed={isBatchCommentFailed}
                fetchNotification={fetchNotification}
                shipmentSubscription={shipmentSubscription}
                isShipmentSubscriptionLoading={isShipmentSubscriptionLoading}
              />
            )}
          </Loader>
        </ResponsiveSection>

        {enableShipmentEventsAction && (
          <AddAssetModal
            show={showAddAssetModal}
            assignAsset={assignAsset}
            assignTrailer={assignTrailer}
            shipment={shipment}
            organization={activeOrganization}
            hide={() => this.setState({ showAddAssetModal: false })}
          />
        )}
        {enableShipmentEventsAction && (
          <UnassignAssetModal
            show={showUnassignAssetModal}
            assetId={assetId}
            shipment={shipment}
            organization={activeOrganization}
            unassignAsset={unassignAsset}
            hide={() => this.setState({ showUnassignAssetModal: false })}
          />
        )}
        {enableShipmentEventsAction && (
          <ShipmentEventsModal
            show={showShipmentEventsModal}
            shipment={shipment}
            organization={activeOrganization}
            hide={() => {
              this.setState({
                showShipmentEventsModal: false,
              });
              //refresh shipment details page only on success report event
              if (successResponseStatusCode) {
                fetchShipmentDetails(shipment.id, null, false);
              }
            }}
            createShipmentEvents={(eventData, notes, onSuccess) =>
              createShipmentEvents(
                shipment,
                activeOrganization,
                eventData,
                notes,
                onSuccess,
              )
            }
            isSubmitLoading={editShipmentData?.isLoading}
            errorMessages={
              editShipmentData?.isLoadingError
                ? editShipmentData?.loadingError
                : null
            }
            responseStatusCode={editShipmentData?.status ?? null}
            clearReportShipmentEvents={clearReportShipmentEvents}
          />
        )}
        {enableCancelShipmentAction && (
          <CancelShipmentModal
            show={showCancelShipmentModal}
            cancelShipment={cancelShipment}
            shipment={shipment}
            hide={() => this.setState({ showCancelShipmentModal: false })}
          />
        )}
        {enableMobileTrackingAction &&
          !getMobileTrackingEnabled(shipment) &&
          getIsShipmentWithinTrackingWindow(shipment) && (
            <MobileTrackingModal
              show={showEnableMobileTrackingModal}
              startMobileTracking={startMobileTracking}
              shipment={shipment}
              organization={activeOrganization}
              toEnable={true}
              hide={() =>
                this.setState({ showEnableMobileTrackingModal: false })
              }
            />
          )}
        {enableMobileTrackingAction &&
          getMobileTrackingEnabled(shipment) &&
          getIsShipmentWithinTrackingWindow(shipment) && (
            <MobileTrackingModal
              show={showDisableMobileTrackingModal}
              stopMobileTracking={stopMobileTracking}
              shipment={shipment}
              organization={activeOrganization}
              toEnable={false}
              hide={() =>
                this.setState({ showDisableMobileTrackingModal: false })
              }
            />
          )}
        {enableReportDelayAction ? (
          <ReportCarrierDelayModal
            show={showReportCarrierDelayModal}
            hide={() => {
              this.setState({ showReportCarrierDelayModal: false });
              clearReportDelayInState();
            }}
            isOrgTypeShipper={isShipper(activeOrganization)}
            selectedShipmentID={shipment?.creator_shipment_id}
            fetchDelayOptions={fetchDelayOptions}
            reportDelayOptionsList={delayOptionsData?.data}
            reportDelayOptionsIsLoading={delayOptionsData?.isLoading}
            reportDelayOptionsIsLoadingError={delayOptionsData?.isLoadingError}
            reportDelay={(
              reasonCode,
              frozenEta,
              comments,
              importShipmentIDs,
              onSuccess,
            ) =>
              reportDelay(
                shipment,
                activeOrganization,
                reasonCode,
                frozenEta,
                comments,
                importShipmentIDs,
                onSuccess,
              )
            }
            isSubmitLoading={reportDelayData?.isLoading}
            responseStatusCode={reportDelayData?.status}
            errorResponse={reportDelayData?.loadingError?.response?.data}
          />
        ) : null}
        {enableClearDelayAction ? (
          <ClearCarrierDelayModal
            show={showClearCarrierDelayModal}
            hide={() => {
              this.setState({ showClearCarrierDelayModal: false });
            }}
            clearDelay={(onSuccess) =>
              clearDelay(shipment, activeOrganization, onSuccess)
            }
            isSubmitLoading={clearDelayData?.isLoading}
            errorMessage={
              clearDelayData?.isLoadingError
                ? clearDelayData?.loadingError?.message
                : null
            }
          />
        ) : null}
        {enableAlertMeAction ? (
          <AlertMeModal
            show={showAlertMeModal}
            hide={() => {
              this.setState({ showAlertMeModal: false });
            }}
            subscribeeDetails={shipment}
            displayedId={shipment.creator_shipment_id}
            subscriptionDetails={shipmentSubscription}
            subscribe={subscribe}
            updateSubscription={updateSubscription}
            unsubscribe={unsubscribe}
            subscriptionRequestError={subscriptionRequestError}
            isSubscriptionUpdating={isSubscriptionUpdating}
            subscriptionUpdateSuccess={subscriptionUpdateSuccess}
            subscriptionUpdateError={subscriptionUpdateError}
          />
        ) : null}
        {enableBillOfLadingAction ? (
          <BillOfLadingModal
            show={showBillOfLadingModal}
            hide={() => {
              this.setState({ showBillOfLadingModal: false });
              clearEditShipmentStatus();
            }}
            updateShipment={updateShipment}
            shipment={shipment}
            fetchShipmentDetails={fetchShipmentDetails}
            clearEditShipmentStatus={clearEditShipmentStatus}
            isSubmitLoading={editShipmentData?.isEditShipmentLoading}
            errorMessages={
              editShipmentData?.isEditShipmentLoadingError
                ? editShipmentData?.editShipmentLoadingError
                : null
            }
            responseStatusCode={editShipmentData?.editShipmentStatus ?? null}
          />
        ) : null}
      </div>
    );
  }
}

export default withTranslation("shipment-details")(ShipmentDetailView);
