import {
  getMultiSelectQueryString,
  getNQueryStringFilterValuePriority,
  getEmptyQueryString,
} from "components/search-bar/search-filter-query-strings";

import {
  locationOptionsState,
  programCodeFilterOptions,
  supplierCodeFilterOptions,
  containerTypeFilterOptions,
  locationOnRouteFilterOptions,
  sensorFilterOptions,
} from "pages/containertracking/modules/domain-data/ContainerTrackingSearchFilterLoaders";

import {
  AsyncSelectFilterButton,
  NFilterButton,
  BatchFilterButton,
} from "components/search-bar/FilterButtons";
import {
  batchCsvContainerTypeExample,
  batchCsvTagIdExample,
  batchCsvContainerIdExample,
  batchCsvProgramCodeExample,
  batchCsvSupplierCodeExample,
} from "components/search-bar/batch-comment-csv-data";

type FilterProps = {
  label: string;
  value: string;
};

type TranslateFunction = (translateValue: string) => string;

export const FILTERS = [
  {
    // ordered top down from 0 - N
    queryKey: ["status", "locations"],
    label: (t: TranslateFunction) => t("container-tracking:Status"),
    Component: NFilterButton,
    nIsAsync: {
      status: false,
      locations: true,
    },
    nOptionsState: {
      status: null,
      locations: locationOptionsState,
    },
    //Label of filter where key is corresponding select
    nLabels: {
      status: null,
      locations: "At Location",
    },
    //Fields required from previous filter
    //Field with contraints as key
    nRequirments: {
      locations: ["At Location"],
    },
    // Hides fuzzy search options
    nHideFuzzySearch: {
      status: true,
      locations: false,
    },
    // Hides select all option
    nHideSelectAll: {
      status: true,
      locations: false,
    },
    // Hides select empty option
    nHideSelectEmpty: {
      status: true,
      locations: false,
    },
    //Showall filters with no requirments or contraints
    showAll: false,
    //Object key as query key
    optionsGetter: ({
      statusFilterOptions,
    }: {
      statusFilterOptions: FilterProps[];
    }) => {
      return {
        status: statusFilterOptions ?? [],
      };
    },
    queryBuilder: getNQueryStringFilterValuePriority,
  },
  {
    queryKey: "type:1",
    label: (t: TranslateFunction) => t("container-tracking:Container Type"),
    optionsState: containerTypeFilterOptions,
    Component: AsyncSelectFilterButton,
    isMulti: true,
    queryBuilder: (
      _ignored_queryParameter: string,
      filterValue: FilterProps[],
    ) => getMultiSelectQueryString("type", filterValue),
  },

  {
    queryKey: "activeExceptions",
    label: (t: TranslateFunction) => t("container-tracking:Active Exception"),
    optionsGetter: ({
      activeExceptionsFilterOptions,
    }: {
      activeExceptionsFilterOptions: FilterProps[];
    }) => activeExceptionsFilterOptions ?? [],
    queryBuilder: (
      _ignored_queryParameter: string,
      filterValue: FilterProps[],
    ) => getMultiSelectQueryString("activeExceptions", filterValue),
    hideFuzzySearch: true,
  },
  {
    queryKey: "lad",
    label: (t: TranslateFunction) => t("container-tracking:LAD"),
    optionsGetter: ({
      ladFilterOptions,
    }: {
      ladFilterOptions: FilterProps[];
    }) => ladFilterOptions ?? [],
    queryBuilder: (
      _ignored_queryParameter: string,
      filterValue: FilterProps[],
    ) => getMultiSelectQueryString("lad", filterValue),
    hideFuzzySearch: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "programCode",
    label: (t: TranslateFunction) => t("container-tracking:Program Code"),
    optionsState: programCodeFilterOptions,
    Component: AsyncSelectFilterButton,
    isMulti: true,
    queryBuilder: (
      _ignored_queryParameter: string,
      filterValue: FilterProps[],
    ) => getMultiSelectQueryString("programCode", filterValue),
  },
  {
    queryKey: "supplierCode",
    label: (t: TranslateFunction) => t("container-tracking:Supplier Code"),
    optionsState: supplierCodeFilterOptions,
    Component: AsyncSelectFilterButton,
    isMulti: true,
    queryBuilder: (
      _ignored_queryParameter: string,
      filterValue: FilterProps[],
    ) => getMultiSelectQueryString("supplierCode", filterValue),
  },
  {
    queryKey: "batteryLife",
    label: (t: TranslateFunction) => t("container-tracking:Battery Life"),
    optionsGetter: ({
      batteryLifeOptions,
    }: {
      batteryLifeOptions: FilterProps[];
    }) => batteryLifeOptions ?? [],
    queryBuilder: (
      _ignored_queryParameter: string,
      filterValue: FilterProps[],
    ) => getMultiSelectQueryString("batteryLife", filterValue),
    hideFuzzySearch: true,
    hideSelectAll: true,
  },
  {
    queryKey: "sensor",
    label: (t: TranslateFunction) => t("container-tracking:Sensor"),
    optionsState: sensorFilterOptions,
    Component: AsyncSelectFilterButton,
    isMulti: true,
    queryBuilder: (
      _ignored_queryParameter: string,
      filterValue: FilterProps[],
    ) => {
      return getMultiSelectQueryString("sensor", filterValue);
    },
  },
  {
    queryKey: "locationsOnRoute",
    label: (t: TranslateFunction) => t("container-tracking:Location On Route"),
    optionsState: locationOnRouteFilterOptions,
    Component: AsyncSelectFilterButton,
    isMulti: true,
    queryBuilder: (
      _ignored_queryParameter: string,
      filterValue: FilterProps[],
    ) => getMultiSelectQueryString("locationsOnRoute", filterValue),
  },
  {
    queryKey: "batch",
    label: (t: TranslateFunction) => t("container-tracking:Batch Search"),
    Component: BatchFilterButton,
    optionsGetter: (_ignored_props: object, t: TranslateFunction) => {
      return [
        {
          label: t("container-tracking:Container Type #"),
          value: "container_type",
          batchCsvExample: batchCsvContainerTypeExample,
        },
        {
          label: t("container-tracking:Tag ID #"),
          value: "tag_id",
          batchCsvExample: batchCsvTagIdExample,
        },
        {
          label: t("container-tracking:Container ID #"),
          value: "container_id",
          batchCsvExample: batchCsvContainerIdExample,
        },
        {
          label: t("container-tracking:Program Code #"),
          value: "program_code",
          batchCsvExample: batchCsvProgramCodeExample,
        },
        {
          label: t("container-tracking:Supplier Code #"),
          value: "supplier_code",
          batchCsvExample: batchCsvSupplierCodeExample,
        },
      ];
    },
    queryBuilder: getEmptyQueryString,
  },
];

export const CONTAINER_TYPE_FILTERS = [
  {
    queryKey: "type",
    label: (t: TranslateFunction) => t("container-tracking:Container Type"),
    optionsState: containerTypeFilterOptions,
    Component: AsyncSelectFilterButton,
    isMulti: true,
    hideSelectEmpty: true,
    hideSelectAll: true,
    queryBuilder: getMultiSelectQueryString,
  },
  {
    queryKey: "programCode",
    label: (t: TranslateFunction) => t("container-tracking:Program Code"),
    optionsState: programCodeFilterOptions,
    Component: AsyncSelectFilterButton,
    isMulti: true,
    queryBuilder: getMultiSelectQueryString,
    hideSelectEmpty: true,
  },
  {
    queryKey: "supplierCode",
    label: (t: TranslateFunction) => t("container-tracking:Supplier Code"),
    optionsState: supplierCodeFilterOptions,
    Component: AsyncSelectFilterButton,
    isMulti: true,
    queryBuilder: getMultiSelectQueryString,
    hideSelectEmpty: true,
  },
];
