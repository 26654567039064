import { connect } from "react-redux";
import _ from "lodash";
import EndToEndView from "./Locations.page";
import LadsState from "shared/redux/Lads.state";
import SearchState from "pages/shipments/redux/ShipmentsSearchBarState";
import { SEARCH_CATEGORIES } from "pages/shipments/components/search/Shipments.searchOptions";
import {
  selectLob,
  selectLocation,
  setSearchStr,
  selectLad,
  toggleLadsPanel,
  toggleLocationsPanel,
} from "./redux/EndToEndState";
import {
  getLocations,
  fetchLadLocations,
} from "pages/administration/location-management/redux/Locations.state";

const locationCategory = _.find(SEARCH_CATEGORIES, { queryKey: "location" });

function mapStateToProps(state) {
  const { getLadsList, selectGroupedLads } = LadsState.selectors;
  return {
    ...state.endToEnd,
    locations: getLocations(state),
    ladsList: getLadsList(state),
    ladsGrouped: selectGroupedLads(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectLob: (ind) => dispatch(selectLob(ind)),
    selectLocation: (loc) => dispatch(selectLocation(loc)),
    selectLad: (lad) => dispatch(selectLad(lad)),
    setSearchStr: (str) => dispatch(setSearchStr(str)),
    toggleLadsPanel: (toggle) => dispatch(toggleLadsPanel(toggle)),
    toggleLocationsPanel: (toggle) => dispatch(toggleLocationsPanel(toggle)),
    searchLocation: (locationName, locationID) => {
      dispatch(SearchState.actionCreators.resetSearchAndFilters());
      dispatch(SearchState.actionCreators.setSearchCategory(locationCategory));
      dispatch(SearchState.actionCreators.setSearchText(locationName));
      dispatch(SearchState.actionCreators.searchEntities());
      dispatch({ type: "SHIPMENT_SEARCH" });
    },
    fetchLadLocations: (code) => dispatch(fetchLadLocations(code)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EndToEndView);
