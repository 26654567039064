import moment from "moment-timezone";
import _ from "lodash";
import axios from "axios";
import { produce } from "immer";
import {
  localizedTimeFormatter,
  localizedDateFormatter,
} from "../../../utils/date-time";

import { SEARCH_CATEGORIES } from "../search/ConnectedCarSearchBarCategoryDefs";
import { FILTERS } from "../search/ConnectedCarFilterSectionCategoryDefs";
import apiUrl from "../../../api-url";
import buildSearchBarState from "../../../components/search-bar/SearchBarStateBuilder";

const STORE_MOUNT_POINT = "ccSearch";
const CLICKED_SEARCH_OR_ENTER = `${STORE_MOUNT_POINT}/CLICKED_SEARCH_OR_ENTER`;

// Helpers

export const RECORD_LIMIT = 5000;

const entitiesUrl = (solutionId, queryString) => {
  const qs = addLifeCycleParam(queryString);
  return apiUrl(`/entity/solution/${solutionId}/entity?${qs}`);
};
const addLifeCycleParam = (qs) => {
  if (qs.includes("lifeCycleState")) {
    return `${qs}`;
  } else {
    // Include the default lifeCycleState if not already specified by a filter
    return `${qs}&lifeCycleState=Active,Delivered`;
  }
};
const batchSearchUrl = (solutionId, queryString, batchType) => {
  const qs = addLifeCycleParam(queryString);
  return apiUrl(
    `/entity/solution/${solutionId}/batch-search?${qs}&batchType=${batchType}`,
  );
};

const axiosConfig = () => {
  return {
    headers: {
      "x-time-zone": moment.tz.guess(),
      Accept: "application/json;version=connected",
    },
  };
};

const translateEntity = (entity) => {
  const city = entity.city !== " " ? entity.city : "N/A";
  const state = entity.state !== " " ? entity.state : "N/A";

  const lastPositionTime = entity.lastPositionTime; // datetime for entity's last position update
  const timestamp = entity.timestamp;

  return {
    id: entity.external_id,
    vin: entity.external_id,
    latitude: entity.latitude,
    longitude: entity.longitude,
    description: entity.description,
    lastPositionTime: lastPositionTime,
    location: `${city}${state !== "N/A" ? ", " : ""}${
      state !== "N/A" ? state : ""
    }`,
    timestamp: `${localizedTimeFormatter(timestamp)} ${localizedDateFormatter(
      timestamp,
    )}`,
    hasDiagnostics: entity.hasDiagnostics,
  };
};

// Actions

const fetchSearch = (qs = "", solutionId, duck, dispatch, state) => {
  // TODO: improve this avoiding direct access to the state, that is, use
  // selectors instead
  const hasSearchCriteria = (state) => {
    const ccState = state[STORE_MOUNT_POINT];
    const hasSearchText = ccState.searchText;
    const hasFilter = Object.keys(ccState.searchFilters).find(
      (key) => !_.isNil(ccState.searchFilters[key]),
    );
    return hasSearchText || hasFilter;
  };

  const batchFilter = state[STORE_MOUNT_POINT].searchFilters.batch;

  if (!hasSearchCriteria(state)) {
    // Error should be shown if user has not applied any search criteria
    dispatch({
      type: duck.actions.REQUEST_ERROR,
      err: "Search text or filter(s) must be specified",
    });
  } else if (batchFilter) {
    // Batch search POST
    batchSearch(solutionId, qs, dispatch, batchFilter, duck);
  } else {
    // Normal search GET

    const url = entitiesUrl(solutionId, qs);
    const config = axiosConfig();
    dispatch(
      duck.fetch(url, config, (response) => {
        return {
          ...response,
          data: response.data.map((entity) => translateEntity(entity)),
        };
      }),
    );
  }
};

const batchSearch = (solutionId, queryString, dispatch, batchFilter, duck) => {
  const url = batchSearchUrl(solutionId, queryString, batchFilter.batch_type);
  const data = {
    batch_list: batchFilter.batch_list,
  };
  const config = axiosConfig();

  dispatch({
    type: duck.actions.REQUEST,
  });

  return axios
    .post(url, data, config)
    .then((response) => {
      dispatch({
        type: duck.actions.RECEIVE,
        payload: {
          ...response.data,
          data: response.data.data.map((entity) => translateEntity(entity)),
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: duck.actions.REQUEST_ERROR,
        err,
      });
    });
};

// H1-2160: There is a very special need for this case that is related to
// showing a message when there is no filter and user tries to filter
// something. The point is that, sometimes, user filter and remove all filters.
// When removing the filters a message was being showed. We then created this
// special property just to mark if it was the user clicking on search or enter
// or something related to filters. With this info on the state we have the
// capacity to define when to show/not show the message considering also this
// very special case.
const clickedSearchOrEnter = (value) => {
  return (dispatch) => {
    dispatch({
      type: CLICKED_SEARCH_OR_ENTER,
      payload: value,
    });
  };
};

const getClickedSearchOrEnter = (state) =>
  _.get(state, `${STORE_MOUNT_POINT}.clickedSearchOrEnter`, false);

const initialState = { clickedSearchOrEnter: false };

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CLICKED_SEARCH_OR_ENTER:
        draft.clickedSearchOrEnter = action.payload;
        break;

      default:
        return draft;
    }
  });

const SearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  FILTERS,
  fetchSearch,
  [reducer],
  {},
  RECORD_LIMIT,
);

SearchBarState.actionCreators.clickedSearchOrEnter = clickedSearchOrEnter;
SearchBarState.actionCreators.exportSearch = _.partial(
  SearchBarState.actionCreators.exportEntities,
  entitiesUrl,
  batchSearchUrl,
  { headers: { Accept: "text/csv;version=connected" } },
  "connected-car-search-results",
);

SearchBarState.selectors.getClickedSearchOrEnter = getClickedSearchOrEnter;

export default SearchBarState;
