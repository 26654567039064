/** @jsxImportSource @emotion/react */
import { forwardRef, CSSProperties, MouseEventHandler, ReactNode } from "react";
import { Button as BootstrapButton } from "react-bootstrap";

import { ButtonType, ButtonVariant, ButtonSize } from "./enums";
import { Text } from "./Text.atom";
import Colors from "styles/colors";

export const Button = forwardRef<HTMLElement, ButtonProps>(
  (
    {
      type = "button",
      variant = "primary",
      size,
      disabled = false,
      pulse = false,
      style = {},
      className,
      onClick = () => {},
      "data-qa": dataQa,
      children,
      ...restProps
    },
    buttonRef: any,
  ) => {
    // Bootstrap has no "transparent" variant so we've rolled our own
    // The conversion to TypeScript enforces only Bootstrap variants
    const bootstrapVariant = variant === "transparent" ? "light" : variant;
    const transparentCss =
      variant === "transparent"
        ? {
            border: "1px solid transparent",
            backgroundColor: "transparent",
            "&:hover": {
              border: `1px solid ${Colors.border.BOTTOM_AXIS_BORDER}`,
              backgroundColor: Colors.text.WHITE,
            },
          }
        : undefined;
    return (
      <BootstrapButton
        data-qa={dataQa}
        type={type}
        variant={bootstrapVariant}
        size={size}
        disabled={disabled}
        style={{ cursor: disabled ? "inherit" : "pointer", ...style }}
        className={pulse ? `${className} pulse` : className}
        onClick={onClick}
        css={transparentCss}
        ref={buttonRef}
        {...restProps}
      >
        {typeof children === "string" ? <Text>{children}</Text> : children}
      </BootstrapButton>
    );
  },
);

export type ButtonProps = {
  /**
   * The type of the button. For most cases, the Button type will do.
   * For forms, you might want to use Reset and Submit types as well.
   *
   * Use the `ButtonType` type for this value.
   */
  type?: ButtonType;
  /**
   * The button variant, which defines it's style.
   *
   * Use the `ButtonVariant` type for this value.
   */
  variant?: ButtonVariant;
  /**
   * The size of the button, either Default, Small, or Large.
   *
   * Use the `ButtonSize` type for this value.
   */
  size?: ButtonSize;
  /**
   * Disable the button.
   */
  disabled?: boolean;
  /**
   * Applies a pulse animation around the button's border.
   */
  pulse?: boolean;
  /**
   * An object that will be used as the element styles for the button.
   */
  style?: CSSProperties;
  /**
   * A string for defining one or more classes that will be added to the button.
   */
  className?: string;
  /**
   * The event fired when the user clicks the button.
   */
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /**
   * The mousedown event fired when the user clicks the button.
   */
  onMouseDown?: MouseEventHandler<HTMLButtonElement>;
  /**
   * The data attribute for QA testing.
   */
  "data-qa"?: string;
  /**
   * The content inside the button.
   */
  children: ReactNode;
};
