import {
  getBasicQueryString,
  getEverythingQueryString,
} from "components/search-bar/search-filter-query-strings";

import { getMultiSelectQueryString } from "components/search-bar/search-filter-query-strings";
import { locationOptionsState } from "pages/containertracking/modules/domain-data/SensorOverviewSearchFilterLoaders";
import {
  AsyncSelectFilterButton,
  MultiSelectFilterButton,
} from "components/search-bar/FilterButtons";

export const SEARCH_CATEGORIES = [
  {
    queryKey: "everything",
    label: (t) => t("container-tracking:Search Everything"),
    placeholder: (t) => t("container-tracking:Search Sensor ID, Location"),
    queryBuilder: getEverythingQueryString,
    property: null,
  },
  {
    queryKey: "sensorId:contains",
    label: (t) => t("container-tracking:Sensor ID"),
    placeholder: (t) => t("container-tracking:Sensor ID"),
    queryBuilder: getBasicQueryString,
    property: "sensorIds",
  },
  {
    queryKey: "location:contains",
    label: (t) => t("container-tracking:Location"),
    placeholder: (t) => t("container-tracking:Location"),
    queryBuilder: getBasicQueryString,
    property: "locations",
  },
];

export const FILTERS = [
  {
    queryKey: "status",
    label: (t) => t("container-tracking:Sensor Status"),
    optionsGetter: (props) => {
      return props.sensorStatusOptions;
    },
    Component: MultiSelectFilterButton,
    isMulti: true,
    queryBuilder: getMultiSelectQueryString,
  },

  {
    queryKey: "location",
    label: (t) => t("container-tracking:Location"),
    optionsState: locationOptionsState,
    Component: AsyncSelectFilterButton,
    isMulti: true,
    queryBuilder: getMultiSelectQueryString,
  },
];
