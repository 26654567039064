/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "components/molecules/Modal.molecule";

import { Text } from "components/atoms/Text.atom";
import { Button } from "components/atoms/Button.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";

export const ShowMoreListType = {
  MODAL: "MODAL",
  TOOLTIP: "TOOLTIP",
};

export const ShowMoreList = ({
  title,
  list,
  ordered = false, // set to true to create a numbered list
  customModalBody, // renders a custom body content in popup
  visibleItemCount = 2,
  type = ShowMoreListType.MODAL,
  inline = false,
  listItemStyle = {},
  showMoreButtonStyle = {},
  toolTipContainerStyle = {},
  buttonText,
  "data-qa": dataQa,
}) => {
  const { t } = useTranslation("components");
  const [showModal, setShowModal] = useState(false);

  const onShowMoreClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (type === ShowMoreListType.MODAL) {
      setShowModal(true);
    }
  };

  const onHide = () => {
    if (type === ShowMoreListType.MODAL) {
      setShowModal(false);
    }
  };

  const showMore = list.length > visibleItemCount;
  const hiddenCount = list.length - visibleItemCount;
  const visibleItems = list.slice(0, visibleItemCount);

  // The full list to display in the modal or tooltip.
  const fullList = ordered
    ? list.map((name, index) => (
        <li key={`${name}-${index}`} style={listItemStyle}>
          <Text block>{name}</Text>
        </li>
      ))
    : list.map((name, index) => (
        // Sometimes the name is not unique so we can't use it as the key
        <Text block key={`${name}-${index}`} style={listItemStyle}>
          {name}
        </Text>
      ));

  // Show More button.
  let showMoreButtonText = t("components:[[[hiddenCount]]] more...", {
    hiddenCount,
  });
  if (buttonText) {
    showMoreButtonText = buttonText;
  } else if (inline) {
    showMoreButtonText = t("components:and [[[hiddenCount]]] more...", {
      hiddenCount,
    });
  }

  const showMoreButton = (
    <Tooltip
      style={toolTipContainerStyle}
      tooltipChildren={
        type === ShowMoreListType.TOOLTIP ? (
          <div data-qa="text-full-list">{fullList}</div>
        ) : null
      }
    >
      <Button
        variant="link"
        data-qa="button-show-more"
        onClick={onShowMoreClick}
        css={{
          padding: 0,
          // Bootstrap's .btn class vertically aligned the text.
          // Which is fine normally, but with other inilne text, it wasn't on the same "line".
          verticalAlign: inline ? "initial" : undefined,
        }}
        style={showMoreButtonStyle}
      >
        {showMoreButtonText}
      </Button>
    </Tooltip>
  );

  return (
    <Fragment>
      {inline ? (
        <Text data-qa={dataQa}>
          {visibleItems.join(", ")}
          {showMore ? [", ", showMoreButton] : null}
        </Text>
      ) : null}
      {!inline ? (
        <div data-qa={dataQa} className="d-flex flex-column align-items-start">
          {ordered ? (
            <ol
              css={{
                paddingLeft: "1em",
              }}
            >
              {fullList.slice(0, visibleItemCount)}
            </ol>
          ) : (
            fullList.slice(0, visibleItemCount)
          )}
          {showMore ? showMoreButton : null}
        </div>
      ) : null}
      {type === ShowMoreListType.MODAL ? (
        <div
          // Prevents the click event from inside the modal from bubbling.
          // When using this component in a cell in BaseTable, this prevents
          // the rowClickHandler from being called.
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Modal show={showModal} onHide={onHide}>
            <Modal.Header
              closeButton
              className="bg-light text-body"
              title={title}
            />

            <Modal.Body>
              <div data-qa="text-full-list">
                {customModalBody ? (
                  <>{customModalBody}</>
                ) : ordered ? (
                  <ol>{fullList}</ol>
                ) : (
                  fullList
                )}
              </div>
            </Modal.Body>
          </Modal>
        </div>
      ) : null}
    </Fragment>
  );
};

ShowMoreList.propTypes = {
  /**
   * The title of the modal when displaying the list as a modal.
   */
  title: PropTypes.string,
  /**
   * Static text for the show more button.
   *
   * By default this will show "<remaining count> more...".
   */
  buttonText: PropTypes.string,
  /**
   * The full list of text to display in a list.
   */
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * The visible amount of items before hiding the remainder of the list.
   */
  visibleItemCount: PropTypes.number,
  /**
   * Configures the display of the list when showing all.
   *
   * Use `ShowMoreListType` exported from the same file.
   *
   * MODAL: Shows the full list in a modal on click.
   * TOOLTIP: Shows the full list in a tooltip on hover.
   */
  type: PropTypes.oneOf([ShowMoreListType.MODAL, ShowMoreListType.TOOLTIP]),
  /**
   * Displays the visible list inline instead of vertically stacked.
   */
  inline: PropTypes.bool,
  /**
   * The style object passed to each text element in the list.
   */
  listItemStyle: PropTypes.object,
  /**
   * The style object passed to the show more button.
   */
  showMoreButtonStyle: PropTypes.object,
  /**
   * The style object passed to the Tooltip's `style` prop.
   */
  toolTipContainerStyle: PropTypes.object,
  /**
   * Renders a custom popup content in the popup.
   */
  customModalBody: PropTypes.element,
  /**
   * Defines whether the list should be a numbered list.
   */
  ordered: PropTypes.bool,
  /**
   * Test ID for QA.
   */
  "data-qa": PropTypes.string,
};
