import { InitialETADuration, TimeOnSiteDuration } from "api/consts";
import Colors from "styles/colors";

export const pieChartDataByTimeOnSite = [
  {
    key: "days10_inf",
    vins_associated: 0,
    name: "10+ Days",
    fill: Colors.inventoryView.RED,
    searchOption: TimeOnSiteDuration.DAYS_10,
  },
  {
    key: "days6_9",
    vins_associated: 0,
    name: "6-9 Days",
    fill: Colors.inventoryView.ORANGE,
    searchOption: TimeOnSiteDuration.DAYS_6_9,
  },
  {
    key: "days3_5",
    vins_associated: 0,
    name: "3-5 Days",
    fill: Colors.inventoryView.ORANGE_YELLOW,
    searchOption: TimeOnSiteDuration.DAYS_3_5,
  },
  {
    key: "days0_2",
    vins_associated: 0,
    name: "0-2 Days",
    fill: Colors.inventoryView.GREEN,
    searchOption: TimeOnSiteDuration.DAYS_0_2,
  },
];

export const pieChartDataByEta = [
  {
    key: "missed",
    vins_associated: 107,
    name: "Missed First ETA",
    fill: Colors.inventoryView.RED,
    searchOption: InitialETADuration.MISSED_FIRST_ETA,
  },
  {
    key: "hours0_47",
    vins_associated: 106,
    name: "0-47 Hours Out",
    fill: Colors.inventoryView.ORANGE_YELLOW,
    searchOption: InitialETADuration.HOURS_0_47_OUT,
  },
  {
    key: "hours48_95",
    vins_associated: 102,
    name: "48-95 Hours Out",
    fill: Colors.inventoryView.FLUORESCENT_GREEN,
    searchOption: InitialETADuration.HOURS_48_95_OUT,
  },
  {
    key: "hours96_inf",
    vins_associated: 202,
    name: "96+ Hours Out",
    fill: Colors.inventoryView.GREEN,
    searchOption: InitialETADuration.HOURS_96_OUT,
  },
];

export const pieChartDataByType = [
  Colors.graphs.CHART_BLUE,
  Colors.text.HIT_GRAY,
  Colors.graphs.CHART_GRAY,
];
