import { customerApiUrl } from "api-url";
import { buildAsyncFilterOptionsState } from "components/search-bar/AsyncFilterOptionsStateBuilder";
import { PaginationType } from "components/search-bar/enums.utils";

const productTypesUrl = (state) => {
  const inventoryLocationId = state.location.payload.locationId;
  return customerApiUrl(
    `/entity-inventory/location/${inventoryLocationId}/search/options/product-type`,
  );
};

const destinationUrl = (state) => {
  const inventoryLocationId = state.location.payload.locationId;
  return customerApiUrl(
    `/entity-inventory/location/${inventoryLocationId}/search/options/ultimate-destination`,
  );
};

const currentLocationUrl = (state) => {
  const inventoryLocationId = state.location.payload.locationId;
  return customerApiUrl(
    `/entity-inventory/location/${inventoryLocationId}/search/options/current-location`,
  );
};

const orderTypeUrl = (state) => {
  const inventoryLocationId = state.location.payload.locationId;
  return customerApiUrl(
    `/entity-inventory/location/${inventoryLocationId}/search/options/order-type`,
  );
};

const carrierUrl = (state) => {
  const inventoryLocationId = state.location.payload.locationId;
  return customerApiUrl(
    `/entity-inventory/location/${inventoryLocationId}/search/options/carrier`,
  );
};

export const productTypeOptionsState = buildAsyncFilterOptionsState({
  topic: "ivProductTypeFilter",
  getUrl: (solutionId, state) => productTypesUrl(state),
  getResponseData: (data) => data?.data ?? [],
  transformResult: (option) => ({ label: option, value: option }),
  paginationType: PaginationType.OPEN_SEARCH,
});

export const destinationOptionsState = buildAsyncFilterOptionsState({
  topic: "ivDestinationFilter",
  getUrl: (solutionId, state) => destinationUrl(state),
  getResponseData: (data) => data?.data ?? [],
  transformResult: (option) => ({
    label: `${option.name} (${option.code})`,
    value: option.id,
  }),
  paginationType: PaginationType.OPEN_SEARCH,
});

export const currentLocationOptionsState = buildAsyncFilterOptionsState({
  topic: "ivCurrentPositionFilter",
  getUrl: (solutionId, state) => currentLocationUrl(state),
  getResponseData: (data) => data?.data ?? [],
  transformResult: (option) => ({
    label: ` ${option.name} (${option.code})`,
    value: option.id,
  }),
  paginationType: PaginationType.OPEN_SEARCH,
});

export const orderTypesOptionsState = buildAsyncFilterOptionsState({
  topic: "ivOrderTypesFilter",
  getUrl: (solutionId, state) => orderTypeUrl(state),
  getResponseData: (data) => data?.data ?? [],
  transformResult: (option) => ({ label: option, value: option }),
  paginationType: PaginationType.OPEN_SEARCH,
});

export const carrierOptionsState = buildAsyncFilterOptionsState({
  topic: "ivCarrierFilter",
  getUrl: (solutionId, state) => carrierUrl(state),
  getResponseData: (data) => data?.data ?? [],
  transformResult: (option) => ({ label: option.name, value: option.fvId }),
  paginationType: PaginationType.OPEN_SEARCH,
});
