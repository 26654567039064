import _ from "lodash";
import { FontSize } from "components/atoms/Text.atom";
import { Features } from "modules/auth/Authorization";
import { OrganizationType } from "shared/constants/organization.const";
import { getActiveOrganization } from "modules/organizations/OrganizationsState";

export const getSolutionId = (featureData) => {
  return featureData
    ? featureData.find(
        (feature) => feature.feature_name === Features.CONTAINER_TRACKING,
      )?.solution ?? ""
    : "";
};

export const getTrackingFeatureName = (state) =>
  getActiveOrganization(state)?.org_type === OrganizationType.HEALTHCAREPROVIDER
    ? Features.SURGICAL_TOTE_TRACKING
    : Features.CONTAINER_TRACKING;

export const getFontSize = (array) => {
  const maxValue = _.max(array) ?? 0;

  if (maxValue > 999999) {
    return FontSize.size18;
  } else if (maxValue > 9999) {
    return FontSize.size24;
  } else {
    return FontSize.size32;
  }
};

export const getPercentageWithOneDecimalPoint = (count, totalCount) => {
  return !count || !totalCount ? 0 : ((count / totalCount) * 100).toFixed(1);
};
