import { connect } from "react-redux";
import { PartViewDealerOnboardingTool } from "./PartViewDealerOnboardingTool.page";
import { PartViewDealerOnboardingToolState } from "pages/administration/internal-tools/redux/PartViewDealerOnboardingToolState";

function mapStateToProps(state) {
  return {
    csvUploadResponseData:
      PartViewDealerOnboardingToolState.selectors.getCsvUploadResponseData(
        state,
      ),
  };
}

const mapDispatchToProps = (dispatch) => {
  const { onCsvUpload } = PartViewDealerOnboardingToolState.actionCreators;

  return {
    onCsvUpload: (payload) => {
      dispatch(onCsvUpload(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PartViewDealerOnboardingTool);
