// Pixel to rem value map
// The naming assumes that the base font size is 16px
export enum FontSize {
  size12 = 0.75,
  size13 = 0.8125,
  size14 = 0.875,
  size16 = 1,
  size18 = 1.125,
  size20 = 1.25,
  size24 = 1.5,
  size28 = 1.75,
  size32 = 2,
  size36 = 2.25,
  size42 = 2.625,
  size48 = 3,
  size54 = 3.375,
  size60 = 3.75,
  size68 = 4.25,
  size76 = 4.75,
  size84 = 5.25,
  size92 = 5.75,
}

export enum IconType {
  FontAwesome = "FontAwesome",
  ReactIcons = "ReactIcons",
  LocalImage = "LocalImage",
}

export enum ImageType {
  Default = "Default",
  Base64 = "Base64",
}

export type ButtonType = "button" | "reset" | "submit";

export const AlertVariant = {
  Primary: "primary",
  Secondary: "secondary",
  Success: "success",
  Warning: "warning",
  Danger: "danger",
  Info: "info",
  Light: "light",
  Dark: "dark",
};

export type ButtonVariant =
  | "primary"
  | "secondary"
  | "success"
  | "warning"
  | "danger"
  | "info"
  | "light"
  | "dark"
  | "outline-primary"
  | "outline-secondary"
  | "outline-success"
  | "outline-warning"
  | "outline-danger"
  | "outline-info"
  | "outline-light"
  | "outline-dark"
  | "link"
  | "transparent";

export type ButtonSize = "sm" | "lg";

export enum SeparatorOrientation {
  HORIZONTAL = "HORIZONTAL",
  VERTICAL = "VERTICAL",
}

export const InputSize = {
  Default: "default",
  Small: "sm",
  Large: "lg",
};

export const TooltipTheme = {
  Default: "default",
  Danger: "danger",
};
