import moment from "moment";
import axios from "axios";
import _ from "lodash";
import qs from "qs";

import apiUrl from "api-url";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";

import {
  SEARCH_CATEGORIES,
  FILTERS,
} from "pages/partview/components/search/DealerPartView.searchOptions";
import { packageDetailsWithFilteredExceptions } from "pages/partview/utils/exceptions.utils";

const STORE_MOUNT_POINT = "dealerPartViewSearch";

// PV-2417: Need to hide Destination filter if has only one location or less.
const destinationLocationsDuck = buildFetchDuck(
  STORE_MOUNT_POINT,
  "destinationLocations",
);

export const fetchDestinationCountForFiliterVisibility =
  (selectedFvOrgId) => (dispatch) => {
    const params = { pageNumber: 0, pageSize: 1 };
    if (selectedFvOrgId) {
      params.fvOrgId = selectedFvOrgId;
    }
    const url = apiUrl("/partview/app/list/destination");
    dispatch(
      destinationLocationsDuck.fetch(url, {
        // Passing pagination params so we get `meta`.
        params,
      }),
    );
  };

export const getShowDestinationFilter = (state) => {
  const duckState = destinationLocationsDuck.selectors.getData(state);
  return duckState?.data?.meta?.totalCount > 1 ?? false;
};

// Actions
const FETCH_TOTAL_COUNT_FOR_SEARCH = `${STORE_MOUNT_POINT}/FETCH_TOTAL_COUNT_FOR_SEARCH`;
const RECEIVE_TOTAL_COUNT_FOR_SEARCH = `${STORE_MOUNT_POINT}/RECEIVE_TOTAL_COUNT_FOR_SEARCH`;

// SearchBarStateBuilder need 'solutionId' as first parameter,
// but not required here therefore named it _ignored_solutionId
const entitiesUrl = (_ignored_solutionId, queryString) => {
  return apiUrl(`/partview/app/search?status=active&${queryString}`);
};

const getSelectedFvOrgId = (state) => {
  return (
    SearchBarState.selectors.getSearchFilters(state)?.fvOrgId?.[0]?.value ??
    null
  );
};

const fetchSearch = (
  queryString = "",
  _ignored_solutionId,
  duck,
  dispatch,
  state,
) => {
  const url = entitiesUrl(null, queryString);
  const config = {
    headers: {
      "x-time-zone": moment.tz.guess(),
      Accept: "application/json;version=SEARCH_RESULTS",
    },
  };

  // Fetch the count
  dispatch(fetchTotalCount(queryString));

  // Remove 'Damage' exception from Active Exception and Exceptions for dealer
  const transform = (data) => {
    let packageDetails = data?.data?.map((result) => {
      return packageDetailsWithFilteredExceptions(result);
    });
    return { ...data, data: packageDetails };
  };

  // Fetch the search
  dispatch(duck.fetch(url, config, transform));

  // Redirect to PartView search page
  dispatch({ type: "DEALER_PARTVIEW_SEARCH" });
};

const fetchTotalCount = (queryString) => {
  return (dispatch, getState) => {
    const state = getState();

    // Update pagination params and Fetch the count
    // pageNumber and pageSize is set to default values always
    // in order to receive the meta object in api
    const countParams = qs.parse(queryString);
    countParams.pageNumber = 0;
    countParams.pageSize = 20;
    const countQueryString = qs.stringify(countParams);

    const url = entitiesUrl(null, countQueryString);

    dispatch({ type: FETCH_TOTAL_COUNT_FOR_SEARCH });

    // Get page size to calculate the total pages
    const pageSize = state[STORE_MOUNT_POINT].pageSize;

    axios({
      method: "GET",
      url,
      headers: {
        "x-time-zone": moment.tz.guess(),
        Accept: "application/json;version=SEARCH_COUNT",
      },
    })
      .then((response) => {
        const count = response.data?.meta?.totalCount;
        dispatch({
          type: RECEIVE_TOTAL_COUNT_FOR_SEARCH,
          count,
          totalPages: _.ceil(count / pageSize),
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

// Selectors
const getTotalCountForSearch = (state) =>
  state[STORE_MOUNT_POINT].totalCountForSearch || 0;

const getTotalPageCountForSearch = (state) =>
  state[STORE_MOUNT_POINT].totalPageCountForSearch || 0;

const getTotalCountForSearchIsLoading = (state) =>
  state[STORE_MOUNT_POINT].totalCountForSearchIsLoading || false;

// Reducer
const DealerPartViewSearchBarReducer = (
  state = {
    totalCountForSearch: 0,
    totalPageCountForSearch: 0,
    totalCountForSearchIsLoading: false,
  },
  action,
) => {
  switch (action.type) {
    case FETCH_TOTAL_COUNT_FOR_SEARCH:
      return {
        ...state,
        totalCountForSearchIsLoading: true,
      };
    case RECEIVE_TOTAL_COUNT_FOR_SEARCH:
      return {
        ...state,
        totalCountForSearch: action.count,
        totalPageCountForSearch: action.totalPages,
        totalCountForSearchIsLoading: false,
      };
    default:
      return state;
  }
};

const SearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  FILTERS,
  fetchSearch,
  [destinationLocationsDuck.reducer, DealerPartViewSearchBarReducer],
  { defaultSort: "destinationEta", reverseSort: false },
);

SearchBarState.actionCreators.exportSearch = _.partial(
  SearchBarState.actionCreators.exportEntities,
  entitiesUrl,
  null,
  { headers: { Accept: "text/csv;version=full" } },
  "part-view-search-results",
);

SearchBarState.selectors = {
  ...SearchBarState.selectors,
  getSelectedFvOrgId,
  getTotalCountForSearch,
  getTotalPageCountForSearch,
  getTotalCountForSearchIsLoading,
};

export default SearchBarState;
