/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { getLads } from "modules/shipment-detail/trip-utils";
import {
  tabsCss,
  tabPanelStyle,
  tabListStyle,
} from "components/multimodal-components/tabStyles";
import { Tabs } from "components/molecules/Tabs.molecule";
import { isExtraLarge } from "components/responsive";
import {
  ArrivalAndDeparturePanelGroup,
  getLocationDataFromShipments,
} from "components/organisms/ArrivalAndDeparturePanelGroup.organism";
import { CommentFeed } from "components/organisms/CommentFeed.organism";
import {
  endToEndTab,
  legTabs,
} from "components/multimodal-components/tabComponents";

import EndToEndTabPanel from "./EndToEndTabPanel";
import LastUpdateDisplay from "../shipment-detail-styled-components/LastUpdateDisplay";
import ShipmentDetailTabs from "../ShipmentDetailTabs";
import { ShipmentTimeline } from "../ShipmentContentPanel";
import ShipmentDetailsCollapsible from "../shipment-detail-styled-components/ShipmentDetailsCollapsible";
import { getAssetId, getReferenceValue } from "../ShipmentUtils";
import { batchCsvShipmentExample } from "components/search-bar/batch-comment-csv-data";
import { useShipmentTranslation } from "shared/hooks/useShipmentTranslation";
import { isShipper } from "shared/utils/organizations.utils";
import { Mode } from "shared/constants/shipments.const";

export const lastUpdateDisplayCss = {
  margin: "0 1em",
  paddingTop: 10,
  " > div": {
    margin: 0,
  },
  div: {
    fontSize: "1em !important",
  },
};

export const shipmentDetailTabsCss = {
  paddingBottom: 10,
  ".react-tabs__tab-panel > div": {
    padding: "0 10px",
  },
  ".table-condensed > thead > tr > th": {
    borderBottomWidth: 1,
    color: "#8c8888",
    fontWeight: "normal",
    padding: 10,
  },
  ".table-condensed > tbody > tr > td": {
    padding: 10,
  },
};

const endToEndPanel = (
  parentShipment,
  childShipments,
  lads,
  selectedMapCoordinate,
  addCoordinate,
  clearCoordinatesByType,
  toggleWatchShipment,
  showComments,
  fetchComments,
  isFetchingComments,
  comments,
  addComment,
  cancelAddComment,
  updateComment,
  cancelUpdateComment,
  markCommentsRead,
  addBatchComments,
  clearBatchComments,
  isBatchCommentInProgress,
  isBatchCommentSuccessful,
  isBatchCommentFailed,
  shipperOrgId,
  fetchNotification,
  enableActionMenu,
  enableAlertMeAction,
  enableShipmentEventsAction,
  enableCancelShipmentAction,
  enableReportDelayAction,
  enableClearDelayAction,
  enableMobileTrackingAction,
  eventHandler,
  shipmentSubscription,
  isShipmentSubscriptionLoading,
  organization,
) => {
  return (
    <Tabs.TabPanel key="end-to-end">
      <EndToEndTabPanel
        parentShipment={parentShipment}
        childShipments={childShipments}
        lads={lads}
        addCoordinate={addCoordinate}
        clearCoordinatesByType={clearCoordinatesByType}
        selectedMapCoordinate={selectedMapCoordinate}
        toggleWatchShipment={toggleWatchShipment}
        showComments={showComments}
        commentShareableWithOrgId={null} // Comments cannot be shared in end-to-end tab
        fetchComments={(pageNumber, pageSize) => {
          return fetchComments(parentShipment.id, pageNumber, pageSize);
        }}
        isFetchingComments={isFetchingComments}
        comments={comments}
        addComment={(data) => {
          return addComment(parentShipment.id, data);
        }}
        cancelAddComment={cancelAddComment}
        updateComment={(commentId, updatedData) => {
          return updateComment(parentShipment.id, commentId, updatedData);
        }}
        cancelUpdateComment={cancelUpdateComment}
        markCommentsRead={(datetime) => {
          return markCommentsRead(parentShipment.id, datetime);
        }}
        addBatchComments={(data, isCsvFormat) => {
          return addBatchComments(data, isCsvFormat);
        }}
        clearBatchComments={clearBatchComments}
        isBatchCommentInProgress={isBatchCommentInProgress}
        isBatchCommentSuccessful={isBatchCommentSuccessful}
        isBatchCommentFailed={isBatchCommentFailed}
        shipperOrgId={shipperOrgId}
        fetchNotification={fetchNotification}
        enableActionMenu={enableActionMenu}
        enableAlertMeAction={enableAlertMeAction}
        enableShipmentEventsAction={enableShipmentEventsAction}
        enableCancelShipmentAction={enableCancelShipmentAction}
        enableReportDelayAction={enableReportDelayAction}
        enableClearDelayAction={enableClearDelayAction}
        enableMobileTrackingAction={enableMobileTrackingAction}
        eventHandler={eventHandler}
        shipmentSubscription={shipmentSubscription}
        isShipmentSubscriptionLoading={isShipmentSubscriptionLoading}
        organization={organization}
      />
    </Tabs.TabPanel>
  );
};

const legPanels = (props) => {
  const {
    parentShipment,
    childShipments,
    addCoordinate,
    clearCoordinatesByType,
    selectedMapCoordinate,
    organization,
    shipmentModes,
    eventHandler,
    showComments,
    fetchComments,
    isFetchingComments,
    comments,
    addComment,
    cancelAddComment,
    updateComment,
    cancelUpdateComment,
    markCommentsRead,
    addBatchComments,
    clearBatchComments,
    isBatchCommentInProgress,
    isBatchCommentSuccessful,
    isBatchCommentFailed,
    fetchNotification,
    t,
    getTranslatedStatus,
  } = props;

  const childSummaries = parentShipment.child_shipments || [];
  const childDetails = childShipments;
  return childSummaries.map((summary, i) => {
    const leg = `Leg ${i + 1}`;

    const shipment = childDetails[summary.shipment_id];

    if (!shipment) {
      return null;
    }

    // Shipment/Shipper Details
    const shipmentId = shipment.creator_shipment_id;
    const assetId =
      organization && shipment ? getAssetId(organization, shipment) : null;
    const mode = shipment?.mode_name ?? "";
    const status = shipment
      ? `${getTranslatedStatus(
          shipment.active_status_ng,
        )} ${getTranslatedStatus(shipment.current_status_ng)}`
      : "";
    const shipperName = shipment.shipper_name;
    const carrierName = shipment.carrier_name;
    const carrierScac = shipment.carrier_scac;

    // H1-3859: Only show unique values on the UI.
    //   To see all, the user can look at the References tab.
    const trailerAndEquipmentNumbers = _.uniq(
      shipment.trailer_equipment_number ?? [],
    );

    const serviceCode = getReferenceValue(shipment, "service_code");

    const exception = shipment ? shipment.active_exceptions_ng : "";

    // Arrivals/Departures
    const { origin, destination, calculatedEta, frozenEta, isFvEta } =
      getLocationDataFromShipments([shipment]);

    return shipment ? (
      <Tabs.TabPanel style={tabPanelStyle} key={summary.shipment_id}>
        <ShipmentDetailsCollapsible
          shipmentId={shipmentId}
          assetId={leg ? assetId : null}
          mode={mode}
          showMadChiclet={!_.isEmpty(mode)}
          status={status}
          shipperName={
            (parentShipment && parentShipment.shipper_name) ||
            (shipment.shipper_name && !parentShipment)
              ? shipperName
              : null
          }
          carrierName={carrierName}
          carrierScac={carrierScac}
          trailerAndEquipmentNumbers={trailerAndEquipmentNumbers}
          exception={exception}
          isBackOrder={shipment?.shipment_details?.is_back_order}
          isOffRoute={shipment?.shipment_details?.is_off_route}
          serviceCode={serviceCode}
          etaTimeCategory={shipment.eta_time_category}
        />
        <ArrivalAndDeparturePanelGroup
          origin={origin}
          destination={destination}
          eta={!_.isNil(frozenEta) ? frozenEta : calculatedEta}
          onlyShowEtaDate={mode === Mode.PARCEL || mode === Mode.LTL}
          etaWarningMessage={
            !_.isNil(frozenEta) &&
            shipment.active_exceptions_ng === "Carrier Delayed"
              ? t(
                  "shipment-details:Carrier has indicated a delay. This ETA is provided by the Carrier.",
                )
              : null
          }
          isFvEta={isFvEta}
          visibleFields={{
            availableForUnload: shipment?.mode_name === "Rail",
          }}
          availableForUnloadTs={shipment?.available_for_unload_ts}
          style={{ margin: "1em" }}
        >
          <ArrivalAndDeparturePanelGroup.SubHeaders>
            <ArrivalAndDeparturePanelGroup.RouteSubHeader
              routeId={shipment?.route_number}
              // ISS-9715: Hiding On Time for Cummins org
              onTimePercentage={
                organization?.fv_id !== "CUMMINS"
                  ? shipment?.on_time_percentage
                  : null
              }
            />
            <ArrivalAndDeparturePanelGroup.TripPlanIdSubHeader
              tripPlanId={shipment?.trip_plan_number}
            />
          </ArrivalAndDeparturePanelGroup.SubHeaders>
        </ArrivalAndDeparturePanelGroup>
        {showComments ? (
          <CommentFeed
            commentShareableWithOrgId={
              shipment.carrier_organization_id !== organization.organization_id
                ? shipment.carrier_organization_id
                : null
            }
            fetchComments={(pageNumber, pageSize) => {
              return fetchComments(summary.shipment_id, pageNumber, pageSize);
            }}
            isFetchingComments={isFetchingComments}
            comments={comments}
            addComment={(data) => {
              return addComment(summary.shipment_id, data);
            }}
            cancelAddComment={cancelAddComment}
            updateComment={(commentId, updatedData) => {
              return updateComment(summary.shipment_id, commentId, updatedData);
            }}
            cancelUpdateComment={cancelUpdateComment}
            markCommentsRead={(datetime) => {
              return markCommentsRead(summary.shipment_id, datetime);
            }}
            addBatchComments={(data, isCsvFormat) =>
              addBatchComments(data, isCsvFormat)
            }
            clearBatchComments={() => clearBatchComments()}
            isBatchCommentInProgress={isBatchCommentInProgress}
            isBatchCommentSuccessful={isBatchCommentSuccessful}
            isBatchCommentFailed={isBatchCommentFailed}
            batchCsvExample={batchCsvShipmentExample}
            batchImportLabel={t("shipment-details:Shipment ID")}
            shipperOrgId={
              isShipper(organization) ? organization.organization_id : null
            }
            style={{ margin: "1em" }}
            fetchNotification={fetchNotification}
          />
        ) : null}
        <ShipmentTimeline
          shipment={shipment}
          organization={organization}
          shipmentModes={shipmentModes}
          hidePercentage={false}
          eventHandler={eventHandler}
        />
        <div css={lastUpdateDisplayCss}>
          <LastUpdateDisplay shipment={shipment} />
        </div>
        <div css={shipmentDetailTabsCss}>
          <ShipmentDetailTabs
            shipment={shipment}
            addCoordinate={addCoordinate}
            clearCoordinatesByType={clearCoordinatesByType}
            selectedMapCoordinate={selectedMapCoordinate}
            organization={organization}
          />
        </div>
      </Tabs.TabPanel>
    ) : null;
  });
};

const ShipmentLegTabs = (props) => {
  const { t } = useTranslation("shipment-details");
  const { getTranslatedStatus } = useShipmentTranslation();

  const {
    parentShipment,
    childShipments,
    selectedChildShipmentID,
    ladsList,
    eventHandler,
    addCoordinate,
    clearCoordinatesByType,
    selectedMapCoordinate,
    toggleWatchShipment,
    showComments,
    fetchComments,
    isFetchingComments,
    comments,
    addComment,
    cancelAddComment,
    updateComment,
    cancelUpdateComment,
    markCommentsRead,
    addBatchComments,
    clearBatchComments,
    isBatchCommentInProgress,
    isBatchCommentSuccessful,
    isBatchCommentFailed,
    shipperOrgId,
    fetchNotification,
    enableActionMenu,
    enableAlertMeAction,
    enableShipmentEventsAction,
    enableCancelShipmentAction,
    enableReportDelayAction,
    enableClearDelayAction,
    enableMobileTrackingAction,
    shipmentSubscription,
    isShipmentSubscriptionLoading,
    organization,
  } = props;

  const childSummaries = parentShipment.child_shipments || [];
  const childDetails = childShipments;
  const tabs = [
    endToEndTab(eventHandler),
    ...legTabs(childSummaries, childDetails, eventHandler, true),
  ];
  const lads = getLads(ladsList);

  const panels = [
    endToEndPanel(
      parentShipment,
      childDetails,
      lads,
      selectedMapCoordinate,
      addCoordinate,
      clearCoordinatesByType,
      toggleWatchShipment,
      showComments,
      fetchComments,
      isFetchingComments,
      comments,
      addComment,
      cancelAddComment,
      updateComment,
      cancelUpdateComment,
      markCommentsRead,
      addBatchComments,
      clearBatchComments,
      isBatchCommentInProgress,
      isBatchCommentSuccessful,
      isBatchCommentFailed,
      shipperOrgId,
      fetchNotification,
      enableActionMenu,
      enableAlertMeAction,
      enableShipmentEventsAction,
      enableCancelShipmentAction,
      enableReportDelayAction,
      enableClearDelayAction,
      enableMobileTrackingAction,
      eventHandler,
      shipmentSubscription,
      isShipmentSubscriptionLoading,
      organization,
    ),
    ...legPanels({ ...props, lads, t, getTranslatedStatus }),
  ];

  let selectedTabIndex = 0;
  if (selectedChildShipmentID && parentShipment && parentShipment.child_ids) {
    selectedTabIndex =
      parentShipment.child_ids.indexOf(selectedChildShipmentID) + 1;
  }

  return (
    <Tabs fullWidthTabs css={tabsCss} defaultIndex={selectedTabIndex}>
      <Tabs.TabList
        maxTabs={isExtraLarge() ? 5 : 3}
        staticTabs={1}
        style={tabListStyle}
      >
        {tabs}
      </Tabs.TabList>
      {panels}
    </Tabs>
  );
};

ShipmentLegTabs.propTypes = {
  parentShipment: PropTypes.object,
  childShipments: PropTypes.object,
  selectedChildShipmentID: PropTypes.string,
  ladsList: PropTypes.array,
  shipmentModes: PropTypes.array,
  eventHandler: PropTypes.func,
  toggleWatchShipment: PropTypes.func,
  showComments: PropTypes.bool,
  fetchComments: PropTypes.func,
  isFetchingComments: PropTypes.bool,
  comments: PropTypes.shape({
    totalPages: PropTypes.number,
    totalCount: PropTypes.number,
    totalCountUnread: PropTypes.number,
    data: PropTypes.array,
  }),
  addComment: PropTypes.func.isRequired,
  cancelAddComment: PropTypes.func.isRequired,
  updateComment: PropTypes.func.isRequired,
  cancelUpdateComment: PropTypes.func.isRequired,
  markCommentsRead: PropTypes.func.isRequired,
  addBatchComments: PropTypes.func.isRequired,
  clearBatchComments: PropTypes.func.isRequired,
  isBatchCommentInProgress: PropTypes.bool.isRequired,
  isBatchCommentSuccessful: PropTypes.bool.isRequired,
  isBatchCommentFailed: PropTypes.bool.isRequired,
  shipperOrgId: PropTypes.number,
  fetchNotification: PropTypes.func.isRequired,
  // Action menu
  enableActionMenu: PropTypes.bool,
  enableAlertMeAction: PropTypes.bool,
  enableShipmentEventsAction: PropTypes.bool,
  enableCancelShipmentAction: PropTypes.bool,
  enableReportDelayAction: PropTypes.bool,
  enableClearDelayAction: PropTypes.bool,
  enableMobileTrackingAction: PropTypes.bool,
  // Map Coordinates
  addCoordinate: PropTypes.func.isRequired,
  clearCoordinatesByType: PropTypes.func.isRequired,
  selectedMapCoordinate: PropTypes.object,
  shipmentSubscription: PropTypes.object,
  isShipmentSubscriptionLoading: PropTypes.bool,
  organization: PropTypes.object,
};
export default ShipmentLegTabs;
