import {
  ItemAttribute,
  DependencyType,
} from "pages/createmilestone/constants/CreateMilestone.const";

// ---- Field helper functions ----
const defaultDependencies = { partnerType: "", milestoneEvent: "" };

export const isRequired = (input, dependencies = defaultDependencies) => {
  return isRequiredHelper(input.required, dependencies);
};

const isRequiredHelper = (valueDef, dependencies) => {
  // This was called with the final "required" value
  if (typeof valueDef === "boolean") {
    return valueDef;
  }
  // This was called with an object where we have to
  // look the next value with a "dependency"
  else if (typeof valueDef === "object") {
    if (valueDef.dependency) {
      // Peek the next value by calling this function recusively
      let nextValueDef;
      if (valueDef.dependency === DependencyType.PARTNER_TYPE) {
        nextValueDef = isRequiredHelper(
          valueDef[dependencies.partnerType],
          dependencies,
        );
      } else if (valueDef.dependency === DependencyType.MILESTONE_EVENT) {
        nextValueDef = isRequiredHelper(
          valueDef[dependencies.milestoneEvent],
          dependencies,
        );
      }

      // If the next call is not undefined or null
      //
      // Note: Using this check so that we don't accidentally
      // interperet the value false as undefined or null.
      // eslint-disable-next-line
      if (nextValueDef != null) {
        return nextValueDef;
      }
    }

    // There wasn't a dependency so we will return
    // the default value
    return valueDef.default;
  }
};

/**
 * This supports returning a boolean to determine whether to display an input or not
 * based on the field's configuration, current partner type, and current milestone event.
 * See the README in this folder for examples of how to configure the fields.
 */
export const isDisplayed = ({ item, partnerType, milestoneEvent }) => {
  const attribute = ItemAttribute.DISPLAYED;
  const value = lookupValue({ item, attribute, partnerType, milestoneEvent });
  return typeof value === "undefined";
};

export const getMilestoneEventValue = (inputVal) => {
  if (inputVal) {
    const [code, vmacsCode] = inputVal.split("_");
    return { code, vmacsCode };
  }
  return { code: null, vmacsCode: null };
};

export const hasDisableUntilValue = ({ item, partnerType, milestoneEvent }) => {
  const attribute = ItemAttribute.DISABLE_UNTIL_VALUE;
  const value = lookupValue({ item, attribute, partnerType, milestoneEvent });
  return typeof value === "undefined" ? null : value;
};

const lookupValue = ({ item, attribute, partnerType, milestoneEvent }) => {
  if (
    milestoneEvent &&
    hasKey(item[attribute], partnerType) &&
    hasKey(item[attribute][partnerType], milestoneEvent)
  ) {
    return item[attribute][partnerType][milestoneEvent];
  }
  if (hasMilestoneDependency({ item, attribute, partnerType })) {
    return item[attribute].default; // fallback
  }
  if (partnerType && hasKey(item[attribute], partnerType)) {
    return item[attribute][partnerType];
  }

  return item[attribute];
};

/**
 * This is a null safe way to see if an object has a key
 */
const hasKey = (object, key) =>
  object ? Object.keys(object).includes(key) : false;

const hasMilestoneDependency = ({ item, attribute, partnerType }) =>
  item[attribute]?.[partnerType]?.dependency === DependencyType.MILESTONE_EVENT;
