/** @jsxImportSource @emotion/react */
import { Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { PhotoPlaceholder } from "components/no-data-placeholders";
import { FontSize } from "components/atoms/Text.atom";
import { Image, ImageType } from "components/atoms/Image.atom";
import { FlexRowDiv, FlexColDiv } from "styles/container-elements";
import { DetailPanelGroupItem } from "pages/components/molecules/DetailPanelGroupItem.molecule";
import { ExceptionsBox } from "shared/components/molecules/ExceptionsBox.molecule";
import { WatchToggle } from "shared/components/molecules/WatchToggle.molecule";
import { MediaQueries, useIsExtraSmall } from "components/responsive";
import { ShowMoreList } from "components/molecules/ShowMoreList.molecule";
import { ChicletCSS as Chiclet, colorForLad } from "components/chiclets";
import Colors from "styles/colors";
import _ from "lodash";
import {
  localizedDateFormatter,
  localizedTimeFormatter,
} from "utils/date-time";

export const SurgicalToteTrackingDetailsWidget = ({
  toteDetails,
  toteMedia,
  sttActiveExceptions,
  watch,
  onWatchChange,
}) => {
  const {
    containerId: toteId,
    status,
    alternateLocationCode,
    location_type: locationType,
    eventTs,
    locationName,
    homeLocation,
    contents,
  } = toteDetails;

  const letterForLad = ({ name }, status) => {
    if (status === "In Transit") {
      return "T";
    }

    if (name === "Distribution") {
      return "D";
    }

    if (name === "Hospital") {
      return "H";
    }

    return "";
  };

  const { t } = useTranslation("surgical-tote-tracking");
  const labelStyle = { width: "auto", marginRight: "1em" };

  const locationCode = alternateLocationCode
    ? `(${alternateLocationCode})`
    : "";

  const isExtraSmall = useIsExtraSmall();
  let checkboxLabelText = t("surgical-tote-tracking:Watch this Asset");
  if (isExtraSmall) {
    checkboxLabelText = t("surgical-tote-tracking:Watch");
  }
  const tooltipText = t(
    "surgical-tote-tracking:Add this Asset to your Watched Asset list on the My Asset Homepage and receive notifications for new comments",
  );

  const headerRightContent = (
    <div css={{ display: "flex", alignItems: "center" }}>
      <WatchToggle
        id="tote-watch-toggle"
        checked={watch}
        onClick={(e) => {
          // stop the event propagation else detail panel is also toggled
          // since it is collapsible.
          e.stopPropagation();
        }}
        onChange={onWatchChange}
        labelText={checkboxLabelText}
        labelTextSize={FontSize.size14}
        tooltipText={tooltipText}
        tooltipTextSize={FontSize.size12}
      />
    </div>
  );

  const codeLetter = letterForLad({ name: locationType }, status);
  const color =
    status === "In Transit"
      ? Colors.background.DARK_BLUE
      : colorForLad({ name: locationType });

  let contentsList = contents?.map((content) => content.value);
  return (
    <Fragment>
      <PanelGroup collapsible>
        <PanelGroup.Header
          title={t("surgical-tote-tracking:Details")}
          rightContent={headerRightContent}
        ></PanelGroup.Header>
        <PanelGroup.Content>
          <FlexRowDiv
            css={{
              flexDirection: "column",
              [MediaQueries.largeAndUp]: {
                flexDirection: "row",
                justifyContent: "space-between",
                flexWrap: "nowrap",
              },
            }}
          >
            <FlexColDiv css={{ flex: "1 1", marginRight: "1em" }}>
              {toteId ? (
                <DetailPanelGroupItem
                  data-qa="details-tote-id"
                  label={t("surgical-tote-tracking:Asset ID")}
                  labelStyle={labelStyle}
                  rowStyle={{ flexDirection: "column", alignItems: "baseline" }}
                >
                  {toteId}
                </DetailPanelGroupItem>
              ) : null}
              <DetailPanelGroupItem
                data-qa="details-tote-id-case-no"
                label={t("surgical-tote-tracking:UDI")}
                labelStyle={labelStyle}
                rowStyle={{ flexDirection: "column", alignItems: "baseline" }}
              >
                {contentsList?.length > 0 ? (
                  <ShowMoreList
                    title={t("surgical-tote-tracking:UDI")}
                    list={contentsList}
                    visibleItemCount={3}
                  />
                ) : null}
              </DetailPanelGroupItem>

              {locationCode || locationName || eventTs ? (
                <DetailPanelGroupItem
                  data-qa="details-tote-id-current-location"
                  label={t("surgical-tote-tracking:Current Location")}
                  labelStyle={labelStyle}
                  rowStyle={{ flexDirection: "column", alignItems: "baseline" }}
                >
                  <FlexColDiv>
                    <div className="align-items-center flex-wrap">
                      {codeLetter ? (
                        <Chiclet
                          codeLetter={codeLetter}
                          backgroundColor={color}
                          height={24}
                          width={24}
                          style={{ marginRight: "0.5em" }}
                        />
                      ) : null}

                      <Fragment>
                        {locationCode ? (
                          <span>
                            <strong>{`${locationCode} `}</strong>
                          </span>
                        ) : null}
                        {locationName ? (
                          <span>
                            <strong>{`${locationName} `}</strong>
                          </span>
                        ) : null}
                        {eventTs ? (
                          <Fragment>
                            <span>
                              <span>{`${localizedDateFormatter(
                                eventTs,
                              )} `}</span>
                              <span>{`${localizedTimeFormatter(
                                eventTs,
                              )}`}</span>
                            </span>
                          </Fragment>
                        ) : null}
                      </Fragment>
                    </div>
                  </FlexColDiv>
                </DetailPanelGroupItem>
              ) : null}
              {!_.isNil(homeLocation) ? (
                <DetailPanelGroupItem
                  data-qa="details-tote-id-home-location"
                  label={t("surgical-tote-tracking:Home Location")}
                  labelStyle={labelStyle}
                  rowStyle={{ flexDirection: "column", alignItems: "baseline" }}
                >
                  <FlexColDiv>
                    <div className="align-items-center flex-wrap">
                      <Fragment>
                        {!_.isNil(homeLocation.locationCode) ? (
                          <div>
                            <strong>{`${homeLocation.locationCode} `}</strong>
                          </div>
                        ) : null}
                        {!_.isNil(homeLocation.name) ? (
                          <div>
                            <strong>{`${homeLocation.name} `}</strong>
                          </div>
                        ) : null}
                        {!_.isNil(homeLocation.address) ? (
                          <div>
                            <strong>{`${homeLocation.address} `}</strong>
                          </div>
                        ) : null}
                        {!_.isNil(homeLocation.city) ||
                        !_.isNil(homeLocation.state) ? (
                          <div>
                            <strong>{`${homeLocation.city}, ${homeLocation.state} `}</strong>
                          </div>
                        ) : null}
                      </Fragment>
                    </div>
                  </FlexColDiv>
                </DetailPanelGroupItem>
              ) : null}
            </FlexColDiv>
            <FlexRowDiv
              css={{
                alignSelf: "flex-start",
                flexDirection: "column-reverse",
                [MediaQueries.mediumAndDown]: {
                  flexDirection: "row",
                },
              }}
            >
              <Image
                style={{
                  alignSelf: "center",
                  [MediaQueries.mediumAndDown]: {
                    marginRight: "1em",
                  },
                  [MediaQueries.largeAndUp]: {
                    marginTop: "1em",
                  },
                }}
                type={ImageType.Default}
                media={toteMedia}
                placeholder={<PhotoPlaceholder />}
                altText={t("surgical-tote-tracking:Car")}
                data-qa={"image-shipper"}
              />
              <ExceptionsBox
                holds={[]}
                showAllException={true}
                exceptions={sttActiveExceptions}
                shouldBuildExceptionsBySeverity={true}
              />
            </FlexRowDiv>
          </FlexRowDiv>
        </PanelGroup.Content>
      </PanelGroup>
    </Fragment>
  );
};

SurgicalToteTrackingDetailsWidget.propTypes = {
  toteDetails: PropTypes.object,
  toteMedia: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  supplierLocation: PropTypes.object,
  supplierLocationLoaded: PropTypes.bool,
  sttActiveExceptions: PropTypes.array,
  watch: PropTypes.bool,
  onWatchChange: PropTypes.func,
};
