/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

import { Text, FontSize } from "components/atoms/Text.atom";
import LocationCell from "components/organisms/base-table/Cell/LocationCell";
import { NaPlaceholder } from "components/no-data-placeholders";
import { DateTime } from "components/atoms/DateTime.atom";

import { getReferenceValueByName } from "utils/response-utils";
import { WindowDateTimeRange } from "pages/shipments/components/WindowDateTimeRange";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import {
  Mode,
  ModeId,
  ShipmentException,
} from "shared/constants/shipments.const";

const DateTimeDisplay = ({ dateTime }) => {
  return (
    <DateTime
      dateTime={dateTime}
      size={FontSize.size12}
      stack
      plain
      bold
      localize
      fallback={<NaPlaceholder />}
    />
  );
};

DateTimeDisplay.propTypes = {
  dateTime: PropTypes.string,
};

const CellContainer = styled.div({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "1em",
});

const StopOrderCell = ({ value }) => {
  return (
    <CellContainer css={{ padding: 0 }}>
      <Text bold size={FontSize.size14}>
        {value}
      </Text>
    </CellContainer>
  );
};

StopOrderCell.propTypes = {
  value: PropTypes.string,
};

const StopLocationCell = ({ value: stop }) => {
  return (
    <CellContainer>
      <LocationCell
        location={stop.location}
        showAddress={!stop.isClm}
        deliveryType={stop.deliveryType}
      />
    </CellContainer>
  );
};

StopLocationCell.propTypes = {
  value: PropTypes.object,
};

const ScheduledCell = ({ value: stop, isMultileg }) => {
  const { t } = useTranslation("shipment-details");

  if (!isMultileg) {
    return (
      <CellContainer>
        <WindowDateTimeRange
          from={stop.earliestArrival}
          to={stop.latestArrival}
          size={FontSize.size12}
          localize
        />
      </CellContainer>
    );
  }

  return (
    <CellContainer>
      {stop.stopOrderLabel !== "O" ? (
        <div>
          <Text underline block size={FontSize.size12}>
            {t("shipment-details:Delivery")}
          </Text>
          <WindowDateTimeRange
            from={stop.deliveryEarliestArrival}
            to={stop.deliveryLatestArrival}
            size={FontSize.size12}
            localize
          />
        </div>
      ) : null}
      {stop.stopOrderLabel !== "D" ? (
        <div>
          <Text underline block size={FontSize.size12}>
            {t("shipment-details:Pickup")}
          </Text>
          <WindowDateTimeRange
            from={stop.earliestArrival}
            to={stop.latestArrival}
            size={FontSize.size12}
            localize
          />
        </div>
      ) : null}
    </CellContainer>
  );
};

ScheduledCell.propTypes = {
  value: PropTypes.object,
  isMultileg: PropTypes.bool,
  showScheduled: PropTypes.bool,
};

const EstimatedCell = ({
  value: stop,
  isMultileg,
  showEstimated,
  showEtaTime,
}) => {
  const { t } = useTranslation("shipment-details");
  if (!isMultileg) {
    const showEta = showEstimated && !stop.arrivedAt && stop.eta;

    if (!showEta) {
      return null;
    }

    return (
      <CellContainer>
        <DateTime
          dateTime={stop.eta}
          size={FontSize.size12}
          stack
          plain
          localize
          showTime={showEtaTime}
        >
          <DateTime.Time italic />
          <DateTime.Timezone italic color="inherit" />
          <DateTime.Date bold />
        </DateTime>
      </CellContainer>
    );
  }

  // H1-1034 Don't display estimated delivery times for legs that actual delivery time
  if (stop.stopOrderLabel !== "O" && !stop.departedAt) {
    return (
      <CellContainer>
        <div css={{ display: "flex", flexDirection: "column" }}>
          <Text underline block size={FontSize.size12}>
            {t("shipment-details:Delivery")}
          </Text>
          {stop.latestArrival ? (
            <DateTimeDisplay dateTime={stop.latestArrival} isETA={false} />
          ) : (
            <NaPlaceholder />
          )}
        </div>
      </CellContainer>
    );
  }

  return null;
};

EstimatedCell.propTypes = {
  value: PropTypes.object,
  isMultileg: PropTypes.bool,
  showEstimated: PropTypes.bool,
  showEtaTime: PropTypes.bool,
};

const ActualCell = ({ value: stop, isMultileg }) => {
  const { t } = useTranslation("shipment-details");
  if (!isMultileg) {
    return (
      <CellContainer>
        <div css={{ display: "flex", flexDirection: "column" }}>
          <Text underline block size={FontSize.size12}>
            {t("shipment-details:Arrival")}
          </Text>
          <DateTimeDisplay dateTime={stop.arrivedAt} isETA={false} />
        </div>
        <div css={{ display: "flex", flexDirection: "column" }}>
          <Text underline block size={FontSize.size12}>
            {t("shipment-details:Departure")}
          </Text>
          <DateTimeDisplay dateTime={stop.departedAt} isETA={false} />
        </div>
      </CellContainer>
    );
  }

  return (
    <CellContainer>
      {stop.stopOrderLabel !== "O" ? (
        <div css={{ display: "flex", flexDirection: "column" }}>
          <Text underline block size={FontSize.size12}>
            {t("shipment-details:Delivery")}
          </Text>
          {stop.departedAt ? (
            <DateTimeDisplay dateTime={stop.delivery} isETA={false} />
          ) : (
            <NaPlaceholder />
          )}
        </div>
      ) : null}
      {stop.stopOrderLabel !== "D" ? (
        <div css={{ display: "flex", flexDirection: "column" }}>
          <Text underline block size={FontSize.size12}>
            {t("shipment-details:Pickup")}
          </Text>
          {stop.arrivedAt ? (
            <DateTimeDisplay dateTime={stop.pickup} isETA={false} />
          ) : (
            <NaPlaceholder />
          )}
        </div>
      ) : null}
    </CellContainer>
  );
};

ActualCell.propTypes = {
  value: PropTypes.object,
  isMultileg: PropTypes.bool,
};

const useStopsColumns = ({
  showScheduled = true,
  showEstimated = true,
  showEtaTime = true,
  isMultileg = false,
}) => {
  const { t } = useTranslation("shipment-details");
  const columns = [];
  const columnConfig = {
    disableSortBy: true,
    disableFilters: true,
  };
  columns.push({
    ...columnConfig,
    Header: "",
    id: "stop-order",
    disableResizing: true,
    width: 20,
    accessor: "stopOrderLabel",
    Cell: StopOrderCell,
  });
  columns.push({
    ...columnConfig,
    Header: t("shipment-details:Stop"),
    width: 180,
    accessor: (stop) => stop,
    Cell: StopLocationCell,
  });
  if (showScheduled) {
    columns.push({
      ...columnConfig,
      Header: t("shipment-details:Scheduled"),
      width: 180,
      accessor: (stop) => stop,
      Cell: (props) => <ScheduledCell {...props} isMultileg={isMultileg} />,
    });
  }
  columns.push({
    ...columnConfig,
    Header: t("shipment-search:Estimated"),
    width: 100,
    accessor: (stop) => stop,
    Cell: (props) => (
      <EstimatedCell
        {...props}
        isMultileg={isMultileg}
        showEstimated={showEstimated}
        showEtaTime={showEtaTime}
      />
    ),
  });
  columns.push({
    ...columnConfig,
    Header: t("shipment-details:Actual"),
    width: 100,
    accessor: (stop) => stop,
    Cell: (props) => <ActualCell {...props} isMultileg={isMultileg} />,
  });
  return columns;
};

export const StopsTable = ({
  mode,
  isMultileg,
  stops,
  active_exceptions_ng,
}) => {
  const columns = useStopsColumns({
    showScheduled: ![Mode.LTL, Mode.PARCEL].includes(mode),
    // DEV-1221:
    // If the shipment has missed the destination (related to DEV-1093),
    // then we prevent the ETA from showing on the stops tab as well
    showEstimated: active_exceptions_ng !== ShipmentException.MISSED_DROP_OFF,
    showEtaTime: ![Mode.LTL, Mode.PARCEL].includes(mode),
    isMultileg,
  });

  if (!stops) {
    return null;
  }

  const data = stops
    .filter((stop) => {
      // HT-61
      // filter out terminal arrivals from LTL shipments which are now being treated as stops in the b.e
      // H1-2635: also filter out parcel terminal
      // FIXME: this shouldn't be so deep in logic, nor should it use mode_id
      const isTerminalStop =
        [ModeId.LTL, ModeId.PARCEL].includes(stop.mode_id) &&
        stop.stop_reason === "X4";
      return isMultileg || !isTerminalStop;
    })
    .map((stop, index, originalStops) => {
      // DEV-1306 - change stop cell display for CLM stops
      const isClm = stop.stop_identifier?.includes("ETAI");
      let stopOrderLabel = index.toString();
      if (index === 0) {
        stopOrderLabel = "O";
      } else if (index === originalStops.length - 1) {
        stopOrderLabel = "D";
      } else if (isClm) {
        stopOrderLabel = "I";
      }
      return {
        stopOrderLabel: stopOrderLabel,
        arrivedAt: isMultileg ? stop.arrived : stop.arrived_at,
        departedAt: isMultileg ? stop.departed : stop.departed_at,
        eta: stop.eta,
        isClm: isClm,
        // SH-6368: Grab DeliveryType from stop references
        deliveryType: getReferenceValueByName(
          stop.shipment_stop_references,
          "DeliveryType",
        ),
        location: stop.location,
        earliestArrival: stop.earliest_arrival_datetime,
        latestArrival: stop.latest_arrival_datetime,
        // Specific to isMultileg
        deliveryEarliestArrival: stop.delivery_earliest_arrival_datetime,
        deliveryLatestArrival: stop.delivery_latest_arrival_datetime,
        delivery: stop.delivery,
        pickup: stop.pickup,
      };
    });

  return <BaseTable theme={Themes.LIGHT} columns={columns} data={data} />;
};

StopsTable.propTypes = {
  mode: PropTypes.string,
  isMultileg: PropTypes.bool,
  stops: PropTypes.array,
  active_exceptions_ng: PropTypes.string,
};
