/** @jsxImportSource @emotion/react */
import { ComponentProps } from "react";
import { StylesConfig, components, SingleValueProps } from "react-select";

import { Button } from "components/atoms/Button.atom";
import { SelectAsync } from "components/atoms/SelectAsync.atom";
import Colors from "styles/colors";
import { useTranslation } from "react-i18next";

const NoSeparator = () => null;

interface SortKey {
  value: string;
  label: string;
}

export const CP_SORT_BY_RUN_OUT_BY = "runOutBy";
export const CP_SORT_BY_MUST_SHIP_BY = "mustShipBy";

export const CP_SORT_DIRECTION_ASCENDING = false;
export const CP_SORT_DIRECTION_DESCENDING = true;

interface SortBySelectProps extends ComponentProps<typeof SelectAsync> {
  label: string;
  setSort: (value: string) => void;
  setDirection: (value: boolean) => void;
  direction: boolean;
  sort: string;
}

export function SortBySelect(props: SortBySelectProps) {
  const { t } = useTranslation("critical-parts");

  const sortKeys: SortKey[] = [
    { value: CP_SORT_BY_RUN_OUT_BY, label: t("critical-parts:Run Out By") },
    { value: CP_SORT_BY_MUST_SHIP_BY, label: t("critical-parts:Must Ship By") },
  ];

  const sortKeyHashMap: { [key: string]: SortKey } = {};
  sortKeys.forEach((sortKey: SortKey) => {
    sortKeyHashMap[sortKey.value] = sortKey;
  });

  const setDir = props.setDirection;
  const dir = props.direction;

  const label = (color: string = "transparent") => ({
    alignItems: "center",
    display: "flex",
    ":before": {
      color: color,
      content: `"${t("critical-parts:Sort By")}"`,
      display: "block",
      marginRight: 8,
      height: 22,
    },
  });

  const styles: StylesConfig = {
    control: (baseStyles) => ({
      ...baseStyles,
      marginBottom: 10,
      width: "265px",
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      zIndex: 999,
      transform: "translateY(-15px)",
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      ...label(Colors.text.GRAY),
    }),
    singleValue: (baseStyles) => ({
      ...baseStyles,
      ...label(Colors.text.GRAY),
    }),
  };

  const SingleValue = ({ children, ...props }: SingleValueProps) => (
    <components.SingleValue {...props}>
      {children}
      <Button
        onMouseDown={(e) => {
          e.stopPropagation();
          setDir(!dir);
        }}
        css={{
          lineHeight: "10px",
          fontSize: 12,
          padding: 1,
          marginLeft: 8,
          float: "right",
        }}
        size="sm"
      >
        {dir ? t("critical-parts:desc") : t("critical-parts:asc")}
      </Button>
    </components.SingleValue>
  );

  return (
    <SelectAsync
      {...props}
      isSearchable={false}
      options={sortKeys}
      onChange={(selected: any) => props.setSort(selected?.value)}
      value={sortKeyHashMap[props.sort]}
      styles={styles}
      components={{
        IndicatorSeparator: NoSeparator,
        SingleValue,
      }}
    />
  );
}
