import { connect } from "react-redux";
import CriticalPartsDetails from "./CriticalParts.Details.page";

function mapStateToProps(state) {
  const locationId = state.location.payload.location_id;
  const partNumber = state.location.payload.part_number;
  return { locationId, partNumber };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CriticalPartsDetails);
