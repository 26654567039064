/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";

import { Tooltip } from "components/atoms/Tooltip.atom";
import { Text } from "components/atoms/Text.atom";
import { FontSize } from "components/atoms/Icon.atom";
import { Icon } from "components/atoms/Icon.atom";
import Colors from "styles/colors";
import {
  getMobileTrackingEnabled,
  getIsShipmentWithinTrackingWindow,
} from "../shipment-detail/ShipmentUtils";

export const ActionNav = ({
  shipment,
  eventHandler,
  enableAlertMeAction,
  enableShipmentEventsAction,
  enableCancelShipmentAction,
  enableReportDelayAction,
  enableClearDelayAction,
  enableMobileTrackingAction,
  enableBillOfLadingAction,
  assetId,
  arrived,
  shipmentSubscription,
  isShipmentSubscriptionLoading,
}) => {
  const { t } = useTranslation("shipment-details");
  const [isOpen, setIsOpen] = useState(false);
  const actionToggle = () => setIsOpen(!isOpen);
  const tooltipChildren = arrived ? (
    <Text>
      {t(
        "shipment-details:This action cannot be performed on a completed shipment",
      )}
    </Text>
  ) : null;

  const allowCarrierDelayActions = !arrived;

  return (
    <DropdownButton
      variant="primary"
      open={isOpen}
      onClick={(e) => {
        e.stopPropagation();
      }}
      title={t("shipment-details:Actions")}
      id="action-selector"
      color={Colors.tableHeadingBackground}
      onToggle={() => {
        actionToggle();
      }}
      className="float-end"
    >
      {enableAlertMeAction && (
        <Dropdown.Item
          disabled={isShipmentSubscriptionLoading}
          onClick={() => {
            eventHandler(null, "SHOW_ALERT_ME_MODAL");
          }}
        >
          <div css={{ display: "flex", flexDirection: "row" }}>
            <Text css={{ marginRight: "1rem" }}>
              {t("fv-vin-details:Alert Me")}
            </Text>
            {isShipmentSubscriptionLoading ? (
              <Icon src={faSpinner} size={FontSize.size20} spin />
            ) : null}
            {shipmentSubscription ? (
              <Text
                italic
                size={FontSize.size12}
                color={Colors.highlight.GREEN}
              >
                ({t("components:Active")})
              </Text>
            ) : null}
          </div>
        </Dropdown.Item>
      )}

      {enableShipmentEventsAction && (
        <Tooltip tooltipChildren={tooltipChildren} placement="left">
          <Dropdown.Item
            disabled={arrived}
            onClick={() => {
              if (!arrived) {
                eventHandler(null, "SHOW_ADD_ASSET");
              }
            }}
          >
            {t("shipment-details:Assign Asset/Trailer")}
          </Dropdown.Item>
        </Tooltip>
      )}
      {enableShipmentEventsAction && assetId && (
        <Dropdown.Item
          onClick={() => {
            eventHandler(null, "SHOW_UNASSIGN_ASSET");
          }}
        >
          {t("shipment-details:Unassign Asset")}
        </Dropdown.Item>
      )}
      {enableShipmentEventsAction && (
        <Dropdown.Item
          onClick={() => {
            eventHandler(null, "SHOW_ADD_EVENTS");
          }}
        >
          {t("shipment-details:Report Arrival/Departure Events")}
        </Dropdown.Item>
      )}
      {enableReportDelayAction ? (
        <Tooltip tooltipChildren={tooltipChildren} placement="left">
          <Dropdown.Item
            disabled={!allowCarrierDelayActions}
            onClick={() => {
              if (allowCarrierDelayActions) {
                eventHandler(null, "SHOW_REPORT_CARRIER_DELAY");
              }
            }}
          >
            {t("shipment-details:Report Destination Arrival Estimate")}
          </Dropdown.Item>
        </Tooltip>
      ) : null}
      {enableClearDelayAction ? (
        <Tooltip tooltipChildren={tooltipChildren} placement="left">
          <Dropdown.Item
            disabled={!allowCarrierDelayActions}
            onClick={() => {
              if (allowCarrierDelayActions) {
                eventHandler(null, "SHOW_CLEAR_CARRIER_DELAY");
              }
            }}
          >
            {t("shipment-details:Clear Delay")}
          </Dropdown.Item>
        </Tooltip>
      ) : null}
      {enableBillOfLadingAction && (
        <Tooltip tooltipChildren={tooltipChildren} placement="left">
          <Dropdown.Item
            onClick={() => eventHandler(null, "SHOW_BILL_OF_LADING")}
          >
            {t("shipment-details:Add Bill of Lading/Manifest Numbers")}
          </Dropdown.Item>
        </Tooltip>
      )}
      {enableCancelShipmentAction && (
        <Tooltip tooltipChildren={tooltipChildren} placement="left">
          <Dropdown.Item
            disabled={arrived}
            onClick={() => {
              if (!arrived) {
                eventHandler(null, "SHOW_CANCEL_SHIPMENT");
              }
            }}
          >
            {t("shipment-details:Cancel Shipment")}
          </Dropdown.Item>
        </Tooltip>
      )}
      {enableMobileTrackingAction &&
        shipment.mode_name !== "Rail" &&
        !getMobileTrackingEnabled(shipment) &&
        getIsShipmentWithinTrackingWindow(shipment) && (
          <Tooltip tooltipChildren={tooltipChildren} placement="left">
            <Dropdown.Item
              disabled={arrived}
              onClick={() => {
                if (!arrived) {
                  eventHandler(null, "TOGGLE_ENABLE_MOBILE_TRACKING");
                }
              }}
            >
              {t("shipment-details:Enable Mobile Tracking")}
            </Dropdown.Item>
          </Tooltip>
        )}
      {enableMobileTrackingAction &&
        getMobileTrackingEnabled(shipment) &&
        getIsShipmentWithinTrackingWindow(shipment) && (
          <Dropdown.Item
            onClick={() => {
              eventHandler(null, "TOGGLE_DISABLE_MOBILE_TRACKING");
            }}
          >
            {t("shipment-details:Disable Mobile Tracking")}
          </Dropdown.Item>
        )}
    </DropdownButton>
  );
};

ActionNav.propTypes = {
  shipment: PropTypes.object,
  eventHandler: PropTypes.func,
  enableAlertMeAction: PropTypes.bool,
  enableShipmentEventsAction: PropTypes.bool,
  enableCancelShipmentAction: PropTypes.bool,
  enableReportDelayAction: PropTypes.bool,
  enableClearDelayAction: PropTypes.bool,
  enableMobileTrackingAction: PropTypes.bool,
  enableBillOfLadingAction: PropTypes.bool,
  assetId: PropTypes.string,
  arrived: PropTypes.bool,
  shipmentSubscription: PropTypes.object,
  isShipmentSubscriptionLoading: PropTypes.bool,
};
