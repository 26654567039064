/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { convertCountToLocaleString } from "utils/html-utils";

export const LocalizedNumber = ({ value = 0 }) => {
  return convertCountToLocaleString(value);
};

LocalizedNumber.propTypes = {
  /**
   * The numerical value to be localized.
   */
  value: PropTypes.number,
};
