import { connect } from "react-redux";
import LocationEditView from "./LocationManagement.LocationNewOrEdit.page";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import {
  addLocation,
  addAndLinkLocation,
  editLocation,
  deleteLocation,
  fetchLocationDetails,
  fetchDdaLocationDetails,
  fetchInventoryViewLocationDetails,
  fetchDamageViewLocationDetails,
  fetchCriticalPartsLocationEnabled,
  getSelectedLocationId,
  getSelectedLocation,
  fetchHeatMapData,
  isLocationLinked,
  isLocationSaving,
  isConfigSaving,
  isInventoryViewLocationDetailsLoading,
  isDamageViewLocationConfigLoading,
  isCriticalPartsLocationConfigLoading,
  isEnableDpuLoading,
  isLocationSaved,
  setLocationSaved,
  setLocationLinked,
  clearActionStatus,
  fetchCountries,
  fetchSubdivisions,
  fetchDdaSubdivisions,
  clearSubdivisions,
  getCurrentSubdivisionsCountryCode,
  fetchLocationChildren,
  setLocationChildrenPagination,
  getLocationChildren,
  getIsLoadingLocationChildren,
  getLocationChildrenErrorMessage,
  getLocationChildrenPage,
  getLocationChildrenPageSize,
  getLocationChildrenTotalPages,
  getDdaLocationData,
  getInventoryViewLocationData,
  getDamageViewLocationData,
  getCriticalPartsLocationData,
  getDpuAddLocationData,
  updateDdaLocationDetails,
  getDdaSubdivisions,
  locationLadChanged,
  getScacListData,
  isScacListLoading,
} from "pages/administration/location-management/redux/Locations.state";
import TitleState from "shared/redux/TitleState";
import LadsState from "shared/redux/Lads.state";
import LocationMatchingState from "pages/administration/location-management/unresolved-location-matching/redux/UnresolvedLocationMatching.state";
import * as OrganizationState from "modules/organizations/OrganizationsState";
import MapState from "modules/map/MapState";

function mapStateToProps(state) {
  const locationId = getSelectedLocationId(state);
  return {
    authorization: getAuthorization(state),
    isFvAdmin: getAuthorization(state).isFvAdmin(),
    lads: LadsState.selectors.getLadsList(state),
    heatmapData: state.locations.heatmap.data,
    locationId: locationId ? parseInt(locationId) : locationId,
    heatmapIsLoading: state.locations.heatmap.isLoading,
    selectedLocation: getSelectedLocation(state),
    unresolvedLocation:
      LocationMatchingState.selectors.getUnresolvedLocationFromRedirect(state),
    unresolvedShipment:
      LocationMatchingState.selectors.getShipmentWithUnresolvedLocation(state),
    activeOrganization: OrganizationState.getActiveOrganization(state),
    mapPlatform: MapState.selectors.getMapPlatform(state),
    mapTypeOverride: MapState.selectors.getMapTypeOverride(state),
    googleMaps: state.maps.googleMaps,
    hereMapsPlatform: state.maps.hereMapsPlatform,
    isLocationSaving: isLocationSaving(state),
    isConfigSaving: isConfigSaving(state),
    isInventoryViewLocationDetailsLoading:
      isInventoryViewLocationDetailsLoading(state),
    isDamageViewLocationConfigLoading: isDamageViewLocationConfigLoading(state),
    isCriticalPartsLocationConfigLoading:
      isCriticalPartsLocationConfigLoading(state),
    isEnableDpuLoading: isEnableDpuLoading(state),
    locationSaved: isLocationSaved(state),
    locationLinked: isLocationLinked(state),
    actionStatus: state.locations.locations.actionStatus,
    countries: state.locations.countries.data,
    subdivisions: state.locations.subdivisions.data,
    subdivisionsCountryCode: getCurrentSubdivisionsCountryCode(state),
    locationChildren: getLocationChildren(state),
    locationChildrenErrorMessage: getLocationChildrenErrorMessage(state),
    isLoadingLocationChildren: getIsLoadingLocationChildren(state),
    locationChildrenPage: getLocationChildrenPage(state),
    locationChildrenPageSize: getLocationChildrenPageSize(state),
    locationChildrenTotalPages: getLocationChildrenTotalPages(state),
    ddaLocationData: getDdaLocationData(state),
    ddaSubdivisions: getDdaSubdivisions(state),
    invLocationData: getInventoryViewLocationData(state),
    dvLocationData: getDamageViewLocationData(state),
    cpLocationData: getCriticalPartsLocationData(state),
    dpuAddLocationData: getDpuAddLocationData(state),
    scacListData: getScacListData(state),
    isScacListLoading: isScacListLoading(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTitle: (title) => dispatch(TitleState.actionCreators.setTitle(title)),
    setSubTitle: (subTitle) =>
      dispatch(TitleState.actionCreators.setSubTitle(subTitle)),
    addLocation: (data) => dispatch(addLocation(data)),
    addAndLinkLocation: (newLocation, unresolvedID, unresolvedLocation) =>
      dispatch(
        addAndLinkLocation(newLocation, unresolvedID, unresolvedLocation),
      ),
    editLocation: (id, data) => dispatch(editLocation(id, data)),
    deleteLocation: (id) => dispatch(deleteLocation(id)),
    fetchLocationDetails: (id, dereference) =>
      dispatch(fetchLocationDetails(id, dereference)),
    fetchDdaLocationDetails: (id) => dispatch(fetchDdaLocationDetails(id)),
    fetchInventoryViewLocationDetails: (locationId) =>
      dispatch(fetchInventoryViewLocationDetails(locationId)),
    fetchDamageViewLocationDetails: (locationId) =>
      dispatch(fetchDamageViewLocationDetails(locationId)),
    fetchCriticalPartsLocationDetails: (locationId) =>
      dispatch(fetchCriticalPartsLocationEnabled(locationId)),
    updateDdaLocationDetails: (
      locationId,
      details,
      ActionOptions,
      isAddLocationForInventoryViewEnabled,
      newCapacity,
      prevIsAddLocationForInventoryViewEnabled,
      isDamageViewAddLocationEnabled,
      prevIsDamageViewAddLocationEnabled,
      isCriticalPartsAddLocationEnabled,
      prevIsCriticalPartsAddLocationEnabled,
      isDpuAddLocationEnabled,
      prevIsDpuAddLocationEnabled,
      scacModificationList,
    ) =>
      dispatch(
        updateDdaLocationDetails(
          locationId,
          details,
          ActionOptions,
          isAddLocationForInventoryViewEnabled,
          newCapacity,
          prevIsAddLocationForInventoryViewEnabled,
          isDamageViewAddLocationEnabled,
          prevIsDamageViewAddLocationEnabled,
          isCriticalPartsAddLocationEnabled,
          prevIsCriticalPartsAddLocationEnabled,
          isDpuAddLocationEnabled,
          prevIsDpuAddLocationEnabled,
          scacModificationList,
        ),
      ),
    setLocationSaved: (value) => dispatch(setLocationSaved(value)),
    setLocationLinked: (value) => dispatch(setLocationLinked(value)),
    pushLocationsScreen: (didCreateNewLocationFromUnlinked) => {
      if (didCreateNewLocationFromUnlinked) {
        dispatch({ type: "LOCATION_UNRESOLVED" });
      } else {
        dispatch({ type: "LOCATION_MANAGEMENT" });
      }
    },
    pushShipmentDetailView: (shipmentID) =>
      dispatch({
        type: "SHIPMENT_DETAIL",
        payload: { shipment_id: shipmentID },
      }),
    fetchHeatMapData: (locationId) => dispatch(fetchHeatMapData(locationId)),
    clearActionStatus: () => dispatch(clearActionStatus()),
    clearSubdivisions: () => dispatch(clearSubdivisions()),
    fetchCountries: () => dispatch(fetchCountries()),
    fetchSubdivisions: (countryCode) =>
      dispatch(fetchSubdivisions(countryCode)),
    fetchDdaSubdivisions: (countryCode) =>
      dispatch(fetchDdaSubdivisions(countryCode)),
    fetchLocationChildren: (locationId, resetPagination) =>
      dispatch(fetchLocationChildren(locationId, resetPagination)),
    setLocationChildrenPagination: (locationId, page, pageSize) =>
      dispatch(setLocationChildrenPagination(locationId, page, pageSize)),
    locationLadChanged: (previousLad, currentLad) =>
      dispatch(locationLadChanged(previousLad, currentLad)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationEditView);
