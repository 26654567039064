import _ from "lodash";
import {
  humanizeTimeString,
  localizedRailDateTimeFormatter,
} from "../../utils/date-time";
import { isCarrier, isShipper } from "shared/utils/organizations.utils";

// Helpers

/**
 * Simple string formatting of location data
 */
const formatLastCityFromLocation = (location) => {
  let result = null;
  if (location.current_city) {
    result = `${location.current_city}, ${location.current_state}`;
    if (location.current_country) {
      result = `${result}, ${location.current_country}`;
    }
  }
  return result;
};

/**
 * Simple check if it is a pending or arriving shipment
 */
const isPendingOrArriving = (activeStatus, currentStatus) => {
  return (
    _.includes(["Arrived", "Released", "Available for Unload"], activeStatus) ||
    _.includes(
      ["Arrived at Destination", "Departed Destination"],
      currentStatus,
    )
  );
};

/**
 * Return reported data when the mode of the shipment is "Rail"
 */
const getLastReportedDataForRailShipment = (
  currentLocation,
  defaultReportLabel,
) => {
  let lastTime = null;
  let formattedLastTime = null;
  let lastCity = null;
  let formattedLastCity = null;

  if (currentLocation?.updates?.length > 0) {
    lastTime = currentLocation.updates[currentLocation.updates.length - 1].time;
    formattedLastTime = localizedRailDateTimeFormatter(
      currentLocation.updates[currentLocation.updates.length - 1].time,
    );
  }

  if (currentLocation) {
    lastCity = {
      city: currentLocation.current_city,
      state: currentLocation.current_state,
      country: currentLocation.current_country,
    };
    formattedLastCity = formatLastCityFromLocation(currentLocation);
  }

  return {
    label: defaultReportLabel,
    lastTime: lastTime,
    formattedLastTime: formattedLastTime,
    lastCity: lastCity,
    formattedLastCity: formattedLastCity,
    showDistance: true, // H1-2149: Always show Last Update for Rail shipments
  };
};

/**
 * Return reported data when the mode of the other shipment modes
 */
const getLastReportedDataForOtherShipmentMode = (
  activeStatus,
  currentStatus,
  currentLocation,
  actualDelivery,
  exception,
  defaultReportLabel,
) => {
  let lastCity = null;
  let formattedLastCity = null;
  let lastTime = null;
  let formattedLastTime = null;
  let reportedLabel = defaultReportLabel;
  let showDistance = false;

  if (isPendingOrArriving(activeStatus, currentStatus)) {
    reportedLabel = "";
    lastTime = actualDelivery;
    formattedLastTime = humanizeTimeString(actualDelivery, true);
  } else if (
    !_.includes(
      [
        "Arrived",
        "Pending: AssetID",
        "Scheduled",
        "Available for Unload",
        "Released",
      ],
      activeStatus,
    ) &&
    exception !== "Under Review"
  ) {
    showDistance = true;

    if (currentLocation && currentLocation.updates.length > 0) {
      lastTime =
        currentLocation.updates[currentLocation.updates.length - 1].time;
      formattedLastTime = humanizeTimeString(
        currentLocation.updates[currentLocation.updates.length - 1].time,
        true,
      );
      lastCity = {
        city: currentLocation.current_city,
        state: currentLocation.current_state,
        country: currentLocation.current_country,
      };
      formattedLastCity = formatLastCityFromLocation(currentLocation);
    }
  }

  return {
    label: reportedLabel,
    lastTime: lastTime,
    formattedLastTime: formattedLastTime,
    lastCity: lastCity,
    formattedLastCity: formattedLastCity,
    showDistance: showDistance,
  };
};

// Exported utils functions

/**
 * Extract last reported data from shipment data depending on shipment mode.
 *
 * NOTE: It does not return last update for pending or arrived shipments.
 * Only compute the last reported information for selective status shipmnets
 */
export const extractLastReportedAt = (
  activeStatus,
  currentStatus,
  exception,
  currentLocation,
  actualDelivery,
  mode,
  t,
) => {
  const defaultReportLabel = t ? t("shipment-search:Last Report") + ":" : null;

  // Do not display last update for pending or arrived shipments
  // Only compute the last reported information for selective status shipmnets
  if (mode === "Rail") {
    return getLastReportedDataForRailShipment(
      currentLocation,
      defaultReportLabel,
    );
  } else {
    return getLastReportedDataForOtherShipmentMode(
      activeStatus,
      currentStatus,
      currentLocation,
      actualDelivery,
      exception,
      defaultReportLabel,
    );
  }
};

export const getAssetId = (organization, shipmentOrStatus) => {
  const assetId = shipmentOrStatus?.obc_asset_id ?? null;
  if (!assetId) {
    return null;
  }

  // If this is a mobile phone asset ID, need to
  // to see if the user has permissions to view it
  if (assetId.indexOf("FVMB") === 0) {
    if (isCarrier(organization) || isShipper(organization)) {
      return assetId;
    } else {
      return "MOBILE";
    }
  }

  return assetId;
};

export const stopIsPending = (stop) => {
  return stop.arrived_at == null && stop.departed_at == null;
};

export const getNextStop = (stops) => {
  let nextStop = null;

  // Walk through all the stops and find the next stop
  // we need to check all the stops, because we
  // may have arrived at other stops later in the shipment
  for (let i = 0; i < stops.length; i++) {
    const stop = stops[i];
    const pending = stopIsPending(stop);

    // If we encounter a stop we've arrived
    // at, clear our next Stop value
    if (pending === false) {
      nextStop = null;
    } else {
      // If this is the first pending stop
      // assign our next stop value
      if (nextStop === null) {
        nextStop = stop;
      }
    }
  }

  return nextStop;
};

export const getLastStop = (stops) => {
  let lastStop = null;

  // Walk through all the stops and find the next stop
  // we need to check all the stops, because we
  // may have arrived at other stops later in the shipment
  for (let i = 0; i < stops.length; i++) {
    const stop = stops[i];
    const pending = stopIsPending(stop);

    // If we encounter a stop we've arrived
    // at set our last stop value
    if (pending === false) {
      lastStop = stop;
    }
  }

  return lastStop;
};

export const getCurrentModeName = (shipment, shipmentModes) => {
  if (_.isNil(shipmentModes)) {
    return shipment.mode_name;
  }
  const railMode = shipmentModes.find((m) => m.name === "Rail");
  const truckMode = shipmentModes.find((m) => m.name === "Truck");

  if (_.isNil(railMode) || _.isNil(truckMode)) {
    return shipment.mode_name;
  }

  if (shipment.mode_name !== "Intermodal") {
    return shipment.mode_name;
  }

  // If an intermodal shipment only has rail mode stops, only return rail
  let truckStops = shipment.shipment_stops.filter(
    (s) => s.mode_id === truckMode.id,
  );
  if (truckStops.length === 0) {
    return railMode.name;
  }

  const nextStop = getNextStop(shipment.shipment_stops);
  const lastStop = getLastStop(shipment.shipment_stops);

  if (nextStop && lastStop) {
    if (
      nextStop.mode_id === railMode.id &&
      lastStop.mode_id === railMode.id &&
      shipment.tracking_disabled === true
    ) {
      return railMode.name;
    }
  }

  return truckMode.name;
};

export const getMobileTrackingEnabled = (shipment) => {
  if (!shipment.obc_asset_id) {
    return false;
  }

  if (shipment.obc_asset_id.indexOf("FVMB") === 0) {
    return true;
  }

  return false;
};

export const getIsShipmentWithinTrackingWindow = (shipment) => {
  return true;
};

export const getEquipNumber = (references) => {
  if (!references || _.isEmpty(references)) {
    return "";
  }

  const equipNum = references
    .filter((r) => _.includes(["equipment_number"], r.qualifier))
    .map((r, i) => r.value);

  return _.head(equipNum) || "";
};

export const getRouteShipments = (
  shipment,
  childShipments,
  selectedLegID,
  isLoaded,
) => {
  if (!shipment || _.isEmpty(shipment)) {
    return [];
  }

  if (!shipment?.shipment_details?.is_multileg) {
    return [shipment];
  }

  if (!childShipments || _.isEmpty(childShipments) || !isLoaded) {
    return [];
  }

  if (selectedLegID) {
    return [childShipments[selectedLegID]];
  }

  return shipment.child_ids
    ? shipment.child_ids.map((id) => childShipments[id])
    : [];
};

export const getLegShipments = (childShipments, isLoaded, isValid) => {
  if (!childShipments || _.isEmpty(childShipments) || !isLoaded || !isValid) {
    return [];
  }

  return childShipments ? Object.values(childShipments) : [];
};

/**
 * From the shipment data, check on the references if there is the qualifier
 * name and return its value.
 */
export const getReferenceValue = (shipment, reference_key) => {
  const references = shipment.shipment_references;
  if (!references || _.isEmpty(references)) {
    return "";
  }

  const values = references
    .filter((r) => _.includes([reference_key], r.qualifier))
    .map((r) => r.value);

  return _.head(values) || "";
};

/**
 * From the known values of shipment.loaded_status (previously rail_loaded_status),
 * return a user-ready translated string representation. Default to Empty.
 */

export const loadedStatusCodesEN = {
  L: "Loaded",
  E: "Empty",
};

export const loadedStatusCodes = {
  L: (t) => t("shipment-search:Loaded"),
  E: (t) => t("shipment-search:Empty"),
};

export const getLoadedStatusDisplayValue = (loadedStatus, t) => {
  const lookup = _.get(loadedStatusCodes, loadedStatus, loadedStatusCodes.E);
  return lookup(t);
};

export const getBillOfLadingNumbers = (references = []) => {
  const bolQualifiers = ["mbol", "bol", "bm"];

  // If references are null, it won't get the default value.
  // null is considered a value.
  if (_.isNil(references)) {
    return [];
  }

  return references
    .filter((ref) => bolQualifiers.includes(ref.qualifier.toLowerCase()))
    .map((ref) => ref.value);
};

export const getTrailerAndEquipmentNumberFromChildShipments = (
  shipments = {},
) => {
  // The map creates an array of arrays.
  // flatten the parent array so that we have a single array of values.
  return _.flatten(
    // `shipments` is an object with keys that are their internal id.
    // the value is the shipment object.
    Object.values(shipments).map(
      (shipment) => shipment.trailer_equipment_number,
    ),
  );
};
