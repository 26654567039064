import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  InventoryStatus,
  InitialETADuration,
  TimeOnSiteDuration,
} from "api/consts";
import { logWarningForMissingTranslation } from "utils/log-warning.utils";

export const useInventoryViewTranslation = () => {
  const { t } = useTranslation("inventory-view");

  const getTranslatedStatus = useCallback(
    (option: string) => {
      switch (option) {
        case InventoryStatus.SHIPPABLE:
          return t("inventory-view:Shippable");
        case InventoryStatus.NONSHIPPABLE:
          return t("inventory-view:Non-Shippable");
        default: {
          logWarningForMissingTranslation(option);
          return option;
        }
      }
    },
    [t],
  );

  const getTranslatedInitialETALabels = useCallback(
    (option: string) => {
      switch (option) {
        case InitialETADuration.MISSED_FIRST_ETA:
          return t("inventory-view:Missed First ETA");
        case InitialETADuration.HOURS_0_47_OUT:
          return t("inventory-view:0-47 Hours Out");
        case InitialETADuration.HOURS_48_95_OUT:
          return t("inventory-view:48-95 Hours Out");
        case InitialETADuration.HOURS_96_OUT:
          return t("inventory-view:96+ Hours Out");
        default: {
          logWarningForMissingTranslation(option);
          return option;
        }
      }
    },
    [t],
  );

  const getTranslatedTimeOnSiteLabels = useCallback(
    (option: string) => {
      switch (option) {
        case TimeOnSiteDuration.DAYS_10:
          return t("inventory-view:10+ Days");
        case TimeOnSiteDuration.DAYS_6_9:
          return t("inventory-view:6-9 Days");
        case TimeOnSiteDuration.DAYS_3_5:
          return t("inventory-view:3-5 Days");
        case TimeOnSiteDuration.DAYS_0_2:
          return t("inventory-view:0-2 Days");
        default: {
          logWarningForMissingTranslation(option);
          return option;
        }
      }
    },
    [t],
  );

  return {
    getTranslatedStatus,
    getTranslatedInitialETALabels,
    getTranslatedTimeOnSiteLabels,
  };
};
