/** @jsxImportSource @emotion/react */
import React from "react";
import {
  OverlayScrollbarsComponent,
  OverlayScrollbarsComponentProps,
} from "overlayscrollbars-react";
import "overlayscrollbars/styles/overlayscrollbars.css";

export type ScrollAreaProps = {
  autoHide?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

export function ScrollArea(props: React.PropsWithChildren<ScrollAreaProps>) {
  const { autoHide = false, className, style = {}, children } = props;
  const handleWidth = "11px";
  const handleColor = "rgba(0, 0, 0, 0.5)";
  const theme = "os-theme-scroll-area";
  const themeConfig = {
    "--os-size": handleWidth,
    "--os-padding-perpendicular": "2px",
    "--os-padding-axis": "2px",
    "--os-track-bg": "none",
    "--os-track-bg-hover": "none",
    "--os-track-bg-active": "none",
    "--os-track-border-radius": handleWidth,
    "--os-track-border-hover": "none",
    "--os-track-border-active": "none",
    "--os-handle-border-radius": handleWidth,
    "--os-handle-bg": handleColor,
    "--os-handle-bg-hover": handleColor,
    "--os-handle-bg-active": handleColor,
    "--os-handle-border": "none",
    "--os-handle-border-hover": "none",
    "--os-handle-border-active": "none",
    "--os-handle-min-size": "33px",
    "--os-handle-max-size": "none",
    "--os-handle-perpendicular-size": "100%",
    "--os-handle-perpendicular-size-hover": "100%",
    "--os-handle-perpendicular-size-active": "100%",
    "--os-handle-interactive-area-offset": "4px",
  };
  const options: OverlayScrollbarsComponentProps["options"] = {
    paddingAbsolute: false,
    showNativeOverlaidScrollbars: false,
    update: {
      elementEvents: [["img", "load"]],
      debounce: [0, 33],
      attributes: null,
      ignoreMutation: null,
    },
    overflow: {
      x: "scroll",
      y: "scroll",
    },
    scrollbars: {
      theme: theme,
      visibility: "auto",
      autoHide: autoHide ? "scroll" : "never",
      autoHideDelay: 1300,
      autoHideSuspend: false,
      dragScroll: true,
      clickScroll: false, // Note: If true, requires ClickScrollPlugin.
      pointers: ["mouse", "touch", "pen"],
    },
  };
  return (
    <OverlayScrollbarsComponent
      css={{ [`.${theme}`]: themeConfig }}
      className={className}
      style={style}
      options={options}
      defer
      children={children}
    />
  );
}
