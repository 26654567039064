/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, FormGroup } from "react-bootstrap";
import ImageUploader from "react-images-upload";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import _ from "lodash";
import Loader from "react-loader";

import {
  addOrganization,
  clearActionStatus,
  getOrganizationTypes,
  fetchExternalCodeDefinition,
  getExternalCodeDefinitions,
  getExternalCodeDefinitionsLoading,
  clearExternalCodeDefinition,
} from "../OrganizationsState";
import { getIsLoadingOrgLocationsFailed } from "pages/administration/location-management/redux/Locations.state";
import { Modal } from "components/molecules/Modal.molecule";
import { FormAlert, SelectInput, TextInput } from "components-old/modal-elems";
import Colors from "styles/colors";
import { FlexRowDiv, FlexColDiv } from "styles/container-elements";
import { isValidEmail } from "utils/validation-utils";
import { LocationDesignationSelect } from "./LocationDesignationSelect";
import { useOrganizationsTranslation } from "shared/utils/organizations.utils";
import { ExternalCodeField } from "./ExternalCodeField";
import { BrandingOptionSelect } from "./BrandingOptionSelect";
import { ShipperOrganizationsAsyncSelect } from "./ShipperOrganizationsAsyncSelect";
import OrganizationsSearchBarState from "../OrganizationsSearchBarState";
import { useGrantedFeaturesOptions } from "./utils/organizations.utils";
import { GrantedFeaturesSelect } from "./GrantedFeaturesSelect";

const ValidationRow = ({ isValid, description }) => {
  return (
    <FlexRowDiv style={{ marginBottom: ".25em", width: "100%" }}>
      <FontAwesomeIcon
        style={{
          color: isValid
            ? Colors.highlight.GREEN
            : Colors.highlight.MEDIUM_LIGHT_GRAY,
          fontSize: "large",
          marginRight: ".5em",
          marginTop: ".1em",
        }}
        icon={faCheckCircle}
      />
      <span css={{ fontSize: "small" }}>{description}</span>
    </FlexRowDiv>
  );
};

ValidationRow.propTypes = {
  description: PropTypes.string.isRequired,
  isValid: PropTypes.bool,
};

const initOrg = {
  name: "",
  selectedType: null,
  email: "",
  freightVerifyId: "",
  contactName: "",
  phoneNumber: "",
  scac: "",
  base64Image: null,
  shipper: null,
  locations: [],
  externalCodes: {},
  features: null,
};

const AddOrganizationModal = ({
  show,
  hide,
  addOrganization,
  actionStatus,
  clearActionStatus,
  fetchExternalCodeDefinition,
  organizationName,
  organizationTypes,
  isLoadingOrgLocationsFailed,
  externalCodeDefinition,
  externalCodeDefinitionLoading,
  clearExternalCodeDefinition,
  searchOrganizations,
}) => {
  const { t } = useTranslation(["organizations"]);
  const { getTranslatedOrganizationType } = useOrganizationsTranslation();

  const [org, setOrg] = useState(initOrg);
  const [shipperID, setShipperID] = useState(null);

  const isCarrier = org.selectedType?.label === t("organizations:Carrier");
  const isPartner = org.selectedType?.label === t("organizations:Partner");
  const isDealer = org.selectedType?.label === t("organizations:Dealer");

  const inputHandler = (value) => {
    if (actionStatus) {
      clearActionStatus();
    }
    setOrg((prevState) => {
      return { ...prevState, ...value };
    });
  };

  const inputHandlerGrantedFeatures = (value) => {
    setOrg((prevState) => ({
      ...prevState,
      features: !_.isEmpty(value?.grantedFeatures)
        ? value?.grantedFeatures
        : null,
    }));
  };

  const getExternalCodeValue = (externalCodeName) => {
    return externalCodeDefinition?.externalCodes?.find(
      (code) => code.name === externalCodeName,
    )?.externalCode;
  };

  const externalCodesOnChangeHandler = (externalCodeName, value) => {
    setOrg((prevState) => {
      const newState = { ...prevState };
      newState.externalCodes[getExternalCodeValue(externalCodeName)] = value;
      return newState;
    });
  };

  const inputHandlerLocationDesignation = (value) => {
    if (actionStatus) {
      clearActionStatus();
    }
    setOrg((prevState) => {
      return { ...prevState, locations: value };
    });
  };

  const inputHandlerBranding = (value) => {
    if (actionStatus) {
      clearActionStatus();
    }
    setOrg((prevState) => {
      return { ...prevState, branding: value };
    });
  };

  const shipperInputHandler = (value) => {
    if (actionStatus) {
      clearActionStatus();
    }

    // Clear locations when setting value
    // Since the locations for one shipper will not be viable for another shipper.
    setOrg((prevState) => {
      return { ...prevState, locations: [], ...value };
    });

    setShipperID(value.shipper.value);
  };

  const isValidForm = () => {
    if (isDealer) {
      if (!org.shipper || !org.locations || org.locations.length === 0) {
        return false;
      }
    }

    if (isCarrier && !org.scac) {
      return false;
    }

    return (!org.email || isValidEmail(org.email)) && org.selectedType;
  };

  const clearForm = () => {
    setOrg(initOrg);
  };

  const grantedFeaturesOptions = useGrantedFeaturesOptions();

  const orgTypeOptions = useMemo(() => {
    return _.chain(organizationTypes)
      .sortBy("name")
      .map((orgType) => {
        return {
          label: getTranslatedOrganizationType(orgType.name),
          value: orgType.id,
        };
      })
      .value();
  }, [organizationTypes, getTranslatedOrganizationType]);

  useEffect(() => {
    if (!_.isNil(organizationName)) {
      const selectedType = orgTypeOptions.find(
        (type) => type.label === organizationName,
      );
      setOrg((prevState) => {
        return { ...prevState, selectedType };
      });
    }
  }, [organizationName, orgTypeOptions]);

  useEffect(() => {
    if (org.shipper) {
      fetchExternalCodeDefinition(org.shipper.value);
      setOrg((prevState) => {
        return { ...prevState, externalCodes: {} };
      });
    } else {
      clearExternalCodeDefinition();
    }
  }, [org.shipper, fetchExternalCodeDefinition, clearExternalCodeDefinition]);

  useEffect(() => {
    if (actionStatus && actionStatus.includes("ORGANIZATION_ADDED")) {
      setOrg(initOrg);
      clearActionStatus();
      searchOrganizations();
      hide();
    }
  }, [actionStatus, clearActionStatus, searchOrganizations, hide]);

  const onImageDrop = (image) => {
    if (image && image[0] instanceof Blob) {
      let reader = new FileReader();
      reader.readAsDataURL(image[0]);
      reader.onload = () => {
        inputHandler({ base64Image: reader.result });
      };
      reader.onerror = (error) => {
        console.error("Error: ", error);
      };
    }
  };

  return (
    <Modal
      backdrop={"static"}
      show={show}
      onHide={() => {
        clearForm();
        hide();
      }}
    >
      <Modal.Header title={t("organizations:Add New Organization")} />
      <Modal.Body>
        {actionStatus && actionStatus === "ORGANIZATION_ADDED" ? (
          <FormAlert
            alertType={"SUCCESS"}
            alert={`${t("organizations:An organization ")} ${org.name} ${t(
              "organizations:has been created",
            )}`}
          />
        ) : null}
        <FlexRowDiv>
          <TextInput
            label={t("organizations:Organization Name")}
            value={org.name}
            changeHandler={inputHandler}
            prop="name"
          />
          <SelectInput
            label={t("organizations:Type")}
            placeholder={t("organizations:Select...")}
            options={orgTypeOptions}
            value={org.selectedType}
            changeHandler={inputHandler}
            prop="selectedType"
            multi={false}
          />
        </FlexRowDiv>
        <FlexRowDiv style={{ marginTop: "1em" }}>
          <TextInput
            label={t("organizations:Email")}
            value={org.email}
            changeHandler={inputHandler}
            prop="email"
          />
          <TextInput
            label={t("organizations:FreightVerify ID")}
            value={org.freightVerifyId}
            changeHandler={inputHandler}
            prop="freightVerifyId"
          />
        </FlexRowDiv>
        <FlexRowDiv style={{ marginTop: "1em" }}>
          <TextInput
            label={t("organizations:Contact Name")}
            value={org.contactName}
            changeHandler={inputHandler}
            prop="contactName"
          />
          <TextInput
            label={t("organizations:Phone Number")}
            value={org.phoneNumber}
            changeHandler={inputHandler}
            prop="phoneNumber"
          />
        </FlexRowDiv>
        {isCarrier || isDealer ? (
          <GrantedFeaturesSelect
            label={t(
              "organizations:Dealer Pick Up Feature - This Action Cannot be Undone",
            )}
            placeholder={t("organizations:Select...")}
            grantedFeaturesOptions={grantedFeaturesOptions}
            value={org?.features}
            changeHandler={inputHandlerGrantedFeatures}
          />
        ) : null}
        {isCarrier ? (
          <FlexRowDiv style={{ marginTop: "1em" }}>
            <TextInput
              style={{ width: "100%" }}
              label={t("organizations:SCAC")}
              value={org.scac}
              changeHandler={inputHandler}
              prop="scac"
            />
          </FlexRowDiv>
        ) : null}
        {isPartner ? (
          <FlexRowDiv style={{ marginTop: "1em" }}>
            <TextInput
              style={{ width: "100%" }}
              label={
                // Partner Code is the SCAC for Partner organizations
                t("organizations:Partner Code")
              }
              value={org.scac}
              changeHandler={inputHandler}
              prop="scac"
            />
          </FlexRowDiv>
        ) : null}
        {isDealer ? (
          <React.Fragment>
            <FlexRowDiv
              css={{
                marginTop: "1em",
                display: "flex",
                flexDirection: "column",
                gap: "0.5em",
              }}
            >
              <span
                css={{
                  color: "#acb5be",
                }}
              >
                {t("organizations:Shipper")}
              </span>
              <ShipperOrganizationsAsyncSelect
                value={org.shipper}
                onChange={(org) => {
                  shipperInputHandler({ shipper: org });
                }}
              />
            </FlexRowDiv>
            {org.shipper ? (
              <React.Fragment>
                <FlexColDiv
                  style={{
                    marginTop: "1em",
                    color: "#acb5be",
                  }}
                >
                  <FormGroup css={{ marginBottom: "1rem" }}>
                    <span>{t("organizations:Location Designation")}</span>
                  </FormGroup>
                  <LocationDesignationSelect
                    onChange={inputHandlerLocationDesignation}
                    value={org.locations}
                    selectedShipper={shipperID}
                  />
                </FlexColDiv>
                <FlexColDiv
                  style={{
                    marginTop: "1em",
                    color: "#acb5be",
                  }}
                >
                  <FormGroup css={{ marginBottom: "1rem" }}>
                    <span>{t("organizations:Branding")}</span>
                  </FormGroup>
                  <BrandingOptionSelect
                    onChange={inputHandlerBranding}
                    value={org.branding}
                    selectedShipper={shipperID}
                  />
                </FlexColDiv>
              </React.Fragment>
            ) : null}
            {org.shipper &&
            (externalCodeDefinitionLoading ||
              externalCodeDefinition?.externalCodes?.length > 0) ? (
              <FlexRowDiv
                style={{
                  marginTop: "1em",
                  minHeight: "5em",
                  position: "relative",
                  color: "#acb5be",
                }}
              >
                <Loader
                  loaded={!externalCodeDefinitionLoading}
                  scale={0.75}
                  loadedClassName="w-100"
                >
                  <FlexRowDiv css={{ flexWrap: "wrap" }}>
                    {externalCodeDefinition?.externalCodes?.map((code) => (
                      <ExternalCodeField
                        key={code.name}
                        externalCodeName={code.name}
                        onChange={externalCodesOnChangeHandler}
                        style={{
                          marginTop: "0.5em",
                          width: "50%",
                          color: Colors.text.HIT_GRAY,
                        }}
                      />
                    ))}
                  </FlexRowDiv>
                </Loader>
              </FlexRowDiv>
            ) : null}
          </React.Fragment>
        ) : null}
        <FlexRowDiv style={{ marginTop: "1.5em" }}>
          <ImageUploader
            withIcon={true}
            withPreview={true}
            singleImage={true}
            buttonText={t("organizations:Choose images")}
            label={t("organizations:Max file size 5mb, accepted jpg|gif|png")}
            fileSizeError={t("organizations:file size is too big")}
            fileTypeError={t("organizations:is not supported file extension")}
            onChange={onImageDrop}
            imgExtension={[".png"]}
            maxFileSize={5242880}
          />
        </FlexRowDiv>
        {actionStatus && actionStatus === "Organization_Exists" ? (
          <FormAlert
            alertType={"FAIL"}
            alert={t(
              "organizations:An organization with that name already exists",
            )}
          />
        ) : null}
        {actionStatus && actionStatus === "Organization_Add_Error" ? (
          <FormAlert
            alertType={"FAIL"}
            alert={t(
              "organizations:An error occurred while adding the organization",
            )}
          />
        ) : null}
        {isLoadingOrgLocationsFailed ? (
          <FormAlert
            alertType={"FAIL"}
            alert={t(
              "organizations:An error occurred while loading locations for the selected Shipper",
            )}
          />
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <ValidationRow
          isValid={org.name ? true : false}
          description={t("organizations:Name is required.")}
        />
        <ValidationRow
          isValid={org.selectedType ? true : false}
          description={t("organizations:Type is required.")}
        />
        {isCarrier ? (
          <ValidationRow
            isValid={!isCarrier || org.scac ? true : false}
            description={t("organizations:SCAC is required for Carriers.")}
          />
        ) : null}
        {isDealer ? (
          <React.Fragment>
            <ValidationRow
              isValid={!isDealer || org.shipper ? true : false}
              description={t("organizations:Shipper is required.")}
            />
            <ValidationRow
              isValid={
                !isDealer || (org.locations && org.locations.length > 0)
                  ? true
                  : false
              }
              description={t(
                "organizations:At least 1 Location Designation is required.",
              )}
            />
          </React.Fragment>
        ) : null}
        <Button
          variant="light"
          style={{ marginRight: ".5em" }}
          onClick={() => {
            clearForm();
            hide();
          }}
        >
          {t("organizations:Cancel")}
        </Button>
        <Button
          variant="success"
          disabled={!isValidForm()}
          onClick={() => {
            let grantedLocations = null;
            let grantedFeatures = null;
            if (isDealer) {
              grantedLocations = [
                {
                  location_ids: org.locations.map((l) => l.value),
                  organization_id: org.shipper.value,
                },
              ];
            }

            if (isDealer || isCarrier) {
              grantedFeatures =
                org?.features?.map((ele) => {
                  if (ele?.value === grantedFeaturesOptions[0]?.value) {
                    return "Dealer Drive Away";
                  }
                  return ele?.value;
                }) ?? null;
            }

            const payload = {
              name: org.name,
              email_address: org.email,
              fv_id: org.freightVerifyId,
              contact_name: org.contactName,
              phone_no: org.phoneNumber,
              organization_profile_id: org.selectedType
                ? organizationTypes[org.selectedType.value].profile_id
                : 0,
              logo: org.base64Image,
              scac: org.scac,
              granted_locations: grantedLocations,
              external_codes: org.externalCodes,
              branding: org.branding?.value,
              features: grantedFeatures,
            };
            addOrganization(payload);
          }}
        >
          {t("organizations:Create")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AddOrganizationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  addOrganization: PropTypes.func.isRequired,
  actionStatus: PropTypes.string,
  clearActionStatus: PropTypes.func.isRequired,
  fetchExternalCodeDefinition: PropTypes.func.isRequired,
  organizationName: PropTypes.string,
  organizationTypes: PropTypes.array.isRequired,
  isLoadingOrgLocationsFailed: PropTypes.bool,
  externalCodeDefinition: PropTypes.any,
  externalCodeDefinitionLoading: PropTypes.bool,
  clearExternalCodeDefinition: PropTypes.func.isRequired,
  searchOrganizations: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    actionStatus: state.organizations.actionStatus,
    organizationTypes: getOrganizationTypes(state),
    isLoadingOrgLocationsFailed: getIsLoadingOrgLocationsFailed(state),
    externalCodeDefinition: getExternalCodeDefinitions(state),
    externalCodeDefinitionLoading: getExternalCodeDefinitionsLoading(state),
  };
}

const mapDispatchToProps = {
  addOrganization,
  clearActionStatus,
  fetchExternalCodeDefinition,
  clearExternalCodeDefinition,
  searchOrganizations: () =>
    OrganizationsSearchBarState.actionCreators.searchEntities(
      null,
      false,
      true,
    ),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddOrganizationModal);
