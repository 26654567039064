import { buildAsyncFilterOptionsState } from "components/search-bar/AsyncFilterOptionsStateBuilder";
import apiUrl from "../../../../api-url";
import { Features } from "modules/auth/Authorization";

export const locationOptionsState = buildAsyncFilterOptionsState({
  topic: "hsttLocationFilter",
  url: apiUrl("/containertracking/api/location"),
  getAdditionalParams: () => ({
    filter_name: "location_code",
  }),
  additionalHeaders: {
    "x-target-feature": Features.SURGICAL_TOTE_TRACKING,
  },
  pageSize: 5,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (item) => ({
    label: `${item.location_name} (${item.location_code})`,
    value: item.location_code,
  }),
});

export const uniqueDeviceIdOptionsState = buildAsyncFilterOptionsState({
  topic: "hsttUniqueDeviceIdFilter",
  url: apiUrl("/containertracking/api/contents"),
  additionalHeaders: {
    "x-target-feature": Features.SURGICAL_TOTE_TRACKING,
  },
  pageSize: 20,
  getResponseData: (data) => data?.data ?? [],
  transformResult: (item) => ({
    label: item.value,
    value: item.value,
  }),
});
