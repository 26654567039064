/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { faStar as faStarRegular } from "@fortawesome/pro-regular-svg-icons";
import { faStar as faStarSolid } from "@fortawesome/pro-solid-svg-icons";

import { Tooltip } from "components/atoms/Tooltip.atom";
import { Checkbox } from "components/atoms/Checkbox.atom";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Icon } from "components/atoms/Icon.atom";
import Colors from "styles/colors";

export const WatchToggle = ({
  id,
  checked,
  onChange,
  onClick,
  disabled,
  iconSize = FontSize.size18,
  color = Colors.background.WHITE,
  checkedColor = Colors.highlight.YELLOW,
  labelText,
  labelTextSize,
  labelLeftAligned = false,
  tooltipText,
  tooltipTextSize = FontSize.size14,
  underlineLabel = false,
}) => {
  const { t } = useTranslation("components");

  const handleOnClick = (event) => {
    if (!_.isNil(onClick)) {
      onClick(event);
    }
  };

  let inputTooltipText;
  if (!labelText) {
    inputTooltipText = (
      <Text>{checked ? t("components:Unwatch") : t("components:Watch")}</Text>
    );
  }

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
      }}
      onClick={handleOnClick}
      data-qa="watch-toggle"
    >
      <Tooltip
        placement="right"
        tooltipChildren={inputTooltipText}
        showDelayInMs={500}
      >
        {labelLeftAligned && id && labelText ? (
          <Text
            as="label"
            htmlFor={id}
            size={labelTextSize}
            style={{
              cursor: "pointer",
              marginBottom: 0,
              marginRight: 5,
              textDecoration: underlineLabel ? "underline" : "none",
            }}
          >
            {labelText}
          </Text>
        ) : null}

        <Checkbox
          id={id}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          icon={faStarRegular}
          checkedIcon={faStarSolid}
          iconSize={iconSize}
          color={color}
          checkedColor={checkedColor}
          css={!labelText ? { marginRight: 0 } : null}
          data-qa="checkbox-watch-toggle"
        />
      </Tooltip>

      {!labelLeftAligned && id && labelText ? (
        <Text
          as="label"
          htmlFor={id}
          size={labelTextSize}
          style={{
            cursor: "pointer",
            marginBottom: 0,
            textDecoration: underlineLabel ? "underline" : "none",
          }}
        >
          {labelText}
        </Text>
      ) : null}

      {id && tooltipText && labelText ? (
        <Tooltip
          tooltipChildren={
            <Text
              size={tooltipTextSize}
              style={{ padding: "0.75em", textAlign: "bottom" }}
            >
              {tooltipText}
            </Text>
          }
          showDelayInMs={500}
        >
          <Icon
            data-qa="info-icon-watch-toggle"
            src={faQuestionCircle}
            style={{
              marginLeft: "0.2em",
              alignSelf: "center",
              cursor: "help",
            }}
          />
        </Tooltip>
      ) : null}
    </div>
  );
};

WatchToggle.propTypes = {
  /**
   * The html id for the element.
   *
   * This is required to link up the label with the checkbox.
   */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * The value of the checkbox.
   */
  checked: PropTypes.bool.isRequired,
  /**
   * A handler for the onChange event for the checkbox
   *
   * This function is passed two parameters; the new checked value and the event object.
   *
   * e.g. (checked, event) => void
   */
  onChange: PropTypes.func.isRequired,
  /**
   * A handler for the click event.
   *
   * This function is passed with the event object as parameter.
   *
   * e.g. (event) => void
   */
  onClick: PropTypes.func,
  /**
   * Set the input as disabled.
   */
  disabled: PropTypes.bool,
  /**
   * The size of the checkbox.
   */
  iconSize: PropTypes.any,
  /**
   * The color of the checkbox when not checked.
   */
  color: PropTypes.string,
  /**
   * The color of the checkbox when checked.
   */
  checkedColor: PropTypes.string,
  /**
   * The text to label the checkbox.
   */
  labelText: PropTypes.string,
  /**
   * The font size of the label text.
   *
   * Use the `FontSize` enum for this value.
   */
  labelTextSize: PropTypes.oneOf(Object.values(FontSize)),
  /**
   * The text to show on hover over the question icon.
   */
  tooltipText: PropTypes.string,
  /**
   * The font size of the tooltip text.
   *
   * Use the `FontSize` enum for this value.
   */
  tooltipTextSize: PropTypes.oneOf(Object.values(FontSize)),
  /**
   * Underline the watch label.
   */
  underlineLabel: PropTypes.bool,
};
