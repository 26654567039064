/** @jsxImportSource @emotion/react */
import _ from "lodash";
import Loader from "react-loader";
import PropTypes from "prop-types";
import { useState, useEffect, Fragment, useCallback } from "react";
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { Modal } from "components/molecules/Modal.molecule";
import { AsyncPaginate } from "react-select-async-paginate";
import SimpleBar from "simplebar-react";
import { useTranslation } from "react-i18next";
import apiUrl from "api-url";
import axios from "axios";
import SelectField from "components-old/forms/fields/SelectField";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import { IdBox } from "components/multimodal-components/InfoBoxes";
import { FlexColDiv, FlexRowDiv } from "styles/container-elements";
import { Text } from "components/atoms/Text.atom";
import { FaSearch, FaSpinner, FaCheck } from "react-icons/fa";
import Colors from "styles/colors";

export const ContainerTrackingLocationManagementModal = ({
  id,
  show,
  hide,
  fetchLocation,

  fetchBucket,
  bucketDetails,
  locationDetails,
  failedLocations,
  resetSavedChanges,
  resetFailedLocationsLists,
  updateBucketAndLocationData,
  isValidNewBucketName,
  fetchBucketDetailsFromName,
  updateBucketLocations,
  isLocationDetailsLoading,
  selectedBucketLocations,
  isBucketListLoading,
  isBucketDetailsLoading,
  isValidatingBucketName,
  isFetchingBucketLocations,
  locationChangesSavedStatus,
  isErrorOnSave,
  errorMessageOnRouteName,
  resetError,
  fetchCountries,
  fetchStates,
  stateList,
  countryList,
  resetBucketLoctions,
  resetUpdatedLocations,
  updateLocations,
  updateLocationsOnFail,
  validationError,
  resetValidationError,
  initialBucketName,
  isFormSaving,
}) => {
  const { t } = useTranslation("container-tracking");
  const formGroupStyle = {
    marginBottom: "0rem",
  };
  const [bucketName, setBucketName] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [isDropdownSaved, setIsDropdownSaved] = useState(false);
  const [isBucketDetailsFetched, setIsBucketDetailsFetched] = useState(false);
  const [errorAlertShow, setErrorAlertShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successAlertShow, setSuccessAlertShow] = useState(false);
  const [isAlertType, setIsAlertType] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isBucketListFetched, setIsBucketListFetched] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [isAvailableLocation, setIsAvailableLocation] = useState(false);
  const [isBucketAvailable, setShowBucketAvailable] = useState(false);
  const [assignedLocations, setAssignedLocations] = useState(null);
  const [createNewBucket, setIsCreateNewBucket] = useState(false);
  const [newBucketName, setNewBucketName] = useState("");
  const [isAddingNewData, setIsAddingNewData] = useState(false);
  const [locations, setLocations] = useState(null);
  const [locationId, setLocationId] = useState("");
  const [locationCode, setLocationCode] = useState("");
  const [locationCodeDisabled, setLocationCodeDisabled] = useState(false);
  const [isLookUpDisabled, setIsLookUpDisabled] = useState(true);
  const [isLocationChecked, setIsLocationChecked] = useState(false);
  const [isLocationNotAvailable, setIsLocationNotAvailable] = useState(false);
  const [isNewLocationAddConfirmed, setIsNewLocationAddConfirmed] =
    useState(false);
  const [ladValue, setLadValue] = useState("Assembly");
  const [locationName, setLocationName] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState("");
  const [state, setStateValue] = useState("");
  const [zip, setZip] = useState("");
  const [searchText, setSearchText] = useState("");
  const [showRenamePopup, setShowRenamePopup] = useState(false);
  const [isRenameAck, setIsRenameAck] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const [showRouteNameError, setShowRouteNameError] = useState(false);

  const locationCodeChange = (event) => {
    if (event?.target?.value) {
      setIsLookUpDisabled(false);
    } else {
      setIsLookUpDisabled(true);
    }
    setLocationCode(event?.target?.value ?? null);
    setIsAvailableLocation(false);
  };

  const deleteLocation = (location) => {
    const updatedLocations = locations.filter((data) => data !== location);
    setLocations(updatedLocations);
    //if error on renaming route name/bucket name, use old bucket name
    let useBucketName = errorMessageOnRouteName.message
      ? initialBucketName
      : bucketName;
    updateBucketLocations(
      location,
      useBucketName,
      id,
      "DELETE",
      locationChangesSavedStatus,
    );
    resetSavedChanges();
    resetError();
    resetFailedLocationsLists();
  };

  const addLocation = useCallback(
    (location, isNew = false) => {
      location["isNew"] = isNew;
      const updatedLocations = locations
        ? [...locations, location]
        : [location];
      setLocations(updatedLocations);
      //if error on renaming route name/bucket name, use old bucket name
      let useBucketName = errorMessageOnRouteName.message
        ? initialBucketName
        : bucketName;
      updateBucketLocations(location, useBucketName, id, "ADD");
      resetSavedChanges();
      resetError();
      resetFailedLocationsLists();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateBucketLocations, locations, id, bucketName],
  );

  const getBucketLocations = (bucketName) => {
    fetchBucketDetailsFromName(bucketName);
  };

  const addLocationCancelHandler = () => {
    setIsNewLocationAddConfirmed(false);
    setIsAddingNewData(false);
    setIsAvailableLocation(false);
    setIsLocationChecked(false);
    setLocationCode("");
    setLocationCodeDisabled(false);
    setIsLookUpDisabled(true);
    setIsLocationNotAvailable(false);
    setLocationName("");
    setCountry({
      label: "United States",
      value: "US",
    });
    setStateValue(null);
    setAddress("");
    setAddress2("");
    setCity("");
    setStateValue("");
    setZip("");
  };

  const addNewLocation = () => {
    const locationData = {
      details: {
        location_id: locationId,
        location_code: locationCode,
        location_name: locationName,
        address,
        address2,
        lad: {
          lad_name: ladValue,
        },
        city,
        state: state.value,
        zip,
        country: country.value,
      },
    };
    addLocation(locationData, true);
    addLocationCancelHandler();
  };

  const getDataForDropdown = () => {
    return bucketName
      ? {
          label: bucketName,
          value: bucketName,
        }
      : null;
  };
  let isBucketNameUpdated = !_.isEqual(
    initialBucketName?.trim(),
    bucketName?.trim(),
  );

  const saveClickHandler = () => {
    if (isAddingNewData) {
      addLocationCancelHandler();
    }
    if (showDropdown) {
      setIsDropdownSaved(true);
    }
    setAssignedLocations(locations);

    setErrorAlertShow(false);
    setShowRouteNameError(false);
    setSuccessAlertShow(false);
    updateBucketAndLocationData(
      initialBucketName,
      bucketName,
      isBucketNameUpdated,
    );
  };

  const updatedFetchBucket = useCallback(() => {
    fetchBucket(id);
  }, [id, fetchBucket]);

  let isErrorMessageOnRouteName = !_.isEmpty(errorMessageOnRouteName);
  useEffect(() => {
    if (failedLocations !== null && failedLocations.length === 0) {
      resetUpdatedLocations();
    } else {
      updateLocationsOnFail(failedLocations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [failedLocations]);
  useEffect(() => {
    if (isErrorOnSave) {
      setErrorAlertShow(true);
      if (isErrorMessageOnRouteName) {
        setShowRouteNameError(true);
        setErrorAlertShow(false);
      }
    } else if (failedLocations && failedLocations.length > 0) {
      setErrorAlertShow(true);
      setErrorMessage(
        `${t(
          "container-tracking:Failed to process the following location codes -.",
        )} ${failedLocations?.join(",")}`,
      );
    } else {
      setErrorAlertShow(false);
      setErrorMessage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorOnSave, failedLocations]);
  useEffect(() => {
    setIsAlertType(false);
    if (
      ((updateLocations ? updateLocations.length > 0 : false) ||
        isBucketNameUpdated) &&
      !isFormSaving &&
      !isAvailableLocation &&
      !_.isEmpty(bucketName) &&
      (locations ? locations.length > 0 : false) &&
      !(failedLocations && failedLocations.length > 0)
    ) {
      setIsAlertType(true);
      setSuccessAlertShow(true);
      setSuccessMessage(
        t("container-tracking:Be sure to hit Save below to save your changes!"),
      );
    } else if (
      locationChangesSavedStatus &&
      (!failedLocations || failedLocations.length === 0) &&
      !isErrorOnSave
    ) {
      setSuccessAlertShow(true);
      setSuccessMessage(
        t("container-tracking:Your changes have been saved successfully."),
      );
    } else {
      setSuccessAlertShow(false);
      setSuccessMessage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    updateLocations,
    isFormSaving,
    isErrorOnSave,
    failedLocations,
    locationChangesSavedStatus,
    bucketName,
    isAvailableLocation,
    locations,
  ]);

  useEffect(() => {
    if (isBucketListLoading) {
      setIsBucketListFetched(true);
    }
  }, [isBucketListLoading]);

  useEffect(() => {
    if (show) {
      updatedFetchBucket();
      setIsBucketDetailsFetched(true);
      resetError();
      setShowBucketAvailable(false);
      setIsAvailableLocation(false);
    } else {
      setBucketName("");
      setIsDropdownSaved(false);
      resetUpdatedLocations();
      resetBucketLoctions();
      resetFailedLocationsLists();
      resetSavedChanges();
      setAssignedLocations(null);
      setIsCreateNewBucket(false);
      setNewBucketName("");
      setIsAddingNewData(false);
      setLocations(null);
      addLocationCancelHandler();
      setIsBucketListFetched(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (
      isBucketDetailsFetched &&
      !isBucketDetailsLoading &&
      !isBucketListFetched &&
      Object.keys(bucketDetails).length === 0
    ) {
      setBucketName("");
      setIsBucketListFetched(true);
    } else if (Object.keys(bucketDetails).length > 0) {
      setBucketName(bucketDetails.name);
      setAssignedLocations(bucketDetails.locations);
    }
  }, [
    bucketDetails,
    isBucketListFetched,
    isBucketDetailsLoading,
    isBucketDetailsFetched,
  ]);

  useEffect(() => {
    setLocations(assignedLocations);
  }, [assignedLocations]);

  useEffect(() => {
    if (isValidNewBucketName) {
      setIsCreateNewBucket(false);
      setBucketName(newBucketName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidNewBucketName]);

  useEffect(() => {
    if (isLocationChecked) {
      if (_.isEmpty(locationDetails) && locationCode) {
        setIsLocationNotAvailable(true);
        setLocationCodeDisabled(true);
      } else {
        const locationData =
          locationDetails && locationDetails[0]
            ? {
                details: {
                  location_id: locationDetails[0]?.id,
                  location_code: locationDetails[0]?.code,
                  location_name: locationDetails[0]?.name,
                  address: locationDetails[0]?.address,
                  lad: {
                    lad_name: locationDetails[0]?.lad.name,
                  },
                  city: locationDetails[0]?.city,
                  country: locationDetails[0]?.country,
                  state: locationDetails[0]?.state,
                  zip: locationDetails[0]?.postal_code,
                },
              }
            : null;
        if (locationData) {
          addLocation(locationData, true);
          setIsAddingNewData(false);
          setLocationCode("");
          setIsLookUpDisabled(true);
          setLocationCodeDisabled(false);
          setIsAddingNewData(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationDetails]);

  useEffect(() => {
    fetchCountries();
    fetchStates("US");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (country && country.value) {
      fetchStates(country.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useEffect(() => {
    if (country === null) {
      setCountry({
        label: "United States",
        value: "US",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryList]);

  useEffect(() => {
    if (Object.keys(bucketDetails).length > 0 || isDropdownSaved) {
      setShowDropdown(false);
    } else {
      setShowDropdown(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bucketDetails, isDropdownSaved]);

  useEffect(() => {
    setAssignedLocations(selectedBucketLocations);
    selectedBucketLocations.forEach((location) => {
      const locationData = locations?.filter((data) => {
        return data.details.location_code === location.details.location_code;
      });
      if (locationData && locationData.length === 0) {
        addLocation(location);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBucketLocations]);

  useEffect(() => {
    if (isValidatingBucketName) {
      setIsValidating(true);
      setShowBucketAvailable(false);
    } else if (isValidating) {
      setIsValidating(false);
      if (!isValidNewBucketName) {
        setShowBucketAvailable(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidatingBucketName, isValidNewBucketName]);

  useEffect(() => {
    const isFormUpdated =
      initialBucketName !== bucketName || !_.isEmpty(updateLocations);
    if (_.isEmpty(locations) || _.isEmpty(bucketName)) {
      setDisableSaveButton(true);
    } else if (_.isEmpty(bucketDetails) && !isFormUpdated) {
      setDisableSaveButton(true);
    } else if (showRenamePopup) {
      setDisableSaveButton(true);
    } else if (isFormUpdated) {
      setDisableSaveButton(false);
    } else {
      setDisableSaveButton(true);
    }
  }, [
    bucketDetails,
    initialBucketName,
    bucketName,
    locations,
    updateLocations,
    showRenamePopup,
  ]);

  const loadBuckets = async (currentVal, pageNumber) => {
    let data = [];
    let metaData = null;
    let url;
    if (searchText !== "") {
      url = apiUrl(
        `/containertracking/api/bucket?everything=${searchText}&pageSize=20&pageNumber=${pageNumber}`,
      );
    } else {
      url = apiUrl(
        `/containertracking/api/bucket?pageSize=20&pageNumber=${pageNumber}`,
      );
    }

    await axios
      .get(url)
      .then((res) => {
        data = res?.data?.data?.map((val) => {
          return {
            label: `${val?.name}`,
            value: val.name,
            data: val,
          };
        });
        metaData = res?.data?.meta;
      })
      .catch((err) => {
        console.log(err);
      });

    const dataValue = {
      options: data ?? [],
      hasMore: metaData ? metaData.currentPage < metaData.totalPages : false,
      additional: {
        pageNumber,
        prevValue: currentVal,
      },
    };
    return dataValue;
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: Colors.tabs.BACKGROUND_SELECTED,
      borderRadius: 5,
      borderWidth: 2,
      borderColor: Colors.border.CYAN_BLUE,
      cursor: "pointer",
      height: "3em",
      fontSize: "1em",
    }),
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),

    option: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 999,
      overflow: "hidden",
      borderRadius: "0 0 5px 5px",
      marginTop: 0,
    }),
  };

  const hideHandler = () => {
    hide();
    setIsRenameAck(false);
    setDisableSaveButton(true);
    setShowRouteNameError(false);
    setShowRenamePopup(false);
    resetValidationError();
  };

  const disableAddLocation =
    isFormSaving ||
    _.isEmpty(bucketName) ||
    showRenamePopup ||
    isFetchingBucketLocations;

  const showRouteNameField =
    initialBucketName ||
    (bucketName && (isFormSaving || locationChangesSavedStatus));

  return (
    <Modal
      backdrop={"static"}
      show={show}
      onHide={() => hideHandler()}
      dialogClassName={"login-modal"}
    >
      <Modal.Header
        closeButton={!isFormSaving}
        css={{
          backgroundColor: Colors.background.LIGHT_GRAY,
          color: Colors.background.DARK_BLUE,
          padding: "1rem",
        }}
        title={
          <div data-qa="title-modal" style={{ fontWeight: "500" }}>
            {t("container-tracking:Manage Route")}
          </div>
        }
      />

      <Modal.Body
        style={{
          backgroundColor: Colors.background.LIGHT_GRAY,
        }}
      >
        {isBucketListLoading || isBucketDetailsLoading ? (
          <Loader />
        ) : (
          <Fragment>
            <FlexColDiv>
              <FormLabel
                css={{
                  color: Colors.text.GRAY,
                  fontSize: "1em",
                }}
              >
                {createNewBucket
                  ? `${t("container-tracking:New Route Name")}:`
                  : `${t("container-tracking:Route Name")}`}
              </FormLabel>
              <div style={{ fontWeight: "500", marginBottom: "1em" }}>
                {showRouteNameField ? (
                  <Fragment>
                    <FlexRowDiv
                      css={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {initialBucketName ? (
                        !showRenamePopup ? (
                          <FormGroup
                            css={{ marginBottom: "1rem", width: "60%" }}
                          >
                            <FormControl
                              isInvalid={!bucketName}
                              value={bucketName}
                              disabled={isFormSaving}
                              onChange={(event) => {
                                if (isRenameAck) {
                                  setBucketName(event.target.value);
                                } else {
                                  setShowRenamePopup(true);
                                  setBucketName(event.target.value);
                                }
                              }}
                            />
                            <FormControl.Feedback type="invalid">
                              <div>
                                {t(
                                  "container-tracking:Route name cannot have an empty value",
                                )}
                              </div>
                            </FormControl.Feedback>
                          </FormGroup>
                        ) : (
                          <Alert
                            css={{ width: "100%" }}
                            variant={AlertVariant.Warning}
                            show={showRenamePopup}
                          >
                            {t(
                              "container-tracking:You are attempting to rename an existing route. Do you want to proceed?",
                            )}
                            <div
                              css={{
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: " flex-end",
                                padding: "0.75rem",
                                marginTop: "0.5rem",
                                gap: "1em",
                                borderTop: "1px solid #dee2e6",
                              }}
                            >
                              <Button
                                style={{
                                  background: "white",
                                  borderColor: "#ccc",
                                  color: Colors.background.DARK_BLUE,
                                }}
                                onClick={() => {
                                  setShowRenamePopup(false);
                                  setBucketName(initialBucketName);
                                }}
                              >
                                {t("container-tracking:No")}
                              </Button>
                              <Button
                                css={{
                                  display: "inline-flex",
                                  flexFlow: " row nowrap",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  gap: "0.5em",
                                }}
                                variant="success"
                                onClick={() => {
                                  setIsRenameAck(true);
                                  setShowRenamePopup(false);
                                }}
                              >
                                {t("container-tracking:Yes")}
                              </Button>
                            </div>
                          </Alert>
                        )
                      ) : (
                        bucketName
                      )}
                    </FlexRowDiv>
                  </Fragment>
                ) : createNewBucket ? (
                  <Fragment>
                    <FlexRowDiv
                      css={{
                        paddingBottom: ".5em",
                        alignItems: "center",
                        gap: "1em",
                      }}
                    >
                      <FormGroup css={formGroupStyle}>
                        <FormControl
                          value={newBucketName}
                          onChange={(event) => {
                            setNewBucketName(event?.target?.value ?? "");
                            setShowBucketAvailable(false);
                          }}
                        />
                      </FormGroup>
                      <Button
                        css={{
                          display: "inline-flex",
                          flexFlow: " row nowrap",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: "0.5em",
                          visibility: locationCodeDisabled
                            ? "hidden"
                            : "visible",
                        }}
                        variant="success"
                        disabled={newBucketName.length > 0 ? false : true}
                        onClick={() => {
                          fetchBucketDetailsFromName(newBucketName, true);
                        }}
                      >
                        {isValidatingBucketName ? (
                          <FaSpinner className={"fa-spin"} />
                        ) : null}
                        {t("components:Finish")}
                      </Button>
                      <Button
                        style={{
                          background: "white",
                          borderColor: "#ccc",
                          color: Colors.background.DARK_BLUE,
                        }}
                        onClick={() => {
                          setIsCreateNewBucket(false);
                          setShowBucketAvailable(false);
                          setNewBucketName("");
                          setIsRenameAck(false);
                          resetValidationError();
                        }}
                      >
                        {t("components:Cancel")}
                      </Button>
                    </FlexRowDiv>
                  </Fragment>
                ) : showDropdown ? (
                  <Fragment>
                    <FlexRowDiv
                      css={{
                        paddingBottom: ".5em",
                        alignItems: "center",
                        gap: "1em",
                      }}
                    >
                      <div
                        style={{
                          width: "60%",
                        }}
                      >
                        <AsyncPaginate
                          placeholder={t(
                            "container-tracking:Select Existing Route",
                          )}
                          debounceTimeout={500}
                          isMulti={false}
                          cacheOptions={false}
                          onInputChange={(val) => {
                            setSearchText(val);
                          }}
                          value={getDataForDropdown()}
                          isClearable={!_.isEmpty(bucketName)}
                          loadOptions={async (
                            currentVal,
                            options,
                            additionalOptions,
                          ) => {
                            const data = await loadBuckets(
                              currentVal,
                              additionalOptions &&
                                currentVal === additionalOptions?.prevValue
                                ? additionalOptions.pageNumber + 1
                                : 0,
                            );
                            return data;
                          }}
                          onChange={(selectedData) => {
                            setBucketName(selectedData?.value ?? null);
                            resetUpdatedLocations();
                            if (selectedData?.value) {
                              getBucketLocations(selectedData.value);
                            } else {
                              setAssignedLocations(null);
                            }
                          }}
                          styles={customStyles}
                        />
                      </div>

                      <Button
                        variant="primary"
                        disabled={!_.isEmpty(bucketName)}
                        onClick={() => {
                          resetUpdatedLocations();
                          setIsCreateNewBucket(true);
                          setAssignedLocations(null);
                          setBucketName("");
                          addLocationCancelHandler();
                        }}
                      >
                        {t("components:Create Route")}
                      </Button>
                    </FlexRowDiv>
                  </Fragment>
                ) : null}
              </div>
            </FlexColDiv>
            {initialBucketName || bucketName ? (
              <SimpleBar
                autoHide={false}
                css={{
                  width: "100%",
                  height: "100%",
                  maxHeight: "280px",
                }}
              >
                <div
                  css={{
                    width: "100%",
                    paddingRight: "5%",
                  }}
                >
                  <FlexColDiv>
                    <FormLabel
                      css={{
                        color: Colors.text.GRAY,
                        fontSize: "1em",
                      }}
                    >{`${t("container-tracking:Route Locations")}`}</FormLabel>
                    {isFetchingBucketLocations ? (
                      <Loader />
                    ) : (
                      <Fragment>
                        <Alert
                          css={{ width: "100%" }}
                          variant={AlertVariant.Warning}
                          show={!locations || locations.length === 0}
                        >
                          {t(
                            "container-tracking:At least one assigned location is required to save a new route.",
                          )}
                        </Alert>
                        <ul
                          css={{
                            // Remove default styles.
                            margin: 0,
                            padding: "0 0 0 1em",
                            listStyle: "decimal",
                            // Option layout.
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.125em",
                          }}
                        >
                          {locations?.map((location) => {
                            const {
                              location_name,
                              location_code,
                              address,
                              address2,
                              state,
                              city,
                              country,
                              zip,
                            } = location.details;

                            return (
                              <li key={location_code}>
                                <FlexRowDiv
                                  css={{
                                    paddingBottom: ".5em",
                                    alignItems: "center",
                                    justifyContent: "space-between;",
                                  }}
                                >
                                  <div css={{ width: "80%" }}>
                                    {(location_name || location_code) && (
                                      <IdBox
                                        css={{ fontSize: "1em !important" }}
                                        data-qa="text-name-location"
                                      >
                                        {`${location_name} (${location_code})${
                                          location.isNew ? " " : ""
                                        }`}
                                        {location.isNew ? (
                                          <span
                                            css={{
                                              color: Colors.text.GREEN,
                                              fontSize: "0.7rem",
                                            }}
                                          >
                                            {t("container-tracking:New")}
                                          </span>
                                        ) : null}
                                      </IdBox>
                                    )}
                                    {address && (
                                      <p
                                        css={{
                                          color: Colors.text.GRAY,
                                          wordWrap: "break-word",
                                        }}
                                        data-qa="text-address-locationaaaa"
                                      >
                                        {address}
                                      </p>
                                    )}
                                    {address2 && (
                                      <p
                                        css={{
                                          color: Colors.text.GRAY,
                                        }}
                                        data-qa="text-address-locationsss"
                                      >
                                        {address2}
                                      </p>
                                    )}
                                    <div>
                                      {city && (
                                        <span
                                          style={{ color: Colors.text.GRAY }}
                                          data-qa="text-address-location"
                                        >
                                          {`${city}${
                                            state || zip || country ? ", " : ""
                                          }`}
                                        </span>
                                      )}
                                      {state && (
                                        <span
                                          style={{ color: Colors.text.GRAY }}
                                          data-qa="text-address-location"
                                        >
                                          {`${state}${
                                            country || zip ? ", " : ""
                                          }`}
                                        </span>
                                      )}
                                      {zip && (
                                        <span
                                          style={{ color: Colors.text.GRAY }}
                                          data-qa="text-address-location"
                                        >
                                          {`${zip}${country ? ", " : ""}`}
                                        </span>
                                      )}
                                      {country && (
                                        <span
                                          style={{ color: Colors.text.GRAY }}
                                          data-qa="text-address-location"
                                        >
                                          {`${country}`}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <Button
                                    variant="danger"
                                    onClick={() => deleteLocation(location)}
                                    disabled={isFormSaving}
                                  >
                                    {t("container-tracking:Delete")}
                                  </Button>
                                </FlexRowDiv>
                              </li>
                            );
                          })}
                          {isAddingNewData ? (
                            <li>
                              <Fragment>
                                <FlexRowDiv
                                  css={{
                                    paddingBottom: ".5em",
                                    alignItems: "center",
                                    gap: "1em",
                                  }}
                                >
                                  <FormGroup css={formGroupStyle}>
                                    <Text>
                                      {t("container-tracking:Location Code")}:
                                    </Text>
                                    <FormControl
                                      value={locationCode}
                                      onChange={locationCodeChange}
                                      disabled={isLocationNotAvailable}
                                    />
                                  </FormGroup>
                                  <Button
                                    css={{
                                      display: "inline-flex",
                                      flexFlow: " row nowrap",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      gap: "0.5em",
                                      visibility: locationCodeDisabled
                                        ? "hidden"
                                        : "visible",
                                      marginTop: "1.25rem",
                                    }}
                                    variant="success"
                                    disabled={
                                      isLookUpDisabled ||
                                      isLocationDetailsLoading
                                    }
                                    onClick={() => {
                                      const availableLocation = locations
                                        ? locations.filter((location) => {
                                            return (
                                              location.details.location_code ===
                                              locationCode
                                            );
                                          })
                                        : [];
                                      if (availableLocation.length === 0) {
                                        fetchLocation(locationCode);
                                        setIsLocationChecked(true);
                                      } else {
                                        setIsAvailableLocation(true);
                                      }
                                    }}
                                  >
                                    {isLocationDetailsLoading ? (
                                      <FaSpinner className={"fa-spin"} />
                                    ) : (
                                      <FaSearch />
                                    )}
                                    {t("container-tracking:Lookup")}
                                  </Button>
                                  <Button
                                    style={{
                                      background: "white",
                                      borderColor: "#ccc",
                                      color: Colors.background.DARK_BLUE,
                                      marginTop: "1.25rem",
                                    }}
                                    onClick={() => {
                                      addLocationCancelHandler();
                                    }}
                                  >
                                    {t("container-tracking:Cancel")}
                                  </Button>
                                </FlexRowDiv>
                                {isLocationNotAvailable ? (
                                  !isNewLocationAddConfirmed ? (
                                    <Fragment>
                                      <Alert
                                        css={{
                                          width: "100%",
                                          marginBottom: "0em",
                                          marginTop: "1em",
                                        }}
                                        variant={AlertVariant.Warning}
                                        show={!isNewLocationAddConfirmed}
                                      >
                                        {t(
                                          "container-tracking:Would you like us to create a location with the location code provided?",
                                        )}
                                        <div
                                          css={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            alignItems: "center",
                                            justifyContent: " flex-end",
                                            padding: "0.75rem",
                                            marginTop: "0.5rem",
                                            gap: "1em",
                                            borderTop: "1px solid #dee2e6",
                                          }}
                                        >
                                          <Button
                                            style={{
                                              background: "white",
                                              borderColor: "#ccc",
                                              color:
                                                Colors.background.DARK_BLUE,
                                            }}
                                            onClick={addLocationCancelHandler}
                                          >
                                            {t("container-tracking:No")}
                                          </Button>
                                          <Button
                                            css={{
                                              display: "inline-flex",
                                              flexFlow: " row nowrap",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                              gap: "0.5em",
                                            }}
                                            variant="success"
                                            onClick={() => {
                                              setIsNewLocationAddConfirmed(
                                                true,
                                              );
                                            }}
                                          >
                                            {t("container-tracking:Yes")}
                                          </Button>
                                        </div>
                                      </Alert>
                                    </Fragment>
                                  ) : (
                                    <Fragment>
                                      <FlexRowDiv
                                        css={{
                                          paddingBottom: ".5em",
                                          alignItems: "center",
                                          gap: "1em",
                                        }}
                                      >
                                        <FormGroup
                                          css={{
                                            width: "75%",
                                            ...formGroupStyle,
                                          }}
                                        >
                                          <Text>
                                            {t("container-tracking:LAD")}:
                                          </Text>
                                          <FormControl
                                            as="select"
                                            value={ladValue}
                                            onChange={(e) =>
                                              setLadValue(e.target.value)
                                            }
                                          >
                                            <option value="Assembly">
                                              Assembly
                                            </option>
                                            <option value="Distribution">
                                              Distribution Center
                                            </option>
                                            <option value="Supplier">
                                              Supplier
                                            </option>
                                          </FormControl>
                                        </FormGroup>
                                      </FlexRowDiv>
                                      <FlexRowDiv
                                        css={{
                                          paddingBottom: ".5em",
                                          alignItems: "center",
                                          gap: "1em",
                                        }}
                                      >
                                        <FormGroup
                                          css={{
                                            width: "75%",
                                            ...formGroupStyle,
                                          }}
                                        >
                                          <Text>
                                            {t(
                                              "container-tracking:Location name",
                                            )}
                                            :
                                          </Text>
                                          <FormControl
                                            value={locationName}
                                            onChange={(event) =>
                                              setLocationName(
                                                event?.target?.value ?? "",
                                              )
                                            }
                                          />
                                        </FormGroup>
                                      </FlexRowDiv>
                                      <FlexRowDiv
                                        css={{
                                          paddingBottom: ".5em",
                                          alignItems: "center",
                                          gap: "1em",
                                        }}
                                      >
                                        <FormGroup
                                          css={{
                                            width: "75%",
                                            ...formGroupStyle,
                                          }}
                                        >
                                          <Text>
                                            {t("container-tracking:Address")}:
                                          </Text>
                                          <FormControl
                                            value={address}
                                            onChange={(event) =>
                                              setAddress(
                                                event?.target?.value ?? "",
                                              )
                                            }
                                          />
                                        </FormGroup>
                                      </FlexRowDiv>
                                      <FlexRowDiv
                                        css={{
                                          paddingBottom: ".5em",
                                          alignItems: "center",
                                          gap: "1em",
                                        }}
                                      >
                                        <FormGroup
                                          css={{
                                            width: "75%",
                                            ...formGroupStyle,
                                          }}
                                        >
                                          <Text>
                                            {t("container-tracking:Address2")}:
                                          </Text>
                                          <FormControl
                                            value={address2}
                                            onChange={(event) =>
                                              setAddress2(
                                                event?.target?.value ?? "",
                                              )
                                            }
                                          />
                                        </FormGroup>
                                      </FlexRowDiv>
                                      <FlexRowDiv
                                        css={{
                                          paddingBottom: ".5em",
                                          alignItems: "center",
                                          gap: "1em",
                                        }}
                                      >
                                        <FormGroup
                                          css={{
                                            width: "75%",
                                            ...formGroupStyle,
                                          }}
                                        >
                                          <Text>
                                            {t("container-tracking:City")}:
                                          </Text>
                                          <FormControl
                                            value={city}
                                            onChange={(event) =>
                                              setCity(
                                                event?.target?.value ?? "",
                                              )
                                            }
                                          />
                                        </FormGroup>
                                      </FlexRowDiv>
                                      <FlexRowDiv
                                        css={{
                                          paddingBottom: ".5em",
                                          alignItems: "center",
                                          gap: "1em",
                                        }}
                                      >
                                        <FormGroup
                                          css={{
                                            ...formGroupStyle,
                                          }}
                                        >
                                          <Text>
                                            {t("container-tracking:State")}:
                                          </Text>
                                          <SelectField
                                            options={stateList?.map((state) => {
                                              return {
                                                label: state.name,
                                                value: state.code,
                                              };
                                            })}
                                            value={state}
                                            placeholder={t(
                                              "container-tracking:Select a State",
                                            )}
                                            onChange={(event) => {
                                              setStateValue({
                                                label: event.label,
                                                value: event.value,
                                              });
                                            }}
                                            cursor="pointer"
                                          />
                                        </FormGroup>
                                      </FlexRowDiv>
                                      <FlexRowDiv>
                                        <FormGroup
                                          css={{
                                            width: "75%",
                                            ...formGroupStyle,
                                          }}
                                        >
                                          <Text>
                                            {t("container-tracking:Zip")}:
                                          </Text>
                                          <FormControl
                                            value={zip}
                                            onChange={(event) =>
                                              setZip(event?.target?.value ?? "")
                                            }
                                          />
                                        </FormGroup>
                                      </FlexRowDiv>
                                      <FlexRowDiv
                                        css={{
                                          paddingBottom: ".5em",
                                          alignItems: "center",
                                          gap: "1em",
                                        }}
                                      >
                                        <FormGroup
                                          css={{
                                            width: "75%",
                                            ...formGroupStyle,
                                          }}
                                        >
                                          <Text>
                                            {t("container-tracking:Country")}:
                                          </Text>
                                          <SelectField
                                            options={countryList?.map(
                                              (country) => {
                                                return {
                                                  label: country.name,
                                                  value: country.code,
                                                };
                                              },
                                            )}
                                            value={country}
                                            placeholder={t(
                                              "container-tracking:Select a Country",
                                            )}
                                            onChange={(event) => {
                                              setCountry({
                                                label: event.label,
                                                value: event.value,
                                              });
                                              setStateValue(null);
                                            }}
                                            cursor="pointer"
                                          />
                                        </FormGroup>
                                        <div
                                          css={{
                                            paddingTop: "1.25rem",
                                          }}
                                        >
                                          <Button
                                            variant="success"
                                            css={{
                                              display: "inline-flex",
                                              flexFlow: " row nowrap",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                              gap: "0.5em",
                                            }}
                                            disabled={
                                              !locationCode ||
                                              !address ||
                                              !locationName ||
                                              !city ||
                                              !country ||
                                              !zip ||
                                              !ladValue ||
                                              !state
                                            }
                                            onClick={() => addNewLocation()}
                                          >
                                            <FaCheck />
                                            {t("container-tracking:Finish")}
                                          </Button>
                                        </div>
                                      </FlexRowDiv>
                                    </Fragment>
                                  )
                                ) : null}
                              </Fragment>
                            </li>
                          ) : null}
                        </ul>
                      </Fragment>
                    )}
                  </FlexColDiv>
                  <FlexColDiv>
                    {!isAddingNewData ? (
                      <div
                        css={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          variant="primary"
                          style={{
                            width: "auto",
                          }}
                          disabled={disableAddLocation}
                          onClick={() => {
                            setIsAddingNewData(true);
                            setIsAvailableLocation(false);
                          }}
                        >
                          {t("container-tracking:Add Location")}
                        </Button>
                      </div>
                    ) : null}
                  </FlexColDiv>
                </div>
              </SimpleBar>
            ) : null}
          </Fragment>
        )}
        <Alert
          css={{ width: "100%", marginBottom: "0em", marginTop: "1em" }}
          variant={isAlertType ? AlertVariant.Warning : AlertVariant.Success}
          show={successAlertShow}
          dismissible={true}
          onDismiss={() => {
            setSuccessAlertShow(false);
          }}
        >
          {successMessage}
        </Alert>

        <Alert
          css={{ width: "100%", marginBottom: "0em", marginTop: "1em" }}
          variant={AlertVariant.Info}
          show={isAvailableLocation}
        >
          {t("container-tracking:Location is already added.")}
        </Alert>

        <Alert
          css={{ width: "100%", marginBottom: "0em", marginTop: "1em" }}
          variant={AlertVariant.Danger}
          dismissible={true}
          onDismiss={() => {
            resetValidationError();
            if (isBucketAvailable) {
              setShowBucketAvailable(false);
            }
          }}
          show={
            (!isValidating && isBucketAvailable && createNewBucket) ||
            !_.isEmpty(validationError)
          }
        >
          {" "}
          {!_.isEmpty(validationError)
            ? t(`container-tracking:${validationError}`)
            : t("container-tracking:Route is already available.")}
        </Alert>
        <Alert
          css={{ width: "100%", marginBottom: "0em", marginTop: "1em" }}
          variant={AlertVariant.Danger}
          dismissible={true}
          onDismiss={() => {
            setErrorAlertShow(false);
          }}
          show={errorAlertShow}
        >
          {errorMessage
            ? errorMessage
            : t(
                "container-tracking:Some error has occurred please try after some time.",
              )}
        </Alert>
        <Alert
          css={{ width: "100%", marginBottom: "0em", marginTop: "1em" }}
          variant={AlertVariant.Danger}
          dismissible={true}
          onDismiss={() => {
            setShowRouteNameError(false);
            setErrorAlertShow(false);
          }}
          show={showRouteNameError}
        >
          {errorMessageOnRouteName.message
            ? errorMessageOnRouteName.message
            : t(
                "container-tracking:Some error has occurred please try after some time.",
              )}
        </Alert>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
        <Button
          style={{
            background: "white",
            borderColor: "#ccc",
            color: Colors.background.DARK_BLUE,
          }}
          disabled={isFormSaving}
          onClick={() => hideHandler()}
        >
          {locationChangesSavedStatus &&
          !isErrorOnSave &&
          (!failedLocations || failedLocations.length === 0)
            ? t("container-tracking:Close")
            : t("container-tracking:Cancel")}
        </Button>
        <Button
          css={{
            display: "inline-flex",
            flexFlow: " row nowrap",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "0.5em",
          }}
          variant="success"
          disabled={disableSaveButton || isFormSaving}
          onClick={saveClickHandler}
        >
          {isFormSaving ? <FaSpinner className={"fa-spin"} /> : null}
          {t("container-tracking:Save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ContainerTrackingLocationManagementModal.propTypes = {
  id: PropTypes.string,
  show: PropTypes.bool,
  hide: PropTypes.func,
  fetchLocation: PropTypes.func,
  fetchBucket: PropTypes.func,
  bucketDetails: PropTypes.object,
  locationDetails: PropTypes.array,
  updateBucketAndLocationData: PropTypes.func,
  isValidNewBucketName: PropTypes.bool,
  fetchBucketDetailsFromName: PropTypes.func,
  updateBucketLocations: PropTypes.func,
  isLocationDetailsLoading: PropTypes.bool,
  selectedBucketLocations: PropTypes.array,
  isBucketListLoading: PropTypes.bool,
  isBucketDetailsLoading: PropTypes.bool,
  isValidatingBucketName: PropTypes.bool,
  isFetchingBucketLocations: PropTypes.bool,
  locationChangesSavedStatus: PropTypes.bool,
  failedLocations: PropTypes.array,
  resetFailedLocationsLists: PropTypes.func,
  resetSavedChanges: PropTypes.func,
  isErrorOnSave: PropTypes.bool,
  errorMessageOnRouteName: PropTypes.object,
  resetError: PropTypes.func,
  fetchCountries: PropTypes.func,
  fetchStates: PropTypes.func,
  stateList: PropTypes.array,
  countryList: PropTypes.array,
  resetBucketLoctions: PropTypes.func,
  resetUpdatedLocations: PropTypes.func,
  updateLocations: PropTypes.array,
  updateLocationsOnFail: PropTypes.func,
  validationError: PropTypes.object,
  resetValidationError: PropTypes.func,
  isFormSaving: PropTypes.bool,
  initialBucketName: PropTypes.string,
};
