import { connect } from "react-redux";
import { SurgicalToteTrackingDetails } from "./SurgicalToteTracking.Details.page";
import { SurgicalToteTrackingDetailsWidgetState } from "../redux/SurgicalToteTrackingDetailsWidgetState";
import MapState from "modules/map/MapState";
import { MapCoordinateType } from "modules/map/components/map-coordinate-definition";
import {
  getActiveOrganization,
  getSolutionIdByFeatureName,
} from "modules/organizations/OrganizationsState";

import NotificationsState from "modules/notifications/NotificationsState";
const { getMapCoordinate } = MapState.selectors;

const { getIsFetchingComments, getComments } =
  SurgicalToteTrackingDetailsWidgetState.selectors;

function mapStateToProps(state) {
  const id = state.location.payload.id;

  return {
    id,
    organization: getActiveOrganization(state),
    solutionId: getSolutionIdByFeatureName(state, "Container Tracking"),
    toteDetails:
      SurgicalToteTrackingDetailsWidgetState.selectors.getToteDetails(state),
    sttActiveExceptions:
      SurgicalToteTrackingDetailsWidgetState.selectors.getSTTActiveExceptions(
        state,
      ),
    isToteDetailsLoading:
      SurgicalToteTrackingDetailsWidgetState.selectors.getToteDetailsLoadingFlag(
        state,
      ),
    toteMedia:
      SurgicalToteTrackingDetailsWidgetState.selectors.getToteMedia(state),
    totePositionUpdates:
      SurgicalToteTrackingDetailsWidgetState.selectors.getTotePositionUpdates(
        state,
      ),
    toteLocationDetails:
      SurgicalToteTrackingDetailsWidgetState.selectors.getLocationDetails(
        state,
      ),
    isToteLocationDetailsLoading:
      SurgicalToteTrackingDetailsWidgetState.selectors.getLocationDetailsLoadingFlag(
        state,
      ),
    selectedMapCoordinate: getMapCoordinate(
      MapCoordinateType.SELECTED_COORDINATE,
    )(state),
    toteHistory:
      SurgicalToteTrackingDetailsWidgetState.selectors.getToteHistory(state),
    isFetchingComments: getIsFetchingComments(state),
    comments: getComments(state),
  };
}

const {
  clearToteMedia,
  fetchToteDetails,
  fetchToteActiveExceptions,
  fetchToteMedia,
  fetchToteSupplierLocationDetails,
  clearToteDetails,
  fetchToteHistory,
  watchToteActions,
  fetchComments,
  addComment,
  cancelAddComment,
  updateComment,
  cancelUpdateComment,
  markCommentsRead,
} = SurgicalToteTrackingDetailsWidgetState.actionCreators;

const { fetchNotification } = NotificationsState.actionCreators;

const { addCoordinate, clearCoordinatesByType, clearAllCoordinates } =
  MapState.actionCreators;

const actionCreators = {
  clearToteMedia,
  fetchToteDetails,
  fetchToteActiveExceptions,
  fetchToteMedia,
  fetchToteSupplierLocationDetails,
  clearToteDetails,
  addCoordinate,
  clearCoordinatesByType,
  clearAllCoordinates,
  fetchToteHistory,
  watchToteActions,
  fetchNotification,
  fetchComments,
  addComment,
  cancelAddComment,
  updateComment,
  cancelUpdateComment,
  markCommentsRead,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return { ...stateProps, ...dispatchProps, ...ownProps };
};

export default connect(
  mapStateToProps,
  actionCreators,
  mergeProps,
)(SurgicalToteTrackingDetails);
