/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { Tabs, TabsClassNames } from "components/molecules/Tabs.molecule";
import { Text, FontSize } from "components/atoms/Text.atom";
import { CoordinatesTable } from "components-old/CoordinatesTable";
import { TabPanelPlaceholder } from "components/no-data-placeholders";
import { flattenShipmentStops } from "modules/shipment-detail/trip-utils";
import { StopsTable } from "../shipment-detail-styled-components/StopsTable";
import { MapCoordinateType } from "modules/map/components/map-coordinate-definition";
import { TripSummary } from "shared/components/organisms/TripSummary.organism";

export const getKeyUpdateForShipment = (t, childShipment) => {
  // H1-799: Match friendly event name formatting in src\shared\hooks\useEntityTripSummary.js
  const keyUpdateFields = [
    {
      eventTimeFieldName: "destination_actual_arrival",
      receivedTimeFieldName: "destination_arrived_at_received_ts",
      comment: `${t("Arrived for Delivery")} - ${
        childShipment.destination_name
      } (${childShipment.destination_location_code})`,
    },
    {
      eventTimeFieldName: "origin_actual_arrival",
      receivedTimeFieldName: "origin_arrived_at_received_ts",
      comment: `${t("Arrived for Pickup")} - ${childShipment.origin_name} (${
        childShipment.origin_location_code
      })`,
    },
    {
      eventTimeFieldName: "origin_actual_departure",
      receivedTimeFieldName: "origin_departed_at_received_ts",
      comment: `${t("Departed Pickup")} - ${childShipment.origin_name} (${
        childShipment.origin_location_code
      })`,
    },
  ];
  let keyUpdates = [];
  keyUpdateFields.forEach((field) => {
    // If there is an event time for this key update, we will include it.
    if (childShipment[field.eventTimeFieldName] !== null) {
      keyUpdates.push({
        eventTs: childShipment[field.eventTimeFieldName],
        ts: childShipment[field.receivedTimeFieldName],
        update: field.comment,
      });
    }
  });
  return keyUpdates;
};

const processKeyUpdates = (t, parentShipment, childShipments) => {
  const tripLegsEndToEnd = flattenShipmentStops(
    t,
    parentShipment,
    childShipments,
  );
  return tripLegsEndToEnd.map((leg) => ({
    ...leg,
    code: "",
    scheduled_delivery: null,
    scheduled_pickup: null,
  }));
};

const processCoordinates = (parentShipment, childShipments) => {
  let data = {};

  if (!parentShipment || _.isEmpty(childShipments)) {
    return data;
  }

  let coordinatesData = [];

  Object.keys(childShipments).forEach((id) => {
    let s = childShipments[id];

    s.current_location.updates.forEach((c, i) => {
      coordinatesData.push(c);
    });
  });

  return {
    coordinates: coordinatesData,
  };
};

const processStops = (parentShipment, childShipments) => {
  let data = {};

  if (!parentShipment || _.isEmpty(childShipments)) {
    return data;
  }

  let stopsData = [];
  let active_exceptions_ng;

  parentShipment.child_shipments.forEach((childShipment) => {
    childShipment.shipment_stops.forEach((childShipmentStop) => {
      stopsData.push(childShipmentStop);
    });
  });

  // H1-796: Add functionality here to dedupe stops
  // Compare each stop to the previous stop to see if they are identical
  // if they are identical, merge them
  // H1-844 first stop of a shipment leg is the pickup, second is delivery
  // So we arrived (pickup) from prevStop, and departed (delivery) from s
  let dedupedStops = [];

  // H1-1349 Update to dedupedStops process. earliest_arrival_datetime and latest_arrival_datetime
  // are the scheudled pickup window of the stop and come from the first shipment leg.
  // Added var to grab the delivery_earliest_arrival_datetime and delivery_latest_arrival_datetime
  // for the scheduled delivery window, which come from the second shipment in the previous stopData in queue.
  // Also update dedupedStops for getting the correct sequence for actual pickup and delivery arrival times
  // using the same process as scheduled.
  let deliveryWindow = {
    delivery_earliest_arrival_datetime: null,
    delivery_latest_arrival_datetime: null,
  };

  stopsData.forEach((s, i) => {
    if (i === 0) {
      dedupedStops.push(
        Object.assign({}, s, {
          ...deliveryWindow,
          pickup: s.arrived,
          delivery: null,
        }),
      );
    }
    if (i === stopsData.length - 1) {
      // for last stop, delivery window times come from earliest_arrival_datetime/latest_arrival_datetime
      dedupedStops.push(
        Object.assign({}, s, {
          delivery_earliest_arrival_datetime: s.earliest_arrival_datetime,
          delivery_latest_arrival_datetime: s.latest_arrival_datetime,
          pickup: null,
          delivery: s.arrived,
        }),
      );
    } else {
      // check if this stop has the same location.id as the previous stop
      let prevStop = dedupedStops.slice(-1)[0];
      if (prevStop.location.id === s.location.id) {
        // these are the relevant fields that are used in stopRows
        prevStop.departed = s.departed;
        prevStop.departed_at = s.departed_at;
        prevStop.eta = prevStop.eta ? prevStop.eta : s.eta;

        // update scheduled pickup
        prevStop.earliest_arrival_datetime = s.earliest_arrival_datetime;
        prevStop.latest_arrival_datetime = s.latest_arrival_datetime;

        // update scheduled delivery
        prevStop.delivery_earliest_arrival_datetime =
          deliveryWindow.delivery_earliest_arrival_datetime;
        prevStop.delivery_latest_arrival_datetime =
          deliveryWindow.delivery_latest_arrival_datetime;

        // update actual pickup
        prevStop.pickup = s.arrived;
      } else {
        dedupedStops.push(
          Object.assign({}, s, {
            ...deliveryWindow,
            pickup: null,
            delivery: s.arrived,
          }),
        );

        // if second leg, save those times for scheduled delivery
        if (s.stop_sequence === 2) {
          deliveryWindow.delivery_earliest_arrival_datetime =
            s.earliest_arrival_datetime;
          deliveryWindow.delivery_latest_arrival_datetime =
            s.latest_arrival_datetime;
        }
      }
    }
  });

  const orderedStopsData = _.orderBy(dedupedStops, [
    "earliest_arrival_datetime",
  ]);

  // H1-815: Use parent shipment ETA if ultimate destination ETA is null
  const lastStop = _.last(orderedStopsData);
  if (lastStop && !lastStop.eta) {
    lastStop.eta = parentShipment.destination_eta;
  }

  return {
    stops: orderedStopsData,
    active_exceptions_ng: active_exceptions_ng,
  };
};

const EndToEndTabsPanel = ({
  childShipments,
  parentShipment,
  addCoordinate,
  clearCoordinatesByType,
  selectedMapCoordinate,
  t,
}) => {
  const keyUpdatesData = processKeyUpdates(t, parentShipment, childShipments);
  const plannedStops = keyUpdatesData.map((d) => {
    return {
      ...d,
      typeCode: d.location.lad.code,
      lad: d.location.lad,
    };
  });

  const stopsData = processStops(parentShipment, childShipments);
  const coordinatesData = processCoordinates(parentShipment, childShipments);

  const onTabsSelect = (index, lastIndex, event) => {
    // Coordinates
    // if we leave from the coordinates tab
    if (event.target.textContent !== t("shipment-details:Coordinates")) {
      clearCoordinatesByType([MapCoordinateType.SELECTED_COORDINATE]);
    }
  };

  return (
    <div>
      <Tabs fullWidthTabs onSelect={onTabsSelect}>
        <Tabs.TabList>
          <Tabs.Tab>
            <Text size={FontSize.size12}>
              {t("shipment-details:Trip Summary")}
            </Text>
          </Tabs.Tab>
          <Tabs.Tab>
            <Text size={FontSize.size12}>{t("shipment-details:Stops")}</Text>
          </Tabs.Tab>
          <Tabs.Tab>
            <Text size={FontSize.size12}>
              {t("shipment-details:Coordinates")}
            </Text>
          </Tabs.Tab>
        </Tabs.TabList>
        <Tabs.TabPanel>
          {keyUpdatesData.length === 0 ? (
            <TabPanelPlaceholder text={t("No Trip Plan Available")} />
          ) : (
            <TripSummary
              plannedStops={plannedStops}
              showTripLegReferences={false}
            />
          )}
        </Tabs.TabPanel>
        <Tabs.TabPanel>
          {stopsData.stops && !_.isEmpty(stopsData.stops) ? (
            <StopsTable
              mode={parentShipment.mode_name}
              isMultileg={parentShipment?.shipment_details?.is_multileg}
              stops={stopsData.stops}
              active_exceptions_ng={stopsData.active_exceptions_ng}
            />
          ) : (
            <TabPanelPlaceholder text={t("No Stops Available")} />
          )}
        </Tabs.TabPanel>
        <Tabs.TabPanel>
          {coordinatesData.coordinates &&
          !_.isEmpty(coordinatesData.coordinates) ? (
            <CoordinatesTable
              coords={coordinatesData.coordinates}
              addCoordinate={addCoordinate}
              clearCoordinatesByType={clearCoordinatesByType}
              selectedMapCoordinate={selectedMapCoordinate}
            />
          ) : (
            <TabPanelPlaceholder text={t("No Coordinates Available")} />
          )}
        </Tabs.TabPanel>
      </Tabs>
    </div>
  );
};

EndToEndTabsPanel.propTypes = {
  childShipments: PropTypes.object,
  parentShipment: PropTypes.object,
  addCoordinate: PropTypes.func,
  clearCoordinatesByType: PropTypes.func,
  selectedMapCoordinate: PropTypes.object,
  t: PropTypes.func,
};

export default withTranslation("shipment-details")(EndToEndTabsPanel);
