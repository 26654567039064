import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import apiUrl from "api-url";
import chainReducers from "vendor/signal-utils/chain-reducers";

const STORE_MOUNT_POINT = "fvGroupCategories";

const groupCategoryUrl = (solutionId, groupType) => {
  return `/entity-group/solution/${solutionId}/group-type/${groupType}/group-category`;
};

const itssCountDuck = buildFetchDuck(STORE_MOUNT_POINT, "itssCount");
const spotBuyCountDuck = buildFetchDuck(STORE_MOUNT_POINT, "spotBuyCount");

// Action Creators

function fetchItssCount(solutionId) {
  const url = apiUrl(`${groupCategoryUrl(solutionId, "ITSS")}/count`);
  return (dispatch) => dispatch(itssCountDuck.fetch(url));
}

function fetchSpotBuyCount(solutionId) {
  const url = apiUrl(`${groupCategoryUrl(solutionId, "SB")}/count`);
  return (dispatch) => dispatch(spotBuyCountDuck.fetch(url));
}

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchItssCount,
    fetchSpotBuyCount,
  },
  selectors: {
    getItssCountData: itssCountDuck.selectors.getData,
    getSpotBuyCountData: spotBuyCountDuck.selectors.getData,
  },
  reducer: chainReducers([itssCountDuck.reducer, spotBuyCountDuck.reducer]),
};
