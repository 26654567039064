/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import _ from "lodash";
import DetailsTable from "components-old/DetailsTable";
import { DateTime } from "components/atoms/DateTime.atom";
import { ShowMoreList } from "components/molecules/ShowMoreList.molecule";

const RowDetails = ({ row }) => {
  const { t } = useTranslation("surgical-tote-tracking");

  let uniqueDeviceIdList = row["Unique Device ID"]?.split(",") ?? [];
  return (
    <tr>
      <td
        style={{
          fontWeight: "bold",
          verticalAlign: "middle",
          width: "5em",
        }}
      >
        {row?.Status}
      </td>
      <td style={{ textAlign: "center", verticalAlign: "middle" }}>
        {uniqueDeviceIdList.length > 0 ? (
          <ShowMoreList
            title={t("surgical-tote-tracking:Unique Device ID")}
            list={uniqueDeviceIdList}
            visibleItemCount={3}
          />
        ) : null}
      </td>
      <td
        style={{
          textAlign: "center",
          verticalAlign: "middle",
          width: "5em",
        }}
      >
        {row?.Type}
      </td>
      <td style={{ textAlign: "center", verticalAlign: "middle" }}>
        <DateTime stack plain localize dateTime={row?.["Event Time"]}>
          <DateTime.Time bold />
          <DateTime.Timezone bold />
        </DateTime>
      </td>
      <td style={{ textAlign: "center", verticalAlign: "middle" }}>
        <DateTime stack plain localize dateTime={row?.["Received Time"]}>
          <DateTime.Time bold />
          <DateTime.Timezone bold />
        </DateTime>
      </td>
    </tr>
  );
};

RowDetails.propTypes = {
  row: PropTypes.object,
};

export const SurgicalToteTrackingHistoryTab = ({ toteHistory }) => {
  const { t } = useTranslation("surgical-tote-tracking");

  let headers = [
    { title: t("surgical-tote-tracking:Event Status"), centered: false },
    { title: t("surgical-tote-tracking:Unique Device ID"), centered: false },
    { title: t("surgical-tote-tracking:Type"), centered: true },
    { title: t("surgical-tote-tracking:Event Time"), centered: true },
    { title: t("surgical-tote-tracking:Received Time"), centered: true },
  ];

  const sortedHistory = _.orderBy(toteHistory, ["Event Time"], ["desc"]);

  const rows = sortedHistory.map((row, index) => {
    return <RowDetails key={index} row={row} />;
  });

  return <DetailsTable headers={headers} rows={rows} />;
};

SurgicalToteTrackingHistoryTab.propTypes = {
  toteHistory: PropTypes.array,
};
