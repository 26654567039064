import {InferType} from "prop-types";
import {useCallback} from "react";
import {TFunction} from "i18next";
import {useTranslation} from "react-i18next";
import {
  MdHourglassEmpty,
  MdTimer,
  MdFileUpload,
  MdFileDownload,
  MdErrorOutline,
  MdLockOutline,
} from "react-icons/md";
import {IoIosHelpCircleOutline} from "react-icons/io";
import {FiEye} from "react-icons/fi";
import {
  faRoute,
  faTireFlat,
  faUndoAlt,
} from "@fortawesome/pro-solid-svg-icons";

import {IconType} from "components/atoms/enums";
import {IconPropTypes} from "components/atoms/Icon.atom";
import Colors from "styles/colors";
import {formatDuration} from "utils/date-time";
import {logWarningForMissingTranslation} from "utils/log-warning.utils";

/**
 * Used to identify exceptions by something other than their english name.
 *
 * We're using this instead of the IDs from the API because they may not
 * be consistent between environments. Once the API has a way to identify
 * exception types (like a reasonCode), we should use those values instead.
 *
 * e.g. `exception.type === ShipmentExceptionType.LOST` vs `exception.name === "Lost"`.
 */
export enum ShipmentExceptionType {
  BEHIND_SCHEDULE = "behind_schedule",
  MISSED_PICKUP = "missed_pickup",
  MISSED_DROPOFF = "missed_dropoff",
  BAD_ORDER = "bad_order",
  IN_HOLD = "in_hold",
  IDLE_TRAIN = "idle_train",
  UNDER_REVIEW = "under_review",
  LOST = "lost",
  CARRIER_DELAYED = "carrier_delayed",
  SHIPPER_DELAYED = "shipper_delayed",
  BACKORDER = "backorder",
  OFF_ROUTE = "off_route",
  NONE = "none",
}

/**
 * Converts the english name of an exception to the ShipmentExceptionType enum value.
 *
 * @param name The english name of the exception.
 * @returns The `ShipmentExceptionType` for the exception.
 */
export const getShipmentExceptionEnumFromName = (name: string) => {
  switch (name) {
    case "Behind Schedule":
      return ShipmentExceptionType.BEHIND_SCHEDULE;
    case "Missed Pickup":
      return ShipmentExceptionType.MISSED_PICKUP;
    case "Missed Drop-Off":
      return ShipmentExceptionType.MISSED_DROPOFF;
    case "Bad Order":
      return ShipmentExceptionType.BAD_ORDER;
    case "In Hold":
      return ShipmentExceptionType.IN_HOLD;
    case "Idle Train":
      return ShipmentExceptionType.IDLE_TRAIN;
    case "Under Review":
      return ShipmentExceptionType.UNDER_REVIEW;
    case "Lost":
      return ShipmentExceptionType.LOST;
    case "Carrier Delayed":
      return ShipmentExceptionType.CARRIER_DELAYED;
    case "Shipper Delayed":
      return ShipmentExceptionType.SHIPPER_DELAYED;
    case "Backorder":
      return ShipmentExceptionType.BACKORDER;
    case "Off Route":
      return ShipmentExceptionType.OFF_ROUTE;
    default:
      return ShipmentExceptionType.NONE;
  }
};

/**
 * Gets the translated name for the exception.
 *
 * @param type The type of the shipment.
 * @param t The i18next translation function.
 * @returns The translated name for the exception.
 */
export const getTranslatedNameForExceptionType = (
  type: ShipmentExceptionType,
  t: TFunction,
) => {
  switch (type) {
    case ShipmentExceptionType.BEHIND_SCHEDULE:
      return t("exceptions:Behind Schedule");
    case ShipmentExceptionType.MISSED_PICKUP:
      return t("exceptions:Missed Pickup");
    case ShipmentExceptionType.MISSED_DROPOFF:
      return t("exceptions:Missed Drop-Off");
    case ShipmentExceptionType.BAD_ORDER:
      return t("exceptions:Bad Order");
    case ShipmentExceptionType.IN_HOLD:
      return t("exceptions:In Hold");
    case ShipmentExceptionType.IDLE_TRAIN:
      return t("exceptions:Idle Train");
    case ShipmentExceptionType.UNDER_REVIEW:
      return t("exceptions:Under Review");
    case ShipmentExceptionType.LOST:
      return t("exceptions:Lost");
    case ShipmentExceptionType.CARRIER_DELAYED:
      return t("exceptions:Carrier Delayed");
    case ShipmentExceptionType.SHIPPER_DELAYED:
      return t("exceptions:Shipper Delayed");
    case ShipmentExceptionType.BACKORDER:
      return t("exceptions:Backorder");
    case ShipmentExceptionType.OFF_ROUTE:
      return t("exceptions:Off Route");
    default:
      return "";
  }
};

// The exception object from DomainDataState.
type ExceptionType = {
  id: number;
  name: string;
  type: ShipmentExceptionType;
};

/**
 * Combines exception types and counts to a format for ExceptionPanel and ExceptionCountGroup.
 *
 * @param exceptionTotals The API data for exception counts.
 * @param exceptionTypes The list of exceptions available.
 * @returns The counts for all shipments and each exception type.
 */
export const getExceptionData = (
  exceptionTotals: { [x: string]: number } = {},
  exceptionTypes: ExceptionType[] = [],
) => {
  const totals = exceptionTypes.map((exceptionType) => {
    let count;
    switch (exceptionType.type) {
      case ShipmentExceptionType.BEHIND_SCHEDULE:
        count = exceptionTotals.total_behind_schedule;
        break;
      case ShipmentExceptionType.MISSED_PICKUP:
        count = exceptionTotals.total_missed_pickup;
        break;
      case ShipmentExceptionType.MISSED_DROPOFF:
        count = exceptionTotals.total_missed_dropoff;
        break;
      case ShipmentExceptionType.CARRIER_DELAYED:
        count = exceptionTotals.total_carrier_delayed;
        break;
      case ShipmentExceptionType.SHIPPER_DELAYED:
        count = exceptionTotals.total_shipper_delayed;
        break;
      case ShipmentExceptionType.BACKORDER:
        count = exceptionTotals.total_backorder;
        break;
      case ShipmentExceptionType.LOST:
        count = exceptionTotals.total_lost;
        break;
      case ShipmentExceptionType.IDLE_TRAIN:
        count = exceptionTotals.total_idle_train;
        break;
      case ShipmentExceptionType.BAD_ORDER:
        count = exceptionTotals.total_bad_order;
        break;
      case ShipmentExceptionType.IN_HOLD:
        count = exceptionTotals.total_in_hold;
        break;
      case ShipmentExceptionType.UNDER_REVIEW:
        count = exceptionTotals.total_under_review;
        break;
      case ShipmentExceptionType.OFF_ROUTE:
        count = exceptionTotals.total_off_route;
        break;
      default:
        count = 0;
    }

    return {
      ...exceptionType,
      count: count ?? 0,
    };
  });

  return {
    exceptions: sortExceptions(totals),
    totalShipments: exceptionTotals.total_shipments,
  };
};

/**
 * Sorts a list of exception types.
 *
 * Used to get the desired order of exception counts for
 * Shipment Exceptions dashboard widget and saved search cards.
 *
 * @param exceptions A list of exception types.
 * @returns The sorted list.
 */
export const sortExceptions = (exceptions: ExceptionType[] = []) => {
  const order = [
    ShipmentExceptionType.BEHIND_SCHEDULE,
    ShipmentExceptionType.MISSED_PICKUP,
    ShipmentExceptionType.MISSED_DROPOFF,
    ShipmentExceptionType.CARRIER_DELAYED,
    ShipmentExceptionType.SHIPPER_DELAYED,
    ShipmentExceptionType.BACKORDER,
    ShipmentExceptionType.LOST,
    ShipmentExceptionType.IDLE_TRAIN,
    ShipmentExceptionType.BAD_ORDER,
    ShipmentExceptionType.IN_HOLD,
    ShipmentExceptionType.UNDER_REVIEW,
    ShipmentExceptionType.OFF_ROUTE,
  ];

  const sorted: ExceptionType[] = [];

  order.forEach((type) => {
    let exception = exceptions.find((exception) => exception.type === type);
    if (exception) {
      sorted.push(exception);
    }
  });

  return sorted;
};

/**
 * Gets the icon type, source and color for an exception.
 *
 * @param type The exception type.
 * @returns The icon info for that exception.
 */
export const getIconData = (type: ShipmentExceptionType) => {
  let iconType: IconType = IconType.FontAwesome;
  let src: InferType<typeof IconPropTypes.src> = null;

  switch (type) {
    case ShipmentExceptionType.BEHIND_SCHEDULE:
      iconType = IconType.ReactIcons;
      src = MdTimer;
      break;
    case ShipmentExceptionType.MISSED_PICKUP:
      iconType = IconType.ReactIcons;
      src = MdFileUpload;
      break;
    case ShipmentExceptionType.MISSED_DROPOFF:
      iconType = IconType.ReactIcons;
      src = MdFileDownload;
      break;
    case ShipmentExceptionType.BAD_ORDER:
      iconType = IconType.ReactIcons;
      src = MdErrorOutline;
      break;
    case ShipmentExceptionType.IN_HOLD:
      iconType = IconType.ReactIcons;
      src = MdLockOutline;
      break;
    case ShipmentExceptionType.IDLE_TRAIN:
      iconType = IconType.ReactIcons;
      src = MdHourglassEmpty;
      break;
    case ShipmentExceptionType.UNDER_REVIEW:
      iconType = IconType.ReactIcons;
      src = FiEye;
      break;
    case ShipmentExceptionType.LOST:
      iconType = IconType.ReactIcons;
      src = IoIosHelpCircleOutline;
      break;
    case ShipmentExceptionType.CARRIER_DELAYED:
      iconType = IconType.FontAwesome;
      src = faTireFlat;
      break;
    case ShipmentExceptionType.SHIPPER_DELAYED:
      iconType = IconType.FontAwesome;
      src = faTireFlat;
      break;
    case ShipmentExceptionType.BACKORDER:
      iconType = IconType.FontAwesome;
      src = faUndoAlt;
      break;
    case ShipmentExceptionType.OFF_ROUTE:
      iconType = IconType.FontAwesome;
      src = faRoute;
      break;
    default:
      break;
  }

  return {
    type: iconType,
    src: src,
    color: getColorForExceptionType(type),
  };
};

/**
 * Gets an exception's color for text and icons for the given type.
 *
 * @param exceptionType The exception type.
 * @returns The exception's color.
 */
export const getColorForExceptionType = (
  exceptionType: ShipmentExceptionType,
) => {
  switch (exceptionType) {
    case ShipmentExceptionType.BEHIND_SCHEDULE:
    case ShipmentExceptionType.IDLE_TRAIN:
    case ShipmentExceptionType.OFF_ROUTE:
      return Colors.highlight.YELLOW;
    case ShipmentExceptionType.MISSED_PICKUP:
    case ShipmentExceptionType.MISSED_DROPOFF:
    case ShipmentExceptionType.BAD_ORDER:
    case ShipmentExceptionType.IN_HOLD:
    case ShipmentExceptionType.LOST:
    case ShipmentExceptionType.CARRIER_DELAYED:
      return Colors.highlight.RED;
    case ShipmentExceptionType.SHIPPER_DELAYED:
      return Colors.highlight.RED;
    case ShipmentExceptionType.BACKORDER:
      return Colors.exceptions.BACKORDER;
    case ShipmentExceptionType.UNDER_REVIEW:
      return Colors.highlight.MEDIUM_LIGHT_GRAY;
    default:
      return null;
  }
};

/**
 * Gets an exception's color for text and icons for the given name.
 *
 * Note: Will be removed once we have consistent exception IDs from the API.
 * See `ShipmentExceptionType`.
 *
 * @param name The english exception name.
 * @returns The color for that exception.
 */
export const getColorForExceptionFromName = (name: string) => {
  const exceptionType = getShipmentExceptionEnumFromName(name);
  return getColorForExceptionType(exceptionType);
};

const getDescriptionFromCode = (code: string, t: Function) => {
  switch (code) {
    case "A1": {
      return `${t("carrier-delayed-reason:Missed Delivery")}`;
    }
    case "A2": {
      return `${t("carrier-delayed-reason:Incorrect Address")}`;
    }
    case "A3": {
      return `${t("carrier-delayed-reason:Indirect Delivery")}`;
    }
    case "A5": {
      return `${t("carrier-delayed-reason:Unable to Locate")}`;
    }
    case "A6": {
      return `${t(
        "carrier-delayed-reason:Address Corrected - Delivery Attempted",
      )}`;
    }
    case "AA": {
      return `${t("carrier-delayed-reason:Mis-sort")}`;
    }
    case "AD": {
      return `${t(
        "carrier-delayed-reason:Customer Requested Future Delivery",
      )}`;
    }
    case "AE": {
      return `${t("carrier-delayed-reason:Restricted Articles Unacceptable")}`;
    }
    case "AF": {
      return `${t("carrier-delayed-reason:Accident")}`;
    }
    case "AG": {
      return `${t("carrier-delayed-reason:Consignee Related")}`;
    }
    case "AH": {
      return `${t("carrier-delayed-reason:Driver Related")}`;
    }
    case "AI": {
      return `${t("carrier-delayed-reason:Mechanical Breakdown")}`;
    }
    case "AJ": {
      return `${t("carrier-delayed-reason:Other Carrier Related")}`;
    }
    case "AK": {
      return `${t("carrier-delayed-reason:Damaged, Rewrapped in Hub")}`;
    }
    case "AL": {
      return `${t("carrier-delayed-reason:Previous Stop")}`;
    }
    case "AM": {
      return `${t("carrier-delayed-reason:Shipper Related")}`;
    }
    case "AN": {
      return `${t("carrier-delayed-reason:Holiday - Closed")}`;
    }
    case "AO": {
      return `${t(
        "carrier-delayed-reason:Weather or Natural Disaster Related",
      )}`;
    }
    case "AP": {
      return `${t("carrier-delayed-reason:Awaiting Export")}`;
    }
    case "AQ": {
      return `${t(
        "carrier-delayed-reason:Recipient Unavailable - Delivery Delayed",
      )}`;
    }

    case "AR": {
      return `${t("carrier-delayed-reason:Improper International Paperwork")}`;
    }
    case "AS": {
      return `${t(
        "carrier-delayed-reason:Hold Due to Customs Documentation Problems",
      )}`;
    }
    case "AT": {
      return `${t(
        "carrier-delayed-reason:Unable to Contact Recipient for Broker Information",
      )}`;
    }
    case "AU": {
      return `${t("carrier-delayed-reason:Civil Event Related Delay")}`;
    }
    case "AV": {
      return `${t("carrier-delayed-reason:Exceeds Service Limitations")}`;
    }

    case "AW": {
      return `${t("carrier-delayed-reason:Past Cut-off Time")}`;
    }
    case "AX": {
      return `${t("carrier-delayed-reason:Insufficient Pick-up Time")}`;
    }
    case "AY": {
      return `${t("carrier-delayed-reason:Missed Pick-up")}`;
    }
    case "AZ": {
      return `${t("carrier-delayed-reason:Alternate Carrier Delivered")}`;
    }

    case "B1": {
      return `${t("carrier-delayed-reason:Consignee Closed")}`;
    }
    case "B2": {
      return `${t("carrier-delayed-reason:Trap for Customer")}`;
    }
    case "B4": {
      return `${t("carrier-delayed-reason:Held for Payment")}`;
    }
    case "B5": {
      return `${t("carrier-delayed-reason:Held for Consignee")}`;
    }

    case "B8": {
      return `${t(
        "carrier-delayed-reason:Improper Unloading Facility or Equipment",
      )}`;
    }
    case "B9": {
      return `${t("carrier-delayed-reason:Receiving Time Restricted")}`;
    }
    case "BB": {
      return `${t("carrier-delayed-reason:Held per Shipper")}`;
    }
    case "BC": {
      return `${t("carrier-delayed-reason:Missing Documents")}`;
    }

    case "BD": {
      return `${t("carrier-delayed-reason:Border Clearance")}`;
    }
    case "BE": {
      return `${t("carrier-delayed-reason:Road Conditions")}`;
    }
    case "BF": {
      return `${t("carrier-delayed-reason:Carrier Keying Error")}`;
    }
    case "BG": {
      return `${t("carrier-delayed-reason:Other")}`;
    }

    case "BH": {
      return `${t(
        "carrier-delayed-reason:Insufficient Time to Complete Delivery",
      )}`;
    }
    case "BI": {
      return `${t("carrier-delayed-reason:Cartage Agent")}`;
    }
    case "BJ": {
      return `${t("carrier-delayed-reason:Customer Wanted Earlier Delivery")}`;
    }
    case "BK": {
      return `${t("carrier-delayed-reason:Prearranged Appointment")}`;
    }

    case "BL": {
      return `${t("carrier-delayed-reason:Held for Protective Service")}`;
    }
    case "BM": {
      return `${t("carrier-delayed-reason:Flatcar Shortage")}`;
    }
    case "BN": {
      return `${t("carrier-delayed-reason:Failed to Release Billing")}`;
    }
    case "BO": {
      return `${t("carrier-delayed-reason:Railroad Failed to Meet Schedule")}`;
    }
    case "BP": {
      return `${t("carrier-delayed-reason:Load Shifted")}`;
    }
    case "BQ": {
      return `${t("carrier-delayed-reason:Shipment Overweight")}`;
    }
    case "BR": {
      return `${t("carrier-delayed-reason:Train Derailment")}`;
    }
    case "BS": {
      return `${t("carrier-delayed-reason:Refused by Customer")}`;
    }
    case "BT": {
      return `${t("carrier-delayed-reason:Returned to Shipper")}`;
    }
    case "C1": {
      return `${t("carrier-delayed-reason:Waiting for Customer Pick-up")}`;
    }
    case "C2": {
      return `${t("carrier-delayed-reason:Credit Hold")}`;
    }
    case "C3": {
      return `${t("carrier-delayed-reason:Suspended at Customer Request")}`;
    }
    case "C4": {
      return `${t("carrier-delayed-reason:Customer Vacation")}`;
    }
    case "C5": {
      return `${t("carrier-delayed-reason:Customer Strike")}`;
    }
    case "C6": {
      return `${t("carrier-delayed-reason:Waiting Shipping Instructions")}`;
    }
    case "C7": {
      return `${t(
        "carrier-delayed-reason:Waiting for Customer Specified Carrier",
      )}`;
    }
    case "C8": {
      return `${t("carrier-delayed-reason:Collect on Delivery Required")}`;
    }
    case "C9": {
      return `${t("carrier-delayed-reason:Cash Not Available From Consignee")}`;
    }
    case "CA": {
      return `${t("carrier-delayed-reason:Customs (Import or Export)")}`;
    }

    case "CB": {
      return `${t(
        "carrier-delayed-reason:No Requested Arrival Date Provided by Shipper",
      )}`;
    }

    case "CC": {
      return `${t(
        "carrier-delayed-reason:No Requested Arrival Time Provided by Shipper",
      )}`;
    }

    case "CR": {
      return `${t("carrier-delayed-reason:COVID Related")}`;
    }

    case "D1": {
      return `${t("carrier-delayed-reason:Carrier Dispatch Error")}`;
    }

    case "D2": {
      return `${t("carrier-delayed-reason:Driver Not Available")}`;
    }

    case "F1": {
      return `${t("carrier-delayed-reason:Non-Express Clearance Delay")}`;
    }

    case "F2": {
      return `${t("carrier-delayed-reason:International Non-carrier Delay")}`;
    }

    case "HB": {
      return `${t("carrier-delayed-reason:Held Pending Appointment")}`;
    }

    case "HS": {
      return `${t("carrier-delayed-reason:Hours of Service")}`;
    }

    case "NA": {
      return `${t("carrier-delayed-reason:Normal Appointment")}`;
    }

    case "NS": {
      return `${t("carrier-delayed-reason:Normal Status")}`;
    }

    case "P1": {
      return `${t("carrier-delayed-reason:Processing Delay")}`;
    }

    case "P2": {
      return `${t("carrier-delayed-reason:Waiting Inspection")}`;
    }

    case "P3": {
      return `${t("carrier-delayed-reason:Production Falldown")}`;
    }

    case "P4": {
      return `${t("carrier-delayed-reason:Held for Full Carrier Load")}`;
    }

    case "RC": {
      return `${t("carrier-delayed-reason:Reconsigned")}`;
    }

    case "S1": {
      return `${t("carrier-delayed-reason:Delivery Shortage")}`;
    }

    case "T1": {
      return `${t(
        "carrier-delayed-reason:Tractor With Sleeper Car Not Available",
      )}`;
    }

    case "T2": {
      return `${t(
        "carrier-delayed-reason:Tractor, Conventional, Not Available",
      )}`;
    }

    case "T3": {
      return `${t("carrier-delayed-reason:Trailer not Available")}`;
    }

    case "T4": {
      return `${t(
        "carrier-delayed-reason:Trailer Not Usable Due to Prior Product",
      )}`;
    }

    case "T5": {
      return `${t("carrier-delayed-reason:Trailer Class Not Available")}`;
    }

    case "T6": {
      return `${t("carrier-delayed-reason:Trailer Volume Not Available")}`;
    }

    case "T7": {
      return `${t("carrier-delayed-reason:Insufficient Delivery Time")}`;
    }
    default:
      return null;
  }
};

/**
 * A hook that provides a function for translating carrier delayed exception reason codes.
 *
 * @returns {Object.getFullTranslatedLabelForReasonCode} A function that, given a reason code, will return the full translated label using getDescriptionFromCode function.
 * @returns {Object.getOptionLabelFromCode} A function that, given a reason code, will return the label for use in a select element using getDescriptionFromCode function.
 */
export const useCarrierDelayedReasonCode = () => {
  const {t} = useTranslation("carrier-delayed-reason");

  return {
    getFullTranslatedLabelForReasonCode: (code: string) => {
      let description = getDescriptionFromCode(code, t);
      return `${description} (${code})`;
    },
    getOptionLabelFromCode: (code: string) => {
      let description = getDescriptionFromCode(code, t);
      return `${code} - ${description}`;
    },
  };
};

/** Keys that represent customer-defined labels to categorize the shipment's ETA */
enum EtaTimeCategoryKey {
  VERY_EARLY = "VERY_EARLY",
  EARLY = "EARLY",
  LATE = "LATE",
  VERY_LATE = "VERY_LATE",
}

/** The status of the ETA used to determine the color to use when displayed */
enum EtaTimeCategoryStatus {
  OK = "ok",
  WARNING = "warning",
  CRITICAL = "critical",
}

/** The category of the ETA from the API */
export type EtaTimeCategory = {
  key: EtaTimeCategoryKey;
  status: EtaTimeCategoryStatus;
  /* The difference in seconds between the ETA and the scheduled window open or close. */
  difference: number;
};

/**
 * Returns values for displaying the ETA time category on shipments.
 *
 * @param etaTimeCategory The category of the shipment's ETA provided by the API.
 * @param mode The mode of the shipment
 * @returns An object with labels, colors, and duration values
 */
export const useEtaTimeCategory = (etaTimeCategory?: EtaTimeCategory, mode?: string) => {
  const {t} = useTranslation("shipment-search");

  const getLabelTranslation = useCallback(
    (label: string) => {
      switch (label) {
        case EtaTimeCategoryKey.VERY_EARLY:
          return t("shipment-search:Very Early");
        case EtaTimeCategoryKey.EARLY:
          return t("shipment-search:Early");
        case EtaTimeCategoryKey.LATE:
          return t("shipment-search:Late");
        case EtaTimeCategoryKey.VERY_LATE:
          return t("shipment-search:Very Late");

        default: {
          logWarningForMissingTranslation(label);
          return label.split("_").join(" ");
        }
      }
    },
    [t],
  );

  const getLongLabelTranslation = useCallback(
    (label: string, duration: string) => {
      switch (label) {
        case EtaTimeCategoryKey.VERY_EARLY:
          return t("shipment-search:Very Early by [[[duration]]]", {
            duration,
          });
        case EtaTimeCategoryKey.EARLY:
          return t("shipment-search:Early by [[[duration]]]", {
            duration,
          });
        case EtaTimeCategoryKey.LATE:
          return t("shipment-search:Late by [[[duration]]]", {
            duration,
          });
        case EtaTimeCategoryKey.VERY_LATE:
          return t("shipment-search:Very Late by [[[duration]]]", {
            duration,
          });

        default: {
          logWarningForMissingTranslation(label);
          return `${label.split("_").join(" ")} by ${duration}`;
        }
      }
    },
    [t],
  );

  const getColor = useCallback((status: EtaTimeCategoryStatus) => {
    switch (status) {
      case EtaTimeCategoryStatus.OK:
        return Colors.etaTimeCategory.OK;
      case EtaTimeCategoryStatus.WARNING:
        return Colors.etaTimeCategory.WARNING;
      case EtaTimeCategoryStatus.CRITICAL:
        return Colors.etaTimeCategory.CRITICAL;
      default:
        return Colors.etaTimeCategory.DEFAULT;
    }
  }, []);

  if (!etaTimeCategory || mode === "LTL" || mode === "Parcel") {
    return null;
  }

  let color = getColor(etaTimeCategory.status);
  // TODO: Once IA-10453 is complete, remove this and rely only on `status` to determine color.
  // This is a work around because the DB does not have the correct config for "critical" statuses.
  if (etaTimeCategory.key === EtaTimeCategoryKey.VERY_LATE) {
    color = Colors.etaTimeCategory.CRITICAL;
  }

  const formattedDuration = formatDuration(etaTimeCategory.difference);
  return {
    label: getLabelTranslation(etaTimeCategory.key),
    longLabel: getLongLabelTranslation(etaTimeCategory.key, formattedDuration),
    color: color,
    formattedDuration,
  };
};
