// Displays a table of Watched VINs, and allows user to watch or unwatch each VIN

/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import moment from "moment";
import { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { EtaName, useEtaTranslations } from "shared/hooks/useEtaTranslations";
import { faCommentAlt } from "@fortawesome/pro-solid-svg-icons";

import Colors from "styles/colors";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Icon } from "components/atoms/Icon.atom";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import FinVehicleEntitiesState from "pages/finishedvehicle/redux/FinVehicleEntitiesState";
import FinVehicleEntityDetailsState from "pages/finishedvehicle/redux/FinVehicleEntityDetailsState";
import { getSolutionId } from "modules/organizations/OrganizationsState";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { DateTimeCell } from "components/organisms/base-table/Cell/DateTimeCell";
import { WatchToggle } from "shared/components/molecules/WatchToggle.molecule";

export const WatchedVins = (props) => {
  const {
    watchedVinArray,
    watchedVinsLoading,
    solutionId,
    fetchWatchedVins,
    fetchWatchedEntitiesTotalPages,
    watchedVinsPageIndex,
    watchedVinsPageSize,
    watchedVinsPageCount,
    setWatchedVinsPageIndex,
  } = props;
  const { t } = useTranslation("fv-dashboard");
  const dispatch = useDispatch();

  const [unwatchedVins, setUnwatchedVins] = useState([]);
  let unwatchTimers = {};

  useEffect(() => {
    fetchWatchedVins(solutionId, watchedVinsPageIndex);
    fetchWatchedEntitiesTotalPages(solutionId, watchedVinsPageIndex);
  }, [
    solutionId,
    watchedVinsPageIndex,
    fetchWatchedVins,
    fetchWatchedEntitiesTotalPages,
  ]);

  const toggleWatch = (vin) => {
    const { fetchWatchedVins, solutionId } = props;
    let newUnwatchedVins = [...unwatchedVins];

    // If the table is pending a refresh, cancel it
    if (unwatchTimers[vin]) {
      clearTimeout(unwatchTimers[vin]);
    }

    if (newUnwatchedVins.includes(vin)) {
      // VIN checkbox has already been unchecked - re-watch it
      newUnwatchedVins = newUnwatchedVins.filter((v) => v !== vin);
    } else {
      // VIN checkbox is checked - unwatch it
      newUnwatchedVins.push(vin);
    }

    setUnwatchedVins(newUnwatchedVins);

    // Refresh the table after delay (gives the user time to undo a click)
    unwatchTimers[vin] = setTimeout(() => {
      fetchWatchedVins(solutionId);
      fetchWatchedEntitiesTotalPages(solutionId);
    }, 2000);
  };

  // Render unchecked VIN rows with dimmed style
  const cellOpacity = (dim) => (dim ? 0.25 : 1);
  const CellRenderer = (cellInfo) => {
    const dim = unwatchedVins.includes(cellInfo.row.original.id);
    return <div css={{ opacity: cellOpacity(dim) }}>{cellInfo.value}</div>;
  };

  const tableColumns = (sortKey, sortDir) => {
    return [
      {
        Header: t("fv-dashboard:Watch"),
        accessor: "watch",
        width: 50,
        disableSortBy: true,
        disableResizing: true,
        centerAligned: true,
        Cell: (cellInfo) => {
          const vin = cellInfo.row.original.id;
          // API returns boolean in a string
          const watched = cellInfo.value === "true";
          return (
            <WatchToggle
              key={vin}
              checked={watched ?? false}
              onChange={(newWatchValue) => {
                dispatch(
                  FinVehicleEntityDetailsState.actionCreators.setWatchEntity(
                    solutionId,
                    vin,
                    newWatchValue,
                  ),
                );
                toggleWatch(vin);
              }}
              iconSize={FontSize.size24}
              color={Colors.nav.NAVY}
              checkedColor={Colors.highlight.YELLOW}
            />
          );
        },
      },
      {
        Header: t("fv-dashboard:VIN"),
        accessor: "id",
        Cell: (cellInfo) => {
          const dim = unwatchedVins.includes(cellInfo.row.original.id);

          return (
            <div
              style={{
                opacity: cellOpacity(dim),
              }}
            >
              {cellInfo.row.original.commentsCount &&
              cellInfo.row.original.commentsCount > 0 ? (
                <Tooltip
                  placement="top"
                  tooltipChildren={
                    <Text>{t("fv-vin-search:This VIN contains comments")}</Text>
                  }
                >
                  <Icon
                    src={faCommentAlt}
                    color={Colors.comments.unreadCommentIcon}
                    style={{ marginRight: 8 }}
                  />
                </Tooltip>
              ) : null}
              <Text bold>{cellInfo.row.original.id}</Text>
            </div>
          );
        },
      },
      {
        Header: t("fv-dashboard:Product Type"),
        accessor: "description",
        Cell: CellRenderer,
      },
      {
        Header: t("fv-dashboard:ETA"),
        accessor: "eta",
        Cell: (cellInfo) => {
          const { getEtaTranslation, isEtaName } = useEtaTranslations();
          let eta =
            cellInfo.row.original.lastEntityProgressUpdate?.destinationEta;
          let etaTranslated = false;
          if (eta === "Pending Dispatch") {
            let manualEtaRangeStart =
              cellInfo.row.original?.manualEtaRangeStart;
            let manualEtaRangeEnd = cellInfo.row.original?.manualEtaRangeEnd;
            etaTranslated = cellInfo.row.original?.manualEtaRangeStart
              ? t(
                  "fv-dashboard:Pending Dispatch - Estimated Delivery [[[fromMonth]]] - [[[toMonthYear]]]",
                  {
                    fromMonth: moment.utc(manualEtaRangeStart).format("MMM"),
                    toMonthYear: moment
                      .utc(manualEtaRangeEnd)
                      .format("MMM YYYY"),
                  },
                )
              : null;
          }
          const status = cellInfo.row.original.lifeCycleState;

          if (status === "Delivered" && cellInfo.row.original.inTransit) {
            return t("fv-dashboard:Delivered");
          } else if (etaTranslated) {
            return etaTranslated;
          } else if (!eta) {
            return null;
          }

          if (isEtaName(eta)) {
            return getEtaTranslation(eta);
          }

          return <DateTimeCell dateTime={eta} stack localize />;
        },
      },
    ];
  };

  const rowClickHandler = (row, cell) => {
    const { setSelectedEntityId } = props;

    // Prevent navigation if clicking in "unwatch" checkbox cell
    if (cell.column.id === "watch") {
      return;
    }

    // Navigate to VIN Details when clicking row
    setSelectedEntityId(row.original.id);
  };

  return (
    <PanelGroup collapsible style={{ marginTop: "1em" }}>
      <PanelGroup.Header title={t("fv-dashboard:My Watched VINs")} />
      <PanelGroup.Content style={{ padding: 0 }}>
        <BaseTable
          data={watchedVinArray}
          columns={tableColumns()}
          theme={Themes.LIGHT}
          isLoading={watchedVinsLoading}
          rowClickHandler={rowClickHandler}
          showPagination={true}
          showPageSizeDropdown={false}
          isManualPagination={true}
          pageIndex={watchedVinsPageIndex}
          pageSize={watchedVinsPageSize}
          pageCount={watchedVinsPageCount}
          onPageChange={(newPage) => {
            setWatchedVinsPageIndex(newPage);
          }}
        />
      </PanelGroup.Content>
    </PanelGroup>
  );
};

WatchedVins.propTypes = {
  watchedVinArray: PropTypes.array,
  watchedVinsLoading: PropTypes.bool,
  watchedVinsPageIndex: PropTypes.number,
  watchedVinsPageSize: PropTypes.number,
  watchedVinsPageCount: PropTypes.number,
  solutionId: PropTypes.string,
  fetchWatchedVins: PropTypes.func.isRequired,
  fetchWatchedEntitiesTotalPages: PropTypes.func.isRequired,
  setWatchedVinsPageIndex: PropTypes.func.isRequired,
  setSelectedEntityId: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    getWatchedVins,
    getWatchedVinsLoading,
    getWatchedVinsPageIndex,
    getWatchedVinsPageSize,
    getWatchedVinsPageCount,
  } = FinVehicleEntitiesState.selectors;

  return {
    solutionId: getSolutionId(state),
    watchedVinArray: getWatchedVins(state),
    watchedVinsLoading: getWatchedVinsLoading(state),
    watchedVinsPageIndex: getWatchedVinsPageIndex(state),
    watchedVinsPageSize: getWatchedVinsPageSize(state),
    watchedVinsPageCount: getWatchedVinsPageCount(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  const {
    fetchWatchedVins,
    fetchWatchedEntitiesTotalPages,
    setWatchedVinsPageIndex,
  } = FinVehicleEntitiesState.actionCreators;

  return {
    fetchWatchedVins: (solutionId) => dispatch(fetchWatchedVins(solutionId)),
    fetchWatchedEntitiesTotalPages: (solutionId) =>
      dispatch(fetchWatchedEntitiesTotalPages(solutionId)),
    setWatchedVinsPageIndex: (pageIndex) =>
      dispatch(setWatchedVinsPageIndex(pageIndex)),
    setSelectedEntityId: (entityId) =>
      dispatch({ type: "VIN_DETAILS", payload: { entity_id: entityId } }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WatchedVins);
