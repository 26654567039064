import {
  getNQueryStringFilterValuePriority,
  getMultiSelectQueryString,
  getBasicQueryString,
} from "components/search-bar/search-filter-query-strings";

import {
  AsyncSelectFilterButton,
  NFilterButton,
} from "components/search-bar/FilterButtons";

import {
  locationOptionsState,
  uniqueDeviceIdOptionsState,
} from "pages/surgicaltotetracking/modules/domain-data/SurgicalToteTrackingFilterLoaders";
export const SEARCH_CATEGORIES = [
  {
    queryKey: "id",
    label: (t) => t("surgical-tote-tracking:Asset ID"),
    placeholder: (t) => t("surgical-tote-tracking:Search Asset ID"),
    queryBuilder: getBasicQueryString,
    property: "toteIds",
  },
  // {
  //   queryKey: "supplierCode:contains",
  //   label: t => t("surgical-tote-tracking:Case #"),
  //   placeholder: t => t("surgical-tote-tracking:Search Case #"),
  //   queryBuilder: getBasicQueryString,
  //   property: "caseNumbers"
  // }
];

export const FILTERS = [
  {
    // ordered top down from 0 - N
    queryKey: ["status", "locations"],
    label: (t) => t("surgical-tote-tracking:Status"),
    Component: NFilterButton,
    nIsAsync: {
      status: false,
      locations: true,
    },
    nOptionsState: {
      status: null,
      locations: locationOptionsState,
    },
    //Label of filter where key is corresponding select
    nLabels: {
      status: null,
      locations: "At Location",
    },
    //Fields required from previous filter
    //Field with contraints as key
    nRequirments: {
      locations: ["At Location"],
    },
    // Hides fuzzy search options
    nHideFuzzySearch: {
      status: true,
      locations: false,
    },
    // Hides select all option
    nHideSelectAll: {
      status: true,
      locations: false,
    },
    // Hides select empty option
    nHideSelectEmpty: {
      status: true,
      locations: false,
    },
    //Showall filters with no requirments or contraints
    showAll: false,
    //Object key as query key
    optionsGetter: (props) => {
      return {
        status: props.statusFilterOptions ?? [],
      };
    },
    queryBuilder: getNQueryStringFilterValuePriority,
  },
  {
    queryKey: "lad",
    label: (t) => t("surgical-tote-tracking:Location Type"),
    optionsGetter: (props) => props.ladFilterOptions ?? [],
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
    hideFuzzySearch: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "activeExceptions",
    label: (t) => t("surgical-tote-tracking:Active Exceptions"),
    optionsGetter: (props) => props.activeExceptionsFilterOptions ?? [],
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
    hideFuzzySearch: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "contents",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("surgical-tote-tracking:Unique Device ID"),
    optionsState: uniqueDeviceIdOptionsState,
    queryBuilder: getMultiSelectQueryString,
  },
];
