/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  faCommentAlt,
  faBell as faBellSolid,
} from "@fortawesome/pro-solid-svg-icons";

import { Button } from "components/atoms/Button.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { Text, FontSize } from "components/atoms/Text.atom";
import { DateTime } from "components/atoms/DateTime.atom";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { Icon } from "components/atoms/Icon.atom";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { ProfileState } from "pages/profile/redux/ProfileState";
import { PartsCell } from "pages/partview/components/molecules/PartsCell.molecule";
import { usePartViewExceptionLabel } from "pages/partview/components/hooks/usePartViewExceptionLabel";
import PartViewEntityDetailsState from "pages/partview/redux/PartViewEntityDetailsState";
import { PartViewWatchedPackagesSubscriptionState } from "pages/partview/redux/PartViewWatchedPackagesSubscriptionState";
import { getIconData } from "pages/partview/utils/exceptions.utils";
import { PackageStatus } from "pages/partview/utils/const";
import { WatchToggle } from "shared/components/molecules/WatchToggle.molecule";
import { AlertMeModal } from "shared/components/modals/AlertMe.modal";
import { EtaName, useEtaTranslations } from "shared/hooks/useEtaTranslations";

import Colors from "styles/colors";

const WATCH_COLUMN_ID = "watch";

const useWatchedTableColumns = ({
  fetchWatchedPackages,
  hasDealerView,
  selectedFvOrgId,
}) => {
  const { t } = useTranslation("partview-dashboard");

  const { getFullTranslatedNameForException } = usePartViewExceptionLabel();
  const { getEtaTranslation } = useEtaTranslations();
  const [unwatchedList, setUnwatchedList] = useState([]);

  const toggleWatch = (trackingNumber) => {
    let newUnwatchedPackages = [...unwatchedList];

    if (newUnwatchedPackages.includes(trackingNumber)) {
      // Checkbox has already been unchecked -> re-watch it
      newUnwatchedPackages = newUnwatchedPackages.filter(
        (unwatchedTrackingNumber) => unwatchedTrackingNumber !== trackingNumber,
      );
    } else {
      // Checkbox is checked -> unwatch it
      newUnwatchedPackages.push(trackingNumber);
    }

    setUnwatchedList(newUnwatchedPackages);
  };

  const columns = [
    {
      Header: t("partview-dashboard:Watch"),
      id: WATCH_COLUMN_ID,
      accessor: "Watched",
      width: 50,
      disableSortBy: true,
      disableResizing: true,
      centerAligned: true,
      Cell: (cellInfo) => {
        const dispatch = useDispatch();
        const id = cellInfo.row.original.id;
        const trackingNumber = cellInfo.row.original.TrackingNumber;
        const watched = cellInfo.value === true;
        return (
          <WatchToggle
            key={id}
            checked={watched ?? false}
            onChange={(newWatchValue) => {
              dispatch(
                PartViewEntityDetailsState.actionCreators.setWatchPackage(
                  trackingNumber,
                  newWatchValue,
                  () => {
                    if (hasDealerView) {
                      if (selectedFvOrgId) {
                        fetchWatchedPackages(hasDealerView, selectedFvOrgId);
                      } else {
                        fetchWatchedPackages(hasDealerView);
                      }
                    } else {
                      fetchWatchedPackages();
                    }
                  },
                ),
              );
              toggleWatch(trackingNumber);
            }}
            iconSize={FontSize.size24}
            color={Colors.nav.NAVY}
            checkedColor={Colors.highlight.YELLOW}
          />
        );
      },
    },
    {
      Header: t("partview-dashboard:Package Number"),
      accessor: "TrackingNumber",
      Cell: (cellInfo) => {
        return (
          <div>
            {cellInfo.row.original?.commentsCount > 0 ? (
              <Tooltip
                placement="top"
                tooltipChildren={
                  <Text>
                    {t("partview-dashboard:This package contains comments")}
                  </Text>
                }
              >
                <Icon
                  src={faCommentAlt}
                  color={Colors.comments.unreadCommentIcon}
                  css={{ marginRight: 8 }}
                />
              </Tooltip>
            ) : null}
            <Text>{cellInfo.value}</Text>
          </div>
        );
      },
    },
    {
      Header: t("partview-dashboard:Part Details"),
      id: "partDetails",
      minWidth: 200,
      disableSortBy: true,
      Cell: PartsCell,
      accessor: (d) => {
        return {
          partList: d?.PartList ?? [],
          trackingNumber: d.TrackingNumber,
        };
      },
    },
    {
      Header: t("partview-dashboard:Last Milestone"),
      accessor: "LastMilestone",
      Cell: (cellInfo) => {
        return cellInfo.value?.location?.name;
      },
    },
    {
      Header: t("partview-dashboard:ETA"),
      accessor: "DestinationEta", // used eta value from "DestinationEta"
      Cell: (cellInfo) => {
        let lifecycleState = cellInfo.row.original.LifecycleState;
        const heldExceptionDateTime =
          cellInfo.row.original?.ActiveExceptionList.filter(
            (exception) => exception.name.toLowerCase() === EtaName.HOLD,
          )[0]?.resolvedDateTime ?? null;
        let eta = cellInfo.value ?? null;

        if (!eta) {
          return null;
        }

        if (lifecycleState?.toUpperCase() === PackageStatus.DELIVERED) {
          return t(getEtaTranslation(EtaName.DELIVERED));
        } else {
          if (
            eta.toLowerCase() === EtaName.HOLD ||
            eta.toLowerCase() === EtaName.TBD ||
            heldExceptionDateTime
          ) {
            return <Text>{getEtaTranslation(EtaName.TBD)}</Text>;
          } else if (eta.toLowerCase() === EtaName.PENDING_DISPATCH) {
            return <Text>{getEtaTranslation(EtaName.PENDING_DISPATCH)}</Text>;
          } else {
            return (
              <DateTime
                plain
                localize
                dateTime={eta}
                fallback={t("partview-search:N/A")}
              >
                <DateTime.Time style={{ display: "none" }} />
                <DateTime.Timezone style={{ display: "none" }} />
              </DateTime>
            );
          }
        }
      },
    },
    {
      Header: t("partview-dashboard:Active Exceptions"),
      accessor: "ActiveExceptionList",
      Cell: (cellInfo) => {
        let exceptions = cellInfo.value ?? [];

        if (!Array.isArray(exceptions)) {
          return null;
        }

        return (
          <div css={{ display: "flex", flexDirection: "column" }}>
            {exceptions.map((exception, i) => {
              const name = getFullTranslatedNameForException(exception);
              const icon = getIconData(exception.reasonCode);
              return (
                <div
                  key={i}
                  css={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  {icon ? (
                    <Icon
                      type={icon.type}
                      src={icon.src}
                      color={icon.color}
                      altText={icon.altText}
                      css={{
                        width: 15,
                        height: 15,
                        ...icon.style,
                      }}
                    />
                  ) : null}
                  <Text>{name}</Text>
                </div>
              );
            })}
          </div>
        );
      },
    },
  ];

  return { columns, unwatchedList };
};

export const WatchedPackages = ({
  watchedPackages,
  isLoading,
  pageIndex,
  pageSize,
  pageCount,
  fetchWatchedPackages,
  pushPartViewDetailsPage,
  setPageIndex,
  selectedFvOrgId = null,
  isDealerOrg = false,
  userPreferencesIsLoading,
  userPreferencesIsLoadingError,
  userPreferences,
  // Subscription
  subscribe,
  updateSubscription,
  unsubscribe,
  isSubscriptionUpdating,
  subscriptionUpdateSuccess,
  subscriptionUpdateError,
  hasDealerView,
}) => {
  const { t } = useTranslation("partview-dashboard");

  const { columns, unwatchedList } = useWatchedTableColumns({
    fetchWatchedPackages,
    hasDealerView,
    selectedFvOrgId,
  });

  const [showAlertMeModal, setShowAlertMeModal] = useState(false);

  useEffect(() => {
    fetchWatchedPackages();
  }, [fetchWatchedPackages, pageIndex]);

  const alertMePreferences =
    userPreferences?.defaultCommunication?.partviewAlertMe ?? {};

  const {
    enableEmail = false,
    enableSms = false,
    enablePlatform = false,
    emailAddress = "",
    phoneNumber = "",
  } = alertMePreferences;

  const isSubscribed = enableEmail || enableSms || enablePlatform;

  const subscriptionDetails = useMemo(
    () => ({
      enable_email: enableEmail,
      enable_sms: enableSms,
      enable_platform: enablePlatform,
      recipient_email: emailAddress,
      mobile_number: phoneNumber,
    }),
    [enableEmail, enableSms, enablePlatform, emailAddress, phoneNumber],
  );

  const rightContent = (
    <Button
      variant="outline-light"
      onClick={(e) => {
        e.stopPropagation();
        setShowAlertMeModal(true);
      }}
    >
      <Icon
        src={faBellSolid}
        color={isSubscribed ? Colors.highlight.GREEN : null}
        size={FontSize.size18}
      />
    </Button>
  );

  return (
    <>
      <PanelGroup collapsible>
        <PanelGroup.Header
          title={t("partview-dashboard:My Watched Packages")}
          rightContent={rightContent}
        />
        <PanelGroup.Content style={{ padding: 0 }}>
          <BaseTable
            theme={Themes.LIGHT}
            data={watchedPackages}
            columns={columns}
            isLoading={isLoading}
            rowClickHandler={(row, cell) => {
              // Prevent navigation if clicking in "unwatch" checkbox cell
              if (cell.column.id === WATCH_COLUMN_ID) {
                return;
              }

              // Navigate to Package Details when clicking row
              pushPartViewDetailsPage(
                row.original.TrackingNumber,
                selectedFvOrgId,
                isDealerOrg,
              );
            }}
            showPagination={true}
            showPageSizeDropdown={false}
            isManualPagination={true}
            pageCount={pageCount}
            pageSize={pageSize}
            pageIndex={pageIndex}
            onPageChange={(newPage) => {
              setPageIndex(newPage);
            }}
            rowDisabledHandler={(row) => {
              return unwatchedList.includes(row.original.TrackingNumber);
            }}
          />
        </PanelGroup.Content>
      </PanelGroup>
      <AlertMeModal
        show={showAlertMeModal}
        hide={() => setShowAlertMeModal(false)}
        modalSize="lg"
        headerText={t(
          "partview-dashboard:Alert me on all of my PartView Watched Packages",
        )}
        bodyText={
          <>
            {t(
              "partview-dashboard:Select or update at least 1 of the options below to enable/update this feature.",
            )}
            <div css={{ paddingBottom: "1rem", paddingTop: "0.25rem" }}>
              <b>Note:</b>
              {t(
                'partview-dashboard:This feature will only apply to packages you add to your "Watched Package" list after enablement/updating.',
              )}
            </div>
          </>
        }
        emailText={t("partview-dashboard:Alert me Via Email")}
        textMessageText={t("partview-dashboard:Alert me Via Text Message")}
        platformNotificationText={t(
          "partview-dashboard:Alert me Via In App Notification",
        )}
        isSubscriptionLoading={
          userPreferencesIsLoading || isSubscriptionUpdating
        }
        subscriptionRequestError={userPreferencesIsLoadingError}
        subscriptionDetails={isSubscribed ? subscriptionDetails : null}
        subscribe={subscribe}
        updateSubscription={updateSubscription}
        unsubscribe={unsubscribe}
        isSubscriptionUpdating={isSubscriptionUpdating}
        subscriptionUpdateSuccess={subscriptionUpdateSuccess}
        subscriptionUpdateError={subscriptionUpdateError}
      />
    </>
  );
};

WatchedPackages.propTypes = {
  fetchWatchedPackages: PropTypes.func.isRequired,
  watchedPackages: PropTypes.array,
  isLoading: PropTypes.bool,
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  pageCount: PropTypes.number,
  setPageIndex: PropTypes.func.isRequired,
  pushPartViewDetailsPage: PropTypes.func.isRequired,
  selectedFvOrgId: PropTypes.string,
  isDealerOrg: PropTypes.bool,
  userPreferencesIsLoading: PropTypes.bool,
  userPreferencesIsLoadingError: PropTypes.bool,
  userPreferences: PropTypes.shape({
    defaultCommunication: PropTypes.shape({
      partviewAlertMe: PropTypes.shape({
        enableEmail: PropTypes.bool,
        enableSms: PropTypes.bool,
        enablePlatform: PropTypes.bool,
        emailAddress: PropTypes.string,
        phoneNumber: PropTypes.string,
      }),
    }),
  }),
  // Subscription
  isSubscriptionUpdating: PropTypes.bool,
  subscriptionUpdateSuccess: PropTypes.bool,
  subscriptionUpdateError: PropTypes.bool,
  updateSubscription: PropTypes.func,
  subscribe: PropTypes.func,
  unsubscribe: PropTypes.func,
  hasDealerView: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    // check for PartView Alert me preference
    userPreferencesIsLoading:
      ProfileState.selectors.getUserPreferencesIsLoading(state),
    userPreferencesIsLoadingError:
      ProfileState.selectors.getUserPreferencesIsLoadingError(state),
    userPreferences: ProfileState.selectors.getUserPreferences(state),

    // Subscription
    isSubscriptionUpdating:
      PartViewWatchedPackagesSubscriptionState.selectors.getIsSubscriptionUpdating(
        state,
      ),
    subscriptionUpdateSuccess:
      PartViewWatchedPackagesSubscriptionState.selectors.getSubscriptionUpdateSuccess(
        state,
      ),
    subscriptionUpdateError:
      PartViewWatchedPackagesSubscriptionState.selectors.getSubscriptionUpdateError(
        state,
      ),
  };
};
const mapDispatchToProps = (dispatch) => {
  const { subscribe } = PartViewWatchedPackagesSubscriptionState.actionCreators;

  return {
    pushPartViewDetailsPage: (
      trackingNumber,
      selectedFvOrgId = null,
      isDealerOrg = false,
    ) => {
      if (isDealerOrg || selectedFvOrgId) {
        dispatch({
          type: "DEALER_PARTVIEW_DETAILS",
          payload: { trackingNumber },
        });
      } else {
        dispatch({
          type: "PARTVIEW_DETAILS",
          payload: { trackingNumber },
        });
      }
    },
    // Subscription
    subscribe: (subscribee, details) =>
      dispatch(subscribe({ ...subscribee, action: "subscribe" }, details)),
    updateSubscription: (subscribee, details) =>
      dispatch(subscribe({ ...subscribee, action: "update" }, details)),
    unsubscribe: (subscribee, details) =>
      dispatch(subscribe({ ...subscribee, action: "unsubscribe" }, details)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WatchedPackages);
