import { connect } from "react-redux";

import LadsState from "shared/redux/Lads.state";
import MapState from "modules/map/MapState";
import { getActiveOrganization } from "modules/organizations/OrganizationsState";
import LocationMatchingState from "pages/administration/location-management/unresolved-location-matching/redux/UnresolvedLocationMatching.state";
import DomainDataState from "modules/domain-data/DomainDataState";
import { MapCoordinateType } from "modules/map/components/map-coordinate-definition";
import NotificationsState from "modules/notifications/NotificationsState";
import TitleState from "shared/redux/TitleState";
import ShipmentsStatusState from "pages/shipments/redux/ShipmentsState";
import SearchState from "pages/shipments/redux/ShipmentsSearchBarState";
import ShipmentSubscriptionState from "./ShipmentSubscriptionState";
import EditShipmentState from "./EditShipmentState";
import ShipmentDetailView from "./ShipmentDetailView";

function mapStateToProps(state) {
  const {
    getShipmentDetails,
    getRouteHeatmap,
    getChildShipmentDetails,
    getIsLoaded,
    getShipmentTripPlan,
    getShipmentTripPlanIsLoading,
    getIsFetchingComments,
    getComments,
    getIsBatchCommentInProgress,
    getIsBatchCommentSuccessful,
    getIsBatchCommentFailed,
  } = ShipmentsStatusState.selectors;
  const { getLadsList } = LadsState.selectors;
  const { getMapCoordinate } = MapState.selectors;

  return {
    ladsList: getLadsList(state),
    shipmentID: state.location.payload.shipment_id,
    scac: state.location.payload.carrier_scac,
    creatorShipmentID: state.location.payload.creator_shipment_id,
    shipment: getShipmentDetails(state),
    routeHeatmap: getRouteHeatmap(state),
    selectedMapCoordinate: getMapCoordinate(
      MapCoordinateType.SELECTED_COORDINATE,
    )(state),
    activeOrganization: getActiveOrganization(state),
    shipmentModes: DomainDataState.selectors.getShipmentModes(state),
    shippers: DomainDataState.selectors.getShippers(state),
    vin: state.location.payload.vin || null,
    childShipmentID: state.location.payload.child_shipment_id,
    childShipments: getChildShipmentDetails(state),
    isLoaded: getIsLoaded(state),
    tripPlan: getShipmentTripPlan(state),
    tripPlanIsLoading: getShipmentTripPlanIsLoading(state),
    isFetchingComments: getIsFetchingComments(state),
    comments: getComments(state),
    isBatchCommentInProgress: getIsBatchCommentInProgress(state),
    isBatchCommentSuccessful: getIsBatchCommentSuccessful(state),
    isBatchCommentFailed: getIsBatchCommentFailed(state),
    // Fetch report delay options
    delayOptionsData: EditShipmentState.selectors.getDelayOptionsData(state),
    editShipmentData: EditShipmentState.selectors.getEditShipmentData(state),
    // Report/Clear Carrier Delay
    reportDelayData: EditShipmentState.selectors.getReportDelayData(state),
    clearDelayData: EditShipmentState.selectors.getClearDelayData(state),
    // Alert Me
    shipmentSubscription:
      ShipmentSubscriptionState.selectors.getSubscription(state),
    isShipmentSubscriptionLoading:
      ShipmentSubscriptionState.selectors.getIsSubscriptionLoading(state),
    subscriptionRequestError:
      ShipmentSubscriptionState.selectors.getSubscriptionRequestError(state),
    isSubscriptionUpdating:
      ShipmentSubscriptionState.selectors.getIsSubscriptionUpdating(state),
    subscriptionUpdateSuccess:
      ShipmentSubscriptionState.selectors.getSubscriptionUpdateSuccess(state),
    subscriptionUpdateError:
      ShipmentSubscriptionState.selectors.getSubscriptionUpdateError(state),
  };
}

function mapDispatchToProps(dispatch) {
  const {
    fetchShipmentDetails,
    clearShipmentDetails,
    fetchShipmentDetailsFromCarrierInfo,
    fetchShipmentDetailsFromVin,
    fetchShipmentTripPlan,
    fetchRouteHeatmap,
    fetchComments,
    addComment,
    addBatchComments,
    clearBatchComments,
    cancelAddComment,
    updateComment,
    cancelUpdateComment,
    markCommentsRead,
  } = ShipmentsStatusState.actionCreators;
  const { addCoordinate, clearCoordinatesByType } = MapState.actionCreators;
  const { setShipmentWithUnresolvedLocation } =
    LocationMatchingState.actionCreators;
  const { fetchNotification } = NotificationsState.actionCreators;

  const {
    assignAsset,
    assignTrailer,
    createShipmentEvents,
    cancelShipment,
    startMobileTracking,
    stopMobileTracking,
    unassignAsset,
    setWatchShipment,
    fetchDelayOptions,
    updateShipment,
  } = EditShipmentState.actionCreators;

  return {
    setTitle: (title) => dispatch(TitleState.actionCreators.setTitle(title)),
    setSubTitle: (subTitle) =>
      dispatch(TitleState.actionCreators.setSubTitle(subTitle)),
    fetchShipmentDetails: (shipmentID) =>
      dispatch(fetchShipmentDetails(shipmentID)),
    clearShipmentDetails: () => dispatch(clearShipmentDetails()),
    fetchShipmentDetailsFromCarrierInfo: (scac, creatorShipmentID) =>
      dispatch(fetchShipmentDetailsFromCarrierInfo(scac, creatorShipmentID)),
    fetchShipmentDetailsFromVin: (scac, creatorShipmentID, vin) =>
      dispatch(fetchShipmentDetailsFromVin(scac, creatorShipmentID, vin)),
    fetchShipmentTripPlan: (shipmentId) =>
      dispatch(fetchShipmentTripPlan(shipmentId)),
    fetchRouteHeatmap: (routeID) => dispatch(fetchRouteHeatmap(routeID)),
    fetchComments: (shipmentId, pageNumber, pageSize) =>
      dispatch(fetchComments(shipmentId, pageNumber, pageSize)),
    addComment: (shipmentId, data) => dispatch(addComment(shipmentId, data)),
    addBatchComments: (data, isCsvFormat) =>
      dispatch(addBatchComments(data, isCsvFormat)),
    clearBatchComments: () => dispatch(clearBatchComments()),
    fetchNotification: () => dispatch(fetchNotification()),
    cancelAddComment: (fakeCommentId) =>
      dispatch(cancelAddComment(fakeCommentId)),
    updateComment: (shipmentId, commentId, data) =>
      dispatch(updateComment(shipmentId, commentId, data)),
    cancelUpdateComment: (commentId) =>
      dispatch(cancelUpdateComment(commentId)),
    markCommentsRead: (shipmentId, datetime) =>
      dispatch(markCommentsRead(shipmentId, datetime)),
    searchLocation: (locationName, locationID) => {
      dispatch(SearchState.actionCreators.resetSearchAndFilters());
      dispatch(SearchState.actionCreators.setSearchCategory("location"));
      dispatch(SearchState.actionCreators.setSearchText(locationName));
      dispatch(SearchState.actionCreators.searchEntities());
      dispatch({ type: "SHIPMENT_SEARCH" });
    },
    addCoordinate: (coordinateType, lat, long, time, ind) =>
      dispatch(addCoordinate(coordinateType, lat, long, time, ind)),
    clearCoordinatesByType: (coordinateTypes) =>
      dispatch(clearCoordinatesByType(coordinateTypes)),
    cancelShipment: (shipment, notes) =>
      dispatch(cancelShipment(shipment, notes, fetchShipmentDetails)),
    assignAsset: (shipment, organization, assetID, assignDate, notes) =>
      dispatch(
        assignAsset(
          shipment,
          organization,
          assetID,
          assignDate,
          notes,
          fetchShipmentDetails,
        ),
      ),
    unassignAsset: (shipment, organization, assetID) =>
      dispatch(
        unassignAsset(shipment, organization, assetID, fetchShipmentDetails),
      ),
    assignTrailer: (shipment, trailerNumber) =>
      dispatch(assignTrailer(shipment, trailerNumber, fetchShipmentDetails)),
    createShipmentEvents: (
      shipment,
      organization,
      eventData,
      notes,
      onSuccess,
    ) => {
      return dispatch(
        createShipmentEvents(
          shipment,
          organization,
          eventData,
          notes,
          onSuccess,
        ),
      );
    },
    startMobileTracking: (shipment, organization, phoneNumber, notes) =>
      dispatch(
        startMobileTracking(
          shipment,
          organization,
          phoneNumber,
          notes,
          fetchShipmentDetails,
        ),
      ),
    stopMobileTracking: (shipment, organization) =>
      dispatch(
        stopMobileTracking(shipment, organization, fetchShipmentDetails),
      ),
    pushLocationMatchingView: (locID) =>
      dispatch({
        type: "LOCATION_MATCHING",
        payload: { location_id: locID },
      }),
    pushShipmentDetailView: (type, payload) => {
      dispatch({
        type: type,
        payload: payload,
      });
    },
    setShipmentWithUnresolvedLocation: (shipment) =>
      dispatch(setShipmentWithUnresolvedLocation(shipment)),
    setWatchShipment: (shipment, watch, onRequestFinished) =>
      dispatch(setWatchShipment(shipment, watch, onRequestFinished)),
    fetchDelayOptions: () => {
      dispatch(fetchDelayOptions());
    },
    // Report Delay actions
    reportDelay: (
      shipment,
      organization,
      reasonCode,
      frozenEta,
      comments,
      importShipmentIDs,
      onSuccess,
    ) =>
      dispatch(
        EditShipmentState.actionCreators.reportDelay(
          shipment,
          organization,
          reasonCode,
          frozenEta,
          comments,
          importShipmentIDs,
          onSuccess,
          fetchShipmentDetails,
        ),
      ),
    // Clear Delay actions
    clearDelay: (shipment, organization, onSuccess) =>
      dispatch(
        EditShipmentState.actionCreators.clearDelay(
          shipment,
          organization,
          onSuccess,
          fetchShipmentDetails,
        ),
      ),

    //Alert Me
    updateSubscription: (...params) =>
      dispatch(
        ShipmentSubscriptionState.actionCreators.updateSubscription(...params),
      ),
    subscribe: (...params) =>
      dispatch(ShipmentSubscriptionState.actionCreators.subscribe(...params)),
    unsubscribe: (...params) =>
      dispatch(ShipmentSubscriptionState.actionCreators.unsubscribe(...params)),
    fetchShipmentSubscription: (...params) =>
      dispatch(
        ShipmentSubscriptionState.actionCreators.fetchSubscription(...params),
      ),

    //Clear Delay Data In Redux action
    clearReportDelayInState: () =>
      dispatch(EditShipmentState.actionCreators.clearReportDelayInState()),

    //Clear Report Shipment Event Data In Redux action
    clearReportShipmentEvents: () =>
      dispatch(EditShipmentState.actionCreators.clearReportShipmentEvents()),

    //Clear Shipment success/ failure status
    clearEditShipmentStatus: () =>
      dispatch(EditShipmentState.actionCreators.clearEditShipmentStatus()),

    updateShipment: (shipment, payload, fetchShipmentDetails) =>
      dispatch(updateShipment(shipment, payload, fetchShipmentDetails)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentDetailView);
