/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { SearchBar } from "components/search-bar/SearchBar";
import PropTypes from "prop-types";

const CriticalPartsSearchBar = ({
  searchText,
  setSearchText,
  hasCriteriaChanged,
  setHasCriteriaChanged,
}) => {
  const { t } = useTranslation("critical-parts");
  const [internalSearchText, setInternalSearchText] = useState(searchText);

  const updateSearchText = (value) => {
    setInternalSearchText(value);
    setHasCriteriaChanged(true);
  };

  const clearSearchText = () => {
    setInternalSearchText("");
  };

  const handleSearchButtonClick = () => {
    setHasCriteriaChanged(false);
    setSearchText(internalSearchText);
  };

  const searchEntities = () => {
    handleSearchButtonClick();
  };

  const typeaheadOptionsMetadata = [
    {
      itemLabelProperty: "label",
      itemValueProperty: "value",
      queryKey: "exampleQueryKey",
      label: (t) => "exampleLabel", // Temp: removed t() to avoid paying for this translation
      placeholder: (t) => t("Search Part Name or Number"),
    },
  ];

  const searchCategory = typeaheadOptionsMetadata[0];

  return (
    <div data-qa="search-bar">
      <SearchBar
        hasSearchCriteriaChanged={hasCriteriaChanged}
        isShowingFilters={false}
        toggleShowFilters={() => null}
        isShowingAdvancedSearchButton={false}
        preventSearchOnChange={false}
        searchCategory={searchCategory}
        setSearchCategory={() => null}
        resetSearchBar={clearSearchText}
        searchText={internalSearchText}
        canUserSearch={true}
        clearSavedSearch={() => null}
        setSearchText={updateSearchText}
        t={t}
        searchEntities={searchEntities}
        typeaheadOptionsMetadata={typeaheadOptionsMetadata}
        showSearchOptions={false}
      />
    </div>
  );
};

CriticalPartsSearchBar.propTypes = {
  setSearchText: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
};

export default CriticalPartsSearchBar;
