import _ from "lodash";

/**
 * value - an object of shape:
 * ```
 * {
 *   to: string,
 *   from: string,
 *   dateType: string[] // (possible values: "Scheduled", "Actual", "ETA")
 * }
 * ```
 */
export const isDateRangeValueValid = (value, dateTypeOptions) => {
  // Value is not required.
  if (_.isNil(value)) {
    return true;
  }

  const hasValidDateType =
    dateTypeOptions?.length > 0 ? value.dateType?.length > 0 : true;
  const hasValidDateValues = !_.isNil(value.to) || !_.isNil(value.from);

  return hasValidDateType && hasValidDateValues;
};
