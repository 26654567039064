/** @jsxImportSource @emotion/react */
import _ from "lodash";
import {
  localizedDateFormatter,
  localizedTimeFormatter,
} from "utils/date-time";
import { zeroMargin } from "../Milestone.Dashboard.styles";
import { RotatingExpanderCell } from "components/organisms/base-table/Cell/RotatingExpanderCell";

export const milestoneEventsTableColumns = (t) => [
  {
    Header: () => null,
    id: "expander",
    Cell: RotatingExpanderCell,
    width: 30,
    maxWidth: 30,
    disableSortBy: true,
    disableResizing: true,
    accessor: () => {
      return {
        t: t,
      };
    },
  },
  {
    id: "vin",
    accessor: "vin",
    Header: t("milestone-search:VIN"),
    minWidth: 180,
    sortColumnOverride: "VIN",
  },
  {
    id: "shipper",
    accessor: (d) => _.get(d, "customerRef[0][0].name"),
    Header: t("milestone-search:Shipper"),
    minWidth: 125,
    maxWidth: 175,
    // note: according to its diagnostic messages, the api does not allow
    // sorting on the shipper column
    disableSortBy: true,
  },
  {
    id: "statusDescription",
    accessor: "statusDescription",
    Header: t("milestone-search:Milestone Event"),
    minWidth: 125,
    maxWidth: 175,
    sortColumnOverride: "MILESTONE_EVENTS",
  },
  {
    id: "statusCode",
    accessor: "statusCode",
    Header: t("milestone-search:Milestone Status Code"),
    maxWidth: 150,
    sortColumnOverride: "MILESTONE_CODES",
  },
  {
    id: "location",
    accessor: (d) => {
      const location = _.get(d, "shipperLocation[0]");
      if (_.isEmpty(location)) {
        return <div />;
      }
      const { name, address, address2, city, state, postal_code, code } =
        location;
      return (
        <div>
          <p css={[zeroMargin]}>{`${name} (${code})`}</p>
          <p css={[zeroMargin]}>{`${address} ${address2}`}</p>
          <p css={[zeroMargin]}>{`${city}, ${state} ${postal_code}`}</p>
        </div>
      );
    },
    Header: t("milestone-search:Location"),
    minWidth: 200,
    sortColumnOverride: "MILESTONE_LOCATIONS",
  },
  {
    id: "timestamp",
    accessor: (d) => (
      <div>
        <p data-qa="text-date" css={[zeroMargin]}>
          {localizedDateFormatter(d.ts)}
        </p>
        <p data-qa="text-time" css={[zeroMargin]}>
          {localizedTimeFormatter(d.ts)}
        </p>
      </div>
    ),
    Header: t("milestone-search:Timestamp"),
    minWidth: 125,
    maxWidth: 175,
    sortColumnOverride: "MILESTONE_TIME",
  },
  {
    id: "comments",
    accessor: "comments",
    Header: t("milestone-search:Milestone Comments and References"),
    minWidth: 300,
    sortColumnOverride: "MILESTONE_COMMENTS",
  },
];

// Return an object that can be used to look up the column by a key. Sometimes
// the key is the column's accessor property, but sometimes the accessor is a
// function instead of a simple key. In those cases the key is the id.
export const keyByIdOrAccessor = (columns) => {
  const result = {};
  columns.forEach((col) => {
    if (col.id) {
      result[col.id] = col;
    }
  });
  return result;
};
