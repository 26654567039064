/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

import {MadChicletCSS} from "components/chiclets";
import {MediaQueries} from "components/responsive";
import {FontSize} from "components/atoms/enums";
import {PanelGroup} from "components/molecules/PanelGroup.molecule";
import {ShowMoreList} from "components/molecules/ShowMoreList.molecule";
import DetailItem
  from "modules/shipment-detail/shipment-detail-styled-components/DetailItem";
import {
  getLoadedStatusDisplayValue
} from "modules/shipment-detail/ShipmentUtils";
import {
  getColorForExceptionFromName
} from "pages/shipments/utils/exception.utils";
import {FlexColDiv, FlexRowDiv} from "styles/container-elements";
import Colors from "styles/colors";
import {
  useShipmentTranslation,
  useShipmentExceptionTranslation,
} from "shared/hooks/useShipmentTranslation";
import {useEtaTimeCategory} from "pages/shipments/utils/exception.utils";
import {ShipmentException} from "../../../shared/constants/shipments.const";

const shipmentDetailPanelStyles = {
  detailsGroupContentContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    [MediaQueries.mediumAndUp]: {
      flexFlow: "row nowrap",
    },
  },
  detailFieldContainer: {
    [MediaQueries.mediumAndUp]: {
      flex: "2 0",
    },
  },
  separator: {
    height: 0,
    borderTop: "1px solid #aaa",
    marginTop: "1em",
    marginBottom: "1em",
  },
  exceptionsContainer: {
    [MediaQueries.mediumAndUp]: {
      flex: "1 0",
      marginLeft: "1em",
      alignSelf: "flex-start",
    },
  },
  detailGroup: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridAutoFlow: "row",
    gap: "1em",
    flexWrap: "wrap",
  },
  detailItem: {
    flex: "1 0 45%",
  },
  exceptionStatus: {
    textAlign: "center",
    background: Colors.background.DARK_GRAY,
    borderRadius: "4px",
    padding: "1em",
    span: {color: "white"},
  },
};

const ShipmentDetailsCollapsible = ({
                                      extraHeaderContent,
                                      shipmentId,
                                      assetId,
                                      mode,
                                      showMadChiclet = false,
                                      status,
                                      shipmentType,
                                      shipperName,
                                      carrierName,
                                      carrierScac,
                                      currentRoad,
                                      trailerAndEquipmentNumbers,
                                      serviceCode,
                                      showException = true,
                                      exception,
                                      isBackOrder,
                                      isOffRoute,
                                      railTrainId,
                                      loadedStatus,
                                      etaTimeCategory,
                                    }) => {
  const {t} = useTranslation("shipment-details");

  //translate shipment mode and Exceptions
  const {getTranslatedMode} = useShipmentTranslation();
  const {getTranslatedShipmentException} = useShipmentExceptionTranslation();
  const etaTimeCategoryVals = useEtaTimeCategory(etaTimeCategory, mode);

  const loadedStatusDisplay = getLoadedStatusDisplayValue(loadedStatus, t);

  // Exception Text
  /* H1-1999 an exception None was added to multimodal End-to-End active leg with no exception, display as N/A */
  const exceptionText = exception && exception !== "None" ? exception : "N/A";

  /* H1-1999 Change for multimodal End-to-End to include "(Leg n)" in display, which affect the color check.
     added a regex to filter that out for the lookup */
  const exceptionLookup = exceptionText.includes("Leg")
    ? exceptionText.replace(/\s\(Leg\s.*\)/g, "")
    : exceptionText;

  // Exception Color and Styles
  const exceptionColor = exception
    ? getColorForExceptionFromName(exceptionLookup)
    : Colors.highlight.GREEN;

  const exceptionOutlineStyles = {
    ...shipmentDetailPanelStyles.exceptionStatus,
    background: exceptionColor,
  };

  const etaTimeMeterOutlineStyles = {
    ...shipmentDetailPanelStyles.exceptionStatus,
    background: etaTimeCategoryVals?.color,
    marginTop: 10,
  };

  const backorderExceptionOutlineStyles = {
    ...shipmentDetailPanelStyles.exceptionStatus,
    background: Colors.exceptions.BACKORDER,
    marginTop: 10,
  };

  const offRouteExceptionOutlineStyles = {
    ...shipmentDetailPanelStyles.exceptionStatus,
    background: Colors.highlight.YELLOW,
    marginTop: 10,
  };

  const showAssetId = mode !== "Parcel";
  const showServiceCode = mode === "Parcel";

  return (
    <PanelGroup collapsible={true} style={{margin: "1em"}}>
      <PanelGroup.Header
        titleStyle={{minHeight: "34px"}}
        title={t("Details")}
        titleSize={FontSize.size18}
        rightContent={extraHeaderContent ? extraHeaderContent : null}
      />
      <PanelGroup.Content>
        <div css={shipmentDetailPanelStyles.detailsGroupContentContainer}>
          {/* Details */}
          <FlexColDiv css={shipmentDetailPanelStyles.detailFieldContainer}>
            {/* Group 1 */}
            <FlexRowDiv css={shipmentDetailPanelStyles.detailGroup}>
              <DetailItem
                style={shipmentDetailPanelStyles.detailItem}
                label={t("shipment-details:Shipment ID")}
                data-qa="text-shipment-id"
              >
                {shipmentId ? shipmentId : t("N/A")}
              </DetailItem>
              {assetId && showAssetId ? (
                <DetailItem
                  style={shipmentDetailPanelStyles.detailItem}
                  label={t("shipment-details:Asset ID")}
                  data-qa="text-asset-id"
                >
                  {assetId}
                </DetailItem>
              ) : null}
              <DetailItem
                style={shipmentDetailPanelStyles.detailItem}
                label={t("shipment-details:Mode")}
                data-qa="text-asset-mode"
              >
                <FlexRowDiv css={{alignItems: "center"}}>
                  {showMadChiclet ? (
                    <MadChicletCSS
                      shipmentMode={mode}
                      stopMode={mode}
                      width={25}
                      height={25}
                      style={{marginRight: 10, border: 0}}
                    />
                  ) : null}
                  {getTranslatedMode(mode)}
                </FlexRowDiv>
              </DetailItem>
              <DetailItem
                style={shipmentDetailPanelStyles.detailItem}
                label={t("shipment-details:Status")}
                data-qa="text-asset-status"
              >
                {status}
              </DetailItem>
              {shipmentType && shipmentType === "Rack Return" ? (
                <DetailItem
                  style={shipmentDetailPanelStyles.detailItem}
                  label={t("shipment-details:Shipment Type")}
                  data-qa="text-shipment-type"
                >
                  {t("shipment-details:Rack Return")}
                </DetailItem>
              ) : null}
            </FlexRowDiv>

            {/* Separator */}
            <div css={shipmentDetailPanelStyles.separator}/>

            {/* Group 2 */}
            <div css={shipmentDetailPanelStyles.detailGroup}>
              {shipperName ? (
                <DetailItem
                  style={shipmentDetailPanelStyles.detailItem}
                  label={t("shipment-details:Shipper")}
                  data-qa="text-shipper"
                >
                  {shipperName}
                </DetailItem>
              ) : null}
              {carrierName ? (
                <DetailItem
                  style={shipmentDetailPanelStyles.detailItem}
                  label={t("shipment-details:Carrier")}
                  data-qa="text-carrier-name"
                >
                  {carrierName}
                </DetailItem>
              ) : null}
              {carrierScac ? (
                <DetailItem
                  style={shipmentDetailPanelStyles.detailItem}
                  label={t("shipment-details:SCAC")}
                  data-qa="text-carrier-scac"
                >
                  {carrierScac}
                </DetailItem>
              ) : null}
              {currentRoad ? (
                <DetailItem
                  style={shipmentDetailPanelStyles.detailItem}
                  label={t("shipment-details:Current Road")}
                  data-qa="text-current-road"
                >
                  {currentRoad}
                </DetailItem>
              ) : null}
              {serviceCode && showServiceCode ? (
                <DetailItem
                  style={shipmentDetailPanelStyles.detailItem}
                  label={t("shipment-details:Service Code")}
                  data-qa="text-service-code"
                >
                  {serviceCode}
                </DetailItem>
              ) : null}

              <DetailItem
                style={shipmentDetailPanelStyles.detailItem}
                label={t("shipment-details:Trailer/Equipment #")}
                tooltipText={t(
                  "shipment-details:This may be the Trailer, Equipment or Container number.",
                )}
                data-qa="text-trailer-and-equipment-numbers"
              >
                {trailerAndEquipmentNumbers?.length > 0 ? (
                  <ShowMoreList
                    title={t("shipment-details:Trailer/Equipment #")}
                    list={trailerAndEquipmentNumbers}
                    visibleItemCount={2}
                    listItemStyle={{fontSize: "inherit"}}
                  />
                ) : (
                  "-"
                )}
              </DetailItem>

              {/*
                H1-2101: show extra rail info
              */}
              {railTrainId ? (
                <DetailItem
                  style={shipmentDetailPanelStyles.detailItem}
                  label={t("shipment-details:Train ID")}
                  data-qa="text-shipment-rail-id"
                >
                  {railTrainId}
                </DetailItem>
              ) : null}
              {loadedStatus ? (
                <DetailItem
                  style={shipmentDetailPanelStyles.detailItem}
                  label={t("shipment-details:Loaded Status")}
                  data-qa="text-shipment-loaded-status"
                >
                  {loadedStatusDisplay}
                </DetailItem>
              ) : null}
            </div>
          </FlexColDiv>

          {/* Active Exceptions */}
          {showException ? (
            <FlexColDiv css={shipmentDetailPanelStyles.exceptionsContainer}>
              <DetailItem
                style={exceptionOutlineStyles}
                label={t("shipment-details:Active Exceptions")}
                data-qa="text-active-exceptions"
              >
                {/* note: despite "active exceptions" being plural above, what's in the
                  shipment record appears to be a single bare string. So our display
                  component only handles a single exception.
                */}
                {getTranslatedShipmentException(exceptionText)}
              </DetailItem>

              {etaTimeCategoryVals ? (
                <DetailItem
                  style={etaTimeMeterOutlineStyles}
                  label={
                    t("shipment-details:ETA") +
                    " - " +
                    etaTimeCategoryVals.label
                  }
                  data-qa="text-eta-duration"
                >
                  {etaTimeCategoryVals.formattedDuration}
                </DetailItem>
              ) : null}

              {isOffRoute ? (
                <DetailItem
                  style={offRouteExceptionOutlineStyles}
                  data-qa="text-off-route-exception"
                >
                  {getTranslatedShipmentException(ShipmentException.OFF_ROUTE)}
                </DetailItem>
              ) : null}

              {isBackOrder ? (
                <DetailItem
                  style={backorderExceptionOutlineStyles}
                  data-qa="text-back-order-exception"
                >
                  {getTranslatedShipmentException(ShipmentException.BACKORDER)}
                </DetailItem>
              ) : null}
            </FlexColDiv>
          ) : null}
        </div>
      </PanelGroup.Content>
    </PanelGroup>
  );
};

ShipmentDetailsCollapsible.propTypes = {
  extraHeaderContent: PropTypes.object,
  shipmentId: PropTypes.string,
  assetId: PropTypes.string,
  mode: PropTypes.string,
  showMadChiclet: PropTypes.bool,
  status: PropTypes.string,
  shipmentType: PropTypes.string,
  shipperName: PropTypes.string,
  carrierName: PropTypes.string,
  carrierScac: PropTypes.string,
  currentRoad: PropTypes.string,
  trailerAndEquipmentNumbers: PropTypes.array,
  serviceCode: PropTypes.string,
  showException: PropTypes.bool,
  exception: PropTypes.string,
  isBackOrder: PropTypes.bool,
  isOffRoute: PropTypes.bool,
  railTrainId: PropTypes.string,
  loadedStatus: PropTypes.string,
  etaTimeCategory: PropTypes.shape({
    key: PropTypes.string,
    status: PropTypes.string,
    difference: PropTypes.number,
  }),
};

export default ShipmentDetailsCollapsible;
