/** @jsxImportSource @emotion/react */
import { connect } from "react-redux";
import { CriticalPartsFilterSection } from "./CriticalParts.FilterSection";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import CriticalPartsState from "pages/critical-parts/redux/CriticalParts.state";
const mapStateToProps = (state) => {
  return {
    auth: getAuthorization(state),
    filterValues: CriticalPartsState.selectors.getFiltersValue(state),
    resetFilters: CriticalPartsState.selectors.getResetFilters(state),
    hasCriteriaChanged:
      CriticalPartsState.selectors.getHasCriteriaChanged(state),
  };
};
const { setFiltersValue, setResetFilters, setHasCriteriaChanged } =
  CriticalPartsState.actionCreators;
const actionCreators = {
  setFiltersValue,
  setResetFilters,
  setHasCriteriaChanged,
};

export default connect(
  mapStateToProps,
  actionCreators,
)(CriticalPartsFilterSection);
