import apiUrl from "api-url";
import { buildSubscriptionState } from "shared/redux/SubscriptionStateBuilder";
import { ProfileState } from "pages/profile/redux/ProfileState";

export const PartViewWatchedPackagesSubscriptionState = buildSubscriptionState({
  topic: "PartViewWatchedPackages",
  systemType: "partview",
  subscriptionType: "update",
  getUrl: () => apiUrl("/partview/api/watched-package-subscription"),
  getSubscribeeId: () => "",
  getAdditionalRequestConfig: (_ignored_requestType, subscribee) => ({
    data: {
      action: subscribee.action,
      owner_solution_id: "FV_PARTVIEW",
    },
  }),
  onChange: (_ignored_requestType, data, state) => {
    const userPreferences = ProfileState.selectors.getUserPreferences(state);

    const preferences = {
      ...userPreferences,
      defaultCommunication: {
        ...userPreferences?.defaultCommunication,
        partviewAlertMe: {
          enableEmail: data.enable_email,
          enableSms: data.enable_sms,
          enablePlatform: data.enable_platform,
          emailAddress: data.recipient_email,
          phoneNumber: data.mobile_number,
        },
      },
    };

    return ProfileState.actionCreators.setPreferences(preferences);
  },
  updateUrl: false,
  fetchSubscriptionOnSuccess: false,
});
