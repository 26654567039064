import { CurrentLocationType } from "api/consts";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { logWarningForMissingTranslation } from "utils/log-warning.utils";

const Lads = {
  PLANT: "Plant",
  UPFITTER: "Upfitter",
  PORT: "Port",
  DISTRIBUTION: "Distribution",
};

export const useInventoryTranslation = () => {
  const { t } = useTranslation("inventory-view");

  const getTranslatedLabelsPipelineGraph = useCallback(
    (option: string) => {
      switch (option.replace(/ /g, "").toLowerCase()) {
        case CurrentLocationType.AT_LOCATION:
          return t("inventory-view:At Location");
        case CurrentLocationType.ON_RAIL:
          return t("inventory-view:On Rail");
        case CurrentLocationType.ON_THE_ROAD:
          return t("inventory-view:On the Road");
        case CurrentLocationType.ON_THE_WATER:
          return t("inventory-view:On the Water");
        case Lads.PLANT:
          return t("lads:At Plant");
        case Lads.UPFITTER:
          return t("lads:At Upfitter");
        case Lads.PORT:
          return t("lads:At Port");
        case Lads.DISTRIBUTION:
          return t("lads:At Distribution");
        default: {
          logWarningForMissingTranslation(option);
          return option;
        }
      }
    },
    [t],
  );

  return { getTranslatedLabelsPipelineGraph };
};
