import _ from "lodash";
import { produce } from "immer";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import chainReducers from "vendor/signal-utils/chain-reducers";
import { milestonesUrl } from "../search/MilestoneDashboardSearchBarState";
import { axiosConfigHeaders } from "utils/fetch-utils";

const STORE_MOUNT_POINT = "statusUpdateDetails";

const REQUEST_UPDATE_DETAILS = `${STORE_MOUNT_POINT}/REQUEST_UPDATE_DETAILS`;
const RECEIVE_UPDATE_DETAILS = `${STORE_MOUNT_POINT}/RECEIVE_UPDATE_DETAILS`;

const duck = buildFetchDuck(STORE_MOUNT_POINT);

// action creators

const fetchStatusUpdateDetails = (statusUpdateId) => (dispatch) => {
  const url = milestonesUrl(null, null, null, statusUpdateId);
  dispatch({ type: REQUEST_UPDATE_DETAILS, payload: { statusUpdateId } });

  dispatch(
    duck.fetch(url, axiosConfigHeaders(), (responseData) => {
      dispatch({
        type: RECEIVE_UPDATE_DETAILS,
        payload: { statusUpdateId, data: responseData },
      });
      return responseData;
    }),
  );
};

// selectors

const getAllStatusUpdateDetails = (state) =>
  _.get(state, `${STORE_MOUNT_POINT}.index`, {});

// Note: this isn't a selector until you call it with an id first
const getStatusUpdateDetailsForId = (statusUpdateId) => (state) => {
  const allUpdates = getAllStatusUpdateDetails(state);
  return _.get(allUpdates, statusUpdateId, {});
};

// reducer

const initialState = { index: {} };

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case REQUEST_UPDATE_DETAILS: {
        const { statusUpdateId } = action.payload;
        draft.index[statusUpdateId] = { loading: true };
        break;
      }
      case RECEIVE_UPDATE_DETAILS: {
        const { statusUpdateId, data } = action.payload;
        draft.index[statusUpdateId] = { loading: false, data };
        break;
      }
      default:
        return draft;
    }
  });

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: { fetchStatusUpdateDetails },
  selectors: {
    getAllStatusUpdateDetails,
    getStatusUpdateDetailsForId,
  },
  reducer: chainReducers([reducer, duck.reducer]),
};
