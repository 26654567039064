/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FilterSection } from "components/search-bar/FilterSection";
import { FILTERS } from "./CriticalParts.filterDefs";
import _ from "lodash";

export const CriticalPartsFilterSection = ({
  auth,
  filterValues,
  hasCriteriaChanged,
  resetFilters,
  setResetFilters,
  setFiltersValue,
  setHasCriteriaChanged,
}) => {
  const [internalFilterParams, setInternalFilterParams] = useState({
    fuCodes: null,
  });

  useEffect(() => {
    if (resetFilters) {
      setHasCriteriaChanged(true);
      setInternalFilterParams({
        fuCodes: null,
      });
      setFiltersValue({
        fuCodes: null,
      });
      setResetFilters(false);
    } else if (_.isEmpty(internalFilterParams) && !_.isEmpty(filterValues)) {
      setInternalFilterParams(filterValues);
    }
  }, [
    filterValues,
    internalFilterParams,
    resetFilters,
    setFiltersValue,
    setResetFilters,
    setHasCriteriaChanged,
  ]);

  const updateSearchFilter = (key, value) => {
    setHasCriteriaChanged(true);
    setInternalFilterParams((prev) => ({ ...prev, [key]: value }));
  };

  const performSearch = () => {
    setFiltersValue(internalFilterParams);
  };

  return (
    <FilterSection
      auth={auth}
      show={true}
      searchFilters={internalFilterParams}
      searchEntities={performSearch}
      clearSearchFilter={updateSearchFilter}
      clearSavedSearch={() => null}
      setSearchFilter={updateSearchFilter}
      filtersMetadata={FILTERS}
      hasSearchCriteriaChanged={hasCriteriaChanged}
    />
  );
};

CriticalPartsFilterSection.propTypes = {
  auth: PropTypes.object,
  resetFilters: PropTypes.bool,
  setResetFilters: PropTypes.func,
  filterValues: PropTypes.object,
  setFiltersValue: PropTypes.func,
};
