import _ from "lodash";
import { getMilestoneEventValue } from "../utils/CreateMilestone.utils";

export const DependencyType = {
  MILESTONE_EVENT: "MILESTONE_EVENT",
};

export const ItemAttribute = {
  DEFAULT_VALUE: "defaultValue",
  DISPLAYED: "displayed",
  DISABLE_UNTIL_VALUE: "disableUntilValue",
};

export const FieldKey = {
  VEHICLE_RECEIVED: "VEHICLE_RECEIVED",
  VIN: "VIN",
  MILESTONE_EVENT: "MILESTONE_EVENT",
  MILESTONE_STATUS_CODE: "MILESTONE_STATUS_CODE",
  VMACS_CODE: "VMACS_CODE",
  REASON_CODE: "REASON_CODE",
  YARD_CODE: "YARD_CODE",
  COMPOUND_CODE: "COMPOUND_CODE",
  BAY_LOCATION: "BAY_LOCATION",
  STATUS_DATE_TIME: "STATUS_DATE_TIME",
  STATUS_LOCATION: "STATUS_LOCATION",
  MS1_LOCATION_CODE: "MS1_LOCATION_CODE",
  TIMEZONE: "TIMEZONE",
  BILL_OF_LADING_NUMBER: "BILL_OF_LADING_NUMBER",
  NEXT_CARRIER: "NEXT_CARRIER",
  TRAILER_NUMBER: "TRAILER_NUMBER",
  SHIP_TRIP_ID: "SHIP_TRIP_ID",
  LANE_TYPE: "LANE_TYPE",
  BOOKING_NUMBER: "BOOKING_NUMBER",
  VOYAGE_NUMBER: "VOYAGE_NUMBER",
  VESSEL_NAME: "VESSEL_NAME",
  PORT_OF_LOADING: "PORT_OF_LOADING",
  PORT_OF_DISCHARGE: "PORT_OF_DISCHARGE",
  WAYBILL_NUMBER: "WAYBILL_NUMBER",
  RAIL_CAR_NUMBER: "RAIL_CAR_NUMBER",
  SHIP_TO: "SHIP_TO",
  SHIP_FROM: "SHIP_FROM",
  COMMENTS: "COMMENTS",
  PLANT_MILESTONE_UPLOAD: "PLANT_MILESTONE_UPLOAD",
  CSV_UPLOAD: "CSV_UPLOAD",
  AIAG_CODE: "AIAG_CODE",
  TRANSPORTATION_TYPE: "TRANSPORTATION_TYPE",
  EQUIPMENT_NUMBER: "EQUIPMENT_NUMBER",
  CUSTOMS_REFERENCE_NUMBER: "CUSTOMS_REFERENCE_NUMBER",
  SENDER_ID: "SENDER_ID",
  CODE: "CODE",
  SCAC: "SCAC",
  USER_ROLE: "USER_ROLE",
  EQUIPMENT_TYPE: "EQUIPMENT_TYPE",
  EXCEPTION_ROUTING_CODE: "EXCEPTION_ROUTING_CODE",
  ESTIMATED_CLEARANCE_DATE: "ESTIMATED_CLEARANCE_DATE",
};

// set the order of the Fields as to be displayed in CSV file
const setOrderFields = (fields) => {
  return _.sortBy(fields, [
    function (o) {
      return o.sequence;
    },
  ]);
};

export const FieldType = {
  UPLOAD: "upload",
  LIST: "list",
  SELECT: "select",
  INPUT: "input",
  DATETIME: "datetime",
  SHIPPER_LOCATION_SELECT: "shipperlocationselect",
  TEXTAREA: "textarea",
  HIDDEN: "hidden",
};

export const EventTypeOptions = {
  SINGLE: "Single",
  MULTIPLE: "Multiple",
};

export const getEventTypeOptions = () => {
  return Object.keys(EventTypeOptions).map((key) => {
    return {
      label: EventTypeOptions[key],
      value: EventTypeOptions[key],
    };
  });
};

export const BatchMilestoneEventSelect = {
  key: FieldKey.MILESTONE_EVENT,
  label: "Milestone Event",
  type: FieldType.SELECT,
  required: true,
  widthOverride: "23%",
  displayed: false,
};

// Paired with Batch Milestone Event, not editable
export const BatchMilestoneStatusCodeInput = {
  key: FieldKey.MILESTONE_STATUS_CODE,
  label: "Milestone Status Code",
  type: FieldType.INPUT,
  editable: false,
  dependency: DependencyType.MILESTONE_EVENT,
  widthOverride: "23%",
  valueFieldName: "code",
};

// Paired with Batch Milestone Event, not editable
export const BatchVMACSCodeInput = {
  key: FieldKey.VMACS_CODE,
  label: "VMACS Code",
  type: FieldType.INPUT,
  editable: false,
  dependency: DependencyType.MILESTONE_EVENT,
  widthOverride: "23%",
  valueFieldName: "vmacsCode",
};

export const CsvBulkUpload = {
  key: FieldKey.CSV_UPLOAD,
  label: "CSV Batch Upload",
  type: FieldType.UPLOAD,
  uploadInputPlaceholder: "Upload Selected Milestone Data (.csv)",
  required: true,
  disableUntilValue: FieldKey.MILESTONE_EVENT,
  widthOverride: "75%",
};

const StatusLocationSelect = {
  key: FieldKey.STATUS_LOCATION,
  label: "Status Location",
  csvHeader: "Status Location",
  type: FieldType.SHIPPER_LOCATION_SELECT,
  options: null,
  required: true,
  sampleCsvData: [41, 42],
  sequence: 6,
};

const Ms1LocationSelect = {
  key: FieldKey.MS1_LOCATION_CODE,
  label: "Status Location",
  csvHeader: "Location Code",
  type: FieldType.SHIPPER_LOCATION_SELECT,
  options: null,
  required: true,
  qualifier: "ms1LocationCode",
  sequence: 7,
};

const VinListInput = {
  key: FieldKey.VIN,
  label: "VIN List (if multiple VINs, line separate)",
  csvHeader: "VIN",
  type: FieldType.LIST,
  sampleCsvData: ["VINSAMPLE123A", "VINSAMPLE123B"],
  sequence: 1,
};

const VehicleReceivedUpload = {
  key: FieldKey.VEHICLE_RECEIVED,
  label: "Vehicle Received (R1)",
  type: FieldType.UPLOAD,
  csvTemplateOverride: "/sample-files/template-location-update.csv",
  csvExpectedColumnsOverride:
    "VIN, Update Date/Time (YYYY-MM-ddThh:mm:ss), Timezone, Location Code, Yard, Bay",
  uploadInputPlaceholder: "Upload VIN Location Data (.csv)",
  required: true,
  sequence: 37,
};

const MilestoneEventSelect = {
  key: FieldKey.MILESTONE_EVENT,
  label: "Milestone Event",
  type: FieldType.SELECT,
  required: true,
};

// Paired with Milestone Event, not editable
const MilestoneStatusCodeInput = {
  key: FieldKey.MILESTONE_STATUS_CODE,
  label: "Milestone Status Code",
  type: FieldType.INPUT,
  editable: false,
  dependency: DependencyType.MILESTONE_EVENT,
  valueFieldName: "code",
  required: true,
  sampleCsvData: ["Milestone Event Code 1", "Milestone Event Code 2"],
  sequence: 2,
};

// Paired with Milestone Event, not editable
const VMACSCodeInput = {
  key: FieldKey.VMACS_CODE,
  label: "VMACS Code",
  type: FieldType.INPUT,
  editable: false,
  dependency: DependencyType.MILESTONE_EVENT,
  valueFieldName: "vmacsCode",
  qualifier: "vmacsCode",
  required: true,
  sampleCsvData: ["VMACS Code 1", "VMACS Code 2"],
  sequence: 3,
};

// AIAG Code - Freeform:
const AiagCodeInput = {
  key: FieldKey.AIAG_CODE,
  label: "AIAG Code",
  csvHeader: "AIAG Code",
  type: FieldType.INPUT,
  required: false,
  qualifier: "aiagCode",
  sampleCsvData: ["Sample AIAG Code 1", "Sample AIAG Code 2"],
  sequence: 25,
};

// Transportation Type:
const TransportationTypeInput = {
  key: FieldKey.TRANSPORTATION_TYPE,
  label: "Transportation Type",
  csvHeader: "Transportation Type",
  type: FieldType.INPUT,
  required: false,
  qualifier: "transportationType",
  sampleCsvData: [
    "Sample Transportation Type 1",
    "Sample Transportation Type 2",
  ],
  sequence: 26,
};

// Equipment Number:
const EquipmentNumberInput = {
  key: FieldKey.EQUIPMENT_NUMBER,
  label: "Equipment Number",
  csvHeader: "Equipment Number",
  type: FieldType.INPUT,
  required: false,
  qualifier: "equipmentNumber",
  sampleCsvData: [2122, 2212],
  sequence: 27,
};

// Customer Reference Number:
const CustomsReferenceNumberInput = {
  key: FieldKey.CUSTOMS_REFERENCE_NUMBER,
  label: "Customer Reference Number",
  csvHeader: "Customer Reference Number",
  type: FieldType.INPUT,
  qualifier: "customsReferenceNumber",
  sampleCsvData: [1127, 7211],
  sequence: 28,
};

// Options change based on Milestone selected
const ReasonCodeSelect = {
  key: FieldKey.REASON_CODE,
  label: "Reason Code",
  csvHeader: "Reason Code",
  type: FieldType.INPUT,
  required: false,
  qualifier: "milestoneReasonCode",
  sampleCsvData: ["BK", "AK"],
  sequence: 14,
};

const YardCodeInput = {
  key: FieldKey.YARD_CODE,
  label: "Yard Code",
  csvHeader: "Yard Code",
  type: FieldType.INPUT,
  qualifier: "milestoneYardLocation",
  sampleCsvData: ["Sample Yard Code 1", "Sample Yard Code 2"],
  sequence: 9,
};

const CompoundCodeInput = {
  key: FieldKey.COMPOUND_CODE,
  label: "Compound Code",
  csvHeader: "Compound Code",
  type: FieldType.INPUT,
  editable: false,
  required: true,
  dependency: FieldKey.STATUS_LOCATION,
  valueFieldName: "code",
  qualifier: "compoundCode",
  sampleCsvData: ["Compound Code 1", "Compound Code 2"],
  sequence: 8,
};

const BayLocationInput = {
  key: FieldKey.BAY_LOCATION,
  label: "Bay Location",
  csvHeader: "Bay Location",
  type: FieldType.INPUT,
  qualifier: "milestoneBayLocation",
  sampleCsvData: ["Sample Bay Location 1", "Sample Bay Location 2"],
  sequence: 15,
};

const StatusDateTimePicker = {
  key: FieldKey.STATUS_DATE_TIME,
  label: "Status Date/Time",
  csvHeader: "Status Date/Time (YYYY-MM-ddThh:mm:ss)",
  type: FieldType.DATETIME,
  required: true,
  sampleCsvData: ["2021-03-04T08:12:56", "2021-03-04T08:12:45"],
  sequence: 4,
};

const MilestoneDateTimePicker = {
  key: FieldKey.STATUS_DATE_TIME,
  label: "Milestone Date/Time",
  csvHeader: "Milestone Date/Time (YYYY-MM-ddThh:mm:ss)",
  type: FieldType.DATETIME,
  required: true,
  sampleCsvData: [],
  sequence: 4,
};

const UpdateDateTimePicker = {
  key: FieldKey.STATUS_DATE_TIME,
  label: "Update Date/Time",
  csvHeader: "Update Date/Time (YYYY-MM-ddThh:mm:ss)",
  type: FieldType.DATETIME,
  required: true,
  sequence: 4,
};

const TimezoneInput = {
  key: FieldKey.TIMEZONE,
  label: "Timezone",
  csvHeader: "Timezone",
  required: false,
  type: FieldType.HIDDEN,
  sampleCsvData: ["EST", "CST"],
  sequence: 5,
};

const BillOfLadingInput = {
  key: FieldKey.BILL_OF_LADING_NUMBER,
  label: "Bill of Lading Number",
  csvHeader: "Bill of Lading Number",
  type: FieldType.INPUT,
  qualifier: "billOfLading",
  sampleCsvData: ["Sample BOL", "Sample BOL"],
  sequence: 21,
};

const NextCarrierInput = {
  key: FieldKey.NEXT_CARRIER,
  label: "Next Carrier",
  csvHeader: "Next Carrier",
  type: FieldType.INPUT,
  qualifier: "nextCarrier",
  sampleCsvData: ["Sample Next Carrier 1", "Sample Next Carrier 2"],
  sequence: 16,
};

const TrailerInput = {
  key: FieldKey.TRAILER_NUMBER,
  label: "Trailer Number",
  csvHeader: "Trailer Number",
  type: FieldType.INPUT,
  qualifier: "trailerNumber",
  sampleCsvData: ["Sample Trailer Number", "Sample Trailer Number"],
  sequence: 22,
};

const ShipTripIdInput = {
  key: FieldKey.SHIP_TRIP_ID,
  label: "Ship ID/Trip ID",
  csvHeader: "Ship ID/Trip ID",
  type: FieldType.INPUT,
  qualifier: "shipmentId",
  sampleCsvData: ["Sample Ship ID/Trip ID 1", "Sample Ship ID/Trip ID 2"],
  sequence: 10,
};

const LaneTypeSelect = {
  key: FieldKey.LANE_TYPE,
  label: "Lane Type",
  csvHeader: "Lane Type",
  type: FieldType.SELECT,
  options: [
    { value: "C2C", label: "Compound to Compound" },
    { value: "C2Y", label: "Compound to Dealer" },
  ],
  qualifier: "laneType",
  sampleCsvData: ["C", "D"],
  sequence: 11,
};

const BookingInput = {
  key: FieldKey.BOOKING_NUMBER,
  label: "Booking Number",
  csvHeader: "Booking Number",
  type: FieldType.INPUT,
  qualifier: "shipmentId",
};

const VoyageInput = {
  key: FieldKey.VOYAGE_NUMBER,
  label: "Voyage Number",
  csvHeader: "Voyage Number",
  type: FieldType.INPUT,
  qualifier: "voyageNumber",
  sampleCsvData: ["Sample Voyage Number 1", "Sample Voyage Number 2"],
  sequence: 23,
};

const VesselInput = {
  key: FieldKey.VESSEL_NAME,
  label: "Vessel Name",
  csvHeader: "Vessel Name",
  type: FieldType.INPUT,
  qualifier: "vesselName",
  sampleCsvData: ["Sample Vessal Name", "Sample Vessal Name"],
  sequence: 24,
};

const PortOfLoadingInput = {
  key: FieldKey.PORT_OF_LOADING,
  label: "Port of Loading",
  csvHeader: "Port of Loading",
  type: FieldType.INPUT,
  qualifier: "shipmentOriginCode",
  sampleCsvData: ["Sample Port of Loading 1", "Sample Port of Loading 2"],
  sequence: 18,
};

const PortOfDischargeInput = {
  key: FieldKey.PORT_OF_DISCHARGE,
  label: "Port of Discharge",
  csvHeader: "Port of Discharge",
  type: FieldType.INPUT,
  qualifier: "shipmentDestinationCode",
  sampleCsvData: ["Sample Port of Discharge 1", "Sample Port of Discharge 2"],
  sequence: 19,
};

const WaybillInput = {
  key: FieldKey.WAYBILL_NUMBER,
  label: "Waybill Number",
  csvHeader: "Waybill Number",
  type: FieldType.INPUT,
  qualifier: "waybillNumber",
  sampleCsvData: ["Sample Waybill Number 1", "Sample Waybill Number 2"],
  sequence: 17,
};

const RailCarInput = {
  key: FieldKey.RAIL_CAR_NUMBER,
  label: "Rail Car Number",
  csvHeader: "Rail Car Number",
  type: FieldType.INPUT,
  required: false,
  qualifier: "railCarNumber",
  sampleCsvData: ["Sample Rail Car Number 1", "Sample Rail Car Number 2"],
  sequence: 20,
};

// Locations from customer/shipper org
const ShipToInput = {
  key: FieldKey.SHIP_TO,
  label: "Ship To",
  csvHeader: "Ship To",
  type: FieldType.SHIPPER_LOCATION_SELECT,
  options: null,
  qualifier: "shipmentDestinationCode",
  sampleCsvData: [34, 41],
  sequence: 12,
};

const ShipFromInput = {
  key: FieldKey.SHIP_FROM,
  label: "Ship From",
  csvHeader: "Ship From",
  type: FieldType.SHIPPER_LOCATION_SELECT,
  options: null,
  qualifier: "shipmentOriginCode",
  sampleCsvData: [34, 41],
  sequence: 13,
};

const CommentsInput = {
  key: FieldKey.COMMENTS,
  label: "Comments",
  csvHeader: "Comments",
  type: FieldType.TEXTAREA,
  required: false,
  sampleCsvData: ["Add Comments", "Add Comments"],
};

// Sender ID - Freeform:
const SenderIdInput = {
  key: FieldKey.SENDER_ID,
  label: "Sender ID",
  csvHeader: "Sender ID",
  type: FieldType.INPUT,
  required: true,
  qualifier: "senderId",
  sampleCsvData: ["Sender ID 1", "Sender ID 2"],
  sequence: 29,
};

const CodeInput = {
  key: FieldKey.CODE,
  label: "Code",
  csvHeader: "Code",
  type: FieldType.INPUT,
  sampleCsvData: ["Code 1", "Code 2"],
  sequence: 30,
};

const ScacInput = {
  key: FieldKey.SCAC,
  label: "Scac",
  csvHeader: "SCAC",
  type: FieldType.INPUT,
  sampleCsvData: ["SCAC 01", "SCAC 02"],
  sequence: 31,
};

const UserRoleInput = {
  key: FieldKey.USER_ROLE,
  label: "User Role",
  csvHeader: "User Role",
  type: FieldType.INPUT,
  sampleCsvData: ["User Role 01", "User Role 02"],
  sequence: 32,
};

const EquipmentTypeInput = {
  key: FieldKey.EQUIPMENT_TYPE,
  label: "Equipment Type",
  csvHeader: "Equipment Type",
  type: FieldType.INPUT,
  sampleCsvData: ["Equipment Type", "Equipment Type"],
  sequence: 33,
};

const ExceptionRoutingCodeInput = {
  key: FieldKey.EXCEPTION_ROUTING_CODE,
  label: "Exception Routing Code",
  csvHeader: "Exception Routing Code",
  type: FieldType.INPUT,
  sampleCsvData: ["Exception Routing Code 01", "Exception Routing Code 02"],
  sequence: 34,
};

const EstimagedClearanceDateInput = {
  key: FieldKey.ESTIMATED_CLEARANCE_DATE,
  label: "Estimated Clearance Date",
  csvHeader: "Estimated Clearance Date",
  type: FieldType.DATETIME,
  sampleCsvData: ["2021-03-04T08:12:56", "2021-03-04T08:12:56"],
  sequence: 35,
};

export const INPUT_FIELD_MAP = {
  VEHICLE_RECEIVED: VehicleReceivedUpload,
  VIN: VinListInput,
  MILESTONE_EVENT: MilestoneEventSelect,
  MILESTONE_STATUS_CODE: MilestoneStatusCodeInput,
  VMACS_CODE: VMACSCodeInput,
  REASON_CODE: ReasonCodeSelect,
  YARD_CODE: YardCodeInput,
  COMPOUND_CODE: CompoundCodeInput,
  BAY_LOCATION: BayLocationInput,
  STATUS_DATE_TIME: StatusDateTimePicker,
  MILESTONE_DATE_TIME: MilestoneDateTimePicker,
  UPDATE_DATE_TIME: UpdateDateTimePicker,
  STATUS_LOCATION: StatusLocationSelect,
  MS1_LOCATION_CODE: Ms1LocationSelect,
  TIMEZONE: TimezoneInput,
  BILL_OF_LADING_NUMBER: BillOfLadingInput,
  BILL_OF_LADING_ORIGIN: BillOfLadingInput,
  BILL_OF_LADING_DESTINATION: BillOfLadingInput,
  NEXT_CARRIER: NextCarrierInput,
  TRAILER_NUMBER: TrailerInput,
  SHIP_TRIP_ID: ShipTripIdInput,
  LANE_TYPE: LaneTypeSelect,
  BOOKING_NUMBER: BookingInput,
  VOYAGE_NUMBER: VoyageInput,
  VESSEL_NAME: VesselInput,
  PORT_OF_LOADING: PortOfLoadingInput,
  PORT_OF_DISCHARGE: PortOfDischargeInput,
  WAYBILL_NUMBER: WaybillInput,
  RAIL_CAR_NUMBER: RailCarInput,
  SHIP_TO: ShipToInput,
  SHIP_FROM: ShipFromInput,
  COMMENTS: CommentsInput,
  CSV_UPLOAD: CsvBulkUpload,
  AIAG_CODE: AiagCodeInput,
  TRANSPORTATION_TYPE: TransportationTypeInput,
  EQUIPMENT_NUMBER: EquipmentNumberInput,
  CUSTOMS_REFERENCE_NUMBER: CustomsReferenceNumberInput,
  PLANT_MILESTONE_UPLOAD: "PLANT_MILESTONE_UPLOAD",
  SENDER_ID: SenderIdInput,
  CODE: CodeInput,
  SCAC: ScacInput,
  USER_ROLE: UserRoleInput,
  EQUIPMENT_TYPE: EquipmentTypeInput,
  EXCEPTION_ROUTING_CODE: ExceptionRoutingCodeInput,
  ESTIMATED_CLEARANCE_DATE: EstimagedClearanceDateInput,
};

// maps API keys with
// respective form fields
export const mappingKeysWithFields = (fields) => {
  if (!fields) {
    return [];
  }

  return Object.keys(fields).map((item) => ({
    ...INPUT_FIELD_MAP[item],
    ...fields[item],
  }));
};

// We use this array to get the tabs
export const getTabsArray = (
  t,
  fields,
  milestoneEvent,
  selectedPartnerType,
  selectedEventType,
) => {
  const { vmacsCode } = getMilestoneEventValue(milestoneEvent?.value);
  // manually set the additional fields
  // as they are not set as a part of CSV compulsory fields
  const manualEntryFields = fields;
  let batchUploadFields = [BatchMilestoneStatusCodeInput, CsvBulkUpload];
  manualEntryFields.push(MilestoneStatusCodeInput);
  if (!_.isNil(vmacsCode)) {
    manualEntryFields.push(VMACSCodeInput);
    batchUploadFields.splice(1, 0, BatchVMACSCodeInput);
  }

  let FORM_FIELDS = [
    {
      title: t("create-milestone:Manual Entry"),
      fields: setOrderFields(manualEntryFields),
    },
    {
      title: t("create-milestone:Batch Upload"),
      fields: batchUploadFields,
      isBatchUpload: true,
      batchData: setOrderFields(fields),
      batchUploadInputKey: FieldKey.CSV_UPLOAD,
      batchMilestoneCodeKey: FieldKey.MILESTONE_STATUS_CODE,
      batchMilestoneVMACSCodeKey: FieldKey.VMACS_CODE,
    },
  ];

  // Display only Batch Upload for Plant and Location Update (Ford specific)
  if (
    selectedPartnerType === "Plant" ||
    selectedPartnerType === "Location Update" ||
    selectedEventType === EventTypeOptions.MULTIPLE
  ) {
    if (selectedEventType === EventTypeOptions.MULTIPLE) {
      batchUploadFields = [CsvBulkUpload];
    }

    FORM_FIELDS = [
      {
        title: t("create-milestone:Batch Upload"),
        fields: batchUploadFields,
        isBatchUpload: true,
        batchData: setOrderFields(fields),
        batchUploadInputKey: FieldKey.CSV_UPLOAD,
        batchMilestoneCodeKey: FieldKey.MILESTONE_STATUS_CODE,
        batchMilestoneVMACSCodeKey: FieldKey.VMACS_CODE,
      },
    ];
  }
  return FORM_FIELDS;
};
