import _ from "lodash";
import {
  translateCodeLabelLookup,
  displayedFilters,
} from "pages/finishedvehicle/utils/filter.utils";
import {
  originOptionsState,
  destinationOptionsState,
  currentPositionsCodeOptionsState,
  productTypeOptionsState,
  lastMilestoneOptionsState,
  soldToOptionsState,
  finCodeOptionsState,
  endUserFinCodeOptionsState,
  dealerRegionOptionsState,
  dealerDistrictOptionsState,
  dealerZoneOptionsState,
  partnersOptionsState,
  originCountryFinOptionsState,
  destinationCountryFinOptionsState,
} from "pages/finishedvehicle/search/FinVehicleSearchFilterLoaders";
import {
  getBasicQueryString,
  getBasicWithStaticOptionsQueryString,
  getBasicQueryStringFilterValuePriority,
  getEntityDateRangeQueryString,
  getEmptyQueryString,
  getNQueryStringFilterValuePriority,
  getLocationQueryString,
  getMultiSelectQueryString,
  getDateRangeQueryString,
} from "../../../components/search-bar/search-filter-query-strings";
import { isDateRangeValueValid } from "components/search-bar/search-filter-validators";
import {
  AsyncSelectFilterButton,
  DateRangeFilterButton,
  BatchFilterButton,
  NFilterButton,
  SelectFilterButton,
} from "../../../components/search-bar/FilterButtons";
import { Features } from "../../../modules/auth/Authorization";
import {
  batchCsvVinExample,
  batchCsvProductTypeExample,
  batchCsvOrderNumberExample,
  batchCsvLast8ofVinExample,
} from "components/search-bar/batch-comment-csv-data";
import { translateExceptionName } from "pages/finishedvehicle/utils/exceptions.utils";
import { useEntityTranslation } from "shared/hooks/useEntityTranslation";
import { useLifecycleStateTranslation } from "shared/hooks/useEntityTranslation";

export const FILTERS = [
  {
    queryKey: "originLocation",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("fv-vin-search:Origin"),
    optionsState: originOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getLocationQueryString("originId", filterValue, { valueKey: "code" }),
  },
  {
    queryKey: "destinationLocation",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("fv-vin-search:Destination"),
    optionsState: destinationOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getLocationQueryString("destinationId", filterValue, {
        valueKey: "code",
      }),
  },
  {
    queryKey: "carrier",
    label: (t) => t("fv-vin-search:Carrier"),
    optionsGetter: (props) => props.carrierFilterOptions,
    queryBuilder: getBasicWithStaticOptionsQueryString,
  },
  // TODO: re-add internal space to the "XxType" filter labels once it won't cause
  // a line break in the widget
  // (this already seems to work in the shipment filters)
  {
    queryKey: "description",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("fv-vin-search:Product Type"),
    optionsState: productTypeOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
  },
  {
    queryKey: ["lifeCycleState", "activeSubStatus"],
    label: (t) => t("fv-vin-search:VIN Status"),
    Component: (props) => {
      const { getTranslatedStatus } = useLifecycleStateTranslation();

      let translatedOptions = props.options;

      for (const [status, subStatusOptions] of Object.entries(props?.options)) {
        translatedOptions[status] = subStatusOptions.map((option) => ({
          ...option,
          label: getTranslatedStatus(option.label),
        }));
      }

      return <NFilterButton {...props} options={translatedOptions} />;
    },
    nIsAsync: {
      lifeCycleState: false,
      activeSubStatus: false,
    },
    nLabels: {
      lifeCycleState: null,
      activeSubStatus: "Active Subcategory",
    },
    nRequirments: {
      activeSubStatus: ["Active"],
    },
    nIsMulti: {
      lifeCycleState: true,
      activeSubStatus: false,
    },
    nHideFuzzySearch: {
      lifeCycleState: true,
      activeSubStatus: true,
    },
    nHideSelectAll: {
      lifeCycleState: true,
      activeSubStatus: true,
    },
    nHideSelectEmpty: {
      lifeCycleState: true,
      activeSubStatus: true,
    },
    nDefaultValue: {
      activeSubStatus: "all_active",
    },
    showAll: false,
    optionsGetter: (props) => {
      return {
        lifeCycleState: props.lifeCycleStateFilterOptions,
        activeSubStatus: [
          { label: "All Active", value: "all_active" },
          { label: "Order Only", value: "prebuilt" },
          { label: "VIN Only", value: "active" },
        ],
      };
    },
    queryBuilder: (queryKeys, filterValues) => {
      if (filterValues.activeSubStatus === "prebuilt") {
        filterValues.lifeCycleState = ["Prebuilt"];
      } else if (filterValues.activeSubStatus === "all_active") {
        filterValues.lifeCycleState = [
          ...filterValues.lifeCycleState,
          "Prebuilt",
        ];
      } else if (filterValues.activeSubStatus === "active") {
        filterValues.lifeCycleState = ["Active"];
      }
      return getNQueryStringFilterValuePriority(
        queryKeys.filter((key) => key !== "activeSubStatus"),
        filterValues,
      );
    },
  },
  {
    queryKey: "exception",
    label: (t) => t("fv-vin-search:VIN Exception"),
    optionsGetter: (props, t) => {
      // H2-708: content of exceptions should be translated when presented.
      return props.exceptionTypeFilterOptions.map((item) => {
        return { ...item, label: translateExceptionName(item.label, t) };
      });
    },
    queryBuilder: getBasicWithStaticOptionsQueryString,
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "lastMilestone",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("fv-vin-search:Last Milestone"),
    optionsState: lastMilestoneOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
  },
  {
    queryKey: "pickupDate",
    label: (t) => t("fv-vin-search:Pickup Date"),
    Component: DateRangeFilterButton,
    optionsGetter: () => [],
    queryBuilder: getEntityDateRangeQueryString,
    dateTypeOptions: (t, auth) => {
      const options = [{ label: t("fv-vin-search:Actual"), value: "Actual" }];

      if (auth.hasFeatures([Features.FINISHED_VEHICLE_SCHEDULED_WINDOW])) {
        options.unshift({
          label: t("fv-vin-search:Scheduled"),
          value: "Scheduled",
        });
      }
      return options;
    },
    isValueValid: isDateRangeValueValid,
  },
  {
    queryKey: "deliveryDate",
    label: (t) => t("fv-vin-search:Delivery Date"),
    Component: DateRangeFilterButton,
    optionsGetter: () => [],
    queryBuilder: getEntityDateRangeQueryString,
    dateTypeOptions: (t, auth) => {
      const options = [
        { label: t("fv-vin-search:Actual"), value: "Actual" },
        { label: t("fv-vin-search:ETA"), value: "ETA" },
      ];

      if (auth.hasFeatures([Features.FINISHED_VEHICLE_SCHEDULED_WINDOW])) {
        options.unshift({
          label: t("fv-vin-search:Scheduled"),
          value: "Scheduled",
        });
      }
      return options;
    },
    isValueValid: isDateRangeValueValid,
  },
  {
    queryKey: "ref:OrderType:0",
    label: (t) => t("fv-vin-search:Order Type"),
    optionsGetter: (props) => props.orderTypeFilterOptions,
    queryBuilder: getBasicWithStaticOptionsQueryString,
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "ref:soldToDealer:0",
    Component: AsyncSelectFilterButton,
    label: (t) => t("fv-vin-search:Sold To"),
    isMulti: true,
    optionsState: soldToOptionsState,
    queryBuilder: (queryParameter, filterValue) => {
      // Fuzzy search is disabled right now, but when we re-enable it:
      // This filter is special. For normally-selected options, the API
      // wants the queryKey to be `ref:soldToDealer:0`, but for the fuzzy
      // search options, it wants it to be `ref:soldToDealer:1` (by default,
      // our code automatically makes fuzzy search to be the
      // queryKey above + `:contains`). So, we're doing the below to replace
      // instances of `:0:contains` with `:1`.
      let soldToQueryString = getMultiSelectQueryString(
        queryParameter,
        filterValue,
      );
      soldToQueryString = soldToQueryString.replace(":0:contains", ":1");
      return soldToQueryString;
    },
    requiredOrgFilters: "FIN_SOLD_TO",
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "ref:FinCode:0",
    Component: AsyncSelectFilterButton,
    label: (t) => t("fv-vin-search:FIN Code"),
    isMulti: true,
    optionsState: finCodeOptionsState,
    queryBuilder: (queryParameter, filterValue) => {
      let finCodeQueryString = getMultiSelectQueryString(
        queryParameter,
        filterValue,
      );
      return finCodeQueryString;
    },
    requiredOrgFilters: "FIN_FINCODE",
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "ref:EndUserFinCode:0",
    Component: AsyncSelectFilterButton,
    label: (t) => t("fv-vin-search:End User FIN Code"),
    isMulti: true,
    optionsState: endUserFinCodeOptionsState,
    queryBuilder: (queryParameter, filterValue) => {
      let endUserFinCodeQueryString = getMultiSelectQueryString(
        queryParameter,
        filterValue,
      );
      return endUserFinCodeQueryString;
    },
    requiredOrgFilters: "FIN_ENDUSER_FINCODE",
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "partner",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("fv-vin-search:Partner"),
    optionsState: partnersOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
  },
  {
    queryKey: "shipmentStatuses",
    label: (t) => t("fv-vin-search:Shipment Status"),
    optionsGetter: (props) => props.shipmentStatusFilterOptions,
    queryBuilder: getBasicQueryStringFilterValuePriority,
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "groupCategory:ITSS",
    label: (t) => t("fv-vin-search:ITSS ID"),
    optionsGetter: (props) => props.itssFilterOptions,
    queryBuilder: getBasicQueryStringFilterValuePriority,
    requiredFeatures: [Features.ITSS],
  },
  {
    queryKey: "groupCategory:SB",
    label: (t) => t("fv-vin-search:Spot Buy Auth"),
    optionsGetter: (props) => props.spotBuyFilterOptions,
    queryBuilder: getBasicQueryStringFilterValuePriority,
    requiredFeatures: [Features.SPOT_BUY],
  },
  {
    queryKey: "shipmentExceptions",
    label: (t) => t("fv-vin-search:Shipment Exceptions"),
    optionsGetter: (props, t) => {
      const visibleFilters = props.shipmentExceptionFilterOptions.map((x) => {
        // Only show specific filters
        const isVisible =
          displayedFilters().find((z) => z === x.label) !== undefined;
        if (isVisible) {
          return {
            label: translateCodeLabelLookup(t, x.label),
            value: x.value,
          };
        } else {
          return null;
        }
      });
      return visibleFilters.filter((y) => y !== null);
    },
    queryBuilder: getBasicQueryStringFilterValuePriority,
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "originCountry",
    Component: AsyncSelectFilterButton,
    label: (t) => t("fv-vin-search:Origin Country"),
    isMulti: true,
    hideFuzzySearch: true,
    optionsState: originCountryFinOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
  },
  {
    queryKey: "origin_region",
    label: (t) => t("shipment-search:Origin Region"),
    optionsGetter: (props) => props.regionFilterOptions,
    queryBuilder: getBasicWithStaticOptionsQueryString,
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "destinationCountry",
    Component: AsyncSelectFilterButton,
    label: (t) => t("fv-vin-search:Destination Country"),
    isMulti: true,
    hideFuzzySearch: true,
    optionsState: destinationCountryFinOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
  },
  {
    queryKey: "destination_region",
    label: (t) => t("shipment-search:Destination Region"),
    optionsGetter: (props) => props.regionFilterOptions,
    queryBuilder: getBasicWithStaticOptionsQueryString,
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "shippability",
    Component: SelectFilterButton,
    label: (t) => t("fv-vin-search:Shippability"),
    optionsGetter: (props, t) => {
      return [
        {
          label: t("fv-vin-search:Shippable"),
          value: "shippable",
        },
        {
          label: t("fv-vin-search:Non-Shippable"),
          value: "non-shippable",
        },
      ];
    },
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString(queryParameter, filterValue),
    requiredOrgFilters: "FIN_SHIPPABILITY",
  },
  {
    queryKey: "dealerRegion",
    Component: AsyncSelectFilterButton,
    label: (t) => t("fv-vin-search:Dealer Region"),
    isMulti: true,
    optionsState: dealerRegionOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
    requiredOrgFilters: "FIN_DEALER_REGION",
  },
  {
    queryKey: "dealerZone",
    Component: AsyncSelectFilterButton,
    label: (t) => t("fv-vin-search:Dealer Zone"),
    isMulti: true,
    optionsState: dealerZoneOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
    requiredOrgFilters: "FIN_DEALER_ZONE",
  },
  {
    queryKey: "dealerDistrict",
    Component: AsyncSelectFilterButton,
    label: (t) => t("fv-vin-search:Dealer District"),
    isMulti: true,
    optionsState: dealerDistrictOptionsState,
    queryBuilder: (queryParameter, filterValue) =>
      getMultiSelectQueryString(queryParameter, filterValue),
    requiredOrgFilters: "FIN_DEALER_DISTRICT",
  },
  {
    queryKey: "batch",
    label: (t) => t("fv-vin-search:Batch Search"),
    Component: (props) => {
      const { getTranslatedBatchSearchOptions } = useEntityTranslation();

      const translatedOptions = props?.options.map((option) => ({
        ...option,
        label: getTranslatedBatchSearchOptions(option.value),
      }));

      return <BatchFilterButton {...props} options={translatedOptions} />;
    },
    optionsGetter: () => {
      return [
        {
          value: "vin",
          batchCsvExample: batchCsvVinExample,
        },
        {
          value: "description",
          batchCsvExample: batchCsvProductTypeExample,
        },
        {
          value: "last_8_of_vin",
          batchCsvExample: batchCsvLast8ofVinExample,
        },
        {
          value: "OrderNumber",
          batchCsvExample: batchCsvOrderNumberExample,
        },
      ];
    },
    queryBuilder: getEmptyQueryString,
  },
  // Hidden parameter for saved searches.
  // This query param lets us change the logic for the exception field to be an AND instead of an OR.
  // e.g. Search for VINs that have Fuel Level and Tire Pressure; entitySearchAnd=exception&exception=id1,id2
  // (usually this would be Fuel Level or Tire Pressure)
  {
    queryKey: "entitySearchAnd",
    queryBuilder: getBasicQueryString,
    hidden: () => true,
  },
  {
    // ordered top down from 0 - N
    queryKey: ["currentPositionTypes", "currentPositionCodes"],
    label: (t) => t("fv-vin-search:Current Location"),
    Component: (props) => {
      const { getTranslatedCurrentLocationTypes } = useEntityTranslation();

      const translatedOptions = {
        currentPositionTypes: props?.options?.currentPositionTypes.map(
          (option) => ({
            ...option,
            label: getTranslatedCurrentLocationTypes(option.label),
          }),
        ),
      };

      return (
        <NFilterButton
          {...props}
          options={
            !_.isEmpty(translatedOptions.currentPositionTypes)
              ? translatedOptions
              : props.options
          }
        />
      );
    },
    nIsAsync: {
      currentPositionTypes: false,
      currentPositionCodes: true,
    },
    nOptionsState: {
      currentPositionTypes: null,
      currentPositionCodes: currentPositionsCodeOptionsState,
    },
    //Label of filter where key is corresponding select
    nLabels: {
      currentPositionTypes: null,
      currentPositionCodes: "At Location",
    },
    //Fields required from previous filter
    //Field with contraints as key
    nRequirments: {
      currentPositionCodes: ["AtLocation"],
    },
    // Hides fuzzy search options
    nHideFuzzySearch: {
      currentPositionTypes: true,
      currentPositionCodes: false,
    },
    // Hides select all option
    nHideSelectAll: {
      currentPositionTypes: true,
      currentPositionCodes: false,
    },
    // Hides select empty option
    nHideSelectEmpty: {
      currentPositionTypes: true,
      currentPositionCodes: false,
    },
    //Showall filters with no requirments or constraints
    showAll: false,
    //Object key as query key
    optionsGetter: (props) => {
      return {
        currentPositionTypes: props.currentPositionTypes ?? [],
      };
    },
    queryBuilder: getNQueryStringFilterValuePriority,
  },
  {
    queryKey: "completedDate",
    label: (t) => t("fv-vin-search:Completed Date"),
    Component: DateRangeFilterButton,
    queryBuilder: (queryParameter, filterValue) =>
      getDateRangeQueryString(queryParameter, filterValue, {
        formatDateTypeValue: JSON.stringify,
        convertToUtc: true,
      }),
    isValueValid: isDateRangeValueValid,
    requiredOrgFilters: "FIN_COMPLETED_DATE",
  },
  {
    queryKey: "routeNumber:0",
    label: (t) => t("fv-vin-search:Route ID"),
    queryBuilder: getBasicWithStaticOptionsQueryString,
    optionsGetter: (props) => props.routeIdOptions,
  },
];
