import axios from "axios";
import apiUrl from "api-url";
import { Features } from "modules/auth/Authorization";

// URLS
const STORE_MOUNT_POINT = "ctMyWatchedContainers";

// Actions

const CTE = (actionName) => `${STORE_MOUNT_POINT}/${actionName}`;
const REQUEST_WATCHED_CONTAINERS = CTE("REQUEST_WATCHED_CONTAINERS");
const RECEIVE_WATCHED_CONTAINERS = CTE("RECEIVE_WATCHED_CONTAINERS");
const RECEIVE_WATCHED_CONTAINERS_ERROR = CTE(
  "RECEIVE_WATCHED_CONTAINERS_ERROR",
);
const REQUEST_WATCHED_CONTAINERS_COUNT = CTE(
  "REQUEST_WATCHED_CONTAINERS_COUNT",
);
const RECEIVE_WATCHED_CONTAINERS_COUNT = CTE(
  "RECEIVE_WATCHED_CONTAINERS_COUNT",
);
const SET_WATCHED_CONTAINERS_PAGE_INDEX = CTE(
  "SET_WATCHED_CONTAINERS_PAGE_INDEX",
);

// Action creators

function fetchWatchedContainers() {
  return async (dispatch, getState) => {
    dispatch({
      type: REQUEST_WATCHED_CONTAINERS,
    });
    dispatch({ type: REQUEST_WATCHED_CONTAINERS_COUNT });
    const state = getState();
    const pageSize = state[STORE_MOUNT_POINT].watchedContainersPageSize;
    const pageNumber = state[STORE_MOUNT_POINT].watchedContainersPageIndex;

    const headers = {
      "x-target-feature": Features.CONTAINER_TRACKING,
    };
    const params = { pageSize, pageNumber };
    const url = apiUrl(`/containertracking/api/watch`);

    try {
      const response = await axios.get(url, { headers, params });
      dispatch({
        type: RECEIVE_WATCHED_CONTAINERS_COUNT,
        payload: { totalPages: response.data?.meta?.totalPages ?? 1 },
      });
      return dispatch({
        type: RECEIVE_WATCHED_CONTAINERS,
        payload: { watchedContainers: response?.data?.data ?? [] },
      });
    } catch (error) {
      console.error(error);
      return dispatch({ type: RECEIVE_WATCHED_CONTAINERS_ERROR, error });
    }
  };
}

function setWatchedContainersPageIndex(pageIndex) {
  return {
    type: SET_WATCHED_CONTAINERS_PAGE_INDEX,
    payload: { pageIndex },
  };
}

// Selectors

const getWatchedContainers = (state) =>
  state[STORE_MOUNT_POINT].watchedContainers;
const getWatchedContainersLoading = (state) =>
  state[STORE_MOUNT_POINT].watchedContainersLoading ||
  state[STORE_MOUNT_POINT].watchedContainersCountLoading;
const getWatchedContainersPageIndex = (state) =>
  state[STORE_MOUNT_POINT].watchedContainersPageIndex;
const getWatchedContainersPageSize = (state) =>
  state[STORE_MOUNT_POINT].watchedContainersPageSize;
const getWatchedContainersPageCount = (state) =>
  state[STORE_MOUNT_POINT].watchedContainersPageCount;

// Initial state
const initialState = {
  watchedContainers: [],
  watchedContainersLoading: false,
  watchedContainersPageIndex: 0,
  watchedContainersPageSize: 10,
  watchedContainersPageCount: 0,
};

const ContainerTrackingMyWatchedContainersReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case REQUEST_WATCHED_CONTAINERS:
      return {
        ...state,
        watchedContainersLoading: true,
      };

    case RECEIVE_WATCHED_CONTAINERS:
      return {
        ...state,
        watchedContainers: action.payload.watchedContainers,
        watchedContainersLoading: false,
      };

    case RECEIVE_WATCHED_CONTAINERS_COUNT:
      return {
        ...state,
        watchedContainersPageCount: action.payload.totalPages,
        watchedContainersCountLoading: false,
      };

    case SET_WATCHED_CONTAINERS_PAGE_INDEX:
      return {
        ...state,
        watchedContainersPageIndex: action.payload.pageIndex,
      };

    default:
      return state;
  }
};

// interface
const ContainerTrackingMyWatchedContainersState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchWatchedContainers,
    setWatchedContainersPageIndex,
  },
  selectors: {
    getWatchedContainers,
    getWatchedContainersLoading,
    getWatchedContainersPageIndex,
    getWatchedContainersPageSize,
    getWatchedContainersPageCount,
  },
  reducer: ContainerTrackingMyWatchedContainersReducer,
};
export default ContainerTrackingMyWatchedContainersState;
