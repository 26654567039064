/** @jsxImportSource @emotion/react */
import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { IoMdTimer } from "react-icons/io";
import { FontSize, Text } from "components/atoms/Text.atom";
import { ShowMoreList } from "components/molecules/ShowMoreList.molecule";
import { ChicletCSS as Chiclet, colorForLad } from "components/chiclets";
import { WatchToggle } from "shared/components/molecules/WatchToggle.molecule";
import { SurgicalToteTrackingDetailsWidgetState } from "../redux/SurgicalToteTrackingDetailsWidgetState";
import Colors from "styles/colors";
import {
  localizedTimeFormatter,
  localizedDateFormatter,
  tsToDaysHrsMins,
} from "utils/date-time";

const WatchCellRenderer = ({ row, value }) => {
  const dispatch = useDispatch();
  return (
    <WatchToggle
      key={row.original.id}
      checked={value?.toLowerCase() === "true" ?? false}
      onChange={(newWatchValue) => {
        dispatch(
          SurgicalToteTrackingDetailsWidgetState.actionCreators.watchToteActions(
            row.original.id,
            newWatchValue,
          ),
        );
      }}
      iconSize={FontSize.size24}
      color={Colors.nav.NAVY}
      checkedColor={Colors.highlight.YELLOW}
    />
  );
};

WatchCellRenderer.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
  value: PropTypes.bool,
};

const ToteIdCell = (props) => {
  const { toteId, tagId } = props.value;

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <div css={{ display: "flex", flexDirection: "row" }}>
        {toteId ? (
          <React.Fragment>
            <Text bold style={{ marginRight: 3 }}>
              Asset ID:
            </Text>
            <Text>{toteId}</Text>
          </React.Fragment>
        ) : null}
      </div>

      <div css={{ display: "flex", flexDirection: "row" }}>
        {tagId ? (
          <React.Fragment>
            <Text bold style={{ marginRight: 3 }}>
              Tag ID:
            </Text>
            <Text>{tagId}</Text>
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
};

ToteIdCell.propTypes = {
  value: PropTypes.shape({
    toteId: PropTypes.string,
  }),
};

const letterForLad = ({ name }, status) => {
  if (status === "In Transit") {
    return "T";
  }

  if (name === "Distribution") {
    return "D";
  }

  if (name === "Hospital") {
    return "H";
  }

  return "";
};

const UniqueDeviceIdCell = ({ value }) => {
  const { t } = useTranslation("surgical-tote-tracking");

  const { contents = [] } = value;
  let uniqueDeviceIdList = contents?.map((content) => content.value);
  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      {uniqueDeviceIdList?.length > 0 ? (
        <ShowMoreList
          title={t("surgical-tote-tracking:Unique Device ID")}
          list={uniqueDeviceIdList}
          visibleItemCount={3}
        />
      ) : null}
    </div>
  );
};

UniqueDeviceIdCell.propTypes = {
  value: {
    contents: PropTypes.array,
  },
};
const ToteLocationCell = (props) => {
  const { status, lad, lastUpdate, locationCode, location } = props.value;

  const locationCodeCheck = locationCode ? `(${locationCode})` : "";
  const ladName = { name: lad };
  const codeLetter = letterForLad(ladName, status);
  const color =
    status === "In Transit"
      ? Colors.background.DARK_BLUE
      : colorForLad(ladName);
  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <div className="d-flex align-items-center flex-wrap">
        {codeLetter ? (
          <Chiclet
            codeLetter={codeLetter}
            backgroundColor={color}
            height={24}
            width={24}
            style={{ marginRight: "0.5em" }}
          />
        ) : null}
        <Text>{status}</Text>
      </div>

      <Fragment>
        {locationCodeCheck || location ? (
          <Text bold>{`${locationCodeCheck} ${location}`}</Text>
        ) : null}
        {lastUpdate?.city && lastUpdate?.state ? (
          <Text>{`${lastUpdate.city}, ${lastUpdate.state}`}</Text>
        ) : null}
      </Fragment>
    </div>
  );
};

ToteLocationCell.propTypes = {
  value: PropTypes.shape({
    status: PropTypes.string,
    lad: PropTypes.string,
    lastUpdate: PropTypes.object,
    locationCode: PropTypes.string,
    location: PropTypes.string,
  }).isRequired,
};

const LastUpdateCell = (props) => {
  const { lastUpdate } = props.value;

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      {lastUpdate?.datetime ? (
        <div className="d-flex flex-wrap">
          <Text className="ms-1">
            {`${localizedTimeFormatter(
              lastUpdate?.datetime,
            )} ${localizedDateFormatter(lastUpdate?.datetime)}`}
          </Text>
        </div>
      ) : null}
    </div>
  );
};

LastUpdateCell.propTypes = {
  value: PropTypes.shape({
    lastUpdate: PropTypes.object,
  }).isRequired,
};

export const CaseNumberCell = (props) => {
  const { caseNumber } = props.value;

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      {caseNumber ? (
        <span>
          <Text>{caseNumber}</Text>
        </span>
      ) : null}
    </div>
  );
};

CaseNumberCell.propTypes = {
  value: PropTypes.shape({
    caseNumber: PropTypes.string,
    surgeryDate: PropTypes.string,
    surgeryAddress: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
  }).isRequired,
};

const ActiveExceptionCell = (props) => {
  const { activeExceptions } = props.value;

  const { t } = useTranslation("surgical-tote-tracking");

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      {activeExceptions &&
        activeExceptions.map((activeException, i) => {
          const reasonCode = activeException?.reasonCode?.toUpperCase() ?? "";

          return (
            <React.Fragment key={i}>
              {reasonCode === "ED" ? (
                <React.Fragment>
                  <div css={{ display: "flex", flexDirection: "row" }}>
                    <Text css={{ color: "red" }}>
                      {t("surgical-tote-tracking:Excessive Dwell")}
                    </Text>
                  </div>
                  <div css={{ display: "flex", flexDirection: "row" }}>
                    <IoMdTimer
                      style={{
                        fontSize: 20,
                        marginRight: 10,
                        color: Colors.holds.RED,
                      }}
                    />
                    <Text bold>
                      {tsToDaysHrsMins(activeException?.activatedAt)}
                    </Text>
                  </div>
                </React.Fragment>
              ) : null}
              {reasonCode === "U" && (
                <Text bold>{t("surgical-tote-tracking:Unaccounted")}</Text>
              )}
            </React.Fragment>
          );
        })}
    </div>
  );
};

ActiveExceptionCell.propTypes = {
  value: PropTypes.shape({
    activeExceptions: PropTypes.array,
  }).isRequired,
};

export const useColumns = () => {
  const { t } = useTranslation("surgical-tote-tracking");

  const results = [
    {
      Header: t("surgical-tote-tracking:Watch"),
      id: "watch",
      Cell: WatchCellRenderer,
      accessor: (d) => {
        return d?.watched;
      },
      width: 50,
      disableSortBy: true,
      disableResizing: true,
      centerAligned: true,
    },

    {
      Header: t("surgical-tote-tracking:Asset"),
      id: "toteId",
      Cell: ToteIdCell,
      accessor: (d) => {
        return {
          toteId: d?.containerId,
          tagId: d?.tagId,
        };
      },
      minWidth: 125,
    },
    {
      Header: t("surgical-tote-tracking:Unique Device ID"),
      id: "contents",
      Cell: UniqueDeviceIdCell,
      accessor: (d) => {
        return {
          contents: d?.contents,
        };
      },
      width: 180,
      minWidth: 150,
    },
    {
      Header: t("surgical-tote-tracking:Asset Location"),
      id: "toteLocation",
      Cell: ToteLocationCell,
      accessor: (d) => {
        return {
          status: d?.status ?? "",
          lad: d?.location_type ?? "",
          lastUpdate: d?.lastUpdate ?? {},
          locationCode: d?.lastUpdate?.locationCode ?? "",
          location: d?.lastMilestone?.location ?? "",
        };
      },
      width: 180,
      minWidth: 150,
    },
    {
      Header: t("surgical-tote-tracking:Last Update"),
      id: "lastUpdate",
      Cell: LastUpdateCell,
      accessor: (d) => {
        return {
          lastUpdate: d?.lastMilestone,
        };
      },
      minWidth: 125,
    },

    {
      Header: t("surgical-tote-tracking:Active Exceptions"),
      id: "activeEtaException",
      Cell: ActiveExceptionCell,
      accessor: (d) => {
        return {
          activeExceptions: d?.activeExceptions,
        };
      },
      minWidth: 125,
    },
  ];

  return results;
};
