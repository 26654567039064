/** @jsxImportSource @emotion/react */
import debouncePromise from "debounce-promise";
import { AsyncPaginate } from "react-select-async-paginate";
import PropTypes from "prop-types";

import { asyncSearchLocations } from "pages/administration/location-management/locations/search/LocationSearchStateExtensions";
import apiUrl from "api-url";
import { LocationAddressComplex } from "components/molecules/LocationAddressBlock.molecule";
import Colors from "styles/colors";
import { IdBox } from "components/multimodal-components/InfoBoxes";

const asyncSearchShipperLocations = debouncePromise(
  (query, loadedOptions, selectedShipper) => {
    if (query.length < 2) {
      return {
        options: [],
        hasMore: false,
      };
    }

    // Calculate page number by checking if the currently loaded results
    // are divisble by the pageSize
    let page = 0;
    const pageSize = 500;
    if (loadedOptions.length % pageSize === 0) {
      page = loadedOptions.length / pageSize;
    }

    return asyncSearchLocations(
      query,
      true,
      page,
      pageSize,
      (q) =>
        apiUrl(
          `/location/locations?verbose=false&all=true&everything=${q}&owner_id=${selectedShipper.organization_id}`,
        ),
      "ShipperLocation",
    );
  },
  500,
);

const LocationOption = ({
  label,
  innerProps,
  selectProps,
  data,
  isDisabled,
  isFocused,
  isSelected,
}) => {
  return (
    <div
      ref={innerProps.ref}
      {...innerProps}
      className={selectProps.className}
      css={{
        fontSize: "0.8em",
        paddingLeft: "10px",
        backgroundColor: isDisabled
          ? Colors.background.LIGHT_GRAY
          : isSelected
          ? "#EBF3FF"
          : isFocused
          ? "#DEEBFF"
          : "#fff",
        color: isDisabled
          ? "#aaa"
          : isSelected
          ? "#333"
          : isFocused
          ? "#333"
          : "#6d6d6d",
      }}
    >
      <IdBox css={{ fontWeight: "bold" }} data-qa="text-name-location">
        {label}
      </IdBox>
      <LocationAddressComplex
        addressColor={Colors.text.GRAY}
        address1={data.address}
        address2={data.address2}
        city={data.city}
        state={data.state}
        postalCode={data.postal_code}
        country={data.country}
      />
    </div>
  );
};

LocationOption.propTypes = {
  label: PropTypes.string,
  data: PropTypes.object,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
  isFocused: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
};

const ShipperLocationSelect = ({ value, onChange, selectedShipper }) => {
  return (
    <AsyncPaginate
      name="async-select-locations-filter"
      isMulti={false}
      cacheOptions={false}
      loadOptions={(query, loadedOptions) =>
        asyncSearchShipperLocations(query, loadedOptions, selectedShipper)
      }
      styles={customStyles}
      isClearable={true}
      value={value}
      onChange={onChange}
      components={{
        Option: LocationOption,
      }}
    />
  );
};

ShipperLocationSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func,
  selectedShipper: PropTypes.object,
};

// todo incorporate this into SelectField to remove duplicate code
const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#fff",
    borderRadius: "5px",
    borderWidth: "2px",
    borderColor: "#ced4da",
    height: "3em",
    fontSize: "0.8em",
  }),
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};

export default ShipperLocationSelect;
