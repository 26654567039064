/** @jsxImportSource @emotion/react */
import _ from "lodash";
import PropTypes from "prop-types";
import { useState, useEffect, useCallback } from "react";
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { Modal } from "components/molecules/Modal.molecule";
import { Button } from "components/atoms/Button.atom";
import apiUrl from "api-url";
import axios from "axios";
import SimpleBar from "simplebar-react";
import { useTranslation } from "react-i18next";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import { LocationAddressComplex } from "components/molecules/LocationAddressBlock.molecule";
import { RouteManagementAsyncSelect } from "../RouteManagement.AsyncSelect";
import { Icon, FontSize } from "components/atoms/Icon.atom";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import Colors from "styles/colors";
import { getTrackingFeatureName } from "../../../../containertracking/utils/ContainerTrackingUtils";
import { useSelector } from "react-redux";

export const RouteManagementPopup = ({
  show,
  hide,
  isEdit,
  defaultBucketName,
  locations,
  containers,
  failure,
  isSaving,
  savedStatus,
  updateData,
  resetFailedCases,
  resetSavedCases,
  resetSavedStatus,
  savedCases,
  resetPopupData,
  searchRouteEntities,
}) => {
  const { t } = useTranslation("container-tracking");
  const [bucketName, setBucketName] = useState("");
  const [addedLocations, setAddedLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState(null);
  const [selectedContainers, setSelectedContainers] = useState(null);
  const [deletedLocations, setDeletedLocations] = useState([]);
  const [addedContainers, setAddedContainers] = useState([]);
  const [deletedContainers, setDeletedContainers] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [containerIdInputValue, setContainerIdInputValue] = useState("");
  const [isRenameAck, setIsRenameAck] = useState(false);
  const [showRenamePopup, setShowRenamePopup] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showWarningAlert, setShowWarningAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [savedRenderLocations, setSavedRenderLocations] = useState(null);
  const [savedRenderContainers, setSavedRenderContainers] = useState(null);
  const [renderLocations, setRenderLocations] = useState([]);
  const [renderContainers, setRenderContainers] = useState([]);
  const [internalDeletedLocations, setInternalDeletedLocations] = useState([]);
  const [internalAddedLocations, setInternalAddedLocations] = useState([]);
  const [internalAddedContainers, setInternalAddedContainers] = useState([]);
  const [internalDeletedContainers, setInternalDeletedContainers] = useState(
    [],
  );
  const trackingFeatureName = useSelector(getTrackingFeatureName);

  const [oldBucketName, setOldBucketName] = useState("");
  const [isSaveClicked, setIsSaveClicked] = useState(false);

  useEffect(() => {
    setOldBucketName(defaultBucketName);
  }, [defaultBucketName]);

  useEffect(() => {
    let deletedLocationCodes = deletedLocations.map((location) => location.id);
    const updatedLocations = locations
      ? locations
          .filter((location) => {
            return !deletedLocationCodes.includes(location.id);
          })
          .concat(addedLocations)
      : [];

    setRenderLocations(updatedLocations);
  }, [locations, addedLocations, deletedLocations]);

  useEffect(() => {
    const updatedContainers = containers
      ? containers
          .filter((container) => {
            return !deletedContainers.includes(container);
          })
          .concat(addedContainers)
      : [];

    setRenderContainers(updatedContainers);
  }, [containers, addedContainers, deletedContainers]);

  useEffect(() => {
    if (show) {
      setBucketName(defaultBucketName);
    }
  }, [show, defaultBucketName]);

  useEffect(() => {
    if (
      failure ? failure._rename || failure._isNew || failure[bucketName] : false
    ) {
      setShowSuccessAlert(false);
      setShowWarningAlert(false);
      if (failure._rename) {
        setShowErrorAlert(true);
        setAlertMessage(
          t(`container-tracking:${failure?._rename?.body?.error?.message}`),
        );
      } else if (failure?._isNew) {
        setShowErrorAlert(true);
        setAlertMessage(t(`container-tracking:${failure?._isNew}`));
      } else {
        setShowErrorAlert(true);
        const updatedFailedLocations = failure
          ? failure[bucketName]
              .map((data) => data.location_code)
              .filter((data) => data && data !== "")
          : [];
        const updatedFiledContainers = failure
          ? failure[bucketName]
              .map((data) => data.container_identifier)
              .filter((data) => data && data !== "")
          : [];
        if (
          updatedFailedLocations.length > 0 &&
          updatedFiledContainers.length > 0
        ) {
          setAlertMessage(
            t(`container-tracking:System failed to add the following locations codes, please check and try again.
            ${updatedFailedLocations.join(",")}
            System failed to load the following container IDs, please check and try again.
            ${updatedFiledContainers.join(",")}`),
          );
        } else if (updatedFailedLocations.length > 0) {
          setAlertMessage(
            t(
              `container-tracking:System failed to add the following locations codes, please check and try again.
              ${updatedFailedLocations.join(",")}`,
            ),
          );
        } else if (updatedFiledContainers.length > 0) {
          setAlertMessage(
            t(
              `container-tracking:System failed to load the following container IDs, please check and try again.
              ${updatedFiledContainers.join(",")}`,
            ),
          );
        } else {
          setAlertMessage(
            t(`container-tracking:Error occured, please check and try again.`),
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [failure, bucketName]);

  useEffect(() => {
    if (isSaving) {
      setSavedRenderLocations(renderLocations);
      setSavedRenderContainers(renderContainers);
    }
  }, [isSaving, renderLocations, renderContainers]);

  useEffect(() => {
    if (savedCases && !_.isEmpty(savedCases)) {
      if (!failure || _.isEmpty(failure)) {
        setInternalDeletedContainers([]);
        setInternalDeletedLocations([]);
        setInternalAddedContainers([]);
        setInternalAddedLocations([]);
      } else {
        const savedAddedLocations =
          savedCases && savedCases[bucketName]
            ? savedCases[bucketName]
                .filter(
                  (data) => data && data.location_code && data.action === "ADD",
                )
                .map((data) => data.location_code)
            : [];
        const savedDeletedLocations =
          savedCases && savedCases[bucketName]
            ? savedCases[bucketName]
                .filter(
                  (data) =>
                    data && data.location_code && data.action === "DELETE",
                )
                .map((data) => data.location_code)
            : [];
        const savedAddedContainers =
          savedCases && savedCases[bucketName]
            ? savedCases[bucketName]
                .filter(
                  (data) =>
                    data && data.container_identifier && data.action === "ADD",
                )
                .map((data) => data.container_identifier)
            : [];
        const savedDeletedContainers =
          savedCases && savedCases[bucketName]
            ? savedCases[bucketName]
                .filter(
                  (data) =>
                    data && data.container_identifier && data.action === "ADD",
                )
                .map((data) => data.container_identifier)
            : [];

        setInternalDeletedContainers(
          deletedContainers.filter(
            (data) => savedDeletedContainers(data.id) === -1,
          ),
        );
        setInternalDeletedLocations(
          deletedLocations.filter(
            (data) => savedDeletedLocations(data.details.location_code) === -1,
          ),
        );
        setInternalAddedContainers(
          addedContainers.filter(
            (data) => savedAddedContainers(data.id) === -1,
          ),
        );
        setInternalAddedLocations(
          addedLocations.filter(
            (data) => savedAddedLocations(data.details.location_code) === -1,
          ),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedCases, failure]);

  const updateSaveDisabled = useCallback(() => {
    if (
      !isSaving &&
      isEdit &&
      isRenameAck &&
      renderLocations.length !== 0 &&
      !_.isEqual(bucketName.trim(), oldBucketName.trim()) &&
      bucketName
    ) {
      setSaveDisabled(false);
    } else if (
      !bucketName ||
      isSaving ||
      renderLocations.length === 0 ||
      (!savedStatus &&
        _.isEqual(locations, renderLocations) &&
        _.isEqual(containers, renderContainers)) ||
      ((_.isEmpty(failure) || !failure) &&
        _.isEqual(savedRenderLocations, renderLocations) &&
        _.isEqual(savedRenderContainers, renderContainers))
    ) {
      setSaveDisabled(true);
    } else if (
      !_.isEqual(locations, renderLocations) ||
      !_.isEqual(containers, renderContainers)
    ) {
      setSaveDisabled(false);
    } else if (
      savedStatus &&
      (_.isEmpty(failure) || !failure) &&
      _.isEqual(savedRenderLocations, renderLocations) &&
      _.isEqual(savedRenderContainers, renderContainers)
    ) {
      setSaveDisabled(true);
    } else if (
      savedStatus &&
      (!_.isEqual(bucketName.trim(), oldBucketName.trim()) ||
        !_.isEqual(savedRenderLocations, renderLocations) ||
        !_.isEqual(savedRenderContainers, renderContainers))
    ) {
      setSaveDisabled(false);
    } else if (savedStatus && failure ? !_.isEmpty(failure) : false) {
      setSaveDisabled(false);
    } else {
      const savedLocations =
        savedCases && savedCases[bucketName]
          ? savedCases[bucketName]
              .map((data) => data.location_code)
              .filter((data) => data && data !== "")
          : [];
      const savedContainers =
        savedCases && savedCases[bucketName]
          ? savedCases[bucketName]
              .map((data) => data.container_identifier)
              .filter((data) => data && data !== "")
          : [];
      const availableLocations = locations
        ? locations.map((location) => {
            return location.details.location_code;
          })
        : [];
      const availableContainers = containers
        ? containers.map((container) => {
            return container.id;
          })
        : [];
      const checkLocations = [...savedLocations, ...availableLocations];
      const checkContainers = [...savedContainers, ...availableContainers];
      const unSavedLocations = renderLocations
        ? renderLocations.filter((location) => {
            return !checkLocations.includes(location.details.location_code);
          })
        : [];
      const unSavedContainers = renderContainers
        ? renderContainers.filter((container) => {
            return !checkContainers.includes(container.id);
          })
        : [];
      if (unSavedContainers.length > 0 || unSavedLocations.length > 0) {
        setSaveDisabled(false);
      }
    }
  }, [
    bucketName,
    containers,
    oldBucketName,
    failure,
    isEdit,
    isRenameAck,
    isSaving,
    locations,
    renderContainers,
    renderLocations,
    savedCases,
    savedRenderContainers,
    savedRenderLocations,
    savedStatus,
  ]);

  useEffect(() => {
    updateSaveDisabled();
  }, [updateSaveDisabled]);

  useEffect(() => {
    updateSaveDisabled();
    setShowWarningAlert(false);
    setShowSuccessAlert(false);
    if (!isSaving && savedCases?._rename?.body?.new_bucket_name) {
      setOldBucketName(savedCases._rename.body.new_bucket_name);
    }
    if (
      !isSaving &&
      isSaveClicked &&
      !_.isEmpty(savedCases) &&
      (_.isEmpty(failure) || !failure) &&
      _.isEqual(savedRenderLocations, renderLocations) &&
      _.isEqual(savedRenderContainers, renderContainers) &&
      (isRenameAck ? bucketName && _.isEqual(oldBucketName, bucketName) : true)
    ) {
      setIsSaveClicked(false);
      setShowSuccessAlert(true);
      setAlertMessage(
        t("container-tracking:Your changes have been saved successfully."),
      );
    } else if (
      !isSaving &&
      (_.isEmpty(failure) || !failure) &&
      ((_.isEmpty(savedRenderLocations)
        ? !_.isEqual(locations, renderLocations)
        : !_.isEqual(savedRenderLocations, renderLocations)) ||
        (_.isEmpty(savedRenderContainers)
          ? !_.isEqual(containers, renderContainers)
          : !_.isEqual(savedRenderContainers, renderContainers)) ||
        (isRenameAck
          ? bucketName && !_.isEqual(oldBucketName, bucketName)
          : false))
    ) {
      setShowWarningAlert(true);
      setAlertMessage(
        t("container-tracking:Be sure to hit Save below to save your changes!"),
      );
    } else {
      setShowSuccessAlert(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    savedCases,
    containers,
    locations,
    isSaving,
    isRenameAck,
    oldBucketName,
    bucketName,
    savedStatus,
    renderContainers,
    renderLocations,
    savedRenderLocations,
    savedRenderContainers,
    failure,
  ]);
  const updateSelectedData = (popupData, checkData, isLocation) => {
    const selectedValues = checkData.map((data) => {
      return isLocation ? data.details.location_code : data.id;
    });
    const updatedData = popupData.filter((data) => {
      return !selectedValues.includes(data.value);
    });
    return updatedData;
  };

  const loadLocationFilter = async (currentVal, pageNumber) => {
    let data = [];
    let metaData = null;
    const url = apiUrl(
      `/location/locations?verbose=false&everything=${inputValue}&pageSize=20&pageNumber=${pageNumber}&sortColumn=name&reverseSort=0`,
    );
    await axios
      .get(url)
      .then((res) => {
        data = res?.data?.data?.map((val) => {
          return {
            label: val.name + ` (${val?.code})`,
            value: val.code,
            data: val,
          };
        });
        metaData = res?.data?.meta;
      })
      .catch((err) => {
        console.log(err);
      });

    const dataValue = {
      options: updateSelectedData(data, renderLocations, true),
      hasMore: metaData ? metaData.currentPage < metaData.totalPages : false,
      additional: {
        pageNumber,
        prevValue: currentVal,
      },
    };
    return dataValue;
  };

  const loadContainerFilterValues = async (currentVal, pageNumber) => {
    let data = [];
    let metaData = null;
    const url = apiUrl(
      `/containertracking/api/containers-without-routes?pageSize=20&pageNumber=${pageNumber}${
        containerIdInputValue ? `&contains=${containerIdInputValue}` : ""
      }`,
    );
    const config = { headers: { "x-target-feature": trackingFeatureName } };
    await axios
      .get(url, config)
      .then((res) => {
        data = res?.data?.data?.map((val) => {
          return {
            label: val.container_id + ` (${val?.tag_identifier ?? ""})`,
            value: val.uuid,
            data: val,
          };
        });
        metaData = res?.data?.meta;
      })
      .catch((err) => {
        console.log(err);
      });

    return {
      options: updateSelectedData(data, renderContainers),
      hasMore: metaData ? metaData.currentPage < metaData.totalPages : false,
      additional: {
        pageNumber,
        prevValue: currentVal,
      },
    };
  };

  const hideHandler = () => {
    if (savedCases && !_.isEmpty(savedCases)) {
      searchRouteEntities();
    }
    setDeletedContainers([]);
    setDeletedLocations([]);
    setAddedContainers([]);
    setAddedLocations([]);
    setRenderLocations([]);
    setRenderContainers([]);
    setInternalAddedContainers([]);
    setInternalDeletedContainers([]);
    setInternalAddedLocations([]);
    setInternalDeletedLocations([]);
    setSavedRenderContainers(null);
    setSavedRenderLocations(null);
    setShowErrorAlert(false);
    setShowSuccessAlert(false);
    setShowWarningAlert(false);
    resetPopupData();
    resetSavedCases();
    resetFailedCases();
    setBucketName("");
    setIsRenameAck(false);
    setShowRenamePopup(false);
    resetSavedStatus();
    setIsSaveClicked(false);
    hide();
  };

  const deleteLocation = (location) => {
    const updatedInternalAddedLocations = internalAddedLocations.filter(
      (addlocation) => {
        return (
          addlocation.details.location_code !== location.details.location_code
        );
      },
    );

    if (_.isEqual(updatedInternalAddedLocations, internalAddedLocations)) {
      setInternalDeletedLocations([...internalDeletedLocations, location]);
    } else {
      setInternalAddedLocations(updatedInternalAddedLocations);
    }

    if (!location.isNew) {
      const updatedDeletedLocations = [...deletedLocations, location];
      setDeletedLocations(updatedDeletedLocations);
    } else {
      const updatedAddedLocations = addedLocations.filter((addlocation) => {
        return (
          addlocation.details.location_code !== location.details.location_code
        );
      });
      setAddedLocations(updatedAddedLocations);
    }
  };

  const deleteContainer = (container) => {
    const updatedInternalAddedContainers = internalAddedContainers.filter(
      (addedContainer) => {
        return container.id !== addedContainer.id;
      },
    );

    if (_.isEqual(updatedInternalAddedContainers, internalAddedContainers)) {
      setInternalDeletedContainers([...internalDeletedContainers, container]);
    } else {
      setInternalAddedContainers(updatedInternalAddedContainers);
    }

    if (addedContainers.indexOf(container) < 0) {
      const updatedDeletedContainers = [...deletedContainers, container];
      setDeletedContainers(updatedDeletedContainers);
    } else {
      const updatedAddedContainers = addedContainers.filter(
        (addedContainer) => {
          return container.id !== addedContainer.id;
        },
      );
      setAddedContainers(updatedAddedContainers);
    }
  };

  const addLocation = () => {
    const locationData = selectedLocations.map((location) => {
      return {
        isNew: true,
        details: {
          location_id: location.data.id,
          location_code: location.data.code,
          location_name: location.data.name,
          address: location.data.address,
          address2: location.data.address2,
          lad: location.data.lad,
          city: location.data.city,
          state: location.data.state,
          zip: location.data.postal_code,
          country: location.data.country,
        },
      };
    });
    setAddedLocations([...addedLocations, ...locationData]);
    const addedLocationCodes = locationData.map(
      (data) => data.details.location_code,
    );
    const updatedInternalDeletedLocations = internalDeletedLocations.filter(
      (data) => addedLocationCodes.indexOf(data.details.location_code) === -1,
    );
    setInternalDeletedLocations(updatedInternalDeletedLocations);
    setInternalAddedLocations([...internalAddedLocations, ...locationData]);
    setSelectedLocations(null);
  };

  const addContainer = () => {
    const updatedSelectedContainers = selectedContainers.map((container) => {
      return {
        containerId: container.data.container_id,
        type: container.data.type,
        id: container.data.uuid,
        tagId: container.data.tag_identifier,
      };
    });
    const addedContainerUuids = updatedSelectedContainers.map(
      (data) => data.id,
    );
    const updatedInternalDeletedContainers = internalDeletedContainers.filter(
      (data) => addedContainerUuids.indexOf(data.id) === -1,
    );
    setInternalDeletedContainers(updatedInternalDeletedContainers);
    setInternalAddedContainers([
      ...internalAddedContainers,
      ...updatedSelectedContainers,
    ]);
    setAddedContainers([...addedContainers, ...updatedSelectedContainers]);
    setSelectedContainers(null);
  };

  const saveClickHandler = () => {
    setShowErrorAlert(false);
    setIsSaveClicked(true);
    const updatedBucketName =
      (savedCases && savedCases.rename && savedCases.rename.status === 200) ||
      (isEdit ? bucketName !== oldBucketName : true)
        ? bucketName
        : oldBucketName;

    updateData(
      updatedBucketName,
      oldBucketName,
      internalAddedLocations,
      internalDeletedLocations,
      internalAddedContainers,
      internalDeletedContainers,
      !isEdit && !savedCases,
    );
  };

  return (
    <Modal
      size="lg"
      backdrop={"static"}
      show={show}
      onHide={() => hideHandler()}
    >
      <Modal.Header
        closeButton={!isSaving}
        css={{
          backgroundColor: Colors.background.LIGHT_GRAY,
          color: Colors.background.DARK_BLUE,
          padding: "1rem",
        }}
        title={
          <div data-qa="title-modal" style={{ fontWeight: "500" }}>
            {isEdit
              ? t("container-tracking:Edit Route")
              : t("container-tracking:Manage Route")}
          </div>
        }
      />

      <Modal.Body
        style={{
          backgroundColor: Colors.background.LIGHT_GRAY,
          padding: "0px",
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            css={{
              width: "50%",
              borderRight: "1px solid #dee2e6",
              padding: "10px 10px 0 10px",
            }}
          >
            <FormLabel
              css={{
                color: Colors.text.GRAY,
                fontSize: "1em",
                fontWeight: "bold",
              }}
            >
              {t("container-tracking:Route Name")}
            </FormLabel>
            {!showRenamePopup ? (
              <FormGroup css={{ marginBottom: "1rem", width: "60%" }}>
                <FormControl
                  isInvalid={!bucketName}
                  value={bucketName}
                  disabled={isSaving}
                  onChange={(event) => {
                    if (isEdit ? isRenameAck : true) {
                      setBucketName(event.target.value);
                    } else {
                      setShowRenamePopup(true);
                      setBucketName(event.target.value);
                    }
                  }}
                />
                <FormControl.Feedback type="invalid">
                  <div>
                    {t(
                      "container-tracking:Route name cannot have an empty value",
                    )}
                  </div>
                </FormControl.Feedback>
              </FormGroup>
            ) : (
              <Alert
                css={{ width: "100%" }}
                variant={AlertVariant.Warning}
                show={showRenamePopup}
              >
                {t(
                  "container-tracking:You are attempting to rename an existing route. Do you want to proceed?",
                )}
                <div
                  css={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: " flex-end",
                    padding: "0.75rem",
                    marginTop: "0.5rem",
                    gap: "1em",
                    borderTop: "1px solid #dee2e6",
                  }}
                >
                  <Button
                    style={{
                      background: "white",
                      borderColor: "#ccc",
                      color: Colors.background.DARK_BLUE,
                    }}
                    onClick={() => {
                      setShowRenamePopup(false);
                      setBucketName(oldBucketName);
                    }}
                  >
                    {t("container-tracking:No")}
                  </Button>
                  <Button
                    css={{
                      display: "inline-flex",
                      flexFlow: " row nowrap",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "0.5em",
                    }}
                    variant="success"
                    onClick={() => {
                      setIsRenameAck(true);
                      setShowRenamePopup(false);
                    }}
                  >
                    {t("container-tracking:Yes")}
                  </Button>
                </div>
              </Alert>
            )}
            <FormLabel
              css={{
                color: Colors.text.GRAY,
                fontSize: "1em",
                fontWeight: "bold",
              }}
            >
              {t("container-tracking:Route Locations")}
            </FormLabel>
            <SimpleBar
              autoHide={false}
              css={{
                width: "100%",
                height: "100%",
                maxHeight: "280px",
              }}
            >
              <div
                css={{
                  width: "100%",
                  paddingRight: "5%",
                }}
              >
                <ul
                  css={{
                    margin: 0,
                    padding: "0 0 0 1em",
                    listStyle: "none",
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.125em",
                  }}
                >
                  <Alert
                    css={{ width: "100%" }}
                    variant={AlertVariant.Warning}
                    show={!renderLocations || renderLocations.length === 0}
                  >
                    {t(
                      "container-tracking:At least one assigned location is required to save a new route.",
                    )}
                  </Alert>
                  {renderLocations?.map((location, index) => {
                    const {
                      location_name,
                      location_code,
                      address,
                      address2,
                      state,
                      city,
                      country,
                      zip,
                    } = location.details;
                    return (
                      <li key={index}>
                        <div
                          css={{
                            display: "flex",
                            flexDirection: "row",
                            paddingBottom: ".5em",
                            alignItems: "center",
                            justifyContent: "space-between;",
                          }}
                        >
                          <LocationAddressComplex
                            name={{
                              value: `${location_name} (${location_code})`,
                              rightContent: location.isNew ? (
                                <span
                                  css={{
                                    color: Colors.text.GREEN,
                                    fontSize: "0.7rem",
                                    paddingLeft: "2px",
                                  }}
                                >
                                  {t("container-tracking:New")}
                                </span>
                              ) : null,
                              style: {
                                color: "#222",
                                fontSize: "1em !important", // since we need to override existing size we are using important
                              },
                            }}
                            lookupCountryNameFromCode={false}
                            addressColor={Colors.text.GRAY}
                            address1={address}
                            address2={address2}
                            city={city}
                            state={state}
                            postalCode={zip}
                            country={country}
                          />
                          <Button
                            variant="danger"
                            onClick={() => deleteLocation(location)}
                            disabled={isSaving}
                          >
                            {t("container-tracking:Delete")}
                          </Button>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div
                css={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1em",
                }}
              >
                <div
                  css={{
                    width: "60%",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <RouteManagementAsyncSelect
                    isDisabled={isSaving}
                    loadOptions={loadLocationFilter}
                    values={selectedLocations}
                    onInputChange={(val) => {
                      setInputValue(val);
                    }}
                    inputValue={inputValue}
                    closeMenuOnSelect={false}
                    menuPosition="fixed"
                    onChange={(data) => {
                      setSelectedLocations(data);
                    }}
                  />
                </div>
                <div>
                  <Button
                    variant="primary"
                    onClick={() => addLocation()}
                    disabled={
                      isSaving ||
                      (selectedLocations ? _.isEmpty(selectedLocations) : true)
                    }
                  >
                    {t("container-tracking:Add Location")}
                  </Button>
                </div>
              </div>
            </SimpleBar>
          </div>
          <div
            css={{
              padding: "10px 10px 0 10px",
              width: "50%",
            }}
          >
            <FormLabel
              css={{
                color: Colors.text.GRAY,
                fontSize: "1em",
                fontWeight: "bold",
              }}
            >
              {t("container-tracking:Assigned Containers")}
            </FormLabel>
            <SimpleBar
              autoHide={false}
              css={{
                width: "100%",
                height: "100%",
                maxHeight: "280px",
              }}
            >
              <div
                css={{
                  width: "100%",
                  paddingRight: "5%",
                }}
              >
                <ul
                  css={{
                    margin: 0,
                    padding: "0 0 0 1em",
                    listStyle: "none",
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.125em",
                  }}
                >
                  {renderContainers?.map((container) => {
                    return (
                      <li key={container.id}>
                        <div
                          css={{
                            display: "flex",
                            flexDirection: "row",
                            paddingBottom: ".5em",
                            alignItems: "center",
                            justifyContent: "space-between;",
                          }}
                        >
                          <div>
                            <span
                              style={{ color: Colors.text.GRAY }}
                              data-qa="text-address-location"
                            >
                              {container.containerId} ({container?.tagId})
                            </span>
                          </div>
                          <Button
                            variant="danger"
                            onClick={() => deleteContainer(container)}
                            disabled={isSaving}
                          >
                            {t("container-tracking:Delete")}
                          </Button>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div
                css={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1em",
                }}
              >
                <div
                  css={{
                    width: "60%",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <RouteManagementAsyncSelect
                    isDisabled={isSaving}
                    loadOptions={loadContainerFilterValues}
                    values={selectedContainers}
                    onInputChange={(val) => {
                      setContainerIdInputValue(val);
                    }}
                    inputValue={inputValue}
                    closeMenuOnSelect={false}
                    menuPosition="fixed"
                    onChange={(data) => {
                      setSelectedContainers(data);
                    }}
                  />
                </div>
                <div>
                  <Button
                    variant="primary"
                    onClick={() => addContainer()}
                    disabled={
                      isSaving ||
                      (selectedContainers
                        ? _.isEmpty(selectedContainers)
                        : true)
                    }
                  >
                    {t("container-tracking:Add Containers")}
                  </Button>
                </div>
              </div>
            </SimpleBar>
          </div>
        </div>
        <Alert
          show={showWarningAlert || showErrorAlert || showSuccessAlert}
          variant={
            showErrorAlert
              ? AlertVariant.Danger
              : showSuccessAlert
              ? AlertVariant.Success
              : AlertVariant.Warning
          }
          onDismiss={() => {
            setShowErrorAlert(false);
            setShowSuccessAlert(false);
            setShowWarningAlert(false);
          }}
          dismissible={true}
        >
          {t(`container-tracking:${alertMessage}`)}
        </Alert>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
        <Button
          variant={"transparent"}
          disabled={isSaving}
          onClick={() => hideHandler()}
        >
          {savedStatus && (failure ? _.isEmpty(failure) : true)
            ? t("container-tracking:Close")
            : t("container-tracking:Cancel")}
        </Button>
        <Button
          css={{
            display: "inline-flex",
            flexFlow: " row nowrap",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "0.5em",
          }}
          variant="success"
          disabled={saveDisabled}
          onClick={saveClickHandler}
        >
          {isSaving ? (
            <Icon src={faSpinner} size={FontSize.size20} spin />
          ) : null}
          {t("container-tracking:Save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

RouteManagementPopup.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  isEdit: PropTypes.bool,
  defaultBucketName: PropTypes.string,
  locations: PropTypes.array,
  containers: PropTypes.array,
  failure: PropTypes.object,
  savedStatus: PropTypes.bool,
  updateData: PropTypes.func,
  resetFailedCases: PropTypes.func,
  isSaving: PropTypes.bool,
};
