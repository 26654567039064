/** @jsxImportSource @emotion/react */
import _ from "lodash";
import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import { useTranslation, Trans } from "react-i18next";
import { useEffect, useState } from "react";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import Colors from "styles/colors";
import PropTypes from "prop-types";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Loader from "react-loader";
import { Search } from "components/templates/Search.template";
import { Tabs } from "components/molecules/Tabs.molecule";
import { Button } from "components/atoms/Button.atom";
import { LocationSelect } from "../components/organisms/LocationSelect";
import CriticalPartsSearchBarContainer from "./components/CriticalParts.SearchBarContainer";
import { useColumns } from "../table/CriticalParts.columns";
import { useCriticalPartsListData } from "../hooks/useCriticalPartsListData";
import { Icon } from "components/atoms/Icon.atom";
import { Text } from "components/atoms/Text.atom";
import { SortBySelect } from "../components/organisms/SortBySelect";
import {
  fetchCpEnabled,
  fetchLocationById,
} from "../redux/LocationSelectState";
import CriticalPartsFiltersContainer from "./components/CriticalParts.FilterSectionContainer";

const SearchResultsTotalCountLabel = () => {
  return (
    <Trans ns="critical-parts">
      <Text color={Colors.text.DARKER_GRAY}>Viewing</Text> <Search.TotalCount />{" "}
      <Text bold>Active Parts</Text>
    </Trans>
  );
};

const CriticalPartsSearch = ({
  searchText,
  userPreferencesIsLoading,
  userPreferencesIsLoadingError,
  setSelectedPlantLocationCode,
  userPreferences,
  filterParams,
  setResetFilters,
  setPreferences,
  setSortColumn,
  setSortReverse,
  sortColumn,
  sortReverse,
}) => {
  const { t } = useTranslation("critical-parts");
  useSetTitleOnMount(t("critical-parts:Critical Parts"));

  useTrackWithMixpanelOnce("Viewing Page: Critical Parts / Search");

  const [selectedLocationCode, setSelectedLocationCode] = useState("");
  const [lastClickedLocationCode, setLastClickedLocationCode] = useState("");
  const [location, setLocation] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const defaultLocationId =
    userPreferences?.criticalPartsPreference?.defaultLocationId ?? "";

  const internalSelectedLocationId = location?.data?.id ?? null;

  const setPagination = (newPageNumber, newPageSize) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
  };

  const resetSearch = () => {
    setResetFilters(true);
  };

  const setDefaultLocation = (locationId) => {
    const preferences = {
      ...userPreferences,
      criticalPartsPreference: { defaultLocationId: locationId },
    };
    setPreferences(preferences);
  };

  const rowClickHandler = (row, cell) => {
    if (!row || !cell) {
      return;
    }
    // dispatch({
    //   type: "CRITICAL_PARTS_DETAILS",
    //   payload: {
    //     location_id: selectedLocationCode,
    //     part_number: row.original?.partNumber,
    //   },
    // });
  };

  const columns = useColumns();

  const { isFetchingList, criticalPartsList, refetch } =
    useCriticalPartsListData(
      selectedLocationCode,
      searchText,
      pageNumber,
      pageSize,
      sortColumn,
      sortReverse,
      filterParams,
    );

  useEffect(() => {
    if (criticalPartsList) {
      setTotalCount(criticalPartsList.meta.totalCount);
      setTotalPages(criticalPartsList.meta.totalPages);
    }
  }, [criticalPartsList]);

  useEffect(() => {
    async function fetchLocation() {
      if (defaultLocationId) {
        let isEnabled = await fetchCpEnabled(`${defaultLocationId}`);
        let location = await fetchLocationById(`${defaultLocationId}`);
        setLocation(location);
        if (!isEnabled || location?.data?.is_active === false) {
          setDefaultLocation(null);
        } else {
          setSelectedLocationCode(location?.data?.code);
          setSelectedPlantLocationCode(location?.data?.code);
        }
      }
    }
    fetchLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultLocationId]);
  return (
    <Tabs
      fullWidthTabs={false}
      style={{ marginLeft: "1em", marginRight: "1em" }}
    >
      <Tabs.TabList
        postTabContent={
          <div
            css={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",
            }}
          >
            {selectedLocationCode && (
              <>
                {userPreferencesIsLoading ? (
                  <Icon
                    src={faSpinner}
                    spin
                    data-qa="default-location-icon-spinner"
                    display="inline-block"
                  />
                ) : userPreferencesIsLoadingError &&
                  selectedLocationCode === lastClickedLocationCode ? (
                  <Button
                    data-qa="default-location-button-retry"
                    variant="link"
                    css={{
                      color: Colors.text.GRAY,
                      ":hover": {
                        color: Colors.background.DARK_BLUE,
                      },
                      display: "flex",
                      alignItems: "center",
                      marginRight: "1em",
                    }}
                    onClick={() => {
                      setLastClickedLocationCode(selectedLocationCode);
                      setDefaultLocation(internalSelectedLocationId);
                    }}
                  >
                    {t("critical-parts:Error, Retry")}
                  </Button>
                ) : defaultLocationId === internalSelectedLocationId ? (
                  <Text
                    data-qa="text-default-location"
                    css={{
                      color: Colors.text.GRAY,
                      display: "flex",
                      alignItems: "center",
                      marginRight: "1em",
                    }}
                  >
                    {t("critical-parts:Default")}
                  </Text>
                ) : (
                  <Button
                    data-qa="button-set-default-location"
                    variant="link"
                    css={{
                      color: Colors.text.GRAY,
                      ":hover": {
                        color: Colors.background.DARK_BLUE,
                      },
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setLastClickedLocationCode(selectedLocationCode);
                      setDefaultLocation(internalSelectedLocationId);
                    }}
                  >
                    {t("critical-parts:Set as Default")}
                  </Button>
                )}
              </>
            )}

            <LocationSelect
              data-qa="dropdown-locations"
              selectedLocation={location}
              setSelectedLocation={(location) => {
                setLocation(location);
                setSelectedLocationCode(location?.data?.code ?? null);
                setSelectedPlantLocationCode(location?.data?.code ?? null);
              }}
              defaultLocationId={defaultLocationId}
            />
          </div>
        }
      >
        <div css={{ width: "25%", borderColor: Colors.tabs.BORDER }}>
          <Tabs.Tab>{t("critical-parts:Part List")}</Tabs.Tab>
        </div>
      </Tabs.TabList>
      <Tabs.TabPanel>
        <Loader loaded={!_.isEmpty(selectedLocationCode)}>
          {selectedLocationCode === "" ? (
            <div
              css={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxHeight: "100%",
              }}
            >
              {t("critical-parts:Select a location to view parts")}
            </div>
          ) : (
            <Search
              showFilters={true}
              FiltersContainer={CriticalPartsFiltersContainer}
              toggleShowFilters={() => {}}
              totalCount={totalCount}
              searchResults={criticalPartsList?.data ?? []}
              TotalCountLabel={SearchResultsTotalCountLabel}
              isLoading={isFetchingList}
              emptyDataOnLoad={true}
              isShowingAdvancedSearchButton={false}
              hideSavedSearch={true}
              disableDownloadData={true}
              sortByDropDownContent={
                <SortBySelect
                  sort={sortColumn}
                  setSort={setSortColumn}
                  direction={sortReverse}
                  setDirection={setSortReverse}
                />
              }
              SearchBarContainer={CriticalPartsSearchBarContainer}
              resetSearch={resetSearch}
              tableProps={{
                columns,
                isLoading: isFetchingList,
                showPagination: true,
                fixPaginationToBottom: true,
                isManualPagination: true,
                isManualSorting: true,
                pageIndex: pageNumber,
                pageSize: pageSize,
                pageCount: totalPages,
                rowClickHandler: rowClickHandler,
                onPageChange: (newPageNumber) => {
                  setPagination(newPageNumber, pageSize);
                },
                onPageSizeChange: (newPageSize) => {
                  setPagination(0, newPageSize);
                },
              }}
            />
          )}
        </Loader>
      </Tabs.TabPanel>
    </Tabs>
  );
};

CriticalPartsSearch.propTypes = {
  userPreferences: PropTypes.object.isRequired,
  setPreferences: PropTypes.func.isRequired,
  userPreferencesIsLoading: PropTypes.bool.isRequired,
  userPreferencesIsLoadingError: PropTypes.bool.isRequired,
  setSortColumn: PropTypes.func,
  setSortReverse: PropTypes.func,
  sortColumn: PropTypes.string,
  sortReverse: PropTypes.bool,
  setSelectedPlantLocationCode: PropTypes.func.isRequired,
  filterParams: PropTypes.object.isRequired,
  setResetFilters: PropTypes.func.isRequired,
};

export default CriticalPartsSearch;
