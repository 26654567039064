import {
  useQuery as react_query_useQuery,
  QueryFunctionContext,
  QueryKey,
} from "@tanstack/react-query";

/**
 * A configuration object for `createQueryRequestHook`.
 *
 * @template TData The response body of a successful request.
 */
interface QueryRequestConfig<TData, TQueryKey extends QueryKey = QueryKey> {
  /**
   * A serializable array of values used to cache the query's data.
   *
   * @example
   * ["shipments"]
   * ["shipments", "details"]
   * ["shipments", { pageNubmer: 0, pageSize: 20 }]
   *
   * @reference https://tanstack.com/query/v5/docs/framework/react/guides/query-keys
   */
  queryKey: TQueryKey;
  /**
   * Query function is the function that should return a promise.
   */
  queryFn: (context: QueryFunctionContext) => Promise<TData>;
  /**
   * Initial data of the state
   */
  initialData?: TData;
  /**
   * Indicates whether the data should be refetched when the window regains focus.
   */
  refetchOnWindowFocus: boolean;
  /**
   *  Query will automatically retry the query if that query's request
   *  has not reached the max number of consecutive retries.
   *  If set to `true`, it will retry indefinitely. If set to `false`, it will not retry.
   *  If set to a number, it will retry the specified number of times.
   */
  retry?: boolean | number;
}

/**
 * The `createQueryRequestHook` function will return a React hook that manages
 * state of the request made with the provided `queryFn` function.
 *
 * This uses `useQuery` from `@tanstack/react-query` under the hood.
 * Please refer [their documentation](https://tanstack.com/query/v5/docs/framework/react/reference/useQuery)
 * for more details.
 *
 * @template TData The response body of a successful request.
 * @template TError The response body of a failed request.
 */

export function createQueryRequestHook<TData, TError>(
  config: QueryRequestConfig<TData, QueryKey>,
) {
  return function useQuery() {
    return react_query_useQuery<TData, TError>({
      queryKey: config.queryKey,
      queryFn: config.queryFn,
      initialData: config.initialData,
      refetchOnWindowFocus: config.refetchOnWindowFocus,
      retry: config.retry,
    });
  };
}
