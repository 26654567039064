/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { faStopwatch } from "@fortawesome/pro-regular-svg-icons";

import { MapCoordinateType } from "modules/map/components/map-coordinate-definition";
import { NO_PAGINATION, Tabs } from "components/molecules/Tabs.molecule";
import ExportModal from "modules/exports/ExportModal";
import { ExportAlert } from "modules/exports/ExportAlert";
import Colors from "styles/colors";

import UpdatesTable from "./UpdatesTable";
import { StopsTable } from "./shipment-detail-styled-components/StopsTable";
import TripPlanTable from "./shipment-detail-styled-components/TripPlanTable";

import { getDayOfWeek } from "../../utils/translation-utils";
import { useExport, ExportProvider } from "../exports/hooks/useExport";
import LinkButton from "../../vendor/signal-widgets/components/link-button";
import { CoordinatesTable } from "components-old/CoordinatesTable";
import DetailsTable from "components-old/DetailsTable";
import { isCarrier } from "shared/utils/organizations.utils";
import { Features } from "../auth/Authorization";
import { getAuthorization } from "../auth/AuthorizationSelectors";
import { isExtraSmall, MediaQueries } from "../../components/responsive";
import { stringToMoment } from "utils/date-time";

const PartsTable = ({ parts = [], t }) => {
  let rows = [];

  // TODO: Will change API to have the same format for all orgs.
  // "PartView" parts have a different object shape in shipments.
  // If it has `partNumber` (always present but possibly null), this is a
  // PartView part. If not, assume these are part references.
  const isPartViewPart = parts?.[0]?.hasOwnProperty("partNumber") ?? false;
  let headers = [t("Part"), t("Planned"), t("Actual")];

  if (isPartViewPart) {
    headers.push(t("Status"));

    rows = _.sortBy(parts, ["name", "ts"]).map((part) => {
      return (
        <tr key={part.partNumber} data-qa="rows-parts-table">
          <td>{part.name}</td>
          <td>{part.plannedQuantity}</td>
          <td>{part.actualQuantity}</td>
          <td>
            {part.lifecycleState &&
            part.lifecycleState.toLowerCase() === "backorder"
              ? `${t("shipment-details:Backorder")}`
              : "-"}
          </td>
        </tr>
      );
    });
  } else {
    const getPartsForTable = (parts = []) => {
      // Combine ASN parts and part values into one row for each unique part name
      let combinedParts = {};

      // Sort the parts by name, then qualifier (partType), then by created
      // This will give us each part name and part type grouped together
      //   ordered from earliest to latest created time.
      const sortedParts = _.sortBy(
        parts.map((p) => ({ ...p, created_at: stringToMoment(p.created_at) })),
        ["name", "qualifier", "created_at"],
      );

      // We can then iterate over the sorted array and assume
      //   subsequent partName/qualifier pairs are the latest ones to display
      sortedParts.forEach((p) => {
        const partType = p.qualifier;
        /* H1-607 change the partName index to account for names that have spaces,
        add name to combined parts to preserve that original name for display */
        const partName = p.name.replace(/\s/g, "");
        if (partName in combinedParts) {
          combinedParts[partName][partType] = p.quantity;
        } else {
          // Create the new combined part object at partName
          combinedParts[partName] = { [partType]: p.quantity, name: p.name };
        }
      });

      return combinedParts;
    };
    const combinedParts = getPartsForTable(parts);
    rows = Object.keys(combinedParts).map((p, i) => {
      const partValues = combinedParts[p];
      /* DEV-609 Parts with the qualifier 'part' should
       * be in the Planned column, and part with the
       * qualifier 'asnpart' should be in the Actual column.
       */
      return (
        <tr key={i} data-qa="rows-parts-table">
          <td>{partValues.name}</td>
          <td>{partValues.part}</td>
          <td>{partValues.asnpart}</td>
        </tr>
      );
    });
  }

  return <DetailsTable headers={headers} rows={rows} />;
};

PartsTable.propTypes = {
  parts: PropTypes.array.isRequired,
  creatorOrgId: PropTypes.number,
  t: PropTypes.func.isRequired,
};

const getReferenceLabelFromQualifier = (qualifier, mode, t) => {
  switch (qualifier) {
    case "CN":
      if (
        mode.toLowerCase() === "rail" ||
        mode.toLowerCase() === "intermodal"
      ) {
        return t("Carrier Reference Number");
      } else {
        return t("Pro Number");
      }
    case "order_number":
      return t("Order/Purchase Order Number");
    case "mode":
      return t("Mode");
    case "service_code":
      return t("Service Code");
    default:
      return qualifier;
  }
};

const ReferencesTable = ({ mode, references, equipment_type_ng, t }) => {
  const referencesToRemove = ["part", "asnpart", "SCAC", "CARRIER", "route_id"];

  /* DEV-1635 LTL references are handled like Truck */
  let rows = references
    .filter((r) => !_.includes(referencesToRemove, r.qualifier))
    .sort((r1, r2) => {
      let qualifier1 = r1.qualifier.toLowerCase();
      let qualifier2 = r2.qualifier.toLowerCase();
      let createdAt1 = r1.created_at.valueOf();
      let createdAt2 = r2.created_at.valueOf();

      if (qualifier1 < qualifier2) {
        return -1;
      }
      if (qualifier1 > qualifier2) {
        return 1;
      }

      if (createdAt1 < createdAt2) {
        return -1;
      }
      if (createdAt1 > createdAt2) {
        return 1;
      }

      return 0;
    })
    .map((r, i) => {
      return (
        <tr key={i} data-qa="rows-references-table">
          <td>{getReferenceLabelFromQualifier(r.qualifier, mode, t)}</td>
          <td>{r.value}</td>
        </tr>
      );
    });

  if (equipment_type_ng && equipment_type_ng.length > 0) {
    rows.push(
      <tr key="ET">
        <td>{t("Equipment Type")}</td>
        <td>{equipment_type_ng}</td>
      </tr>,
    );
  }

  return <DetailsTable headers={[t("Qualifier"), t("Value")]} rows={rows} />;
};

ReferencesTable.propTypes = {
  equipment_type_ng: PropTypes.string,
  mode: PropTypes.string,
  references: PropTypes.array,
  t: PropTypes.func.isRequired,
};

const VINsTable = ({ vins, t }) => {
  const dispatch = useDispatch();
  const authorization = useSelector(getAuthorization);
  const isHotLinkEnabled = authorization.isAuthorized(
    [],
    [Features.FINISHED_VEHICLE],
  );
  const pushVinDetailsPageHandler = (vin) => () =>
    dispatch({ type: "VIN_DETAILS", payload: { entity_id: vin.name } });
  const rows = vins.map((vin) => {
    const vinCellContents = isHotLinkEnabled ? (
      <LinkButton
        style={{
          color: Colors.linkText,
          cursor: "pointer",
          fontSize: "small",
        }}
        onClick={pushVinDetailsPageHandler(vin)}
      >
        {vin.name}
      </LinkButton>
    ) : (
      <React.Fragment>{vin.name}</React.Fragment>
    );
    return (
      <tr key={`shipment-vin-${vin.name}`}>
        <td>{vin.qualifier}</td>
        <td>{vinCellContents}</td>
      </tr>
    );
  });
  return <DetailsTable headers={[t("Name"), t("VIN")]} rows={rows} />;
};

VINsTable.propTypes = {
  t: PropTypes.func.isRequired,
  vins: PropTypes.array,
};

const TripPlanHeader = ({ shipment, tripPlan }) => {
  const {
    requestExport,
    exportIdentifier,
    fileName,
    isExporting,
    exportFailed,
  } = useExport();

  const { t } = useTranslation("shipment-details");

  // The cutoff is changed if the currentSchedule doesn't match the originalSchedule
  let cutoffChange =
    tripPlan.currentSchedule &&
    tripPlan.originalSchedule &&
    tripPlan.currentSchedule !== tripPlan.originalSchedule;

  let missedCutoff =
    cutoffChange && tripPlan.currentSchedule > tripPlan.originalSchedule;

  return (
    <React.Fragment>
      <div
        style={{
          backgroundColor: !missedCutoff
            ? Colors.background.DARK_TEAL
            : Colors.background.DARKER_YELLOW,
          color: "white",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          minHeight: "2em",
        }}
      >
        {!cutoffChange ? (
          <div style={{ flex: 1, paddingLeft: 65, fontWeight: "bold" }}>
            {t("Following [[[dayOfWeek]]] Trip Plan", {
              dayOfWeek: getDayOfWeek(tripPlan.currentSchedule, t),
            })}
          </div>
        ) : (
          <React.Fragment>
            <div style={{ width: 65, textAlign: "center" }}>
              <FontAwesomeIcon icon={faStopwatch} />
            </div>
            <div style={{ flex: 1, fontWeight: "bold" }}>
              {t(
                "[[[changeDir]]] Cutoff For [[[originalDayOfWeek]]] - Following [[[currentDayOfWeek]]] Schedule",
                {
                  originalDayOfWeek: getDayOfWeek(tripPlan.originalSchedule, t),
                  currentDayOfWeek: getDayOfWeek(tripPlan.currentSchedule, t),
                  changeDir: t(missedCutoff ? "Missed" : "Ahead of"),
                },
              )}
            </div>
          </React.Fragment>
        )}
        <div
          css={{
            textAlign: "right",
            marginRight: 10,
            paddingLeft: 10,
            fontStyle: "italic",
            cursor: "pointer",
            fontSize: "0.9em",
            ":hover": {
              textDecoration: "underline",
            },
          }}
          onClick={() => {
            if (isExporting) {
              return;
            }

            requestExport({ shipmentId: shipment.id });
          }}
        >
          {t("Download Complete Trip Plan")}
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            css={{
              display: isExporting ? "inline" : "none",
              marginLeft: 5,
            }}
          />
          <FontAwesomeIcon
            icon={faDownload}
            style={{ display: isExporting ? "none" : "inline", marginLeft: 5 }}
          />
        </div>
      </div>
      <ExportModal exportIdentifier={exportIdentifier} exportName={fileName} />
      <ExportAlert exportFailed={exportFailed} className="m-2" />
    </React.Fragment>
  );
};

TripPlanHeader.propTypes = {
  shipment: PropTypes.object.isRequired,
  tripPlan: PropTypes.object.isRequired,
};

const hasParts = (shipment) =>
  shipment.part_numbers != null && shipment.part_numbers.length > 0;
const hasRefs = (shipment) =>
  shipment.shipment_references != null &&
  shipment.shipment_references.length > 0;
const hasVins = (shipment) => shipment.vins && shipment.vins.length > 0;

const ShipmentDetailTabs = ({
  shipment,
  addCoordinate,
  clearCoordinatesByType,
  selectedMapCoordinate,
  organization,
  tripPlan,
  tripPlanIsLoading,
  showVinsTab = true,
}) => {
  const { t } = useTranslation("shipment-details");

  /* H1-1057 Only show Parts tab on shipment details page if user’s organization matches the shipper organization on the shipment */
  /* H1-1104 Add same check for References tab */
  const isCreator = shipment.created_by_org_id === organization.organization_id;
  const isCreatorOrCarrier = isCreator || isCarrier;

  // Used to determine if we are switching from this tab in <Tabs/>'s onSelect
  const coordinatesTabTitle = t("Coordinates");

  return (
    <Tabs
      fullWidthTabs
      style={{ margin: "1em" }}
      css={{
        [MediaQueries.mediumAndDown]: {
          fontSize: 12,
        },
        [MediaQueries.large]: {
          fontSize: 10,
        },
        [MediaQueries.extraLarge]: {
          fontSize: 12,
        },
      }}
      onSelect={(index, lastIndex, e) => {
        // If we are moving away from the coordinate tab
        if (e.target.textContent !== coordinatesTabTitle) {
          clearCoordinatesByType([MapCoordinateType.SELECTED_COORDINATE]);
        }
      }}
    >
      <Tabs.TabList maxTabs={isExtraSmall() ? 4 : NO_PAGINATION}>
        <Tabs.Tab data-qa="tab-button-updates">{t("Updates")}</Tabs.Tab>
        {tripPlan ? (
          <Tabs.Tab data-qa="tab-button-tripplan" disabled={tripPlanIsLoading}>
            {t("Trip Plan")}
          </Tabs.Tab>
        ) : (
          <Tabs.Tab data-qa="tab-button-stops" disabled={tripPlanIsLoading}>
            {t("Stops")}
          </Tabs.Tab>
        )}
        <Tabs.Tab data-qa="tab-button-coordinates">
          {coordinatesTabTitle}
        </Tabs.Tab>
        {hasParts(shipment) && isCreatorOrCarrier ? (
          <Tabs.Tab data-qa="tab-button-parts">{t("Parts")}</Tabs.Tab>
        ) : null}
        {showVinsTab && hasVins(shipment) && (
          <Tabs.Tab data-qa="tab-button-vins">{t("VINs")}</Tabs.Tab>
        )}
        {hasRefs(shipment) && isCreatorOrCarrier && (
          <Tabs.Tab style={{ marginRight: 0 }} data-qa="tab-button-references">
            {t("References")}
          </Tabs.Tab>
        )}
      </Tabs.TabList>

      <Tabs.TabPanel className="rounded-bottom" data-qa="panel-updates">
        <UpdatesTable
          statuses={shipment.shipment_statuses}
          exceptions={shipment.shipment_exceptions}
          organization={organization}
        />
      </Tabs.TabPanel>

      {tripPlan ? (
        <Tabs.TabPanel className="rounded-bottom" data-qa="panel-tripplan">
          <ExportProvider
            url="/shipment-trip-plan/trip_plan"
            fileName="trip-plan-export"
          >
            <TripPlanHeader shipment={shipment} tripPlan={tripPlan} />
          </ExportProvider>
          <TripPlanTable tripPlan={tripPlan} />
        </Tabs.TabPanel>
      ) : (
        <Tabs.TabPanel className="rounded-bottom" data-qa="panel-stops">
          <StopsTable
            stops={shipment.shipment_stops}
            mode={shipment.mode_name}
            isMultileg={
              shipment?.shipment_details?.is_multileg &&
              shipment?.shipment_details?.relation?.toLowerCase() === "parent"
            }
            references={shipment.shipment_references}
            active_exceptions_ng={shipment.active_exceptions_ng}
          />
        </Tabs.TabPanel>
      )}

      <Tabs.TabPanel className="rounded-bottom" data-qa="panel-coordinates">
        <CoordinatesTable
          coords={
            shipment.current_location ? shipment.current_location.updates : []
          }
          addCoordinate={addCoordinate}
          clearCoordinatesByType={clearCoordinatesByType}
          selectedMapCoordinate={selectedMapCoordinate}
        />
      </Tabs.TabPanel>

      {hasParts(shipment) && isCreatorOrCarrier ? (
        <Tabs.TabPanel className="rounded-bottom" data-qa="panel-parts">
          <PartsTable parts={shipment.part_numbers} t={t} />
        </Tabs.TabPanel>
      ) : null}
      {showVinsTab && hasVins(shipment) && (
        <Tabs.TabPanel className="rounded-bottom" data-qa="panel-vins">
          <VINsTable vins={shipment.vins} t={t} />
        </Tabs.TabPanel>
      )}
      {hasRefs(shipment) && isCreatorOrCarrier ? (
        <Tabs.TabPanel className="rounded-bottom" data-qa="panel-references">
          <ReferencesTable
            references={shipment.shipment_references}
            mode={shipment.mode_name}
            equipment_type_ng={shipment.equipment_type_ng}
            t={t}
          />
        </Tabs.TabPanel>
      ) : null}
    </Tabs>
  );
};

ShipmentDetailTabs.propTypes = {
  organization: PropTypes.object,
  shipment: PropTypes.object,
  tripPlan: PropTypes.object,
  tripPlanIsLoading: PropTypes.bool,
  showVinsTab: PropTypes.bool,
  // Map Coordinates
  addCoordinate: PropTypes.func.isRequired,
  clearCoordinatesByType: PropTypes.func.isRequired,
  selectedMapCoordinate: PropTypes.object,
};

export default ShipmentDetailTabs;
