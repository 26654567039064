/**
 * @description Used by NFilterButton in FilterButtons.js
 */
export enum RequirementStrategy {
  // As long as one of the values from the requirements is selected
  ANY,
  // Selected values has to match the requirements exactly
  MATCH,
}

export enum PaginationType {
  /**
   * Default pagination types adds `pageSize` and `pageNumber` in query parameters
   * PaginationType.DEFAULT - supports for fuzzy option
   */
  DEFAULT = "DEFAULT",
  /**
   * Adds `size` and `searchAfter` in query parameters
   */
  OPEN_SEARCH = "OPEN_SEARCH",
}

export enum SortType {
  DEFAULT = "DEFAULT",
  OPEN_SEARCH = "OPEN_SEARCH",
}
