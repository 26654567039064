/** @jsxImportSource @emotion/react */

import Colors from "styles/colors";

const baseTab = {
  border: 0,
  borderRadius: "4px 4px 0 0",
  fontSize: 20,
  marginRight: "5px",
  padding: "15px 20px",
  borderBottom: "4px",
  borderStyle: "solid",
  opacity: 1,
  cursor: "pointer",
};

export const selectedTab = {
  ...baseTab,
  color: Colors.inventoryView.BLUE,
  borderBlockColor: Colors.inventoryView.BLUE,
  backgroundColor: Colors.inventoryView.WHITE,
  cursor: "auto",
  ":disabled": {
    opacity: 1,
    color: Colors.inventoryView.BLUE,
    backgroundColor: Colors.inventoryView.WHITE,
    borderBlockColor: Colors.inventoryView.BLUE,
    cursor: "auto",
  },
};

export const defaultTab = {
  ...baseTab,
  color: Colors.inventoryView.DARK_GRAY,
  borderBlockColor: Colors.inventoryView.LIGHT_GRAY,
  opacity: 0.6,
  ":hover": {
    ...selectedTab,
    cursor: "pointer",
  },
};

export const tabsListStyle = {
  display: "flex",
  flexDirection: "row",
  marginBottom: "1em",
  borderBottom: "1px solid",
  borderColor: Colors.tabs.BACKGROUND,
  alignItems: "center",
  minHeight: "65px",
  listStyle: "none",
  paddingLeft: 0,
};
