/** @jsxImportSource @emotion/react */
import { connect } from "react-redux";
import CriticalPartsSearchBar from "./CriticalParts.SearchBar";
import CriticalPartsState from "pages/critical-parts/redux/CriticalParts.state";

const mapStateToProps = (state) => {
  return {
    searchText: CriticalPartsState.selectors.getSearchText(state),
    hasCriteriaChanged:
      CriticalPartsState.selectors.getHasCriteriaChanged(state),
  };
};
const { setHasCriteriaChanged, setSearchText } =
  CriticalPartsState.actionCreators;
const actionCreators = { setHasCriteriaChanged, setSearchText };

export default connect(mapStateToProps, actionCreators)(CriticalPartsSearchBar);
