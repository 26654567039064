/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

import { Search } from "components/templates/Search.template";
import SearchBarContainer from "pages/finishedvehicle/search/FinVehicleSearchBarContainer";
import FiltersContainer from "pages/finishedvehicle/search/FinVehicleSearchFiltersContainer";
import { columns } from "./FinishedVehicle.Search.columns";
import FinVehicleEditSavedSearchModalContainer from "pages/finishedvehicle/search/FinVehicleEditSavedSearchModalContainer";

import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";

const SearchResultsTotalCountLabel = () => {
  return (
    <Trans ns="fv-vin-search">
      <Search.TotalCount /> Total VINs
    </Trans>
  );
};

const FinVehicleSearch = (props) => {
  const {
    savedSearch,
    loadSavedSearch,
    solutionId,
    toggleShowFilters,
    pushVinDetailsView,
    vinSearchResults,
    isLoading,
    showFilters,
    sortColumn,
    reverseSort,
    page,
    pageSize,
    totalPages,
    setPagination,
    setSort,
    resetSearch,
    exportEntities,
    totalEntities,
    totalEntitiesIsLoading,
    isExporting,
    exportIdentifier,
    exportName,
    exportFailed,
    clearExportErrors,
    showItssData,
    showSpotBuyData,
    showScheduleWindow,
    resetExport,
  } = props;
  const { t } = useTranslation("fv-vin-search");

  useSetTitleOnMount(t("fv-vin-search:Search Results"));

  useTrackWithMixpanelOnce("Viewed Page: VIN / Search");

  const rowClickHandler = (row, cell) => {
    // Prevent navigation if clicking in "watch" checkbox cell.
    if (cell.column.id === "watch") {
      return;
    }

    // Navigate to VIN Details when clicking row
    pushVinDetailsView(row.original.id);
  };

  return (
    <Search
      isLoading={isLoading}
      searchResults={vinSearchResults}
      totalCount={totalEntities}
      totalCountIsLoading={totalEntitiesIsLoading}
      TotalCountLabel={SearchResultsTotalCountLabel}
      savedSearch={savedSearch}
      loadSavedSearch={loadSavedSearch}
      SavedSearchModalContainer={FinVehicleEditSavedSearchModalContainer}
      resetSearch={resetSearch}
      showFilters={showFilters}
      toggleShowFilters={toggleShowFilters}
      SearchBarContainer={SearchBarContainer}
      FiltersContainer={FiltersContainer}
      exportProps={{
        exportEntities: exportEntities,
        exportIdentifier: exportIdentifier,
        exportName: exportName,
        isExporting: isExporting,
        exportFailed: exportFailed,
        clearExportErrors: clearExportErrors,
        resetExport: resetExport,
      }}
      tableProps={{
        data: vinSearchResults,
        columns: columns(
          t,
          solutionId,
          showItssData,
          showSpotBuyData,
          showScheduleWindow,
        ),
        isLoading: isLoading,
        rowClickHandler: rowClickHandler,
        showPagination: true,
        fixPaginationToBottom: true,
        isManualPagination: true,
        isManualSorting: true,
        sortColumn: sortColumn,
        reverseSort: reverseSort,
        pageIndex: page,
        pageSize: pageSize,
        pageCount: totalPages,
        onPageChange: (newPage) => {
          setPagination(solutionId, newPage, pageSize);
        },
        onPageSizeChange: (newPageSize) => {
          setPagination(solutionId, 0, newPageSize);
        },
        onPageSort: (column, isReverse) => {
          setSort(solutionId, column, isReverse);
        },
      }}
    />
  );
};

FinVehicleSearch.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  loadSavedSearch: PropTypes.func.isRequired,
  sortColumn: PropTypes.string,
  reverseSort: PropTypes.bool,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  pushVinDetailsView: PropTypes.func.isRequired,
  savedSearch: PropTypes.object,
  setPagination: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  exportEntities: PropTypes.func.isRequired,
  showFilters: PropTypes.bool.isRequired,
  solutionId: PropTypes.string.isRequired,
  toggleShowFilters: PropTypes.func.isRequired,
  totalEntities: PropTypes.number.isRequired,
  totalEntitiesIsLoading: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
  vinSearchResults: PropTypes.array.isRequired,
  isExporting: PropTypes.bool.isRequired,
  exportIdentifier: PropTypes.string,
  exportName: PropTypes.string,
  exportFailed: PropTypes.bool,
  clearExportErrors: PropTypes.func,
  showItssData: PropTypes.bool.isRequired,
  showSpotBuyData: PropTypes.bool.isRequired,
  showScheduleWindow: PropTypes.bool.isRequired,
  resetExport: PropTypes.func,
};

export default FinVehicleSearch;
