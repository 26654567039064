/** @jsxImportSource @emotion/react */
import _ from "lodash";
import { useMemo } from "react";

import LadRowItem from "./LadRowItem";
import LadsListToggleButton from "./LadsListToggleButton";
import { SelectElem } from "vendor/signal-widgets/forms";
import { LobsType, useLadsTranslation } from "shared/utils/lads.utils";
import { FlexDiv, FlexColDiv } from "styles/container-elements";

/* Hardcoded since the client wanted a defined order */
const LOBS = [
  LobsType.ASSEMBLY,
  LobsType.FINISHED_PRODUCT,
  LobsType.AFTERMARKET_PARTS,
];

const LadsList = ({
  t,
  eventHandler,
  ladsGrouped,
  showLadsPanel,
  showLocationsPanel,
  selectedLad,
  selectedLob,
}) => {
  const { getTranslatedLobName } = useLadsTranslation();
  const lobNames = useMemo(
    () => LOBS.map((name) => getTranslatedLobName(name)),
    [getTranslatedLobName],
  );
  const lob = _.get(LOBS, selectedLob);

  let mapNavElem;
  if (!_.isEmpty(ladsGrouped) && lob) {
    mapNavElem = ladsGrouped[lob].map((lad, i) => (
      <LadRowItem
        key={i}
        lad={lad}
        ind={i}
        showLadsPanel={showLadsPanel}
        eventHandler={eventHandler}
        active={selectedLad === lad}
      />
    ));
  }

  let businessElem = showLadsPanel ? (
    <FlexColDiv
      css={{
        flex: 1,
        marginBottom: ".25em",
        paddingLeft: "1em",
        paddingRight: "1em",
      }}
    >
      <FlexDiv
        css={{
          paddingTop: "1em",
          marginBottom: ".5em",
          color: "white",
        }}
      >
        {t("end-to-end:Line of Business")}
      </FlexDiv>
      <SelectElem
        t={t}
        style={{ flex: "0 0 100%", marginBottom: ".5em" }}
        list={lobNames}
        lbl=""
        evt="SELECT_LOB"
        handler={eventHandler}
        val={getTranslatedLobName(selectedLob)}
      />
    </FlexColDiv>
  ) : null;

  return (
    <FlexColDiv>
      <FlexDiv
        css={{
          display: "inline-flex",
        }}
      >
        <FlexColDiv>
          <LadsListToggleButton
            onClick={() => {
              const isVisible = showLocationsPanel ? false : !showLadsPanel;
              eventHandler(isVisible, "TOGGLE_LADS");
            }}
            showLadsPanel={showLadsPanel}
            showLocationsPanel={showLocationsPanel}
            selectedLad={selectedLad}
          />
        </FlexColDiv>
        {businessElem}
      </FlexDiv>
      <FlexColDiv
        css={{
          flex: 1,
          overflowY: "auto",
        }}
      >
        {mapNavElem}
      </FlexColDiv>
    </FlexColDiv>
  );
};

export default LadsList;
