import { connect } from "react-redux";

import { VinViewDashboard } from "./VinView.Dashboard.page";
import VinViewEntitiesState from "../redux/VinViewEntitiesState";
import VinViewSearchBarState from "../redux/VinViewSearchBarState";
import VinViewDomainDataState from "modules/domain-data/VinViewDomainData";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import { OrganizationType } from "shared/constants/organization.const";

function mapStateToProps(state) {
  const auth = getAuthorization(state);

  return {
    exceptionTypes: VinViewDomainDataState.selectors.getExceptionTypes(state),
    entityCount: VinViewEntitiesState.selectors.getEntityCount(state),
    isEntityCountLoading:
      VinViewEntitiesState.selectors.getEntityCountLoading(state),
    newEntityCount: VinViewEntitiesState.selectors.getNewEntityCount(state),
    isNewEntityCountLoading:
      VinViewEntitiesState.selectors.getNewEntityCountLoading(state),
    entityDeliveredCount:
      VinViewEntitiesState.selectors.getEntityDeliveredCount(state),
    isEntityDeliveredCountLoading:
      VinViewEntitiesState.selectors.getEntityCountDeliveredLoading(state),
    entityCompleteCount:
      VinViewEntitiesState.selectors.getEntityCompleteCount(state),
    isEntityCompleteCountLoading:
      VinViewEntitiesState.selectors.getEntityCountCompleteLoading(state),
    watchedVins: VinViewEntitiesState.selectors.getWatchedVins(state),
    isWatchedVinsLoading:
      VinViewEntitiesState.selectors.getWatchedVinsLoading(state),
    watchedVinsPageIndex:
      VinViewEntitiesState.selectors.getWatchedVinsPageIndex(state),
    watchedVinsPageSize:
      VinViewEntitiesState.selectors.getWatchedVinsPageSize(state),
    watchedVinsPageCount:
      VinViewEntitiesState.selectors.getWatchedVinsPageCount(state),
    showFilters: VinViewSearchBarState.selectors.getShowAdvancedSearch(state),
    selectedDealerOrgId:
      VinViewSearchBarState.selectors.getSelectedDealerOrgId(state),
    isDealerOrg: auth.hasOrganizationTypes([OrganizationType.DEALER]),
    lifeCycleStateFilterOptions:
      VinViewDomainDataState.selectors.getLifeCycleStates(state),
  };
}

const {
  fetchEntityCount,
  fetchNewEntityCount,
  fetchEntityDeliveredCount,
  fetchEntityCompleteCount,
  fetchWatchedVins,
  fetchWatchedEntitiesTotalPages,
  setWatchedVinsPageIndex,
} = VinViewEntitiesState.actionCreators;
const {
  selectSavedSearch,
  resetSavedSearch,
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSearchBar,
  clearSearchFilters,
  toggleShowFilters,
} = VinViewSearchBarState.actionCreators;

const actionCreators = {
  fetchEntityCount,
  fetchNewEntityCount,
  fetchEntityDeliveredCount,
  fetchEntityCompleteCount,
  fetchWatchedVins,
  fetchWatchedEntitiesTotalPages,
  setWatchedVinsPageIndex,
  selectSavedSearch,
  resetSavedSearch,
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSearchBar,
  clearSearchFilters,
  toggleShowFilters,
};

export default connect(mapStateToProps, actionCreators)(VinViewDashboard);
