import { z } from "zod";

/**
 * Valid names for the available environments
 */
const EnvironmentNameSchema = z
  .enum([
    "dev",
    "dev2",
    "qa",
    "qa2",
    "test",
    "staging1",
    "proda",
    "prod",
    // TODO: Remove the following and the transform after Jenkins is updated.
    // The old value for prod was "production". For a smoother release, we will
    // accept these values and transform them to one of the values above.
    "local",
    "production",
  ])
  // Even though the enum schema originally has "local" and "production",
  // the inferred type omits these because of the transform!
  .transform((value) => {
    if (value === "production") {
      return "prod";
    }

    if (value === "local") {
      return "dev";
    }

    return value;
  });
export type EnvironmentName = z.infer<typeof EnvironmentNameSchema>;

/**
 * Allows using certain strings to represent a boolean value.
 * If the string is not an allowed value, it will be set to false.
 */
const BooleanEnvValueSchema = z
  .union([
    z.enum(["true", "yes", "on", "1"]).transform((_ignored) => true),
    z.enum(["false", "no", "off", "0"]).transform((_ignored) => false),
  ])
  .catch(({ error, input }) => {
    // Ignore empty string or undefined, e.g. REACT_APP_ENABLE_MOCK_API=
    if (input) {
      const issue = error.issues[0];
      console.warn(
        `Environment Variable ${issue.path}:`,
        `Unknown boolean value '${input}'.`,
        "Default value is false.",
        "Allowed values are: true|false, yes|no, on|off, 1|0",
      );
    }
    return false;
  });

const AppEnvSchema = z.object({
  // Configured by Create React App
  NODE_ENV: z.enum(["development", "test", "production"]),
  // Custom environment variables
  REACT_APP_ENVIRONMENT: EnvironmentNameSchema,
  REACT_APP_IS_PUBLIC: BooleanEnvValueSchema,
  REACT_APP_ENABLE_MOCK_API: BooleanEnvValueSchema,
  REACT_APP_ENABLE_REDUX_LOGGER: BooleanEnvValueSchema,
  REACT_APP_MIXPANEL_TOKEN: z.string().optional(),
});

/**
 * A typed version of process.env
 */
export const env = AppEnvSchema.parse({
  // `process.env` is not directly passed for it to work with our storybook config.
  // If you add `console.log(process.env)` above `parse`, it will show {}, but
  // `console.log(process.env.REACT_APP_ENVIRONMENT)` displays the value from .env
  NODE_ENV: process.env.NODE_ENV,
  REACT_APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
  REACT_APP_IS_PUBLIC: process.env.REACT_APP_IS_PUBLIC,
  REACT_APP_ENABLE_MOCK_API: process.env.REACT_APP_ENABLE_MOCK_API,
  REACT_APP_ENABLE_REDUX_LOGGER: process.env.REACT_APP_ENABLE_REDUX_LOGGER,
  REACT_APP_MIXPANEL_TOKEN: process.env.REACT_APP_MIXPANEL_TOKEN,
});
