/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";

import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { MediaQueries } from "components/responsive";
import { getIconData } from "../utils/exceptions.utils";
import { Dashboard } from "components/templates/Dashboard.template";
import SearchBarContainer from "../components/search/CarrierView.SearchBar.container";
import FiltersContainer from "../components/search/CarrierView.SearchFilters.container";
import { ExceptionsPanel } from "components/organisms/ExceptionsPanel.organism";
import WatchedVins from "./components/organisms/CarrierView.WatchedVins.organism";
import { CarrierViewSavedSearchesPanel } from "./components/organisms/CarrierView.SavedSearchesPanel.organism";

import {
  useSetDescriptionOnMount,
  useSetTitleOnMount,
} from "components/hooks/useSetTitle";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";

export const CarrierViewDashboard = ({
  entityCount,
  isEntityCountLoading,
  entityDeliveredCount,
  isEntityDeliveredCountLoading,
  watchedVins,
  isWatchedVinsLoading,
  watchedVinsPageIndex,
  watchedVinsPageSize,
  watchedVinsPageCount,
  setWatchedVinsPageIndex,
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  exceptionTypes,
  resetSearchBar,
  clearSearchFilters,
  fetchEntityCount,
  fetchEntityDeliveredCount,
  fetchWatchedVins,
  fetchWatchedEntitiesTotalPages,
  resetSavedSearch,
}) => {
  const { t } = useTranslation("carrierview-dashboard");

  useSetTitleOnMount(t("carrierview-dashboard:CarrierView"));
  useSetDescriptionOnMount(
    t(
      "carrierview-dashboard:All VINs for your organization and their related ETA",
    ),
  );

  useTrackWithMixpanelOnce("Viewed Page: CarrierView / Dashboard");

  // Show the filters by defualt.
  const [showFilters, setShowFilters] = useState(true);

  useEffect(
    () => {
      resetSearchBar();
      clearSearchFilters();
      resetSavedSearch();

      fetchEntityCount();
      fetchEntityDeliveredCount();
      fetchWatchedEntitiesTotalPages();
    },
    // We want this effect only to run once
    // eslint-disable-next-line
    [],
  );

  // Refetch watched vins when page index changes.
  useEffect(() => {
    fetchWatchedVins();
  }, [fetchWatchedVins, watchedVinsPageIndex]);

  const handleClickException = (e) => {
    let searchableValue = [];
    if (e && e.name === t("carrierview-dashboard:Delivered")) {
      clearSearchFilter();
      setSearchFilter("lifeCycleState", ["Delivered"]);
      searchEntities();
    } else {
      if (e && e.reason_code) {
        searchableValue.push(
          _.find(exceptionTypes, { reasonCode: e.reason_code }).id,
        );
      } else {
        exceptionTypes.forEach((exceptionType) => {
          searchableValue.push(exceptionType.id);
        });
      }

      clearSearchFilter();
      setSearchFilter("exception", searchableValue);
      setSearchFilter("lifeCycleState", ["Active"]);
      searchEntities();
    }
  };

  const handleActiveChartClick = () => {
    clearSearchFilter();
    setSearchFilter("lifeCycleState", ["Active"]);
    searchEntities();
  };

  const exceptionGroups = [
    {
      title: t("carrierview-dashboard:Delivered Last 30 Days"),
      includeInDonutChart: false,
      exceptions: [
        {
          name: t("carrierview-dashboard:Delivered"),
          count: entityDeliveredCount ?? 0,
          icon: {
            ...getIconData("Delivered"),
          },
        },
      ],
      isLoading: isEntityDeliveredCountLoading,
    },
  ];

  return (
    <Dashboard
      SearchBarContainer={SearchBarContainer}
      FiltersContainer={FiltersContainer}
      showFilters={showFilters}
      toggleShowFilters={(newShowFilters) => setShowFilters(newShowFilters)}
    >
      <div
        css={{
          [MediaQueries.smallAndUp]: {
            display: "flex",
            flexDirection: "column",
          },
          [MediaQueries.mediumAndUp]: {
            display: "grid",
            gridColumnGap: "20px",
            gridRowGap: "20px",
            justifyItems: "stretch",
            gridTemplateColumns: "repeat(12, 1fr)",
            gridTemplateRows: "1fr",
            gridAutoFlow: "row",
          },
        }}
      >
        <div
          css={{
            [MediaQueries.smallAndDown]: {
              marginBottom: "20px",
            },
            [MediaQueries.mediumAndUp]: {
              marginBottom: 0,
              gridColumn: "1 / span 5",
            },
          }}
        >
          <ExceptionsPanel
            title={t("carrierview-dashboard:Finished Vehicle VINs")}
            exceptionGroups={exceptionGroups}
            handleClickException={handleClickException}
            totalCountChartElement={
              <Fragment>
                <ExceptionsPanel.Chart
                  exceptionGroups={exceptionGroups}
                  count={entityCount}
                  countIsLoading={isEntityCountLoading}
                  countLabel={t("carrierview-dashboard:Active")}
                  onClick={handleActiveChartClick}
                  showNumberSeparator={true}
                />
              </Fragment>
            }
            showNumberSeparator={true}
          />
        </div>
        <div
          css={{
            marginBottom: "20px",
            [MediaQueries.mediumAndUp]: {
              marginBottom: 0,
              gridColumnStart: 6,
              gridColumnEnd: -1, // Span until the end
            },
          }}
        >
          <WatchedVins
            fetchWatchedVins={fetchWatchedVins}
            fetchWatchedEntitiesTotalPages={fetchWatchedEntitiesTotalPages}
            watchedVins={watchedVins}
            watchedVinsPageIndex={watchedVinsPageIndex}
            watchedVinsPageSize={watchedVinsPageSize}
            watchedVinsPageCount={watchedVinsPageCount}
            isWatchedVinsLoading={isWatchedVinsLoading}
            setWatchedVinsPageIndex={setWatchedVinsPageIndex}
          />
        </div>
        <div
          css={{
            [MediaQueries.smallAndDown]: {
              marginBottom: "20px",
            },
            [MediaQueries.mediumAndUp]: {
              gridColumnStart: 1,
              gridColumnEnd: -1,
            },
          }}
        >
          <CarrierViewSavedSearchesPanel />
        </div>
      </div>
    </Dashboard>
  );
};

CarrierViewDashboard.propTypes = {
  clearSearchFilter: PropTypes.func.isRequired,
  clearSearchFilters: PropTypes.func.isRequired,
  entityCount: PropTypes.number,
  isEntityCountLoading: PropTypes.bool,
  fetchWatchedVins: PropTypes.func.isRequired,
  fetchWatchedEntitiesTotalPages: PropTypes.func.isRequired,
  watchedVins: PropTypes.array,
  isWatchedVinsLoading: PropTypes.bool,
  watchedVinsPageIndex: PropTypes.number,
  watchedVinsPageSize: PropTypes.number,
  watchedVinsPageCount: PropTypes.number,
  setWatchedVinsPageIndex: PropTypes.func.isRequired,
  entityDeliveredCount: PropTypes.number,
  exceptionTypes: PropTypes.array,
  fetchEntityCount: PropTypes.func.isRequired,
  fetchEntityDeliveredCount: PropTypes.func.isRequired,
  isEntityDeliveredCountLoading: PropTypes.bool,
  holdTypes: PropTypes.any,
  resetSavedSearch: PropTypes.func.isRequired,
  resetSearchBar: PropTypes.func.isRequired,
  searchEntities: PropTypes.any,
  setSearchFilter: PropTypes.func.isRequired,
  solutionId: PropTypes.any,
};
