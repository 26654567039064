import axios from "axios";
import moment from "moment";
import _ from "lodash";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import apiUrl, { customerApiUrl } from "api-url";
import chainReducers from "vendor/signal-utils/chain-reducers";

import { getType } from "../geofence-edit/geofence-types";
import {
  fetchOrganizations,
  getOrganizationByDbId,
} from "modules/organizations/OrganizationsState";
import { ShipmentStatusCode } from "shared/constants/shipments.const";

// URLs

const SHIPMENT_EVENTS_URL = customerApiUrl("/ws/rest/v2/tl/shipment/event");
const SHIPMENT_URL = customerApiUrl("/ws/rest/v2/tl/shipment");
const STORE_MOUNT_POINT = "editShipment";
const STORE_DELAY_OPTIONS_URL = apiUrl("/shipping/events/reason/description");

// Helpers
const delayOptionsDuck = buildFetchDuck(STORE_MOUNT_POINT, "delayOptions");
const reportDelayDuck = buildFetchDuck(STORE_MOUNT_POINT, "reportDelayDuck");
const clearDelayDuck = buildFetchDuck(STORE_MOUNT_POINT, "clearDelay");

// Actions
//Report Arrival/Departure Actions
const REQUEST_SHIPMENT_EVENTS = `${STORE_MOUNT_POINT}/REQUEST_SHIPMENT_EVENTS`;
const RECEIVE_SHIPMENT_EVENTS = `${STORE_MOUNT_POINT}/RECEIVE_SHIPMENT_EVENTS`;
const REQUEST_ERROR_SHIPMENT_EVENTS = `${STORE_MOUNT_POINT}/REQUEST_ERROR_SHIPMENT_EVENTS`;
const CLEAR_SHIPMENT_EVENTS = `${STORE_MOUNT_POINT}/CLEAR_SHIPMENT_EVENTS`;

//BOL
const SET_EDIT_SHIPMENT_STATUS = `${STORE_MOUNT_POINT}/SET_EDIT_SHIPMENT_STATUS`;
const CLEAR_EDIT_SHIPMENT_STATUS = `${STORE_MOUNT_POINT}/CLEAR_EDIT_SHIPMENT_STATUS`;

const buildCarrierBlock = (carrier) => {
  return {
    fvCarrierId: carrier && carrier.fv_id ? String(carrier.fv_id) : "",
    scac: carrier && carrier.scac ? carrier.scac : "",
  };
};

const buildLocationBlock = (stop) => {
  let locDetails = {
    locationId: {
      qualifier: "",
      value: stop.location.code,
    },
    name: stop.location.name,
    address1: stop.location.address,
    address2: "",
    city: stop.location.city,
    state: stop.location.state,
    postalCode: stop.location.postal_code,
    country: stop.location.country ? stop.location.country : "",
  };

  // If there is a valid geofence for this location, add
  // the center coordinate into our object
  if (stop.location.geofence) {
    const fenceType = getType(stop.location.geofence);
    if (fenceType.isValid(stop.location.geofence)) {
      locDetails["latitude"] =
        stop.location.geofence.properties.center.latitude;
      locDetails["longitude"] =
        stop.location.geofence.properties.center.longitude;
    }
  }

  return locDetails;
};

const buildTimeString = (time, stop) => {
  if (stop && stop.timezone) {
    return moment(time).tz(stop.timezone).format();
  } else {
    return moment(time).format();
  }
};

const buildErrorMessage = (response) => {
  let errorMessageTypeOne = response?.data?.error?.message ?? "";
  let errorMessageTypeTwo = response?.data?.errors ?? "";
  if (errorMessageTypeOne) {
    return errorMessageTypeOne;
  } else if (errorMessageTypeTwo) {
    return errorMessageTypeTwo;
  } else {
    return "please try again later";
  }
};

export function buildFVShimentIdHeaders(shipment) {
  return async (dispatch, getState) => {
    let { organizations } = getOrganizationByDbId([shipment.created_by_org_id])(
      getState(),
    );

    if (_.isEmpty(organizations)) {
      await dispatch(fetchOrganizations({ ids: [shipment.created_by_org_id] }));
      organizations = getOrganizationByDbId([shipment.created_by_org_id])(
        getState(),
      ).organizations;
    }

    const creatorFvId = organizations?.[0]?.fv_id;

    return {
      "X-WSS-fvShipmentId": [
        String(shipment.carrier_fv_id),
        String(creatorFvId),
        String(shipment.creator_shipment_id),
      ].join("|"),
    };
  };
}

const getEventAndShipmentId = (shipment_id) => {
  let shipmentIds;

  if (Array.isArray(shipment_id)) {
    shipmentIds = shipment_id.toString();
  } else {
    shipmentIds = shipment_id;
  }

  if (shipmentIds === "") {
    // If shipment_id is an empty string, explicitly pass that to shipmentIDs
    return {
      event: "manual_shipper_delay",
      shipmentIDs: "",
    };
  } else if (shipmentIds) {
    return {
      event: "manual_shipper_delay",
      shipmentIDs: `${shipmentIds},`,
    };
  } else {
    // Otherwise, don't pass shipmentIDs at all (eg: in case of a null/undefined value for shipment_id)
    return {
      event: "manual_carrier_delay",
    };
  }
};

const getCurrentExceptionEvent = (shipment) => {
  if (shipment.current_exception === "manual_carrier_delay") {
    return {
      event: "clear_manual_carrier_delay",
    };
  } else if (shipment.current_exception === "manual_shipper_delay") {
    return {
      event: "clear_manual_shipper_delay",
      shipmentIDs: "",
    };
  }
};

const getCarrierIdAndScac = (shipment, orgType) => {
  if (orgType.org_type === "Shipper") {
    return {
      fvCarrierId: shipment && shipment.carrier_fv_id,
      scac: shipment && shipment.carrier_scac,
    };
  } else if (orgType.org_type === "Carrier") {
    return {
      fvCarrierId: orgType && String(orgType.fv_id),
      scac: orgType && orgType.scac,
    };
  }
};

// Action Creators

const createShipmentEvents = (
  shipment,
  organization,
  eventData,
  notes,
  onSuccess = _.noop,
) => {
  const mode = shipment?.mode_name?.toUpperCase();

  let statusMap;

  if (mode === "RAIL") {
    statusMap = {
      origin: {
        arrivedAt: ShipmentStatusCode.RAIL_ARRIVED,
        unloadedAt: ShipmentStatusCode.RAIL_DEPARTED_DROP_OFF,
        loadedAt: ShipmentStatusCode.RAIL_PICKED_UP,
        departedAt: ShipmentStatusCode.RAIL_DEPARTED_PICK_UP,
      },
      middle: {
        arrivedAt: ShipmentStatusCode.RAIL_ARRIVED,
        unloadedAt: ShipmentStatusCode.RAIL_DEPARTED_DROP_OFF,
        loadedAt: ShipmentStatusCode.RAIL_PICKED_UP,
        departedAt: ShipmentStatusCode.RAIL_DEPARTED_PICK_UP,
      },
      destination: {
        arrivedAt: ShipmentStatusCode.RAIL_ARRIVED_AT_DROP_OFF,
        unloadedAt: ShipmentStatusCode.RAIL_DEPARTED_DROP_OFF,
        loadedAt: ShipmentStatusCode.RAIL_PICKED_UP,
        departedAt: ShipmentStatusCode.RAIL_DEPARTED_DROP_OFF,
      },
    };
  } else {
    statusMap = {
      origin: {
        arrivedAt: ShipmentStatusCode.ARRIVED,
        unloadedAt: ShipmentStatusCode.DELIVERED,
        loadedAt: ShipmentStatusCode.PICKED_UP,
        departedAt: ShipmentStatusCode.DEPARTED_PICK_UP,
      },
      middle: {
        arrivedAt: ShipmentStatusCode.ARRIVED,
        unloadedAt: ShipmentStatusCode.DELIVERED,
        loadedAt: ShipmentStatusCode.PICKED_UP,
        departedAt: ShipmentStatusCode.DEPARTED_PICK_UP,
      },
      destination: {
        arrivedAt: ShipmentStatusCode.ARRIVED_AT_DROP_OFF,
        unloadedAt: ShipmentStatusCode.DELIVERED,
        loadedAt: ShipmentStatusCode.PICKED_UP,
        departedAt: ShipmentStatusCode.DEPARTED_DROP_OFF,
      },
    };
  }

  const stopsSortedByStopSequence = _.sortBy(
    shipment.shipment_stops,
    "stop_sequence",
  );

  let successStatus;
  let errorStatuses = [];

  return async (dispatch) => {
    dispatch({
      type: REQUEST_SHIPMENT_EVENTS,
    });

    const headers = await dispatch(buildFVShimentIdHeaders(shipment));

    for (
      let stopIndex = 0;
      stopIndex < stopsSortedByStopSequence.length;
      stopIndex++
    ) {
      // For each stop, go over each field and submit an event to update.
      let stopType = null;
      if (stopIndex === 0) {
        stopType = "origin";
      } else if (stopIndex === stopsSortedByStopSequence.length - 1) {
        stopType = "destination";
      } else {
        stopType = "middle";
      }

      const statusCodesForStop = statusMap[stopType];

      // Iterate through each field to make an event
      // one of: arrivedAt, unloadedAt, loadedAt, departedAt
      for (let key in statusCodesForStop) {
        const stop = stopsSortedByStopSequence[stopIndex];
        const eventCode = statusCodesForStop[key];
        const eventTime = eventData[stopIndex][key];

        // If null, go to next field to process.
        if (_.isNil(eventTime)) {
          continue;
        }

        const payload = {
          carrier: buildCarrierBlock(organization),
          stopSequence: stop.stop_sequence,
          location: buildLocationBlock(stop),
          eventDetail: {
            parentShipmentDbId: shipment.parent_shipment_id,
            eventDate: buildTimeString(eventTime, stop),
            event: eventCode,
            reason: "NS", // placeholder for manual
            comments: notes,
          },
        };

        try {
          let res = await axios.post(SHIPMENT_EVENTS_URL, payload, {
            headers,
          });
          successStatus = res.status;
        } catch (e) {
          errorStatuses.push({
            field: key,
            error: buildErrorMessage(e.response),
          });
        }
      }
    }

    if (errorStatuses.length > 0) {
      dispatch({
        type: REQUEST_ERROR_SHIPMENT_EVENTS,
        errors: errorStatuses,
        statusArray: [successStatus, ...errorStatuses],
      });
    } else {
      //Only run when all requests were successful.
      onSuccess();
      dispatch({
        type: RECEIVE_SHIPMENT_EVENTS,
        statusArray: [successStatus, ...errorStatuses],
      });
    }
  };
};

const assignAsset = (
  shipment,
  organization,
  assetID,
  assignDate,
  notes,
  fetchShipmentDetails,
) => {
  // If no asset ID is specified, keep the existing asset ID
  const asset = assetID && assetID.length > 0 ? assetID : shipment.obc_asset_id;

  const dateValue = assignDate ? assignDate : Date();

  const payload = {
    carrier: buildCarrierBlock(organization),
    telematics: {
      trackingAssetId: asset,
    },
    eventDetail: {
      eventDate: buildTimeString(dateValue, null),
      event: "XB",
      reason: "NS",
      comments: notes,
    },
  };

  return async (dispatch) => {
    const headers = await dispatch(buildFVShimentIdHeaders(shipment));

    const allPromise = Promise.all([
      axios.post(`${SHIPMENT_EVENTS_URL}`, payload, { headers: headers }),
    ]);

    allPromise
      .then(() => {
        dispatch(fetchShipmentDetails(shipment.id));
      })
      .catch((error) => {
        throw new Error(error);
      });
  };
};

const assignTrailer = (shipment, trailerNumber, fetchShipmentDetails) => {
  const payload = {
    shipmentDetails: {
      equipmentDetail: {
        trailerNumber: trailerNumber,
      },
    },
  };

  return async (dispatch) => {
    const headers = await dispatch(buildFVShimentIdHeaders(shipment));

    const allPromise = Promise.all([
      axios.patch(`${SHIPMENT_URL}`, payload, { headers: headers }),
    ]);

    allPromise
      .then(() => {
        dispatch(fetchShipmentDetails(shipment.id));
      })
      .catch((error) => {
        throw new Error(error);
      });
  };
};

const cancelShipment = (shipment, notes, fetchShipmentDetails) => {
  const payload = { notes: notes };

  // For the delete to work properly, need to pass that payload
  // explicity as the data object.  Reference:
  // https://github.com/axios/axios/issues/736
  // This is really sort of a workaround as there is debate
  // whether this is good form, passing data to the DELETE
  return async (dispatch) => {
    const headers = await dispatch(buildFVShimentIdHeaders(shipment));

    const allPromise = Promise.all([
      axios.delete(`${SHIPMENT_URL}`, { headers: headers, data: payload }),
    ]);

    allPromise
      .then(() => {
        dispatch(fetchShipmentDetails(shipment.id));
      })
      .catch((error) => {
        throw new Error(error);
      });
  };
};

const startMobileTracking = (
  shipment,
  organization,
  phoneNumber,
  notes,
  fetchShipmentDetails,
) => {
  const payload = {
    carrier: buildCarrierBlock(organization),
    telematics: {
      trackingAssetId: "FVMB-" + phoneNumber,
    },
    eventDetail: {
      eventDate: buildTimeString(Date(), null),
      event: "XB",
      reason: "NS",
      comments: notes,
    },
  };

  return async (dispatch) => {
    const headers = shipment.parent_creator_shipment_id
      ? {
          "X-WSS-fvShipmentId": `${shipment.carrier_fv_id}|${shipment.shipper_fv_id}|${shipment.parent_creator_shipment_id}`,
        }
      : await dispatch(buildFVShimentIdHeaders(shipment));

    const allPromise = Promise.all([
      axios.post(`${SHIPMENT_EVENTS_URL}`, payload, { headers: headers }),
    ]);

    allPromise
      .then(() => {
        dispatch(fetchShipmentDetails(shipment.id));
      })
      .catch((error) => {
        throw new Error(error);
      });
  };
};

const stopMobileTracking = (shipment, organization, fetchShipmentDetails) => {
  const payload = {
    carrier: buildCarrierBlock(organization),
    eventDetail: {
      eventDate: buildTimeString(Date(), null),
      event: "XX",
    },
  };

  return async (dispatch) => {
    const headers = shipment.parent_creator_shipment_id
      ? {
          "X-WSS-fvShipmentId": `${shipment.carrier_fv_id}|${shipment.shipper_fv_id}|${shipment.parent_creator_shipment_id}`,
        }
      : await dispatch(buildFVShimentIdHeaders(shipment));

    const allPromise = Promise.all([
      axios.post(`${SHIPMENT_EVENTS_URL}`, payload, { headers: headers }),
    ]);

    allPromise
      .then(() => {
        dispatch(fetchShipmentDetails(shipment.id));
      })
      .catch((error) => {
        throw new Error(error);
      });
  };
};

const unassignAsset = (
  shipment,
  organization,
  assetID,
  fetchShipmentDetails,
) => {
  const payload = {
    carrier: buildCarrierBlock(organization),
    telematics: {
      trackingAssetId: assetID,
    },
    eventDetail: {
      eventDate: buildTimeString(Date(), null),
      event: "XX",
      reason: "NS",
      comments: "",
    },
  };

  return async (dispatch) => {
    const headers = await dispatch(buildFVShimentIdHeaders(shipment));

    const allPromise = Promise.all([
      axios.post(`${SHIPMENT_EVENTS_URL}`, payload, { headers: headers }),
    ]);

    allPromise
      .then(() => {
        dispatch(fetchShipmentDetails(shipment.id));
      })
      .catch((error) => {
        throw new Error(error);
      });
  };
};

const setWatchShipment = (shipment, watch, onRequestFinished) => {
  return async (dispatch) => {
    const headers = await dispatch(buildFVShimentIdHeaders(shipment));

    const url = apiUrl(
      `/preferences-ng/shipments/${shipment.creator_shipment_id}/watch`,
    );

    const data = { watch };

    const allPromise = Promise.all([
      axios.patch(url, data, {
        headers: headers,
      }),
    ]);

    allPromise
      .then(() => {
        if (onRequestFinished) {
          onRequestFinished();
        }
      })
      .catch(() => {
        console.error("Failed to update watch on shipment.");
      });
  };
};

const fetchDelayOptions = () => {
  return (dispatch) => {
    dispatch(delayOptionsDuck.fetch(STORE_DELAY_OPTIONS_URL));
  };
};

const reportDelay = (
  shipment,
  organization,
  reasonCode,
  frozenEta,
  comments,
  importShipmentIDs = null,
  onSuccess = _.noop,
  fetchShipmentDetails,
) => {
  const eventAndShipmentIds = getEventAndShipmentId(importShipmentIDs);

  const payload = {
    carrier: getCarrierIdAndScac(shipment, organization),
    eventDetail: {
      // H1-4569: The eventDate is where the API gets the ETA from.
      // This is the same as the AG event when carriers submit a delay.
      eventDate: buildTimeString(frozenEta, null),
      event: eventAndShipmentIds.event,
      reason: reasonCode,
      comments: comments,
      shipmentIDs: eventAndShipmentIds.shipmentIDs,
    },
  };

  return async (dispatch) => {
    const headers = await dispatch(buildFVShimentIdHeaders(shipment));

    dispatch(
      reportDelayDuck.fetch(
        SHIPMENT_EVENTS_URL,
        {
          method: "POST",
          headers: headers,
          data: payload,
        },
        _.noop,
        () => {
          onSuccess();
          if (fetchShipmentDetails) {
            dispatch(fetchShipmentDetails(shipment.id));
          }
        },
      ),
    );
  };
};

const clearReportDelayInState = () => {
  return (dispatch) => {
    dispatch(reportDelayDuck.clear());
  };
};

const clearDelay = (
  shipment,
  organization,
  onSuccess = _.noop,
  fetchShipmentDetails,
) => {
  const currentExceptionEvent = getCurrentExceptionEvent(shipment);

  const payload = {
    carrier: getCarrierIdAndScac(shipment, organization),
    eventDetail: {
      eventDate: buildTimeString(Date(), null),
      event: currentExceptionEvent.event,
      shipmentIDs: currentExceptionEvent.shipmentIDs,
    },
  };

  return async (dispatch) => {
    const headers = await dispatch(buildFVShimentIdHeaders(shipment));

    dispatch(
      clearDelayDuck.fetch(
        SHIPMENT_EVENTS_URL,
        {
          method: "POST",
          headers: headers,
          data: payload,
        },
        _.noop,
        () => {
          onSuccess();
          dispatch(fetchShipmentDetails(shipment.id));
        },
      ),
    );
  };
};

const clearEditShipmentStatus = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_EDIT_SHIPMENT_STATUS });
  };
};

const clearReportShipmentEvents = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_SHIPMENT_EVENTS });
  };
};

const updateShipment = (shipment, payload, fetchShipmentDetails) => {
  return async (dispatch) => {
    dispatch({
      type: SET_EDIT_SHIPMENT_STATUS,
      isLoading: true,
    });

    const headers = await dispatch(buildFVShimentIdHeaders(shipment));

    try {
      await Promise.all([
        axios.patch(`${SHIPMENT_URL}`, payload, { headers: headers }),
      ]);
      dispatch({
        type: SET_EDIT_SHIPMENT_STATUS,
        status: "success",
      });
      dispatch(fetchShipmentDetails(shipment.id));
    } catch (error) {
      if (error.response && error?.response?.data?.code !== "504") {
        dispatch({
          type: SET_EDIT_SHIPMENT_STATUS,
          error: error?.response?.data?.errors,
          status: "error",
        });
      }
    }
  };
};

// Selectors
const getData = (state) => {
  return state[STORE_MOUNT_POINT];
};

// Reducer
const initialStateForReportShipmentEvents = {
  url: "",
  isLoading: false,
  isLoadingError: false,
  loadingError: null,
  status: null,
  isEditShipmentLoading: false,
  isEditShipmentLoadingError: false,
  editShipmentLoadingError: null,
  editShipmentStatus: null,
};

const shipmentEventModalReducer = (
  state = initialStateForReportShipmentEvents,
  action = {},
) => {
  switch (action.type) {
    case REQUEST_SHIPMENT_EVENTS:
      return {
        ...state,
        status: null,
        isLoading: true,
        url: SHIPMENT_EVENTS_URL,
      };
    case RECEIVE_SHIPMENT_EVENTS:
      return {
        ...state,
        isLoading: false,
        isLoadingError: false,
        loadingError: null,
        status: 201,
        statusArray: action.statusArray,
      };
    case REQUEST_ERROR_SHIPMENT_EVENTS:
      return {
        ...state,
        isLoading: false,
        isLoadingError: true,
        loadingError: action.errors,
        statusArray: action.statusArray,
      };
    case CLEAR_SHIPMENT_EVENTS:
      return { ...state, ...initialStateForReportShipmentEvents };
    case SET_EDIT_SHIPMENT_STATUS:
      return {
        ...state,
        isEditShipmentLoading: action?.isLoading,
        isEditShipmentLoadingError: action?.isLoadingError,
        editShipmentLoadingError: action?.error,
        editShipmentStatus: action?.status,
      };
    case CLEAR_EDIT_SHIPMENT_STATUS:
      return {
        ...state,
        isEditShipmentLoading: null,
        isEditShipmentLoadingError: null,
        editShipmentLoadingError: null,
        editShipmentStatus: null,
      };
    default:
      return state;
  }
};

const EditShipmentState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    assignAsset,
    assignTrailer,
    createShipmentEvents,
    cancelShipment,
    startMobileTracking,
    stopMobileTracking,
    unassignAsset,
    setWatchShipment,
    reportDelay,
    clearDelay,
    fetchDelayOptions,
    clearReportDelayInState,
    clearEditShipmentStatus,
    clearReportShipmentEvents,
    updateShipment,
  },
  selectors: {
    getEditShipmentData: getData,
    getDelayOptionsData: delayOptionsDuck.selectors.getData,
    getReportDelayData: reportDelayDuck.selectors.getData,
    getClearDelayData: clearDelayDuck.selectors.getData,
  },
  reducer: chainReducers([
    delayOptionsDuck.reducer,
    reportDelayDuck.reducer,
    clearDelayDuck.reducer,
    shipmentEventModalReducer,
  ]),
};

export default EditShipmentState;
