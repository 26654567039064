import axios from "axios";
import apiUrl from "api-url";
import { Features } from "modules/auth/Authorization";

const STORE_MOUNT_POINT = "surgicalToteTrackingCountsWidget";

//URLs
const BASE_URL = apiUrl("/containertracking/api/reuse-trip-container-totals");
const BASE_EXCEPTION_TYPES_URL = apiUrl(
  "/containertracking/api/reuse-trip-container/exception-types",
);

//Action Types
const REQUEST_TOTE_TOTALS = "REQUEST_TOTE_TOTALS";
const RECEIVE_TOTE_TOTALS = "RECEIVE_TOTE_TOTALS";
const REQUEST_EXCEPTION_TYPES = "REQUEST_EXCEPTION_TYPES";
const RECEIVE_EXCEPTION_TYPES = "RECEIVE_EXCEPTION_TYPES";

const axiosConfig = () => {
  return {
    headers: {
      "x-target-feature": Features.SURGICAL_TOTE_TRACKING,
    },
  };
};

//Action Creators
const fetchTotesTotals = () => {
  const url = BASE_URL;
  return async (dispatch) => {
    dispatch({ type: REQUEST_TOTE_TOTALS });
    try {
      const config = axiosConfig();
      const response = await axios.get(url, config);
      dispatch({
        type: RECEIVE_TOTE_TOTALS,
        payload: response.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
};

const fetchExceptionTypes = () => {
  const url = BASE_EXCEPTION_TYPES_URL;
  return async (dispatch) => {
    dispatch({ type: REQUEST_EXCEPTION_TYPES });
    try {
      const config = axiosConfig();
      const response = await axios.get(url, config);
      dispatch({ type: RECEIVE_EXCEPTION_TYPES, payload: response.data });
    } catch (err) {
      console.log(err);
    }
  };
};

//Selectors
const getTotesTotals = (state) => state[STORE_MOUNT_POINT].toteTotals;
const getTotesTotalsLoadingFlag = (state) =>
  state[STORE_MOUNT_POINT].isTotesTotalsLoading;
const getExceptionTypes = (state) => state[STORE_MOUNT_POINT].exceptionTypes;
const getExceptionTypesIsLoadingFlag = (state) =>
  state[STORE_MOUNT_POINT].isExceptionTypesIsLoading;

//Intial State
const initialState = {
  toteTotals: {},
  isTotesTotalsLoading: false,
};

//Reducer
const SurgicalToteTrackingCountsWidgetReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case REQUEST_TOTE_TOTALS:
      return {
        ...state,
        isTotesTotalsLoading: true,
      };

    case RECEIVE_TOTE_TOTALS:
      return {
        ...state,
        toteTotals: action.payload,
        isTotesTotalsLoading: false,
      };
    case REQUEST_EXCEPTION_TYPES:
      return {
        ...state,
        isExceptionTypesIsLoading: true,
      };
    case RECEIVE_EXCEPTION_TYPES:
      return {
        ...state,
        exceptionTypes: action.payload,
        isExceptionTypesIsLoading: false,
      };
    default:
      return state;
  }
};

//interface
export const SurgicalToteTrackingCountsWidgetState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchTotesTotals,
    fetchExceptionTypes,
  },
  selectors: {
    getTotesTotals,
    getTotesTotalsLoadingFlag,
    getExceptionTypes,
    getExceptionTypesIsLoadingFlag,
  },
  reducer: SurgicalToteTrackingCountsWidgetReducer,
};
