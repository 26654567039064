import { connect } from "react-redux";

import CriticalPartsSearch from "./CriticalParts.Search.page";

import { ProfileState } from "pages/profile/redux/ProfileState";
import {
  setSortColumn,
  setSortReverse,
  getSortColumn,
  getSearchText,
  getSortReverse,
  setSelectedLocationCode,
  getFiltersValue,
  setResetFilters,
} from "pages/critical-parts/redux/CriticalParts.state";

function mapStateToProps(state) {
  return {
    userPreferencesIsLoading:
      ProfileState.selectors.getUserPreferencesIsLoading(state),
    userPreferencesIsLoadingError:
      ProfileState.selectors.getUserPreferencesIsLoadingError(state),
    userPreferences: ProfileState.selectors.getUserPreferences(state),
    searchText: getSearchText(state),
    sortColumn: getSortColumn(state),
    sortReverse: getSortReverse(state),
    filterParams: getFiltersValue(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUserPreferences: () =>
      dispatch(ProfileState.actionCreators.getUserPreferences()),
    setPreferences: (payload) =>
      dispatch(ProfileState.actionCreators.setPreferences(payload)),
    setSortColumn: (sortColumn) => dispatch(setSortColumn(sortColumn)),
    setSortReverse: (sortReverse) => dispatch(setSortReverse(sortReverse)),
    setSelectedPlantLocationCode: (code) =>
      dispatch(setSelectedLocationCode(code)),
    setResetFilters: (reset) => dispatch(setResetFilters(reset)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CriticalPartsSearch);
