import {
  getBasicQueryString,
  getEverythingQueryString,
} from "components/search-bar/search-filter-query-strings";
import { Features } from "modules/auth/Authorization";

export const SEARCH_CATEGORIES = [
  {
    queryKey: "entityId",
    label: (t) => t("fv-vin-search:VIN"),
    placeholder: (t) => t("fv-vin-search:Search VIN"),
    queryBuilder: getBasicQueryString,
    property: "vins",
  },
  {
    queryKey: "everything",
    label: (t) => t("fv-vin-search:Search Everything"),
    placeholder: (t, auth) => {
      if (auth.hasFeatures([Features.ITSS])) {
        return t(
          "fv-vin-search:Search VIN, Shipment ID, Order #, Product Type, Location, Last Milestone, ITSS ID, or Route ID",
        );
      }
      return t(
        "fv-vin-search:Search VIN, Shipment ID, Order #, Product Type, Location, Last Milestone, or Route ID",
      );
    },
    queryBuilder: getEverythingQueryString,
    property: null,
  },
  {
    queryKey: "shipmentId",
    label: (t) => t("fv-vin-search:Shipment ID"),
    placeholder: (t) => t("fv-vin-search:Search Shipment ID"),
    queryBuilder: getBasicQueryString,
    property: "shipments",
  },
  {
    queryKey: "ref:OrderNumber:1",
    label: (t) => t("fv-vin-search:Order #"),
    placeholder: (t) => t("fv-vin-search:Search Order #"),
    queryBuilder: getBasicQueryString,
    property: "orderNumbers",
  },
  {
    // H2-305 productType is now descriptionSearch for the search bar
    queryKey: "descriptionSearch",
    label: (t) => t("fv-vin-search:Product Type"),
    placeholder: (t) => t("fv-vin-search:Search Product Type"),
    queryBuilder: getBasicQueryString,
    property: "productTypes",
  },
  {
    queryKey: "location",
    label: (t) => t("fv-vin-search:Location"),
    placeholder: (t) => t("fv-vin-search:Search Location"),
    queryBuilder: getBasicQueryString,
    property: "locations",
  },
  {
    queryKey: "lastMilestone:contains",
    label: (t) => t("fv-vin-search:Last Milestone"),
    placeholder: (t) => t("fv-vin-search:Search Last Milestone"),
    queryBuilder: getBasicQueryString,
    property: null,
  },
  {
    queryKey: "categorySearch",
    label: (t) => t("fv-vin-search:ITSS ID"),
    placeholder: (t) => t("fv-vin-search:ITSS ID"),
    // The `categorySearch` param needs the `categorySearchGroupType` to know what group type to search on.
    // This filter is for ITSS only, so we set the second param to `ITSS`
    queryBuilder: (queryParameter, filterValue) => {
      let queryString = getBasicQueryString(queryParameter, filterValue);

      if (queryString?.length > 0) {
        queryString += "&categorySearchGroupType=ITSS";
      }

      return queryString;
    },
    property: null,
    requiredFeatures: [Features.ITSS],
  },
  {
    queryKey: "routeNumber:1",
    label: (t) => t("fv-vin-search:Route ID"),
    placeholder: (t) => t("fv-vin-search:Search Route ID"),
    queryBuilder: getBasicQueryString,
    property: "routeIds",
  },
];
