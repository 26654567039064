import axios from "axios";
import { createSelector } from "reselect";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import apiUrl from "api-url";
import { CarrierAndDealerTripSummaryEventCodes } from "api/consts";

// URLS
const STORE_MOUNT_POINT = "vinViewEntityDetails";

// Actions
const RECEIVE_ENTITY_DETAILS = `${STORE_MOUNT_POINT}/RECEIVE_ENTITY_DETAILS`;
const RECEIVE_ENTITY_DETAILS_ERROR = `${STORE_MOUNT_POINT}/RECEIVE_ENTITY_DETAILS_ERROR`;
const RECEIVE_ENTITY_EXCEPTIONS = `${STORE_MOUNT_POINT}/RECEIVE_ENTITY_EXCEPTIONS`;
const RECEIVE_ENTITY_HOLDS = `${STORE_MOUNT_POINT}/RECEIVE_ENTITY_HOLDS`;
const RECEIVE_ENTITY_POSITION_UPDATES = `${STORE_MOUNT_POINT}/RECEIVE_ENTITY_POSITION_UPDATES`;
const RECEIVE_ENTITY_CONNECTED_CAR_POSITION_UPDATES = `${STORE_MOUNT_POINT}/RECEIVE_ENTITY_CONNECTED_CAR_POSITION_UPDATES`;
const CLEAR_MEDIA = `${STORE_MOUNT_POINT}/CLEAR_MEDIA`;
const RECEIVE_ENTITY_MEDIA = `${STORE_MOUNT_POINT}/RECEIVE_ENTITY_MEDIA`;
const RECEIVE_ENTITY_EVENTS = `${STORE_MOUNT_POINT}/RECEIVE_ENTITY_EVENTS`;
const ENTITY_UPDATE_SUCCESS = `${STORE_MOUNT_POINT}/ENTITY_UPDATE_SUCCESS`;
const FETCH_COMMENTS = `${STORE_MOUNT_POINT}/FETCH_COMMENTS`;
const FETCH_COMMENTS_FAILED = `${STORE_MOUNT_POINT}/FETCH_COMMENTS_FAILED`;
const RECEIVE_COMMENTS = `${STORE_MOUNT_POINT}/RECEIVE_COMMENTS`;
const SUBMIT_NEW_COMMENT = `${STORE_MOUNT_POINT}/SUBMIT_NEW_COMMENT`;
const RECEIVE_NEW_COMMENT = `${STORE_MOUNT_POINT}/RECEIVE_NEW_COMMENT`;
const SUBMIT_NEW_COMMENT_FAILED = `${STORE_MOUNT_POINT}/SUBMIT_NEW_COMMENT_FAILED`;
const CANCEL_NEW_COMMENT = "shipment/CANCEL_NEW_COMMENT";
const SET_IS_UPDATING_COMMENT = `${STORE_MOUNT_POINT}/SET_IS_UPDATING_COMMENT`;
const SET_IS_UPDATING_COMMENT_FAILED = `${STORE_MOUNT_POINT}/SET_IS_UPDATING_COMMENT_FAILED`;
const CANCEL_UPDATE_COMMENT = `${STORE_MOUNT_POINT}/CANCEL_UPDATE_COMMENT`;
const SUBMIT_BATCH_COMMENTS = `${STORE_MOUNT_POINT}/SUBMIT_BATCH_COMMENTS`;
const SUBMIT_BATCH_COMMENTS_SUCCESS = `${STORE_MOUNT_POINT}/SUBMIT_BATCH_COMMENTS_SUCCESS`;
const SUBMIT_BATCH_COMMENTS_FAILED = `${STORE_MOUNT_POINT}/SUBMIT_BATCH_COMMENTS_FAILED`;
const SUBMIT_BATCH_COMMENTS_RESET = `${STORE_MOUNT_POINT}/SUBMIT_BATCH_COMMENTS_RESET`;
const SHARE_VIN_CLEAR = `${STORE_MOUNT_POINT}/SHARE_VIN_CLEAR`;
const SHARE_VIN_BEGIN = `${STORE_MOUNT_POINT}/SHARE_VIN_BEGIN`;
const SHARE_VIN_SUCCESS = `${STORE_MOUNT_POINT}/SHARE_VIN_SUCCESS`;
const SHARE_VIN_FAILED = `${STORE_MOUNT_POINT}/SHARE_VIN_FAILED`;
const FETCH_SHIPPERS = `${STORE_MOUNT_POINT}/FETCH_SHIPPERS`;
const FETCH_SHIPPERS_SUCCESS = `${STORE_MOUNT_POINT}/FETCH_SHIPPERS_SUCCESS`;
const FETCH_SHIPPERS_FAILED = `${STORE_MOUNT_POINT}/FETCH_SHIPPERS_FAILED`;
const RECEIVE_ENTITY_STATE_HISTORY = `${STORE_MOUNT_POINT}/RECEIVE_ENTITY_STATE_HISTORY`;
const RECEIVE_ENTITY_STATE_HISTORY_ERROR = `${STORE_MOUNT_POINT}/RECEIVE_ENTITY_STATE_HISTORY_ERROR`;

const entityUrl = (entityId) => apiUrl(`/entity/internal/${entityId}`);

// Action creators
function fetchEntityDetails(solutionId, entityId, dealerOrgId = null) {
  const url = entityUrl(entityId);
  const params = { includeConfigurations: true };

  if (dealerOrgId) {
    params.dealerOrgId = dealerOrgId;
  }

  return (dispatch) => {
    return axios
      .get(url, { params })
      .then((response) => {
        dispatch({
          type: RECEIVE_ENTITY_DETAILS,
          payload: { entityDetails: response.data },
        });
      })
      .catch((error) => {
        dispatch({ type: RECEIVE_ENTITY_DETAILS_ERROR, payload: { error } });
        console.log("ERROR retreiving entity details", error);
      });
  };
}

const fetchEntityStateHistory = (entityId, stateQuery = null) => {
  return (dispatch) => {
    let url = apiUrl(
      `/entity-workflow/internal/entity/${entityId}/state-history`,
    );

    if (stateQuery) {
      url += `?state=${stateQuery}`;
    }

    return axios
      .get(url)
      .then((response) => {
        dispatch({
          type: RECEIVE_ENTITY_STATE_HISTORY,
          payload: {
            entityStateHistory: response.data,
          },
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: RECEIVE_ENTITY_STATE_HISTORY_ERROR,
          payload: {},
        });
      });
  };
};

function fetchEntityPositionUpdates(
  solutionId,
  entityId,
  dealerOrgId = null,
  type,
  maxResults = null,
) {
  const url = entityUrl(entityId) + "/position-update";
  const params = {};

  if (!_.isNil(type)) {
    params.type = type;
  }

  // FIN-5442: Paginate the response to one page with a max size of maxResults.
  if (!_.isNil(maxResults)) {
    params.paginate = true;
    params.pageNumber = 0;
    params.pageSize = maxResults;
  }

  if (dealerOrgId) {
    params.dealerOrgId = dealerOrgId;
  }

  return (dispatch) => {
    // Connected Car and trip legs comes together in the same position-update
    // method and are only diferentiated by the "type" attribute. When it is
    // from connected car, it is of type "entity", when it is not, it is of
    // type "tripleg"
    return axios
      .get(url, { params })
      .then((response) => {
        if (type.includes("entity")) {
          dispatch({
            type: RECEIVE_ENTITY_CONNECTED_CAR_POSITION_UPDATES,
            // Default to [] if data happens to be null.
            payload: response.data?.data ?? [],
          });
        } else {
          dispatch({
            type: RECEIVE_ENTITY_POSITION_UPDATES,
            payload: {
              // Default to [] if data happens to be null.
              entityPositionUpdates: response.data?.data ?? [],
            },
          });
        }
      })
      .catch((err) => {
        console.error("fetchEntityPositionUpdates error:", err);
      });
  };
}

function clearEntityMedia() {
  return (dispatch) =>
    dispatch({
      type: CLEAR_MEDIA,
    });
}

function fetchEntityMedia(url) {
  if (!url) {
    return clearEntityMedia();
  }

  return (dispatch) => {
    dispatch({
      type: RECEIVE_ENTITY_MEDIA,
      payload: { entityMedia: url },
    });
  };
}

function fetchEntityExceptions(
  entityId,
  dealerOrgId = null,
  exceptionStatus = "ACTIVE",
) {
  let url = entityUrl(entityId) + "/exception";

  const params = {};

  if (exceptionStatus) {
    params.status = exceptionStatus;
  }

  if (dealerOrgId) {
    params.dealerOrgId = dealerOrgId;
  }

  return (dispatch) => {
    return Promise.all([axios.get(url, { params })])
      .then((responses) => {
        dispatch({
          type: RECEIVE_ENTITY_EXCEPTIONS,
          payload: { entityExceptions: responses[0].data },
        });
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

function fetchEntityHolds(entityId, holdStatus = "ACTIVE") {
  let url = entityUrl(entityId) + "/hold";

  const params = {};

  if (holdStatus) {
    params.status = holdStatus;
  }

  return (dispatch) => {
    return Promise.all([axios.get(url, { params })])
      .then((responses) => {
        dispatch({
          type: RECEIVE_ENTITY_HOLDS,
          payload: { entityHolds: responses[0].data },
        });
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

function fetchEntityEvents(solutionId, entityId, dealerOrgId = null) {
  let url = `${entityUrl(entityId)}/event`;
  let params = {
    code: CarrierAndDealerTripSummaryEventCodes.join(","),
  };

  if (dealerOrgId) {
    params.dealerOrgId = dealerOrgId;
  }

  return (dispatch) => {
    return Promise.all([
      axios.get(url, {
        headers: {
          Accept: "application/vnd+freightVerify.eventDetail.v1+json",
        },
        params,
      }),
    ])
      .then((responses) => {
        dispatch({
          type: RECEIVE_ENTITY_EVENTS,
          payload: { entityEvents: responses[0].data },
        });
      })
      .catch((err) => {
        throw new Error(err);
        //console.log(err);
      });
  };
}

function fetchComments(solutionId, entityId, pageNumber, pageSize) {
  let url = `${entityUrl(entityId)}/comment`;
  if (pageNumber && pageSize) {
    url += `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  return (dispatch) => {
    let clearData = false;
    if (pageNumber === 0) {
      clearData = true;
      dispatch({
        type: FETCH_COMMENTS,
      });
    }

    return axios
      .get(url)
      .then((response) => {
        dispatch({
          type: RECEIVE_COMMENTS,
          payload: { comments: response.data, clearData },
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: FETCH_COMMENTS_FAILED,
        });
      });
  };
}

function addComment(solutionId, entityId, data) {
  let url = `${entityUrl(entityId)}/comment`;

  return (dispatch) => {
    const fakeCommentId = uuidv4();
    dispatch({
      type: SUBMIT_NEW_COMMENT,
      payload: { data: { ...data, id: fakeCommentId, isAdding: true } },
    });

    const requestData = { text: data.text, shared_with: data.shared_with };

    return axios
      .post(url, requestData)
      .then((response) => {
        dispatch({
          type: RECEIVE_NEW_COMMENT,
          // Force the new comment to be marked as read
          payload: { data: { ...response.data, read: true }, fakeCommentId },
        });
      })
      .catch((err) => {
        dispatch({
          type: SUBMIT_NEW_COMMENT_FAILED,
          payload: { fakeCommentId },
        });
      });
  };
}

function addBatchComments(data, isCsvFormat) {
  // For VINView shipperOrgId is the solutionId to identify ownership of VINs
  const solutionId = data.shipperOrgId;

  let url = apiUrl(`/entity/solution/${solutionId}/comment/batch`);
  if (isCsvFormat) {
    url += `?sharedWith=[${data.shareableOrg}]`;
  }
  const headers = {
    "content-type": isCsvFormat ? "text/csv" : "application/json",
  };
  const postData = isCsvFormat ? data.csv : data;

  return (dispatch) => {
    dispatch({
      type: SUBMIT_BATCH_COMMENTS,
      payload: { data },
    });
    return axios
      .post(url, postData, { headers })
      .then((response) => {
        dispatch({
          type: SUBMIT_BATCH_COMMENTS_SUCCESS,
          payload: { data },
        });
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: SUBMIT_BATCH_COMMENTS_FAILED,
          payload: { data },
        });
      });
  };
}

function clearBatchComments() {
  return (dispatch) => {
    dispatch({ type: SUBMIT_BATCH_COMMENTS_RESET });
  };
}

function fetchEntityShippers() {
  const url = apiUrl("/entity-search/list?shipper=true");

  return (dispatch) => {
    dispatch({ type: FETCH_SHIPPERS });
    return Promise.all([axios.get(url)])
      .then((responses) => {
        dispatch({
          type: FETCH_SHIPPERS_SUCCESS,
          payload: { data: responses[0].data },
        });
      })
      .catch((error) => {
        dispatch({ type: FETCH_SHIPPERS_FAILED });
        console.error("ERROR retreiving entity shippers", error);
      });
  };
}

function cancelAddComment(fakeCommentId) {
  return (dispatch) => {
    dispatch({
      type: CANCEL_NEW_COMMENT,
      payload: { fakeCommentId },
    });
  };
}

function updateComment(solutionId, entityId, commentId, updatedData) {
  let url = `${entityUrl(entityId)}/comment/${commentId}`;

  return (dispatch) => {
    dispatch({
      type: SET_IS_UPDATING_COMMENT,
      payload: { isUpdating: true, commentId, updatedData },
    });

    const requestData = {
      text: updatedData.text,
      shared_with: updatedData.shared_with,
    };

    return axios
      .patch(url, requestData)
      .then((response) => {
        dispatch({
          type: SET_IS_UPDATING_COMMENT,
          payload: { isUpdating: false, commentId, updatedData: response.data },
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_IS_UPDATING_COMMENT_FAILED,
          payload: { commentId },
        });
      });
  };
}

function cancelUpdateComment(commentId) {
  return (dispatch) => {
    dispatch({
      type: CANCEL_UPDATE_COMMENT,
      payload: { commentId },
    });
  };
}

function markCommentsRead(solutionId, entityId, datetime) {
  let url = `${entityUrl(entityId)}/comment/read`;

  // Pass in the current date and time as the last read date
  const data = {
    date_until: moment.utc(datetime).format("YYYY-MM-DDTHH:mm:ss.SSS"),
  };

  return (dispatch) => {
    return axios
      .post(url, data)
      .then((response) => {
        // Do nothing
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

function shareVin(
  solutionId,
  entityId,
  emails,
  capturedScreenshot,
  timezone,
  dealerOrgId = null,
) {
  let url = `${entityUrl(entityId)}/share`;

  if (dealerOrgId) {
    url += `?dealerOrgId=${dealerOrgId}`;
  }

  return (dispatch) => {
    dispatch({
      type: SHARE_VIN_BEGIN,
    });

    const requestData = {
      shareTo: emails,
      mapImageBase64: capturedScreenshot,
      timezone,
    };

    return axios
      .post(url, requestData)
      .then((response) => {
        dispatch({
          type: SHARE_VIN_SUCCESS,
        });
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: SHARE_VIN_FAILED,
        });
      });
  };
}

function clearShareVin(clearIsSuccessful) {
  return (dispatch) => {
    dispatch({
      type: SHARE_VIN_CLEAR,
      clearIsSuccessful,
    });
  };
}

function setWatchEntity(
  solutionId,
  entityId,
  watch = true,
  dealerOrgId = null,
) {
  let url = entityUrl(entityId);

  if (dealerOrgId) {
    url += `?dealerOrgId=${dealerOrgId}`;
  }

  return (dispatch) => {
    return Promise.all([axios.patch(url, { watch })])
      .then((responses) => {
        dispatch({ type: ENTITY_UPDATE_SUCCESS });
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

// Selectors
const getEntityDetails = (state) => state[STORE_MOUNT_POINT].entityDetails;

const getEntityDetailsError = (state) =>
  state[STORE_MOUNT_POINT].entityDetailsError;

const translatePositionUpdates = (item) => {
  return {
    time: item.datetime,
    db_time: item.receivedDatetime,
    latitude: item.latitude,
    longitude: item.longitude,
    city: item.city,
    state: item.state,
    country: item.country,
    positionType: item.positionType,
    references: item.references,
  };
};

const getEntityPositionUpdates = createSelector(
  // only recalculate when entityPositionUpdates changes
  [
    (state) =>
      state[STORE_MOUNT_POINT].entityPositionUpdates.entityPositionUpdates,
  ],
  (entityPositionUpdates) => {
    if (entityPositionUpdates) {
      return entityPositionUpdates.map(translatePositionUpdates);
    }
    return [];
  },
);

const getEntityConnectedCarPositionUpdates = createSelector(
  // only recalculate when entityConnectedCarPositionUpdates changes
  [(state) => state[STORE_MOUNT_POINT].entityConnectedCarPositionUpdates],
  (entityConnectedCarPositionUpdates) => {
    return entityConnectedCarPositionUpdates.map(translatePositionUpdates);
  },
);

const getEntityExceptions = createSelector(
  // only recalculate when entityExceptions changes
  [(state) => state[STORE_MOUNT_POINT].entityExceptions],
  (entityExceptions) => {
    return entityExceptions ? _.flatten(entityExceptions) : [];
  },
);

const getEntityHolds = createSelector(
  // only recalculate when entityHolds changes
  [(state) => state[STORE_MOUNT_POINT].entityHolds],
  (entityHolds) => {
    // FIXME remove this once the attributes come in the same case.
    return entityHolds.map((hold) => {
      let holdCopy = Object.assign({}, hold);
      holdCopy.typeName = _.get(hold, "type_name", "");
      hold.typeName && delete holdCopy.type_name;

      if (holdCopy.type) {
        holdCopy.typeName = `${holdCopy.typeName} (${holdCopy.type})`;
      }

      return holdCopy;
    });
  },
);

const getEntityEvents = createSelector(
  // only recalculate when entityEvents changes
  [(state) => state[STORE_MOUNT_POINT].entityEvents],
  (entityEvents) => entityEvents || [],
);

const getCombinedExceptions = createSelector(
  [getEntityExceptions, getEntityHolds, getEntityDetails],
  (exceptions, holds, details) => {
    return details?.configurations?.displayHoldEventsForDealersAndCarriers
      ? holds
      : exceptions;
  },
);

const getEntityMedia = (state) => {
  return state[STORE_MOUNT_POINT].entityMedia || null;
};

const getEntityCurrentLocation = createSelector(
  getEntityPositionUpdates,
  (positionUpdates) => _.orderBy(positionUpdates, ["time"], ["desc"])[0],
);

const getIsFetchingComments = (state) =>
  state[STORE_MOUNT_POINT].isFetchingComments;
const getComments = (state) => state[STORE_MOUNT_POINT].comments || [];

const getIsSharingVin = (state) =>
  state[STORE_MOUNT_POINT].isSharingVin ?? false;
const getIsShareVinSuccessful = (state) =>
  state[STORE_MOUNT_POINT].isShareVinSuccessful ?? false;
const getIsShareVinFailed = (state) =>
  state[STORE_MOUNT_POINT].isShareVinFailed ?? false;

const getIsBatchCommentInProgress = (state) =>
  state[STORE_MOUNT_POINT].isBatchCommentInProgress;
const getIsBatchCommentSuccessful = (state) =>
  state[STORE_MOUNT_POINT].isBatchCommentSuccessful;
const getIsBatchCommentFailed = (state) =>
  state[STORE_MOUNT_POINT].isBatchCommentFailed;

const getEntityStateChangeTs = (state) => (entityState) => {
  const entityStateChangeRecord = state[
    STORE_MOUNT_POINT
  ]?.entityStateHistory.filter((record) => record.to_state === entityState);
  return entityStateChangeRecord.length
    ? _.orderBy(entityStateChangeRecord, ["state_change_ts"], ["desc"])[0]
        ?.state_change_ts
    : null;
};

const getEntityShippers = (state) => state[STORE_MOUNT_POINT].entityShippers;

// Initial state
const initialState = {
  entityDetails: null,
  entityDetailsError: null,
  entityExceptions: [],
  // Since FinishedVehicle and VINView share a template
  // we need to pass in an empty holds since we don't care about that for VINView.
  entityHolds: [],
  entityPositionUpdates: [],
  entityConnectedCarPositionUpdates: [],
  entityShippers: [],
  isFetchingComments: false,
  comments: {
    totalPages: 0,
    totalCount: 0,
    totalCountUnread: 0,
    data: [],
  },
  isSharingVin: false,
  isShareVinSuccessful: false,
  isShareVinFailed: false,
  isBatchCommentInProgress: false,
  isBatchCommentSuccessful: false,
  isBatchCommentFailed: false,
  entityStateHistory: [],
};

const VinViewEntityDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_ENTITY_DETAILS:
      return {
        ...state,
        entityDetails: action.payload.entityDetails,
      };

    case RECEIVE_ENTITY_DETAILS_ERROR:
      return {
        ...state,
        entityDetails: null,
        entityDetailsError: action.payload.error,
      };

    case RECEIVE_ENTITY_MEDIA:
      return {
        ...state,
        entityMedia: action.payload.entityMedia,
      };

    case CLEAR_MEDIA:
      return {
        ...state,
        entityMedia: null,
      };

    case RECEIVE_ENTITY_POSITION_UPDATES:
      return {
        ...state,
        entityPositionUpdates: action.payload,
      };

    case RECEIVE_ENTITY_CONNECTED_CAR_POSITION_UPDATES:
      return {
        ...state,
        entityConnectedCarPositionUpdates: action.payload,
      };

    case RECEIVE_ENTITY_EXCEPTIONS:
      return {
        ...state,
        entityExceptions: action.payload.entityExceptions,
      };

    case RECEIVE_ENTITY_HOLDS:
      return {
        ...state,
        entityHolds: action.payload.entityHolds,
      };

    case RECEIVE_ENTITY_EVENTS:
      return {
        ...state,
        entityEvents: action.payload.entityEvents,
      };

    case FETCH_COMMENTS:
      return {
        ...state,
        isFetchingComments: true,
        comments: initialState.comments,
      };

    case FETCH_COMMENTS_FAILED:
      return {
        ...state,
        isFetchingComments: false,
      };

    case RECEIVE_COMMENTS:
      return {
        ...state,
        isFetchingComments: false,
        comments: {
          ...action.payload.comments,
          // The infinite-scrolling package requires the data to be consolidated,
          // not just the current page. So do a union of the new data with the old data.
          // Ignore the above if this is the first page of data.
          data: action.payload.clearData
            ? action.payload.comments.data
            : _.union(state.comments.data, action.payload.comments.data),
        },
      };

    case SUBMIT_NEW_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: [action.payload.data].concat(state.comments.data),
        },
      };

    case RECEIVE_NEW_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          // Manually increase the total count of comments
          totalCount: state.comments.totalCount + 1,
          // If this is the first comment, update the number of pages to 1
          totalPages:
            state.comments.totalPages === 0
              ? state.comments.totalPages + 1
              : state.comments.totalPages,
          data: state.comments.data.map((c) =>
            c.id === action.payload.fakeCommentId ? action.payload.data : c,
          ),
        },
      };

    case SUBMIT_NEW_COMMENT_FAILED:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: state.comments.data.map((c) =>
            c.id === action.payload.fakeCommentId
              ? { ...c, isAdding: false, isAddingFailed: true }
              : c,
          ),
        },
      };

    case CANCEL_NEW_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: state.comments.data.filter(
            (c) => c.id !== action.payload.fakeCommentId,
          ),
        },
      };

    case SET_IS_UPDATING_COMMENT: {
      let commentList = state.comments.data ? [...state.comments.data] : [];
      if (action.payload.updatedData) {
        const updatedData = action.payload.updatedData;
        commentList = commentList.map((c) =>
          c.id === action.payload.commentId
            ? {
                ...c,
                ...updatedData,
                // Keep track of the original data in case we need to cancel the update.
                // Gets cleared on success.
                original: action.payload.isUpdating ? c : null,
              }
            : c,
        );
      }

      commentList = commentList.map((c) =>
        c.id === action.payload.commentId
          ? {
              ...c,
              isUpdating: action.payload.isUpdating,
              isUpdatingFailed: false,
            }
          : c,
      );

      return {
        ...state,
        comments: { ...state.comments, data: commentList },
      };
    }

    case SET_IS_UPDATING_COMMENT_FAILED: {
      let commentList = state.comments.data ? [...state.comments.data] : [];

      commentList = commentList.map((c) =>
        c.id === action.payload.commentId
          ? {
              ...c,
              isUpdating: false,
              isUpdatingFailed: true,
            }
          : c,
      );

      return {
        ...state,
        comments: { ...state.comments, data: commentList },
      };
    }

    case CANCEL_UPDATE_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: state.comments.data.map((c) =>
            c.id === action.payload.commentId
              ? { ...c.original, isUpdating: false, isUpdatingFailed: false }
              : c,
          ),
        },
      };

    case SUBMIT_BATCH_COMMENTS:
      return {
        ...state,
        isBatchCommentInProgress: true,
      };

    case SUBMIT_BATCH_COMMENTS_SUCCESS:
      return {
        ...state,
        isBatchCommentInProgress: false,
        isBatchCommentSuccessful: true,
      };

    case SUBMIT_BATCH_COMMENTS_FAILED:
      return {
        ...state,
        isBatchCommentInProgress: false,
        isBatchCommentFailed: true,
      };

    case SUBMIT_BATCH_COMMENTS_RESET:
      return {
        ...state,
        isBatchCommentInProgress: false,
        isBatchCommentSuccessful: false,
        isBatchCommentFailed: false,
      };

    case SHARE_VIN_CLEAR:
      return {
        ...state,
        isSharingVin: false,
        isShareVinSuccessful: action.clearIsSuccessful
          ? false
          : state.isShareVinSuccessful,
        isShareVinFailed: false,
      };

    case SHARE_VIN_BEGIN:
      return {
        ...state,
        isSharingVin: true,
        isShareVinSuccessful: false,
        isShareVinFailed: false,
      };

    case SHARE_VIN_SUCCESS:
      return {
        ...state,
        isSharingVin: false,
        isShareVinSuccessful: true,
        isShareVinFailed: false,
      };

    case SHARE_VIN_FAILED:
      return {
        ...state,
        isSharingVin: false,
        isShareVinSuccessful: false,
        isShareVinFailed: true,
      };

    case FETCH_SHIPPERS_SUCCESS:
      const hasFV = (solution) => solution.feature === "Finished Vehicle";

      const entityShippers = action.payload.data.shipper
        .filter((shipper) => shipper.solutions.some(hasFV))
        .map((shipper) => {
          const label = shipper.org_name;
          const finishedVehicleSolution = shipper.solutions.find(hasFV);
          return {
            shipper_name: label,
            shipper_organization_id: finishedVehicleSolution?.solution,
          };
        });
      return {
        ...state,
        entityShippers,
      };

    case RECEIVE_ENTITY_STATE_HISTORY:
      const { entityStateHistory } = action.payload;
      return {
        ...state,
        entityStateHistory: entityStateHistory,
      };

    case RECEIVE_ENTITY_STATE_HISTORY_ERROR:
      return {
        ...state,
        entityStateHistory: [],
      };

    default:
      return state;
  }
};

// interface
const VinViewEntityDetailsState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchEntityDetails,
    clearEntityMedia,
    fetchEntityMedia,
    fetchEntityExceptions,
    fetchEntityHolds,
    fetchEntityPositionUpdates,
    fetchEntityEvents,
    fetchEntityStateHistory,
    setWatchEntity,
    fetchComments,
    addComment,
    addBatchComments,
    clearBatchComments,
    fetchEntityShippers,
    cancelAddComment,
    updateComment,
    cancelUpdateComment,
    markCommentsRead,
    shareVin,
    clearShareVin,
  },
  selectors: {
    getCombinedExceptions,
    getEntityCurrentLocation,
    getEntityExceptions,
    getEntityHolds,
    getEntityDetails,
    getEntityDetailsError,
    getEntityPositionUpdates,
    getEntityConnectedCarPositionUpdates,
    getEntityEvents,
    getEntityMedia,
    getIsFetchingComments,
    getComments,
    getIsSharingVin,
    getIsShareVinSuccessful,
    getIsShareVinFailed,
    getIsBatchCommentInProgress,
    getIsBatchCommentSuccessful,
    getIsBatchCommentFailed,
    getEntityShippers,
    getEntityStateChangeTs,
  },
  reducer: VinViewEntityDetailsReducer,
};
export default VinViewEntityDetailsState;
