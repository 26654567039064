import Colors from "styles/colors";

export const strokeDasharray = "5, 5";
export const strokeLinecap = "round";
export const strokeLinejoin = "round";
export const strokeWidth = "2px";

export const axisTicksLabelStyle = { fontFamily: "inherit" };

// Victory Chart style object
export const axisStyle = {
  axis: {
    fontSize: 12,
    stroke: Colors.border.BOTTOM_AXIS_BORDER,
    strokeDasharray,
    strokeLinecap,
    strokeLinejoin,
  },
  grid: {
    stroke: Colors.border.BOTTOM_AXIS_BORDER,
    strokeDasharray,
    strokeLinecap,
    strokeLinejoin,
  },
};

export const crossAxisStyle = {
  axis: {
    fontSize: 12,
    stroke: Colors.border.BOTTOM_AXIS_BORDER,
    strokeDasharray,
    strokeLinecap,
    strokeLinejoin,
  },
  axisLabel: { fontSize: 12 },
  grid: {
    stroke: Colors.border.BOTTOM_AXIS_BORDER,
    strokeDasharray,
    strokeLinecap,
    strokeLinejoin,
  },
  tickLabels: {
    fontSize: 12,
    padding: 5,
    fontFamily: "inherit",
  },
};
