import moment from "moment";
import _ from "lodash";

import apiUrl from "api-url";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";
import { SEARCH_CATEGORIES } from "../components/search/SurgicalToteTracking.Search.Options";
import { FILTERS } from "../components/search/SurgicalToteTracking.Search.Options";
import { Features } from "modules/auth/Authorization";

const STORE_MOUNT_POINT = "sttSearch";

const entitiesUrl = (solutionId, queryString) => {
  return apiUrl(
    `/containertracking/api/reuse-trip-container-search/container?${queryString}`,
  );
};

const batchSearchUrl = (solutionId, queryString, batchType) => {
  return apiUrl(
    `/containertracking/batch-search?${queryString}&batchType=${batchType}`,
  );
};

const axiosConfig = () => {
  return {
    headers: {
      "x-time-zone": moment.tz.guess(),
      "x-target-feature": Features.SURGICAL_TOTE_TRACKING,
    },
  };
};

const fetchSearch = (
  queryString = "",
  solutionId,
  duck,
  dispatch,
  state,
  preventRedirect,
) => {
  const url = entitiesUrl(solutionId, queryString, state);
  const config = axiosConfig();
  dispatch(duck.fetch(url, config));
  // Prevent redirect if needed
  if (!preventRedirect) {
    // Redirect to Surgical Tote Tracking search page
    dispatch({ type: "SURGICAL_TOTE_TRACKING_SEARCH" });
  }
};

// Selectors
const getTotalCountForSearch = (state) =>
  state[STORE_MOUNT_POINT].data?.meta?.totalCount || 0;

const getTotalCountForSearchIsLoading = (state) =>
  state[STORE_MOUNT_POINT].totalCountForSearchIsLoading || false;

const getTotalPageCountForSearch = (state) =>
  state[STORE_MOUNT_POINT].data?.meta?.totalPages || 0;

// Reducer
const sttSearchBarReducer = (
  state = {
    totalCountForSearch: 0,
    totalPageCountForSearch: 0,
    totalCountForSearchIsLoading: false,
  },
  action,
) => {
  switch (action.type) {
    default:
      return state;
  }
};

const SearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  FILTERS,
  fetchSearch,
  [sttSearchBarReducer],
);

SearchBarState.actionCreators = {
  ...SearchBarState.actionCreators,
  exportSearch: _.partial(
    SearchBarState.actionCreators.exportEntities,
    entitiesUrl,
    batchSearchUrl,
    {
      headers: {
        Accept: "text/csv;version=basic",
        "x-time-zone": moment.tz.guess(),
        "x-target-feature": Features.SURGICAL_TOTE_TRACKING,
      },
    },
    "sugical-tote-tracking-search-results",
  ),
};

SearchBarState.selectors = {
  ...SearchBarState.selectors,
  getTotalCountForSearch,
  getTotalCountForSearchIsLoading,
  getTotalPageCountForSearch,
};

export default SearchBarState;
