/** @jsxImportSource @emotion/react */
import { Suspense, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Tabs } from "components/molecules/Tabs.molecule";
import Colors from "styles/colors";
import { FlexRowDiv } from "styles/container-elements";

import { Modal } from "components/molecules/Modal.molecule";
import { FormButton, SelectInput, TextInput } from "components-old/modal-elems";
import { buildSearchLocationOptions } from "pages/administration/location-management/locations/search/LocationSearchStateExtensions";
import AuthenticationUtils from "modules/auth/authentication";

export const tabStyle = {
  display: "flex",
  cursor: "pointer",
  justifyContent: "space-around",
  alignItems: "center",
  color: Colors.background.GRAY_BLUE,
  height: 47,
  borderRadius: "5px 5px 0 0",
  flex: 1,
};

const initFilteredReport = {
  name: "",
  saveLocation: null,
  description: "",
};

const options = [
  {
    value: "my",
    label: "My Reports",
  },
  {
    value: "shared",
    label: "Shared Reports",
  },
];

const ReportsFilterModal = ({
  report,
  hide,
  show,
  setShipmentSearchFilter,
  clearShipmentSearchFilters,
  setFinishedVehicleSearchFilter,
  clearFinishedVehicleSearchFilters,
  setContainerTrackingSearchFilter,
  clearContainerTrackingSearchFilters,
  ShipmentFilterSectionContainer,
  FinishedVehicleFilterSectionContainer,
  ContainerTrackingFilterSectionContainer,
  shipmentSearchFilters,
  finishedVehicleSearchFilters,
  fetchDomainDataFIN,
  fetchDomainDataCT,
  containerTrackingSearchFilters,
  saveFilteredReport,
  isSaving,
  updateFilteredReport,
  isUpdating,
  solutionId,
  fetchLocationDetails,
  locations,
  modalReportType,
  reportType,
}) => {
  const { t } = useTranslation("reports");

  const [filteredReport, setFilteredReport] = useState(initFilteredReport);
  const [error, setError] = useState("");
  const [startedSaving, setStartedSaving] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [prevReportCategory, setPrevReportCategory] = useState(null);

  useEffect(() => {
    // When we've started saving, update the state so we know when to fetch reports
    if ((isSaving || isUpdating) && !startedSaving) {
      setStartedSaving(true);
    } else if (startedSaving && !isSaving && !isUpdating) {
      setStartedSaving(false);
    }
  }, [startedSaving, setStartedSaving, isSaving, isUpdating, hide]);

  useEffect(() => {
    if (prevReportCategory) {
      let reportCategoryName = prevReportCategory
        .toUpperCase()
        .split(" ")
        .join("_");

      if (startedSaving && !isSaving && !isUpdating) {
        if (reportType === reportCategoryName) {
          // fetch the latest results for the current tab
          modalReportType(reportType);
        }
        // latest results for other tabs will be fetched while switching to the respective tabs
        // hide the modal
        hide();
      }
    }
  }, [
    prevReportCategory,
    startedSaving,
    isSaving,
    isUpdating,
    reportType,
    modalReportType,
    hide,
  ]);

  useEffect(() => {
    // Finished Vehicle domain data needs to be fetched
    // Note: Shipment domain data is fetched by the App on initial page load
    if (fetchDomainDataFIN) {
      fetchDomainDataFIN(solutionId);
    }
  }, [fetchDomainDataFIN, solutionId]);

  useEffect(() => {
    fetchDomainDataCT();
  }, [fetchDomainDataCT]);

  useEffect(() => {
    if (report) {
      if (report.filterSet) {
        setFilteredReport((prevState) => {
          return {
            ...prevState,
            name: report.filterSet.name,
            description: report.filterSet.description,
            saveLocation: report.filterSet.private ? options[0] : options[1],
          };
        });

        if (report.filterSet.filters) {
          const shipmentFilters = Object.entries(
            report.filterSet.filters.shipments,
          );
          const finishedVehicleFilters = Object.entries(
            report.filterSet.filters.finished_vehicle,
          );
          const containertrackingFilters = Object.entries(
            report.filterSet.filters.container_tracking,
          );

          if (shipmentFilters) {
            for (const [key, value] of shipmentFilters) {
              // For origin/destination, we need to lookup the name
              // since we only save out the ID
              if (key === "origin" || key === "destination") {
                value.forEach((v) => {
                  fetchLocationDetails(v);
                });
              } else {
                setShipmentSearchFilter(key, value);
              }
            }
          }

          if (finishedVehicleFilters) {
            for (const [key, value] of finishedVehicleFilters) {
              setFinishedVehicleSearchFilter(key, value);
            }
          }

          if (containertrackingFilters) {
            for (const [key, value] of containertrackingFilters) {
              setContainerTrackingSearchFilter(key, value);
            }
          }
        }
      } else {
        setFilteredReport((prevState) => {
          return {
            ...prevState,
            name: report.name,
            description: report.description,
          };
        });
      }
    }
  }, [
    report,
    setShipmentSearchFilter,
    setFinishedVehicleSearchFilter,
    setContainerTrackingSearchFilter,
    fetchLocationDetails,
  ]);

  // For tracking locations for Shipment Origin/Destination
  useEffect(() => {
    if (locations && report && report.filterSet && report.filterSet.filters) {
      // Map the shipmentFilters
      const shipmentFilters = Object.entries(
        report.filterSet.filters.shipments,
      );

      if (shipmentFilters) {
        // Go through each filter and see if the user has specified Origin/Destination
        for (const [key, value] of shipmentFilters) {
          if (key === "origin" || key === "destination") {
            // If so, loop through all locations we've searched for and
            // find the matching IDs
            let filteredLocations = [];
            value.forEach((locationId) => {
              locations.forEach((location) => {
                if (
                  location.id === locationId &&
                  location.name &&
                  location.name.length > 0
                ) {
                  filteredLocations.push(location);
                }
              });
            });

            // For each location we've found
            if (filteredLocations.length > 0) {
              // Convert it to the proper format for the filter and apply it
              setShipmentSearchFilter(
                key,
                buildSearchLocationOptions(filteredLocations),
              );
            }
          }
        }
      }
    }
  }, [locations, report, setShipmentSearchFilter]);

  useEffect(() => {
    // If a report is a filterSet from a core report and is shared,
    // don't allow changing of the save location (unless it's the original user who made it)
    if (
      report &&
      report.groupName === "Default" &&
      report.filterSet &&
      !report.filterSet.private &&
      report.filterSet.createdBy?.toLowerCase() !==
        AuthenticationUtils.userEmail.toLowerCase()
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [report, setIsDisabled]);

  const inputHandler = (value) => {
    setFilteredReport((prevState) => {
      return { ...prevState, ...value };
    });
  };

  const clearForm = useCallback(() => {
    setFilteredReport(initFilteredReport);
    clearFinishedVehicleSearchFilters();
    clearShipmentSearchFilters();
    clearContainerTrackingSearchFilters();
  }, [
    setFilteredReport,
    clearFinishedVehicleSearchFilters,
    clearShipmentSearchFilters,
    clearContainerTrackingSearchFilters,
  ]);

  useEffect(() => {
    if (!show) {
      clearForm();
    }
  }, [show, clearForm]);

  const onCancel = () => {
    clearForm();
    hide();
  };

  const onSave = () => {
    // Clear errors
    setError("");

    // Name is required
    if (!filteredReport.name || filteredReport.name.length === 0) {
      setError(t("reports:Report Name is required."));
      return;
    }

    // Save location is required
    if (!filteredReport.saveLocation) {
      setError(
        t("reports:Please select where you would like to save your report to."),
      );
      return;
    }

    //get the value selected in the Save report Under
    setPrevReportCategory(filteredReport.saveLocation.label);

    // Define filters
    let filters = {
      shipments: null,
      finished_vehicle: null,
      container_tracking: null,
    };

    // At least 1 filter is required
    if (
      Object.keys(shipmentSearchFilters).length === 0 &&
      Object.keys(finishedVehicleSearchFilters).length === 0 &&
      Object.keys(containerTrackingSearchFilters).length === 0
    ) {
      setError(t("reports:Please select at least one filter."));
      return;
    }
    // Make a deep copy of the filter values so we can edit them (without affecting the filters)
    let shipmentPayload = JSON.parse(JSON.stringify(shipmentSearchFilters));

    // Cleanup some filters values
    if (shipmentPayload.destination) {
      shipmentPayload.destination.forEach((element, index, array) => {
        array[index] = element.id;
      });
    }

    if (shipmentPayload.origin) {
      shipmentPayload.origin.forEach((element, index, array) => {
        array[index] = element.id;
      });
    }

    if (shipmentPayload.service_code) {
      shipmentPayload.service_code.forEach((element, index, array) => {
        array[index] = element.value;
      });
    }
    // Set filters
    filters.shipments = shipmentPayload;
    filters.finished_vehicle = finishedVehicleSearchFilters;
    filters.container_tracking = containerTrackingSearchFilters;

    let data = {
      workspace_number: report.workspaceId,
      report_name: filteredReport.name,
      report_description: filteredReport.description,
      filters: JSON.stringify(filters),
    };

    // Figure out whether this is a private report or not based on the location value
    if (filteredReport.saveLocation === options[0]) {
      data.private = true;
    } else if (filteredReport.saveLocation === options[1]) {
      data.private = false;
    } else {
      console.error("Invalid location specified: ", data.location);
    }

    // Save
    if (report.filterSet) {
      // We are updating an existing filter set
      updateFilteredReport(report.reportId, report.filterSet.filterSetId, data);
    } else {
      // We are creating a new filter set
      saveFilteredReport(report.reportId, data);
    }
  };

  return (
    <Modal
      size="lg"
      backdrop="static"
      show={show}
      onHide={() => {
        clearForm();
        hide();
      }}
    >
      <Suspense fallback="">
        <Modal.Header title={t("reports:Create Filtered Report")} />
        <Modal.Body>
          <FlexRowDiv>
            <TextInput
              label={t("reports:Report Name")}
              value={filteredReport.name}
              changeHandler={inputHandler}
              prop="name"
              style={{ marginRight: "1em" }}
            />
            <SelectInput
              label={t("reports:Save Report Under")}
              options={options}
              value={filteredReport.saveLocation}
              changeHandler={inputHandler}
              prop={"saveLocation"}
              multi={false}
              searchable={false}
              disabled={isDisabled}
              style={{ width: "50%" }}
            />
          </FlexRowDiv>
          <FlexRowDiv style={{ marginTop: "0.5em" }}>
            <TextInput
              label={t("reports:Description")}
              value={filteredReport.description}
              changeHandler={inputHandler}
              prop="description"
              style={{ width: "100%" }}
            />
          </FlexRowDiv>
          <FlexRowDiv style={{ marginTop: "1.5em", flexDirection: "column" }}>
            <Tabs fullWidthTabs>
              <Tabs.TabList>
                <Tabs.Tab>{t("reports:Shipments")}</Tabs.Tab>
                <Tabs.Tab>{t("reports:Finished Vehicle")}</Tabs.Tab>
                <Tabs.Tab>{t("reports:Container Tracking")}</Tabs.Tab>
              </Tabs.TabList>
              <Tabs.TabPanel>
                <ShipmentFilterSectionContainer
                  show={true}
                  backgroundColor={Colors.background.WHITE}
                  hideBatchFilter={true}
                  hideSearchButton={true}
                />
              </Tabs.TabPanel>
              <Tabs.TabPanel>
                <FinishedVehicleFilterSectionContainer
                  show={true}
                  backgroundColor={Colors.background.WHITE}
                  hideBatchFilter={true}
                  hideSearchButton={true}
                />
              </Tabs.TabPanel>
              <Tabs.TabPanel>
                <ContainerTrackingFilterSectionContainer
                  show={true}
                  backgroundColor={Colors.background.WHITE}
                  hideSearchButton={true}
                  hideBatchFilter={true}
                />
              </Tabs.TabPanel>
            </Tabs>
          </FlexRowDiv>
        </Modal.Body>
        <Modal.Footer>
          {error && (
            <Alert
              variant="danger"
              dismissible
              css={{ flex: 1, margin: 0, marginRight: "1em" }}
              onClose={() => setError("")}
            >
              <p css={{ margin: 0 }}>{error}</p>
            </Alert>
          )}
          <FormButton
            style={{ marginRight: ".5em" }}
            label={t("reports:Cancel")}
            clickHandler={onCancel}
            disabled={isSaving || isUpdating}
          />
          <FormButton
            actionType="ACTION"
            label={
              isSaving || isUpdating
                ? t("reports:Saving...")
                : t("reports:Save")
            }
            clickHandler={onSave}
            disabled={isSaving || isUpdating}
          />
        </Modal.Footer>
      </Suspense>
    </Modal>
  );
};

ReportsFilterModal.propTypes = {
  report: PropTypes.object,
  hide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  setShipmentSearchFilter: PropTypes.func,
  clearShipmentSearchFilters: PropTypes.func,
  setFinishedVehicleSearchFilter: PropTypes.func,
  clearFinishedVehicleSearchFilters: PropTypes.func,
  setContainerTrackingSearchFilter: PropTypes.func,
  clearContainerTrackingSearchFilters: PropTypes.func,
  shipmentSearchFilters: PropTypes.object,
  finishedVehicleSearchFilters: PropTypes.object,
  containerTrackingSearchFilters: PropTypes.object,
  ShipmentFilterSectionContainer: PropTypes.object,
  FinishedVehicleFilterSectionContainer: PropTypes.object,
  ContainerTrackingFilterSectionContainer: PropTypes.object,
  fetchDomainDataFIN: PropTypes.func,
  fetchDomainDataCT: PropTypes.func,
  saveFilteredReport: PropTypes.func,
  isSaving: PropTypes.bool,
  updateFilteredReport: PropTypes.func,
  isUpdating: PropTypes.bool,
  fetchReports: PropTypes.func,
  solutionId: PropTypes.string,
  fetchLocationDetails: PropTypes.func,
  locations: PropTypes.array,
  modalReportType: PropTypes.func.isRequired,
  reportType: PropTypes.string,
};

export default ReportsFilterModal;
