import axios from "axios";
import apiUrl from "api-url";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import chainReducers from "vendor/signal-utils/chain-reducers";
import { getSolutionId } from "modules/organizations/OrganizationsState";
import { redirectToRoute } from "redux/utils";

const MOUNT_POINT = "freezeEtas";

// Actions
const CLEAR_ACTION_STATUS = "FreezeEtas/CLEAR_ACTION_STATUS";
const FREEZE_ETAS = "FreezeEtas/FREEZE_ETAS";
const FREEZE_ETAS_SUCCEEDED = "FreezeEtas/FREEZE_ETAS_SUCCEEDED";
const FREEZE_ETAS_FAILED = "FreezeEtas/FREEZE_ETAS_FAILED";

// Fetch ducks
const duck = buildFetchDuck(MOUNT_POINT);

const getFreezeEtasUrl = (solutionId) =>
  apiUrl(`/eta-shipments/override/solution/${solutionId}`);

//Action Creators
export function pushFreezeEtasView() {
  return redirectToRoute("FREEZE_ETA");
}

export function getActionStatus(state) {
  return state[MOUNT_POINT].actionStatus;
}

export function clearActionStatus() {
  return function (dispatch) {
    dispatch({ type: CLEAR_ACTION_STATUS });
  };
}

export function freezeEtas(payload) {
  return (dispatch, getState) => {
    dispatch({ type: FREEZE_ETAS });
    const solutionId = getSolutionId(getState());
    let url = getFreezeEtasUrl(solutionId);
    const config = {
      headers: {
        Accept: "application/json;version=vin",
      },
    };
    return axios
      .post(url, payload, config)
      .then((resp) => {
        dispatch({ type: FREEZE_ETAS_SUCCEEDED });
      })
      .catch((error) => {
        console.error(error);
        dispatch({ type: FREEZE_ETAS_FAILED, error });
      });
  };
}

// Initial state
export const initialState = {
  actionStatus: null,
};

// Reducer
function FreezeEtasReducer(state = initialState, action = {}) {
  switch (action.type) {
    case CLEAR_ACTION_STATUS:
      return {
        ...state,
        actionStatus: null,
      };
    case FREEZE_ETAS:
      return {
        ...state,
        actionStatus: "FREEZE_ETAS",
      };
    case FREEZE_ETAS_SUCCEEDED:
      return {
        ...state,
        actionStatus: "FREEZE_ETAS_SUCCEEDED",
      };
    case FREEZE_ETAS_FAILED:
      return {
        ...state,
        actionStatus: "FREEZE_ETAS_FAILED",
      };
    default:
      return state;
  }
}

const FreezeETAState = {
  mountPoint: MOUNT_POINT,
  actionCreators: {
    pushFreezeEtasView,
    clearActionStatus,
    freezeEtas,
  },
  selectors: {
    getActionStatus,
  },
  reducer: chainReducers([FreezeEtasReducer, duck.reducer]),
};
export default FreezeETAState;
