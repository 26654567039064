import PropTypes from "prop-types";
import { FontSize, Text } from "../../../components/atoms/Text.atom";
import Colors from "../../../styles/colors";
import { useTranslation } from "react-i18next";

export const ShippabilityStatusText = ({ isShippable }) => {
  const { t } = useTranslation("components");
  return (
    <Text
      bold
      block
      size={FontSize.size14}
      color={isShippable ? Colors.inventoryView.GREEN : Colors.highlight.RED}
    >
      {isShippable ? t("Shippable") : t("Non-Shippable")}
    </Text>
  );
};

ShippabilityStatusText.propTypes = {
  isShippable: PropTypes.bool.isRequired,
};
