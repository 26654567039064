import { buildAsyncFilterOptionsState } from "components/search-bar/AsyncFilterOptionsStateBuilder";
import { locationsUrl } from "pages/milestone/redux/MilestoneDomainDataState";

export const milestoneLogsLocationsOptionsState = buildAsyncFilterOptionsState({
  topic: "milestoneLogsLocationsFilter",
  getUrl: locationsUrl,
  pageSize: 20,
  getResponseData: (data) => data?.locations ?? [],
  transformResult: (result) => ({ label: result.name, value: result.code }),
});
