import moment from "moment";

import { customerApiUrl } from "api-url";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";

import {
  INVENTORY_VIEW_DETAILS_FILTERS,
  INVENTORY_VIEW_DETAILS_SEARCH_CATEGORIES,
} from "../details/search/InventoryView.Details.Search.Options";
import { PaginationType, SortType } from "components/search-bar/enums.utils";
import _ from "lodash";

const STORE_MOUNT_POINT = "inventoryViewDetailsSearch";

const UPDATE_CURRENT_LOCATION = `${STORE_MOUNT_POINT}/UPDATE_CURRENT_LOCATION`;
const INVENTORY_VIEW_DETAILS_URL = `/entity-inventory/location`;

const inventoryExportUrl = (_, queryString, state) => {
  return customerApiUrl(
    `${INVENTORY_VIEW_DETAILS_URL}/${state.location.payload.locationId}/search/export?${queryString}`,
  );
};

const fetchSearch = (queryString = null, solutionId, duck, dispatch, state) => {
  const url = customerApiUrl(
    `/entity-inventory/location/${state.location.payload.locationId}/search?${queryString}`,
  );

  const config = {
    headers: {
      "x-time-zone": moment.tz.guess(),
      Accept: "application/json;",
    },
  };

  // Fetch the search
  dispatch(duck.fetch(url, config));

  dispatch({
    type: "INVENTORY_VIEW_DETAILS",
    payload: { locationId: state.location.payload.locationId },
  });
};

// Selectors
const setCurrentLocation = (id) => {
  return { type: UPDATE_CURRENT_LOCATION, payload: id };
};

const detailsSearchState = (
  state = {
    currentLocation: null,
  },
  action,
) => {
  switch (action.type) {
    case UPDATE_CURRENT_LOCATION:
      return {
        ...state,
        currentLocation: action.payload,
      };

    default:
      return state;
  }
};

const SearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  INVENTORY_VIEW_DETAILS_SEARCH_CATEGORIES,
  INVENTORY_VIEW_DETAILS_FILTERS,
  fetchSearch,
  [detailsSearchState],
  {},
  20,
  PaginationType.OPEN_SEARCH,
  SortType.OPEN_SEARCH,
);

SearchBarState.selectors = {
  ...SearchBarState.selectors,
};

SearchBarState.actionCreators = {
  ...SearchBarState.actionCreators,
  setCurrentLocation,
};

SearchBarState.actionCreators.exportSearch = _.partial(
  SearchBarState.actionCreators.exportEntities,
  inventoryExportUrl,
  null,
  { headers: { Accept: "application/json" } },
  "inventory-view-search-results",
);

export default SearchBarState;
