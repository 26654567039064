/** @jsxImportSource @emotion/react */

// External
import _ from "lodash";
import { useState, useEffect } from "react";
import Loader from "react-loader";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { isShipper } from "shared/utils/organizations.utils";

// Components
import { DetailWithMap } from "components/templates/DetailWithMap.template";
import { RoutingMapContextProvider } from "modules/map/components/RoutingMapContext";
import { CoordinatesTable } from "components-old/CoordinatesTable";
import { ContainerTrackingHistoryTab } from "./components/ContainerTrackingHistoryTab";
import { ContainerTrackingExceptionsTab } from "./components/ContainerTrackingExceptionsTab";
import { TabPanelPlaceholder } from "components/no-data-placeholders";
import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import { Tabs } from "components/molecules/Tabs.molecule";
import { ContainerTrackingDetailsWidget } from "./components/ContainerTrackingDetailsWidget";
import GeofenceBuilderMapContainer from "modules/map/components/GeofenceBuilderMap";
import { MapCoordinateType } from "modules/map/components/map-coordinate-definition";

import { CommentFeed } from "components/organisms/CommentFeed.organism";
import { batchCsvContainerExample } from "components/search-bar/batch-comment-csv-data";

// Styles
import Colors from "styles/colors";
import {
  tabsCss,
  tabListStyle,
} from "components/multimodal-components/tabStyles";
import { ContainerTrackingLocationManagementModal } from "./components/ContainerTrackingLocationManagementModal";

export const ContainerTrackingDetails = ({
  id,
  organization,
  solutionId,
  containerDetails,
  fetchContainerComments,
  addContainerComments,
  addContainerBatchComments,
  clearContainerBatchComments,
  cancelContainerAddComment,
  updateContainerComment,
  cancelContainerUpdateComment,
  markContainerCommentsRead,
  isFetchingContainerComments,
  containerComments,
  isContainerBatchCommentInProgress,
  isContainerBatchCommentSuccessful,
  isContainerBatchCommentFailed,

  fetchNotification,
  isContainerDetailsLoading,

  containerMedia,
  containerPositionUpdates,
  iscontainerLocationDetailsLoading,
  containerLocationDetails,
  fetchContainerDetails,
  fetchContainerMedia,
  clearContainerMedia,
  iscontainerSupplierLocationLoading,
  containerSupplierLocationDetails,
  fetchContainerSupplierLocationDetails,
  clearContainerDetails,
  addCoordinate,
  selectedMapCoordinate,
  fetchContainerActiveExceptions,
  ctActiveExceptions,
  fetchContainerHistory,
  containerHistory,
  watchContainerActions,
  redirectTo404,
  detailsError,
  isLocationDetailsLoading,
  locationDetails,
  updateBucketLocations,
  fetchLocation,
  fetchBucket,
  bucketDetails,
  fetchBucketDetailsFromName,
  isValidNewBucketName,
  selectedBucketLocations,
  isBucketListLoading,
  isBucketDetailsLoading,
  isValidatingBucketName,
  isFetchingBucketLocations,
  updateBucketAndLocationData,
  locationChangesSavedStatus,
  failedLocations,
  resetFailedLocationsLists,
  resetSavedChanges,
  isErrorOnSave,
  errorMessageOnRouteName,
  resetError,
  resetBucketLoctions,
  resetUpdatedLocations,
  updateLocationsOnFail,
  updatedLocations,
  fetchCountries,
  fetchStates,
  countryList,
  stateList,
  validationError,
  resetValidationError,
  initialBucketName,
  isFormSaving,
}) => {
  const { t } = useTranslation("container-tracking");
  const [watch, setWatch] = useState(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [showLocationManagementModal, setShowLocationManagementModal] =
    useState(false);

  const { rackCode = null, supplierCode } = containerDetails ?? {};
  const mapLocations = containerLocationDetails;

  useSetTitleOnMount(t("container-tracking:Container Details"));

  useEffect(() => {
    fetchContainerHistory(id);
    fetchContainerActiveExceptions(id);
    fetchContainerDetails(id);
  }, [
    id,
    fetchContainerHistory,
    fetchContainerActiveExceptions,
    fetchContainerDetails,
  ]);

  // Redirect when errors happen
  useEffect(() => {
    if (detailsError?.response?.status === 404) {
      const errorMessage = t(
        'fv-vin-details:Details for Container "[[[id]]]" could not be found',
        {
          id,
        },
      );
      redirectTo404(errorMessage);
    }
  }, [detailsError, redirectTo404, t, id]);

  useEffect(() => {
    if (supplierCode) {
      fetchContainerSupplierLocationDetails(supplierCode);
    }
    // If the details have been updated we need to make sure
    // that our watch checkbox matches the value in the props
    const watchFlag =
      containerDetails?.watched?.toLowerCase() === "true" ?? false;
    setWatch(watchFlag);
  }, [supplierCode, containerDetails, fetchContainerSupplierLocationDetails]);

  useEffect(() => {
    clearContainerMedia();
    fetchContainerMedia(rackCode);
  }, [rackCode, clearContainerMedia, fetchContainerMedia]);

  useEffect(() => {
    if (containerPositionUpdates && containerPositionUpdates.length) {
      const data = containerPositionUpdates[0];
      const { latitude, longitude, time } = data;
      addCoordinate(
        MapCoordinateType.SELECTED_COORDINATE,
        latitude,
        longitude,
        time,
        "0_0",
        data,
        null,
        0,
      );
    }
  }, [containerPositionUpdates, addCoordinate]);

  // clean up container details on unmount
  useEffect(() => {
    return () => {
      clearContainerDetails();
    };
  }, [clearContainerDetails]);

  const eventHandler = (type) => {
    switch (type) {
      case "MANAGE_LOCATIONS":
        setShowLocationManagementModal(true);
        break;
      default:
        break;
    }
  };

  function getMapChildren(loading) {
    return loading || !mapLocations || _.isEmpty(mapLocations) ? (
      <GeofenceBuilderMapContainer
        mapLocations={[]}
        selectedMapCoordinate={selectedMapCoordinate}
        initialZoomLevel={selectedMapCoordinate ? 16 : 4}
      />
    ) : (
      <GeofenceBuilderMapContainer
        drawAllGeofences
        selectedLocation={{ ...mapLocations }}
        mapLocations={mapLocations ? [mapLocations] : []}
        selectedLad={mapLocations?.lad}
        selectedMapCoordinate={selectedMapCoordinate}
        useBoxChiclets
      />
    );
  }

  let detailsChildren = (
    <Tabs
      css={tabsCss}
      onSelect={(index) => {
        setSelectedTabIndex(index);
      }}
      defaultIndex={selectedTabIndex}
    >
      <Tabs.TabList style={tabListStyle}>
        <Tabs.Tab
          style={{
            borderColor: `${Colors.tabs.BORDER}`,
            width: "50%",
          }}
        >
          {t("container-tracking:Container Details")}
        </Tabs.Tab>
      </Tabs.TabList>
      <Tabs.TabPanel
        style={{
          backgroundColor: `${Colors.background.LIGHT_GRAY}`,
          border: `1px solid ${Colors.tabs.BORDER}`,
        }}
      >
        <div
          css={{
            padding: "1em",
            display: "flex",
            flexDirection: "column",
            gap: "1em",
          }}
        >
          <ContainerTrackingDetailsWidget
            containerDetails={containerDetails}
            containerMedia={containerMedia}
            supplierLocation={containerSupplierLocationDetails}
            supplierLocationLoaded={!iscontainerSupplierLocationLoading}
            ctActiveExceptions={ctActiveExceptions}
            activeOrganization={organization}
            watch={watch}
            eventHandler={eventHandler}
            toggleWatchContainer={() => {
              // Toggle our internal state, so the UI remains responsive
              // and then send the update.  If the state is not properly
              // changed on the backend, we will revert when we receive the
              // response from the API
              const newWatchState = !watch;
              setWatch(newWatchState);
              watchContainerActions(id, newWatchState, solutionId);
            }}
          />
          {containerDetails?.id ? (
            <CommentFeed
              commentShareableWithOrgId={null}
              fetchComments={(pageNumber, pageSize) => {
                return fetchContainerComments(
                  containerDetails?.containerId,
                  pageNumber,
                  pageSize,
                );
              }}
              isFetchingComments={isFetchingContainerComments}
              comments={containerComments}
              addComment={(data) => {
                return addContainerComments(
                  containerDetails?.containerId,
                  data,
                );
              }}
              cancelAddComment={cancelContainerAddComment}
              updateComment={(commentId, updatedData) => {
                return updateContainerComment(
                  containerDetails?.containerId,
                  commentId,
                  updatedData,
                );
              }}
              cancelUpdateComment={cancelContainerUpdateComment}
              markCommentsRead={(datetime) => {
                return markContainerCommentsRead(
                  containerDetails?.containerId,
                  datetime,
                );
              }}
              addBatchComments={(data, isCsvFormat) =>
                addContainerBatchComments(data, isCsvFormat, solutionId)
              }
              clearBatchComments={() => clearContainerBatchComments()}
              isBatchCommentInProgress={isContainerBatchCommentInProgress}
              isBatchCommentSuccessful={isContainerBatchCommentSuccessful}
              isBatchCommentFailed={isContainerBatchCommentFailed}
              batchCsvExample={batchCsvContainerExample}
              batchImportLabel={t("container-tracking:Container ID")}
              fetchNotification={fetchNotification}
              shipperOrgId={
                isShipper(organization) ? organization.organization_id : null
              }
            />
          ) : null}
          <Tabs>
            <Tabs.TabList>
              <Tabs.Tab
                style={{
                  width: "25%",
                }}
              >
                {t("container-tracking:Container History")}
              </Tabs.Tab>
              <Tabs.Tab
                style={{
                  width: "25%",
                }}
              >
                {t("container-tracking:Active Exceptions")}
              </Tabs.Tab>
              <Tabs.Tab
                style={{
                  width: "25%",
                }}
              >
                {t("container-tracking:Coordinates")}
              </Tabs.Tab>
            </Tabs.TabList>
            <Tabs.TabPanel
              style={{
                border: `1px solid ${Colors.tabs.BORDER}`,
              }}
            >
              {containerHistory && containerHistory.length > 0 ? (
                <ContainerTrackingHistoryTab
                  containerHistory={containerHistory}
                />
              ) : (
                <TabPanelPlaceholder
                  text={t("container-tracking:No Container History available")}
                />
              )}
            </Tabs.TabPanel>
            <Tabs.TabPanel
              style={{
                border: `1px solid ${Colors.tabs.BORDER}`,
              }}
            >
              {ctActiveExceptions && ctActiveExceptions.length > 0 ? (
                <ContainerTrackingExceptionsTab
                  ctActiveExceptions={ctActiveExceptions}
                />
              ) : (
                <TabPanelPlaceholder
                  text={t("container-tracking:No Active Exceptions")}
                />
              )}
            </Tabs.TabPanel>
            <Tabs.TabPanel
              style={{
                border: `1px solid ${Colors.tabs.BORDER}`,
              }}
            >
              {containerPositionUpdates.length > 0 ? (
                <CoordinatesTable
                  addCoordinate={addCoordinate}
                  selectedMapCoordinate={selectedMapCoordinate}
                  showCityColumn={false}
                  showStateColumn={false}
                  showCityStateColumn={true}
                  clearCoordinatesByType={() => {}}
                  coords={containerPositionUpdates}
                />
              ) : (
                <TabPanelPlaceholder
                  text={t("container-tracking:No Coordinates Available")}
                />
              )}
            </Tabs.TabPanel>
          </Tabs>
        </div>
      </Tabs.TabPanel>

      <ContainerTrackingLocationManagementModal
        updateBucketLocations={updateBucketLocations}
        isLocationDetailsLoading={isLocationDetailsLoading}
        locationDetails={locationDetails}
        fetchLocation={fetchLocation}
        fetchBucket={fetchBucket}
        bucketDetails={bucketDetails}
        fetchBucketDetailsFromName={fetchBucketDetailsFromName}
        isValidNewBucketName={isValidNewBucketName}
        selectedBucketLocations={selectedBucketLocations}
        isBucketListLoading={isBucketListLoading}
        isBucketDetailsLoading={isBucketDetailsLoading}
        isValidatingBucketName={isValidatingBucketName}
        isFetchingBucketLocations={isFetchingBucketLocations}
        updateBucketAndLocationData={updateBucketAndLocationData}
        locationChangesSavedStatus={locationChangesSavedStatus}
        failedLocations={failedLocations}
        resetFailedLocationsLists={resetFailedLocationsLists}
        resetSavedChanges={resetSavedChanges}
        id={id}
        show={showLocationManagementModal}
        resetBucketLoctions={resetBucketLoctions}
        isErrorOnSave={isErrorOnSave}
        errorMessageOnRouteName={errorMessageOnRouteName}
        resetError={resetError}
        resetUpdatedLocations={resetUpdatedLocations}
        updateLocations={updatedLocations}
        updateLocationsOnFail={updateLocationsOnFail}
        fetchCountries={fetchCountries}
        fetchStates={fetchStates}
        countryList={countryList}
        stateList={stateList}
        validationError={validationError}
        resetValidationError={resetValidationError}
        hide={() => {
          setShowLocationManagementModal(false);
        }}
        initialBucketName={initialBucketName}
        isFormSaving={isFormSaving}
      />
    </Tabs>
  );
  return (
    <RoutingMapContextProvider>
      <DetailWithMap
        mapChildren={
          !isContainerDetailsLoading &&
          containerDetails?.status === "In Transit" ? (
            <GeofenceBuilderMapContainer />
          ) : (
            getMapChildren(iscontainerLocationDetailsLoading)
          )
        }
        detailsChildren={
          <Loader loaded={!isContainerDetailsLoading}>{detailsChildren}</Loader>
        }
      ></DetailWithMap>
    </RoutingMapContextProvider>
  );
};

ContainerTrackingDetails.propTypes = {
  id: PropTypes.string,
  organization: PropTypes.object,
  shipmentModes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    }),
  ),
  isShipmentSubscriptionLoading: PropTypes.bool,
  subscribe: PropTypes.func.isRequired,
  unsubscribe: PropTypes.func.isRequired,
  updateSubscription: PropTypes.func.isRequired,
  shipmentSubscription: PropTypes.shape({
    email: PropTypes.string,
    enable_email: PropTypes.bool,
    enable_platform: PropTypes.bool,
    enable_sms: PropTypes.bool,
    id: PropTypes.any,
    mobile_number: PropTypes.any,
    owner_id: PropTypes.any,
    reference_id: PropTypes.string,
    source_service: PropTypes.string,
    timezone: PropTypes.string,
    type: PropTypes.string,
  }),
  subscriptionRequestError: PropTypes.bool,
  isSubscriptionUpdating: PropTypes.bool,
  subscriptionUpdateSuccess: PropTypes.bool,
  subscriptionUpdateError: PropTypes.bool,
  editShipmentData: PropTypes.shape({
    data: PropTypes.any,
    isLoading: PropTypes.bool,
    isLoadingError: PropTypes.bool,
    loadingError: PropTypes.string,
    status: PropTypes.number,
    statusArray: PropTypes.array,
  }),
  fetchShipmentSubscription: PropTypes.func.isRequired,
  setWatchShipment: PropTypes.func.isRequired,
  updateReviewStatus: PropTypes.func.isRequired,
  assignAsset: PropTypes.func.isRequired,
  assignTrailer: PropTypes.func.isRequired,
  shipment: PropTypes.object,
  containerDetails: PropTypes.object,
  isContainerDetailsLoading: PropTypes.bool,
  isShipmentDetailsLoading: PropTypes.bool,
  areBothDetailsLoading: PropTypes.bool,
  containerMedia: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  containerPositionUpdates: PropTypes.array,
  fetchContainerDetails: PropTypes.func,
  fetchShipmentDetails: PropTypes.func,
  fetchContainerActiveExceptions: PropTypes.func,
  fetchContainerMedia: PropTypes.func,
  clearContainerMedia: PropTypes.func,
  addCoordinate: PropTypes.func,
  clearCoordinatesByType: PropTypes.func,
  selectedMapCoordinate: PropTypes.object,
  fetchContainerHistory: PropTypes.func,
  containerHistory: PropTypes.array,
  createShipmentEvents: PropTypes.func.isRequired,
  clearReportShipmentEvents: PropTypes.func,
  updateShipment: PropTypes.func,
  cancelShipment: PropTypes.func.isRequired,
  startMobileTracking: PropTypes.func.isRequired,
  stopMobileTracking: PropTypes.func.isRequired,
  reportDelayData: PropTypes.shape({
    data: PropTypes.any,
    isLoading: PropTypes.bool,
    isLoadingError: PropTypes.bool,
    loadingError: PropTypes.object,
    status: PropTypes.number,
  }),
  clearDelay: PropTypes.func.isRequired,
  clearReportDelayInState: PropTypes.func.isRequired,
  clearDelayData: PropTypes.shape({
    data: PropTypes.any,
    isLoading: PropTypes.bool,
    isLoadingError: PropTypes.bool,
    loadingError: PropTypes.object,
  }),
  reportDelay: PropTypes.func.isRequired,
  fetchDelayOptions: PropTypes.func,
  delayOptionsData: PropTypes.shape({
    data: PropTypes.any,
    isLoading: PropTypes.bool,
    isLoadingError: PropTypes.bool,
    loadingError: PropTypes.object,
  }),
  setShipmentWithUnresolvedLocation: PropTypes.func.isRequired,
  pushLocationMatchingView: PropTypes.func.isRequired,
  ladsList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  childShipments: PropTypes.object.isRequired,
  routeHeatmap: PropTypes.array,
  // Error redirects
  redirectTo404: PropTypes.func.isRequired,
  detailsError: PropTypes.object,
};
