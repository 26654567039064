import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { customerApiUrl } from "api-url";
import _ from "lodash";

const fetchParts = async (
  locationId,
  searchText,
  pageNumber,
  pageSize,
  sortColumn,
  reverseSort,
  filterParams,
) => {
  if (!locationId) {
    return { parts: [], meta: { totalCount: 0, totalPages: 0 } };
  }
  const getFilterValues = (filterData) => {
    return filterData
      .map((data) => {
        return data.value;
      })
      .join(",");
  };
  const searchParams = new URLSearchParams();
  searchParams.append("searchText", searchText.toString());
  searchParams.append("pageNumber", pageNumber.toString());
  searchParams.append("pageSize", pageSize.toString());
  searchParams.append("sortColumn", sortColumn.toString());
  searchParams.append("sortOrder", reverseSort ? "desc" : "asc");
  Object.keys(filterParams)?.map((key) => {
    if (filterParams[key]) {
      if (_.isArray(filterParams[key])) {
        searchParams.append(key, getFilterValues(filterParams[key]));
      } else {
        searchParams.append(key, filterParams[key]);
      }
    }
  });

  const url = customerApiUrl(`critical-parts/location-inventory/${locationId}`);

  try {
    const response = await axios.get(url, { params: searchParams });
    const data = response.data;

    if (!data.data || _.isArray(data.data.parts) || data.data.length === 0) {
      return { parts: [], meta: { totalCount: 0, totalPages: 0 } };
    }

    return data;
  } catch (error) {
    return { parts: [], meta: { totalCount: 0, totalPages: 0 } };
  }
};

export const useCriticalPartsListData = (
  locationId,
  searchText,
  pageNumber,
  pageSize,
  sortColumn,
  reverseSort,
  filterParams,
) => {
  const {
    isFetching: isFetchingList,
    data: criticalPartsList,
    refetch,
  } = useQuery({
    queryKey: [
      locationId,
      searchText,
      pageNumber,
      pageSize,
      sortColumn,
      reverseSort,
      filterParams,
      "Critical Parts List",
    ],
    enabled: true,
    queryFn: () =>
      fetchParts(
        locationId,
        searchText,
        pageNumber,
        pageSize,
        sortColumn,
        reverseSort,
        filterParams,
      ),
  });
  return { isFetchingList, criticalPartsList, refetch };
};
