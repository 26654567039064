//External Packages
import _ from "lodash";
import moment from "moment";
import axios from "axios";

import apiUrl from "api-url";
import chainReducers from "vendor/signal-utils/chain-reducers";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";
import { SEARCH_CATEGORIES } from "pages/shipments/components/search/Shipments.searchOptions";
import { FILTERS } from "pages/shipments/components/search/Shipments.searchOptions";
import {
  searchLocations,
  locationsReducer,
} from "pages/administration/location-management/locations/search/LocationSearchStateExtensions";
import { searchVins, vinsReducer } from "./VinSearchStateExtensions";

// Helpers

const STORE_MOUNT_POINT = "search";

// NOTE: solutionId parameter is here to follow pattern required by
// exportEntities
const shipmentsUrl = (solutionId, queryString) => {
  return apiUrl(`/shipping-ng/shipments?${queryString}`);
};

// NOTE: solutionId parameter is here to follow pattern required by
// exportEntities
const batchSearchUrl = (solutionId, queryString, batchType) => {
  return apiUrl(
    `/shipping-ng/batch_shipments?${queryString}&batchType=${batchType}`,
  );
};

const axiosConfig = () => {
  return {
    headers: {
      "x-time-zone": moment.tz.guess(),
    },
  };
};

// Actions

const fetchSearch = (
  queryString = "",
  solutionId,
  duck,
  dispatch,
  state,
  preventRedirect,
) => {
  const batchFilter = state[STORE_MOUNT_POINT].searchFilters.batch;

  if (batchFilter) {
    // Batch search POST
    batchSearch(queryString, dispatch, batchFilter, preventRedirect);
  } else {
    // Normal search GET
    const url = shipmentsUrl(null, queryString);
    const config = axiosConfig();
    dispatch(duck.fetch(url, config));
    if (!preventRedirect) {
      dispatch({ type: "SHIPMENT_SEARCH" });
    }
  }
};

const batchSearch = (queryString, dispatch, batchFilter, preventRedirect) => {
  const url = batchSearchUrl(null, queryString, batchFilter.batch_type);
  const data = {
    batch_list: batchFilter.batch_list,
  };
  const config = axiosConfig();

  dispatch({
    type: `Search/REQUEST_SEARCH`,
  });

  return Promise.all([axios.post(url, data, config)])
    .then((responses) => {
      dispatch({
        type: `Search/RECEIVE_SEARCH`,
        payload: responses ? responses[0].data : [],
      });
      if (!preventRedirect) {
        dispatch({ type: "SHIPMENT_SEARCH" });
      }
    })
    .catch((err) => {
      dispatch({
        type: `Search/REQUEST_SEARCH_ERROR`,
        err,
      });
    });
};

// Additional selectors for async search results
const getLocations = (state) => state[STORE_MOUNT_POINT].locations || [];
const getLocationsLoading = (state) =>
  state[STORE_MOUNT_POINT].locationsLoading || false;
const getVins = (state) => state[STORE_MOUNT_POINT].vins || [];
const getVinsLoading = (state) => state[STORE_MOUNT_POINT].vinsLoading || false;

// Override the exported interface to expose our replacements
const ShipmentsSearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  FILTERS,
  fetchSearch,
  [],
  {
    defaultSort: "destination_earliest_arrival",
  },
);

ShipmentsSearchBarState.actionCreators = {
  ...ShipmentsSearchBarState.actionCreators,
  exportSearch: _.partial(
    ShipmentsSearchBarState.actionCreators.exportEntities,
    shipmentsUrl,
    batchSearchUrl,
    { headers: { Accept: "text/csv;version=basic" } },
    "shipment-search-results",
  ),
  searchLocations,
  searchVins,
};

ShipmentsSearchBarState.selectors = {
  ...ShipmentsSearchBarState.selectors,
  getLocations,
  getLocationsLoading,
  getVins,
  getVinsLoading,
};

ShipmentsSearchBarState.reducer = chainReducers([
  ShipmentsSearchBarState.reducer,
  locationsReducer,
  vinsReducer,
]);

export default ShipmentsSearchBarState;
