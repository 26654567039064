/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import { SupportedLanguages } from "utils/language-utils";
import { useState } from "react";
import Colors from "styles/colors";
import { Text } from "components/atoms/Text.atom";
import { Button } from "components/atoms/Button.atom";
import SelectField from "components-old/forms/fields/SelectField";

const SelectFieldInput = ({ value, onChange, options }) => {
  return (
    <SelectField
      borderWidth="2px"
      indicatorContainerHeight={40}
      options={options}
      stateValue={value}
      onChange={onChange}
      css={{ paddingTop: "0.25rem" }}
    />
  );
};

export function UserPreferenceForm({ userPreferences, setPreferences }) {
  const { t } = useTranslation("profile");
  const languagePreferenceOptions = Object.keys(SupportedLanguages).map(
    (name) => {
      return { label: name, value: SupportedLanguages[name] };
    },
  );

  const timePreferenceOptions = [
    { value: "TWELVE_HOUR_AM_PM", label: "12 Hour" },
    { value: "TWENTY_FOUR_HOUR", label: "24 Hour" },
  ];

  const datePreferenceOptions = [
    { value: "DAY_MONTH_YEAR", label: "DD/MM/YYYY" },
    { value: "MONTH_DAY_YEAR", label: "MM/DD/YYYY" },
    { value: "YEAR_MONTH_DAY", label: "YYYY/MM/DD" },
  ];

  const [languagePreference, setLanguagePreference] = useState(
    userPreferences["locale"],
  );
  const [dateFormatPreference, setDateFormatPreference] = useState(
    userPreferences["dateformat"],
  );
  const [timeFormatPreference, setTimeFormatPreference] = useState(
    userPreferences["timeformat"],
  );

  const submitPreferences = () => {
    setPreferences({
      locale: languagePreference,
      dateformat: dateFormatPreference,
      timeformat: timeFormatPreference,
      timezone: userPreferences.timezone,
      criticalPartsPreference: userPreferences.criticalPartsPreference,
    });
  };
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: Colors.background.LIGHT_GRAY,
        border: "1px solid white",
        borderRadius: 8,
        padding: "1.25em",
        gap: "1em",
      }}
    >
      <span>
        <Text css={{ marginBottom: ".25em" }}>
          {t("profile:Language Preference")}
        </Text>
        <SelectFieldInput
          value={languagePreference}
          onChange={(selectedOption) => {
            setLanguagePreference(selectedOption.value);
          }}
          options={languagePreferenceOptions}
        />
      </span>
      <span>
        <Text css={{ marginBottom: ".25em" }}>
          {t("profile:Date Format Preference")}
        </Text>
        <SelectFieldInput
          value={dateFormatPreference}
          onChange={(selectedOption) => {
            setDateFormatPreference(selectedOption.value);
          }}
          options={datePreferenceOptions}
        />
      </span>
      <span>
        <Text css={{ marginBottom: ".25em" }}>
          {t("profile:Time Format Preference")}
        </Text>
        <SelectFieldInput
          value={timeFormatPreference}
          onChange={(selectedOption) => {
            setTimeFormatPreference(selectedOption.value);
          }}
          options={timePreferenceOptions}
        />
      </span>
      <Button
        variant="primary"
        onClick={submitPreferences}
        css={{ marginTop: "auto", alignSelf: "end" }}
      >
        {t("profile:Save Preferences")}
      </Button>
    </div>
  );
}
