/** @jsxImportSource @emotion/react */
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { FaCog } from "react-icons/fa";
import PropTypes from "prop-types";

import { isCarrier } from "shared/utils/organizations.utils";
import { Tabs } from "components/molecules/Tabs.molecule";
import LocationsViewContainer from "pages/administration/location-management/locations/LocationManagement.Locations.page.container";
import EditLadsContainer from "pages/administration/location-management/lads/LocationManagement.Lads.page.container";
import LocationResolutionList from "pages/administration/location-management/unresolved-locations/LocationManagement.UnresolvedLocations.page.container";
import { Privileges } from "modules/auth/Authorization";
import { Icon, IconType } from "components/atoms/Icon.atom";
import { LOCATION_MANAGEMENT_TAB } from "./utils/const";

import {
  useSetTitleOnMount,
  useSetDescriptionOnMount,
} from "components/hooks/useSetTitle";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";

export const LocationManagementView = ({
  authorization,
  activeOrganization,
  selectedIndex,
}) => {
  const { t } = useTranslation("locations");

  useSetTitleOnMount(t("locations:Location Management"));
  useSetDescriptionOnMount(
    t("locations:Create, Update, and Delete Locations for an organization"),
  );

  let mixPanelTitle = null;
  switch (selectedIndex) {
    case LOCATION_MANAGEMENT_TAB.LOCATION_LADS: {
      mixPanelTitle = "LADs";
      break;
    }
    case LOCATION_MANAGEMENT_TAB.LOCATION_UNRESOLVED: {
      mixPanelTitle = "Unresolved Locations";
      break;
    }
    case LOCATION_MANAGEMENT_TAB.LOCATION_MANAGEMENT:
    default: {
      mixPanelTitle = "Locations";
    }
  }

  useTrackWithMixpanelOnce(
    `Viewed Page: Location Management / ${mixPanelTitle}`,
  );

  const dispatch = useDispatch();

  const canUserManageLocations =
    authorization.hasPrivileges([Privileges.MANAGE_SHIPPER_LOCATIONS]) &&
    !isCarrier(activeOrganization);

  // display just locations view if user cannot manage locations
  if (!canUserManageLocations) {
    return (
      <div className="p-3">
        <LocationsViewContainer
          canUserManageLocations={canUserManageLocations}
        />
      </div>
    );
  }
  const onSelect = (index) => {
    if (index === LOCATION_MANAGEMENT_TAB.LOCATION_MANAGEMENT) {
      dispatch({ type: "LOCATION_MANAGEMENT" });
    }
    if (index === LOCATION_MANAGEMENT_TAB.LOCATION_LADS) {
      dispatch({ type: "LOCATION_LADS" });
    }
    if (index === LOCATION_MANAGEMENT_TAB.LOCATION_UNRESOLVED) {
      dispatch({ type: "LOCATION_UNRESOLVED" });
    }
  };

  return (
    <Tabs
      fullWidthTabs
      selectedIndex={selectedIndex}
      onSelect={onSelect}
      style={{ marginLeft: "1em", marginRight: "1em" }}
    >
      <Tabs.TabList>
        <Tabs.Tab>{t("locations:Locations")}</Tabs.Tab>
        <Tabs.Tab>
          <Icon
            type={IconType.ReactIcons}
            src={FaCog}
            className="me-1 me-lg-1"
          />
          {t("locations:Edit LAD Labels")}
        </Tabs.Tab>
        <Tabs.Tab>{t("locations:Unresolved Locations")}</Tabs.Tab>
      </Tabs.TabList>
      <Tabs.TabPanel>
        <LocationsViewContainer
          canUserManageLocations={canUserManageLocations}
        />
      </Tabs.TabPanel>
      <Tabs.TabPanel>
        <EditLadsContainer />
      </Tabs.TabPanel>
      <Tabs.TabPanel>
        <LocationResolutionList />
      </Tabs.TabPanel>
    </Tabs>
  );
};

LocationManagementView.propTypes = {
  activeOrganization: PropTypes.any.isRequired,
  authorization: PropTypes.object.isRequired,
  selectedIndex: PropTypes.number.isRequired,
};
