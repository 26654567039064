/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import FormRow from "components-old/forms/FormRow";
import GeofenceEditForm from "modules/geofence-edit/GeofenceEditForm";
import { useGetShipperNameById } from "pages/administration/utils/location-utils";
import StandardInput from "components-old/forms/inputs/StandardInput";
import LADSelectInput from "components-old/forms/inputs/LADSelectInput";
import SelectInput from "components-old/forms/inputs/SelectInput";
import { useLadsTranslation } from "shared/utils/lads.utils";
import { FontSize, Text } from "components/atoms/Text.atom";

const LocationEditForm = ({
  data,
  lads,
  updateField,
  updateGeofence,
  isReadOnly,
  isTracing,
  editingPolygonIndex,
  addPolygon,
  deletePolygon,
  cancelTracing,
  showUpdateGeofenceTypeModal,
  countries,
  subdivisions,
  isCarrierOrg,
}) => {
  const { t } = useTranslation("location-edit");
  const { getTranslatedLadLobLabel } = useLadsTranslation();

  const countryOptions = countries
    ? countries.map((c) => {
        return { value: c.code, label: c.name };
      })
    : [];

  const subdivisionOptions = subdivisions
    ? subdivisions.map((s) => {
        // H1-1020: Use full subdivision name as value if code is numeric
        return { value: isNaN(s.code) ? s.code : s.name, label: s.name };
      })
    : [];

  const shipperName = useGetShipperNameById(data.customer_id);

  let ladOptions;
  if (Array.isArray(lads)) {
    ladOptions = lads.map((l) => {
      return { value: l.id, label: getTranslatedLadLobLabel(l) };
    });
  } else {
    const keys = Object.keys(lads);
    for (let keyIndex = 0; keyIndex < keys.length; keyIndex++) {
      ladOptions = lads[keys[keyIndex]].map((l) => {
        return { value: l.org_lad_id, label: getTranslatedLadLobLabel(l) };
      });
    }
  }

  // Skip LAD lookup for Carriers (LADs data will not match the dereferenced location LAD ID)
  if (isCarrierOrg && data?.lad) {
    const l = data.lad;
    ladOptions = [
      {
        ...l,
        value: l.id,
        label: getTranslatedLadLobLabel(l),
      },
    ];
  }

  return (
    <div
      css={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        padding: "1em",
        backgroundColor: "white",
      }}
    >
      {isReadOnly ? (
        <div css={{ display: "flex", alignItems: "center" }}>
          {isCarrierOrg && (
            <Text size={FontSize.size18} data-qa="location-shipper">
              {shipperName}
            </Text>
          )}
        </div>
      ) : null}
      <FormRow>
        <StandardInput
          label={t("location-edit:Location Name")}
          value={data.name}
          onChange={(value) => updateField("name", value)}
          isReadOnly={isReadOnly}
        />
      </FormRow>
      <FormRow divider={true}>
        <LADSelectInput
          label={t("location-edit:Location Type")}
          placeholder={t("location-edit:Select...")}
          options={ladOptions}
          value={data.lad ? Number(data.lad.id) : null}
          onChange={(value) => {
            updateField("lad", value);
          }}
          lads={isCarrierOrg ? ladOptions : lads}
          isReadOnly={isReadOnly}
          selectedLad={data.lad}
        />
        <StandardInput
          label={t("location-edit:Location Code")}
          value={data.code}
          onChange={(value) => updateField("code", value)}
          isReadOnly={isReadOnly}
        />
      </FormRow>
      <FormRow>
        <StandardInput
          label={t("location-edit:Address 1")}
          value={data.address}
          onChange={(value) => updateField("address", value)}
          isReadOnly={isReadOnly}
        />
        <StandardInput
          label={t("location-edit:Address 2")}
          value={data.address2}
          onChange={(value) => updateField("address2", value)}
          isReadOnly={isReadOnly}
        />
      </FormRow>
      <FormRow>
        <StandardInput
          label={t("location-edit:City")}
          value={data.city}
          onChange={(value) => updateField("city", value)}
          isReadOnly={isReadOnly}
        />
        <SelectInput
          label={subdivisions?.[0]?.type ?? ""}
          options={subdivisionOptions}
          value={data.state}
          onChange={(value) => updateField("state", value)}
          isReadOnly={isReadOnly}
          minWidth="12em"
        />
        <StandardInput
          label={t("location-edit:Postal Code")}
          value={data.postal_code}
          onChange={(value) => updateField("postal_code", value)}
          isReadOnly={isReadOnly}
        />
      </FormRow>
      <FormRow divider={true}>
        <SelectInput
          label={t("location-edit:Country")}
          options={countryOptions}
          value={data.country}
          onChange={(value) => updateField("country", value)}
          isReadOnly={isReadOnly}
        />
      </FormRow>
      {data.geofence && (
        <FormRow>
          <GeofenceEditForm
            isTracing={isTracing}
            editingPolygonIndex={editingPolygonIndex}
            data={data}
            updateGeofence={updateGeofence}
            addPolygon={addPolygon}
            cancelTracing={cancelTracing}
            deletePolygon={deletePolygon}
            showUpdateGeofenceTypeModal={showUpdateGeofenceTypeModal}
            isReadOnly={isReadOnly}
          />
        </FormRow>
      )}
    </div>
  );
};

LocationEditForm.propTypes = {
  addPolygon: PropTypes.func,
  cancelTracing: PropTypes.func,
  countries: PropTypes.any,
  data: PropTypes.any,
  deletePolygon: PropTypes.func,
  editingPolygonIndex: PropTypes.any,
  isCarrierOrg: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isTracing: PropTypes.bool,
  lads: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  showUpdateGeofenceTypeModal: PropTypes.any,
  subdivisions: PropTypes.any,
  updateField: PropTypes.any,
  updateGeofence: PropTypes.any,
};

export default LocationEditForm;
