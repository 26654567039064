import { connect } from "react-redux";
import FinVehicleSearch from "./FinishedVehicle.Search.page";
import FinVehicleSearchBarState from "pages/finishedvehicle/redux/FinVehicleSearchBarState";
import FinVehicleSavedSearchState from "pages/finishedvehicle/redux/FinVehicleSavedSearchState";
import { getSolutionId } from "modules/organizations/OrganizationsState";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import { Features } from "modules/auth/Authorization";

function mapStateToProps(state) {
  const auth = getAuthorization(state);

  return {
    savedSearch:
      FinVehicleSearchBarState.selectors.getSelectedSavedSearch(state),
    vinSearchResults:
      FinVehicleSearchBarState.selectors.getSearchResults(state),
    showFilters:
      FinVehicleSearchBarState.selectors.getShowAdvancedSearch(state),
    isLoading: FinVehicleSearchBarState.selectors.getIsLoading(state),
    sortColumn: FinVehicleSearchBarState.selectors.getSortColumn(state),
    reverseSort: FinVehicleSearchBarState.selectors.getReverseSort(state),
    page: FinVehicleSearchBarState.selectors.getPage(state),
    pageSize: FinVehicleSearchBarState.selectors.getPageSize(state),
    totalPages:
      FinVehicleSearchBarState.selectors.getTotalPageCountForSearch(state),
    totalEntities:
      FinVehicleSearchBarState.selectors.getTotalCountForSearch(state),
    totalEntitiesIsLoading:
      FinVehicleSearchBarState.selectors.getTotalCountForSearchIsLoading(state),
    solutionId: getSolutionId(state),
    exportIdentifier:
      FinVehicleSearchBarState.selectors.getExportIdentifier(state),
    exportName: FinVehicleSearchBarState.selectors.getExportName(state),
    isExporting: FinVehicleSearchBarState.selectors.getIsExporting(state),
    exportFailed: FinVehicleSearchBarState.selectors.getExportFailed(state),
    showItssData: auth.hasFeatures([Features.ITSS]),
    showSpotBuyData: auth.hasFeatures([Features.SPOT_BUY]),
    showScheduleWindow: auth.hasFeatures([
      Features.FINISHED_VEHICLE_SCHEDULED_WINDOW,
    ]),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadSavedSearch: (savedSearch) =>
      dispatch(
        FinVehicleSavedSearchState.actionCreators.loadSavedSearch(savedSearch),
      ),
    pushVinDetailsView: (entityId) =>
      dispatch({ type: "VIN_DETAILS", payload: { entity_id: entityId } }),
    toggleShowFilters: (showFilters) =>
      dispatch(
        FinVehicleSearchBarState.actionCreators.toggleShowFilters(showFilters),
      ),
    setPagination: (solutionId, page, pageSize) =>
      dispatch(
        FinVehicleSearchBarState.actionCreators.setPagination(
          solutionId,
          page,
          pageSize,
        ),
      ),
    setSort: (solutionId, sortColumn, reverse) =>
      dispatch(
        FinVehicleSearchBarState.actionCreators.setSort(
          solutionId,
          sortColumn,
          reverse,
        ),
      ),
    exportEntities: (solutionId) => {
      dispatch(
        FinVehicleSearchBarState.actionCreators.exportSearch(solutionId),
      );
    },
    clearExportErrors: () => {
      dispatch(FinVehicleSearchBarState.actionCreators.clearExportErrors());
    },
    resetSearch: (solutionId) => {
      dispatch(FinVehicleSearchBarState.actionCreators.resetSearchAndFilters());
      dispatch(
        FinVehicleSearchBarState.actionCreators.searchEntities(solutionId),
      );
    },
    resetExport: () => {
      dispatch(FinVehicleSearchBarState.actionCreators.resetExport());
    },
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    exportEntities: () => dispatchProps.exportEntities(stateProps.solutionId),
    resetSearch: () => dispatchProps.resetSearch(stateProps.solutionId),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(FinVehicleSearch);
