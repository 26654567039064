import moment from "moment";

import apiUrl from "api-url";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import chainReducers from "vendor/signal-utils/chain-reducers";
import { PackageStatusOption } from "pages/partview/utils/filter.utils";
import { packageDetailsWithFilteredExceptions } from "pages/partview/utils/exceptions.utils";
const STORE_MOUNT_POINT = "partViewDashboard";

//Ducks
const exceptionsListFetchDuck = buildFetchDuck(
  STORE_MOUNT_POINT,
  "exceptionsList",
  {},
);
const exceptionCountsFetchDuck = buildFetchDuck(
  STORE_MOUNT_POINT,
  "exceptionCounts",
  {},
);
const activeAndExceptionCountsFetchDuck = buildFetchDuck(
  STORE_MOUNT_POINT,
  "activeExceptionAndPackageStatusCounts",
  {},
);
const watchedTableDuck = buildFetchDuck(STORE_MOUNT_POINT, "watchedTable", {});

const etaPackagesCountsFetchDuck = buildFetchDuck(
  STORE_MOUNT_POINT,
  "etaPackagesCounts",
  {},
);

// Actions
const getScopedActionName = (actionName) =>
  `${STORE_MOUNT_POINT}/${actionName}`;
const SET_WATCHED_TABLE_PAGE_INDEX = getScopedActionName(
  "SET_WATCHED_TABLE_PAGE_INDEX",
);

// Action Creators
function fetchExceptionList() {
  return (dispatch) => {
    const url = apiUrl("/partview/app/list/exception");
    return dispatch(exceptionsListFetchDuck.fetch(url));
  };
}

function fetchExceptionOrPackageStatusCounts(
  fvOrgId = null,
  isDealerOrg = false,
  includeAPU,
) {
  let version;

  if (isDealerOrg || fvOrgId) {
    version = "PARTSELLER_COUNT";
  } else {
    version = "COUNT";
  }

  // Default lifecycleStates for shipper and dealer for organizations that doesn't required Available For Pickup(APU).
  const lifecycleOptionsParamList = [
    PackageStatusOption.CREATED_OR_PACKAGED.value,
    PackageStatusOption.IN_ROUTE.value,
    PackageStatusOption.DELAYED.value,
    PackageStatusOption.DELIVERED.value,
  ];

  // Include Available For Pickup(APU) to lifecycleState for organizations that requires.
  if (includeAPU) {
    lifecycleOptionsParamList.push(
      PackageStatusOption.AVAILABLE_FOR_PICKUP.value,
    );
  }

  return (dispatch) => {
    const url = apiUrl("/partview/app/search");
    const config = {
      headers: {
        Accept: `application/json;version=${version}`,
      },
      params: {
        fvOrgId,
        status: "active",
        lifecycleState: lifecycleOptionsParamList.join(","), // To format lifecycleState qsp value into "Created/Packaged,In Route,Delayed,Available for Pickup"
      },
    };
    return dispatch(exceptionCountsFetchDuck.fetch(url, config));
  };
}

// only for dealer dashabord
function fetchActiveAndExceptionCounts(fvOrgId = null, includeAPU) {
  // Default lifecycleStates for shipper and dealer for organizations that doesn't required Available For Pickup(APU).
  const lifecycleOptionsParamList = [
    PackageStatusOption.CREATED_OR_PACKAGED.value,
    PackageStatusOption.IN_ROUTE.value,
    PackageStatusOption.DELAYED.value,
    PackageStatusOption.DELIVERED.value,
  ];

  // Include Available For Pickup(APU) to lifecycleState for organizations that requires.
  if (includeAPU) {
    lifecycleOptionsParamList.push(
      PackageStatusOption.AVAILABLE_FOR_PICKUP.value,
    );
  }

  return (dispatch) => {
    const url = apiUrl("/partview/app/search");
    const config = {
      headers: {
        Accept: `application/json;version=COUNT`,
      },
      params: {
        fvOrgId,
        status: "active",
        lifecycleState: lifecycleOptionsParamList.join(","), // To format lifecycleState qsp value into "Created/Packaged,In Route,Delayed,Available for Pickup"
      },
    };
    return dispatch(activeAndExceptionCountsFetchDuck.fetch(url, config));
  };
}

//only for dealer dashboard
function fetchEtaPackagesCounts(fvOrgId) {
  return (dispatch) => {
    const url = apiUrl("/partview/app/eta-count/packages");
    //added config with header and params, added x-time-zone in the header
    const config = {
      headers: { "x-time-zone": moment.tz.guess() },
      params: { fvOrgId: fvOrgId },
    };
    return dispatch(etaPackagesCountsFetchDuck.fetch(url, config));
  };
}

function fetchWatchedPackages(hasDealerView = false, fvOrgId = null) {
  return (dispatch, getState) => {
    const state = getState();
    const url = apiUrl("/partview/app/search");
    const params = {
      watched: true,
      fvOrgId: fvOrgId,
      pageNumber: getWatchedTablePageIndex(state),
      pageSize: getWatchedTablePageSize(state),
      status: "active",
    };

    const transform = (data) => {
      let packageDetails = data?.data;
      if (hasDealerView) {
        packageDetails = data?.data?.map((result) => {
          return packageDetailsWithFilteredExceptions(result);
        });
      }

      return { ...data, data: packageDetails };
    };

    dispatch(watchedTableDuck.fetch(url, { params }, transform));
  };
}

function setWatchedTablePageIndex(index) {
  return { type: SET_WATCHED_TABLE_PAGE_INDEX, payload: { index } };
}

// Selectors
const getWatchedTableIsLoading = (state) =>
  watchedTableDuck.selectors.getData(state)?.isLoading ?? false;
const getWatchedTableResults = (state) =>
  watchedTableDuck.selectors.getData(state)?.data?.data ?? [];

const getWatchedTablePageIndex = (state) =>
  state[STORE_MOUNT_POINT].watchedTablePageIndex ?? 0;
const getWatchedTablePageSize = (state) => 10;
const getWatchedTablePageCount = (state) =>
  watchedTableDuck.selectors.getData(state)?.data?.meta?.totalPages ?? 0;

// Reducer
const partViewDashboardReducer = (
  state = {
    watchedTablePageIndex: 0,
    etaCounts: { packages: {}, orders: {}, parts: {} },
  },
  action,
) => {
  switch (action.type) {
    case SET_WATCHED_TABLE_PAGE_INDEX:
      return { ...state, watchedTablePageIndex: action.payload.index };
    default:
      return state;
  }
};

const PartViewDashboardState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchExceptionList,
    fetchExceptionOrPackageStatusCounts,
    fetchWatchedPackages,
    setWatchedTablePageIndex,
    fetchEtaPackagesCounts,
    fetchActiveAndExceptionCounts,
  },
  selectors: {
    getExceptionsListRequestData: exceptionsListFetchDuck.selectors.getData,
    getExceptionCountsRequestData: exceptionCountsFetchDuck.selectors.getData,
    getActiveAndExceptionCountsRequestData:
      activeAndExceptionCountsFetchDuck.selectors.getData,
    getWatchedTableIsLoading,
    getWatchedTableResults,
    getWatchedTablePageIndex,
    getWatchedTablePageSize,
    getWatchedTablePageCount,
    getEtaPackagesCountsRequestData:
      etaPackagesCountsFetchDuck.selectors.getData,
  },
  reducer: chainReducers([
    exceptionsListFetchDuck.reducer,
    exceptionCountsFetchDuck.reducer,
    activeAndExceptionCountsFetchDuck.reducer,
    watchedTableDuck.reducer,
    partViewDashboardReducer,
    etaPackagesCountsFetchDuck.reducer,
  ]),
};

export default PartViewDashboardState;
