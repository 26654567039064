/** @jsxImportSource @emotion/react */
import React, { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import { Button, FormGroup } from "react-bootstrap";
import ImageUploader from "react-images-upload";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import Loader from "react-loader";

import {
  fetchOrganizationDetails,
  updateOrganization,
  clearActionStatus,
  getOrganizationDetailsRequestData,
  getOrganizationTypes,
  fetchExternalCodeDefinition,
  getExternalCodeDefinitions,
  getExternalCodeDefinitionsLoading,
  clearExternalCodeDefinition,
  getBrandingOptions,
  getBrandingOptionsLoading,
  fetchBrandingOptions,
} from "../OrganizationsState";
import {
  fetchOrgLocations,
  getIsLoadingOrgLocations,
  getOrgLocations,
} from "pages/administration/location-management/redux/Locations.state";
import { Modal } from "components/molecules/Modal.molecule";
import { SelectInput, TextInput } from "components-old/modal-elems";
import Colors from "styles/colors";
import { FlexRowDiv, FlexColDiv } from "styles/container-elements";
import { isValidEmail } from "utils/validation-utils";
import { LocationDesignationSelect } from "./LocationDesignationSelect";
import { buildSearchLocationOptions } from "pages/administration/location-management/locations/search/LocationSearchStateExtensions";
import { useOrganizationsTranslation } from "shared/utils/organizations.utils";
import { ExternalCodeField } from "./ExternalCodeField";
import { BrandingOptionSelect } from "./BrandingOptionSelect";
import { ShipperOrganizationsAsyncSelect } from "./ShipperOrganizationsAsyncSelect";
import { useOrganizations } from "shared/hooks/useOrganizations";
import OrganizationsSearchBarState from "../OrganizationsSearchBarState";
import { useGrantedFeaturesOptions } from "./utils/organizations.utils";
import { GrantedFeaturesSelect } from "./GrantedFeaturesSelect";

const ValidationRow = ({ isValid, description }) => {
  return (
    <FlexRowDiv style={{ marginBottom: ".25em", width: "100%" }}>
      <FontAwesomeIcon
        style={{
          color: isValid
            ? Colors.highlight.GREEN
            : Colors.highlight.MEDIUM_LIGHT_GRAY,
          fontSize: "large",
          marginRight: ".5em",
          marginTop: ".1em",
        }}
        icon={faCheckCircle}
      />
      <span css={{ fontSize: "small" }}>{description}</span>
    </FlexRowDiv>
  );
};

ValidationRow.propTypes = {
  description: PropTypes.string.isRequired,
  isValid: PropTypes.bool,
};

const initOrg = {
  id: null,
  name: "",
  selectedType: null,
  email: "",
  freightVerifyId: "",
  contactName: "",
  phoneNumber: "",
  scac: "",
  base64Image: null,
  shipper: null,
  locations: [],
  externalCodes: {},
  branding: null,
  features: null,
};

const EditOrganizationModal = ({
  show,
  hide,
  organizationId,
  fetchOrganizationDetails,
  organizationDetailsRequestData,
  organizationTypes,
  fetchOrgLocations,
  orgLocations,
  isLoadingOrgLocations,
  updateOrganization,
  actionStatus,
  clearActionStatus,
  fetchBrandingOptions,
  brandingOptions,
  brandingOptionsLoading,
  fetchExternalCodeDefinition,
  externalCodeDefinition,
  externalCodeDefinitionLoading,
  clearExternalCodeDefinition,
  searchOrganizations,
}) => {
  const { t } = useTranslation(["organizations"]);
  const { getTranslatedOrganizationType } = useOrganizationsTranslation();

  const [org, setOrg] = useState(initOrg);
  const [shipperID, setShipperID] = useState(null);
  const [isGrantedFeaturesDisable, setIsGrantedFeaturesDisable] =
    useState(true);

  const isCarrier = org.selectedType?.label === t("organizations:Carrier");
  const isPartner = org.selectedType?.label === t("organizations:Partner");
  const isDealer = org.selectedType?.label === t("organizations:Dealer");

  useEffect(() => {
    if (!_.isNil(organizationId)) {
      fetchOrganizationDetails(organizationId);
    }
  }, [fetchOrganizationDetails, organizationId]);

  const organization = organizationDetailsRequestData?.data;
  const isOrganizationDetailsLoading =
    organizationDetailsRequestData?.isLoading;

  const locationIds = organization?.granted_locations?.[0]?.location_ids;
  const grantorShipperOrgId =
    organization?.granted_locations?.[0]?.organization_id;

  const { getOrganizationByDbId } = useOrganizations({
    ids: [grantorShipperOrgId],
  });
  const grantorShipperOrg = getOrganizationByDbId(grantorShipperOrgId);

  // Executed when shipper is changed/selected from the dropdown
  useEffect(() => {
    if (org.shipper) {
      setShipperID(org.shipper.value);
      fetchExternalCodeDefinition(org.shipper.value);
      fetchBrandingOptions(org.shipper.value);
    } else {
      clearExternalCodeDefinition();
    }
  }, [
    org.shipper,
    fetchExternalCodeDefinition,
    fetchBrandingOptions,
    clearExternalCodeDefinition,
  ]);

  const inputHandler = (value) => {
    setOrg((prevState) => {
      return { ...prevState, ...value };
    });
  };

  const inputHandlerGrantedFeatures = (value) => {
    setOrg((prevState) => ({
      ...prevState,
      features: !_.isEmpty(value?.grantedFeatures)
        ? value?.grantedFeatures
        : null,
    }));
  };

  const inputHandlerLocationDesignation = (value) => {
    setOrg((prevState) => {
      return { ...prevState, locations: value };
    });
  };

  const inputHandlerBranding = (value) => {
    if (actionStatus) {
      clearActionStatus();
    }
    setOrg((prevState) => {
      return { ...prevState, branding: value.value };
    });
  };

  const getSelectedBrandValue = (brandValue) => {
    if (!brandingOptionsLoading) {
      return brandingOptions.filter((item) => item.value === brandValue);
    }
    return brandValue;
  };

  const shipperInputHandler = (value) => {
    setShipperID(value.shipper.value);

    // Clear locations when setting value
    // Since the locations for one shipper will not be viable for another shipper.
    setOrg((prevState) => {
      return {
        ...prevState,
        locations: [],
        ...value,
        externalCodes: {},
        branding: null,
      };
    });

    // Load locations for the selected shipper org
    if (locationIds) {
      fetchOrgLocations(value.shipper.value, locationIds);
    } else {
      fetchOrgLocations(value.shipper.value);
    }
  };

  /**
   * Converts external code name to external code value
   * @param {string} externalCodeName
   * @returns {string} external code value
   */
  const getExternalCodeValue = (externalCodeName) => {
    return externalCodeDefinition?.externalCodes?.find(
      (code) => code.name === externalCodeName,
    ).externalCode;
  };

  const externalCodesOnChangeHandler = (qualifierName, value) => {
    setOrg((prevState) => {
      const newState = { ...prevState };
      newState.externalCodes[getExternalCodeValue(qualifierName)] = value;
      return newState;
    });
  };

  const isValidForm = () => {
    if (isDealer) {
      if (!org.shipper || !org.locations || org.locations.length === 0) {
        return false;
      }
    }

    if (isCarrier && !org.scac) {
      return false;
    }

    return (!org.email || isValidEmail(org.email)) &&
      org.name &&
      org.selectedType
      ? true
      : false;
  };

  const grantedFeaturesOptions = useGrantedFeaturesOptions();

  const orgTypeOptions = useMemo(() => {
    return _.chain(organizationTypes)
      .sortBy("name")
      .map((orgType) => {
        return {
          label: getTranslatedOrganizationType(orgType.name),
          value: orgType.id,
        };
      })
      .value();
  }, [organizationTypes, getTranslatedOrganizationType]);

  const orgLocationOptions = useMemo(() => {
    if (orgLocations) {
      return buildSearchLocationOptions(orgLocations);
    }
  }, [orgLocations]);

  const getExternalCodeFieldPrefilledValues = (externalCode) => {
    const shipperChanged = org?.shipper?.value !== grantorShipperOrgId;

    if (organization?.external_org_codes?.length > 0 && !shipperChanged) {
      return [
        ...organization?.external_org_codes
          .filter(
            (preFilledExternalCode) =>
              preFilledExternalCode.qualifier === externalCode.externalCode,
          )
          .map((preFilledExternalCode) => {
            return {
              label: preFilledExternalCode.value,
              value: preFilledExternalCode.value,
            };
          }),
      ];
    }
    return [];
  };

  useEffect(() => {
    setOrg((prevState) => {
      const select = organization
        ? orgTypeOptions.find((type) => type.label === organization.org_type)
        : null;

      // Dealers have a Shipper and Locations defined. Get the Shipper to load the Locations dropdown values.
      if (
        organization?.granted_locations &&
        organization?.granted_locations.length > 0 &&
        organization?.granted_locations[0]?.organization_id
      ) {
        // Load locations for the selected shipper org
        fetchOrgLocations(
          organization?.granted_locations[0]?.organization_id,
          locationIds,
        );
      }

      let shipper = null;
      if (grantorShipperOrgId && grantorShipperOrg) {
        shipper = {
          label: grantorShipperOrg.org_name,
          value: grantorShipperOrg.organization_id,
        };
      }

      return {
        ...prevState,
        id: organization ? organization.organization_id : null,
        name:
          organization && organization.org_name ? organization.org_name : "",
        email:
          organization && organization.email_address
            ? organization.email_address
            : "",
        freightVerifyId:
          organization && organization.fv_id ? organization.fv_id : "",
        contactName:
          organization && organization.contact_name
            ? organization.contact_name
            : "",
        phoneNumber:
          organization && organization.phone_no ? organization.phone_no : "",
        scac: organization && organization.scac ? organization.scac : "",
        shipper: shipper,
        selectedType: select,
        branding: organization ? organization.branding : null,
      };
    });
  }, [
    organization,
    orgTypeOptions,
    fetchOrgLocations,
    locationIds,
    grantorShipperOrgId,
    grantorShipperOrg,
  ]);

  useEffect(() => {
    if (organization && orgLocationOptions && orgLocationOptions.length > 0) {
      setOrg((prevState) => {
        let locations = [];
        if (
          organization?.granted_locations &&
          organization?.granted_locations.length > 0 &&
          organization?.granted_locations[0]?.location_ids &&
          organization?.granted_locations[0]?.location_ids.length > 0
        ) {
          organization.granted_locations[0].location_ids.forEach(
            (locationId) => {
              locations.push(
                orgLocationOptions.find(
                  (location) => location.value === locationId,
                ),
              );
            },
          );
        }
        return {
          ...prevState,
          locations: locations,
        };
      });
    }
  }, [organization, orgLocationOptions]);

  // Close the modal after successfully updating the org
  useEffect(() => {
    if (actionStatus === "ORGANIZATION_UPDATED") {
      hide();
      searchOrganizations();
    }

    return () => {
      clearActionStatus();
    };
  }, [actionStatus, hide, searchOrganizations, clearActionStatus]);

  useEffect(() => {
    if (organization?.features && organization?.features.length > 0) {
      setIsGrantedFeaturesDisable(true);
    } else {
      setIsGrantedFeaturesDisable(false);
    }

    setOrg((prevState) => {
      return {
        ...prevState,
        features:
          organization?.features?.map((ele) => {
            if (ele === "Dealer Drive Away") {
              return grantedFeaturesOptions[0];
            }
            return { label: ele, value: ele };
          }) ?? null,
      };
    });
  }, [organization, grantedFeaturesOptions]);

  const onImageDrop = (image) => {
    if (image && image[0] instanceof Blob) {
      let reader = new FileReader();
      reader.readAsDataURL(image[0]);
      reader.onload = () => {
        inputHandler({ base64Image: reader.result });
      };
      reader.onerror = (error) => {
        console.error("Error: ", error);
      };
    }
  };

  return (
    <Modal
      backdrop={"static"}
      show={show}
      onHide={() => {
        hide();
      }}
    >
      <Modal.Header title={t("organizations:Edit Organization")} />
      <Modal.Body>
        <Loader loaded={!isOrganizationDetailsLoading}>
          <FlexRowDiv>
            <TextInput
              label={t("organizations:Organization Name")}
              value={org.name}
              changeHandler={inputHandler}
              prop="name"
            />
            <SelectInput
              label={t("organizations:Type")}
              placeholder={t("organizations:Select...")}
              options={orgTypeOptions}
              value={org.selectedType}
              changeHandler={inputHandler}
              prop="selectedType"
              multi={false}
            />
          </FlexRowDiv>
          <FlexRowDiv style={{ marginTop: "1em" }}>
            <TextInput
              label={t("organizations:Email")}
              value={org.email}
              changeHandler={inputHandler}
              prop="email"
            />
            <TextInput
              label={t("organizations:FreightVerify ID")}
              value={org.freightVerifyId}
              changeHandler={inputHandler}
              prop="freightVerifyId"
              disabled={true}
            />
          </FlexRowDiv>
          <FlexRowDiv style={{ marginTop: "1em" }}>
            <TextInput
              label={t("organizations:Contact Name")}
              value={org.contactName}
              changeHandler={inputHandler}
              prop="contactName"
            />
            <TextInput
              label={t("organizations:Phone Number")}
              value={org.phoneNumber}
              changeHandler={inputHandler}
              prop="phoneNumber"
            />
          </FlexRowDiv>
          {isCarrier || isDealer ? (
            <GrantedFeaturesSelect
              label={t(
                "organizations:Dealer Pick Up Feature - This Action Cannot be Undone",
              )}
              placeholder={t("organizations:Select...")}
              grantedFeaturesOptions={grantedFeaturesOptions}
              value={org?.features}
              changeHandler={inputHandlerGrantedFeatures}
              disabled={isGrantedFeaturesDisable}
            />
          ) : null}
          {isCarrier ? (
            <FlexRowDiv style={{ marginTop: "1em" }}>
              <TextInput
                style={{ width: "100%" }}
                label={t("organizations:SCAC")}
                value={org.scac}
                changeHandler={inputHandler}
                prop="scac"
              />
            </FlexRowDiv>
          ) : null}
          {isPartner ? (
            <FlexRowDiv style={{ marginTop: "1em" }}>
              <TextInput
                style={{ width: "100%" }}
                label={
                  // Partner Code is the SCAC for Partner organizations
                  t("organizations:Partner Code")
                }
                value={org.scac}
                changeHandler={inputHandler}
                prop="scac"
              />
            </FlexRowDiv>
          ) : null}
          {isDealer ? (
            <React.Fragment>
              <FlexRowDiv
                css={{
                  marginTop: "1em",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5em",
                }}
              >
                <span
                  css={{
                    color: "#acb5be",
                  }}
                >
                  {t("organizations:Shipper")}
                </span>
                <ShipperOrganizationsAsyncSelect
                  value={org.shipper}
                  onChange={(org) => {
                    shipperInputHandler({ shipper: org });
                  }}
                />
              </FlexRowDiv>
              {org.shipper ? (
                <React.Fragment>
                  <FlexRowDiv
                    style={{
                      marginTop: "1em",
                      minHeight: "5em",
                      position: "relative",
                      color: "#acb5be",
                    }}
                  >
                    <Loader
                      loaded={!isLoadingOrgLocations}
                      scale={0.75}
                      loadedClassName="w-100"
                    >
                      <FormGroup css={{ marginBottom: "1rem" }}>
                        <span>{t("organizations:Location Designation")}</span>
                      </FormGroup>

                      <LocationDesignationSelect
                        onChange={inputHandlerLocationDesignation}
                        value={org.locations}
                        selectedShipper={shipperID}
                      />
                    </Loader>
                  </FlexRowDiv>
                  <FlexRowDiv
                    style={{
                      marginTop: "1em",
                      minHeight: "5em",
                      position: "relative",
                      color: "#acb5be",
                    }}
                  >
                    <FlexColDiv
                      style={{
                        width: "100%",
                      }}
                    >
                      <FormGroup css={{ marginBottom: "1rem" }}>
                        <span>{t("organizations:Branding")}</span>
                      </FormGroup>
                      <BrandingOptionSelect
                        onChange={inputHandlerBranding}
                        value={getSelectedBrandValue(org.branding)}
                        selectedShipper={shipperID}
                      />
                    </FlexColDiv>
                  </FlexRowDiv>
                </React.Fragment>
              ) : null}
              {org.shipper &&
              (externalCodeDefinitionLoading ||
                externalCodeDefinition?.externalCodes?.length > 0) ? (
                <FlexRowDiv
                  style={{
                    marginTop: "1em",
                    minHeight: "5em",
                    position: "relative",
                    color: "#acb5be",
                  }}
                >
                  <Loader
                    loaded={!externalCodeDefinitionLoading}
                    scale={0.75}
                    loadedClassName="w-100"
                  >
                    <FlexRowDiv css={{ flexWrap: "wrap" }}>
                      {externalCodeDefinition?.externalCodes?.map(
                        (externalCode) => (
                          <ExternalCodeField
                            key={externalCode.name}
                            externalCodeName={externalCode.name}
                            onChange={externalCodesOnChangeHandler}
                            style={{
                              marginTop: "0.5em",
                              width: "50%",
                              color: Colors.text.HIT_GRAY,
                            }}
                            prefilledValues={getExternalCodeFieldPrefilledValues(
                              externalCode,
                            )}
                          />
                        ),
                      )}
                    </FlexRowDiv>
                  </Loader>
                </FlexRowDiv>
              ) : null}
            </React.Fragment>
          ) : null}
          <FlexRowDiv style={{ marginTop: "1.5em" }}>
            <ImageUploader
              withIcon={true}
              withPreview={true}
              singleImage={true}
              buttonText={t("organizations:Choose images")}
              label={t("organizations:Max file size 5mb, accepted jpg|gif|png")}
              fileSizeError={t("organizations:file size is too big")}
              fileTypeError={t("organizations:is not supported file extension")}
              onChange={onImageDrop}
              imgExtension={[".png"]}
              maxFileSize={5242880}
            />
          </FlexRowDiv>
        </Loader>
      </Modal.Body>
      <Modal.Footer>
        <ValidationRow
          isValid={org.name ? true : false}
          description={t("organizations:Name is required.")}
        />
        <ValidationRow
          isValid={org.selectedType ? true : false}
          description={t("organizations:Type is required.")}
        />
        {isCarrier ? (
          <ValidationRow
            isValid={!isCarrier || org.scac ? true : false}
            description={t("organizations:SCAC is required for Carriers.")}
          />
        ) : null}
        {isDealer ? (
          <React.Fragment>
            <ValidationRow
              isValid={!isDealer || org.shipper ? true : false}
              description={t("organizations:Shipper is required.")}
            />
            <ValidationRow
              isValid={
                !isDealer || (org.locations && org.locations.length > 0)
                  ? true
                  : false
              }
              description={t(
                "organizations:At least 1 Location Designation is required.",
              )}
            />
          </React.Fragment>
        ) : null}
        <Button
          variant="light"
          style={{ marginRight: ".5em" }}
          onClick={() => {
            hide();
          }}
        >
          {t("organizations:Cancel")}
        </Button>
        <Button
          variant="success"
          disabled={!isValidForm()}
          onClick={() => {
            let grantedLocations = null;
            let grantedFeatures = null;
            if (isDealer) {
              grantedLocations = [
                {
                  location_ids: org.locations.map((l) => l.value),
                  organization_id: org.shipper.value,
                },
              ];
            }

            if (isDealer || isCarrier) {
              grantedFeatures =
                org?.features?.map((ele) => {
                  if (ele?.value === grantedFeaturesOptions[0]?.value) {
                    return "Dealer Drive Away";
                  }
                  return ele?.value;
                }) ?? null;
            }

            const payload = {
              name: org.name,
              email_address: org.email,
              fv_id: org.freightVerifyId,
              contact_name: org.contactName,
              phone_no: org.phoneNumber,
              organization_profile_id: org.selectedType
                ? organizationTypes[org.selectedType.value].profile_id
                : 0,
              logo: org.base64Image,
              scac: org.scac,
              granted_locations: grantedLocations,
              external_codes: org.externalCodes,
              branding: org.branding,
              features: grantedFeatures,
            };

            updateOrganization(org.id, payload);
          }}
        >
          {t("organizations:Update")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

EditOrganizationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  organizationId: PropTypes.number,
  fetchOrganizationDetails: PropTypes.func.isRequired,
  organizationDetailsRequestData: PropTypes.shape({
    data: PropTypes.object,
    isLoading: PropTypes.bool,
  }),
  organizationTypes: PropTypes.array.isRequired,
  fetchOrgLocations: PropTypes.func.isRequired,
  orgLocations: PropTypes.array,
  isLoadingOrgLocations: PropTypes.bool,
  updateOrganization: PropTypes.func.isRequired,
  actionStatus: PropTypes.string,
  clearActionStatus: PropTypes.func.isRequired,
  fetchBrandingOptions: PropTypes.func.isRequired,
  brandingOptions: PropTypes.array,
  brandingOptionsLoading: PropTypes.bool,
  fetchExternalCodeDefinition: PropTypes.func.isRequired,
  externalCodeDefinition: PropTypes.any,
  externalCodeDefinitionLoading: PropTypes.bool,
  clearExternalCodeDefinition: PropTypes.func.isRequired,
  searchOrganizations: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    organizationDetailsRequestData: getOrganizationDetailsRequestData(state),
    organizationTypes: getOrganizationTypes(state),
    orgLocations: getOrgLocations(state),
    isLoadingOrgLocations: getIsLoadingOrgLocations(state),
    actionStatus: state.organizations.actionStatus,
    brandingOptions: getBrandingOptions(state),
    brandingOptionsLoading: getBrandingOptionsLoading(state),
    externalCodeDefinition: getExternalCodeDefinitions(state),
    externalCodeDefinitionLoading: getExternalCodeDefinitionsLoading(state),
  };
}

const actionCreators = {
  fetchOrganizationDetails,
  fetchOrgLocations,
  updateOrganization,
  clearActionStatus,
  fetchBrandingOptions,
  fetchExternalCodeDefinition,
  clearExternalCodeDefinition,
  searchOrganizations: () =>
    OrganizationsSearchBarState.actionCreators.searchEntities(
      null,
      false,
      true,
    ),
};

export default connect(mapStateToProps, actionCreators)(EditOrganizationModal);
