import _ from "lodash";
import apiUrl from "api-url";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";
import { CONTAINER_TYPE_FILTERS } from "../search/ContainerTrackingSearchFilterDefs";
import { Features } from "modules/auth/Authorization";

const STORE_MOUNT_POINT = "ctContainerTypeTable";

// Helpers

const fetchContainerTypeUrl = (solutionId, queryString) => {
  return apiUrl(`/containertracking/api/homecontainertype?${queryString}`);
};

// Actions

const axiosConfig = () => {
  return {
    headers: {
      "x-target-feature": Features.CONTAINER_TRACKING,
    },
  };
};

const fetchContainerType = (queryString = "", solutionId, duck, dispatch) => {
  const url = fetchContainerTypeUrl(solutionId, queryString);
  const config = axiosConfig();
  dispatch(duck.fetch(url, config));
};

const ContainerTrackingContainerTypeTableState = buildSearchBarState(
  STORE_MOUNT_POINT,
  [],
  CONTAINER_TYPE_FILTERS,
  fetchContainerType,
  [],
  {
    defaultSort: "total",
    reverseSort: true,
  },
);

ContainerTrackingContainerTypeTableState.actionCreators = {
  ...ContainerTrackingContainerTypeTableState.actionCreators,
  exportSearch: _.partial(
    ContainerTrackingContainerTypeTableState.actionCreators.exportEntities,
    fetchContainerTypeUrl,
    null,
    { headers: { accept: "text/csv" } },
    "container-type-table-results",
    null,
    true,
  ),
};

export default ContainerTrackingContainerTypeTableState;
