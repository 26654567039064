/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Text } from "components/atoms/Text.atom";
import moment from "moment";
import Colors from "styles/colors";

export const useDetailsColumns = () => {
  const { t } = useTranslation("critical-parts");

  const columns = [
    {
      Header: t("critical-parts:Part"),
      accessor: "partName",
      width: 200,
      Cell: PartCell,
    },
    {
      Header: t("critical-parts:Run Out By"),
      accessor: "runOutBy",
      width: 200,
      Cell: RunOutByCell,
    },
    {
      Header: t("critical-parts:Must Ship By"),
      accessor: "mustShipBy",
      width: 200,
      Cell: MustShipByCell,
    },
    {
      Header: t("critical-parts:Days On Hand"),
      accessor: "daysOnHand",
      width: 200,
      Cell: DaysOnHandCell,
    },
    {
      Header: t("critical-parts:Reqmts"),
      accessor: "requirements",
      width: 200,
      Cell: RequirementsCell,
    },
    {
      Header: t("critical-parts:DOH/Schedule Shipments"),
      accessor: "nextShipments",
      width: 200,
      Cell: NextShipmentCell,
    },
    {
      Header: t("critical-parts:Notes/Comments"),
      accessor: "notesComments",
      width: 200,
      Cell: NotesCell,
    },
  ];

  return columns;
};

export const PartCell = ({ value, row }) => (
  <div>
    <Text block bold>
      {value}
    </Text>
    <Text block>{row?.original?.supplierInfo?.name}</Text>
    <Text block>
      {row?.original?.supplierInfo?.city}, {row?.original?.supplierInfo?.state}
    </Text>
    <Text block>{row?.original?.supplierInfo?.phoneNumber}</Text>
  </div>
);

const RunOutByCell = ({ value }) => {
  const date = moment(value);
  const formattedDate = date.format("MM/DD/YYYY");
  const formattedTime = date.format("hh:mm A");
  const now = moment();
  const timeLeft = Math.round(moment.duration(date.diff(now)).asHours());

  return (
    <div>
      <Text block bold>
        {formattedDate}
      </Text>
      <Text block bold>
        {formattedTime}
      </Text>
      <Text
        block
        bold
        css={{
          color: timeLeft <= 1 ? Colors.highlight.RED : Colors.highlight.YELLOW,
        }}
      >
        {timeLeft} Hours Left
      </Text>
    </div>
  );
};

const MustShipByCell = ({ value }) => {
  const date = moment(value);
  const formattedDate = date.format("MM/DD/YYYY");
  const formattedTime = date.format("hh:mm A");

  return (
    <div>
      <Text block bold>
        {formattedDate}
      </Text>
      <Text block bold>
        {formattedTime}
      </Text>
    </div>
  );
};

export const DaysOnHandCell = ({ row }) => (
  <div>
    <Text block bold>
      {row?.original?.daysOnHand}
    </Text>
    <Text block>
      Current: {row?.original?.currentAmount}, Min: {row?.original?.minAmount}
    </Text>
  </div>
);

export const RequirementsCell = ({ row }) => (
  <div>
    <Text block bold>
      {row?.original?.requiredAmount}
    </Text>
  </div>
);

export const NextShipmentCell = ({ value }) => {
  const nextShipment = value?.[0];

  return (
    <div>
      <Text block>ETA: {nextShipment?.eta}</Text>
      <Text block>Shipment ID: {nextShipment?.shipmentId}</Text>
      <Text block>Quantity: {nextShipment?.quantityReceiving}</Text>
    </div>
  );
};

export const NotesCell = ({ row }) => (
  <div>
    <Text block>System Comment: {row?.original?.systemComment?.comment}</Text>
    <Text block>User Comment: {row?.original?.userComment?.comment}</Text>
    <Text block>Email: {row?.original?.userComment?.email}</Text>
  </div>
);

PartCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.object,
};

RunOutByCell.propTypes = {
  value: PropTypes.string,
};

MustShipByCell.propTypes = {
  value: PropTypes.string,
};

DaysOnHandCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      daysOnHand: PropTypes.number,
      currentAmount: PropTypes.number,
      minAmount: PropTypes.number,
    }),
  }),
};

RequirementsCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      requiredAmount: PropTypes.number,
    }),
  }),
};

NextShipmentCell.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      eta: PropTypes.string,
      shipmentId: PropTypes.string,
      quantityReceiving: PropTypes.number,
    }),
  ),
};

NotesCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      systemComment: PropTypes.shape({
        comment: PropTypes.string,
      }),
      userComment: PropTypes.shape({
        comment: PropTypes.string,
        email: PropTypes.string,
      }),
    }),
  }),
};
