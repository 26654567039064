import { createSelector } from "reselect";
import apiUrl from "api-url";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";

const STORE_MOUNT_POINT = "fvHolds";
const HOLDS_URL_BASE = apiUrl("/entity/");

const holdCountsDuck = buildFetchDuck(STORE_MOUNT_POINT);

// Action creators
function fetchHoldCounts(solutionId) {
  return (dispatch) => {
    const holdsUrl = `${HOLDS_URL_BASE}solution/${solutionId}/entity/hold/count?status=ACTIVE&lifeCycleState=Active,Delivered`;
    dispatch(holdCountsDuck.fetch(holdsUrl));
  };
}

// selectors
const getHoldCountRows = (state) => state[STORE_MOUNT_POINT].data;
const getHoldCountsLoading = (state) => state[STORE_MOUNT_POINT].isLoading;

const selectHoldCountsForWidget = createSelector([getHoldCountRows], (rows) =>
  rows.map((r) => ({
    type: r.name,
    reason_code: r.reason_code,
    vins_associated: r.count,
  })),
);

// interface
const FinVehicleHoldsState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchHoldCounts,
  },
  selectors: {
    getHoldCountRows,
    getHoldCountsLoading,
    selectHoldCountsForWidget,
  },
  reducer: holdCountsDuck.reducer,
};
export default FinVehicleHoldsState;
