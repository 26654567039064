import { createSelector } from "reselect";

import InventoryViewDomainDataState from "modules/domain-data/InventoryViewDomainData";
import { createOptionsMapper } from "utils/selectors-utils";

export const selectCurrentLocation = createSelector(
  [InventoryViewDomainDataState.selectors.getCurrentLocation],
  createOptionsMapper({
    labelKey: (item) => `${item.label}`,
    valueKey: (item) => `${item.value}`,
  }),
);
