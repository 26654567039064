import { connect } from "react-redux";

import { ComplianceDashboard } from "./Compliance.Dashboard.page";

const actionCreators = {
  pushReportDetailScreen: (reportId) => {
    return {
      type: "COMPLIANCE_REPORT_DETAILS",
      payload: { reportId: reportId },
    };
  },
};

export default connect(null, actionCreators)(ComplianceDashboard);
