/** @jsxImportSource @emotion/react */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useGetCountryNameByCountryCode } from "pages/administration/utils/location-utils";
import { FontSize, Text } from "components/atoms/Text.atom";
import { DeliveryType } from "shared/constants/shipments.const";

// Note: This is a duplicate of components/organisms/bootstrap-table/Cell/LocationCell.js
// Eventually, when we move everything to using BaseTable, we can get rid of the other component.
// If upgrading something to use BaseTable, and it's referencing the other component,
// you can switch to using this one without issue.
const LocationCell = ({
  style = {},
  location,
  showAddress,
  deliveryType = null,
}) => {
  const { t } = useTranslation("components");

  const countryName = useGetCountryNameByCountryCode(location.country);

  let deliveryTypeTranslated = null;
  switch (deliveryType?.toLowerCase()) {
    case DeliveryType.ATTENDED.toLowerCase(): {
      deliveryTypeTranslated = t("components:Attended");
      break;
    }
    case DeliveryType.UNATTENDED.toLowerCase(): {
      deliveryTypeTranslated = t("components:Unattended");
      break;
    }
    default: {
      deliveryTypeTranslated = null;
    }
  }

  return (
    <div style={style}>
      <Text bold block size={FontSize.size12}>
        {location.code}
      </Text>{" "}
      <Text block size={FontSize.size12}>
        {location.name}
      </Text>{" "}
      {showAddress ? (
        <React.Fragment>
          <Text block size={FontSize.size12}>
            {location.address}
          </Text>{" "}
          <Text block size={FontSize.size12}>
            {location.city}
            {location.city && location.state ? ", " : ""}
            {location.state}
            {location.postalCode && " "}
            {location.postalCode}
          </Text>
          {countryName ? (
            <Text block size={FontSize.size12}>
              {" "}
              {countryName}
            </Text>
          ) : null}
        </React.Fragment>
      ) : null}
      {deliveryTypeTranslated ? (
        <Text bold block size={FontSize.size12}>
          {t("components:Delivery Type")}: {deliveryTypeTranslated}
        </Text>
      ) : null}
    </div>
  );
};

LocationCell.propTypes = {
  style: PropTypes.object,
  location: PropTypes.object,
  showAddress: PropTypes.bool,
  deliveryType: PropTypes.string,
};

export default LocationCell;
