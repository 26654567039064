/** @jsxImportSource @emotion/react */

import { connect } from "react-redux";
import { SearchBar } from "components/search-bar/SearchBar";
import SearchBarState from "pages/containertracking/redux/SensorOverviewSearchBarState";
import { SensorOverviewDomainDataState } from "pages/containertracking/redux/SensorOverviewDomainDataState";

const mapStateToProps = (state) => {
  const {
    getSearchText,
    getSearchCategory,
    getIgnoreSearchCategory,
    getTypeaheadOptionsMetadata,
    getHasSearchCriteriaChanged,
    getCanUserSearch,
  } = SearchBarState.selectors;

  return {
    searchCategory: getSearchCategory(state),
    searchText: getSearchText(state),
    ignoreSearchCategory: getIgnoreSearchCategory(state),
    typeaheadOptionsMetadata: getTypeaheadOptionsMetadata(state),
    hasSearchCriteriaChanged: getHasSearchCriteriaChanged(state),
    canUserSearch: getCanUserSearch(state),
    containerTypes: [],
    sensorIds: [],
    locations: [],
  };
};

const {
  setSearchCategoryForKey,
  setSearchText,
  clearSearchText,
  searchEntities,
  clearSavedSearch,
  resetSearchBar,
} = SearchBarState.actionCreators;

const { fetchDomainData } = SensorOverviewDomainDataState.actionCreators;

const actionCreators = {
  setSearchCategory: setSearchCategoryForKey,
  setSearchText: (text, ignoreValueChange) => (dispatch) => {
    dispatch(setSearchText(text, ignoreValueChange, true));
  },
  clearSearchText,
  searchEntities,
  clearSavedSearch,
  fetchDomainData,
  resetSearchBar,
};

export default connect(mapStateToProps, actionCreators)(SearchBar);
