import { connect } from "react-redux";
import { VinViewDetails } from "./VinView.Details.page";

import VinViewEntityDetailsState from "../redux/VinViewEntityDetailsState";
import VinViewTripLegState from "../redux/VinViewTripLegState";
import VinViewSubscriptionState from "../redux/VinViewSubscriptionState";
import MapState from "modules/map/MapState";
import ShipmentsStatusState from "pages/shipments/redux/ShipmentsState";
import {
  getSolutionId,
  getActiveOrganization,
} from "modules/organizations/OrganizationsState";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import { MapCoordinateType } from "modules/map/components/map-coordinate-definition";
import VinViewEntityHistoricalState from "../redux/VinViewEntityHistoricalState";
import NotificationsState from "modules/notifications/NotificationsState";
import ErrorState from "pages/error/redux/Error.state";

function mapStateToProps(state) {
  const entityId = state.location.payload.internal_id;

  return {
    solutionId: getSolutionId(state),
    entityId,
    details: VinViewEntityDetailsState.selectors.getEntityDetails(state),
    detailsError:
      VinViewEntityDetailsState.selectors.getEntityDetailsError(state),
    coords: VinViewEntityDetailsState.selectors.getEntityPositionUpdates(state),
    connectedCarCoords:
      VinViewEntityDetailsState.selectors.getEntityConnectedCarPositionUpdates(
        state,
      ),
    plannedLegs: VinViewTripLegState.selectors.getPlannedTripLeg(state),
    actualLegs: VinViewTripLegState.selectors.getActualTripLeg(state),
    combinedLegs: VinViewTripLegState.selectors.getCombinedTripLegs(state),
    parentShipment: ShipmentsStatusState.selectors.getShipmentDetails(state),
    childShipments:
      ShipmentsStatusState.selectors.getChildShipmentDetails(state),
    shipmentsLoaded: ShipmentsStatusState.selectors.getIsLoaded(state),
    exceptions: VinViewEntityDetailsState.selectors.getEntityExceptions(state),
    combinedExceptions:
      VinViewEntityDetailsState.selectors.getCombinedExceptions(state),
    currentLocation:
      VinViewEntityDetailsState.selectors.getEntityCurrentLocation(state),
    authorization: getAuthorization(state),
    holds: VinViewEntityDetailsState.selectors.getEntityHolds(state),
    events: VinViewEntityDetailsState.selectors.getEntityEvents(state),
    media: VinViewEntityDetailsState.selectors.getEntityMedia(state),
    // Comments
    isFetchingComments:
      VinViewEntityDetailsState.selectors.getIsFetchingComments(state),
    comments: VinViewEntityDetailsState.selectors.getComments(state),
    shipmentComments: ShipmentsStatusState.selectors.getComments(state),
    isBatchCommentInProgress:
      VinViewEntityDetailsState.selectors.getIsBatchCommentInProgress(state),
    isBatchCommentSuccessful:
      VinViewEntityDetailsState.selectors.getIsBatchCommentSuccessful(state),
    isBatchCommentFailed:
      VinViewEntityDetailsState.selectors.getIsBatchCommentFailed(state),
    shippers: VinViewEntityDetailsState.selectors.getEntityShippers(state),
    // Subscription
    vinSubscription: VinViewSubscriptionState.selectors.getSubscription(state),
    isVinSubscriptionLoading:
      VinViewSubscriptionState.selectors.getIsSubscriptionLoading(state),
    vinSubscriptionRequestError:
      VinViewSubscriptionState.selectors.getSubscriptionRequestError(state),
    isVinSubscriptionUpdating:
      VinViewSubscriptionState.selectors.getIsSubscriptionUpdating(state),
    vinSubscriptionUpdateSuccess:
      VinViewSubscriptionState.selectors.getSubscriptionUpdateSuccess(state),
    vinSubscriptionUpdateError:
      VinViewSubscriptionState.selectors.getSubscriptionUpdateError(state),
    // Share
    isSharingVin: VinViewEntityDetailsState.selectors.getIsSharingVin(state),
    isShareVinSuccessful:
      VinViewEntityDetailsState.selectors.getIsShareVinSuccessful(state),
    isShareVinFailed:
      VinViewEntityDetailsState.selectors.getIsShareVinFailed(state),
    // Map Coordinates
    currentMapCoordinate: MapState.selectors.getMapCoordinate(
      MapCoordinateType.CURRENT_LOCATION,
    )(state),
    selectedMapCoordinate: MapState.selectors.getMapCoordinate(
      MapCoordinateType.SELECTED_COORDINATE,
    )(state),
    selectedConnectedCarCoordinate: MapState.selectors.getMapCoordinate(
      MapCoordinateType.CURRENT_CONNECTED_CAR,
    )(state),
    // Historical Data
    getHistoryByQualifier:
      VinViewEntityHistoricalState.selectors.getHistoryByQualifier,
    getEntityStateChangeTs:
      VinViewEntityDetailsState.selectors.getEntityStateChangeTs(state),
    // Other
    activeOrganization: getActiveOrganization(state),
    selectedDealerOrgId: state.location.payload.dealerOrgId,
  };
}

// Dispatch to props

const {
  fetchEntityExceptions,
  fetchEntityHolds,
  fetchEntityDetails,
  fetchEntityMedia,
  clearEntityMedia,
  fetchEntityPositionUpdates,
  fetchEntityEvents,
  fetchEntityStateHistory,
  setWatchEntity,
  fetchComments,
  addComment,
  cancelAddComment,
  updateComment,
  cancelUpdateComment,
  markCommentsRead,
  addBatchComments,
  clearBatchComments,
  fetchEntityShippers,
  shareVin,
  clearShareVin,
} = VinViewEntityDetailsState.actionCreators;
const { fetchPlannedTripLeg, fetchActualTripLeg } =
  VinViewTripLegState.actionCreators;
const {
  fetchSubscription: fetchVinSubscription,
  updateSubscription,
  subscribe,
  unsubscribe,
} = VinViewSubscriptionState.actionCreators;
const { fetchHistoricalData } = VinViewEntityHistoricalState.actionCreators;
const { fetchLegShipmentDetails, clearLegShipmentDetails } =
  ShipmentsStatusState.actionCreators;
const { fetchNotification } = NotificationsState.actionCreators;
const { addCoordinate, clearCoordinatesByType } = MapState.actionCreators;
const { redirectTo404, redirectTo403 } = ErrorState.actionCreators;

const actionCreators = {
  fetchEntityExceptions,
  fetchEntityDetails,
  fetchEntityMedia,
  clearEntityMedia,
  fetchEntityHolds,
  fetchEntityPositionUpdates,
  fetchEntityEvents,
  setWatchEntity,
  fetchPlannedTripLeg,
  fetchActualTripLeg,
  fetchLegShipmentDetails,
  clearLegShipmentDetails,
  // Map Coordinates
  addCoordinate,
  clearCoordinatesByType,
  // Historical Data
  fetchHistoricalData,
  fetchEntityStateHistory,
  // Comments
  fetchComments,
  addComment,
  cancelAddComment,
  updateComment,
  cancelUpdateComment,
  markCommentsRead,
  addBatchComments,
  clearBatchComments,
  fetchNotification,
  fetchEntityShippers,
  // Subscription
  fetchVinSubscription,
  updateSubscription,
  subscribe,
  unsubscribe,
  // Share
  shareVin,
  clearShareVin,
  // Error redirects
  redirectTo404,
  redirectTo403,
};

export default connect(mapStateToProps, actionCreators)(VinViewDetails);
