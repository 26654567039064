import { connect } from "react-redux";
import debouncePromise from "debounce-promise";

import LadsState from "shared/redux/Lads.state";
import {
  fetchHeatMapData,
  fetchLocationDetails,
  getLocations,
  matchLocation,
} from "pages/administration/location-management/redux/Locations.state";
import { getSolutionId } from "modules/organizations/OrganizationsState";
import TitleState from "shared/redux/TitleState";
import LocationMatchingState from "pages/administration/location-management/unresolved-location-matching/redux/UnresolvedLocationMatching.state";
import { LocationMatchingViewSearchBarState } from "./redux/UnresolvedLocationMatching.SearchBar.state";
import { LocationMatchingView } from "./LocationManager.UnresolvedLocationMatching.page";

function mapStateToProps(state) {
  const {
    getUnresolvedLocation,
    getUnresolvedLocationId,
    getShipmentWithUnresolvedLocation,
    getReturnToPreviousScreen,
  } = LocationMatchingState.selectors;

  const {
    getSearchResults,
    getIsLoading,
    getPage,
    getPageSize,
    getTotalPages,
    getTotalEntities,
  } = LocationMatchingViewSearchBarState.selectors;

  const locationId = getUnresolvedLocationId(state);

  return {
    locationId: locationId,
    unresolvedLocation: getUnresolvedLocation(state),
    isUnresolvedLocationLoading:
      state.locationMatching.isUnresolvedLocationLoading,
    shipment: getShipmentWithUnresolvedLocation(state),
    returnToPreviousScreen: getReturnToPreviousScreen(state),
    lads: LadsState.selectors.getLadsList(state),
    locations: getLocations(state),
    heatmapData: state.locations.heatmap.data,
    heatmapUid: state.locations.heatmap.heatMapUid,
    heatmapIsLoading: state.locations.heatmap.isLoading,
    locationSearchResults: getSearchResults(state),
    isLoading: getIsLoading(state),
    page: getPage(state),
    pageSize: getPageSize(state),
    totalPages: getTotalPages(state),
    totalLocations: getTotalEntities(state),
    solutionId: getSolutionId(state),
  };
}

function mapDispatchToProps(dispatch) {
  const {
    fetchUnresolvedLocationDetails,
    clearUnresolvedLocation,
    setShipmentWithUnresolvedLocation,
    setReturnToPreviousScreen,
    linkLocation,
  } = LocationMatchingState.actionCreators;

  return {
    setTitle: (title) => dispatch(TitleState.actionCreators.setTitle(title)),
    setSubTitle: (subTitle) =>
      dispatch(TitleState.actionCreators.setSubTitle(subTitle)),
    clearUnresolvedLocation: () => dispatch(clearUnresolvedLocation()),
    setShipmentWithUnresolvedLocation: (shipment) =>
      dispatch(setShipmentWithUnresolvedLocation(shipment)),
    linkLocation: (id, data) => dispatch(linkLocation(id, data, matchLocation)),
    fetchUnresolvedLocationDetails: (id) =>
      dispatch(fetchUnresolvedLocationDetails(id)),
    fetchLocationDetails: (id) => dispatch(fetchLocationDetails(id)),
    setReturnToPreviousScreen: (value) =>
      dispatch(setReturnToPreviousScreen(value)),
    pushShipmentDetailView: (shipmentID) =>
      dispatch({
        type: "SHIPMENT_DETAIL",
        payload: { shipment_id: shipmentID },
      }),
    pushCreateLocationScreen: (unresolvedLocation) =>
      dispatch({ type: "CREATE_LOCATION", payload: { unresolvedLocation } }),
    pushLocationsScreen: () => dispatch({ type: "LOCATION_UNRESOLVED" }),
    fetchHeatMapData: (locationId) => dispatch(fetchHeatMapData(locationId)),
    setPagination: (solutionId, page, pageSize) =>
      dispatch(
        LocationMatchingViewSearchBarState.actionCreators.setPagination(
          solutionId,
          page,
          pageSize,
        ),
      ),
    resetSearchBar: () =>
      dispatch(
        LocationMatchingViewSearchBarState.actionCreators.resetSearchBar(),
      ),
    clearSearchFilters: () =>
      dispatch(
        LocationMatchingViewSearchBarState.actionCreators.clearSearchFilters(),
      ),
    setSearchFilter: (key, value) =>
      dispatch(
        LocationMatchingViewSearchBarState.actionCreators.setSearchFilter(
          key,
          value,
        ),
      ),
    clearSearchFilter: (key) =>
      dispatch(
        LocationMatchingViewSearchBarState.actionCreators.clearSearchFilter(
          key,
        ),
      ),
    searchLocations: debouncePromise(
      (solutionId) =>
        dispatch(
          LocationMatchingViewSearchBarState.actionCreators.searchEntities(
            solutionId,
          ),
        ),
      500,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationMatchingView);
