import _ from "lodash";
import { formatDuration } from "utils/date-time";
import { getReferenceValueByName } from "utils/response-utils";

export const getVinDetails = (details) => {
  const description = details && details.description;
  const orderNumber =
    details && _.find(details.references, ["qualifier", "OrderNumber"]);
  const yearObj = details && _.find(details.references, ["qualifier", "year"]);
  const makeObj = details && _.find(details.references, ["qualifier", "make"]);
  const modelObj =
    details && _.find(details.references, ["qualifier", "model"]);
  const yearTxt = yearObj ? yearObj.value : "";
  const makeTxt = makeObj ? makeObj.value : "";
  const modelTxt = modelObj ? modelObj.value : "";
  const orderNumberTxt =
    !_.isNil(orderNumber) && !_.isNil(orderNumber.value)
      ? orderNumber.value
      : "-";

  return {
    id: details && details.id,
    type: description || `${yearTxt} ${makeTxt} ${modelTxt}`,
    orderNumber: orderNumberTxt,
    status: (details && details.lifeCycleState) || "",
    dwell: formatDuration(details?.currentDwell?.dwellTimeSeconds),
    isShippable: details.isShippable ?? false,
    displayShippabilityStatus:
      details.configurations?.displayShippabilityStatus ?? false,
  };
};

export const getVinPopUpDetails = (details, type = "details") => {
  const make = getReferenceValueByName(details?.references, "Decoded-Make");
  const model = getReferenceValueByName(details?.references, "Decoded-Model");
  const year = getReferenceValueByName(
    details?.references,
    "Decoded-ModelYear",
  );

  return {
    id: details && details.id,
    year: year ?? "",
    make: make ?? "",
    model: model ?? "",
    type: type,
  };
};
