import {
  CP_SORT_BY_RUN_OUT_BY,
  CP_SORT_DIRECTION_ASCENDING,
} from "../components/organisms/SortBySelect";

const STORE_MOUNT_POINT = "criticalparts";

const SAVE_SORT_COLUMN = "CriticalParts/SAVE_SORT_COLUMN";
const SAVE_SORT_REVERSE = "CriticalParts/SAVE_SORT_REVERSE";
const UPDATE_LOCATION = "CriticalParts/UPDATE_LOCATION";
const UPDATE_FILTER = "CriticalParts/UPDATE_FILTER";
const UPDATE_RESET_FILTERS = "CriticalParts/UPDATE_RESET_FILTERS";
const UPDATE_CRITERIA_CHANGED = "CriticalParts/UPDATE_CRITERIA_CHANGED";
const UPDATE_SEARCH_TEXT = "CriticalParts/UPDATE_SEARCH_TEXT";

export const setSortColumn = (sortColumn) => {
  return (dispatch) => {
    dispatch({ type: SAVE_SORT_COLUMN, payload: { sortColumn } });
  };
};

export const setSortReverse = (sortReverse) => {
  return (dispatch) => {
    dispatch({ type: SAVE_SORT_REVERSE, payload: { sortReverse } });
  };
};

export const setSelectedLocationCode = (locationCode) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_LOCATION, payload: { locationCode } });
  };
};

export const setFiltersValue = (filtersValue) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_FILTER, payload: { filtersValue } });
  };
};

export const setResetFilters = (reset) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_RESET_FILTERS, payload: { reset } });
  };
};

export const setSearchText = (searchText) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_SEARCH_TEXT, payload: { searchText } });
  };
};

export const setHasCriteriaChanged = (hasCriteriaChanged) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CRITERIA_CHANGED,
      payload: hasCriteriaChanged,
    });
  };
};

export const getSortColumn = (state) =>
  state.criticalparts.data.sortColumn ?? initialState.data.sortColumn;

export const getSortReverse = (state) =>
  state.criticalparts.data.sortReverse ?? initialState.data.sortReverse;

export const getSelectedLocationCode = (state) =>
  state.criticalparts.data.selectedLocationCode;

export const getFiltersValue = (state) => state.criticalparts.data.filtersValue;

export const getResetFilters = (state) => state.criticalparts.data.resetFilters;

export const getSearchText = (state) => state.criticalparts.data.searchText;

export const getHasCriteriaChanged = (state) =>
  state.criticalparts.data.hasSearchCriteriaChanged;

const initialState = {
  data: {
    sortColumn: CP_SORT_BY_RUN_OUT_BY,
    sortReverse: CP_SORT_DIRECTION_ASCENDING,
    selectedLocationCode: null,
    resetFilters: false,
    searchText: "",
    hasSearchCriteriaChanged: false,
    filtersValue: {
      fuCodes: null,
    },
  },
};

const CriticalPartsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SAVE_SORT_COLUMN:
      return {
        ...state,
        data: {
          ...state.data,
          sortColumn: action.payload.sortColumn,
        },
      };

    case SAVE_SORT_REVERSE:
      return {
        ...state,
        data: {
          ...state.data,
          sortReverse: action.payload.sortReverse,
        },
      };

    case UPDATE_LOCATION:
      return {
        ...state,
        data: {
          ...state.data,
          selectedLocationCode: action.payload.locationCode,
        },
      };

    case UPDATE_FILTER:
      return {
        ...state,
        data: {
          ...state.data,
          filtersValue: action.payload.filtersValue,
        },
      };

    case UPDATE_RESET_FILTERS:
      return {
        ...state,
        data: {
          ...state.data,
          resetFilters: action.payload.reset,
        },
      };

    case UPDATE_CRITERIA_CHANGED:
      return {
        ...state,
        data: {
          ...state.data,
          hasSearchCriteriaChanged: action.payload,
        },
      };

    case UPDATE_SEARCH_TEXT:
      return {
        ...state,
        data: {
          ...state.data,
          searchText: action.payload.searchText,
        },
      };

    default:
      return state;
  }
};

const CriticalPartsState = {
  mountPoint: STORE_MOUNT_POINT,
  actionTypes: {},
  actionCreators: {
    setSortColumn,
    setSortReverse,
    setSelectedLocationCode,
    setFiltersValue,
    setResetFilters,
    setHasCriteriaChanged,
    setSearchText,
  },
  selectors: {
    getSortColumn,
    getSortReverse,
    getSelectedLocationCode,
    getFiltersValue,
    getResetFilters,
    getSearchText,
    getHasCriteriaChanged,
  },
  reducer: CriticalPartsReducer,
};

export default CriticalPartsState;
