/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

// Components
import { MediaQueries } from "components/responsive";
import { SavedSearchPanel } from "components/organisms/SavedSearchPanel.organism";
import { DonutChart } from "components/molecules/DonutChart.molecule";
import { ExceptionCountGroup } from "components/molecules/ExceptionCountGroup.molecule";

// Utils
import { getIconData } from "pages/vinview/utils/exceptions.utils";
import { getExceptionChartData } from "components/utils/exceptions.utils";

// State
import VinViewSearchBarState from "../../../redux/VinViewSearchBarState";
import VinViewSavedSearchState from "../../../redux/VinViewSavedSearchState";
import VinViewSavedSearchCardsState from "../../../redux/VinViewSavedSearchCardsState";
import VinViewDomainData from "modules/domain-data/VinViewDomainData";

const { searchEntities, setSearchFilter } =
  VinViewSearchBarState.actionCreators;
const { loadSavedSearch } = VinViewSavedSearchState.actionCreators;

export const VinViewSavedSearch = ({
  savedSearch,
  onEditClick,
  isDeleting,
  isDealerOrg = true,
  selectedDealerOrgId = null,
}) => {
  const { t } = useTranslation("fv-dashboard");
  const dispatch = useDispatch();

  const savedSearchData = useSelector(
    VinViewSavedSearchCardsState.selectors.getSavedSearchCardData(
      savedSearch?.id,
    ),
  );
  const lifeCycleStateFilterOptions = useSelector(
    VinViewDomainData.selectors.getLifeCycleStates,
  );
  const showCompleteCountInWidget = _.includes(
    lifeCycleStateFilterOptions,
    "Complete",
  );

  const isLoading = savedSearchData?.isLoading ?? true;
  const data = savedSearchData?.data;

  useEffect(() => {
    if (isDealerOrg || (!isDealerOrg && selectedDealerOrgId)) {
      dispatch(
        VinViewSavedSearchCardsState.actionCreators.fetchSavedSearchCardData(
          savedSearch,
          isDealerOrg,
          selectedDealerOrgId,
        ),
      );
    }
  }, [dispatch, savedSearch, isDealerOrg, selectedDealerOrgId]);

  const loadFullSavedSearch = () => {
    dispatch(loadSavedSearch(savedSearch));
    dispatch(searchEntities());
  };

  let loadActiveSavedSearch = () => {
    dispatch(loadSavedSearch(savedSearch));
    // The graph displays the Active VIN count
    dispatch(setSearchFilter("lifeCycleState", ["Active"]));
    if (savedSearch?.search?.isNew === "prebuilt") {
      dispatch(setSearchFilter("isNew", "prebuilt"));
    } else if (savedSearch?.search?.isNew === "all_active") {
      dispatch(setSearchFilter("isNew", "all_active"));
    }
    dispatch(searchEntities());
  };

  let loadDeliveredSavedSearch = () => {
    dispatch(loadSavedSearch(savedSearch));
    dispatch(setSearchFilter("lifeCycleState", ["Delivered"]));
    dispatch(searchEntities());
  };

  let loadCompleteSavedSearch = () => {
    dispatch(loadSavedSearch(savedSearch));
    dispatch(setSearchFilter("lifeCycleState", ["Complete"]));
    dispatch(searchEntities());
  };

  // Disable specific lifeCylceState search when lifeCycleState is not selected
  const lifeCycleStateHandler = {
    Active: loadActiveSavedSearch,
    Delivered: loadDeliveredSavedSearch,
    Complete: loadCompleteSavedSearch,
  };

  const getLifeCycleStateHandler = (state) => {
    return _.includes(savedSearch.search.lifeCycleState, state)
      ? lifeCycleStateHandler[state]
      : null;
  };

  if (savedSearch.search.lifeCycleState?.length > 0) {
    loadActiveSavedSearch = getLifeCycleStateHandler("Active");
    loadDeliveredSavedSearch = getLifeCycleStateHandler("Delivered");
    loadCompleteSavedSearch = getLifeCycleStateHandler("Complete");
  }

  const deliveredData = [
    {
      name: t("fv-dashboard:Delivered"),
      count: data?.deliveredCount ?? 0,
      icon: {
        ...getIconData("Delivered"),
      },
      tooltipText: t(
        "vinview-dashboard:VINs delivered in the last [[[count]]] days or awaiting additional action",
        {
          count: 30,
        },
      ),
    },
  ];

  const completeData = [
    {
      name: t("fv-dashboard:Complete"),
      count: data?.completeCount ?? 0,
      icon: {
        ...getIconData("Complete"),
      },
      tooltipText: t("vinview-dashboard:VINs Completed in the last 30 days"),
    },
  ];

  const chartData = getExceptionChartData([], data?.activeCount, 0);

  return (
    <SavedSearchPanel
      savedSearch={savedSearch}
      onSearchClick={loadFullSavedSearch}
      onEditClick={onEditClick}
      isLoading={isLoading}
      isDeleting={isDeleting}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          [MediaQueries.mediumAndUp]: {
            flexDirection: "column-reverse",
            justifyContent: "between",
          },
          [MediaQueries.extraLarge]: {
            flexDirection: "row",
            justifyContent: "space-around",
          },
          // This prevents edge cases when it starts getting too constrained
          // we get a little bit of a scrollbar right before hiding the sidebar
          // the graph could get cut off but it only affects a small range
          overflow: "hidden",
        }}
      >
        <DonutChart
          data={chartData}
          handler={loadActiveSavedSearch}
          totalLabel={t("fv-dashboard:Active")}
          total={data?.activeCount}
          showNumberSeparator={true}
        />
        <ExceptionCountGroup
          exceptions={deliveredData}
          clickHandler={loadDeliveredSavedSearch}
          showNumberSeparator={true}
        />
        {showCompleteCountInWidget ? (
          <ExceptionCountGroup
            exceptions={completeData}
            clickHandler={loadCompleteSavedSearch}
            showNumberSeparator={true}
          />
        ) : null}
      </div>
    </SavedSearchPanel>
  );
};

VinViewSavedSearch.propTypes = {
  // These come from the getCardProps in FinishedVehicleSavedSearchesPanel
  savedSearch: PropTypes.object,
  onEditClick: PropTypes.func,
  isDeleting: PropTypes.bool,
  isDealerOrg: PropTypes.bool,
  selectedDealerOrgId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};
