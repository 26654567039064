import axios from "axios";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { createSelector } from "reselect";
import apiUrl from "api-url";
import SurgicalToteTrackingSearchBarState from "../redux/SurgicalToteTrackingSearchBarState";
import ApplicationConfig from "application-config";
import { Features } from "modules/auth/Authorization";

const STORE_MOUNT_POINT = "surgicalToteTrackingDetailsWidget";

//URLs
const APPLICATION_BASE_URL = apiUrl("/containertracking/api");
const surgicalToteDetailsUrl = (id) =>
  APPLICATION_BASE_URL + `/reuse-trip-container-details/${id}`;

const surgicalTotePositionUpdateUrl = (id) =>
  APPLICATION_BASE_URL + `/${id}/position-updates`;

const surgicalToteActiveExceptions = (id) =>
  apiUrl(`/containertracking/api/reuse-trip-container/${id}/exceptions`);

const surgicalToteHistoryApiUrl = (id) =>
  APPLICATION_BASE_URL + `/containerhistory/${id}`;

const TOTE_IMAGE_BASE_URL = ApplicationConfig.isEnvironment("proda", "prod")
  ? "https://prod-b-fv-container-tracking-media.s3.us-east-1.amazonaws.com"
  : "https://fv-container-tracking-media.s3.amazonaws.com";

const watchToteUrl = (id) => APPLICATION_BASE_URL + `/watch/${id}`;

const axiosConfig = () => {
  return {
    headers: {
      "x-target-feature": Features.SURGICAL_TOTE_TRACKING,
    },
  };
};

//Action Types
const REQUEST_TOTE_DETAILS = "REQUEST_TOTE_DETAILS";
const RECEIVE_TOTE_DETAILS = "RECEIVE_TOTE_DETAILS";
const REQUEST_TOTE_ACTIVE_EXCEPTIONS = "REQUEST_TOTE_ACTIVE_EXCEPTIONS";
const RECEIVE_TOTE_ACTIVE_EXCEPTIONS = "RECEIVE_TOTE_ACTIVE_EXCEPTIONS";
const RECEIVE_TOTE_MEDIA = "RECEIVE_TOTE_MEDIA";
const CLEAR_TOTE_MEDIA = "CLEAR_TOTE_MEDIA";
const REQUEST_TOTE_POSITION_UPDATES = "REQUEST_TOTE_POSITION_UPDATES";
const REQUEST_TOTE_LOCATION_DETAILS = "REQUEST_TOTE_LOCATION_DETAILS";
const RECEIVE_TOTE_OTHER_ALL = "RECEIVE_TOTE_OTHER_ALL";
const REQUEST_TOTE_SUPPLIER_LOCATION_DETAILS =
  "REQUEST_TOTE_SUPPLIER_LOCATION_DETAILS";
const RETRIEVE_TOTE_SUPPLIER_LOCATION_DETAILS =
  "RETRIEVE_TOTE_SUPPLIER_LOCATION_DETAILS";
const CLEAR_TOTE_DETAILS = "CLEAR_TOTE_DETAILS";
const REQUEST_TOTE_HISTORY = "REQUEST_TOTE_HISTORY";
const RETRIEVE_TOTE_HISTORY = "RETRIEVE_TOTE_HISTORY";
const TOTE_UPDATE_SUCCESS = "TOTE_UPDATE_SUCCESS";
const FETCH_TOTE_COMMENTS = `${STORE_MOUNT_POINT}/FETCH_TOTE_COMMENTS`;
const FETCH_TOTE_COMMENTS_FAILED = `${STORE_MOUNT_POINT}/FETCH_TOTE_COMMENTS_FAILED`;
const RECEIVE_TOTE_COMMENTS = `${STORE_MOUNT_POINT}/RECEIVE_TOTE_COMMENTS`;
const SUBMIT_NEW_COMMENT = `${STORE_MOUNT_POINT}/SUBMIT_NEW_COMMENT`;
const RECEIVE_NEW_COMMENT = `${STORE_MOUNT_POINT}/RECEIVE_NEW_COMMENT`;
const SUBMIT_NEW_COMMENT_FAILED = `${STORE_MOUNT_POINT}/SUBMIT_NEW_COMMENT_FAILED`;
const CANCEL_NEW_COMMENT = `${STORE_MOUNT_POINT}/CANCEL_NEW_COMMENT`;
const SET_IS_UPDATING_COMMENT = `${STORE_MOUNT_POINT}/SET_IS_UPDATING_COMMENT`;
const SET_IS_UPDATING_COMMENT_FAILED = `${STORE_MOUNT_POINT}/SET_IS_UPDATING_COMMENT_FAILED`;
const CANCEL_UPDATE_COMMENT = `${STORE_MOUNT_POINT}/CANCEL_UPDATE_COMMENT`;
const SUBMIT_BATCH_COMMENTS = `${STORE_MOUNT_POINT}/SUBMIT_BATCH_COMMENTS`;
const SUBMIT_BATCH_COMMENTS_SUCCESS = `${STORE_MOUNT_POINT}/SUBMIT_BATCH_COMMENTS_SUCCESS`;
const SUBMIT_BATCH_COMMENTS_FAILED = `${STORE_MOUNT_POINT}/SUBMIT_BATCH_COMMENTS_FAILED`;
const SUBMIT_BATCH_COMMENTS_RESET = `${STORE_MOUNT_POINT}/SUBMIT_BATCH_COMMENTS_RESET`;
const START_BOTH_DETAILS_LOADING = "START_BOTH_DETAILS_LOADING";
const STOP_BOTH_DETAILS_LOADING = "STOP_BOTH_DETAILS_LOADING";

//Action Creators

//Tote tracking comments feed actions

const fetchComments = (toteId, pageNumber, pageSize) => {
  let url = `${APPLICATION_BASE_URL}/${toteId}/comment`;
  if (pageNumber && pageSize) {
    url += `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  return (dispatch) => {
    let clearData = false;
    if (pageNumber === 0) {
      clearData = true;
      dispatch({
        type: FETCH_TOTE_COMMENTS,
      });
    }

    const config = axiosConfig();
    return axios
      .get(url, config)
      .then((response) => {
        dispatch({
          type: RECEIVE_TOTE_COMMENTS,
          payload: { comments: response.data, clearData },
        });
      })
      .catch((err) => {
        console.log(err);

        dispatch({
          type: FETCH_TOTE_COMMENTS_FAILED,
        });
      });
  };
};

function addComment(toteId, data) {
  let url = `${APPLICATION_BASE_URL}/${toteId}/comment`;

  return (dispatch) => {
    const fakeCommentId = uuidv4();
    dispatch({
      type: SUBMIT_NEW_COMMENT,
      payload: { data: { ...data, id: fakeCommentId, isAdding: true } },
    });

    const requestData = { text: data.text, shared_with: data.shared_with };
    const config = axiosConfig();

    return axios
      .post(url, requestData, config)
      .then((response) => {
        dispatch({
          type: RECEIVE_NEW_COMMENT,
          // Force the new comment to be marked as read
          payload: { data: { ...response.data, read: true }, fakeCommentId },
        });
      })
      .catch((err) => {
        dispatch({
          type: SUBMIT_NEW_COMMENT_FAILED,
          payload: { fakeCommentId },
        });
      });
  };
}

function cancelAddComment(fakeCommentId) {
  return (dispatch) => {
    dispatch({
      type: CANCEL_NEW_COMMENT,
      payload: { fakeCommentId },
    });
  };
}

function addBatchComments(data, isCsvFormat, solutionId) {
  let url = `${APPLICATION_BASE_URL}/solution/${solutionId}/comment/batch`;
  const { shareableOrg, shipperOrgId } = data;
  let params = {};
  if (isCsvFormat) {
    params.sharedWith = shareableOrg ? `[${shareableOrg}]` : "[]";
    if (shipperOrgId) {
      params.shipperOrgId = shipperOrgId;
    }
  }

  const postData = isCsvFormat ? data.csv : data;

  return (dispatch) => {
    const headers = {
      "content-type": isCsvFormat ? "text/csv" : "application/json",
      "x-target-feature": Features.SURGICAL_TOTE_TRACKING,
    };

    dispatch({
      type: SUBMIT_BATCH_COMMENTS,
      payload: { data },
    });
    return axios
      .post(url, postData, { headers, params })
      .then((response) => {
        dispatch({
          type: SUBMIT_BATCH_COMMENTS_SUCCESS,
          payload: { data },
        });
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: SUBMIT_BATCH_COMMENTS_FAILED,
          payload: { data },
        });
      });
  };
}

function clearBatchComments() {
  return (dispatch) => {
    dispatch({ type: SUBMIT_BATCH_COMMENTS_RESET });
  };
}

function updateComment(toteId, commentId, updatedData) {
  let url = `${APPLICATION_BASE_URL}/${toteId}/comment/${commentId}`;

  return (dispatch) => {
    dispatch({
      type: SET_IS_UPDATING_COMMENT,
      payload: { isUpdating: true, commentId, updatedData },
    });

    const requestData = {
      text: updatedData.text,
      shared_with: updatedData.shared_with,
    };
    const config = axiosConfig();

    return axios
      .patch(url, requestData, config)
      .then((response) => {
        dispatch({
          type: SET_IS_UPDATING_COMMENT,
          payload: { isUpdating: false, commentId, updatedData: response.data },
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_IS_UPDATING_COMMENT_FAILED,
          payload: { commentId },
        });
      });
  };
}

function cancelUpdateComment(commentId) {
  return (dispatch) => {
    dispatch({
      type: CANCEL_UPDATE_COMMENT,
      payload: { commentId },
    });
  };
}

function markCommentsRead(toteId, datetime) {
  let url = `${APPLICATION_BASE_URL}/${toteId}/comment/read`;

  // Pass in the current date and time as the last read date
  const data = {
    date_until: moment.utc(datetime).format("YYYY-MM-DDTHH:mm:ss.SSS"),
  };

  return (dispatch) => {
    const config = axiosConfig();
    return axios
      .post(url, data, config)
      .then((response) => {
        // Do nothing
      })
      .catch((err) => {
        console.error(err);
      });
  };
}
const fetchToteDetails = (id) => {
  return async (dispatch) => {
    dispatch({ type: REQUEST_TOTE_DETAILS });
    dispatch({ type: REQUEST_TOTE_LOCATION_DETAILS });
    dispatch({ type: REQUEST_TOTE_POSITION_UPDATES });
    dispatch({ type: START_BOTH_DETAILS_LOADING });
    let details = {};
    const config = axiosConfig();
    // Fetch and load details first
    try {
      details = await axios.get(surgicalToteDetailsUrl(id), config);
      dispatch({
        type: RECEIVE_TOTE_DETAILS,
        payload: details.data,
      });
      if (!details.data?.status || details.data?.status !== "In Transit") {
        dispatch({ type: STOP_BOTH_DETAILS_LOADING });
      }
    } catch (error) {
      console.log(error);
    }
    // Fetch position updates & location details
    try {
      const promisesArray = [];
      if (details.data && details.data.id) {
        promisesArray.push(
          axios.get(surgicalTotePositionUpdateUrl(details.data.id), config),
        );
      } else {
        promisesArray.push(Promise.reject());
      }
      if (details.data && details.data.alternateLocationCode) {
        promisesArray.push(
          axios.get(
            `${apiUrl("/location/locations")}?code=${
              details.data.alternateLocationCode
            }`,
          ),
        );
      } else {
        promisesArray.push(Promise.reject());
      }
      Promise.all([
        promisesArray[0].catch(() => {
          return { data: [] };
        }),
        promisesArray[1].catch(() => {
          return { data: [] };
        }),
      ]).then(([positionUpdatesRep, locationDetailsResp]) => {
        dispatch({
          type: RECEIVE_TOTE_OTHER_ALL,
          payload: {
            locationDetails:
              locationDetailsResp.data &&
              locationDetailsResp.data.length &&
              locationDetailsResp.data[0],
            positionUpdates: positionUpdatesRep.data,
          },
        });
      });
    } catch (error) {
      console.log(error);
    }
  };
};

const fetchToteSupplierLocationDetails = (code) => {
  const url = `${apiUrl("/location/locations")}?code=${code}`;
  return async (dispatch) => {
    dispatch({ type: REQUEST_TOTE_SUPPLIER_LOCATION_DETAILS });
    try {
      const response = await axios.get(url);
      dispatch({
        type: RETRIEVE_TOTE_SUPPLIER_LOCATION_DETAILS,
        payload: response.data?.[0] ?? {},
      });
    } catch (err) {
      console.log(err);
    }
  };
};

const fetchToteHistory = (id) => {
  const url = surgicalToteHistoryApiUrl(id);
  return async (dispatch) => {
    dispatch({ type: REQUEST_TOTE_HISTORY });
    try {
      const config = axiosConfig();
      const response = await axios.get(url, config);

      dispatch({
        type: RETRIEVE_TOTE_HISTORY,
        payload: response.data?.data ?? [],
      });
    } catch (err) {
      console.log(err);
    }
  };
};

const fetchToteActiveExceptions = (id) => {
  const url = surgicalToteActiveExceptions(id);
  const params = { activeOnly: true };
  return async (dispatch) => {
    const headers = { "x-target-feature": Features.SURGICAL_TOTE_TRACKING };
    dispatch({ type: REQUEST_TOTE_ACTIVE_EXCEPTIONS });
    try {
      const response = await axios.get(url, { headers, params });
      dispatch({
        type: RECEIVE_TOTE_ACTIVE_EXCEPTIONS,
        payload: response.data ?? [],
      });
    } catch (err) {
      console.log(err);
    }
  };
};

const clearToteDetails = () => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_TOTE_DETAILS });
  };
};

function clearToteMedia() {
  return (dispatch) =>
    dispatch({
      type: CLEAR_TOTE_MEDIA,
    });
}

function fetchToteMedia(type, solutionId) {
  if (!type || !solutionId) {
    return clearToteMedia();
  }

  // replace spaces with "+" for image names
  type = type?.replace(/ /g, "+");

  return (dispatch) => {
    dispatch({
      type: RECEIVE_TOTE_MEDIA,
      payload: {
        toteMedia: `${TOTE_IMAGE_BASE_URL}/${solutionId}/${type}.jpg`,
      },
    });
  };
}

const watchToteActions = (id, watch = true) => {
  const url = watchToteUrl(id);
  return async (dispatch) => {
    try {
      const config = axiosConfig();
      watch
        ? await axios.post(url, {}, config)
        : await axios.delete(url, config);
      dispatch({ type: TOTE_UPDATE_SUCCESS });
      dispatch(
        SurgicalToteTrackingSearchBarState.actionCreators.searchEntities(
          null,
          false,
          true,
        ),
      );
    } catch (err) {
      console.log(
        "Error in SurgicalToteTrackingDetailsWidgetState: watchToteActions",
      );
    }
  };
};

//Selectors
const getToteDetails = (state) => state[STORE_MOUNT_POINT].toteDetails;

const getToteDetailsLoadingFlag = (state) =>
  state[STORE_MOUNT_POINT].isToteDetailsLoading;

const getToteMedia = (state) => state[STORE_MOUNT_POINT].toteMedia;

const translatePositionUpdates = (item) => {
  // only recalculate when totePositionUpdates changes
  return {
    db_time: item.receivedTime,
    city: item.locationCity,
    country: item.locationCountry,
    latitude: item.latitude,
    locationName: item.locationName,
    locationCode: item.locationCode,
    longitude: item.longitude,
    state: item.locationState,
    time: item.ts,
  };
};

const getTotePositionUpdates = createSelector(
  [(state) => state[STORE_MOUNT_POINT].totePositionUpdates],
  (totePositionUpdates) => {
    if (totePositionUpdates) {
      return _.orderBy(totePositionUpdates, ["ts"], ["desc"]).map(
        translatePositionUpdates,
      );
    }
    return [];
  },
);

const getLocationDetails = (state) =>
  state[STORE_MOUNT_POINT].toteLocationDetails;

const getLocationDetailsLoadingFlag = (state) =>
  state[STORE_MOUNT_POINT].isToteLocationDetailsLoading;

const getSupplierLocationDetails = (state) =>
  state[STORE_MOUNT_POINT].toteSupplierLocationDetails;

const getSupplierLocationDetailsLoadingFlag = (state) =>
  state[STORE_MOUNT_POINT].isToteSupplierLocationDetailsLoading;

const getToteHistory = (state) => state[STORE_MOUNT_POINT].toteHistory;

const getSTTActiveExceptions = (state) => {
  if (state[STORE_MOUNT_POINT].ctActiveExceptions) {
    return state[STORE_MOUNT_POINT].ctActiveExceptions.map((row) => {
      if (row?.comments?.includes("container")) {
        row.comments = row.comments.replaceAll("container", "tote");
      }
      if (row?.comments?.includes("Container")) {
        row.comments = row.comments.replaceAll("Container", "Tote");
      }
      return row;
    });
  }
  return state[STORE_MOUNT_POINT].ctActiveExceptions;
};

const getBothDetailsLoadingFlag = (state) => {
  return state[STORE_MOUNT_POINT].areBothDetailsLoading;
};

const getIsFetchingComments = (state) =>
  state[STORE_MOUNT_POINT].isFetchingComments;
const getComments = (state) => state[STORE_MOUNT_POINT].comments || [];

const getIsBatchCommentInProgress = (state) =>
  state[STORE_MOUNT_POINT].isBatchCommentInProgress;
const getIsBatchCommentSuccessful = (state) =>
  state[STORE_MOUNT_POINT].isBatchCommentSuccessful;
const getIsBatchCommentFailed = (state) =>
  state[STORE_MOUNT_POINT].isBatchCommentFailed;

//Initial State
const initialState = {
  toteDetails: {},
  isToteDetailsLoading: false,
  toteMedia: null,
  isTotePositionUpdatesLoading: false,
  totePositionUpdates: [],
  isToteLocationDetailsLoading: false,
  toteLocationDetails: {},
  isToteSupplierLocationDetailsLoading: false,
  toteSupplierLocationDetails: {},
  isToteHistoryLoading: false,
  toteHistory: [],
  sttActiveExceptions: [],
  areBothDetailsLoading: false,
  isFetchingComments: false,
  comments: {
    totalPages: 0,
    totalCount: 0,
    totalCountUnread: 0,
    data: [],
  },
  isBatchCommentInProgress: false,
  isBatchCommentSuccessful: false,
  isBatchCommentFailed: false,
};

//Reducer
const SurgicalToteTrackingDetailsWidgetReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case REQUEST_TOTE_DETAILS:
      return {
        ...state,
        isToteDetailsLoading: true,
      };

    case RECEIVE_TOTE_DETAILS:
      return {
        ...state,
        toteDetails: action.payload,
        isToteDetailsLoading: false,
      };

    case RECEIVE_TOTE_MEDIA:
      return {
        ...state,
        toteMedia: action.payload.toteMedia,
      };

    case CLEAR_TOTE_MEDIA:
      return {
        ...state,
        toteMedia: null,
      };

    case REQUEST_TOTE_SUPPLIER_LOCATION_DETAILS:
      return {
        ...state,
        isToteSupplierLocationDetailsLoading: true,
      };

    case RETRIEVE_TOTE_SUPPLIER_LOCATION_DETAILS:
      return {
        ...state,
        toteSupplierLocationDetails: action.payload,
        isToteSupplierLocationDetailsLoading: false,
      };

    case REQUEST_TOTE_LOCATION_DETAILS:
      return {
        ...state,
        isToteLocationDetailsLoading: true,
      };

    case REQUEST_TOTE_POSITION_UPDATES:
      return {
        ...state,
        isTotePositionUpdatesLoading: true,
      };

    case RECEIVE_TOTE_OTHER_ALL:
      return {
        ...state,
        toteLocationDetails: action.payload.locationDetails ?? {},
        totePositionUpdates: action.payload.positionUpdates ?? [],
        isTotePositionUpdatesLoading: false,
        isToteLocationDetailsLoading: false,
      };

    case REQUEST_TOTE_HISTORY:
      return {
        ...state,
        isToteHistoryLoading: true,
      };

    case RETRIEVE_TOTE_HISTORY:
      return {
        ...state,
        toteHistory: action.payload,
      };

    case REQUEST_TOTE_ACTIVE_EXCEPTIONS:
      return {
        ...state,
        isToteDetailsLoading: true,
      };

    case RECEIVE_TOTE_ACTIVE_EXCEPTIONS:
      return {
        ...state,
        ctActiveExceptions: action.payload,
        isToteDetailsLoading: false,
      };

    case CLEAR_TOTE_DETAILS:
      return { ...initialState };

    case STOP_BOTH_DETAILS_LOADING:
      return {
        ...state,
        areBothDetailsLoading: false,
      };

    case FETCH_TOTE_COMMENTS:
      return {
        ...state,
        isFetchingComments: true,
        comments: initialState.comments,
      };

    case FETCH_TOTE_COMMENTS_FAILED:
      return {
        ...state,
        isFetchingComments: false,
      };

    case RECEIVE_TOTE_COMMENTS:
      return {
        ...state,
        isFetchingComments: false,
        comments: {
          ...action.payload.comments,
          // The infinite-scrolling package requires the data to be consolidated,
          // not just the current page. So do a union of the new data with the old data.
          // Ignore the above if this is the first page of data.
          data: action.payload.clearData
            ? action.payload.comments.data
            : _.union(state.comments.data, action.payload.comments.data),
        },
      };

    case SUBMIT_NEW_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: [action.payload.data].concat(state.comments.data),
        },
      };

    case RECEIVE_NEW_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          // Manually increase the total count of comments
          totalCount: state.comments.totalCount + 1,
          // If this is the first comment, update the number of pages to 1
          totalPages:
            state.comments.totalPages === 0
              ? state.comments.totalPages + 1
              : state.comments.totalPages,
          data: state.comments.data.map((c) =>
            c.id === action.payload.fakeCommentId ? action.payload.data : c,
          ),
        },
      };

    case SUBMIT_NEW_COMMENT_FAILED:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: state.comments.data.map((c) =>
            c.id === action.payload.fakeCommentId
              ? { ...c, isAdding: false, isAddingFailed: true }
              : c,
          ),
        },
      };

    case CANCEL_NEW_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: state.comments.data.filter(
            (c) => c.id !== action.payload.fakeCommentId,
          ),
        },
      };

    case SET_IS_UPDATING_COMMENT: {
      let commentList = state.comments.data ? [...state.comments.data] : [];
      if (action.payload.updatedData) {
        const updatedData = action.payload.updatedData;
        commentList = commentList.map((c) =>
          c.id === action.payload.commentId
            ? {
                ...c,
                ...updatedData,
                // Keep track of the original data in case we need to cancel the update.
                // Gets cleared on success.
                original: action.payload.isUpdating ? c : null,
              }
            : c,
        );
      }

      commentList = commentList.map((c) =>
        c.id === action.payload.commentId
          ? {
              ...c,
              isUpdating: action.payload.isUpdating,
              isUpdatingFailed: false,
            }
          : c,
      );

      return {
        ...state,
        comments: {
          ...state.comments,
          data: commentList,
        },
      };
    }

    case SET_IS_UPDATING_COMMENT_FAILED: {
      let commentList = state.comments.data ? [...state.comments.data] : [];

      commentList = commentList.map((c) =>
        c.id === action.payload.commentId
          ? {
              ...c,
              isUpdating: false,
              isUpdatingFailed: true,
            }
          : c,
      );

      return {
        ...state,
        comments: { ...state.comments, data: commentList },
      };
    }

    case CANCEL_UPDATE_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: state.comments.data.map((c) =>
            c.id === action.payload.commentId
              ? { ...c.original, isUpdating: false, isUpdatingFailed: false }
              : c,
          ),
        },
      };

    case SUBMIT_BATCH_COMMENTS:
      return {
        ...state,
        isBatchCommentInProgress: true,
      };

    case SUBMIT_BATCH_COMMENTS_SUCCESS:
      return {
        ...state,
        isBatchCommentInProgress: false,
        isBatchCommentSuccessful: true,
      };

    case SUBMIT_BATCH_COMMENTS_FAILED:
      return {
        ...state,
        isBatchCommentInProgress: false,
        isBatchCommentFailed: true,
      };

    case SUBMIT_BATCH_COMMENTS_RESET:
      return {
        ...state,
        isBatchCommentInProgress: false,
        isBatchCommentSuccessful: false,
        isBatchCommentFailed: false,
      };

    default:
      return state;
  }
};

//interface
export const SurgicalToteTrackingDetailsWidgetState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    clearToteMedia,
    fetchToteDetails,
    fetchToteActiveExceptions,
    fetchToteMedia,
    fetchToteSupplierLocationDetails,
    fetchToteHistory,
    clearToteDetails,
    watchToteActions,
    fetchComments,
    addComment,
    addBatchComments,
    clearBatchComments,
    cancelAddComment,
    updateComment,
    cancelUpdateComment,
    markCommentsRead,
  },
  selectors: {
    getToteDetails,
    getToteDetailsLoadingFlag,
    getToteMedia,
    getTotePositionUpdates,
    getLocationDetailsLoadingFlag,
    getLocationDetails,
    getSupplierLocationDetails,
    getSupplierLocationDetailsLoadingFlag,
    getToteHistory,
    getSTTActiveExceptions,
    getBothDetailsLoadingFlag,
    getIsFetchingComments,
    getComments,
    getIsBatchCommentInProgress,
    getIsBatchCommentSuccessful,
    getIsBatchCommentFailed,
  },
  reducer: SurgicalToteTrackingDetailsWidgetReducer,
};
