import { connect } from "react-redux";

import { FinishedVehicleDetails } from "./FinishedVehicle.Details.page";
import FinVehicleEntityDetailsState from "../redux/FinVehicleEntityDetailsState";
import FinVehicleTripLegState from "../redux/FinVehicleTripLegState";
import MapState from "modules/map/MapState";
import ShipmentsStatusState from "pages/shipments/redux/ShipmentsState";
import {
  getSolutionId,
  getActiveOrganization,
} from "modules/organizations/OrganizationsState";
import FinVehicleSubscriptionState from "pages/finishedvehicle/redux/FinVehicleSubscriptionState";
import DomainDataState from "modules/domain-data/DomainDataState";
import { getCombinedTripLegs } from "../redux/TripProgressSelectors";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import { MapCoordinateType } from "modules/map/components/map-coordinate-definition";
import FinVehicleEntityHistoricalState from "../redux/FinVehicleEntityHistoricalState";
import NotificationsState from "modules/notifications/NotificationsState";
import ErrorState from "pages/error/redux/Error.state";

function mapStateToProps(state) {
  const { entity_id: entityId } = state.location.payload;

  const {
    getEntityDetails,
    getEntityDetailsError,
    getEntityPositionUpdates,
    getEntityConnectedCarPositionUpdates,
    getCombinedEntityExceptions,
    getEntityCurrentLocation,
    getIsFetchingComments,
    getComments,
    getEntityEvents,
    getEntityExceptions,
    getEntityHolds,
    getEntityMedia,
    getIsSharingVin,
    getIsShareVinSuccessful,
    getIsShareVinFailed,
    getIsBatchCommentInProgress,
    getIsBatchCommentSuccessful,
    getIsBatchCommentFailed,
    getEntityStateChangeTs,
  } = FinVehicleEntityDetailsState.selectors;

  const { getHistoryByQualifier } = FinVehicleEntityHistoricalState.selectors;

  const { getPlannedTripLeg, getActualTripLeg, getCarrierFvIdsFromTripPlan } =
    FinVehicleTripLegState.selectors;

  const { getShipmentDetails, getChildShipmentDetails, getIsLoaded } =
    ShipmentsStatusState.selectors;

  const { getMapCoordinate } = MapState.selectors;

  return {
    solutionId: getSolutionId(state),
    entityId,
    details: getEntityDetails(state),
    detailsError: getEntityDetailsError(state),
    coords: getEntityPositionUpdates(state),
    connectedCarCoords: getEntityConnectedCarPositionUpdates(state),
    plannedLegs: getPlannedTripLeg(state),
    actualLegs: getActualTripLeg(state),
    combinedLegs: getCombinedTripLegs(state),
    shipmentModes: DomainDataState.selectors.getShipmentModes(state),
    parentShipment: getShipmentDetails(state),
    childShipments: getChildShipmentDetails(state),
    shipmentsLoaded: getIsLoaded(state),
    combinedExceptions: getCombinedEntityExceptions(state),
    currentLocation: getEntityCurrentLocation(state),
    authorization: getAuthorization(state),
    events: getEntityEvents(state),
    exceptions: getEntityExceptions(state),
    holds: getEntityHolds(state),
    media: getEntityMedia(state),
    shareableCarrierFvIds: getCarrierFvIdsFromTripPlan(state),
    // Comments
    isFetchingComments: getIsFetchingComments(state),
    comments: getComments(state),
    isFetchingShipmentComments:
      ShipmentsStatusState.selectors.getIsFetchingComments(state),
    shipmentComments: ShipmentsStatusState.selectors.getComments(state),
    isBatchCommentInProgress: getIsBatchCommentInProgress(state),
    isBatchCommentSuccessful: getIsBatchCommentSuccessful(state),
    isBatchCommentFailed: getIsBatchCommentFailed(state),
    isBatchShipmentCommentInProgress:
      ShipmentsStatusState.selectors.getIsBatchCommentInProgress(state),
    isBatchShipmentCommentSuccessful:
      ShipmentsStatusState.selectors.getIsBatchCommentSuccessful(state),
    isBatchShipmentCommentFailed:
      ShipmentsStatusState.selectors.getIsBatchCommentFailed(state),
    // Subscription
    vinSubscription:
      FinVehicleSubscriptionState.selectors.getSubscription(state),
    isVinSubscriptionLoading:
      FinVehicleSubscriptionState.selectors.getIsSubscriptionLoading(state),
    vinSubscriptionRequestError:
      FinVehicleSubscriptionState.selectors.getSubscriptionRequestError(state),
    isVinSubscriptionUpdating:
      FinVehicleSubscriptionState.selectors.getIsSubscriptionUpdating(state),
    vinSubscriptionUpdateSuccess:
      FinVehicleSubscriptionState.selectors.getSubscriptionUpdateSuccess(state),
    vinSubscriptionUpdateError:
      FinVehicleSubscriptionState.selectors.getSubscriptionUpdateError(state),
    // Share
    isSharingVin: getIsSharingVin(state),
    isShareVinSuccessful: getIsShareVinSuccessful(state),
    isShareVinFailed: getIsShareVinFailed(state),
    // Other
    activeOrganization: getActiveOrganization(state),
    // Map Coordinates
    selectedMapCoordinate: getMapCoordinate(
      MapCoordinateType.SELECTED_COORDINATE,
    )(state),
    selectedConnectedCarCoordinate: getMapCoordinate(
      MapCoordinateType.CURRENT_CONNECTED_CAR,
    )(state),
    currentMapCoordinate: getMapCoordinate(MapCoordinateType.CURRENT_LOCATION)(
      state,
    ),
    // Historical Data
    getHistoryByQualifier,
    getEntityStateChangeTs: getEntityStateChangeTs(state),
  };
}

const pushShipmentDetailView =
  (dispatch) => (creatorShipmentId, scac, vinNumber) => {
    dispatch({
      type: "SHIPMENT_DETAIL_FINISHED_VEHICLE",
      payload: {
        carrier_scac: scac,
        creator_shipment_id: creatorShipmentId,
        vin: vinNumber,
      },
    });
  };

const {
  fetchEntityExceptions,
  fetchEntityDetails,
  fetchEntityMedia,
  clearEntityMedia,
  fetchEntityHolds,
  fetchEntityPositionUpdates,
  fetchEntityEvents,
  fetchEntityStateHistory,
  setWatchEntity,
  fetchComments,
  addComment,
  addBatchComments,
  clearBatchComments,
  cancelAddComment,
  updateComment,
  cancelUpdateComment,
  markCommentsRead,
  shareVin,
  clearShareVin,
} = FinVehicleEntityDetailsState.actionCreators;
const { fetchHistoricalData } = FinVehicleEntityHistoricalState.actionCreators;
const { fetchPlannedTripLeg, fetchActualTripLeg } =
  FinVehicleTripLegState.actionCreators;
const { fetchLegShipmentDetails, clearLegShipmentDetails } =
  ShipmentsStatusState.actionCreators;
const {
  fetchSubscription: fetchVinSubscription,
  updateSubscription,
  subscribe,
  unsubscribe,
} = FinVehicleSubscriptionState.actionCreators;
const { fetchNotification } = NotificationsState.actionCreators;
const { addCoordinate, clearCoordinatesByType, clearAllCoordinates } =
  MapState.actionCreators;
const { redirectTo404 } = ErrorState.actionCreators;

const actionCreators = {
  fetchEntityPositionUpdates,
  fetchEntityMedia,
  clearEntityMedia,
  fetchEntityDetails,
  fetchEntityExceptions,
  fetchEntityHolds,
  fetchEntityEvents,
  fetchPlannedTripLeg,
  fetchActualTripLeg,
  fetchLegShipmentDetails,
  clearLegShipmentDetails,
  setWatchEntity,
  pushShipmentDetailView,
  // Map Coordinates
  addCoordinate,
  clearCoordinatesByType,
  clearAllCoordinates,
  // Historical Data
  fetchHistoricalData,
  fetchEntityStateHistory,
  // Comments
  fetchComments,
  addComment,
  addBatchComments,
  clearBatchComments,
  cancelAddComment,
  updateComment,
  cancelUpdateComment,
  markCommentsRead,
  fetchShipmentComments: ShipmentsStatusState.actionCreators.fetchComments,
  addShipmentComment: ShipmentsStatusState.actionCreators.addComment,
  cancelAddShipmentComment:
    ShipmentsStatusState.actionCreators.cancelAddComment,
  updateShipmentComment: ShipmentsStatusState.actionCreators.updateComment,
  cancelUpdateShipmentComment:
    ShipmentsStatusState.actionCreators.cancelUpdateComment,
  markShipmentCommentsRead:
    ShipmentsStatusState.actionCreators.markCommentsRead,
  addBatchShipmentComments:
    ShipmentsStatusState.actionCreators.addBatchComments,
  clearBatchShipmentComments:
    ShipmentsStatusState.actionCreators.clearBatchComments,
  fetchNotification,
  // Subscription
  fetchVinSubscription,
  updateSubscription,
  subscribe,
  unsubscribe,
  // Share
  shareVin,
  clearShareVin,
  // Error redirects
  redirectTo404,
};

export default connect(mapStateToProps, actionCreators)(FinishedVehicleDetails);
