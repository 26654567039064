import { connect } from "react-redux";

import { FilterSection } from "components/search-bar/FilterSection";
import SensoreOverViewSearchBarState from "pages/containertracking/redux/SensorOverviewSearchBarState";
import { FILTERS } from "pages/containertracking/search/SensorOverview/SensorOverview.searchOptions";
import { SensorOverviewDomainDataState } from "pages/containertracking/redux/SensorOverviewDomainDataState";

const mapStateToProps = (state) => ({
  searchFilters:
    SensoreOverViewSearchBarState.selectors.getSearchFilters(state),
  hasSearchCriteriaChanged:
    SensoreOverViewSearchBarState.selectors.getHasSearchCriteriaChanged(state),
  sensorStatusOptions:
    SensorOverviewDomainDataState.selectors.getSensorStatusOptions(state).data,
});

const {
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
} = SensoreOverViewSearchBarState.actionCreators;

const actionCreators = {
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSavedSearch,
  clearSavedSearch,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  filtersMetadata: FILTERS,
});

export default connect(
  mapStateToProps,
  actionCreators,
  mergeProps,
)(FilterSection);
