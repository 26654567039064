/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import { SavedSearchPanel } from "components/organisms/SavedSearchPanel.organism";
import { DonutChart } from "components/molecules/DonutChart.molecule";
import { ExceptionCountGroup } from "components/molecules/ExceptionCountGroup.molecule";

import {
  getExceptionsDataForWidget,
  getIconData,
} from "pages/partview/utils/exceptions.utils";
import { getExceptionChartData } from "components/utils/exceptions.utils";
import { MediaQueries } from "components/responsive";
import { FontSize } from "components/atoms/enums";
import DealerPartViewSearchBarState from "pages/partview/redux/DealerPartViewSearchBarState";
import PartViewDashboardState from "pages/partview/redux/PartViewDashboardState";
import DealerPartViewSavedSearchState from "pages/partview/redux/DealerPartViewSavedSearchState";
import DealerPartViewSavedSearchCardsState from "pages/partview/redux/DealerPartViewSavedSearchCardsState";
import { PackageStatusOption } from "pages/partview/utils/filter.utils";

const { searchEntities, setSearchFilter } =
  DealerPartViewSearchBarState.actionCreators;
const { loadSavedSearch } = DealerPartViewSavedSearchState.actionCreators;

export const DealerPartViewSavedSearch = ({
  savedSearch,
  onEditClick,
  isDeleting,
  isPartSeller = false,
}) => {
  const { t } = useTranslation("partview-dashboard");
  const dispatch = useDispatch();

  const { data: exceptionsListData, isLoading: isExceptionsListLoading } =
    useSelector(PartViewDashboardState.selectors.getExceptionsListRequestData);
  const exceptionsList = exceptionsListData?.data ?? [];

  const {
    data: counts,
    isLoading: isCountsLoading,
    errorStatus = null,
  } = useSelector(
    DealerPartViewSavedSearchCardsState.selectors.getSavedSearchCardData(
      savedSearch?.id,
    ),
  );

  const includeAPU = useSelector(
    getAuthorization,
  ).validateEntitySystemConfigValue([
    { key: "AVAILABLE_FOR_PICKUP", value: "true" },
  ]);

  useEffect(() => {
    dispatch(
      DealerPartViewSavedSearchCardsState.actionCreators.fetchSavedSearchCardData(
        savedSearch,
        includeAPU,
      ),
    );
  }, [dispatch, savedSearch, includeAPU]);

  const loadFullSavedSearch = () => {
    dispatch(loadSavedSearch(savedSearch));
    dispatch(searchEntities());
  };

  let checkSavedSearchLifeCyleState = () => {
    let lifecycleState = savedSearch.search.lifecycleState ?? [];
    if (lifecycleState.length > 0) {
      return savedSearch.search.lifecycleState;
    } else {
      return null;
    }
  };

  const handleActiveClick = () => {
    // "Active" in partview includes lifecycleState => created/packaged, in route and delayed by default.
    // By default "created/packaged, in route and delayed" filters will be applied on click if no package status has been selected.
    dispatch(loadSavedSearch(savedSearch));

    let savedSearchResult = checkSavedSearchLifeCyleState();
    if (savedSearchResult) {
      dispatch(setSearchFilter("lifecycleState", savedSearchResult));
    } else {
      const selectedActiveFilterOptions = [
        PackageStatusOption.CREATED_OR_PACKAGED,
        PackageStatusOption.IN_ROUTE,
        PackageStatusOption.DELAYED,
      ];

      if (includeAPU) {
        selectedActiveFilterOptions.push(
          PackageStatusOption.AVAILABLE_FOR_PICKUP,
        );
      }

      dispatch(setSearchFilter("lifecycleState", selectedActiveFilterOptions));
    }
    dispatch(searchEntities());
  };

  const handleDeliveredClick = () => {
    dispatch(loadSavedSearch(savedSearch));
    dispatch(
      setSearchFilter("lifecycleState", [PackageStatusOption.DELIVERED]),
    );
    dispatch(searchEntities());
  };

  // Disable delivered count click if user has any lifecycleState selected.
  // `handleDeliveredClick` applies the "Delivered" lifecycleState.
  // If user set only "In Route", we would always get a delivered count of 0.
  // Setting the lifecycleState to delivered would cause a mismatch in counts.
  const isDeliveredClickDisabled = useMemo(() => {
    if (!Array.isArray(savedSearch.search.lifecycleState)) {
      return false;
    }

    const includesDelivered = savedSearch.search.lifecycleState
      .map((option) => option.value?.toLowerCase() ?? null)
      .includes("delivered");

    return !includesDelivered;
  }, [savedSearch.search.lifecycleState]);
  let totalActiveCount = counts
    ? counts?.created +
      counts?.delayed +
      counts?.in_route +
      counts?.available_for_pickup
    : 0;

  const totalCount = totalActiveCount ?? 0;
  const deliveredCount = counts?.delivered ?? 0;

  const exceptions = getExceptionsDataForWidget(exceptionsList, counts);

  //For Part Seller the exceptions should be hidden in the pie chart graph
  const chartData = getExceptionChartData(
    isPartSeller ? [] : exceptions,
    totalCount,
    _.sumBy(exceptions, "count"),
  );
  const isLoading = isExceptionsListLoading || isCountsLoading;

  return (
    <SavedSearchPanel
      savedSearch={savedSearch}
      onSearchClick={loadFullSavedSearch}
      onEditClick={onEditClick}
      isLoading={isLoading}
      isDeleting={isDeleting}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          [MediaQueries.mediumAndUp]: {
            flexDirection: "column-reverse",
            justifyContent: "between",
          },
          [MediaQueries.extraLarge]: {
            flexDirection: "row",
            justifyContent: "space-around",
          },
          // This prevents edge cases when it starts getting too constrained
          // we get a little bit of a scrollbar right before hiding the sidebar
          // the graph could get cut off but it only affects a small range
          overflow: "hidden",
        }}
      >
        {/* If error, display below message to user*/}
        {errorStatus ? (
          <p
            css={{
              textAlign: "center",
              fontSize: FontSize.size18 + "rem",
              paddingTop: "3rem",
            }}
          >
            {t(
              "partview-dashboard:The Search results have timed out, please retry your search again.",
            )}
          </p>
        ) : (
          <>
            <DonutChart
              data={chartData}
              handler={handleActiveClick}
              totalLabel={t("partview-dashboard:Active")}
              total={totalCount}
              showNumberSeparator={true}
            />
            <ExceptionCountGroup
              exceptions={[
                {
                  name: t("partview-dashboard:Delivered"),
                  count: deliveredCount,
                  icon: { ...getIconData("Delivered") },
                  reasonCode: "Delivered",
                },
              ]}
              // Pass null to clickHandler to "disable" the button.
              clickHandler={
                isDeliveredClickDisabled ? null : handleDeliveredClick
              }
              showNumberSeparator={true}
            />
          </>
        )}
      </div>
    </SavedSearchPanel>
  );
};

DealerPartViewSavedSearch.propTypes = {
  // These come from the getCardProps in DealerPartViewSavedSearchesPanel
  savedSearch: PropTypes.object,
  onEditClick: PropTypes.func,
  isDeleting: PropTypes.bool,
  isPartSeller: PropTypes.object,
};
