import _ from "lodash";
import apiUrl from "api-url";
import { buildAsyncFilterOptionsState } from "components/search-bar/AsyncFilterOptionsStateBuilder";
import { Features } from "modules/auth/Authorization";
import { OrganizationType } from "shared/constants/organization.const";
import DealerPartViewSearchBarState from "pages/partview/redux/DealerPartViewSearchBarState";
import {
  partViewOriginUrl,
  partViewDestinationUrl,
  partViePartNumberUrl,
  partViewPackageStatusUrl,
  partViewActiveExceptionUrl,
  partViewPriorityUrl,
} from "modules/domain-data/PartViewDomainData";
// Shared logic and values between filter state configs

const pageSize = 20;

const getResponseData = (data) =>
  data?.data.filter((result) => !_.isNil(result.label)) ?? [];

const transformResult = ({ label, value }) => ({
  label: label ?? value,
  value: value,
});

// Filter option states

export const dealerOriginStateOptionsState = buildAsyncFilterOptionsState({
  topic: "dealerPartViewOriginStateOptionsState",
  getUrl: (_ignored_id, state) => {
    let fvOrgId =
      DealerPartViewSearchBarState.selectors.getSelectedFvOrgId(state);
    return partViewOriginUrl(fvOrgId);
  },
  pageSize,
  getResponseData: (data) => data?.data ?? [],
  transformResult,
});

export const dealerDestinationStateOptionsState = buildAsyncFilterOptionsState({
  topic: "dealerPartViewDestinationStateOptionsState",
  getUrl: (_ignored_id, state) => {
    let fvOrgId =
      DealerPartViewSearchBarState.selectors.getSelectedFvOrgId(state);
    return partViewDestinationUrl(fvOrgId);
  },
  pageSize,
  getResponseData: (data) => data?.data ?? [],
  transformResult,
});

export const dealerPartNumberOptionsState = buildAsyncFilterOptionsState({
  topic: "dealerPartViewPartNumberFilter",
  getUrl: (_ignored_id, state) => {
    let fvOrgId =
      DealerPartViewSearchBarState.selectors.getSelectedFvOrgId(state);
    return partViePartNumberUrl(fvOrgId);
  },
  pageSize,
  getResponseData,
  transformResult,
});

export const dealerPackageStatusOptionsState = buildAsyncFilterOptionsState({
  topic: "dealerPartViewPackageStatusFilter",
  getUrl: (_ignored_id, state) => {
    let fvOrgId =
      DealerPartViewSearchBarState.selectors.getSelectedFvOrgId(state);
    return partViewPackageStatusUrl(fvOrgId);
  },
  pageSize,
  getResponseData,
  transformResult,
});

export const dealerActiveExceptionOptionsState = buildAsyncFilterOptionsState({
  topic: "dealerPartViewActiveExceptioFilter",
  getUrl: (_ignored_id, state) => {
    let fvOrgId =
      DealerPartViewSearchBarState.selectors.getSelectedFvOrgId(state);
    return partViewActiveExceptionUrl(fvOrgId);
  },
  pageSize,
  getResponseData: (data) => {
    // remove Damage exception from active exception filter list
    let removeDmException = data?.data?.filter((exception) => {
      return exception.value !== "DM";
    });
    return removeDmException;
  },
  transformResult,
});

export const dealerOrderPriorityOptionsState = buildAsyncFilterOptionsState({
  topic: "dealerPartViewOrderPriorityFilter",
  getUrl: (_ignored_id, state) => {
    let fvOrgId =
      DealerPartViewSearchBarState.selectors.getSelectedFvOrgId(state);
    return partViewPriorityUrl(fvOrgId);
  },
  pageSize,
  getResponseData,
  transformResult,
});

export const dealerPVOrgOptionsState = buildAsyncFilterOptionsState({
  topic: "dealerPartViewOrganizationFilter",
  url: apiUrl("/iam/organizations"),
  queryParam: "everything",
  additionalParams: {
    orgType: OrganizationType.DEALER,
    paginate: true,
    feature: Features.PART_VIEW,
  },
  pageSize,
  getResponseData: (data) => data?.response ?? [],
  transformResult: (org) => ({ label: org.org_name, value: org.fv_id }),
});
