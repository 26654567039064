import { connect } from "react-redux";

import FinVehicleDashboard from "./FinishedVehicle.Dashboard.page";
import FinVehicleEntitiesState from "pages/finishedvehicle/redux/FinVehicleEntitiesState";
import FinVehicleExceptionsState from "pages/finishedvehicle/redux/FinVehicleExceptionsState";
import FinVehicleHoldsState from "pages/finishedvehicle/redux/FinVehicleHoldsState";
import FinVehicleSearchBarState from "pages/finishedvehicle/redux/FinVehicleSearchBarState";
import FinVehicleDomainData from "modules/domain-data/FinVehicleDomainData";
import FinVehicleGroupCategoryState from "pages/finishedvehicle/redux/FinVehicleGroupCategoryState";
import { getSolutionId } from "modules/organizations/OrganizationsState";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import { Features } from "modules/auth/Authorization";

function mapStateToProps(state) {
  const itssCountData =
    FinVehicleGroupCategoryState.selectors.getItssCountData(state);
  const spotBuyCountData =
    FinVehicleGroupCategoryState.selectors.getSpotBuyCountData(state);
  const auth = getAuthorization(state);

  return {
    exceptions: FinVehicleExceptionsState.selectors.getExceptions(state),
    exceptionTypes: FinVehicleDomainData.selectors.getExceptionTypes(state),
    entityCount: FinVehicleEntitiesState.selectors.getEntityCount(state),
    entityCountLoading:
      FinVehicleEntitiesState.selectors.getEntityCountLoading(state),
    entityDeliveredCount:
      FinVehicleEntitiesState.selectors.getEntityDeliveredCount(state),
    entityDeliveredCountLoading:
      FinVehicleEntitiesState.selectors.getEntityCountDeliveredLoading(state),
    entityCompleteCount:
      FinVehicleEntitiesState.selectors.getEntityCompleteCount(state),
    entityCompleteCountLoading:
      FinVehicleEntitiesState.selectors.getEntityCompleteCountLoading(state),
    solutionId: getSolutionId(state),
    vinHolds: FinVehicleHoldsState.selectors.selectHoldCountsForWidget(state),
    vinHoldsLoading: FinVehicleHoldsState.selectors.getHoldCountsLoading(state),
    exceptionsLoading: FinVehicleExceptionsState.selectors.getIsLoading(state),
    showFilters:
      FinVehicleSearchBarState.selectors.getShowAdvancedSearch(state),
    // ITSS data
    itssCountData: itssCountData?.data ?? [],
    itssCountDataIsLoading: itssCountData?.isLoading ?? true,
    showItssWidget: auth.hasFeatures([Features.ITSS]),
    // Spot Buy data
    spotBuyCountData: spotBuyCountData?.data ?? [],
    spotBuyCountDataIsLoading: spotBuyCountData?.isLoading ?? true,
    showSpotyBuyWidget: auth.hasFeatures([Features.SPOT_BUY]),
    lifeCycleStateFilterOptions:
      FinVehicleDomainData.selectors.getLifeCycleStates(state),
  };
}

const { fetchExceptions } = FinVehicleExceptionsState.actionCreators;
const { fetchEntityCount } = FinVehicleEntitiesState.actionCreators;
const { fetchEntityDeliveredCount } = FinVehicleEntitiesState.actionCreators;
const { fetchEntityCompleteCount } = FinVehicleEntitiesState.actionCreators;
const { fetchHoldCounts } = FinVehicleHoldsState.actionCreators;
const { fetchItssCount, fetchSpotBuyCount } =
  FinVehicleGroupCategoryState.actionCreators;
const {
  selectSavedSearch,
  resetSavedSearch,
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSearchBar,
  clearSearchFilters,
  toggleShowFilters,
} = FinVehicleSearchBarState.actionCreators;

const actionCreators = {
  fetchExceptions,
  fetchEntityCount,
  fetchHoldCounts,
  fetchEntityDeliveredCount,
  fetchEntityCompleteCount,
  fetchItssCount,
  fetchSpotBuyCount,
  selectSavedSearch,
  resetSavedSearch,
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  resetSearchBar,
  clearSearchFilters,
  toggleShowFilters,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  // Merge props like normal
  const props = { ...stateProps, ...dispatchProps, ...ownProps };

  // If we don't want to show the widget
  if (!stateProps.showItssWidget) {
    // Set a no-op function for the fetcher
    props.fetchItssCount = () => {};
    // Set the data to empty
    props.itssCountData = [];
    props.itssCountDataIsLoading = false;
  }

  return props;
};

export default connect(
  mapStateToProps,
  actionCreators,
  mergeProps,
)(FinVehicleDashboard);
