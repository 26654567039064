/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";

import { Trans, useTranslation } from "react-i18next";

import { Search } from "components/templates/Search.template";
import SearchBarContainer from "../components/search/VinView.SearchBar.container";
import FiltersContainer from "../components/search/VinView.SearchFilters.container";
import { columns } from "./VinView.Search.columns";
import VinViewEditSavedSearchModalContainer from "../components/search/VinView.EditSavedSearchModal.container";

import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";

const SearchResultsTotalCountLabel = () => {
  return (
    <Trans ns="vinview-search">
      <Search.TotalCount /> Total VINs
    </Trans>
  );
};

const VinViewSearch = (props) => {
  const {
    savedSearch,
    loadSavedSearch,
    toggleShowFilters,
    pushVinViewDetailsPage,
    vinSearchResults,
    isLoading,
    isLoadingError,
    loadingError,
    showFilters,
    sortColumn,
    reverseSort,
    page,
    pageSize,
    totalPages,
    setPagination,
    setSort,
    resetSearch,
    exportEntities,
    totalEntities,
    totalEntitiesIsLoading,
    isExporting,
    exportIdentifier,
    exportName,
    exportFailed,
    clearExportErrors,
    selectedDealerOrgId = null,
    isDealerOrg,
    resetExport,
  } = props;
  const { t } = useTranslation("vinview-search");

  useSetTitleOnMount(t("vinview-search:Search Results"));

  useTrackWithMixpanelOnce("Viewed Page: VINView / Search");

  const rowClickHandler = (row, cell) => {
    // Prevent navigation if clicking in "watch" or "Active Exceptions" checkbox cell
    if (cell.column.id === "watch" || cell.column.id === "shipmentExeptions") {
      return;
    }

    // Navigate to VIN Details when clicking row
    pushVinViewDetailsPage(row.original.internal_id, selectedDealerOrgId);
  };

  return (
    <Search
      isLoading={isLoading}
      searchResults={vinSearchResults}
      hideSearchResults={!isDealerOrg && _.isNil(selectedDealerOrgId)}
      hideSearchResultsMessage={t(
        "vinview-search:Select a Dealer Organization from the filter above to continue.",
      )}
      totalCount={totalEntities}
      totalCountIsLoading={totalEntitiesIsLoading}
      savedSearch={savedSearch}
      loadSavedSearch={loadSavedSearch}
      SavedSearchModalContainer={VinViewEditSavedSearchModalContainer}
      resetSearch={resetSearch}
      showFilters={showFilters}
      toggleShowFilters={toggleShowFilters}
      SearchBarContainer={SearchBarContainer}
      FiltersContainer={FiltersContainer}
      showError={isLoadingError}
      showErrorMessage={loadingError?.response?.data?.error?.message}
      TotalCountLabel={SearchResultsTotalCountLabel}
      exportProps={{
        exportEntities: exportEntities,
        exportIdentifier: exportIdentifier,
        exportName: exportName,
        isExporting: isExporting,
        exportFailed: exportFailed,
        clearExportErrors: clearExportErrors,
        resetExport: resetExport,
      }}
      tableProps={{
        data: vinSearchResults,
        columns: columns(t, selectedDealerOrgId),
        isLoading: isLoading,
        rowClickHandler: rowClickHandler,
        showPagination: true,
        fixPaginationToBottom: true,
        isManualPagination: true,
        isManualSorting: true,
        sortColumn: sortColumn,
        reverseSort: reverseSort,
        pageIndex: page,
        pageSize: pageSize,
        pageCount: totalPages,
        onPageChange: (newPage) => {
          setPagination(newPage, pageSize);
        },
        onPageSizeChange: (newPageSize) => {
          setPagination(0, newPageSize);
        },
        onPageSort: (column, isReverse) => {
          setSort(column, isReverse);
        },
      }}
    />
  );
};

VinViewSearch.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isLoadingError: PropTypes.bool,
  loadingError: PropTypes.any,
  loadSavedSearch: PropTypes.func.isRequired,
  sortColumn: PropTypes.string,
  reverseSort: PropTypes.bool,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  pushVinViewDetailsPage: PropTypes.func.isRequired,
  savedSearch: PropTypes.object,
  setPagination: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  exportEntities: PropTypes.func.isRequired,
  showFilters: PropTypes.bool.isRequired,
  toggleShowFilters: PropTypes.func.isRequired,
  totalEntities: PropTypes.number.isRequired,
  totalEntitiesIsLoading: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
  vinSearchResults: PropTypes.array.isRequired,
  isExporting: PropTypes.bool.isRequired,
  exportIdentifier: PropTypes.string,
  exportName: PropTypes.string,
  exportFailed: PropTypes.bool,
  clearExportErrors: PropTypes.func,
  selectedDealerOrgId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  isDealerOrg: PropTypes.bool,
  resetExport: PropTypes.func,
};

export default VinViewSearch;
