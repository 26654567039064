import _ from "lodash";
import { createSelector } from "reselect";

import apiUrl from "api-url";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import { axiosConfigHeaders } from "utils/fetch-utils";
import { createOptionsMapper } from "utils/selectors-utils";

const STORE_MOUNT_POINT = "milestoneDomainData";

// Exported URLs
export const locationsUrl = (solutionId) => {
  return apiUrl(`/entity-search/status-update-filter?location=location`);
};

const duck = buildFetchDuck(STORE_MOUNT_POINT);

const domainQueryArgs = [
  "customer_shipper",
  "milestone_description",
  "milestone_code",
]
  .map((item) => `${item}=${item}`)
  .join("&");

const fetchDomainData = (solutionId) => {
  const url = apiUrl(`/entity-search/status-update-filter?${domainQueryArgs}`);
  return (dispatch) => dispatch(duck.fetch(url, axiosConfigHeaders()));
};

// selectors

const getDomainData = (state) => state[STORE_MOUNT_POINT].data;

const getMilestoneEventNames = createSelector(getDomainData, (domainData) =>
  _.get(domainData, "milestone_description", []).sort(),
);

const getMilestoneEventCodes = createSelector(getDomainData, (domainData) =>
  _.get(domainData, "milestone_code", []),
);

const getShippers = createSelector(getDomainData, (domainData) =>
  _.get(domainData, "shippers", []),
);

// filter selectors

const getEventsOptions = createSelector(
  getMilestoneEventNames,
  createOptionsMapper({ sorted: true }),
);

const getStatusCodesOptions = createSelector(
  getMilestoneEventCodes,
  createOptionsMapper({ sorted: true }),
);

const getShippersOptions = createSelector(
  getShippers,
  createOptionsMapper({ labelKey: "name", valueKey: "id", sorted: true }),
);

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: { fetchDomainData },
  selectors: {
    getMilestoneEventNames,
    getMilestoneEventCodes,
    getShippers,
    getEventsOptions,
    getStatusCodesOptions,
    getShippersOptions,
  },
  reducer: duck.reducer,
};
