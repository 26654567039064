import _ from "lodash";
import ApplicationConfig from "application-config";

// Perform authorization checks based on system configuration settings
// Example: NGAVS_STATUS_CODE
// On pageConfigs:
// - This is a map from our route keys (in routes.js) to the config for that page.
// - The "section" keys are also included for the product configs.
export default class AppConfigAuthorization {
  constructor(globalAppConfig = {}, entitySystemConfigOptions = []) {
    this.entityConfig = this.processEntityConfig(
      entitySystemConfigOptions ?? [],
    );
    this.globalAppConfig = globalAppConfig ?? {};
  }

  /**
   * Check if the given options match the global app config from the backend.
   *
   * @example
   * hasAnyGlobalAppConfig({
   *   "containerTracking.productName": "Container Tracking"
   * })
   * // returns `true` with this global app config
   * // {
   * //   containerTracking: {
   * //     productName: "Container Tracking"
   * //   }
   * // }
   *
   * @param {Object} requiredConfig Key (path to the property) and value pairs to match against.
   * @returns
   */
  hasAnyGlobalAppConfig(requiredConfig = {}) {
    return Object.keys(requiredConfig).reduce(
      (requiredMatchesConfig, propertyPath) => {
        return (
          requiredMatchesConfig &&
          _.get(this.globalAppConfig, propertyPath) ===
            requiredConfig[propertyPath]
        );
      },
      true,
    );
  }

  /**
   * Formats the enttiy config options from the backend to make it easier to
   * validate later.
   *
   * @param {Object[]} configOptions The entity config options from the backend:
   * ```js
   * [{ CONFIG_KEY: "value", metadata: null, solution_id: "1234" }]
   * ```
   * @returns
   * ```js
   * {
   *   CONFIG_KEY: {
   *     value: "value",
   *     metadata: null,
   *     solution_id: "1234"
   *   }
   * }
   * ```
   */
  processEntityConfig(configOptions = []) {
    return configOptions.reduce((config, option) => {
      const configKey = Object.keys(option).find((key) => {
        return key !== "metadata" || key !== "solution_id";
      });

      // Skip processing if we don't have the config key.
      if (!configKey) {
        return config;
      }

      // Skip processing if config already exists.
      if (config[configKey]) {
        return config;
      }

      return {
        ...config,
        [configKey]: {
          value: option[configKey],
          metadata: option.metadata,
          solution_id: option.solution_id,
        },
      };
    }, {});
  }

  /**
   * Check if the given options match the entity config from the backend.
   *
   * @example
   * hasAnyEntitySystemConfig([
   *   { key: "DISPLAY_CONNECTED_CAR_TAB", value: "true" }
   * ])
   * // returns `true` with this entity config
   * // [
   * //   {
   * //     DISPLAY_CONNECTED_CAR_TAB: "true",
   * //     metadata: null,
   * //     solution_id: FV1234
   * //   }
   * // ]
   *
   * @param {Object[]} requiredOptions The list of config options to validate.
   * @returns
   */
  hasAnyEntitySystemConfig(requiredOptions = []) {
    return requiredOptions.reduce((allOptionsMatchConfig, option) => {
      return (
        allOptionsMatchConfig &&
        this.entityConfig.hasOwnProperty(option.key) &&
        this.entityConfig[option.key].value === option.value
      );
    }, true);
  }

  /**
   * returns the values for the given options that match the entity config from the backend.
   *
   * @example
   * getEntitySystemConfig([
   *   { key: "DISPLAY_ETA_TIME_METER", value: "true" }
   * ])
   *
   *  returns the values that match the entity config
   *  [
   *   {
   *    "DISPLAY_ETA_TIME_METER": "true",
   *    "metadata": {
   *        "levels": {
   *           "LATE": { "end": 6, "start": 1, "status": "warning" },
   *            "EARLY": { "end": 0, "start": -2, "status": "ok" },
   *            "VERY_LATE": { "end": null, "start": 6, "status": "warning" },
   *            "VERY_EARLY": { "end": -2, "start": null, "status": "ok" }
   *         }
   *    },
   *    "solution_id": "FV_TEST"
   *   }
   *  ]
   *
   * @param {Object[]} requiredOptions The list of config options to retrieve.
   * @returns
   */
  getEntitySystemConfig(requiredOptions = []) {
    return requiredOptions
      .map((option) =>
        this.entityConfig.hasOwnProperty(option.key) &&
        this.entityConfig[option.key].value === option.value
          ? this.entityConfig[option.key]
          : null,
      )
      .filter((option) => option !== null);
  }
}

export function getAppConfig(fvId) {
  function shouldOrgDisplaySurgicalToteTrackingForContainerTracking() {
    const lookup = {
      dev: [
        "FV9387082A", // TEST-Surgical Shipper
        "FV9042029A", // IMPLANTS & INSTRUMENTATION
        "FV2298606A",
      ],
      dev2: [
        "FV9387082A", // TEST-Surgical Shipper
        "FV9042029A", // IMPLANTS & INSTRUMENTATION
        "FV2298606A",
      ],
      qa: [
        "FV9387082A", // TEST-Surgical Shipper
        "FV9042029A", // IMPLANTS & INSTRUMENTATION
        "FV2298606A",
      ],
      qa2: [
        "FV9387082A", // TEST-Surgical Shipper
        "FV9042029A", // IMPLANTS & INSTRUMENTATION
        "FV2298606A",
      ],
      test: [
        "FV9387082A", // TEST-Surgical Shipper
        "FV9042029A", // IMPLANTS & INSTRUMENTATION
        "FV2298606A",
      ],
      staging1: [
        "FV9387082A", // TEST-Surgical Shipper
        "FV9042029A", // IMPLANTS & INSTRUMENTATION
        "FV2298606A",
      ],
      proda: [
        "FV9387082A", // TEST-Surgical Shipper
        "FV9042029A", // IMPLANTS & INSTRUMENTATION
        "FV2298606A",
      ],
      prod: [
        "FV9387082A", // TEST-Surgical Shipper
        "FV9042029A", // IMPLANTS & INSTRUMENTATION
        "FV2298606A",
      ],
    };

    return lookup[ApplicationConfig.ENVIRONMENT.name]?.includes(fvId) ?? false;
  }

  function shouldOrgDisplayInventoryView() {
    const lowerEnvs = [
      "FORD", // Ford
      "FV3221778A", // TEST-Shipper,
      "FV0834338A", // TEST-Carrier
      "FV2540611A", // TEST-Carrier 2
      "TEST_PARTNER", // TEST_PARTNER
    ];
    const lookup = {
      development: lowerEnvs,
      dev: lowerEnvs,
      dev2: lowerEnvs,
      qa: lowerEnvs,
      qa2: lowerEnvs,
      test: lowerEnvs,
      staging1: lowerEnvs,
      proda: lowerEnvs,
      prod: [
        "FV3221778A", // TEST-Shipper,
        "FV0834338A", // TEST-Carrier
        "FV2540611A", // TEST-Carrier 2
        "TEST_PARTNER", // TEST_PARTNER
      ],
    };

    return lookup[ApplicationConfig.ENVIRONMENT.name]?.includes(fvId) ?? false;
  }

  const configuration = {
    containerTracking: {
      productName: "Container Tracking",
    },
    inventoryView: {
      visible: false,
    },
  };

  if (shouldOrgDisplaySurgicalToteTrackingForContainerTracking()) {
    configuration.containerTracking.productName = "Surgical Tote Tracking";
  }

  if (shouldOrgDisplayInventoryView()) {
    configuration.inventoryView.visible = true;
  }

  return configuration;
}

export function getPageConfig(fvId, routeKey, routesMap) {
  function getConfigForProduct() {
    const productConfig = {};

    // const navGroup = routesMap[routeKey].navGroup;

    // Example: Use `navGroup` to determine the config for a nav section.
    // if (navGroup === "PART_VIEW") {
    //  productConfig.filters = {
    //    partName: {
    //      label: "Part Name",
    //    },
    //  };
    // }

    return productConfig;
  }

  function getConfigForRoute() {
    const pageConfig = {};

    if (
      routeKey === "PARTVIEW_DETAILS" ||
      routeKey === "DEALER_PARTVIEW_DETAILS"
    ) {
      pageConfig.tabs = {
        order: {
          isVisible: false,
        },
      };

      if (routeKey === "DEALER_PARTVIEW_DETAILS") {
        pageConfig.isDealerView = true;
      } else {
        pageConfig.isDealerView = false;
      }

      if (fvId === "SMARTCAB") {
        pageConfig.tabs.order.isVisible = true;
      }
    }

    return pageConfig;
  }

  return {
    product: getConfigForProduct(),
    page: getConfigForRoute(),
  };
}
