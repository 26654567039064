import _ from "lodash";

import axios from "axios";
import apiUrl from "api-url";
import { getActiveOrganization } from "modules/organizations/OrganizationsState";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import { OrganizationType } from "shared/constants/organization.const";

const STORE_MOUNT_POINT = "inventoryViewDashboard";

//urls
const LOCATION_VISIBILITY_BASE_URL = apiUrl(
  "/entity-inventory/location-visibility",
);

// Actions
const REQUEST_WATCHED_LOCATIONS = `${STORE_MOUNT_POINT}/REQUEST_WATCHED_LOCATIONS`;
const REQUEST_ALL_WATCHED_LOCATIONS = `${STORE_MOUNT_POINT}/REQUEST_ALL_WATCHED_LOCATIONS`;
const FETCH_WATCHED_LOCATIONS = `${STORE_MOUNT_POINT}/FETCH_WATCHED_LOCATIONS`;
const FETCH_ALL_WATCHED_LOCATIONS = `${STORE_MOUNT_POINT}/FETCH_ALL_WATCHED_LOCATIONS`;
const REQUEST_WATCHED_LOCATIONS_COUNT = `${STORE_MOUNT_POINT}/REQUEST_WATCHED_LOCATIONS_COUNT`;
const RECEIVE_WATCHED_LOCATIONS_COUNT = `${STORE_MOUNT_POINT}/RECEIVE_WATCHED_LOCATIONS_COUNT`;
const RECEIVE_WATCHED_LOCATIONS_ERROR = `${STORE_MOUNT_POINT}/RECEIVE_WATCHED_LOCATIONS_ERROR`;
const SET_WATCHED_LOCATIONS_PAGE_INDEX = `${STORE_MOUNT_POINT}/SET_WATCHED_LOCATIONS_PAGE_INDEX`;
const SET_DWELLING_VINS_WIDGET_PAGE_INDEX = `${STORE_MOUNT_POINT}/SET_DWELLING_VINS_WIDGET_PAGE_INDEX`;
const SET_CAPACITY_METER_WIDGET_PAGE_INDEX = `${STORE_MOUNT_POINT}/SET_CAPACITY_METER_WIDGET_PAGE_INDEX`;

function fetchWatchedLocations() {
  return async (dispatch, getState) => {
    dispatch({
      type: REQUEST_WATCHED_LOCATIONS,
    });
    dispatch({ type: REQUEST_WATCHED_LOCATIONS_COUNT });
    const state = getState();
    const pageSize = state[STORE_MOUNT_POINT].watchedLocationsPageSize;
    const pageNumber = state[STORE_MOUNT_POINT].watchedLocationsPageIndex;

    const fvId = getActiveOrganization(state)?.fv_id;
    const params = { pageSize, pageNumber };
    const url = `${LOCATION_VISIBILITY_BASE_URL}/organization/${fvId}?watched=true`;

    await axios
      .get(url, { params })
      .then((response) => {
        dispatch({
          type: RECEIVE_WATCHED_LOCATIONS_COUNT,
          payload: { totalPages: response.data?.meta?.totalPages ?? 1 },
        });
        return dispatch({
          type: FETCH_WATCHED_LOCATIONS,
          payload: { watchedLocations: response?.data?.data ?? [] },
        });
      })
      .catch((err) => {
        dispatch({ type: RECEIVE_WATCHED_LOCATIONS_ERROR, err });
      });
  };
}

const fetchAllWatchedLocations = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: REQUEST_ALL_WATCHED_LOCATIONS,
    });
    const state = getState();
    const fvId = getActiveOrganization(state)?.fv_id;
    const url = `${LOCATION_VISIBILITY_BASE_URL}/organization/${fvId}?watched=true`;

    await axios
      .get(url)
      .then((response) => {
        return dispatch({
          type: FETCH_ALL_WATCHED_LOCATIONS,
          payload: { allWatchedLocations: response?.data?.data ?? [] },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

const saveWatchedLocations = (newWatchedLocations, deletedWatchedLocations) => {
  let promises = [];

  if (!_.isEmpty(newWatchedLocations)) {
    promises.push(axios.post(apiUrl("/location/watch"), newWatchedLocations));
  }

  if (!_.isEmpty(deletedWatchedLocations)) {
    promises.push(
      axios.post(apiUrl("/location/unwatch"), deletedWatchedLocations),
    );
  }

  return (_) => {
    return Promise.all(promises);
  };
};

function setWatchedLocationsPageIndex(pageIndex) {
  return {
    type: SET_WATCHED_LOCATIONS_PAGE_INDEX,
    payload: { pageIndex },
  };
}

const setDwellingVinsWidgetPageIndex = (pageIndex) => {
  return {
    type: SET_DWELLING_VINS_WIDGET_PAGE_INDEX,
    payload: { pageIndex },
  };
};

const setCapacityMeterWidgetPageIndex = (pageIndex) => {
  return {
    type: SET_CAPACITY_METER_WIDGET_PAGE_INDEX,
    payload: { pageIndex },
  };
};

function redirectToVinDetails(vin) {
  return (dispatch, getState) => {
    const authorization = getAuthorization(getState());
    const isShipper = authorization.hasOrganizationTypes([
      OrganizationType.SHIPPER,
    ]);
    const isCarrier = authorization.hasOrganizationTypes([
      OrganizationType.CARRIER,
    ]);
    if (isShipper) {
      dispatch({
        type: "VIN_DETAILS",
        payload: { entity_id: vin.id },
      });
    } else if (isCarrier) {
      dispatch({
        type: "CARRIERVIEW_DETAILS",
        payload: { internal_id: vin.internal_id },
      });
    } else {
      // Ignored for other org types.
    }
  };
}

// Selectors
const getWatchedLocations = (state) =>
  state[STORE_MOUNT_POINT].watchedLocations ?? [];
const getAllWatchedLocations = (state) =>
  state[STORE_MOUNT_POINT].allWatchedLocations ?? [];

const getWatchedLocationsLoading = (state) =>
  state[STORE_MOUNT_POINT].isWatchLocationsLoading;

const getAllWatchedLocationsLoading = (state) =>
  state[STORE_MOUNT_POINT].isAllWatchLocationsLoading;

const getWatchedLocationsError = (state) =>
  state[STORE_MOUNT_POINT].watchedLocationsError;

const getWatchedLocationsPageIndex = (state) =>
  state[STORE_MOUNT_POINT].watchedLocationsPageIndex;
const getWatchedLocationsPageSize = (state) =>
  state[STORE_MOUNT_POINT].watchedLocationsPageSize;
const getWatchedLocationsPageCount = (state) =>
  state[STORE_MOUNT_POINT].watchedLocationsPageCount;
const getDwellingVinsWidgetPageIndex = (state) =>
  state[STORE_MOUNT_POINT].dwellingVinsWidgetPageIndex;
const getCapacityMeterWidgetPageIndex = (state) =>
  state[STORE_MOUNT_POINT].capacityMeterWidgetPageIndex;

// Reducers
const inventoryViewDashboardReducer = (
  state = {
    watchedLocations: [],
    allWatchedLocations: [],
    isWatchLocationsLoading: false,
    isAllWatchLocationsLoading: false,
    watchedLocationsPageIndex: 0,
    watchedLocationsPageSize: 10,
    watchedLocationsPageCount: 0,
    watchedLocationsError: {},
    dwellingVinsWidgetPageIndex: 0,
    capacityMeterWidgetPageIndex: 0,
  },
  action,
) => {
  switch (action.type) {
    case REQUEST_WATCHED_LOCATIONS:
      return {
        ...state,
        isWatchLocationsLoading: true,
        watchedLocationsError: {},
      };
    case REQUEST_ALL_WATCHED_LOCATIONS:
      return {
        ...state,
        isAllWatchLocationsLoading: true,
      };
    case FETCH_WATCHED_LOCATIONS:
      return {
        ...state,
        isWatchLocationsLoading: false,
        watchedLocations: action.payload.watchedLocations,
      };
    case FETCH_ALL_WATCHED_LOCATIONS:
      return {
        ...state,
        isAllWatchLocationsLoading: false,
        allWatchedLocations: action.payload.allWatchedLocations,
      };
    case RECEIVE_WATCHED_LOCATIONS_ERROR:
      return {
        ...state,
        isWatchLocationsLoading: false,
        watchedLocationsError: action.err,
      };
    case RECEIVE_WATCHED_LOCATIONS_COUNT:
      return {
        ...state,
        watchedLocationsPageCount: action.payload.totalPages,
      };

    case SET_WATCHED_LOCATIONS_PAGE_INDEX:
      return {
        ...state,
        watchedLocationsPageIndex: action.payload.pageIndex,
      };
    case SET_DWELLING_VINS_WIDGET_PAGE_INDEX:
      return {
        ...state,
        dwellingVinsWidgetPageIndex: action.payload.pageIndex,
      };
    case SET_CAPACITY_METER_WIDGET_PAGE_INDEX:
      return {
        ...state,
        capacityMeterWidgetPageIndex: action.payload.pageIndex,
      };
    default:
      return state;
  }
};

const InventoryViewDashboardState = {
  mountPoint: STORE_MOUNT_POINT,
  actionTypes: {},
  actionCreators: {
    fetchWatchedLocations,
    fetchAllWatchedLocations,
    saveWatchedLocations,
    setWatchedLocationsPageIndex,
    setDwellingVinsWidgetPageIndex,
    setCapacityMeterWidgetPageIndex,
    redirectToVinDetails,
  },
  selectors: {
    getWatchedLocations,
    getAllWatchedLocations,
    getWatchedLocationsError,
    getWatchedLocationsLoading,
    getAllWatchedLocationsLoading,
    getWatchedLocationsPageIndex,
    getWatchedLocationsPageSize,
    getWatchedLocationsPageCount,
    getDwellingVinsWidgetPageIndex,
    getCapacityMeterWidgetPageIndex,
  },
  reducer: inventoryViewDashboardReducer,
};
export default InventoryViewDashboardState;
