/** @jsxImportSource @emotion/react */
import _ from "lodash";
import Loader from "react-loader";
import {
  VictoryPie,
  VictoryLabel,
  VictoryTooltip,
  VictoryTooltipProps,
} from "victory";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import Colors from "styles/colors";
import { MediaQueries } from "components/responsive";
import { Text } from "components/atoms/Text.atom";
import { Icon } from "components/atoms/Icon.atom";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { convertCountToLocaleString } from "utils/html-utils";

type DataCountProps = {
  style: Pick<
    React.CSSProperties,
    "height" | "width" | "position" | "top" | "left"
  >;
  count: number;
  dataLabel: string;
};

type HalfPieChartAndTableProps = {
  title: string;
  isLoading: boolean;
  dataLabel: string;
  nameAndCountList: [];
  tableHeaderDataTypeLabel: string;
  tableHeaderDataCountLabel: string;
  chartDataLabel: string;
  showTotalCount: boolean;
  onRowClick: () => void;
  width: number;
};

const DataCount = ({ style, count, dataLabel }: DataCountProps) => {
  return (
    <svg css={style} viewBox="0 0 50 50">
      <text x="0" y="25" fontSize="20" fontWeight="bold" textAnchor="start">
        {convertCountToLocaleString(count)}
      </text>
      <text x="0" y="40" fontSize="14" textAnchor="start">
        {dataLabel}
      </text>
    </svg>
  );
};

type NameCellProps = {
  value: {
    fill: string;
    fullDescription: string;
  };
};

type RowData = {
  fill: string;
  fullDescription: string;
};

type CountCellProps = {
  value: number;
};

export const HalfPieChartAndTable = ({
  title = "",
  isLoading,
  dataLabel,
  nameAndCountList,
  tableHeaderDataTypeLabel,
  tableHeaderDataCountLabel,
  chartDataLabel,
  showTotalCount,
  onRowClick,
  width,
}: HalfPieChartAndTableProps) => {
  const dataColumns = [
    {
      Header: tableHeaderDataTypeLabel,
      accessor: (rowData: RowData) => {
        return {
          fill: rowData.fill,
          fullDescription: rowData.fullDescription,
        };
      },
      width: width,
      maxWidth: width,
      Cell: (cellInfo: NameCellProps) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon
              src={faCircle}
              color={cellInfo.value.fill}
              style={{
                marginRight: 8,
                fontSize: 10,
              }}
            />
            <Text>{cellInfo.value.fullDescription}</Text>
          </div>
        );
      },
    },
    {
      Header: tableHeaderDataCountLabel,
      accessor: "count",
      width: width,
      maxWidth: width,
      Cell: (cellInfo: CountCellProps) => {
        return (
          <Text style={{ padding: "10px 0" }}>
            {convertCountToLocaleString(cellInfo.value)}
          </Text>
        );
      },
    },
  ];

  return (
    <PanelGroup>
      <PanelGroup.Header title={title} />
      <PanelGroup.Content
        style={{
          position: "relative",
          backgroundColor: "white",
          minHeight: 290,
          padding: 0,
          [MediaQueries.mediumAndUp]: {
            margin: "unset",
          },
        }}
      >
        <>
          {isLoading ? (
            <Loader loaded={false} /> // this is to remove children prop type issue on "Loader" as per typescript
          ) : (
            <>
              <div
                css={{
                  position: "relative",
                }}
              >
                {showTotalCount ? (
                  <DataCount
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      height: "25%",
                      width: "25%",
                    }}
                    count={_.sumBy(nameAndCountList, "count")}
                    dataLabel={dataLabel}
                  />
                ) : null}

                <VictoryPie
                  height={185}
                  origin={{ x: 200, y: 185 }}
                  style={{
                    data: {
                      fill: ({ datum }) => datum.fill,
                    },
                  }}
                  x="type"
                  y="count"
                  startAngle={-90}
                  endAngle={90}
                  radius={165}
                  innerRadius={100}
                  data={nameAndCountList}
                  labels={({ datum }) => datum.type}
                  labelComponent={
                    <DataLabelToolTip
                      x={200}
                      y={300}
                      chartDataLabel={chartDataLabel}
                    />
                  }
                />
              </div>
              <div
                css={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px 10px",
                  backgroundColor: "white",
                  marginTop: -5,
                }}
              >
                <BaseTable
                  theme={Themes.LIGHT}
                  data={nameAndCountList}
                  columns={dataColumns}
                  pageSize={nameAndCountList?.length}
                  disableSortBy={true}
                  rowClickHandler={onRowClick}
                />
              </div>
            </>
          )}
        </>
      </PanelGroup.Content>
    </PanelGroup>
  );
};

interface DataLabelToolTipProps extends VictoryTooltipProps {
  x: number;
  y: number;
  datum?: {
    fill: string;
    fullDescription: string;
    count: number;
  };
  chartDataLabel: string;
}

const DataLabelToolTip = (props: DataLabelToolTipProps) => {
  const { active, datum, x, y, chartDataLabel } = props;
  if (!active) {
    return null;
  }

  let victoryLabelCountCommaSeparator = convertCountToLocaleString(
    datum?.count,
  );

  return (
    <g>
      <VictoryLabel
        x={x}
        y={y - 170}
        text={`${victoryLabelCountCommaSeparator} ${chartDataLabel}`}
        textAnchor="middle"
        style={{
          textAlign: "center",
          fontSize: 14,
          fontWeight: 600,
          fill: Colors.holds.DARK_BLUE,
        }}
      />
      <VictoryLabel
        x={x}
        y={y - 145}
        /* H2-940: Add Hold Code next to Description in Parentheses
         * Truncate the text because it can be too long to fit in the inner part of the pie graph
         * Keep in mind that this is user submitted data, the user can use mixed casing or all caps.
         * Because of this, we are forcing all caps because the capital letters in the font are
         *   generally the same width with a few exceptions (e.g. "I")
         *
         * Note: Unfortunately, this is SVG text, it is unaffected by CSS's text-overflow: ellipsis
         */
        text={_.truncate(datum?.fullDescription.toUpperCase(), {
          length: 22,
        })}
        textAnchor="middle"
        style={{
          textAlign: "center",
          fontSize: 15,
          fill: `${datum?.fill}`,
        }}
      />
    </g>
  );
};

DataLabelToolTip.defaultEvents = VictoryTooltip.defaultEvents;
