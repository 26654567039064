import { customerApiUrl } from "api-url";
import buildFetchDuck from "vendor/signal-utils/build-fetch-duck";
import { combineReducers } from "redux";

const STORE_MOUNT_POINT = "inventoryViewDomainData";

const inventoryViewCurrentLocationTypesDuck = buildFetchDuck(
  "inventoryViewCurrentLocaitonTypesDuck",
);

export function fetchDomainData() {
  return (dispatch, getState) => {
    const state = getState();
    const locationId = state.location.payload.locationId;
    const urlCurrentLocationType = customerApiUrl(
      `/entity-inventory/location/${locationId}/search/options/current-location-type`,
    );
    dispatch(
      inventoryViewCurrentLocationTypesDuck.fetch(urlCurrentLocationType),
    );
  };
}

const getCurrentLocationTypes = (state) => {
  return state[STORE_MOUNT_POINT]?.currentLocationTypes?.data?.data ?? [];
};

const InventoryViewDomainData = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchDomainData,
  },
  selectors: {
    getCurrentLocation: getCurrentLocationTypes,
  },
  reducer: combineReducers({
    currentLocationTypes: inventoryViewCurrentLocationTypesDuck.reducer,
  }),
};

export default InventoryViewDomainData;
