/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";

import { useState } from "react";

import Loader from "react-loader";
import {
  VictoryChart,
  VictoryLabel,
  VictoryTooltip,
  VictoryBar,
  VictoryAxis,
} from "victory";
import { Button } from "components/atoms/Button.atom";
import { FontSize } from "components/atoms/Text.atom";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { Icon } from "components/atoms/Icon.atom";
import { faCaretDown, faCaretUp } from "@fortawesome/pro-solid-svg-icons";
import { MediaQueries } from "components/responsive";
import Colors from "styles/colors";
import { NoChartData } from "components/molecules/NoChartData.molecule";
import { convertCountToLocaleString } from "utils/html-utils";

const AXIS_STROKE_COLOR = Colors.border.CHART_AXIS_BORDER;
const MAX_DOMAIN_FOR_VIN_COUNT = 5000;

/**
 * This component charts the count of VINs in categories from `entity-group`.
 *
 * This chart will:
 * - graph the categories on the vertical axis.
 * - graph the count of VINs in that category on the horizontal axis.
 * - Paginates the data in sets of 5.
 * - Maintains the same ticks on the count axis between "pages".
 * - Displays a max tick of 5000+ if there is a category that has a count of 5000 or greater.
 */
export const VinCategoryChartPanel = ({
  data,
  isLoading,
  onBarClick = _.noop,
  title,
  noDataLabel,
  chartColor = Colors.highlight.BRIGHT_BLUE,
  chartYAxisLabel,
  chartXAxisLabel,
  chartTooltipSingularLabel,
  chartTooltipPluralLabel,
}) => {
  const chartData = _.orderBy(data, "count", "desc").map((item) => {
    const shouldUsePlural = item.count > 1;
    let label = "";
    if (shouldUsePlural) {
      label = chartTooltipPluralLabel;
    } else {
      label = chartTooltipSingularLabel;
    }

    return {
      ...item,
      // The label contains the actual count.
      // This appears on bar hover.
      label: `${convertCountToLocaleString(item.count)} ${label}`,
      // Limit the bar to a count of 5000.
      // This prevents the bar from extending beyond the width of the graph.
      count:
        item.count > MAX_DOMAIN_FOR_VIN_COUNT
          ? MAX_DOMAIN_FOR_VIN_COUNT
          : item.count,
    };
  });

  const hasData = chartData?.length > 0;

  //font-family
  const fontFamily = `ProximaNova, "Open Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, "Noto Sans", sans-serif`;

  // Pagination
  const [page, setPage] = useState(0);
  const pageSize = 5;
  const totalPages = _.ceil(chartData.length / pageSize);

  const pagedChartData = chartData.slice(
    page * pageSize,
    page * pageSize + pageSize,
  );

  // Domain for the count (y) domain.
  const maxCountDomainValue = _.min([
    _.maxBy(chartData, "count")?.count ?? 1,
    MAX_DOMAIN_FOR_VIN_COUNT,
  ]);

  return (
    <PanelGroup>
      <PanelGroup.Header title={title} />
      <PanelGroup.Content style={{ padding: 0 }}>
        <div
          css={{
            position: "relative",
            minHeight: "100px",
            [MediaQueries.smallAndDown]: {
              // Give more space below on mobile to allow room for pagination buttons.
              paddingBottom: totalPages > 1 ? "1em" : null,
            },
          }}
        >
          <Loader loaded={!isLoading}>
            {hasData && (
              <VictoryChart
                height={250}
                width={1000}
                domainPadding={15}
                // Limit the domain of the count axis.
                domain={{ y: [0, maxCountDomainValue] }}
                scale={{ y: "sqrt" }}
                // The right padding is so that there is enough space to show the hover label
                // when the data is at the edge of the chart.
                padding={{ left: 200, right: 100, top: 30, bottom: 75 }}
              >
                <VictoryBar
                  data={pagedChartData.reverse()}
                  x="category"
                  y="count"
                  barWidth={15}
                  horizontal
                  labelComponent={
                    <VictoryTooltip
                      cornerRadius={0}
                      pointerLength={0}
                      pointerWidth={0}
                      flyoutStyle={{
                        stroke: Colors.background.DARK_BLUE,
                        fill: "white",
                      }}
                    />
                  }
                  style={{
                    data: {
                      fill: chartColor,
                      cursor: "pointer",
                    },
                  }}
                  events={[
                    {
                      target: "data",
                      eventHandlers: {
                        onClick: () => ({
                          target: "data",
                          mutation: ({ datum }) => onBarClick(datum),
                        }),
                      },
                    },
                  ]}
                />
                <VictoryAxis
                  label={chartXAxisLabel}
                  axisLabelComponent={
                    <VictoryLabel
                      dx={10}
                      dy={-75}
                      angle="0"
                      textAnchor="end"
                      style={{
                        fontWeight: "bold",
                        fontFamily,
                      }}
                    />
                  }
                  style={{
                    axis: { stroke: AXIS_STROKE_COLOR },
                    axisLabel: { fontFamily },
                    tickLabels: { fontFamily },
                  }}
                />
                <VictoryAxis
                  dependentAxis
                  label={chartYAxisLabel}
                  tickFormat={(tick) => {
                    // If this is the tick that is the max we will show for the chart,
                    // add a `+` to tell the user that some of the data may go beyond the max.
                    if (tick >= MAX_DOMAIN_FOR_VIN_COUNT) {
                      return `${convertCountToLocaleString(
                        MAX_DOMAIN_FOR_VIN_COUNT,
                      )}+`;
                    }
                    // Don't display non-whole numbers
                    return Math.trunc(tick) !== tick
                      ? undefined
                      : convertCountToLocaleString(tick);
                  }}
                  axisLabelComponent={
                    <VictoryLabel
                      dy={15}
                      style={{ fontWeight: "bold", fontFamily }}
                    />
                  }
                  padding={{ top: 100 }}
                  style={{
                    axis: { stroke: AXIS_STROKE_COLOR },
                    grid: { stroke: AXIS_STROKE_COLOR, strokeDasharray: 4 },
                    ticks: { padding: 10 },
                    tickLabels: { fontFamily },
                  }}
                />
              </VictoryChart>
            )}
            {/* Pagination Controls */}
            {totalPages > 1 ? (
              <div
                css={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  padding: "1em",
                  width: "20%",
                  display: "flex",
                  justifyContent: "flex-end",
                  [MediaQueries.smallAndDown]: {
                    // allow the buttons to take up more width on smaller screen.
                    // 20% made the buttons go right up to the left edge.
                    width: "22%",
                  },
                }}
              >
                <Button
                  size="sm"
                  variant="outline-dark"
                  disabled={page <= 0}
                  onClick={() => setPage(page - 1)}
                >
                  <Icon src={faCaretUp} size={FontSize.size16} />
                </Button>
                <Button
                  size="sm"
                  variant="outline-dark"
                  disabled={page >= totalPages - 1}
                  onClick={() => setPage(page + 1)}
                  style={{ marginLeft: "1em" }}
                >
                  <Icon src={faCaretDown} size={FontSize.size16} />
                </Button>
              </div>
            ) : null}
          </Loader>

          {!isLoading && !hasData && (
            <div style={{ padding: "2em" }}>
              <NoChartData size={FontSize.size20} label={noDataLabel} />
            </div>
          )}
        </div>
      </PanelGroup.Content>
    </PanelGroup>
  );
};

VinCategoryChartPanel.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      count: PropTypes.number,
    }),
  ),
  isLoading: PropTypes.bool.isRequired,
  onBarClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  noDataLabel: PropTypes.string,
  chartColor: PropTypes.string,
  chartYAxisLabel: PropTypes.string,
  chartXAxisLabel: PropTypes.string,
  chartTooltipSingularLabel: PropTypes.string,
  chartTooltipPluralLabel: PropTypes.string,
};
