/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import Colors from "styles/colors";

import {
  VictoryLabel,
  VictoryLegend,
  VictoryPie,
  VictoryTooltip,
} from "victory";
import { PanelGroup } from "./PanelGroup.molecule";
import { LocalizedNumber } from "components/atoms/LocalizedNumber";

const CustomTooltip = ({ datum }) => {
  return <LocalizedNumber value={datum?.vins_associated} />;
};

CustomTooltip.propTypes = {
  datum: PropTypes.object,
};

export const PieChartWithLegends = ({
  widgetData,
  title,
  subTitle,
  x,
  y,
  onClickHandler,
  queryKey,
}) => {
  const colorScale = widgetData.map((item) => item.fill);

  return (
    <PanelGroup>
      <PanelGroup.Content>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <VictoryLegend
            width={140}
            height={140}
            rowGutter={{ top: 0, bottom: 0 }}
            symbolSpacer={7}
            title={[subTitle, title, "_"]}
            data={widgetData}
            colorScale={colorScale}
            titleComponent={
              <VictoryLabel
                style={[
                  {
                    fontSize: 12,
                    fill: Colors.text.TOTALS_LABEL_GRAY,
                  },
                  {
                    fontSize: 14,
                    fontWeight: 600,
                    fill: Colors.text.DARK_GRAY,
                  },
                  {
                    fontSize: 2,
                    fontWeight: 600,
                    fill: Colors.text.WHITE,
                  },
                ]}
                lineHeight={[2, 1, 2]}
              />
            }
            style={{
              labels: { fontSize: 12 },
            }}
          />
          <VictoryPie
            data={widgetData}
            width={140}
            height={140}
            innerRadius={40}
            colorScale={colorScale}
            x={x}
            y={y}
            padAngle={0}
            labels={() => null}
            padding={{ top: 10, right: 10, left: 10 }}
            events={[
              {
                target: "data",
                eventHandlers: {
                  onClick: () => ({
                    target: "data",
                    mutation: (props) => {
                      if (onClickHandler) {
                        onClickHandler(queryKey, props.datum.searchOption);
                      }
                    },
                  }),
                },
              },
            ]}
            style={{
              data: { cursor: "pointer" },
            }}
            labelComponent={
              <VictoryTooltip
                center={{ x: 70, y: 70 }}
                flyoutComponent={<CustomTooltip />}
                // need to add translation here
                text={({ datum }) => `${datum.vins_associated} VINs`}
              />
            }
          />
        </div>
      </PanelGroup.Content>
    </PanelGroup>
  );
};

PieChartWithLegends.propTypes = {
  widgetData: PropTypes.array,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  onClickHandler: PropTypes.func,
  queryKey: PropTypes.string,
  datum: PropTypes.object,
};
