import { connect } from "react-redux";
import {
  getActionStatus,
  clearActionStatus,
  importLocations,
} from "pages/administration/location-management/redux/Locations.state";
import { ImportLocationsModal } from "./ImportLocationsModal";

function mapStateToProps(state) {
  return {
    actionStatus: getActionStatus(state),
  };
}

const actionCreators = {
  clearActionStatus,
  importLocations,
};

export default connect(mapStateToProps, actionCreators)(ImportLocationsModal);
